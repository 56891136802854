<!--订单门票页-->
<template>
  <div class="wrapper flex-between flex-col">
    <div class="header ">
      <a-form-model layout="inline" :model="searchForm" ref="searchForm">
        <a-form-model-item label="下单日期">
          <a-range-picker
            :allow-clear="false"
            class="input"
            v-model="dateRange"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="订单号" prop="mainOrderId">
          <!-- 处理 输入空格过滤的问题 -->
          <a-input
            v-model.trim="searchForm.keyword"
            placeholder="订单号/总单号/外部单号/票号"
            class="input"
            allow-clear
            ref="orderListRef"
            @pressEnter="reload"
          >
          </a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="销售商户">
        <a-select value='' onChange=''>
          <a-option value=""></a-option>
          <a-option value=""></a-option>
        </a-select>
  
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button type="primary" @click="reload">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <section class="main mt-2x">
      <!--表格-->
      <a-table
        :scroll="{ x: 1366, y: tableHeight }"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        rowKey="id"
      >
        <template
          slot="appointmentPeriodStartTime"
          slot-scope="{ appointmentPeriodStartTime }"
          >{{
            appointmentPeriodStartTime | date("YYYY-MM-DD hh:mm:ss")
          }}</template
        >
        <span class="primary" slot="useDate" slot-scope="{ useDate }">{{
          useDate | date
        }}</span>

        <template slot="action" slot-scope="row">
          <!-- <a-button
          type="link"
          class="p-0 mr-1x"
          @click="refund(row)"
          :disabled="
            row.orderStatus !== 3 ||
              row.orderBusinessType === 5 ||
              row.orderBusinessType === 7
          "
          >退单</a-button
        > -->
          <a-button
            type="link"
            class="p-0 mr-1x"
            @click="
              $router.push(`/home/ticket/TicketOperateDetail?id=${row.id}`)
            "
            >门票操作</a-button
          >
        </template>
      </a-table>
      <!--分页-->
      <div class="flex-end p-2x">
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { orderTicketlist } from "@/api/search";
import { dataDictionary } from "@/api/setting";
import moment from "moment";
import { changFouce } from "@/utils/global";
import { orderSaleChannel } from "../../../api/merchant";
import { payWayForOrderQuery } from "@/api/pay";

export default {
  name: "OrderTicket",
  data() {
    return {
      //实时屏幕高度
      tableHeight: this.$tableHeight(64, 64, 54, 36, 64, 50),
      moment,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      dateRange: [moment(), moment()],
      searchForm: {
        page: 1,
        size: 10,
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        keyword: "", // 总单号或外部订单号
        buyChannel: "", // 销售渠道
        status: "", //订单状态
        payWay: "", // 支付方式
        visitors: "", // 出游人
        orderType: 1, //订单类型，查询用
        purchaserMemberId: "", //消费会员编号
        purchaserMemberPhone: "", //会员手机号
        remarks: "", //订单备注
        marketingStaffId: "", //营销员编号
        salespersonName: "", //操作人
        myOrder: 1
      },
      // visibleSearch: false, // 高级查询弹窗
      buyChannel: [], // 销售渠道
      paymentType: [], // 支付方式
      status: [], // 订单状态
      // 订单表格
      total: 0, // 总条数
      columns: [
        {
          title: "票号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId",
          width: 182
        },
        {
          title: "取票号",
          dataIndex: "mainAuxiliaryId",
          key: "mainAuxiliaryId",
          width: 120
        },
        {
          title: "游客姓名",
          dataIndex: "playPersonName",
          key: "playPersonName",
          width: 120
        },
        {
          title: "游客手机号",
          dataIndex: "playPersonPhone",
          key: "playPersonPhone",
          width: 120
        },
        {
          title: "游客身份证",
          dataIndex: "playPersonIdCard",
          key: "playPersonIdCard",
          width: 120
        },
        {
          title: "使用日期",
          key: "useDate",
          scopedSlots: { customRender: "useDate" },
          width: 170
        },
        {
          title: "使用时段",
          key: "appointmentPeriodStartTime",
          scopedSlots: { customRender: "appointmentPeriodStartTime" },
          width: 120
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 120
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryName",
          key: "productCategoryName",
          width: 120
        },
        {
          title: "产品分类",
          dataIndex: "productTypeName",
          key: "productTypeName",

          width: 120
        },
        {
          title: "门票状态",
          dataIndex: "statusText",
          key: "statusText",
          width: 120
        },
        {
          title: "销售直销渠道",
          dataIndex: "buyChannelsText",
          key: "buyChannelsText",
          width: 120
        },
        {
          title: "销售分销渠道",
          dataIndex: "yxpChannelsCode",
          key: "yxpChannelsCode",
          width: 120
        },
        {
          title: "产品所属商户",
          key: "productOwnerMerchantName",
          dataIndex: "productOwnerMerchantName",
          width: 120
        },
        {
          title: "订单号",
          key: "mainOrderId",
          dataIndex: "mainOrderId",
          width: 120
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          width: 110
        }
      ], //表头
      tableData: []
    };
  },
  created() {
    this.loadPayWay(); // 获取支付方式
    this.loadKeys(); // 获取订单状态
    this.loadSaleChannel(); // 获取销售渠道
    this.loadData();
    changFouce(this, "orderListRef");
  },
  methods: {
    //查询支付方式
    loadPayWay() {
      payWayForOrderQuery()
        .then(res => {
          // 过滤出只有交账
          if (res) {
            this.paymentType =
              res.filter(item => item.payWayCode == "OnCredit") || [];
            // this.searchForm.payWay = "";
            this.searchForm.payWay = this.paymentType[0].payWayQuery;
          }
        })
        .catch(() => {});
    },
    // 字典数据 ：payWay 支付方式旧的已废弃
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,orderStatus"
      })
        .then(data => {
          this.status = data.orderStatus;
        })
        .catch(() => {});
    },
    // 加载销售渠道
    loadSaleChannel() {
      orderSaleChannel()
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },
    // 加载table数据
    loadData() {
      const obj = { ...this.searchForm };
      //有mainOrderId时其他条件不传
      orderTicketlist(
        obj.mainOrderId ? { mainOrderId: obj.mainOrderId, myOrder: 1 } : obj
      )
        .then(({ records, total }) => {
          this.total = total;
          this.tableData = records;
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.searchForm.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 查询
    reload() {
      this.visibleSearch = false;
      this.searchForm.page = 1;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.searchForm.orderBeginTime = moment()
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment()
        .endOf("date")
        .valueOf();
      this.searchForm.keyword = "";
      this.searchForm.buyChannel = "";
      this.searchForm.status = "";
      this.searchForm.visitors = "";
      this.searchForm.remarks = "";
      this.searchForm.purchaserMemberId = "";
      this.searchForm.purchaserMemberPhone = "";
      this.searchForm.displayRender = "";
      this.searchForm.marketingStaffId = "";
      this.searchForm.salespersonName = "";
      this.reload();
    },
    // 条数改变
    sizeChange(current, size) {
      this.searchForm.page = 1;
      this.searchForm.size = size;
      this.reload();
    },
    // 销售渠道选择
    fromChange(value, selectOptions) {
      this.searchForm.buyChannel = selectOptions[selectOptions.length - 1].id;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    }
  }
};
</script>
<style lang="less" scoped>
.input {
  width: 220px;
}
/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
