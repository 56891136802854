<template>
  <a-modal
    :visible="vis"
    title="补充人脸信息"
    width="780px"
    @cancel="close"
    :body-style="{
      'max-height': '460px'
    }"
    :mask-closable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="refAddFaceInfor"
    >
      <a-form-model-item label="门票名称">
        <span>{{ activateInfo.productName }}</span>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        label="主票票号"
        :label-col="labelCol"
        v-if="
          !activateInfo.subTicketListInfo ||
            activateInfo.subTicketListInfo.length <= 0
        "
      >
        <a-row type="flex" justify="start">
          <a-col :span="6">{{ activateInfo.auxiliaryId }}</a-col>
          <a-col :span="10">
            <IdCardInput
              @readCard="readCard"
              v-model="idCardNumber"
            ></IdCardInput>
          </a-col>
        </a-row>
        <a-row class="mt-2x" type="flex" justify="start">
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <FaceInput
              ref="faceInput"
              :echoimgurl="faceUrl"
              :faceid="faceid"
              v-model="faceNumber"
              @imgUrlChange="imgUrl"
            ></FaceInput>
          </a-col>
        </a-row>
      </a-form-model-item>

      <!-- 子票：补充人脸 -->
      <a-form-model-item
        v-if="
          activateInfo.subTicketListInfo &&
            activateInfo.subTicketListInfo.length
        "
        label="子票票号"
      >
        <div
          v-for="(item, index) in activateInfo.subTicketListInfo"
          :key="index"
          class="mb-3x"
        >
          <a-row type="flex" justify="start">
            <a-col :span="6">
              {{ item.auxiliaryId }}
            </a-col>
            <a-col :span="10">
              <IdCardInput
                @readCard="
                  data => {
                    subReadCard(data, index);
                  }
                "
                v-model="item.subIdCardNumber"
              ></IdCardInput>
            </a-col>
          </a-row>
          <a-row class="mt-x" type="flex" justify="start">
            <a-col :span="6"></a-col>
            <a-col :span="10">
              <FaceInput
                ref="faceInput"
                v-model="item.subFaceNumber"
                :echoimgurl="item.subFaceUrl"
                :faceid="faceid"
                @imgUrlChange="
                  url => {
                    subImgUrl(url, index);
                  }
                "
              ></FaceInput>
            </a-col>
          </a-row>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="flex-end" slot="footer">
      <a-button key="cancel" @click="close">取消</a-button>
      <a-button type="primary" @click="save" :loading="loading">保存</a-button>
    </div>
  </a-modal>
</template>

<script>
import IdCardInput from "../../../../components/hardware/IdCardInput.vue";
import FaceInput from "../../../../components/hardware/FaceInput.vue";
import {
  findTicketActivateInfo,
  ticketActivateFace
} from "../../../../api/order";
import { fileUploadBase64 } from "@/api/upload";

export default {
  name: "AddFaceInfor",
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    IdCardInput,
    FaceInput
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      form: {},
      rules: {},
      loading: false,
      activateInfo: {},
      idCardNumber: "", // 身份证输入框
      faceNumber: "", // 人脸信息框
      faceUrl: "", // 人脸图片地址
      faceid: ""
    };
  },
  mounted() {
    this.findActivateInfo();
  },
  methods: {
    // 主票号：身份证读卡
    readCard(data) {
      if (data.photo) {
        fileUploadBase64({
          data: data.photo, // 数据
          ext: "png" // 图片后缀格式
        })
          .then(res => {
            this.faceUrl = res.src;
          })
          .catch(() => {
            this.$message.error("图片上传接口错误");
          });
      }
    },
    // 主票号-人脸图片
    imgUrl(url) {
      this.faceUrl = url;
    },
    // 子票号 - 人脸图片
    subImgUrl(url, index) {
      this.activateInfo.subTicketListInfo[index].subFaceUrl = url;
    },
    // 子票号 - 身份证读卡
    subReadCard(data, index) {
      if (data.photo) {
        fileUploadBase64({
          data: data.photo, // 数据
          ext: "png" // 图片后缀格式
        })
          .then(res => {
            this.activateInfo.subTicketListInfo[index].subFaceUrl = res.src;
          })
          .catch(() => {
            this.$message.error("图片上传接口错误");
          });
      }
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 查询
    async findActivateInfo() {
      const res = await findTicketActivateInfo({
        id: this.detail.id
      }).catch(() => {});
      // 没有子票号场景
      if (res) {
        // 回显
        this.idCardNumber = res.bindIdCardNo || "";
        this.faceNumber = res.bindFaceId || "";
        this.faceid = res.bindFaceId;
        this.faceUrl = res.bindFaceURL || "";
        this.activateInfo = res;
      }
      // 有子票号场景
      if (res.subTicketListInfo) {
        const deepCopyRes = JSON.parse(JSON.stringify(res));
        deepCopyRes.subTicketListInfo.forEach(item => {
          item.subIdCardNumber = item.bindIdCardNo || ""; // 存储硬件读取的身份证
          item.subFaceNumber = item.bindFaceId || ""; // 存储硬件读取的人脸
          item.subFaceUrl = item.bindFaceURL || ""; // 存储硬件读取的人脸
        });
        this.activateInfo = deepCopyRes;
      }
    },
    // 保存
    save() {
      const arr = [];
      // 主票号：情况校验
      if (!this.activateInfo.subTicketListInfo) {
        if (!this.idCardNumber) {
          this.$message.warning("请先录入身份证");
          return;
        }
        if (!this.faceUrl) {
          // 不判断faceNumber，因为用身份证读卡获取的时候，是没有faceid参数
          this.$message.warning("没获取到人脸图片");
          return;
        }
      }
      // 子票号情况校验
      let flag = true;
      let index = 0;
      if (this.activateInfo.subTicketListInfo) {
        this.activateInfo.subTicketListInfo.some((item, i) => {
          // 不需要校验人脸id参数，因为读身份证的时候，没有人脸id参数
          if (!item.subIdCardNumber || !item.subFaceUrl) {
            flag = false;
            index = i;
          }
          // 退出some循环
          if (!flag) {
            this.$message.warning(
              `第${index + 1}项，子票号：${item.auxiliaryId}，信息未填写完整`
            );
            return true;
          }
          arr.push({
            id: item.id,
            idCardNo: item.subIdCardNumber,
            faceId: item.subFaceNumber,
            faceUrl: item.subFaceUrl
          });
        });
      }
      if (!flag) {
        return;
      }
      this.loading = true;
      // console.log("提交的信息：", {
      //   ifActivateNow: false, // 是否立即激活
      //   id: this.activateInfo.id, // 门票订单详情记录id
      //   auxiliaryId: this.activateInfo.auxiliaryId, // 主票号
      //   idCardNo: this.idCardNumber || "", // 主票号绑定的身份证号
      //   faceId: this.faceNumber || "", // 主票号绑定的人脸id
      //   faceUrl: this.faceUrl, // 人脸图片地址
      //   // 子票号
      //   ticketPrintSubAuxiliaryInfoList: arr
      // });
      ticketActivateFace({
        channel: "", // 操作渠道, 非必填
        auxiliaryInfoList: [
          {
            ifActivateNow: false, // 是否立即激活
            id: this.activateInfo.id, // 门票订单详情记录id
            auxiliaryId: this.activateInfo.auxiliaryId, // 主票号
            idCardNo: this.idCardNumber || "", // 主票号绑定的身份证号
            faceId: this.faceNumber || "", // 主票号绑定的人脸id
            faceUrl: this.faceUrl, // 人脸图片地址
            // 子票号
            ticketPrintSubAuxiliaryInfoList: arr
          }
        ]
      })
        .then(() => {
          this.$emit("updateParentData");
          this.$emit("update:vis", false);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
/deep/.ant-modal-body {
  overflow-y: auto;
}
</style>
