<template>
  <div>
    <header class="mb-2x flex-start align-center">
      <span class="flex-shrink">退款单号</span>
      <div>
        <a-input-search
          class="ml-2x"
          placeholder="请输入单品票号/退款单号/组合产品票号"
          v-model="refundId"
          suffix-icon="el-icon-search"
          allowClear
          @change="inputChange"
        ></a-input-search>
      </div>
    </header>
    <!--列表-->
    <!--列表-->
    <a-table
      :scroll="{ x: 1366 }"
      :pagination="false"
      rowKey="auxiliaryId"
      :columns="tableColumns"
      :data-source="refundList"
      header-cell-class-name="bg-gray bd-t"
    >
      <span slot="refundTime" slot-scope="{ refundTime }">{{
        refundTime | date("YYYY-MM-DD hh:mm:ss")
      }}</span>
      <template slot="refundAmount" slot-scope="{ refundAmount }">{{
        refundAmount | money
      }}</template>
      <template slot="actualRefundAmount" slot-scope="{ actualRefundAmount }">{{
        actualRefundAmount | money
      }}</template>
      <span
        class="primary"
        slot="refundStatusText"
        slot-scope="{ refundStatusText }"
        >{{ refundStatusText }}</span
      >
      <span :title="remarks" slot="remarks" slot-scope="{ remarks }">{{
        remarks
      }}</span>
    </a-table>
  </div>
</template>

<script>
const tableColumns = [
  {
    title: "单品编号（票号）",
    dataIndex: "auxiliaryId",
    key: "auxiliaryId",
    fixed: "left",
    width: 182
  },
  {
    title: "单品名称",
    dataIndex: "productName",
    key: "productName",
    width: 182
  },
  {
    title: "组合产品编号（票号）",
    dataIndex: "combineAuxiliaryId",
    key: "combineAuxiliaryId",
    width: 182
  },
  {
    title: "组合产品名称",
    dataIndex: "combineProductName",
    key: "combineProductName",
    width: 182
  },
  {
    title: "退款总单号",
    dataIndex: "refundId",
    key: "refundId",
    width: 182
  },
  {
    title: "退款时间",
    key: "id",
    scopedSlots: { customRender: "refundTime" },
    width: 180
  },
  {
    title: "退款状态",
    key: "refundStatusText",
    scopedSlots: { customRender: "refundStatusText" },
    width: 120
  },
  {
    title: "明细实收余额",
    key: "refundAmount",
    scopedSlots: { customRender: "refundAmount" },
    width: 120
  },
  // {
  //   title: "应退金额",
  //   key: "refundAmount",
  //   scopedSlots: { customRender: "refundAmount" },
  //   width: 120
  // },
  {
    title: "退积分数量",
    dataIndex: "refundIntegralDeductionAmount",
    key: "refundIntegralDeductionAmount",
    width: 120
  },
  {
    title: "实退金额",
    key: "actualRefundAmount",
    scopedSlots: { customRender: "actualRefundAmount" },
    width: 120
  },
  {
    title: "退款方式",
    dataIndex: "refundWayName",
    key: "refundWayName",
    width: 100
  },
  {
    title: "退款渠道",
    dataIndex: "refundChannelsText",
    key: "refundChannelsText",
    width: 100
  },
  {
    title: "外部退款号",
    dataIndex: "refundOutsideCode",
    key: "refundOutsideCode",
    width: 130
  },
  {
    title: "退款操作人",
    dataIndex: "refundAuditPersonName",
    key: "refundAuditPersonName",
    width: 120
  },
  {
    title: "退款备注",
    scopedSlots: { customRender: "remarks" },
    key: "remarks",
    ellipsis: true,
    width: 200
  }
];
export default {
  name: "RefundRecordNew",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      tableColumns,
      refundList: [],
      refundListOld: [], // 旧数据
      refundId: "" // 退款单号
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newValue) {
        this.refundList = JSON.parse(JSON.stringify(newValue)) || [];
        // // // 处理合计
        const obj = {
          refundAmount: 0,
          actualRefundAmount: 0,
          refundIntegralDeductionAmount: 0,
          auxiliaryId: "合计"
        };
        this.refundList.forEach(item => {
          obj.refundAmount += item.refundAmount;
          obj.actualRefundAmount += item.actualRefundAmount;
          obj.refundIntegralDeductionAmount +=
            item.refundIntegralDeductionAmount;
        });
        if (this.refundList.length) this.refundList.push(obj);
        this.refundListOld = this.refundList;
      }
    }
  },
  methods: {
    // 退款单号change
    inputChange({ target }) {
      // 单品号，组合产品号，退款单号
      this.refundList = this.refundListOld.filter(
        item =>
          item.auxiliaryId.toString().indexOf(target.value) !== -1 ||
          (item.combineAuxiliaryId &&
            item.combineAuxiliaryId.toString().indexOf(target.value) !== -1) ||
          (item.refundId &&
            item.refundId.toString().indexOf(target.value) !== -1)
      );
      if (!target.value) this.refundList = this.refundListOld;
    }
  }
};
</script>
