<template>
  <div>
    <div v-show="isShowMerchant">
      <!-- 头部开始 -->
      <a-form layout="inline" class="mb-2x">
        <a-form-item label="商户类型">
          <a-select
            placeholder="请选择"
            style="width:150px"
            v-model="search.merchantType"
          >
            <a-select-option
              :value="item.value"
              v-for="item in selectOptions"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="商户名称">
          <a-input
            placeholder="请输入商户名称"
            allowClear
            v-model="search.merchantName"
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchBtn">
            查询
          </a-button>
        </a-form-item>
      </a-form>
      <!-- 头部结束 -->
      <a-table
        :columns="columns"
        :data-source="merchantDetailList"
        :scroll="{ y: 400 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="operation" slot-scope="scope">
          <a-button type="link" @click="openProductDialog(scope)"
            >所有产品</a-button
          >
        </template>
      </a-table>
    </div>
    <div v-show="!isShowMerchant">
      <!-- 所有产品弹窗 -->
      <!-- 头部开始 -->
      <a-form layout="inline" class="mb-2x">
        <a-form-item label="产品名称">
          <a-input
            placeholder="请输入产品名称"
            allowClear
            v-model="search.productName"
          ></a-input>
        </a-form-item>
        <a-form-item label="产品类型">
          <a-select
            placeholder="请选择"
            style="width:150px"
            v-model="search.productType"
          >
            <a-select-option label="全部" value="" key="0"
              >全部</a-select-option
            >
            <a-select-option
              :value="item.cid1"
              v-for="item in selectOptions2"
              :key="item.cid1"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" @click="searchBtnPro">
            查询
          </a-button>
        </a-form-item>
        <a-button type="link" @click="isShowMerchant = true"
          >《返回商户列表</a-button
        >
      </a-form>
      <!-- 头部结束 -->
      <a-table
        :columns="columns2"
        :data-source="productDetailList"
        :scroll="{ y: 400 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="cid1" slot-scope="scope">
          <span v-if="scope.cid1 == '1365211690130337793'">门票</span>
          <span v-if="scope.cid1 == '1365211690470076417'">综合套票</span>
          <span v-if="scope.cid1 == '1365211691942277121'">项目套餐</span>
          <span v-if="scope.cid1 == '1365211695276748801'">门票资产套餐</span>
          <span v-if="scope.cid1 == '1365211692399456258'">零售商品</span>
          <span v-if="scope.cid1 == '1365211693653553153'">酒店</span>
        </template>
        <template slot="sellingPrice" slot-scope="scope">
          <span class="red">{{ scope.sellingPrice | money }}</span>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { merchantBasicInfoByIds } from "../../../../api/merchant";
import { productBackMerchantproduct } from "../../../../api/search";

export default {
  name: "look-app-products",
  props: {
    // 商户id数组
    merchantIds: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      columns: [
        {
          title: "商户类型",
          dataIndex: "merchantTypeText",
          key: "merchantTypeText"
        },
        { title: "商户名称", dataIndex: "merchantName", key: "merchantName" },
        {
          title: "商户分类",
          dataIndex: "merchantCategoryGroupName",
          key: "merchantCategoryGroupName"
        },
        {
          title: "适用产品",
          key: "operation",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],
      columns2: [
        {
          title: "产品名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "产品编号",
          dataIndex: "id",
          key: "id"
        },
        {
          title: "产品类型",
          key: "cid1",
          scopedSlots: {
            customRender: "cid1"
          }
        },
        {
          title: "产品分类",
          dataIndex: "categoryName",
          key: "categoryName"
        },
        {
          title: "销售价格",
          key: "sellingPrice",
          scopedSlots: {
            customRender: "sellingPrice"
          }
        }
      ],
      tabelList: [],
      merchantDetailList: [],
      productDetailList: [],
      isShowMerchant: true, // 是显示：已选商户还是已选产品
      search: {
        merchantName: "",
        merchantType: "all",
        productName: "",
        productType: ""
      },
      // 商户类型：1趣买票，2服务商（趣买票代理商），3集团，4景区，5游小铺，6园内商户，7周边商户，8分销商，9OTA，10零售供应商
      selectOptions: [
        { label: "全部", value: "all" },
        { label: "景区", value: 4 },
        { label: "园内商户", value: 6 },
        { label: "园外商户", value: 7 }
      ],
      selectOptions2: [
        { name: "门票", cid1: "1365211690130337793" },
        { name: "综合套票", cid1: "1365211690470076417" },
        { name: "项目套餐", cid1: "1365211691942277121" },
        { name: "门票资产套餐", cid1: "1365211695276748801" },
        { name: "零售商品", cid1: "1365211692399456258" },
        { name: "酒店", cid1: "1365211693653553153" }
      ],
      // 分页
      pageSize: 10,
      currentPage: 1,
      total: 0,
      productParams: {
        merchantId: "", // 所属商户id
        name: "", // 产品名称
        cid1: "", // 产品分类
        ids: "", // 多个产品skuId,所有产品传空
        page: 1,
        size: 10
      }
    };
  },
  watch: {
    merchantIds: function() {
      this.getDetail();
    },
    tabelList(newVal) {
      if (newVal) {
        this.merchantDetailList = JSON.parse(JSON.stringify(this.tabelList));
      }
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 适用产品数据
    getDetail() {
      merchantBasicInfoByIds({
        ids: this.merchantIds.join(","),
        isNeedCategoryInfo: true
      })
        .then(res => {
          const arr = [];
          this.merchantIds.forEach(item => {
            res.forEach(subItem => {
              if (item == subItem.id) {
                arr.push({
                  merchantTypeText: subItem.merchantTypeText,
                  merchantName: subItem.merchantName,
                  merchantCategoryGroupName: subItem.merchantCategoryGroupName,
                  merchantId: subItem.id,
                  merchantType: subItem.merchantType,
                  products: ""
                });
              }
            });
          });
          this.tabelList = arr;
        })
        .catch(() => {});
    },
    // 开启：查看已选产品
    openProductDialog(row) {
      this.isShowMerchant = false;
      this.productParams.merchantId = row.merchantId;
      this.productParams.ids = "";
      this.productParams.page = this.currentPage;
      this.productParams.size = this.pageSize;
      this.getProduct();
    },
    getProduct() {
      productBackMerchantproduct(this.productParams)
        .then(res => {
          this.productDetailList = res.records;
        })
        .catch(() => {});
    },
    // 本地搜索、查询（已选商户）
    searchBtn() {
      this.merchantDetailList = JSON.parse(JSON.stringify(this.tabelList));
      // 没有选择商户类型，没有输入商户名称
      if (!this.search.merchantType && !this.search.merchantName) {
        this.merchantDetailList = JSON.parse(JSON.stringify(this.tabelList));
      } else if (this.search.merchantType && !this.search.merchantName) {
        // 只选择了 商户类型，没输入商户名称
        if (this.search.merchantType == "all") {
          return;
        }
        this.merchantDetailList = this.merchantDetailList.filter(item => {
          return item.merchantType == this.search.merchantType;
        });
      } else if (!this.search.merchantType && this.search.merchantName) {
        // 没选择商户类型，输入了商户名称
        this.merchantDetailList = this.merchantDetailList.filter(item => {
          return item.merchantName.includes(this.search.merchantName);
        });
      } else {
        // 选择了类型，也输入了名称
        // 类型选了全部的情况，不用包含merchantType
        if (this.search.merchantType == "all") {
          this.merchantDetailList = this.merchantDetailList.filter(item => {
            return item.merchantName.includes(this.search.merchantName);
          });
        } else {
          this.merchantDetailList = this.merchantDetailList.filter(item => {
            return (
              item.merchantName.includes(this.search.merchantName) &&
              item.merchantType == this.search.merchantType
            );
          });
        }
      }
    },
    // 已选产品搜索
    searchBtnPro() {
      this.productParams.name = this.search.productName;
      this.productParams.cid1 = this.search.productType;
      this.productParams.page = this.currentPage;
      this.productParams.size = this.pageSize;
      this.getProduct();
    }
  }
};
</script>

<style lang="less" scoped></style>
