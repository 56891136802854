var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.vis,"title":"取票","width":"780px","body-style":{
    'max-height': '460px',
    overflow: 'auto'
  },"maskClosable":false},on:{"cancel":_vm.close}},[_c('a-form-model',{ref:"refTicketCollection",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"门票名称"}},[_c('span',[_vm._v(_vm._s(_vm.activateInfo.productName))])]),_c('a-form-model-item',{attrs:{"label":"主票票号"}},[_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[_vm._v(" "+_vm._s(_vm.activateInfo.auxiliaryId)+" ")]),_c('a-col',{attrs:{"span":16}},[_c('a-checkbox',{attrs:{"disabled":!_vm.activateInfo.isNeedActivate || _vm.detail.status != 4},model:{value:(_vm.form.check),callback:function ($$v) {_vm.$set(_vm.form, "check", $$v)},expression:"form.check"}},[_vm._v(" 立即激活 ")])],1)],1)],1),(
        !_vm.activateInfo.subTicketListInfo ||
          _vm.activateInfo.subTicketListInfo.length <= 0
      )?_c('a-form-model-item',{attrs:{"colon":false,"label":" ","label-col":_vm.labelCol}},[(_vm.activateInfo.byTicketId || _vm.activateInfo.bindIdCardNo)?_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":10}},[_c('IdCardInput',{model:{value:(_vm.idCardNumber),callback:function ($$v) {_vm.idCardNumber=$$v},expression:"idCardNumber"}})],1)],1):_vm._e(),(
          !_vm.activateInfo.ifPayDeposit && _vm.activateInfo.productVoucherType != 0
        )?_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":10}},[_c('IcCardinput',{attrs:{"placeholder":"请输入卡/腕带编号"},model:{value:(_vm.voucherType),callback:function ($$v) {_vm.voucherType=$$v},expression:"voucherType"}})],1)],1):_vm._e(),(_vm.activateInfo.byFaces || _vm.activateInfo.bindFaceURL)?_c('a-row',{staticClass:"mt-2x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":10}},[_c('FaceInput',{attrs:{"echoimgurl":_vm.activateInfo.bindFaceURL},model:{value:(_vm.faceNumber),callback:function ($$v) {_vm.faceNumber=$$v},expression:"faceNumber"}})],1)],1):_vm._e()],1):_vm._e(),(
        _vm.activateInfo.subTicketListInfo &&
          _vm.activateInfo.subTicketListInfo.length
      )?_c('a-form-model-item',{attrs:{"label":"子票票号"}},_vm._l((_vm.activateInfo.subTicketListInfo),function(item,index){return _c('div',{key:index,staticClass:"mb-3x"},[(_vm.activateInfo.byTicketId)?_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[(_vm.activateInfo.byTicketId)?_c('span',[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")]):_vm._e()]),(_vm.activateInfo.byTicketId)?_c('a-col',{attrs:{"span":10}},[_c('IdCardInput',{model:{value:(item.subIdCardNumber),callback:function ($$v) {_vm.$set(item, "subIdCardNumber", $$v)},expression:"item.subIdCardNumber"}})],1):_vm._e()],1):_vm._e(),(
            !_vm.activateInfo.ifPayDeposit && _vm.activateInfo.productVoucherType != 0
          )?_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[(
                !_vm.activateInfo.byTicketId &&
                  _vm.activateInfo.productVoucherType != 0
              )?_c('span',[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":10}},[_c('IcCardinput',{attrs:{"placeholder":"请输入卡/腕带编号"},model:{value:(item.subVoucherType),callback:function ($$v) {_vm.$set(item, "subVoucherType", $$v)},expression:"item.subVoucherType"}})],1)],1):_vm._e(),(_vm.activateInfo.byFaces)?_c('a-row',{staticClass:"mt-x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[(
                !_vm.activateInfo.byTicketId &&
                  _vm.activateInfo.productVoucherType == 0
              )?_c('span',[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":10}},[_c('FaceInput',{attrs:{"echoimgurl":item.bindFaceURL},model:{value:(item.subFaceNumber),callback:function ($$v) {_vm.$set(item, "subFaceNumber", $$v)},expression:"item.subFaceNumber"}})],1)],1):_vm._e(),(
            !_vm.activateInfo.byFaces &&
              !_vm.activateInfo.byTicketId &&
              _vm.activateInfo.productVoucherType == 0
          )?_c('a-row',{staticClass:"mt-x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":10}},[_c('span',[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")])])],1):_vm._e()],1)}),0):_vm._e()],1),_c('div',{staticClass:"flex-between align-center",class:{ 'flex-end': _vm.activateInfo.ifPayDeposit },attrs:{"slot":"footer"},slot:"footer"},[(!_vm.activateInfo.ifPayDeposit)?_c('div',[_c('span',[_vm._v(" 押金："),_c('span',{staticClass:"red"},[_vm._v(_vm._s(_vm._f("money")(_vm.activateInfo.voucherDeposit)))])])]):_vm._e(),_c('div',[_c('a-button',{key:"cancel",on:{"click":_vm.close}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.getTicketBtn}},[_vm._v("确定取票")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }