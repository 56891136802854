<template>
  <!--详情弹窗-->
  <a-modal
    title="产品详情"
    :visible="visible"
    @cancel="close"
    width="750px"
    :body-style="{
      maxHeight: '500px',
      overflowY: 'auto'
    }"
  >
    <a-spin tip="加载中..." :spinning="loading">
      <div style="min-height: 350px">
        <!--// 1365211690130337793 门票 1365211691942277121 项目套餐
         1365211693271871490 资产套餐
          其它： 酒店 餐饮 零售 剧场
         -->
        <!--门票-->
        <template v-if="detail.productCategoryId === '1365211690130337793'">
          <section>
            <a-row>
              <a-col :span="4">子产品名称：</a-col>
              <a-col :span="20">{{ detail.productName }}</a-col>
            </a-row>
            <a-row>
              <a-col :span="4">产品分类：</a-col>
              <a-col :span="20">{{ detail.productCategoryText }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">订单编号：</a-col>
              <a-col :span="20">{{ detail.orderId }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">原子产品金额：</a-col>
              <a-col :span="20">{{ detail.totalAmount | money }} </a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">优惠分摊金额：</a-col>
              <a-col :span="20">{{ detail.discountAmount | money }} </a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">优惠后金额：</a-col>
              <a-col :span="20" class="red"
                >{{ detail.totalAmount | money }}
              </a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">产品状态：</a-col>
              <a-col :span="20" class="primary">{{ detail.statusText }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">使用有效期：</a-col>
              <a-col :span="20"
                >{{ detail.beginValidTime | date }} ~
                {{ detail.endValidTime | date }}</a-col
              >
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">可使用日期：</a-col>
              <a-col :span="20">{{
                ticketWriteOffConfigInfo.checkTravelDateType === 0
                  ? "门票有效期内都可核销"
                  : "仅预约游玩日期可核销"
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">可核销次数：</a-col>
              <a-col :span="20">{{
                ticketWriteOffConfigInfo.enterTimesType
                  ? ticketWriteOffConfigInfo.enterTimes
                  : "不限制"
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">每日可核销：</a-col>
              <a-col :span="20">{{
                ticketWriteOffConfigInfo.dailyEnterTimesType
                  ? ticketWriteOffConfigInfo.dailyEnterTimes
                  : "不限制"
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">可核销月份：</a-col>
              <a-col :span="20">{{
                ticketWriteOffConfigInfo.validWeeks
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">可核销星期：</a-col>
              <a-col :span="20">{{
                ticketWriteOffConfigInfo.validMonths
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">可核销时段：</a-col>
              <a-col :span="20">
                <span v-if="ticketWriteOffConfigInfo.useTimeType === 0"
                  >不限制</span
                >
                <span v-else>
                  <template
                    v-if="
                      ticketWriteOffConfigInfo.useTimeRange &&
                        ticketWriteOffConfigInfo.useTimeRange.length
                    "
                  >
                    {{
                      ticketWriteOffConfigInfo.useTimeRange[0] | date("hh:mm")
                    }}
                    ~
                    {{
                      ticketWriteOffConfigInfo.useTimeRange[1] | date("hh:mm")
                    }}
                  </template>
                </span>
              </a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">已核销次数：</a-col>
              <a-col :span="20">{{
                detail.useNum === -1 ? "不限制" : detail.useNum
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">首次核销时间：</a-col>
              <a-col :span="20">{{
                detail.firstWriteOffTime | date("yyyy-mm-dd hh:mm:ss")
              }}</a-col>
            </a-row>
            <a-row class="mt-2x">
              <a-col :span="4">核销后到期日期：</a-col>
              <a-col :span="20">{{
                detail.afterWriteOffValidTime | date("yyyy-mm-dd hh:mm:ss")
              }}</a-col>
            </a-row>
          </section>
        </template>
        <!--项目套餐-->
        <template
          v-else-if="detail.productCategoryId === '1365211691942277121'"
        >
          <a-row>
            <a-col :span="4">产品名称：</a-col>
            <a-col :span="20">{{ detail.productName }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">收款金额：</a-col>
            <a-col :span="20">
              <p class="red">{{ detail.receivedAmount | money }}</p>
            </a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">订单编号</a-col>
            <a-col :span="20">{{ detail.orderId }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">产品状态</a-col>
            <a-col :span="20" class="primary">{{ detail.statusText }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">可使用次数</a-col>
            <a-col :span="20">{{
              detail.canUseNum === -1 ? "不限制" : detail.canUseNum
            }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">每天可使用次数</a-col>
            <a-col :span="20">{{
              ticketPlayItemPackageConfigInfo.whetherDailyUseLimitType === 0
                ? "不限制"
                : ticketPlayItemPackageConfigInfo.dailyUseLimit
            }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">已使用次数</a-col>
            <a-col :span="20">{{ detail.useNum }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">剩余可使用</a-col>
            <a-col :span="20" class="red">{{
              detail.remainUseNum === -1 ? "不限制" : detail.remainUseNum
            }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">可玩项目</a-col>

            <a-col :span="20" v-if="isLimitPlayData">
              <a-table
                :columns="canPlayColumns"
                :data-source="playData"
                :pagination="false"
                rowKey="id"
                size="middle"
              >
                <template slot="lessLimit" slot-scope="row">
                  {{
                    row.customRelation ? row.customRelation.lessLimit : "不限"
                  }}
                </template>
                <template slot="mostLimit" slot-scope="row">
                  {{
                    row.customRelation ? row.customRelation.mostLimit : "不限"
                  }}
                </template>
              </a-table>
            </a-col>
            <a-col :span="20" v-else
              ><span class="primary">全部项目</span></a-col
            >
          </a-row>
        </template>
        <!--资产套餐-->
        <template
          v-else-if="detail.productCategoryId === '1365211695276748801'"
        >
          <a-row>
            <a-col :span="4">产品名称：</a-col>
            <a-col :span="20">{{ detail.productName }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">收款金额：</a-col>
            <a-col :span="20">
              <p class="red">{{ detail.receivedAmount | money }}</p>
            </a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">订单编号</a-col>
            <a-col :span="20">{{ detail.orderId }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">产品状态</a-col>
            <a-col :span="20" class="primary">{{ detail.statusText }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">套餐详情：</a-col>
            <a-col :span="20">
              <a-table
                :columns="packColumns"
                :data-source="ticketConsumeTypePackageConfigInfList"
                :pagination="false"
                rowKey="id"
                size="middle"
              >
              </a-table>
            </a-col>
          </a-row>
        </template>
        <!--酒店房型、其它-->
        <template v-else>
          <a-row class="mt-2x">
            <a-col :span="4">产品名称：</a-col>
            <a-col :span="20">{{ detail.productName }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">所属商户：</a-col>
            <a-col :span="20">{{ detail.productOwnerMerchantName }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">收款金额：</a-col>
            <a-col :span="20">
              <p class="red">{{ detail.receivedAmount | money }}</p>
            </a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">订单编号：</a-col>
            <a-col :span="20">{{ detail.orderId }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">外部订单号：</a-col>
            <a-col :span="20">{{ detail.outsideCode }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">产品状态：</a-col>
            <a-col :span="20" class="primary">{{ detail.statusText }}</a-col>
          </a-row>
        </template>

        <!--退款信息-->
        <template v-if="orderRefundInfo">
          <a-row class="mt-2x">
            <a-col :span="4">已退款金额：</a-col>
            <a-col :span="20" class="red">{{
              orderRefundInfo.refundAmount | money
            }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">退款说明：</a-col>
            <a-col :span="20">{{ orderRefundInfo.remarks }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">退款操作人：</a-col>
            <a-col :span="20">{{ orderRefundInfo.refundAuditPersonId }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">操作渠道：</a-col>
            <a-col :span="20">{{ orderRefundInfo.refundChannasText }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">退款时间：</a-col>
            <a-col :span="20">{{ orderRefundInfo.refundCompleteTime }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">退款单号：</a-col>
            <a-col :span="20">{{ orderRefundInfo.refundId }}</a-col>
          </a-row>
        </template>
      </div>
    </a-spin>

    <footer slot="footer" class="text-c">
      <a-button @click="close">关闭</a-button>
    </footer>
  </a-modal>
</template>

<script>
import { findOrderDetailProductDetail } from "../../../../api/order";
import { orderPackageusableProject } from "../../../../api/search";

export default {
  name: "TicketProductDetail",
  props: {
    // show
    visible: {
      type: Boolean,
      default: false
    },
    // 产品记录id
    id: {
      type: [Number, String],
      default: ""
    },
    //项目产品id
    productId: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {
      ticket: {}, // 门票详情
      checkConfVO: {}, // 门票配置详情
      list: [], // 列表/详情
      loading: false, // 加载中
      //0门票 1 项目套餐 2资产套餐 3酒店 4 餐饮 5零售 6剧场
      secCategory: "",
      playData: [], //可玩项目
      isLimitPlayData: true, //是否有限的可玩项目
      detail: {}, // 详情信息
      orderRefundInfo: {}, // 退款信息
      ticketPlayItemPackageConfigInfo: {}, // 门票项目套餐配置信息
      ticketConsumeTypePackageConfigInfList: [], // 门票资产套餐配置信息
      ticketWriteOffConfigInfo: {}, // 门票的核销配置信息

      playItemList: [], // 可玩项目
      // 表格
      packColumns: [
        {
          title: "门票资产类型",
          dataIndex: "assetsName",
          key: "assetsName"
        },
        {
          title: "资产数量",
          key: "count",
          dataIndex: "count"
        }
        // {
        //   title: "退款系数",
        //   dataIndex: "refundRatio",
        //   key: "refundRatio"
        // }
      ],
      canPlayColumns: [
        {
          title: "项目名称",
          dataIndex: "followProductName",
          key: "followProductName"
        },
        {
          title: "最少使用次数",
          key: "lessLimit",
          scopedSlots: { customRender: "lessLimit" }
        },
        {
          title: "最多使用次数",
          scopedSlots: { customRender: "mostLimit" },
          key: "mostLimit"
        },
        {
          title: "已使用次数",
          dataIndex: "useNum",
          key: "useNum"
        }
      ]
    };
  },
  watch: {
    id(nVal) {
      this.loadData(nVal);
      //获取可玩项目
      this.getPlayData();
    }
  },
  methods: {
    // 加载详情数据
    loadData(id) {
      this.loading = true;
      findOrderDetailProductDetail({ id })
        .then(res => {
          const weeks = {
            1: "星期一",
            2: "星期二",
            3: "星期三",
            4: "星期四",
            5: "星期五",
            6: "星期六",
            7: "星期七"
          };
          const months = {
            1: "一月",
            2: "二月",
            3: "三月",
            4: "四月",
            5: "五月",
            6: "六月",
            7: "七月",
            8: "八月",
            9: "九月",
            10: "十月",
            11: "十一月",
            12: "十二月"
          };
          this.detail = res;
          this.orderRefundInfo = res.orderRefundInfo;
          this.ticketPlayItemPackageConfigInfo =
            res.ticketPlayItemPackageConfigInfo || {};
          this.ticketConsumeTypePackageConfigInfList =
            res.ticketConsumeTypePackageConfigInfList || [];
          this.ticketConsumeTypePackageConfigInfList.map(item => {
            item.count = item.count / 100;
            item.refundRatio = item.refundRatio / 100;
          });
          this.ticketWriteOffConfigInfo = res.ticketWriteOffConfigInfo || {};

          // 处理可核销星期
          if (
            res.ticketWriteOffConfigInfo.validWeekType === 1 &&
            res.ticketWriteOffConfigInfo.validWeeks.length
          ) {
            let week = "";
            res.ticketWriteOffConfigInfo.validWeeks.forEach(item => {
              week += weeks[item];
              week += "，";
            });
            this.ticketWriteOffConfigInfo.validWeeks = week;
          } else {
            this.ticketWriteOffConfigInfo.validWeeks = "不限制";
          }
          // 处理可核销月份
          if (
            res.ticketWriteOffConfigInfo.validMonthsType === 1 &&
            res.ticketWriteOffConfigInfo.validMonths.length
          ) {
            let month = "";
            res.ticketWriteOffConfigInfo.validMonths.forEach(item => {
              month += months[item];
              month += "，";
            });
            this.ticketWriteOffConfigInfo.validMonths = month;
          } else {
            this.ticketWriteOffConfigInfo.validMonths = "不限制";
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getPlayData() {
      orderPackageusableProject({
        id: this.productId,
        orderDetailId: this.id,
        page: 1,
        size: 100
      })
        .then(({ records, relationProjectType }) => {
          this.playData = records;
          //1全部项目，2指定项目
          this.isLimitPlayData = relationProjectType === 2;
        })
        .catch(() => {});
    },
    // 关闭弹窗
    close() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style scoped></style>
