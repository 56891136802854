<template>
  <div class="flex-col flex-start wrapper">
    <!-- 头部 -->
    <panel-head :title="pageType === 'edit' ? '会员修改' : '会员办理'" />
    <!-- 主体 -->
    <div class="main mt-2x flex-center p-2x">
      <a-form-model
        class="rule-form"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        label-align="left"
      >
        <a-form-model-item label="会员手机号码" prop="mobile">
          <a-input
            v-model="ruleForm.mobile"
            placeholder="请输入手机号码"
            allow-clear
            type="tel"
          />
        </a-form-model-item>
        <a-form-model-item label="会员等级">
          <span>
            {{
              memberInfoOne.currentLevelName
                ? memberInfoOne.currentLevelName
                : defaultLevelName
            }}
          </span>
        </a-form-model-item>
        <a-form-model-item label="会员姓名" prop="name">
          <a-input
            v-model="ruleForm.name"
            placeholder="请输入姓名"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="身份证号码" prop="idCardNumber">
          <id-card-input
            v-model="ruleForm.idCardNumber"
            @readCard="readCard"
            @change="checkIdCardNumber"
            placeholder="请输入身份证号码"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="会员相片" prop="photo">
          <single-upload
            v-model="ruleForm.photo"
            @change="checkPhoto"
          ></single-upload>
        </a-form-model-item>
        <a-form-model-item label="会员性别" prop="sex">
          <a-radio-group v-model="ruleForm.sex">
            <a-radio :value="0"> 未知 </a-radio>
            <a-radio :value="1"> 女 </a-radio>
            <a-radio :value="2"> 男 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="会员生日" prop="birthday">
          <a-date-picker
            v-model="ruleForm.birthday"
            value-format="YYYY-MM-DD"
            class="res"
          />
        </a-form-model-item>
        <a-form-model-item label="会员住址">
          <select-address
            ref="myCascader"
            v-model="ruleForm.region"
            @selected="selected"
            :clear="false"
          ></select-address>
        </a-form-model-item>
        <a-form-model-item prop="address" :wrapper-col="{ offset: 6 }">
          <a-input
            v-model="ruleForm.address"
            placeholder="请输入会员详细地址"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="会员备注" prop="remark">
          <a-textarea
            v-model="ruleForm.remark"
            placeholder="请输入会员备注"
            :rows="4"
            allow-clear
          ></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        class="btn-lg mr-2x"
        size="large"
        type="primary"
        shape="round"
        ghost
        @click="$router.go(-1)"
        >返回</a-button
      >
      <a-button
        class="btn-lg"
        size="large"
        shape="round"
        type="primary"
        @click="editMember"
        >确定{{ pageType === "edit" ? "修改" : "办理" }}</a-button
      >
    </div>

    <!-- 设置密码弹窗 -->
    <a-modal
      v-model="showPassword"
      title="设置支付密码"
      ok-text="确认"
      cancel-text="取消"
      @ok="setPassword"
    >
      <a-form-item label="支付密码">
        <a-input-password
          v-model="payPassword"
          placeholder="请输入6位纯数字支付密码"
          :max-length="6"
          type="number"
        />
      </a-form-item>
    </a-modal>
  </div>
</template>
<script>
import PanelHead from "@/components/common/PanelHead";
import IdCardInput from "@/components/hardware/IdCardInput";
import SelectAddress from "@/components/select/SelectAddress";
import SingleUpload from "@/components/select/SingleUpload";
import { mapGetters, mapActions } from "vuex";
import {
  checkPhone,
  checkNormal,
  checkText,
  dateFormat,
  checkIdCard
} from "@/utils/global";
import { encrypt } from "@/utils/crypt-aes";
import {
  memberSaveOne,
  memberUpdate,
  memberInfoOne,
  levelList
} from "@/api/member.js"; // MS-会员中心接口
import { fileUploadBase64 } from "@/api/upload";
import { chinaRegionAddress } from "@/api/setting";

export default {
  name: "MemberEdit",
  components: {
    PanelHead,
    IdCardInput,
    SelectAddress,
    SingleUpload
  },
  data() {
    return {
      pageType: "", // 页面类型
      memberInfoOne: {}, // 会员详情
      levelList: [], // 会员等级列表
      defaultLevelName: "", // 默认的会员等级名称
      headers: {
        "Content-Type": "multipart/form-data",
        "open-d": localStorage.getItem("token")
      },
      selectAddrName: "", // 选择省市区后的名字
      payPassword: "", // 会员支付密码
      showPassword: false, // 显示设置支付密码框
      // 新增、编辑会员
      ruleForm: {
        mobile: "", // 会员手机号码
        name: "", // 会员姓名
        idCardNumber: "", // 身份证号码
        photo: "", // 会员相片
        sex: 1, // 会员性别，0未知 1女 2男
        birthday: undefined, // 会员生日
        region: [], // 省市区id
        provinceNumber: "", // 省份编号
        cityNumber: "", // 城市编号
        districtNumber: "", // 地区编号
        province: "", // 省份名字
        city: "", // 城市名字
        district: "", // 地区名字
        address: "", // 详细地址
        remark: "" // 备注
      },
      rules: {
        mobile: [
          { required: true, message: "请输入会员手机号码", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入会员姓名", trigger: "blur" },
          { validator: checkNormal, trigger: "blur" }
        ],
        idCardNumber: [
          {
            message: "请输入购票人身份证号码",
            trigger: "blur"
          },
          { validator: checkIdCard, trigger: "blur" }
        ],
        photo: [{ message: "请上传会员相片", trigger: "change" }],
        birthday: [{ message: "请选择会员生日", trigger: "change" }],
        address: [
          { message: "请输入会员详细地址", trigger: "blur" },
          { validator: checkNormal, trigger: "blur" }
        ],
        remark: [
          { message: "请输入会员备注", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  // 监听页面路由变化
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       if (route) {
  //         this.pageType = route.query.pageType;
  //         if (this.pageType === "add") {
  //           this.$refs.ruleForm.resetFields(); // 表单重置
  //           this.ruleForm.mobile = route.query.mobile; // 会员手机号码;
  //         }
  //         if (this.pageType === "edit") {
  //           this.getMemberInfoOne(); // 根据会员id查询会员信息
  //         }
  //       }
  //     }
  //   }
  // },
  created() {
    this.pageType = this.$route.query.pageType; // 页面类型
    this.ruleForm.mobile = this.$route.query.mobile; // 页面类型

    if (this.pageType === "add") {
      this.ruleForm.mobile = this.$route.query.mobile; // 会员手机号码;
    }
    if (this.pageType === "edit") {
      this.getMemberInfoOne(); // 根据会员id查询会员信息
    }

    this.getLevelList(); // 查询等级列表
  },
  methods: {
    ...mapActions("common", ["updateMember"]),

    // 重新校验身份证号码
    checkIdCardNumber() {
      this.$refs.ruleForm.validateField("idCardNumber"); // 对部分表单字段进行校验的方法
    },

    // 重新校验会员相片
    checkPhoto() {
      this.$refs.ruleForm.validateField("photo");
    },

    // 读取身份证信息
    readCard(data) {
      this.ruleForm.name = data.name; // 会员姓名
      this.ruleForm.idCardNumber = data.id; // 身份证号码
      this.ruleForm.birthday = data.birth; // 会员生日
      this.ruleForm.sex = data.sex === "未知" ? 0 : data.sex === "女" ? 1 : 2; // 会员性别，0未知 1女 2男
      this.ruleForm.address = data.address; // 详细地址

      // 会员相片
      fileUploadBase64({
        data: data.photo, // 数据
        ext: "png" // 图片后缀格式
      })
        .then(res => {
          this.ruleForm.photo = res.src;
        })
        .catch(() => {
          this.$message.error("图片上传接口错误");
        });

      this.getChinaRegionIds(); // 通过汉字地址获取省市区的id
    },

    // 通过汉字地址获取省市区的id
    getChinaRegionIds() {
      chinaRegionAddress({
        address: this.ruleForm.address // 模糊的地址
      })
        .then(res => {
          if (res.province && res.city && res.area) {
            this.ruleForm.region = [
              Number(res.province),
              Number(res.city),
              Number(res.area)
            ]; // 省市区id;
            // this.$refs.myCascader.getChregionList(); // 初始化省份数据
          }
        })
        .catch(() => {});
    },

    // 查询等级列表
    getLevelList() {
      levelList()
        .then(res => {
          this.levelList = res;
          // 找出默认的会员等级名称
          this.defaultLevelName = this.levelList.filter(item => {
            return item.systemDefault === 0;
          })[0]?.name;
        })
        .catch(() => {});
    },

    // 根据会员id查询会员信息
    getMemberInfoOne() {
      memberInfoOne({ id: this.memberId })
        .then(res => {
          this.memberInfoOne = res; // 会员详情
          this.ruleForm.mobile = res.mobile; // 会员手机号码
          this.ruleForm.name = res.name; // 会员姓名
          this.ruleForm.idCardNumber = res.idCardNumber; // 身份证号码
          this.ruleForm.photo = res.photo; // 会员相片
          this.ruleForm.sex = res.sex; // 会员性别，0未知 1女 2男
          this.ruleForm.birthday = dateFormat(res.birthday, "YYYY-MM-DD"); // 会员生日
          if (res.provinceNumber && res.cityNumber && res.districtNumber) {
            this.ruleForm.region = [
              res.provinceNumber,
              res.cityNumber,
              res.districtNumber
            ]; // 省市区id
          }
          this.ruleForm.provinceNumber = res.provinceNumber; // 省份编号
          this.ruleForm.cityNumber = res.cityNumber; // 城市编号
          this.ruleForm.districtNumber = res.districtNumber; // 地区编号
          this.ruleForm.province = res.province; // 省份名字
          this.ruleForm.city = res.city; // 城市名字
          this.ruleForm.district = res.district; // 地区名字
          this.ruleForm.address = res.address; // 详细地址
          this.ruleForm.remark = res.remark; // 备注
        })
        .catch(() => {});
    },

    // 新增、编辑会员
    editMember() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.pageType === "edit") {
            this.ruleForm.id = this.memberId;
            this.doSubmit("edit");
          } else {
            //如果是新增，则弹出设置消费支付密码
            this.showPassword = true;
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    //上传数据
    doSubmit(type) {
      let api = memberSaveOne;
      if (type === "edit") api = memberUpdate;
      // 会员来源： 0,窗口售票，1,小程序购票，2,自助机购票，3,手持机购票，4,公众号购票，5,OTA购票，6,后台添加，7,窗口端添加
      this.ruleForm.source = 7;
      const { key, encryptedWord } = encrypt(this.payPassword);
      let memberData;
      if (type === "edit") {
        memberData = this.ruleForm;
      } else {
        memberData = {
          ...this.ruleForm,
          memberPaymentPassword: encryptedWord,
          passwordKey: key
        };
      }
      api(memberData)
        .then(data => {
          this.updateMember(data);
          // this.$store.commit("common/setmemberId", data.id);
          // this.$router.push({
          //   path: "/home/member/MemberDetail"
          // });
          this.$router.go(-1);
        })
        .catch(() => {});
    },

    //设置支付密码
    setPassword() {
      let regex = /^\d{6}$/;
      if (!regex.test(this.payPassword)) {
        this.$message.warning("请输入6位数字密码");
      } else {
        this.doSubmit("add");
      }
    },

    // 选择省市区
    selected({ path, pathLabels }) {
      this.ruleForm.provinceNumber = path[0];
      this.ruleForm.cityNumber = path[1];
      this.ruleForm.districtNumber = path[2];
      this.ruleForm.province = pathLabels[0];
      this.ruleForm.city = pathLabels[1];
      this.ruleForm.district = pathLabels[2];
    }
  }
};
</script>
<style lang="less" scoped></style>
