<!--
  @name: 搜索组件,
  @author: chenshiti,
  @attr: {
    //搜索条件
    filters: {
      default: function() {
        return [];
      },
      type: Array
    },
    //是否展示重置按钮
    showReset: {
      default: true,
      type: Boolean
    },
    //激活tab 0时间 1单号
    tabActive: {
      default: 0,
      type: Number
    }
  },
  @methods{
    changeSearchTab： 切换药丸事件
    loadData: 查询按钮事件
    reset: 重置按钮事件
  }
-->
<template>
  <div class="p-2x bg-white">
    <a-row type="flex">
      <a-col :span="2">
        <div class="flex-around bd round pointer tab-box mt-x">
          <div
            @click="changeSearchTab(0)"
            class="flex-center flex-1 pv-x"
            v-bind:class="{ 'bg-primary': !tabActive }"
          >
            时间
          </div>
          <div
            @click="changeSearchTab(1)"
            class="flex-center flex-1 pv-x"
            v-bind:class="{ 'bg-primary': tabActive }"
          >
            单号
          </div>
        </div>
      </a-col>
      <a-col class="ml-2x">
        <!--筛选表单-->
        <a-form layout="inline" :model="filterForm" ref="ruleForm">
          <a-form-item
            v-for="(item, index) in filters"
            :key="index"
            :label="item.label"
            :name="item.key"
            class="pr-x"
          >
            <!-- 日期类型 -->
            <!--            <a-date-picker-->
            <!--              v-if="item.type === 'time'"-->
            <!--              v-model="filterForm[item.key]"-->
            <!--              type="datetimerange"-->
            <!--              range-separator="至"-->
            <!--              start-placeholder="开始日期"-->
            <!--              end-placeholder="结束日期"-->
            <!--              value-format="timestamp"-->
            <!--              :default-time="['00:00:00', '23:59:59']"-->
            <!--            >-->
            <!--            </a-date-picker>-->
            <a-range-picker
              v-if="item.type === 'time'"
              :allow-clear="true"
              v-model="filterForm[item.key]"
              class="input"
              @change="loadData"
              :ranges="pickerOptions()"
            />
            <!--            @change="dateChange($event, item.key)"-->

            <!-- <SelectDate
              v-if="item.type === 'time'"
              v-model="filterForm[item.key]"
              :showPicker="false"
              clearable
            ></SelectDate> -->
            <!-- input类型 -->
            <a-input
              v-else
              v-model="filterForm[item.key]"
              :placeholder="item.placeholder"
              :style="{ width: item.width }"
              :allow-clear="true"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button @click="loadData" type="primary" plain class="mr-2x"
              >查询</a-button
            >
            <a-button v-if="showReset" @click="reset" plain>重置</a-button>
          </a-form-item>
          <!--按钮插槽-->
          <a-form-item>
            <slot name="buttons"></slot>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import SelectDate from "@/components/select/SelectDate";
import moment from "moment";

export default {
  name: "SearchTool",
  // components: { SelectDate },
  props: {
    //搜索条件
    filters: {
      // 数据结构如  [{ label: "下单日期", prop: "playDate", type: "time", placeholder: "" }]
      default: function() {
        return [];
      },
      type: Array
    },
    //是否展示重置按钮
    showReset: {
      default: true,
      type: Boolean
    },
    //激活tab 0时间 1单号
    tabActive: {
      default: 0,
      type: Number
    }
  },
  watch: {
    filters(newValue) {
      if (newValue) {
        newValue.forEach(item => {
          if (item.type === "time" && item.value) {
            this.$set(this.filterForm, item.key, item.value);
          }
        });
      }
    }
  },
  data() {
    return {
      moment,
      filterForm: {} //搜索参数
    };
  },
  mounted() {},
  methods: {
    pickerOptions() {
      let dateTime = 3600 * 24 * 1000;
      let nowTime = new Date().getTime();
      let newDate = new Date();
      let nowDay = newDate.getDate() - 1;
      let nowDayOfWeek = newDate.getDay() == 0 ? 6 : newDate.getDay() - 1;
      return {
        今天: [moment(), moment()],
        昨天: [moment(nowTime - 1 * dateTime), moment(nowTime - 1 * dateTime)],
        近一周: [moment(nowTime - 7 * dateTime), moment()],
        近一月: [moment(nowTime - 30 * dateTime), moment()],
        本周: [
          moment(
            new Date(newDate.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)
          ),
          moment()
        ],
        本月: [
          moment(new Date(newDate.getTime() - nowDay * 24 * 60 * 60 * 1000)),
          moment()
        ]
      };
    },
    //切换药丸 0
    changeSearchTab(value) {
      this.$emit("changeSearchTab", value);
    },
    //查询
    loadData() {
      this.$emit("loadData", this.filterForm, this.tabActive);
    },
    //重置
    reset() {
      // location.reload();
      this.filterForm = {};
      this.$emit("loadData", this.filterForm, this.tabActive);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.input {
  width: 220px;
}
.tab-box {
  overflow: hidden;
  border: 1px solid @primary;
  > div {
    transition: all 0.4s;
  }
}
</style>
