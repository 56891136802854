import { render, staticRenderFns } from "./AccountsRecording.vue?vue&type=template&id=5ada2aad&scoped=true&"
import script from "./AccountsRecording.vue?vue&type=script&lang=js&"
export * from "./AccountsRecording.vue?vue&type=script&lang=js&"
import style0 from "./AccountsRecording.vue?vue&type=style&index=0&id=5ada2aad&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ada2aad",
  null
  
)

export default component.exports