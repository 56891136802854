<!-- 快捷取票（F5），填写出游人信息 -->
<template>
  <div class="flex-col flex-start wrapper">
    <!-- 头部 -->
    <Panelhead title="取票操作"></Panelhead>

    <section class="main mt-2x p-2x">
      <a-form-model
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
        ref="refTicketCollection"
      >
        <div v-for="(item, index) in dataList" :key="index">
          <a-form-model-item label="门票名称">
            <span>{{ item.productName }}</span>
          </a-form-model-item>
          <a-form-model-item label="主票票号">
            <a-row type="flex" justify="start">
              <a-col :span="6">
                {{ item.auxiliaryId }}
              </a-col>
              <a-col :span="16">
                <a-checkbox disabled v-model="form.check">
                  立即激活
                </a-checkbox>
              </a-col>
            </a-row>
          </a-form-model-item>
          <!-- 主票号，需要身份证及人脸的情况 -->
          <a-form-model-item
            v-if="!item.subTicketListInfo || item.subTicketListInfo.length <= 0"
            :colon="false"
            label=" "
            :label-col="labelCol"
          >
            <a-row
              type="flex"
              justify="start"
              v-if="item.byTicketId || item.bindIdCardNo"
            >
              <a-col :span="6"></a-col>
              <a-col :span="10">
                <IdCardInput v-model="item.idCardNumber"></IdCardInput>
              </a-col>
            </a-row>
            <!-- 卡腕带 -->
            <a-row
              type="flex"
              justify="start"
              v-if="!item.ifPayDeposit && item.productVoucherType != 0"
            >
              <a-col :span="6"></a-col>
              <a-col :span="10">
                <IcCardinput
                  placeholder="请输入卡/腕带编号"
                  v-model="item.voucherType"
                ></IcCardinput>
              </a-col>
            </a-row>
            <a-row
              class="mt-2x"
              type="flex"
              justify="start"
              v-if="item.byFaces || item.bindFaceURL"
            >
              <a-col :span="6"></a-col>
              <a-col :span="10">
                <FaceInput
                  @imgUrlChange="
                    res => {
                      item.faceUrl = res;
                    }
                  "
                  v-model="item.faceNumber"
                  :echoimgurl="item.bindFaceURL"
                ></FaceInput>
              </a-col>
            </a-row>
          </a-form-model-item>
          <!-- 子票号 -->
          <a-form-model-item
            v-if="item.subTicketListInfo && item.subTicketListInfo.length"
            label="子票票号"
          >
            <div
              v-for="(subItem, index) in item.subTicketListInfo"
              :key="index"
              class="mb-3x"
            >
              <!-- 身份证 -->
              <a-row type="flex" justify="start" v-if="item.byTicketId">
                <a-col :span="6">
                  <span v-if="item.byTicketId">
                    {{ subItem.auxiliaryId }}
                  </span>
                </a-col>
                <a-col :span="10" v-if="item.byTicketId">
                  <IdCardInput v-model="subItem.idCardNumber"></IdCardInput>
                </a-col>
              </a-row>
              <!-- 卡腕带 -->
              <a-row
                type="flex"
                justify="start"
                v-if="!item.ifPayDeposit && item.productVoucherType != 0"
              >
                <a-col :span="6">
                  <!-- 上面身份证里没显示、且为腕带,才在这列显示子票号 -->
                  <span v-if="!item.byTicketId && item.productVoucherType != 0">
                    {{ subItem.auxiliaryId }}
                  </span>
                </a-col>
                <a-col :span="10">
                  <IcCardinput
                    placeholder="请输入卡/腕带编号"
                    v-model="subItem.voucherType"
                  ></IcCardinput>
                </a-col>
              </a-row>
              <!-- 人脸 -->
              <a-row
                class="mt-x"
                type="flex"
                justify="start"
                v-if="item.byFaces"
              >
                <a-col :span="6">
                  <span v-if="!item.byTicketId && item.productVoucherType == 0">
                    {{ subItem.auxiliaryId }}
                  </span>
                </a-col>
                <a-col :span="10">
                  <FaceInput
                    @imgUrlChange="
                      res => {
                        subItem.faceUrl = res;
                      }
                    "
                    v-model="subItem.faceNumber"
                    :echoimgurl="subItem.bindFaceURL"
                  ></FaceInput>
                </a-col>
              </a-row>
              <!-- 不需身份证、人脸、腕带 -->
              <!-- <a-row
                class="mt-x"
                type="flex"
                justify="start"
                v-if="
                  !subItem.byFaces &&
                    !subItem.byTicketId &&
                    subItem.productVoucherType == 0
                "
              >
                <a-col :span="10">
                  <span>
                    {{ subItem.auxiliaryId }}
                </span>
              </a-col>
            </a-row> -->
            </div>
          </a-form-model-item>
          <a-divider />
        </div>
      </a-form-model>
    </section>
    <!-- 底部 -->
    <footer class="footer">
      <p class="flex-grow">应收：{{ receivable | money }}</p>
      <div>
        <a-button type="primary" shape="round" size="large" @click="onSubmit"
          >全部取票</a-button
        >
        <a-button
          size="large"
          shape="round"
          class="ml-3x"
          @click="$router.go(-1)"
          >返回</a-button
        >
      </div>
    </footer>
  </div>
</template>
<script>
import Panelhead from "../../../components/common/PanelHead.vue";
import IdCardInput from "../../../components/hardware/IdCardInput.vue";
import IcCardinput from "../../../components/hardware/IcCardinput.vue";
import FaceInput from "../../../components/hardware/FaceInput.vue";
import { ticketGroupPrint, ticketPrint } from "../../../api/order";
import { batchGetTicket } from "../../../api/cart";
import {
  orderAuxiliaryprintinfo,
  orderOrdinaryprintinfo,
  orderGroupprintinfo
} from "../../../api/search";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
export default {
  name: "WriteTouristMsg",
  components: {
    Panelhead,
    IdCardInput,
    FaceInput,
    IcCardinput
  },
  props: {},
  data() {
    return {
      receivable: 0, // 应收金额

      labelCol: { span: 2 },
      wrapperCol: { span: 16 },
      form: {
        check: true
      },
      rules: {},
      dataList: [], // 取票查询接口数据：需要填写的信息
      otherData: [], // 不需要填写身份证等信息的数据
      tabKey: "1", // 1:普通门票2：团体票
      keyword: "", // 快捷取票-查询页-输入的搜索字段
      isGroup: false // 是否为团体票
    };
  },
  created() {
    const data = this.$route.params.searchRes || [];
    this.receivable = this.$route.params.receivable || 0; // 押金
    this.otherData = this.$route.params.otherData || [];
    this.tabKey = this.$route.params.tabKey || "1";
    this.keyword = this.$route.params.keyword || "";
    this.isGroup = this.$route.params.isGroup;
    data.forEach(item => {
      item.idCardNumber = ""; // 主票号：身份证
      item.voucherType = ""; // 主票号：卡腕带
      item.faceNumber = ""; // 主票号：人脸
      item.faceUrl = ""; // 主票号：人脸url
      // 有子票号
      if (item.subTicketListInfo && item.subTicketListInfo.length) {
        item.subTicketListInfo.forEach(subItem => {
          subItem.idCardNumber = ""; // 子票号：身份证
          subItem.voucherType = ""; // 子票号：卡腕带
          subItem.faceNumber = ""; // 子票号：人脸
          subItem.faceUrl = ""; // 子票号：人脸url
        });
      }
    });
    this.dataList = data;
    console.log(
      "要填写信息的数据：",
      this.dataList,
      "+++++",
      "不需填写信息的数据:",
      this.otherData
    );
  },
  methods: {
    // 立即取票
    onSubmit() {
      let flag = false; // 用来跳出some循环，减少遍历次数
      // 判断是否信息，都录入完整
      this.dataList.some((item, index) => {
        console.log("some循环执行的次数+++++");
        // 有子票
        if (item.subTicketListInfo && item.subTicketListInfo.length) {
          item.subTicketListInfo.some(subItem => {
            flag = this.checkInfo(
              item,
              subItem.idCardNumber,
              subItem.voucherType,
              subItem.faceUrl,
              subItem.bindFaceURL,
              index
            );
            // 只要一种场景匹配到，跳出循环
            if (flag) {
              return true;
            }
          });
        } else {
          // 没有子票，只是主票
          flag = this.checkInfo(
            item,
            item.idCardNumber,
            item.voucherType,
            item.faceUrl,
            item.bindFaceURL,
            index
          );
        }
        // 只要一种场景匹配到，跳出循环
        if (flag) {
          return true;
        }
      });

      // 信息填写校验通过
      if (!flag) {
        // this.$message.warning("校验通过");
        // 合并要填写和不需要填写信息的数据，一起取票
        const allData = this.dataList.concat(this.otherData);
        this.getTickets(allData);
      }
    },
    /**
     * 校验身份证、腕带，人脸等信息填写完成
     * @param {item} : 每张门票的数据
     * @param {idCardNumber} : 填写的身份证信息
     * @param {voucherType} : 填写的卡腕带信息
     * @param {faceUrl} : 采集的人脸Url
     * @param {bindFaceURL} : 已采集过的人脸Url回显
     * @param {index} : 循环遍历的索引
     */
    checkInfo(item, idCardNumber, voucherType, faceUrl, bindFaceURL, index) {
      let msg = "";
      let flag = false;
      // 未录入身份证
      if (item.byTicketId && !idCardNumber) {
        msg = `第${index + 1}张票：【${item.productName}】，未录入身份证`;
        this.$message.warning(msg);
        flag = true;
        return flag;
      }
      // 未录入卡腕带
      if (!item.ifPayDeposit && item.productVoucherType != 0 && !voucherType) {
        msg = `第${index + 1}张票：【${item.productName}】，未录入卡腕带`;
        this.$message.warning(msg);
        flag = true;
        return flag;
      }
      // 未录入人脸
      if (item.byFaces) {
        if (!faceUrl && !bindFaceURL) {
          msg = `第${index + 1}张票：【${item.productName}】，未采集人脸`;
          this.$message.warning(msg);
          flag = true;
          return flag;
        }
      }
    },
    // 取票
    getTickets(arr) {
      const list = [];
      const sonList = [];

      // 若取票时有押金，则全部票都走下单接口
      if (this.receivable > 0) {
        this.placeOrder(arr);
        return;
      }

      // 团体票取票接口
      if (this.isGroup) {
        this.getGroupTickets(arr);
        return;
      }
      // 非团体票取票接口
      arr.forEach(item => {
        let isSub = item.subTicketListInfo && item.subTicketListInfo.length; // 是否为子票（一票多人）
        if (isSub) {
          item.subTicketListInfo.forEach(subItem => {
            sonList.push({
              id: subItem.id, // 门票订单详情记录id
              idCardNo: subItem.idCardNumber, // 子票号绑定的身份证号
              faceId: subItem.faceNumber // 子票号绑定的人脸id
            });
          });
        }
        list.push({
          ifActivateNow: true, // 是否立即激活
          id: item.id, // 门票订单详情记录id
          auxiliaryId: item.auxiliaryId || "", // 主票号或子票号
          idCardNo: item.idCardNumber, // 票号绑定的身份证号
          faceId: item.faceNumber, // 票号绑定的人脸id
          ticketPrintSubAuxiliaryInfoList: isSub ? sonList : [] // 子票绑定的身份证人脸信息
        });
      });
      console.log(arr, "/-------------------");
      // 取票接口
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: list
      })
        .then(() => {
          this.$message.success("取票完成");
          // 单张取票 - 打印
          if (arr && arr.length == 1) {
            this.printTicket(list[0].auxiliaryId);
          } else {
            // 多张票 - 打印
            this.printTicketAll();
          }
        })
        .catch(() => {});
    },
    // 团体票取票接口
    getGroupTickets(arr) {
      console.log("团体票取票+9+++++++", arr);
      const list = [];
      arr.forEach(item => {
        list.push(item.id);
      });
      ticketGroupPrint({
        channel: "", // 打印渠道,非必填，未提供时系统自动从登陆用户的channel中获取
        groupTicketIdList: list // 要打印的团体票记录ID列表
      })
        .then(() => {
          this.$message.success("取票完成");
          // 单张取票 - 打印
          if (arr && arr.length == 1) {
            this.printTicket(arr[0].auxiliaryId);
          } else {
            // 多张票 - 打印
            this.printTicketAll();
          }
        })
        .catch(() => {});
    },
    // 纸质门票 打印(单张取票打印)
    printTicket(id) {
      orderAuxiliaryprintinfo({ auxiliaryId: id })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
          this.backHome();
        })
        .catch(() => {});
    },
    // 纸质门票 打印(全部取票打印)
    printTicketAll() {
      let api = "";
      if (this.tabKey == 1) {
        // 普通门票打印接口
        api = orderOrdinaryprintinfo;
      } else {
        // 团体票打印接口
        api = orderGroupprintinfo;
      }
      api({
        keyword: this.keyword
      })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
          this.backHome();
        })
        .catch(() => {});
    },
    // 取票完成，返回首页提示
    backHome() {
      this.$confirm({
        title: "温馨提示",
        content: "取票完成，点击按钮返回",
        okText: "返回首页",
        cancelText: "继续取票",
        onOk: () => {
          this.$router.replace("/home");
        },
        onCancel: () => {
          this.$router.replace("/home/ticket/GetTicket");
        }
      });
    },
    // 取票有押金，走订单接口
    placeOrder(arr) {
      const list = [];
      const sonList = [];
      arr.forEach(item => {
        let isSub = item.subTicketListInfo && item.subTicketListInfo.length; // 是否为子票（一票多人）
        if (isSub) {
          item.subTicketListInfo.forEach(subItem => {
            sonList.push({
              id: subItem.id, // 门票订单详情记录id
              idCardNo: subItem.idCardNumber, // 子票号绑定的身份证号
              faceId: subItem.faceNumber, // 子票号绑定的人脸id
              ticketNo: "", // 票号
              faceUrl: subItem.faceUrl, // 人脸的url
              voucherNo: subItem.voucherType // 绑定的腕带
            });
          });
        }
        list.push({
          productId: item.productId, // 产品Id
          spuId: item.spuId, // spuId
          id: item.id, // 门票订单详情记录id
          ticketNo: item.auxiliaryId || "", // 主票号
          activateNow: this.form.check, // 是否立即激活
          idCardNo: item.idCardNumber, // 票号绑定的身份证号
          faceId: item.faceNumber, // 票号绑定的人脸id
          faceUrl: item.faceUrl, // 人脸的url
          voucherNo: item.voucherType, // 绑定的腕带
          ticketList: isSub ? sonList : [] // 子票绑定的身份证人脸信息
        });
      });
      batchGetTicket(list)
        .then(res => {
          this.$store.dispatch("order/setCartList", { id: res.id });
          // 跳转下单页面
          this.$router.push({ path: "/home/sell/OrderInfo" });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped></style>
