<template>
  <div class="ph-2x pb-2x">
    <p>
      <span :span="3" class="gray mr-4x">收取押金</span>
      <span :span="21">{{ info.totalAmount | money }}</span>
    </p>
    <p class="mt-2x">
      <span :span="3" class="gray mr-4x">押金状态</span>
      <span :span="21" class="primary">{{ info.statusText | money }}</span>
    </p>
    <p class="mt-2x">
      <span :span="3" class="gray mr-4x">已退押金</span>
      <span :span="21">{{ info.totalRefundAmount | money }}</span>
    </p>
    <p class="mt-2x">
      <span :span="3" class="gray mr-4x">押金余额</span>
      <span :span="21">{{ info.totalRemainAmount | money }} </span>
    </p>
  </div>
</template>

<script>
import { findTicketDepositInfo } from "../../../../api/order";

export default {
  name: "CardPledge",
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      findTicketDepositInfo({
        id: this.$route.query.id
      })
        .then(res => {
          this.info = res;
        })
        .catch(function() {});
    }
  }
};
</script>
