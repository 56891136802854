import { render, staticRenderFns } from "./TicketAccount.vue?vue&type=template&id=2faf3a78&scoped=true&"
import script from "./TicketAccount.vue?vue&type=script&lang=js&"
export * from "./TicketAccount.vue?vue&type=script&lang=js&"
import style0 from "./TicketAccount.vue?vue&type=style&index=0&id=2faf3a78&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faf3a78",
  null
  
)

export default component.exports