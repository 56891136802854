<template>
  <div
    class="member-refund-balance flex-col flex-start flex-grow m-1x bd round"
  >
    <!-- 头部 -->
    <panel-head title="退会员余额"></panel-head>
    <!-- 主体 -->
    <div class="main">
      <div class="member-info flex-start p-2x">
        <span class="font-l mr-4x">{{ memberInfoOne.name }}</span>
        <div class="member-grade mr-4x">
          <span class="iconfont icondengji"></span
          >{{ memberInfoOne.currentLevelName }}
        </div>
        <span class="mr-4x">手机号：{{ memberInfoOne.mobile }}</span>
        <span class="mr-4x"
          >余额：{{ memberInfoOne.buyBalance | money }}元</span
        >
        <!--        <span>赠送余额：{{ memberInfoOne.giftBalance | money }}元</span>-->
      </div>
      <keyboard-pro class="keyboard-pro" type="2" v-model="numbers" @ok="onOk">
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              class="prefix-input mb-2x"
              size="large"
              v-model="numbers"
              @pressEnter="confirm()"
              allow-clear
            >
              <span slot="prefix" class="primary">本次退款金额</span>
              <span slot="suffix" class="primary">
                <a-icon type="search" />
              </span>
            </a-input>
            <span>
              确定退款后，会员购买余额与会员赠送余额将清零，退款金额按充值方式原路退回
            </span>
          </a-form-model>
        </template>
      </keyboard-pro>
    </div>
  </div>
</template>
<script>
import { memberInfoOne } from "@/api/member.js"; // MS-会员中心接口
import PanelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "../../../components/common/KeyboardPro.vue";
import { mapGetters } from "vuex";
import { fix2Reg } from "../../../utils/global";
export default {
  name: "MemberRefundBalance",
  components: { PanelHead, KeyboardPro },
  data() {
    return {
      numbers: "", // 会员可退余额
      memberInfoOne: {} // 会员详情
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  activated() {
    this.getMemberInfoOne(); // 根据会员id查询会员详情
  },
  methods: {
    // 根据会员id查询会员详情
    getMemberInfoOne() {
      memberInfoOne({ id: this.memberId })
        .then(res => {
          this.memberInfoOne = res;
          this.numbers = String(this.memberInfoOne.balance);
        })
        .catch(() => {});
    },

    // 跳转到会员退余额列表
    goMemberRefundBalanceList() {
      this.$router.push({
        path: "/home/member/MemberRefundBalanceList",
        query: { refundBalance: this.numbers }
      });
    },

    // 确定退余额
    onOk() {
      // 先验证input值
      if (this.numbers === "") {
        this.$message.warning("请输入退款金额");
        return false;
      } else if (!fix2Reg.test(this.numbers)) {
        this.$message.warning("退款金额输入格式有误");
        return false;
      } else if (Number(this.numbers) > Number(this.memberInfoOne.balance)) {
        this.$message.warning("退款金额不能大于会员余额");
        return false;
      }
      this.goMemberRefundBalanceList();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.font-20px {
  font-size: 20px;
}
.member-refund-balance {
  .main {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .member-info {
      background: #ececec;
      align-items: center;
      .member-grade {
        color: #ff8219;
      }
    }
    .keyboard-pro {
      width: 600px;
      margin: 0 auto;
      padding: @3x;
      .keyboard-input-wrapper {
        /deep/.ant-input {
          padding-left: 150px;
        }
      }
    }
  }
}
</style>
