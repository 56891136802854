<!--废弃页面待删除-->
<template>
  <main>
    <section>
      <!-- 1产品 2子景区 3景区 -->
      <SellTicket v-if="appointmentSelection === 1" />
      <TicketHomePage
        v-else-if="appointmentSelection === 2 || appointmentSelection === 3"
      />
    </section>
  </main>
</template>
<script>
import TicketHomePage from "./ticketHomePage.vue";
import SellTicket from "./SellTicket.vue";
import { merchantSettingInfo } from "@/api/merchant";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
export default {
  components: { TicketHomePage, SellTicket },
  name: "ticketType",
  props: {},
  data() {
    return {
      appointmentSelection: "" //景区设置-分时预约
    };
  },
  created() {
    this.querymerchantSettingInfo();
  },
  methods: {
    querymerchantSettingInfo() {
      merchantSettingInfo().then(res => {
        // 如果后台景区设置有配置
        if (res && res.merchantSetting) {
          // 1">产品</ 2">子景区 3">景区</
          let orderRules = JSON.parse(res.merchantSetting).orderRules;
          let orderInformationCollectionInfo = "";
          orderRules.forEach(item => {
            if (item.orderInformationCollectionInfo.length > 0) {
              orderInformationCollectionInfo =
                item.orderInformationCollectionInfo;
            }
          });
          let appointmentSelection = JSON.parse(res.merchantSetting);
          this.appointmentSelection = appointmentSelection
            ? appointmentSelection.appointmentSelection
            : 1;
          sessionStorage.setItem(
            "appointmentSelection",
            this.appointmentSelection
          );
          // let a = appointmentSelection;
          //是否需要填游客信息
          sessionStorage.setItem(
            "orderInformationCollectionInfo",
            orderInformationCollectionInfo
              ? JSON.stringify(orderInformationCollectionInfo)
              : ""
          );
        } else {
          this.appointmentSelection = 1;
        }
      });
      HardwareService.initPrinter(); //初始化打印设置
    }
  }
};
</script>
