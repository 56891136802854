<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="退款详情"></panel-head>

    <div class="main mt-2x ph-2x pb-2x">
      <!--退款信息-->
      <div class="flex-between">
        <h3 class="title-mid">退款信息</h3>
        <!-- 支付方式为：支付宝/微信，并且退款状态为“退款失败”才显示按钮 -->
        <a-button
          v-if="
            basicInfoVO.refundStatus == 5 &&
              (refundInfoVO.refundWay == 2 || refundInfoVO.refundWay == 1)
          "
          class="mt-2x"
          @click="orderRefundRetry"
          type="primary"
        >
          退款重试
        </a-button>
      </div>
      <a-row>
        <a-col :span="12">
          <ul class="flex-start">
            <li class="gray">退款单号</li>
            <li>{{ basicInfoVO.id }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">提交时间</li>
            <li>
              {{ basicInfoVO.createTime | date("YYYY-MM-DD hh:mm:ss") }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">原订单号</li>
            <li>{{ basicInfoVO.orderId }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">会员</li>
            <li>
              <span>{{ memberInfo.auxiliaryId }}</span>
              <a-popover title="会员信息" width="200">
                <span class="ml-2x blue pointer">详情</span>
                <template slot="content">
                  <div class="gray">
                    <p>会员编号：{{ memberInfo.auxiliaryId }}</p>
                    <p class="mt-2x">会员姓名：{{ memberInfo.name }}</p>
                    <p class="mt-2x">手机号码：{{ memberInfo.mobile }}</p>
                    <p class="mt-2x">
                      身份证号码：{{ memberInfo.idCardNumber }}
                    </p>
                    <p class="mt-2x">会员性别：{{ gender[memberInfo.sex] }}</p>
                    <p class="mt-2x">
                      会员生日：{{ memberInfo.birthday | date }}
                    </p>
                    <p class="mt-2x">
                      会员等级：{{ memberInfo.currentLevelName }}
                    </p>
                  </div>
                </template>
              </a-popover>
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">操作员</li>
            <li>{{ basicInfoVO.refundAuditPersonName }}</li>
          </ul>
        </a-col>
        <a-col :span="12">
          <ul class="flex-start">
            <li class="gray">退款渠道</li>
            <li>{{ basicInfoVO.refundChannelsText }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款状态</li>
            <li class="primary">{{ basicInfoVO.refundStatusText }}</li>
          </ul>
          <ul class="flex-start mt-2x" v-if="basicInfoVO.refundFailMsg">
            <li class="gray">驳回原因</li>
            <li>{{ basicInfoVO.refundFailMsg }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款备注</li>
            <li>{{ basicInfoVO.remarks }}</li>
          </ul>
        </a-col>
      </a-row>

      <!--退款产品-->
      <h3 class="title-mid">退款产品</h3>
      <a-table
        :data-source="productInfoVOList"
        :pagination="false"
        :columns="columns"
        :rowKey="(record, index) => index"
      >
        <template slot="totalAmount" slot-scope="row">
          <div>
            {{ row.totalAmount | money }}
          </div>
        </template>
        <template slot="originalReceiveAmount" slot-scope="row">
          <div>
            {{ row.originalReceiveAmount | money }}
          </div>
        </template>
        <template slot="refundAmount" slot-scope="row">
          <div>
            {{ row.refundAmount | money }}
          </div>
        </template>
      </a-table>

      <!--退款核销信息-->
      <h3 class="title-mid" v-if="auditInfoVO">退款审核信息</h3>
      <a-row v-if="auditInfoVO">
        <a-col :span="12">
          <ul class="flex-start">
            <li class="gray">原收款总额</li>
            <li>{{ auditInfoVO.originalReceiveAmount | money }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">本次退款总额</li>
            <li>
              {{ auditInfoVO.refundAmount | money }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退会员余额</li>
            <li>
              {{ auditInfoVO.refundMemberBalanceAmount | money }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退积分抵扣</li>
            <li>
              {{ auditInfoVO.refundIntegralDeductionAmount | money }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退实收金额</li>
            <li>
              {{ auditInfoVO.refundCashAmount | money }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">原支付方式</li>
            <li>{{ auditInfoVO.refundWayText }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">原支付通道</li>
            <li>{{ auditInfoVO.refundWayText }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款申请人</li>
            <li>{{ auditInfoVO.refundApplyPersonName }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款提交时间</li>
            <li>
              {{ auditInfoVO.createTime | date("YYYY-MM-DD hh:mm:ss") }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款提交渠道</li>
            <li>
              {{ basicInfoVO.refundChannelsText }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">申请退款说明</li>
            <li>{{ auditInfoVO.remarks }}</li>
          </ul>
        </a-col>
        <a-col :span="12">
          <ul class="flex-start">
            <li class="gray">审核结果</li>
            <li>{{ auditInfoVO.refundAuditStatusText }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">审核备注</li>
            <li>{{ auditInfoVO.refundAuditRemarks }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款审核人</li>
            <li>{{ auditInfoVO.refundAuditPersonName }}</li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款审核时间</li>
            <li>
              {{ auditInfoVO.refundAuditTime | date("YYYY-MM-DD hh:mm:ss") }}
            </li>
          </ul>
          <ul class="flex-start mt-2x">
            <li class="gray">退款审核渠道</li>
            <li>{{ auditInfoVO.refundAuditChannelsText }}</li>
          </ul>
        </a-col>
      </a-row>

      <!--退款信息-->
      <template>
        <h2 class="title-mid">原收款总额</h2>
        <ul class="flex-start">
          <li :span="6" class="gray">本次退款总额</li>
          <li :span="14">{{ refundInfoVO.refundAmount | money }}</li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退会员余额</li>
          <li :span="14">
            {{ refundInfoVO.refundMemberBalanceAmount | money }}
          </li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退积分抵扣</li>
          <li :span="14">
            {{ refundInfoVO.refundIntegralDeductionAmount | money }}
          </li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退实收金额</li>
          <li :span="14">{{ refundInfoVO.refundCashAmount | money }}</li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退款方式</li>
          <li :span="14">{{ refundInfoVO.refundWayText }}</li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退款时间</li>
          <li :span="14">
            {{ refundInfoVO.refundCompleteTime | date("YYYY-MM-DD hh:mm:ss") }}
          </li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">退款通道</li>
          <li :span="14">{{ refundInfoVO.refundPasswayName }}</li>
        </ul>
        <ul class="flex-start mt-2x">
          <li :span="6" class="gray">外部退款号</li>
          <li :span="14">{{ refundInfoVO.refundOutsideCode }}</li>
        </ul>
        <!-- <ul class="flex-start mt-2x">
        <li :span="6" class="gray">退款状态</li>
        <li :span="14">
          <span class="primary">{{
          refundInfoVO.refundStatusRemark || "无"
        }}</span>
        <a-button type="text" class="blue ml-2x" @click="orderRefundRetry"
          >退款重试</a-button
        >
      </li>
    </ul> -->
        <!-- <ul class="flex-start mt-2x">
        <li :span="6" class="gray">状态描述</li>
        <li :span="14">{{ refundInfoVO.refundStatusRemark }}</li>
      </ul> -->
      </template>
    </div>
  </div>
</template>

<script>
import { memberInfoOne } from "@/api/member";
import { findOrderRefundDetailInfo, findOrderRefundRetry } from "@/api/order";
import PanelHead from "../../../components/common/PanelHead";
export default {
  name: "RefundDetail",
  components: {
    PanelHead
  },
  data() {
    return {
      columns: [
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName"
        },
        {
          title: "产品编号",
          dataIndex: "productId",
          key: "productId"
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryText",
          key: "productCategoryText"
        },
        {
          title: "退款状态",
          dataIndex: "refundStatusText",
          key: "refundStatusText"
        },
        {
          title: "退款数量",
          dataIndex: "refundNum",
          key: "refundNum"
        },
        {
          title: "原收款金额",
          // dataIndex: "originalReceiveAmount",
          key: "originalReceiveAmount",
          scopedSlots: { customRender: "originalReceiveAmount" }
        },
        {
          title: "本次退款金额",
          // dataIndex: "refundAmount",
          key: "refundAmount",
          scopedSlots: { customRender: "refundAmount" }
        }
      ],
      gender: {
        0: "未知",
        1: "女",
        2: "男"
      },
      detail: {
        id: "测试数据"
      },
      memberInfo: {}, // 会员信息
      orderId: this.$route.query.id, // 退款单id
      basicInfoVO: {}, // 退款信息
      productInfoVOList: [], // 退款产品列表
      auditInfoVO: {}, // 退款审核信息
      refundInfoVO: {} //退款信息
    };
  },
  created() {
    this.getDetail(); // 获取退款单详情
  },
  methods: {
    //退款重试
    orderRefundRetry() {
      findOrderRefundRetry({
        refundId: this.basicInfoVO.id
      }).then(res => {
        console.log(res);
      });
    },
    // 获取退款单详情
    getDetail() {
      findOrderRefundDetailInfo({
        id: this.orderId
      })
        .then(res => {
          this.basicInfoVO = res.basicInfoVO; // 获取退款信息
          this.productInfoVOList = res.productInfoVOList; // 获取退款产品列表
          this.auditInfoVO = res.auditInfoVO; // 获取退款审核信息
          this.refundInfoVO = res.refundInfoVO || {}; // 获取信息
          if (this.productInfoVOList.length > 0) {
            this.addCalcRow();
            // let calcObj = {
            //   refundStatusText: "合计",
            //   refundNum: 0,
            //   originalReceiveAmount: 0,
            //   refundAmount: 0
            // };
            // this.productInfoVOList.forEach(item => {
            //   calcObj.refundNum += item.refundNum;
            //   calcObj.originalReceiveAmount += item.originalReceiveAmount;
            //   calcObj.refundAmount += item.refundAmount;
            // });
            // this.productInfoVOList.push(calcObj);
          }

          // 获取会员详情  ---  有时候会没有会员id
          if (!this.basicInfoVO.purchaserMemberId) return;
          memberInfoOne({
            id: this.basicInfoVO.purchaserMemberId
          })
            .then(res => {
              this.memberInfo = res;
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 表格增加一行：合计
    addCalcRow() {
      this.productInfoVOList.push({
        refundStatusText: "合计",
        refundNum: this.calcRow("refundNum"),
        originalReceiveAmount: this.calcRow("originalReceiveAmount"),
        refundAmount: this.calcRow("refundAmount")
      });
    },

    // 表格求和
    calcRow(param) {
      return this.productInfoVOList.reduce((pre, cur) => {
        return pre + parseFloat(cur[param]);
      }, 0);
    }
  }
};
</script>

<style scoped>
.flex-start .gray {
  width: 100px;
}
</style>
