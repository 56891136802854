<!--
  @name: 票务-身份证匹配产品,
  @author: chenshiti,
  props: {
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "" // 子景区id
        };
      }
    }
  },
  @methods{
    changeCard：数据变动触发事件
  }
-->
<template>
  <div>
    <a-input-group compact style="display: flex;">
      <a-select :default-value="info.fieldType" v-model="info.fieldType">
        <a-select-option
          v-for="ite in cardTypes"
          :key="ite.fieldType"
          :value="ite.fieldType"
        >
          {{ ite.name }}
        </a-select-option>
      </a-select>
      <IdCardInput
        v-inputfocus
        v-model="info.idCard"
        @readCard="readCard"
        @pressEnter="changeCard"
      ></IdCardInput>
    </a-input-group>
  </div>
</template>
<script>
import IdCardInput from "@/components/hardware/IdCardInput";
import { isIdcard } from "@/utils/global";
export default {
  name: "ProductIdCard",
  components: { IdCardInput },
  data() {
    return {
      cardTypes: [
        {
          fieldType: 6,
          name: "身份证"
        }
      ], //凭证类型
      info: {
        fieldType: 6, //证件类型
        idCard: "", //身份证/证件号
        name: "", //姓名
        address: "", //广东 地址
        birth: "", //生日
        sex: "", //性别(2男，1女)
        id: "", //游客类型id
        sybSystemIdProductId: "", //匹配产品id
        systemId: "", //景区id
        touristCategoryName: "", //游客类型name
        cardUrl: "", // 证件照url
        personFaceUrl: "", //人脸url
        personId: "" // 人脸id
      }
    };
  },
  methods: {
    //触发事件
    changeCard() {
      //身份证查游客类型
      if (!this.info.idCard) {
        this.$message.warning("证件号为空！");
        return;
      }
      let err = isIdcard(this.info.idCard);
      if (err) {
        this.$message.warning(err);
        return;
      }
      this.$emit("changeCard", this.info);
    },
    readCard(res) {
      if (res) {
        //性别(2男，1女)
        if (res.sex === "男") {
          this.info.sex = "2";
        } else {
          this.info.sex = "1";
        }
        this.info = { ...this.info, ...res };
        this.changeCard();
        // this.queryTouristByIdCard(); // 因需求暂时注释该方法，先改为直接调用智勇购物车
      }
    }
  }
};
</script>

<style scoped lang="less">
@import "./ticket-order-common";
</style>
