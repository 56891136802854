<!--
  @name: 订单折扣 => 优惠券
-->
<template>
  <div class="relative">
    <a-button
      class="top-btn"
      icon="swap"
      @click="resetCheckcoupon('m')"
      v-show="!showMemberTab"
    >
      使用会员优惠券
    </a-button>
    <a-button
      class="top-btn"
      icon="swap"
      @click="resetCheckcoupon('d')"
      v-show="showMemberTab"
    >
      兑换优惠券
    </a-button>
    <!--兑换优惠券键盘-->
    <keyboard-pro
      class="keyword"
      v-model="code"
      @ok="checkCode"
      v-show="!showMemberTab && !tableData.length"
    >
      <template v-slot:input="{ confirm }">
        <a-form-model autocomplete="off">
          <a-input-search
            ref="fouceRef"
            size="large"
            placeholder="请扫码查询/输入优惠券编号兑换"
            v-model="code"
            allow-clear
            @pressEnter="confirm()"
          >
            <a-icon slot="prefix" type="search" class="primary" />
            <a-button slot="enterButton">
              <a-icon type="scan" class="primary" />
            </a-button>
          </a-input-search>
        </a-form-model>
      </template>
    </keyboard-pro>
    <!-- 使用会员优惠券键盘 -->
    <find-member
      v-if="showMemberTab && !tableData.length"
      @success="getActivityList"
    ></find-member>

    <!-- 查询到的优惠券数据 -->
    <div
      v-if="tableData && tableData.length"
      class="flex-start flex-wrap counp-wrap"
    >
      <div
        class="yhq-wrap flex-start align-center pointer"
        v-for="(item, index) in tableData"
        :key="index"
        :class="{
          disable: !item.isCouponsAvailable,
          act: curIndex === index
        }"
        @click="selectCoupon(item, index)"
      >
        <div class="left pl-2x">
          <p class="text-1 font-xl">{{ item.name }}</p>
          <p v-if="item.startDate" class="font-s gray mt-x">
            有效期：{{ item.startDate | date }} 至
            {{ item.endDate | date }}
          </p>
          <p v-if="item.remark" class="font-s text-1 gray mt-x">
            {{ item.remark }}
          </p>
        </div>
        <div
          class="right ml-2x font-xxl relative"
          :class="{
            disable: !item.isCouponsAvailable,
            act: curIndex === index
          }"
        >
          <span class="font-l uni-text">¥</span>
          <span class="ml-x mon-text">{{
            item.discountedPriceText | money("")
          }}</span>
        </div>
      </div>
    </div>

    <!-- 适用产品 弹窗 -->
    <!-- <a-modal
      v-model="showLookAppProducts"
      title="查看适用产品"
      :footer="null"
      :width="1000"
    >
      <LookAppProducts :merchantIds="merchantIds"></LookAppProducts>
    </a-modal> -->
  </div>
</template>

<script>
import FindMember from "../../../../components/member/FindMember.vue";
import { mapActions, mapGetters } from "vuex";
import {
  windowActivityDiscountedPrice,
  windowCouponActivityList,
  windowRedeemCoupons
  // getMarketingActivityById
} from "../../../../api/marketing";
import KeyboardPro from "../../../../components/common/KeyboardPro.vue";
// import LookAppProducts from "./LookAppProducts";

export default {
  name: "OrderYhq",
  props: {
    orderData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    FindMember,
    KeyboardPro
    // LookAppProducts
  },
  computed: {
    ...mapGetters("common", ["memberId"])
  },
  data() {
    return {
      tableData: [],
      showMemberTab: false,
      code: "", //优惠券码
      // showLookAppProducts: false, //查看适用产品
      // merchantIds: [], //商户id数组
      curIndex: "" // 当前选中的优惠券
    };
  },
  watch: {
    memberId: {
      handler: function(val) {
        //监听会员变化，重新获取优惠券数据
        if (val) {
          this.showMemberTab = true;
          this.getActivityList();
        }
      }
    }
  },
  created() {
    if (this.memberId) {
      this.showMemberTab = true;
      this.getActivityList();
    }
  },
  methods: {
    ...mapActions("order", ["removeOrderDiscountTextList"]),
    //获取可用优惠券
    getActivityList() {
      let list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      windowCouponActivityList({
        memberId: this.memberId,
        orderChannelId: 6, //6是窗口
        productItems: list
      })
        .then(res => {
          if (!res) {
            this.$message.warning("该会员没有可用优惠券");
            return;
          }
          this.tableData = res;
        })
        .catch(() => {});
    },
    // //查看适用产品
    // showAllProduct(id) {
    //   getMarketingActivityById({ id }).then(res => {
    //     this.yhInfo = res;
    //     this.merchantIds = res.applicableProducts.map(item => {
    //       return item.merchantId;
    //     });
    //     this.showLookAppProducts = true;
    //   });
    // },
    // 选择优惠券
    selectCoupon(row, index) {
      if (!row.isCouponsAvailable) {
        this.$message.warning("该优惠券不满足使用条件");
        return;
      }
      // 点击当前选中的，取消
      if (index === this.curIndex) {
        this.reset();
        return;
      }
      const list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      this.reset();
      windowActivityDiscountedPrice({
        memberId: this.memberId,
        orderChannel: 6, //6是窗口
        productItems: list,
        preferentialList: [
          {
            preferentialType: "C", //C是优惠券，A是窗口折扣，B是会员折扣,D是营销活动
            businessId: row.id //优惠券id
          }
        ]
      })
        .then(res => {
          if (res) {
            // 赋值选中状态
            this.curIndex = index;
            // 抛出给父元素，点击确定在执行，存储优惠到vuex
            this.$emit("triggerCalc", {
              name: "优惠券",
              amount: res.amount,
              merchantDiscountStr: "C",
              couponNo: row.couponCode,
              marketingId: row.id
            });
          } else {
            this.$message.warning("该优惠不能使用");
          }
        })
        .catch(() => {});
    },
    reset() {
      this.curIndex = "";
      this.removeOrderDiscountTextList("orderDiscount");
      this.$emit("triggerDel");
    },
    // 通过优惠券码，查询优惠券
    checkCode() {
      if (!this.code) {
        this.$message.warning("请先输入券码");
        return;
      }
      let list = this.orderData.productItemList.map(item => {
        return {
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        };
      });
      windowRedeemCoupons({
        couponCode: this.code,
        productItems: list
      })
        .then(res => {
          if (res) {
            this.tableData = res;
          }
        })
        .catch(() => {});
    },
    // 兑换优惠券 切换
    resetCheckcoupon(type) {
      this.tableData = [];
      this.reset();
      if (type == "m") {
        this.showMemberTab = true;
      } else {
        this.showMemberTab = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/*键盘  */
.keyword {
  margin: 16px auto;
}

.top-btn {
  width: 190px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border: 1px solid #f8724a;
  border-radius: 25px;
  position: absolute;
  right: 10px;
  top: -60px;
  color: #f8724a;
}

.counp-wrap {
  max-height: 340px;
  overflow-y: auto;
  overflow-x: hidden;
}

.yhq-wrap {
  width: 350px;
  height: 96px;
  margin: 0 23px 25px 0;
  background: url("../../../../assets/images/yhq.png") no-repeat center center;
  background-size: cover;
  &.act {
    background: url("../../../../assets/images/yhq_act.png") no-repeat center
      center;
    color: #fff;
    .gray {
      color: #fff;
    }
  }
  &.disable {
    background: url("../../../../assets/images/yhq_disable.png") no-repeat
      center center;
    color: #999;
  }
  .left {
    width: 230px;
  }
  .right {
    color: #f87a4b;
    &.disable {
      color: #999;
    }
    &.act {
      color: #fff;
    }
    .uni-text {
      position: absolute;
      left: 0;
      top: 6px;
    }
    .mon-text {
      margin-left: 10px;
    }
  }
}
</style>
