<template>
  <div class="date-box">
    <div>
      <div class="font-xl colors bold">选择景区</div>
      <!-- 景区选择 -->
      <div class="flex-between flex-wrap date font-xl">
        <a-button
          class="btn text-1 font-x"
          :class="navIndex == index ? 'active_date' : ''"
          v-for="(item, index) in navData"
          :key="index"
          :disabled="navIndex == index"
          @click="navClick(item, index)"
          >{{ item.subScenicName }}</a-button
        >
      </div>
    </div>
    <!-- 日期列表选择 -->
    <div>
      <div class="font-xl  colors bold">选择日期</div>
      <div class="flex-between flex-wrap date">
        <a-button
          v-for="(date, index) in dateData"
          :key="index"
          class="btn"
          :class="dateIndex == index ? 'active_date' : ''"
          @click="dateClick(index)"
          >{{ date | date }}</a-button
        >
      </div>
      <!-- 更多景区 -->
      <!-- <div class="text-c more">更多景区</div> -->
    </div>
    <!-- 时段列表选择 -->
    <div>
      <div class="font-xl colors bold">选择时段</div>
      <div
        v-if="timeData && timeData.length"
        class="flex-between flex-wrap date"
      >
        <a-button
          :disabled="item.disabled"
          v-for="(item, index) in timeData"
          :key="index"
          @click="timeClick(item, index)"
          class="btn text-2"
          :class="timeIndex == index ? 'active_date' : ''"
          style="height: 46px;font-size: 12px;"
        >
          <div>
            {{ item.beginTime | date("hh:mm") }}
            -
            {{ item.endTime | date("hh:mm") }}
          </div>
          <div>余票：{{ item.remainingStock }}</div></a-button
        >
      </div>
      <p v-else class="disabled m-2x">—— 暂无数据 ——</p>
    </div>
  </div>
</template>

<script>
import { subScenics } from "../../../api/merchant";
import { appointmentStockDate } from "../../../api/product";
import { mapGetters } from "vuex";

export default {
  name: "DateList",

  components: {},

  props: {},

  data() {
    return {
      navIndex: 0, // 当前选中的景区
      navData: [], // 景区数据
      dateIndex: 0, // 当前选中的日期
      timeIndex: 0, // 当前选中的 时间段
      dateData: [], // 日期数据
      timeData: [], // 时段数据
      // 获取时段接口参数
      params: {
        subScenicId: "", // 子景区id
        subScenicName: "", // 子景区名称
        date: "" // 日期
      },
      newTime: ""
    };
  },

  computed: {
    ...mapGetters("setting", ["getshortcutKey"])
  },

  created() {
    this.getSubScenic();
    this.newTime = Date.now();
  },

  beforeDestroy() {
    this.clearInterval();
  },

  methods: {
    // 获取
    getSubScenic() {
      // 窗口端控制 filterSubScenic
      subScenics({ filterSubScenic: true })
        .then(res => {
          if (res) {
            this.navData = res.subScenics || [];
            this.params.subScenicId = this.navData[0].subScenicId;
            this.params.subScenicName = this.navData[0].subScenicName;
            // 生成日期
            this.genarateDate(4);
          }
        })
        .catch(() => {});
    },
    // 生成日期(返回时间戳数组): 获取当天到len天，连续的日期
    genarateDate(len) {
      const today = new Date();
      for (let i = 0; i < len; i++) {
        this.dateData.push(today.getTime() + 1000 * 60 * 60 * 24 * i);
      }
      this.params.date = this.dateData[0];
      // 根据景区和日期，获取时间段
      this.getTimeSlot();
    },
    // 获取时段
    getTimeSlot() {
      appointmentStockDate(this.params)
        .then(res => {
          this.clearInterval();
          this.timeData = res.filter(item => {
            //300000 提前5分钟
            item.disabled =
              item.endTime - new Date().getTime() <= 300000 &&
              this.dateIndex === 0;
            return item.remainingStock > 0;
          });
          // 如果没有可用时段，则清空购物车
          if (this.timeData.length === 0) {
            this.$emit("clear");
            return;
          }
          const num = this.timeData.findIndex(item => !item.disabled);
          this.timeIndex = num;
          if (num !== -1) {
            this.$emit(
              "hand-time",
              this.params.subScenicId,
              this.params.subScenicName,
              this.dateData[this.dateIndex],
              this.timeData[num]
            );
          }
          this.timer = setInterval(() => {
            this.handleTimeData();
          }, 60000);
        })
        .catch(() => {});
    },
    clearInterval() {
      clearInterval(this.timer);
      this.timer = null;
    },
    //处理时段 提前5分钟禁用且跳到下一个时段
    handleTimeData() {
      this.timeData = this.timeData.map(item => {
        //300000 提前5分钟
        item.disabled =
          item.endTime - new Date().getTime() <= 300000 && this.dateIndex === 0;
        return item;
      });
      let num = this.timeData.findIndex(item => !item.disabled);
      // 当前选中未禁用就不用激活其它的
      if (!this.timeData[this.timeIndex].disabled) {
        num = this.timeIndex;
      }
      this.timeIndex = num;
      if (num !== -1) {
        this.$emit("changeTimeData", this.timeData[num]);
      }
    },
    // 选择景区
    navClick(item, i) {
      this.navIndex = i;
      this.timeIndex = "";
      this.dateIndex = 0;
      this.params.date = this.dateData[0];
      this.params.subScenicId = this.navData[i].subScenicId;
      this.params.subScenicName = this.navData[i].subScenicName;
      this.timeData = [];
      this.getTimeSlot();
    },
    // 选择日期
    dateClick(i) {
      this.dateIndex = i;
      this.params.date = this.dateData[i];
      this.getTimeSlot();
    },
    // 选择时段
    timeClick(timeData, index) {
      this.timeIndex = index;
      if (timeData.disabled) {
        this.$message.error("该时段不在可选范围");
        return;
      }
      // params: [ 事件， 子景区，日期， 时段]
      this.$emit(
        "hand-time",
        this.params.subScenicId,
        this.params.subScenicName,
        this.dateData[this.dateIndex],
        timeData
      );
    }
  }
};
</script>

<style lang="less">
.colors {
  color: #1b1b1b;
}
.date-box {
  height: 100%;
  border-right: 1px solid #ebebeb;
  padding: 16px;
  overflow-y: auto;
  .date {
    margin-top: 10px;
    .btn {
      // width: 110px;
      height: 35px;
      border-radius: 25px;
      font-size: 12px;
      color: #1b1b1b;
      margin: 0 23px 18px 0;
    }
    .active_date {
      background-color: #3f5ffc;
      color: #fff;
    }
  }
  .more {
    font-size: 22px;
    color: #3f5efc;
    margin-top: 11px;
  }
}
</style>
