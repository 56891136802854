<!--
  @name: 订单表格组件 + 退单
  props: tableData - 订单表格数据
-->
<template>
  <div ref="tables">
    <!--列表-->
    <!-- :scroll="{ x: 1366固定 ,y:动态设置表格体的可视高度}" -->
    <a-table
      :scroll="{ x: 1366, y: tableHeight }"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      rowKey="id"
    >
      <template slot="createTime" slot-scope="{ createTime }">{{
        createTime | date("YYYY-MM-DD hh:mm:ss")
      }}</template>
      <span
        class="primary"
        slot="orderStatusText"
        slot-scope="{ orderStatusText }"
        >{{ orderStatusText }}</span
      >
      <template slot="noRefundAmount" slot-scope="{ noRefundAmount }">{{
        noRefundAmount | money
      }}</template>
      <template slot="totalRefundAmount" slot-scope="{ totalRefundAmount }">{{
        totalRefundAmount | money
      }}</template>
      <template slot="actualAmount" slot-scope="{ actualAmount }">{{
        actualAmount | money
      }}</template>
      <template slot="action" slot-scope="row">
        <!--1、只有订单状态为“已支付”-->
        <!--2、业务类型为“会员特权”、“补办”、“自定义收款”的订单不显示退单按钮-->
        <a-button
          v-permission="'appOrderOrderListTicketcollection'"
          :disabled="
            row.orderStatus !== 3 ||
              row.orderBusinessType === 5 ||
              row.orderBusinessType === 7
          "
          type="link"
          class="p-0 mr-1x"
          @click="printTicketOne(row)"
          >取票</a-button
        >
        <a-button
          v-permission="'appOrderOrderListChargeback'"
          type="link"
          class="p-0 mr-1x"
          @click="refund(row)"
          :disabled="
            row.orderStatus !== 3 ||
              row.orderBusinessType === 5 ||
              row.orderBusinessType === 7
          "
          >退单</a-button
        >

        <a-button
          v-permission="'appOrderOrderListdetails'"
          type="link"
          class="p-0 mr-1x"
          @click="lintTo(row)"
          >详情</a-button
        >
        <a-button
          v-if="row.orderStatus == 13"
          type="link"
          class="p-0 mr-1x"
          @click="settleAccounts(row)"
          >清账</a-button
        >
        <!-- whetherInvoice是否开发票: 0 否  1 是 -->
        <a-button
          v-if="
            row.whetherInvoice === 1 &&
              row.orderStatus !== 1 &&
              row.orderStatus !== 7 &&
              row.orderStatus !== 8
          "
          type="link"
          class="p-0"
          @click="checkInvoice(row)"
          >开发票</a-button
        >
        <!--redInvoice 是否开红字发票：0 否  1 是 -->
        <!-- 红冲隐藏只能后台开 @click="checkRedInvoice(row)" -->
        <a-button
          type="link"
          v-if="
            row.redInvoice === 1 &&
              row.orderStatus !== 1 &&
              row.orderStatus !== 7 &&
              row.orderStatus !== 8
          "
          class="p-0 red"
          >已开发票</a-button
        >
        <a-button
          v-permission="'appOrderOrderListDownloadvoucher'"
          type="link"
          class="p-0"
          @click="download(row)"
          >下载凭证</a-button
        >
      </template>
    </a-table>

    <!--退单、退款、退票-->
    <order-refund
      :order-id="orderId"
      :visible.sync="visible"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="1"
      @on-ok="refunded"
    ></order-refund>
    <!-- 开发票弹窗 -->
    <a-modal
      :visible="showInvoice"
      title="开发票"
      width="650px"
      :footer="null"
      @cancel="showInvoice = false"
      :mask-closable="false"
    >
      <a-form-model
        :model="invoiceForm"
        :rules="invoiceRules"
        ref="ruleForm"
        label-width="110px"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        label-align="right"
      >
        <a-form-model-item label="发票抬头" prop="gmfMc">
          <a-input v-model="invoiceForm.gmfMc" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="单位税号" prop="gmfNsrsbh">
          <a-input v-model="invoiceForm.gmfNsrsbh" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="单位地址" prop="companyAddress">
          <a-input v-model="invoiceForm.companyAddress" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="电话号码" prop="telephone">
          <a-input v-model="invoiceForm.telephone" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="开户账号" prop="bankAccount">
          <a-input v-model="invoiceForm.bankAccount" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="开户银行" prop="bankName">
          <a-input v-model="invoiceForm.bankName" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发票金额" prop="totalAmount">
          <div>{{ invoiceForm.totalAmount | money }}</div>
        </a-form-model-item>
        <a-form-model-item label="接收方手机号" prop="receivePhone">
          <a-input v-model="invoiceForm.receivePhone" class="input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="接收邮箱地址" prop="receiveMail">
          <a-input v-model="invoiceForm.receiveMail" class="input"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div class="flex-center mt-2x">
        <a-button size="large" @click="showInvoice = false">取消</a-button>
        <a-button type="primary ml-4x" size="large" @click="saveInvoice">
          确定
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import OrderRefund from "./OrderRefund";
// import { orderDocuments } from "@/api/order";
import {
  orderOrdinarytaketicket,
  orderCombinationprintinfo,
  orderCheckinvoice,
  orderIssueblueinvoice,
  orderIssueredinvoice
} from "@/api/search";
import qs from "qs";
import { mapActions } from "vuex";
import HardwareService from "@/common/domains/hardware-domain/hardwareService";
import { orderPrint } from "@/api/order";
const columns = [
  {
    title: "订单编号",
    dataIndex: "id",
    key: "id",
    width: 182
  },
  {
    title: "产品业态",
    dataIndex: "productFormsName",
    key: "productFormsName",
    width: 120
  },
  {
    title: "所属商户",
    dataIndex: "ownerMerchants",
    key: "ownerMerchants",
    width: 120
  },
  {
    title: "销售员",
    dataIndex: "salespersonName",
    key: "salespersonName",
    width: 120
  },
  {
    title: "业务类型",
    dataIndex: "orderBusinessTypeText",
    key: "orderBusinessTypeText",
    width: 120
  },
  {
    title: "下单时间",
    key: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: 170
  },
  {
    title: "支付金额",
    key: "actualAmount",
    scopedSlots: { customRender: "actualAmount" },
    width: 120
  },
  {
    title: "已退金额",
    key: "totalRefundAmount",
    scopedSlots: { customRender: "totalRefundAmount" },
    width: 120
  },
  {
    title: "剩余金额",
    key: "noRefundAmount",
    scopedSlots: { customRender: "noRefundAmount" },
    width: 120
  },
  {
    title: "优惠活动",
    key: "specialActivity",
    dataIndex: "specialActivity",
    width: 120
  },
  {
    title: "支付方式",
    dataIndex: "payWayName",
    key: "payWayName",
    width: 120
  },
  {
    title: "支付通道",
    dataIndex: "payChannels",
    key: "payChannels",
    width: 120
  },
  {
    title: "销售渠道",
    dataIndex: "buyChannelsText",
    key: "buyChannelsText",
    width: 120
  },
  {
    title: "会员手机号",
    key: "purchaserMemberPhone",
    dataIndex: "purchaserMemberPhone",
    width: 120
  },
  {
    title: "团体类型",
    key: "groupType",
    dataIndex: "groupType",
    width: 120
  },
  {
    title: "导游手机号",
    key: "guidePhone",
    dataIndex: "guidePhone",
    width: 120
  },
  {
    title: "订单状态",
    scopedSlots: { customRender: "orderStatusText" },
    key: "orderStatusText",
    width: 100
  },
  {
    title: "订单备注",
    key: "remarks",
    dataIndex: "remarks",
    width: 150
  },
  {
    title: "外部订单号",
    key: "outsideCode",
    dataIndex: "outsideCode",
    width: 150
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 245
  }
];

export default {
  name: "OrderListTable",
  components: { OrderRefund },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      }
    }
    // // 订单类型 1 个人订单, 2商户订单
    // orderType: {
    //   type: Number,
    //   default: 1
    // }
    // // 是否为清账场景
    // isSettleAccounts: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      //实时屏幕高度
      tableHeight: this.$tableHeight(64, 64, 54, 36, 64, 50),
      columns,
      // 退单退款
      visible: false,
      // 退款信息
      refundList: [], // 退款记录
      orderId: "", // 退款订单编号
      refundIdType: "", // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      productName: "", // 退款产品名称
      productCategoryId: "", // 产品业务类型id
      fullPath: "", //路由地址
      showInvoice: false, //发票弹窗
      invoiceForm: {
        gmfNsrsbh: "", //购买方纳税人识别号
        gmfMc: "", //购买方名称
        telephone: "", //购买方电话 (0433-2820087)
        bankName: "", //购买方银行账号名称 (交通银行延边分行营业部)
        bankAccount: "", //购买方银行账号 (271211601018150210329)
        companyAddress: "", //购买方地址 (延吉市春辉街3-9号全季酒店后边 )
        receivePhone: "", //购买方接收手机号
        receiveMail: "" //购买方接收邮箱
      },
      invoiceRules: {
        gmfMc: [{ required: true, message: "请输入发票抬头", trigger: "blur" }],
        gmfNsrsbh: [{ required: true, message: "请输入税号", trigger: "blur" }],
        receivePhone: [
          { required: true, message: "请输入手机号", trigger: "blur" }
        ],
        receiveMail: [
          { required: true, message: "请输入邮箱", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.fullPath = this.$route.fullPath;
    this.tableHeight =
      this.fullPath === "/home/order/OrderList"
        ? this.$tableHeight(64, 64, 54, 36, 64, 50)
        : this.$tableHeight(64, 88, 72, 64, 64, 64);
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    //开蓝字发票
    saveInvoice() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          orderIssueblueinvoice(this.invoiceForm)
            .then(() => {
              this.$message.success("发票开具成功，请到您的邮箱查看");
              this.showInvoice = false;
              this.$emit("loadData");
            })
            .catch(error => {
              this.$message.warning(
                error.message ? error.message : "发票开具失败，请您稍后重试"
              );
            });
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },
    //红字发票
    checkRedInvoice(row) {
      this.$confirm({
        title: "提示",
        content: "确定要红冲发票吗？",
        onOk: () => {
          orderIssueredinvoice({ orderId: row.id }).then(() => {
            this.$message.success("已红冲");
            this.$emit("loadData");
          });
        }
      });
    },
    //检查是否可开票发票
    checkInvoice(temp) {
      if (temp.id && temp.actualAmount) {
        this.invoiceForm.orderId = temp.id;
        this.invoiceForm.totalAmount = temp.noRefundAmount;
        orderCheckinvoice({ orderId: temp.id })
          .then(res => {
            // 是否能开发票 1：能  2:不能
            if (res === 1) {
              this.showInvoice = true;
            } else {
              this.$message.warning("该订单暂不允许开票！");
            }
          })
          .catch(() => {});
      } else {
        this.$message.warning("请稍后重试");
      }
    },
    //取票
    printTicketOne(row) {
      // 新版（业态：16会员 6票务 7游乐 8剧院 9酒店 10餐饮 11零售 12租赁 13组合）
      if (row.productForms && row.productForms == 13) {
        //组合产品取票
        orderCombinationprintinfo({ orderId: row.id }).then(res => {
          // alert(JSON.stringify(res));
          this.print(row.id, res);
        });
      } else {
        orderOrdinarytaketicket({ orderId: row.id }).then(res => {
          this.print(row.id, res);
        });
      }
    },
    print(orderId, res) {
      HardwareService.printTicket(JSON.stringify(res));
      //如果成功调用打印门票，就掉接口通知后端已经取票
      orderPrint({
        channel: 6, //6是窗口端
        orderId: orderId
      })
        .then(() => {})
        .catch(() => {});
    },
    lintTo(row) {
      this.$router.push({
        path: "/home/order/OrderDetail",
        query: {
          orderId: row.id,
          fullPath: this.fullPath
        }
      });
    },
    // 退单退款退票
    refund(row) {
      this.productName = row.productName;
      this.productCategoryId = row.productCategoryId;
      this.orderId = row.id;
      this.visible = true;
    },
    // 退款提交成功
    refunded() {
      this.$emit("refunded", true);
    },
    // 清账
    settleAccounts(row) {
      this.setEndOfPayConfig({
        backUrl: "/home/order/OrderList",
        logoutMember: true
      });
      this.$router.push({
        path: "/newPay",
        query: {
          orderNumber: row.id, // 订单id
          totalFee: row.actualAmount, // 总价
          ignorePay: "3" // 不显示哪种支付方式，逗号隔开（1扫码支付2线下支付3挂账支付4抵扣支付）
          // formatId: res.productCategoryId // 业态id
        }
      });
    },
    //下载凭证
    download(row) {
      let param = {
        orderId: row.id,
        channel: 20
      };
      window.open(
        `/api/order/order/documents?${qs.stringify(param)}`,
        "_blank"
      );
    }
  }
};
</script>

<style scoped>
.input {
  width: 450px;
}
</style>
