<template>
  <div>
    <h3 class="title-mid" v-if="isTrue">产品列表</h3>
    <!--门票列表-->
    <div class="section" v-if="isTrue">
      <ul v-if="productList.length" class="list">
        <li class="item" v-for="item in productList" :key="item.id">
          <div class="content">
            <img
              :src="
                item.skuImages ||
                  'https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16279/1627956072463.png'
              "
              alt=""
            />
            <div class="right">
              <p>{{ item.name }}</p>
              <a-tag v-if="item.merchantName" class="blue">{{
                item.merchantName
              }}</a-tag>
              <div class="flex-between">
                <div class="price font-xl">{{ item.sellingPrice | money }}</div>
                <a-icon
                  type="plus-circle"
                  theme="filled"
                  class="plus"
                  @click="updateCart(item)"
                />
                <!--<div class="flex-start">-->
                <!--  <a-icon-->
                <!--    type="minus-circle"-->
                <!--    class="minus"-->
                <!--    v-show="item.buyNum > 0"-->
                <!--  />-->
                <!--  <a-icon-->
                <!--    v-show="item.buyNum === 0"-->
                <!--    type="minus-circle"-->
                <!--    class="minus-disabled"-->
                <!--  />-->
                <!--  <input-->
                <!--    v-inputfocus-->
                <!--    :value="item.buyNum"-->
                <!--    class="input-number"-->
                <!--    onkeyup="this.value=this.value.replace(/\D/g,'')"-->
                <!--    @click="updateCart(item, item.buyNum + 1)"-->
                <!--  />-->

                <!--</div>-->
              </div>
            </div>
          </div>
        </li>
      </ul>
      <a-empty v-else :style="{ height: '130px' }" />
    </div>
    <!-- 游客信息 -->
    <div class="flex-between mv-1x mt-3x">
      <h3 class="title-mid" style="margin: 10px 0 16px 0">游客信息</h3>
      <div class="flex-start">
        <AutoIdCardInput
          :is-cart-verify="true"
          :current-card-id.sync="currentCardId"
          ref="autoIdCard"
          class="productIdCard mr-2x"
          v-inputfocus
          v-model="autoIdCard"
          @readCard="changeCard"
          @pressEnter="changeCard({ id: autoIdCard })"
        ></AutoIdCardInput>
      </div>
    </div>
    <!-- 游客信息汇总table -->
    <a-table
      ref="touristSumtable"
      size="small"
      :columns="touristColumns"
      :data-source="touristSumData"
      :pagination="false"
      row-key="touristCategoryId"
      :scroll="{ x: 1000, y: 800 }"
    >
      <!-- 游客类型 -->
      <template slot="touristCategoryName" slot-scope="text, scope, index">
        <!-- {{ scope.touristCategoryName }} -->
        <a-select
          :default-value="scope.touristCategoryId"
          v-model="scope.touristCategoryId"
          @change="switchSumTouristData(scope.touristCategoryId, index, scope)"
          class="res"
          :disabled="scope.isSale || scope.touristDisabled"
        >
          <a-select-option
            v-for="item in scope.touristType"
            :key="item.id"
            :value="item.id"
          >
            {{ item.touristCategoryName }}
          </a-select-option>
        </a-select>
      </template>
      <!-- 匹配产品 -->
      <template slot="name" slot-scope="text, scope, index">
        <!-- {{ scope.name }} -->
        <a-select
          :default-value="scope.sybSystemIdProductId"
          v-model="scope.sybSystemIdProductId"
          @change="switchSumTouristProData(scope.sybSystemIdProductId, index)"
          class="res"
          :disabled="scope.touristDisabled"
        >
          <a-select-option
            v-for="item in scope.subscenicProductList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <!-- 单价 -->
      <template slot="sellingPrice" slot-scope="scope"
        >{{ scope.sellingPrice || 0 | money }}
      </template>
      <!-- 手机号 -->
      <template slot="phone" slot-scope="scope">
        <a-input @blur="checkPhone(scope)" v-model="scope.phone"></a-input>
      </template>
      <!-- 身份证 -->
      <template slot="idCard" slot-scope="scope">
        <a-input
          :disabled="scope.isSale || scope.idCardDisabled"
          v-model="scope.idCard"
        ></a-input>
      </template>
      <!-- 姓名 -->
      <template slot="userName" slot-scope="scope">
        <a-input v-model="scope.userName"></a-input>
      </template>
      <!-- 优惠 个人时 && 团体开启游客优惠 -->
      <template slot="discountCategoryText" slot-scope="scope">{{
        scope.discountCategory == 1
          ? scope.fold / 10 + " 折"
          : scope.discountCategoryText
      }}</template>
      <!-- 游客人数 -->
      <template slot="sumNum" slot-scope="text, scope, index">
        <!-- {{ scope.sumNum }} -->
        <a-input-number
          style="width: 60px;"
          v-model="scope.sumNum"
          :min="0"
          @change="switchSumTouristProData(scope.sybSystemIdProductId, index)"
        />
      </template>
      <template slot="sumPrice" slot-scope="scope">{{
        scope.sumPrice | money
      }}</template>
      <!-- 删除游客 -->
      <template slot="action" slot-scope="text, scope, index">
        <a-button @click="delTourist(scope, index)" type="link" class="blue"
          >删除</a-button
        >
      </template>
    </a-table>
  </div>
</template>
<script>
import { isIdcard, phoneReg } from "@/utils/global";
import { toUpTouristInfoByCard } from "@/api/member";
import { productFrontWindowRapidticketo } from "@/api/search";
import AutoIdCardInput from "@/components/hardware/AutoIdCardInput";
import moment from "moment";
export default {
  name: "tourist",
  props: {
    // 子景区
    playDate: {
      type: [Object, String]
    },
    // 子景区
    subScenic: {
      type: Object
    },
    //子景区产品
    subscenicProductList: {
      type: Array,
      default() {
        return [];
      }
    },
    playTime: {
      type: String,
      default() {
        return undefined;
      }
    }
  },
  components: {
    AutoIdCardInput
  },
  data() {
    return {
      isTrue: false, // 暂时注释产品列表
      phoneReg,
      productList: [], // 产品列表
      autoIdCard: "",
      moment,
      // selectTouristShow: false, //添加游客信息框
      //游客信息汇总table
      touristColumns: [
        {
          title: "游客类型",
          key: "touristCategoryName",
          scopedSlots: { customRender: "touristCategoryName" },
          width: 150
        },
        {
          title: "产品名称",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 230
        },
        {
          title: "单价",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" },
          width: 80
        },
        {
          title: "手机号",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          width: 120
        },
        {
          title: "身份证",
          key: "idCard",
          scopedSlots: { customRender: "idCard" },
          width: 160
        },
        {
          title: "姓名",
          key: "userName",
          scopedSlots: { customRender: "userName" },
          width: 100
        },
        // {
        //   title: "优惠",
        //   key: "discountCategoryText",
        //   scopedSlots: { customRender: "discountCategoryText" },
        //   width: 60
        // },
        {
          title: "数量",
          key: "sumNum",
          scopedSlots: { customRender: "sumNum" },
          width: 70
        },
        {
          title: "总金额",
          key: "sumPrice",
          scopedSlots: { customRender: "sumPrice" },
          width: 80
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 60
        }
      ],
      touristCardType: "1", //添加-游客证件类型 1身份证 2护照
      //添加游客-游客类型table-data
      touristTypeData: {
        touristType: [],
        idCard: "",
        touristCategoryId: "", //游客类型id
        sybSystemIdProductId: "", //产品id
        sellingPrice: "", //
        discountCategory: "", //优惠类型(1.折扣 2.无 3.免费)
        fold: "" //折
      },
      touristSumData: [], //已添加游客信息数据 处理汇总后的
      // checkTouristShow: false, //核对游客弹出
      currentCardId: "" // 记录当前身份证号
    };
  },
  mounted() {
    this.$refs.autoIdCard.listen();
    // 暂时注释产品列表
    // this.getProductList();
  },
  methods: {
    // 产品列表
    getProductList() {
      let playDate = moment(this.playDate).format("YYYY-MM-DD");
      productFrontWindowRapidticketo({
        subScenicId: this.subScenic.subScenicId,
        playDate
      })
        .then(res => {
          this.productList = res;
        })
        .catch(() => {});
    },
    checkPhone(scope) {
      if (!phoneReg.test(scope.phone)) {
        this.$message.warning("手机号格式错误!");
        scope.phone = "";
      }
    },
    updateCart(item, isSale) {
      if (!this.playTime) {
        this.$message.warning("请选择时间段！");
        return;
      }
      let touristTypeData = this.touristTypeData;
      touristTypeData.idCard = ""; // 身份证
      touristTypeData.userName = ""; // 姓名
      touristTypeData.touristType = []; // 游客类型列表
      touristTypeData.discountCategory = 2; // 优惠类型(1.折扣 2.无 3.免费)
      touristTypeData.discountCategoryText = "无"; //优惠类型文案
      touristTypeData.fold = 0; //折
      touristTypeData.touristCategoryId = ""; // 游客分类id
      touristTypeData.touristCategoryName = ""; // 游客分类name
      touristTypeData.touristDisabled = true; // 禁用游客类型,产品
      touristTypeData.idCardDisabled = false; // 禁用身份证
      touristTypeData.isSale = false; // 是否是惠民
      touristTypeData.subscenicProductList = [
        {
          formatType: 6,
          productId: item.id,
          id: item.id,
          productName: item.name,
          name: item.name
        }
      ];
      touristTypeData.sybSystemIdProductId = item.id;
      touristTypeData.productName = item.name || ""; //默认产品名称
      // 如果是刷身份证匹配到优惠票时
      if (isSale) {
        touristTypeData.idCardDisabled = true; // 禁用身份证
        touristTypeData.touristDisabled = false; // 禁用游客类型
        touristTypeData.isSale = true;
        touristTypeData.idCard = item.idCard; // 身份证
        touristTypeData.userName = item.userName; // 身份证
        touristTypeData.sellingPrice =
          item.selectSpecProducts[0].selectProductPrice; // 价格
        touristTypeData.subscenicProductList = [];
        item.selectSpecProducts.forEach(temp => {
          touristTypeData.subscenicProductList.push({
            formatType: 6,
            productId: temp.selectProductSkuId,
            id: temp.selectProductSkuId,
            productName: temp.selectProductName,
            name: temp.selectProductName,
            sybSystemIdProductId: temp.selectProductSkuId,
            sellingPrice: temp.selectProductPrice
          });
        });
        touristTypeData.sybSystemIdProductId =
          item.selectSpecProducts[0].selectProductSkuId;
        touristTypeData.productName =
          item.selectSpecProducts[0].selectProductName || ""; //默认产品名称
      }
      // 添加FTP区别是否是匿名添加产品
      this.sumTouristData("FTP");
    },
    // 根据身份证获取游客年龄，6周岁以下（包含6周岁）不能购票
    isBaby(id) {
      // 获取证件号码
      let fromYear = id.substr(6, 4);
      let currentYear = new Date().getFullYear();
      return currentYear - fromYear <= 6;
    },
    // 匹配游客类型,产品匹配
    changeCard(info) {
      if (!info.id) {
        this.$message.warning("证件号为空！");
        return;
      }
      if (!this.playTime) {
        this.$message.warning("请选择时间段！");
        return;
      }
      if (this.isBaby(info.id)) {
        this.$warning({
          title: "6周岁及以下儿童无须购票",
          okText: "确认"
        });
        return;
      }
      let err = isIdcard(info.id);
      if (err) {
        this.$message.warning(err);
        return;
      }
      this.currentCardId = info.id;
      let playDate = moment(this.playDate).format("YYYY-MM-DD");
      toUpTouristInfoByCard({
        type: 1, //1窗口  2.游小铺
        sybSystemId: this.subScenic.subScenicId,
        idCard: [info.id],
        playDate,
        formatType: "6",
        beginTime: this.playTime.split("-")[0]
      }).then(res => {
        if (
          !res.length ||
          !res[0].memberTouristManagementVOList ||
          !res[0].memberTouristManagementVOList.length
        ) {
          this.$error({
            title: "提示",
            content: "暂未匹配到游客分类，请联系管理员!"
          });
          return;
        }
        // 如果有惠民票
        if (res[0].selectSpecProducts && res[0].selectSpecProducts.length) {
          let value = res[0];
          let item = {
            idCard: value.idCard,
            userName: info.name || "",
            selectSpecProducts: res[0].selectSpecProducts
          };
          this.updateCart(item, true);
          return;
        }
        // 如果没有检测到惠民票
        this.touristTypeData.idCard = info.id || "";
        this.touristTypeData.userName = info.name || "";
        if (
          res[0].memberTouristManagementVOList &&
          res[0].memberTouristManagementVOList.length
        ) {
          let idCardArr = [];
          let artificial = [];
          // 类型排序，添加id，name字段
          res[0].memberTouristManagementVOList.forEach(te => {
            te.matchProductsListVOList.forEach(t => {
              t.id = t.productId;
              t.name = t.productName;
            });
            if (te.judgmentsBased === 2) {
              // 人工类型
              artificial.push(te);
            } else {
              // 身份证类型
              idCardArr.push(te);
            }
          });
          // 类型数据集合
          this.touristTypeData.touristType = idCardArr.concat(artificial);

          this.touristTypeData.touristDisabled = false; // 禁用游客类型
          this.touristTypeData.idCardDisabled = true; // 禁用身份证
          this.touristTypeData.isSale = false; // 是否是惠民
          // 默认显示第一个类型
          let item = this.touristTypeData.touristType[0];
          //默认匹配游客分类
          this.touristTypeData.touristCategoryId = item.id; //游客分类id
          this.touristTypeData.touristCategoryName = item.touristCategoryName; //游客分类name
          this.touristTypeData.discountCategory = item.discountCategory; //优惠类型(1.折扣 2.无 3.免费)
          this.touristTypeData.discountCategoryText = item.discountCategoryText; //优惠类型文案
          this.touristTypeData.fold = item.fold; //折
          this.touristTypeData.subscenicProductList =
            item.matchProductsListVOList;

          let productId =
            item.matchProductsListVOList[0] &&
            item.matchProductsListVOList[0].productId;
          this.touristTypeData.sybSystemIdProductId = productId
            ? productId
            : ""; //默认产品
          this.touristTypeData.productName = productId
            ? item.matchProductsListVOList[0].productName
            : ""; //默认产品名称
        }

        //设置价格
        if (this.touristTypeData.sybSystemIdProductId) {
          this.touristTypeData.subscenicProductList.forEach(ite => {
            if (ite.id == this.touristTypeData.sybSystemIdProductId) {
              this.touristTypeData.sellingPrice = ite.sellingPrice;
            }
          });
        }
        this.sumTouristData();
      });
    },
    //确定&处理游客分类汇总数据 ‘FTP’ 判断是否是匿名下单
    sumTouristData(FTP) {
      let item = this.touristTypeData;
      if (!item.idCard && !FTP) {
        return;
      }
      // if (!item.sybSystemIdProductId) {
      //   this.$message.warning("请选择游客类型或票种");
      //   return;
      // }
      let obj = {
        touristType: item.touristType, // 游客类型列表
        subscenicProductList: item.subscenicProductList, // 产品列表
        sybSystemIdProductId: item.sybSystemIdProductId, //产品id
        productName: item.productName, //产品名称
        userName: item.userName, //游客名称
        phone: item.phone,
        idCard: item.idCard,
        isSale: item.isSale, // 是否是优惠票
        touristDisabled: item.touristDisabled || false, // 是否禁用游客类型选择
        idCardDisabled: item.idCardDisabled || false, // 是否禁用游客类型选择
        sellingPrice: item.sellingPrice,
        touristCategoryId: item.touristCategoryId, //游客类型id
        touristCategoryName: item.touristCategoryName, //游客类型name
        discountCategoryText: item.discountCategoryText,
        discountCategory: item.discountCategory, //优惠类型(1.折扣 2.无 3.免费)
        fold: item.fold //折
      };
      //遍历已汇总-已有数据才进这
      this.touristSumData.forEach((ite, index) => {
        //存在同一游客类型&产品 update
        if (
          item.touristCategoryId == ite.touristCategoryId &&
          item.sybSystemIdProductId == ite.sybSystemIdProductId &&
          item.idCard == ite.idCard
        ) {
          obj.sumNum = ++ite.sumNum;
          obj.sumPrice = obj.sumNum * item.sellingPrice || 0;
          let list = this.touristSumData;
          list[index] = obj;
          this.touristSumData = [...list]; //解构一下触发table更新
          this.switchSumTouristProData(item.sybSystemIdProductId, index);
        } else if (
          !this.touristSumData.some(temp => {
            return (
              temp.touristCategoryId == obj.touristCategoryId &&
              temp.sybSystemIdProductId == obj.sybSystemIdProductId &&
              temp.idCard == obj.idCard
            );
          })
        ) {
          //类型和产品id 都不一致才add
          // obj.sumPrice = item.sellingPrice || 0;
          obj.sellingPrice = item.sellingPrice;
          obj.sumNum = 1;
          this.touristSumData.push(obj);
          this.switchSumTouristProData(
            item.sybSystemIdProductId,
            this.touristSumData.length - 1
          );
        }
      });
      //没有数据时这
      if (this.touristSumData.length == 0) {
        obj.sumPrice = item.sellingPrice || 0;
        obj.sumNum = 1;
        this.touristSumData.push(obj);
        this.switchSumTouristProData(item.sybSystemIdProductId, 0);
      }
      // this.$emit("changeTouristSumData", this.touristSumData);
      // 清空当前身份证输入框
      this.autoIdCard = "";
      this.getOrderInfo();
    },
    //汇总订单信息-计算优惠
    getOrderInfo() {
      //分个人 团体(是否开启游客优惠) 0关闭 只算团体优惠 1 开启 折上折
      let orderInfo = {
        free: 0, //免费人数
        folds: [], //折扣
        discount: 0, //已折扣
        actualSum: 0, //实际总金额
        sumPrice: 0, //原始总金额
        sumNum: 0, //总游客数
        sumGuide: 0, //总导游数
        guidePrice: 0, //导游总价
        freeGuide: 0, //减免导游数
        guideFare: "", //导游票价 1全免 2免一人 3全价
        guideProductPice: "", //导游票单价
        guideSumPice: 0 //导游所需总价
      };
      this.touristSumData.forEach(item => {
        orderInfo.sumNum += item.sumNum; //总游客数
        orderInfo.actualSum += item.sumPrice;
      });
      this.$emit("changeOrderInfo", orderInfo);
    },
    //切换游客table的游客类型
    switchSumTouristData(id, index, scope) {
      let touristType = scope.touristType.find(item => item.id == id);
      let obj = this.touristSumData[index];
      obj.touristCategoryId = touristType.id;
      obj.sybSystemIdProductId = touristType.matchProductsListVOList.length
        ? touristType.matchProductsListVOList[0].id
        : "";
      obj.productName = touristType.matchProductsListVOList.length
        ? touristType.matchProductsListVOList[0].productName
        : "";
      obj.discountCategory = touristType.discountCategory; //折扣类型 (1.折扣 2.无 3.免费)
      obj.discountCategoryText = touristType.discountCategoryText;
      obj.fold = touristType.fold; //折扣
      obj.subscenicProductList = touristType.matchProductsListVOList; //折扣
      // obj.touristType.forEach(tem => {
      //   tem.subscenicProductList = subscenicProductList; //匹配产品列表
      // });
      let list = this.touristSumData;
      list[index] = obj;
      this.touristSumData = [...list];
      //设置价格
      this.switchSumTouristProData(obj.sybSystemIdProductId, index);
      // this.getOrderInfo();
    },
    //切换游客table的产品类型
    switchSumTouristProData(id, index) {
      let product = this.subscenicProductList.find(item => item.id == id);
      if (!product) product = { sellingPrice: 0 };
      let obj = this.touristSumData[index];
      if (!obj.isSale) {
        // 非惠民票时
        //不匹配
        if (!id || !this.subscenicProductList.some(item => item.id == id)) {
          obj.sellingPrice = 0;
          obj.sybSystemIdProductId = "";
          obj.productName = "";
        } else {
          obj.sellingPrice = product.sellingPrice || 0;
          obj.productName = product.name || "";
        }
      } else {
        // 如果是惠民票过来的，直接取惠民票里面的价格，否则需要拿取所有产品列表对比对应的价格了
        let find = obj.subscenicProductList.find(
          te => te.id === obj.sybSystemIdProductId
        );
        obj.productName = find.productName;
        obj.sellingPrice = find.sellingPrice;
        product = { sellingPrice: find.sellingPrice };
      }
      //优惠类型(1.折扣 2.无 3.免费)
      if (obj.discountCategory == 1) {
        // 1.折扣
        obj.fold = obj.fold ? obj.fold : "-";
        if (obj.fold !== "-") {
          let op = obj.sellingPrice * (obj.fold / 100) * obj.sumNum;
          obj.sumPrice = op < 0 ? 0 : Number(op.toFixed(0));
        } else {
          obj.sumPrice = obj.sumNum * product.sellingPrice || 0;
        }
      } else if (obj.discountCategory == 3) {
        obj.sumPrice = 0;
      } else if (obj.discountCategory == 2) {
        //优惠 无
        obj.sumPrice = obj.sumNum * product.sellingPrice || 0;
      }
      let list = this.touristSumData;
      list[index] = obj;
      this.touristSumData = [...list];
      this.$emit("changeTouristSumData", list);
      this.getOrderInfo();
    },
    //删除游客
    delTourist(row, index) {
      this.touristSumData.splice(index, 1);
      if (!this.touristSumData.length) {
        this.currentCardId = "";
      } else if (row.idCard === this.currentCardId && !row.disabled) {
        // 如果删除当前的产品身份证和上一次记录的身份证相等，清空当前记录的身份号，允许重新刷号
        this.currentCardId = "";
      }
      this.getOrderInfo();
      this.$emit("changeTouristSumData", this.touristSumData);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.productIdCard {
  /deep/.ant-input {
    height: 40px !important;
  }
}
/*加减号*/
.minus {
  color: #a4a4a4;
  font-size: 24px;
  cursor: pointer;
}
.minus-disabled {
  color: #a4a4a4;
  font-size: 24px;
  cursor: not-allowed;
}
.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
  background: #f1f1f1;
}
/*减号*/
.plus {
  font-size: 24px;
  color: #25a2f2;
  cursor: pointer;
}
.section {
  margin-bottom: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px;
}
.list {
  .item {
    display: inline-block;
    width: 328px;
    height: 100px;
    background: #ffffff;
    border-radius: 11px;
    border: 1px solid #e6e6e6;
    margin-right: 10px;
    padding: 14px 13px 13px 0;
    .content {
      display: flex;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
        border-radius: 8px;
        margin: 0 12px 0 11px;
      }
      .right {
        flex-grow: 1;
        .ant-tag {
          margin: 3px 0 4px 0;
          background-color: #e4f6fc;
          color: #4170db;
          font-size: 10px;
        }
      }
    }
    &:hover {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.23);
    }
  }
  .active {
    border: 1px solid @primary;
  }
}
</style>
