<template>
  <div class="wrapper flex-col flex-start">
    <panelhead title="取票"></panelhead>

    <section class="main mt-2x">
      <a-tabs type="card" default-active-key="1" @change="tabChange">
        <a-tab-pane key="1" tab="门票取票" v-if="ticketDetail.doorTicket">
          <a-table
            :columns="columns"
            :data-source="tableData"
            :rowKey="(record, index) => index"
            :pagination="false"
            :scroll="{ x: 1366, y: 600 }"
          >
            <!-- 门票名称 -->
            <span
              slot="productName"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.productName }}
            </span>
            <!-- 取票状态 -->
            <template slot="ticketStatus" slot-scope="row">
              <span class="primary" v-if="row.whetherTakeTicket == 0">
                未取票
              </span>
              <span class="gray" v-if="row.whetherTakeTicket == 1">
                已取票
              </span>
            </template>
            <!-- 票号 -->
            <span
              slot="ticketNo"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.auxiliaryId }}
            </span>
            <!-- 游玩日期 -->
            <span
              slot="playDate"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.useDate | date }}
            </span>
            <!-- 使用有效期 -->
            <span
              slot="validStart"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.beginValidTime | date }} ~
              {{ row.endValidTime | date }}
            </span>
            <!-- 订单编号 -->
            <span
              slot="mainOrderId"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.mainOrderId }}
            </span>
            <!-- 预订时间 -->
            <span
              slot="appointment"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.createTime | date }}
            </span>
            <!-- 销售渠道 -->
            <span
              slot="buyChannelsText"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.buyChannelsText }}
            </span>
            <!-- 取票手机号 -->
            <span
              slot="takeTicketPersonPhone"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.takeTicketPersonPhone ? row.takeTicketPersonPhone : "-" }}
            </span>
            <!-- 取票身份证 -->
            <span
              slot="takeTicketPersonIdCard"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{
                row.takeTicketPersonIdCard ? row.takeTicketPersonIdCard : "-"
              }}
            </span>
            <!-- 操作 -->
            <template slot="operation" slot-scope="row">
              <p
                v-if="row.whetherTakeTicket == 0"
                class="blue oprate pointer"
                @click="oprate(row)"
              >
                取票
              </p>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="团体票取票" v-if="ticketDetail.groupTicket">
          <a-table
            :columns="columns2"
            :data-source="tableData2"
            :rowKey="(record, index) => index"
            :pagination="false"
            :scroll="{ x: 1366, y: 600 }"
          >
            <!-- 门票名称 -->
            <span
              slot="productName"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.productName }}
            </span>
            <!-- 包含门票数量 -->
            <span
              slot="buyNum"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.buyNum }}
            </span>
            <!-- 取票状态 -->
            <template slot="ticketStatus" slot-scope="row">
              <span class="primary" v-if="row.whetherTakeTicket == 0">
                未取票
              </span>
              <span class="gray" v-if="row.whetherTakeTicket == 1">
                已取票
              </span>
            </template>
            <!-- 验票码 -->
            <span
              slot="ticketNo"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.auxiliaryId }}
            </span>
            <!-- 游玩日期 -->
            <span
              slot="useDate"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.useDate | date }}
            </span>
            <!-- 使用有效期 -->
            <span
              slot="validStart"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.beginValidTime | date }} ~
              {{ row.endValidTime | date }}
            </span>
            <!-- 订单编号 -->
            <span
              slot="mainOrderId"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.mainOrderId }}
            </span>
            <!-- 预订时间 -->
            <span
              slot="appointment"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.createTime | date }}
            </span>
            <!-- 销售渠道 -->
            <span
              slot="buyChannelsText"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.buyChannelsText }}
            </span>
            <!-- 取票手机号 -->
            <span
              slot="takeTicketPersonPhone"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{ row.takeTicketPersonPhone ? row.takeTicketPersonPhone : "-" }}
            </span>
            <!-- 取票身份证 -->
            <span
              slot="takeTicketPersonIdCard"
              slot-scope="row"
              :class="{ gray: row.whetherTakeTicket == 1 }"
            >
              {{
                row.takeTicketPersonIdCard ? row.takeTicketPersonIdCard : "-"
              }}
            </span>
            <!-- 操作 -->
            <template slot="operation" slot-scope="row">
              <a-button
                type="link"
                v-if="row.whetherTakeTicket == 0"
                @click="oprate(row)"
                >取票
              </a-button>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </section>

    <footer class="footer">
      <p class="flex-grow">应收：{{ receivable | money }}</p>
      <div>
        <a-button
          type="primary"
          size="large"
          shape="round"
          @click="onSubmit"
          class="mr-3x"
          >全部取票</a-button
        >
        <a-button size="large" shape="round" @click="$router.go(-1)"
          >返回</a-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import panelhead from "../../../components/common/PanelHead.vue";
import {
  orderDoorticket,
  orderAuxiliaryprintinfo,
  orderOrdinaryprintinfo,
  orderGroupprintinfo,
  orderGroupticket
} from "../../../api/search";
import {
  ticketPrint,
  findTicketPreprintBatchInfo,
  ticketGroupPrint
} from "../../../api/order";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";

const columns = [
  {
    title: "取票号",
    dataIndex: "mainAuxiliaryId",
    width: 130
  },
  {
    title: "门票名称",
    scopedSlots: { customRender: "productName" },
    ellipsis: true,
    width: 300
  },
  {
    title: "取票状态",
    scopedSlots: { customRender: "ticketStatus" },
    width: 90
  },
  {
    title: "票号",
    scopedSlots: { customRender: "ticketNo" },
    width: 130
  },
  {
    title: "游玩日期",
    scopedSlots: { customRender: "playDate" },
    width: 120
  },
  {
    title: "使用有效期",
    scopedSlots: { customRender: "validStart" },
    width: 200
  },
  {
    title: "订单编号",
    scopedSlots: { customRender: "mainOrderId" },
    width: 180
  },
  {
    title: "预订时间",
    scopedSlots: { customRender: "appointment" },
    width: 120
  },
  {
    title: "销售渠道",
    scopedSlots: { customRender: "buyChannelsText" },
    width: 120
  },
  {
    title: "取票手机号",
    scopedSlots: { customRender: "takeTicketPersonPhone" },
    width: 130
  },
  {
    title: "取票身份证",
    scopedSlots: { customRender: "takeTicketPersonIdCard" },
    width: 180
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
    width: 80
  }
];
const columns2 = [
  {
    title: "取票号",
    dataIndex: "mainAuxiliaryId",
    width: 130
  },
  {
    title: "门票名称",
    scopedSlots: { customRender: "productName" },
    width: 300
  },
  {
    title: "包含门票数量",
    scopedSlots: { customRender: "buyNum" },
    width: 130
  },
  {
    title: "取票状态",
    scopedSlots: { customRender: "ticketStatus" },
    width: 100
  },
  {
    title: "验票码",
    scopedSlots: { customRender: "ticketNo" },
    width: 130
  },
  {
    title: "游玩日期",
    scopedSlots: { customRender: "useDate" },
    width: 120
  },
  {
    title: "使用有效期",
    scopedSlots: { customRender: "validStart" },
    width: 200
  },
  {
    title: "订单编号",
    scopedSlots: { customRender: "mainOrderId" },
    width: 180
  },
  {
    title: "预订时间",
    scopedSlots: { customRender: "appointment" },
    width: 120
  },
  {
    title: "销售渠道",
    scopedSlots: { customRender: "buyChannelsText" },
    width: 120
  },
  {
    title: "取票手机号",
    scopedSlots: { customRender: "takeTicketPersonPhone" },
    width: 130
  },
  {
    title: "取票身份证",
    scopedSlots: { customRender: "takeTicketPersonIdCard" },
    width: 180
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
    width: 80
  }
];

export default {
  name: "GetTicketDetail",
  props: {},
  components: {
    panelhead
  },
  data() {
    return {
      ticketDetail: [], // 接口门票信息
      columns, // 门票取票table的列
      columns2, // 团体票取票table的列
      tableData: [], // 门票取票table数据
      tableData2: [], // 团体票取票table数据
      tabKey: "1", // 当前tab项
      receivable: 0, // 应收金额
      isGroup: false // 是否团体票
      // selectedRowKeys: [] // table checkbox
      // selectedRows: [], // 勾选的门票、checkbox
    };
  },
  computed: {
    // // 去掉全选框功能[x]
    // rowSelection() {
    //   return {
    //     selectedRowKeys: this.selectedRowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       this.selectedRowKeys = selectedRowKeys;
    //       this.selectedRows = selectedRows; // 赋值已选门票信息
    //       // // 计算应收金额（去掉）
    //       // this.receivable = this.selectedRows.reduce((cur, pre) => {
    //       //   return cur + pre.depositFee;
    //       // }, 0);
    //     },
    //     getCheckboxProps: record => ({
    //       props: {
    //         // 门票状态为：已取票，不能勾选
    //         disabled: record.whetherTakeTicket == 1
    //       }
    //     })
    //   };
    // }
  },
  created() {
    this.ticketDetail =
      JSON.parse(sessionStorage.getItem("getTicketInfo")) || {};
    this.init();
    // this.selectedRowKeys = [];
  },
  methods: {
    // tab切换
    tabChange(key) {
      this.tabKey = key;
    },
    init() {
      if (this.ticketDetail.doorTicket) {
        this.loadData();
      }
      if (this.ticketDetail.groupTicket) {
        this.loadGroupData();
      }
    },
    // 加载门票取票tab数据
    loadData() {
      orderDoorticket({
        keyword: this.ticketDetail.keyword
      })
        .then(res => {
          console.log(res, "1223002365");
          this.tableData = res.records || [];
          this.receivable = res.totalDeposit;
        })
        .catch(() => {});
    },
    // 加载团体票取票数据
    loadGroupData() {
      orderGroupticket({
        keyword: this.ticketDetail.keyword
      })
        .then(res => {
          this.tableData2 = res || [];
          this.receivable = 0;
          console.log(res, "21320---*----");
        })
        .catch(() => {});
    },
    // 底部按钮，全部取票
    onSubmit() {
      let data = [];
      let ids = []; // 存储着：需要填写身份证信息的id
      const otherData = []; // 存储着：取票不需要填写身份证等信息的数据
      if (this.tabKey == 1 && this.tableData.length) {
        data = this.tableData;
      } else {
        data = this.tableData2;
        this.isGroup = true;
      }
      data.forEach(item => {
        if (
          item.voucherType != 0 ||
          item.isNeedActivate != 0 ||
          item.byFaces ||
          item.byTicketId
        ) {
          ids.push(item.id);
        } else {
          otherData.push(item);
        }
      });
      // 需要填写信息
      if (ids.length) {
        findTicketPreprintBatchInfo({
          idList: ids
        })
          .then(res => {
            this.$router.push({
              name: "homeWriteTouristMsg",
              params: {
                searchRes: res,
                receivable: this.receivable,
                otherData,
                tabKey: this.tabKey,
                keyword: this.ticketDetail.keyword,
                isGroup: this.isGroup
              }
            });
          })
          .catch(() => {});
      } else {
        // 不需要填写身份证、人脸等信息
        console.log("不需要填写身份证、人脸等信息+++2336+++++", data);
        this.oprateTicket(data, true);
      }
    },
    // 表格里的 单项取票
    oprate(row) {
      // 取票，不需要绑定腕带、激活、绑定人脸、绑定身份证
      if (
        row.voucherType == 0 &&
        row.isNeedActivate == 0 &&
        !row.byFaces &&
        !row.byTicketId
      ) {
        // 团体票取票
        if (
          this.tabKey == 2 ||
          (this.tableData.length <= 0 && this.tableData2.length)
        ) {
          this.isGroup = true;
          // 团体票取票接口
          this.getGroupTicket([row]);
          return;
        }
        // 非团体票取票
        this.oprateTicket([row]);
        return;
      }
      if (
        this.tabKey == 2 ||
        (this.tableData.length <= 0 && this.tableData2.length)
      ) {
        this.isGroup = true;
      }
      // 取票，需要填一些信息
      findTicketPreprintBatchInfo({
        idList: [row.id]
      })
        .then(res => {
          this.$router.push({
            name: "homeWriteTouristMsg",
            params: {
              searchRes: res,
              receivable: row.voucherDeposit,
              tabKey: this.tabKey,
              isGroup: this.isGroup
            }
          });
        })
        .catch(() => {});
    },
    // 直接取票，并打印 (isAll:是否为全部取票)
    oprateTicket(arr, isAll) {
      if (
        this.tabKey == 2 ||
        (this.tableData.length <= 0 && this.tableData2.length)
      ) {
        this.isGroup = true;
        // 团体票取票接口
        this.getGroupTicket(arr, isAll);
        return;
      }
      const list = [];
      arr.forEach(item => {
        list.push({
          ifActivateNow: true, // 是否立即激活
          id: item.id, // 门票订单详情记录id
          auxiliaryId: item.auxiliaryId || "", // 主票号或子票号
          idCardNo: "", // 票号绑定的身份证号
          faceId: "", // 票号绑定的人脸id
          ticketPrintSubAuxiliaryInfoList: [] // 子票绑定的身份证人脸信息
        });
      });
      // 取票接口
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: list
      })
        .then(() => {
          this.$message.success("取票完成");
          if (isAll) {
            // 全部取票的 打印接口
            this.printTicketAll();
          } else {
            // 单张取票的 打印接口
            this.printTicket(list[0].auxiliaryId);
            // 刷新数据（单张取票）
            if (this.tabKey == 1) {
              this.loadData();
            } else {
              this.loadGroupData();
            }
          }
        })
        .catch(() => {});
    },
    // 团体票取票接口
    getGroupTicket(arr, isAll) {
      const list = [];
      arr.forEach(item => {
        list.push(item.id);
      });
      ticketGroupPrint({
        channel: "", // 打印渠道,非必填，未提供时系统自动从登陆用户的channel中获取
        groupTicketIdList: list // 要打印的团体票记录ID列表
      })
        .then(() => {
          this.$message.success("取票完成");
          if (isAll) {
            // 全部取票的 打印接口
            this.printTicketAll();
          } else {
            // 单张取票的 打印接口
            this.printTicket(arr[0].auxiliaryId);
            // 刷新数据（单张取票）
            if (this.tabKey == 1 && this.tableData.length) {
              this.loadData();
            } else {
              this.loadGroupData();
            }
          }
        })
        .catch(() => {});
    },
    // 纸质门票 打印(单张取票打印)
    printTicket(id) {
      orderAuxiliaryprintinfo({ auxiliaryId: id })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
          this.backHome();
        })
        .catch(() => {});
    },
    // 纸质门票 打印(全部取票打印)
    printTicketAll() {
      let api = "";
      if (this.tabKey == 1) {
        // 普通门票打印接口
        api = orderOrdinaryprintinfo;
      } else {
        // 团体票打印接口
        api = orderGroupprintinfo;
      }
      api({
        keyword: this.ticketDetail.keyword
      })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
          this.backHome();
        })
        .catch(() => {});
    },
    // 取票完成，返回首页提示
    backHome() {
      this.$confirm({
        title: "温馨提示",
        content: "取票完成，点击按钮返回",
        okText: "返回首页",
        cancelText: "继续取票",
        onOk: () => {
          this.$router.replace("/home");
        },
        onCancel: () => {
          this.$router.replace("/home/ticket/GetTicket");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
