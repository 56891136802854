<!-- 门票核销记录 -->
<template>
  <div class="ph-2x pb-2x">
    <header>
      <span>操作日期</span>
      <!-- <a-date-picker
        :default-value="moment(playDate)"
        class="mh-2x"
        @change="dateChange"
        :allow-clear="false"
      >
      </a-date-picker> -->
      <a-range-picker class="w230 mh-2x" @change="dateChange"></a-range-picker>
      <a-button @click="search" type="primary">查询</a-button>
    </header>
    <!--table-->
    <a-table
      class="mt-2x"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      size="middle"
      row-key="id"
    >
      <template slot="writeOffTime" slot-scope="row">
        {{ row.writeOffTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="writeOffStatusText" slot-scope="row">
        <span class="primary">{{ row.writeOffStatusText }}</span></template
      >
      <template slot="action" slot-scope="row">
        <div class="flex-start">
          <a-button type="link" class="blue" @click="showDetail(row)"
            >详情</a-button
          >
          <!-- 反核销会影响清分数据-飞哥-去掉 -->
          <!-- <a-button
            type="link"
            v-if="row.writeOffStatus == 1"
            class="blue"
            @click="onWriteOff(row)"
            >取消核销</a-button
          > -->
        </div>
      </template>
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
      />
    </footer>

    <!--详情弹窗-->
    <a-modal
      title="门票核销记录"
      :visible.sync="visible"
      @cancel="visible = false"
      width="500px"
    >
      <a-row>
        <a-col :span="5">门票名称：</a-col>
        <a-col :span="19">{{ row.productName }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">主票票号：</a-col>
        <a-col :span="19">{{ row.mainAuxiliaryId }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">子票票号：</a-col>
        <a-col :span="19">{{ row.subAuxiliaryId }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销时间：</a-col>
        <a-col :span="19">{{
          row.writeOffTime | date("yyyy-mm-dd hh:mm:ss")
        }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销人：</a-col>
        <a-col :span="19">{{ row.writeOffPersonName }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销渠道：</a-col>
        <a-col :span="19">{{ row.writeOffChannelText }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销状态：</a-col>
        <a-col :span="19" class="primary">{{ row.writeOffStatusText }}</a-col>
      </a-row>
      <template v-if="row.writeOffStatus === 2">
        <a-row class="mt-2x">
          <a-col :span="5">反核销时间：</a-col>
          <a-col :span="19">{{
            row.backWriteOffTime | date("yyyy-mm-dd hh:mm:ss")
          }}</a-col>
        </a-row>
        <a-row class="mt-2x">
          <a-col :span="5">反核销人：</a-col>
          <a-col :span="19">{{ row.backWriteOffPersonName }}</a-col>
        </a-row>
        <a-row class="mt-2x">
          <a-col :span="5">申请渠道：</a-col>
          <a-col :span="19">{{ row.backWriteOffChannelText }}</a-col>
        </a-row>
        <a-row class="mt-2x">
          <a-col :span="5">申请说明：</a-col>
          <a-col :span="19">{{ row.backWriteOffRemarks }}</a-col>
        </a-row>
      </template>

      <footer slot="footer" class="flex-center right" @click="visible = false">
        <a-button>关闭</a-button>
      </footer>
    </a-modal>
    <!--取消核销-->
    <a-modal title="门票反核销" :visible.sync="refund" width="700px">
      <a-row>
        <a-col :span="5">门票名称：</a-col>
        <a-col :span="19">{{ row.productName }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">主票票号：</a-col>
        <a-col :span="19">{{ row.mainAuxiliaryId }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">子票票号：</a-col>
        <a-col :span="19">{{ row.subAuxiliaryId || "-" }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销时间：</a-col>
        <a-col :span="19">{{
          row.writeOffTime | date("yyyy-mm-dd hh:mm:ss")
        }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销人：</a-col>
        <a-col :span="19">{{ row.writeOffPersonName }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <a-col :span="5">核销渠道：</a-col>
        <a-col :span="19">{{ row.writeOffChannelText }}</a-col>
      </a-row>
      <a-row class="mt-2x">
        <!-- <a-col :span="5">反核销说明：</a-col> -->
        <a-col :span="19" class="primary">
          <a-form-model ref="refundForm" :model="refundForm" :rules="rules">
            <a-form-model-item
              label="反核销说明："
              class="flex-start"
              prop="refundMoney"
            >
              <a-input
                v-model="refundForm.refundMoney"
                class="form-item-width"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <footer slot="footer" class="flex-center">
        <a-button type="primary" @click="onRefundSave">保存</a-button>
        <a-button @click="onRefundClose">取 消</a-button>
      </footer>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { findTicketWriteOffInfoPage, apiOrderTicketUncheck } from "@/api/order";
export default {
  name: "TicketCancel",
  data() {
    return {
      moment,
      playDate: moment(),
      refund: false, //取消核销弹窗
      date: moment().valueOf(),
      writeOffBeginTime: "", //操作日期开始
      writeOffEndTime: "", //操作日期结束
      visible: false,
      tableData: [], //门票核销记录列表
      total: 0,
      page: 1,
      size: 10,
      row: {}, //核销详情
      refundForm: {
        refundMoney: ""
      },
      rules: {
        refundMoney: [
          { required: true, message: "请输入说明", trigger: "blur" }
        ]
      },
      // 核销列title
      columns: [
        {
          width: 130,
          title: "核销主票号",
          dataIndex: "mainAuxiliaryId",
          key: "mainAuxiliaryId"
        },
        {
          width: 130,
          title: "核销子票号",
          dataIndex: "subAuxiliaryId",
          key: "subAuxiliaryId"
        },
        {
          width: 130,
          title: "核销时间",
          scopedSlots: { customRender: "writeOffTime" },
          key: "writeOffTime"
        },
        {
          width: 130,
          title: "核销单号",
          dataIndex: "id",
          key: "id"
        },
        {
          width: 120,
          title: "核销人",
          dataIndex: "writeOffPersonName",
          key: "writeOffPersonName"
        },
        {
          width: 100,
          title: "核销渠道",
          dataIndex: "writeOffChannelText",
          key: "writeOffChannelText"
        },
        {
          width: 100,
          title: "核销状态",
          scopedSlots: { customRender: "writeOffStatusText" },
          key: "writeOffStatusText"
        },
        {
          width: 130,
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    //取消核销取消
    onRefundClose() {
      this.refundForm.refundMoney = "";
      this.$refs.refundForm.clearValidate();
      this.refund = false;
    },
    //取消核销保存
    onRefundSave() {
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          apiOrderTicketUncheck({
            id: this.row.id,
            remark: this.refundForm.refundMoney
          })
            .then(() => {
              this.loadData();
              this.onRefundClose();
              this.$message.success("反核销成功");
            })
            .catch(() => {});
        } else {
          return this.$message.warning("验证不通过，请重新检查");
        }
      });
    },
    //取消核销
    onWriteOff(row) {
      this.refund = true;
      this.row = row;
    },
    //门票核销记录列表
    loadData() {
      findTicketWriteOffInfoPage({
        page: this.page,
        size: this.size,
        id: this.$route.query.id,
        writeOffBeginTime: this.writeOffBeginTime,
        writeOffEndTime: this.writeOffEndTime
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 展示详情
    showDetail(row) {
      this.row = row;
      this.visible = true;
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    // 操作日期
    dateChange(val) {
      if (val.length) {
        this.writeOffBeginTime = val[0].startOf("day").valueOf();
        this.writeOffEndTime = val[1].endOf("day").valueOf();
      } else {
        this.writeOffBeginTime = "";
        this.writeOffEndTime = "";
      }
    },
    search() {
      this.page = 1;
      this.loadData();
    }
  }
};
</script>
<style lang="less" scoped>
.form-item-width {
  width: 300px;
}
.w230 {
  width: 230px;
}
.right {
  justify-content: flex-end;
}
</style>
