<template>
  <div class="wrapper flex-between flex-col">
    <!-- 头部 -->
    <panel-head title="门票信息"></panel-head>
    <!-- 主体 -->
    <div class="main mt-2x">
      <header class="container">
        <!-- 查询 -->
        <a-form-model layout="inline" ref="myform" :model="formModel">
          <a-form-model-item label="购票日期">
            <a-range-picker
              class="input"
              :allow-clear="false"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="订单号" prop="orderId">
            <a-input
              v-model="formModel.orderId"
              placeholder="请输入订单号"
              class="input"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <a-input
              v-model="formModel.ticketNo"
              placeholder="请输入票号"
              class="input"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="getList">查询</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="reset('myform')">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </header>
      <!--表格-->
      <ticket-list-table :table-data="tableData"></ticket-list-table>
      <!--分页-->
      <footer class="flex-end p-2x">
        <a-pagination
          v-model="page"
          :default-page-size="size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="getList"
          @showSizeChange="sizeChange"
        />
      </footer>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        type="primary"
        shape="round"
        class="btn-lg"
        size="large"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
    </div>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { orderMemberTicket } from "../../../api/search";
import TicketListTable from "../ticket/components/TicketListTable";

export default {
  name: "TicketsRecord",
  components: { PanelHead, TicketListTable },
  data() {
    return {
      moment,
      tableData: [],
      dateRange: [moment(), moment()],
      formModel: {
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        orderId: "",
        ticketNo: ""
      },
      page: 1,
      size: 10,
      total: 0
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getList(); // 查询门票信息
  },
  methods: {
    // 日期选择
    dateChange([start, end]) {
      this.formModel.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.formModel.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },

    // 查询门票信息
    getList() {
      orderMemberTicket({
        memberId: this.memberId,
        ...this.formModel
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(function() {});
    },

    // 重置表单
    reset(form) {
      this.$refs[form].resetFields();
      this.dateRange = [moment(), moment()];
      this.getList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 200px;
}
</style>
