<template>
  <div class="bg-white">
    <!--    表头搜索tag-->
    <div
      v-if="searchList.length"
      class="pb-1x flex-start"
      style="overflow: auto"
    >
      <div
        class="flex-start flex-shrink flex-noWrap pl-2x pt-1x"
        v-for="(item, index) in searchList"
        :key="index"
      >
        <h3 class="pr-x flex-shrink">{{ item.name }}：</h3>
        <a-tag
          v-for="(temp, inde) in item.tagList"
          class="mr-x"
          color="blue"
          :key="inde"
          :visible="true"
          closable
          @close="filterChange(false, temp, item.column)"
        >
          {{ temp.value }}
        </a-tag>
      </div>
    </div>
    <!--    表单table-->
    <a-table
      :scroll="{ x: 1366, y: currentHeight }"
      :data-source="dataSource"
      :pagination="false"
      :row-selection="rowSelection"
      rowKey="id"
    >
      <template v-for="column in tableColumns">
        <a-table-column
          v-if="column.popover !== true"
          :key="column.key"
          :width="column.width"
          :align="column.align ? column.align : ''"
          :ellipsis="column.ellipsis ? column.ellipsis : false"
          :fixed="column.fixed ? column.fixed : null"
        >
          <!--        头部显示-->
          <div slot="title">
            {{ column.title }}
            <a-dropdown
              :trigger="['click']"
              v-model="column.dropdownVisible"
              @visibleChange="visibleChange($event, column)"
              v-if="column.filters"
            >
              <a-icon type="down" class="pointer" />
              <a-menu slot="overlay" class="cum_menu">
                <a-menu-item
                  v-if="
                    isCheckAll && column.fieldList && column.fieldList.length
                  "
                >
                  <a-checkbox
                    style="color:#4182FC"
                    v-model="column.checkAll"
                    @change="handleCheckAllChange(column)"
                    >全选</a-checkbox
                  >
                </a-menu-item>
                <a-menu-item
                  v-for="(ite, inde) in column.fieldList"
                  :key="inde"
                >
                  <a-checkbox
                    :label="ite.value"
                    v-model="ite.checked"
                    @change="
                      val => filterChange(val.target.checked, ite, column)
                    "
                    >{{ ite.value }}</a-checkbox
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <!--        body显示-->
          <!--        时间显示区域-->
          <span
            :class="column.primary ? column.primary : ''"
            v-if="
              column.scopedSlots && column.scopedSlots.customRender === 'date'
            "
            slot-scope="record"
          >
            {{
              record[column.key]
                | date(
                  column.scopedSlots.valueFormat
                    ? column.scopedSlots.valueFormat
                    : "YYYY-MM-DD hh:mm:ss"
                )
            }}
          </span>
          <!--        金钱显示区域-->
          <span
            :class="column.primary ? column.primary : ''"
            v-else-if="
              column.scopedSlots && column.scopedSlots.customRender === 'money'
            "
            slot-scope="record"
          >
            {{ record[column.key] | money(column.scopedSlots.typeValue) }}
          </span>
          <!--        人脸显示区域-->
          <div
            v-else-if="
              column.scopedSlots && column.scopedSlots.customRender === 'face'
            "
            slot-scope="record"
          >
            <img
              class="pointer"
              @click="imgClick(record[column.key])"
              v-if="
                record[column.key] &&
                  record[column.key].indexOf('https://') !== -1
              "
              :width="40"
              :src="record[column.key]"
            />
            <span :title="column.ellipsis ? record[column.key] : ''" v-else>{{
              record[column.key]
            }}</span>
          </div>
          <!--        默认显示区域-->
          <span
            :title="column.ellipsis ? record[column.key] : ''"
            v-else
            slot-scope="record"
          >
            {{ record[column.key] }}
          </span>
        </a-table-column>
        <template v-else>
          <slot name="popover"></slot>
        </template>
      </template>
      <slot name="action"></slot>
    </a-table>
    <!--    查看图片-->
    <a-modal v-model="faceShow" title="查看图片" centered>
      <img width="400" :src="faceUrl" alt="" />
      <div slot="footer" class="text-c">
        <a-button type="primary" @click="faceShow = false">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { orderFilterCondition } from "@/api/reportCk";
export default {
  name: "CurrentTable",
  props: {
    dataSource: {
      type: Array,
      default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    checkSearchs: {
      type: Array,
      default() {
        return [];
      }
    },
    rowSelection: {
      type: Object,
      default() {
        return null;
      }
    },
    tableHeight: {
      type: Number,
      default() {
        return 340;
      }
    },
    // 下拉框条件接口
    filterApi: {
      type: Function,
      default() {
        return () => {};
      }
    },
    orderBeginTime: {
      type: [String, Number],
      default() {
        return "";
      }
    },
    orderEndTime: {
      type: [String, Number],
      default() {
        return "";
      }
    },
    filterType: {
      type: String,
      default() {
        return "1";
      }
    },
    isCheckAll: {
      // table头部下拉，是否开启全选
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      //实时屏幕高度
      currentHeight: "",
      tableColumns: [],
      faceUrl: "",
      faceShow: false,
      searchList: [], // table筛选过滤list
      timer: null // 截流
    };
  },
  created() {
    this.currentHeight = this.$tableHeight(this.tableHeight);
    this.tableColumns = [...this.columns];
  },
  watch: {
    tableHeight(newValue) {
      this.currentHeight = this.$tableHeight(newValue);
    },
    columns() {
      this.$nextTick(() => {
        this.tableColumns = [...this.columns];
      });
    },
    checkSearchs(newVal) {
      this.searchList = newVal;
      this.$emit("update:checkSearchs", newVal);
    }
  },
  methods: {
    // 显示多选时，多选checkAll方法
    handleCheckAllChange(column) {
      let i = this.searchList.findIndex(te => te.column.key === column.key);
      if (i !== -1) {
        this.searchList[i].tagList = [];
      }
      let isTrue = column.checkAll ? true : false;
      if (column.fieldList && column.fieldList.length) {
        column.fieldList.forEach(ite => {
          this.filterChange(isTrue, ite, column);
        });
      }
    },
    // 点击人脸
    imgClick(url) {
      this.faceShow = true;
      this.faceUrl = url;
    },
    visibleChange(v, column) {
      if (v && !column.fieldList.length) {
        let api = orderFilterCondition;
        if (
          this.filterApi.name !== "_default" &&
          this.filterApi.name !== "default"
        ) {
          api = this.filterApi;
        }
        let obj = {
          columnName: column.filterKey,
          filterConditionType: this.filterType // 兼容院民旧接口，最新接口华哥提供的可忽略该参数
        };
        if (this.orderBeginTime && this.orderEndTime) {
          obj.orderBeginTime = this.orderBeginTime;
          obj.orderEndTime = this.orderEndTime;
        }
        api(obj).then(res => {
          if (
            res.filterConditionInfo &&
            typeof res.filterConditionInfo == "string"
          ) {
            res.filterConditionInfo = JSON.parse(res.filterConditionInfo);
          }
          column.fieldList = res.filterConditionInfo || [];
        });
      }
    },
    // 表格过滤checked联动tag列表显示
    filterChange(val, tagItem, column) {
      let len = 0;
      // 找到对应的表格过滤修改checked值
      column.fieldList.forEach(item => {
        if (item.key === tagItem.key) {
          this.$set(item, "checked", val);
        }
        if (item.checked) {
          len++;
        }
      });
      // 全选
      column.checkLength = len;
      column.checkAll = column.checkLength === column.fieldList.length;
      // tag列表显示
      let arr = this.searchList;
      if (!arr.length && val) {
        // 判断搜索列表空直接push
        arr.push({
          name: column.title,
          column: column,
          tagList: [tagItem]
        });
      } else {
        // 是否有存在的下标值
        let i = arr.findIndex(temp => temp.name === column.title);
        if (i !== -1) {
          // 有的情况下，找到对应下表push进去
          if (val) arr[i].tagList.push(tagItem);
          // 如果checked为false,找到对应下标剔除值
          else {
            let j = arr[i].tagList.findIndex(te => te.key === tagItem.key);
            arr[i].tagList.splice(j, 1);
            if (arr[i].tagList.length === 0) arr.splice(i, 1);
          }
        } else {
          if (val) {
            // 直接push标签对象
            arr.push({
              name: column.title,
              column: column,
              tagList: [tagItem]
            });
          }
        }
      }
      // 截流 （预防多选时）
      if (this.timer) return;
      this.timer = setTimeout(() => {
        this.$emit("update:checkSearchs", arr);
        this.$emit("filter-change");
        clearTimeout(this.timer);
        this.timer = null;
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.primary {
  color: @primary;
}
/*防止下拉内容过多无法滚动*/
.cum_menu {
  overflow: auto;
  max-height: 90vh;
}
</style>
