<template>
  <div>
    <ChangeRecord
      v-if="visible"
      title="成长值变更记录"
      :columns="columns"
      :data="data"
      :total="total"
      :is-show-grow-change-type="true"
      @dataChange="dataChange"
      @onSearch="onSearch"
    ></ChangeRecord>
  </div>
</template>

<script>
import ChangeRecord from "@/components/ChangeRecord";
import { mapGetters } from "vuex";
import { growthRecord } from "@/api/member"; // MS-会员中心接口

const columns = [
  {
    title: "变更时间",
    scopedSlots: { customRender: "createTime" },
    width: 180
  },
  { title: "单据编号", dataIndex: "totalOrderId", width: 190 },
  { title: "业务类型", dataIndex: "memberBusinessTypeText", width: 100 },
  { title: "交易商户", dataIndex: "merchantName", width: 200 },
  { title: "交易渠道", dataIndex: "tradingTypeText", width: 100 },
  { title: "变更类型", dataIndex: "changeTypeText", width: 150 },
  { title: "变更成长值", dataIndex: "changeNum", width: 100 },
  { title: "变更后成长值", dataIndex: "changeTotal", width: 100 },
  { title: "操作人", dataIndex: "creatorName", width: 120 },
  { title: "变更备注", dataIndex: "remark", width: 150 }
];

export default {
  name: "ScoreChangeRecord",
  components: {
    ChangeRecord
  },
  data() {
    return {
      columns, // 表格表头
      data: [], // 表格数据列表
      // 接口参数
      interFaceParams: {
        page: 1,
        size: 10
      },
      total: 0, // 总条数
      visible: false // 是否显示
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getGrowthRecordList(); // 查询积分变更记录
  },
  methods: {
    // 查询积分变更记录
    getGrowthRecordList() {
      this.interFaceParams.id = this.memberId;
      growthRecord(this.interFaceParams)
        .then(res => {
          this.data = res.records;
          this.total = res.total;
          this.visible = true; // 等父组件数据回来再显示子组件
        })
        .catch(() => {});
    },

    // 分页变化时触发
    dataChange(pagination) {
      this.interFaceParams.page = pagination.current;
      this.interFaceParams.size = pagination.pageSize;
      this.getGrowthRecordList();
    },

    // 搜索
    onSearch(params) {
      this.interFaceParams.beginTime = params.beginTime;
      this.interFaceParams.endTime = params.endTime;
      this.interFaceParams.totalOrderId = params.orderNo;
      this.interFaceParams.changeType = params.changeType;
      this.getGrowthRecordList();
    }
  }
};
</script>
