<!--订单明细页-->
<template>
  <div class="wrapper flex-between flex-col">
    <div class="header ">
      <a-form-model layout="inline" :model="searchForm" ref="searchForm">
        <a-form-model-item label="下单日期">
          <a-range-picker
            :allow-clear="false"
            class="input"
            v-model="dateRange"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="订单号">
          <a-input
            v-model.trim="searchForm.keyword"
            placeholder="订单号/总单号/外部单号/票号"
            class="input"
            allow-clear
            ref="orderListRef"
            @pressEnter="reload"
          >
          </a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="销售商户">
        <a-select value='' onChange=''>
          <a-option value=""></a-option>
          <a-option value=""></a-option>
        </a-select>
  
        </a-form-model-item> -->
        <a-form-model-item>
          <a-button type="primary" @click="reload">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <section class="main mt-2x">
      <!--表格-->
      <a-table
        :scroll="{ x: 1366, y: tableHeight }"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        rowKey="id"
      >
        <template
          slot="appointmentPeriodStartTime"
          slot-scope="{ appointmentPeriodStartTime }"
          >{{
            appointmentPeriodStartTime | date("YYYY-MM-DD hh:mm:ss")
          }}</template
        >
        <template slot="totalAmount" slot-scope="{ totalAmount }">{{
          totalAmount | money
        }}</template>
        <template slot="actualAmount" slot-scope="{ actualAmount }">{{
          actualAmount | money
        }}</template>
        <template slot="discountAmount" slot-scope="{ discountAmount }">{{
          discountAmount | money
        }}</template>
        <template slot="totalRefundAmount" slot-scope="{ totalRefundAmount }">{{
          totalRefundAmount | money
        }}</template>
        <template slot="noRefundAmount" slot-scope="{ noRefundAmount }">{{
          noRefundAmount | money
        }}</template>
        <template slot="totalRefundAmount" slot-scope="{ totalRefundAmount }">{{
          totalRefundAmount | money
        }}</template>
        <template
          slot="integralDeductionAmount"
          slot-scope="{ integralDeductionAmount }"
          >{{ integralDeductionAmount | money }}</template
        >
        <span class="primary" slot="useDate" slot-scope="{ useDate }">{{
          useDate | date
        }}</span>
        <template slot="action" slot-scope="row">
          <a-button
            type="link"
            :disabled="row.orderStatus == 8"
            class="p-0 mr-1x"
            @click="onRefund(row)"
            >退款</a-button
          >
        </template>
      </a-table>
      <!--分页-->
      <div class="flex-end p-2x">
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
    </section>
    <!--退款-->
    <order-refund
      :visible.sync="refundVisible"
      :order-id="refundType === 3 ? ticketDepositOrderId : orderId"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
// /api/order/refund 退款
import { orderOrderDetail } from "@/api/search";
import { dataDictionary } from "@/api/setting";
import moment from "moment";
import { changFouce } from "@/utils/global";
import { orderSaleChannel } from "../../../api/merchant";
import { payWayForOrderQuery } from "@/api/pay";
import OrderRefund from "../order/components/OrderRefund";

export default {
  name: "OrderDetailList",
  components: { OrderRefund },
  data() {
    return {
      //实时屏幕高度
      tableHeight: this.$tableHeight(64, 64, 54, 36, 64, 50),
      moment,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      dateRange: [moment(), moment()],
      searchForm: {
        page: 1,
        size: 10,
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        keyword: "", // 总单号或外部订单号
        buyChannel: "", // 销售渠道
        status: "", //订单状态
        payWay: "", // 支付方式
        visitors: "", // 出游人
        orderType: 1, //订单类型，查询用
        purchaserMemberId: "", //消费会员编号
        purchaserMemberPhone: "", //会员手机号
        remarks: "", //订单备注
        marketingStaffId: "", //营销员编号
        salespersonName: "", //操作人
        myOrder: 1 // 查询我的订单 myOrder 1 我的 0 不是我的
      },
      // visibleSearch: false, // 高级查询弹窗
      buyChannel: [], // 销售渠道
      paymentType: [], // 支付方式
      status: [], // 订单状态
      // 订单表格
      total: 0, // 总条数
      columns: [
        {
          title: "订单号",
          dataIndex: "id",
          key: "id",
          width: 182
        },
        {
          title: "总订单号",
          dataIndex: "mainOrderId",
          key: "mainOrderId",
          width: 120
        },
        {
          title: "业态",
          dataIndex: "productFormsName",
          key: "productFormsName",
          width: 120
        },
        {
          title: "销售员",
          dataIndex: "salespersonName",
          key: "salespersonName",
          width: 120
        },
        {
          title: "会员手机号",
          dataIndex: "purchaserMemberPhone",
          key: "purchaserMemberPhone",
          width: 120
        },
        {
          title: "业务类型",
          dataIndex: "orderBusinessTypeText",
          key: "orderBusinessTypeText",
          width: 170
        },
        {
          title: "产品编号",
          dataIndex: "productId",
          key: "productId",
          width: 120
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 120
        },
        {
          title: "产品规格",
          dataIndex: "productSpec",
          key: "productSpec",
          width: 120
        },

        {
          title: "产品类型",
          dataIndex: "productCategoryName",
          key: "productCategoryName",
          width: 120
        },
        {
          title: "产品分类",
          dataIndex: "productTypeName",
          key: "productTypeName",

          width: 120
        },
        {
          title: "产品数量",
          dataIndex: "buyNum",
          key: "buyNum",
          width: 120
        },
        {
          title: "销售渠道",
          dataIndex: "buyChannelsText",
          key: "buyChannelsText",
          width: 120
        },
        {
          title: "应收金额",
          scopedSlots: { customRender: "totalAmount" },
          key: "totalAmount",
          width: 120
        },
        {
          title: "实收金额",
          key: "actualAmount",
          scopedSlots: { customRender: "actualAmount" },
          width: 120
        },
        {
          title: "优惠活动",
          key: "specialActivity",
          dataIndex: "specialActivity",
          width: 120
        },

        {
          title: "优惠金额",
          key: "discountAmount",
          scopedSlots: { customRender: "discountAmount" },
          width: 120
        },
        {
          title: "积分抵扣",
          key: "integralDeductionAmount",
          dataIndex: "integralDeductionAmount",
          width: 120
        },
        {
          title: "已退数量",
          key: "totalRefundNum",
          dataIndex: "totalRefundNum",
          width: 120
        },
        {
          title: "已退款金额",
          key: "totalRefundAmount",
          scopedSlots: { customRender: "totalRefundAmount" },
          width: 120
        },
        {
          title: "未退金额",
          key: "noRefundAmount",
          scopedSlots: { customRender: "noRefundAmount" },
          width: 120
        },
        {
          title: "订单状态",
          key: "orderStatusText",
          dataIndex: "orderStatusText",
          width: 120
        },
        {
          title: "订单备注",
          key: "remarks",
          dataIndex: "remarks",
          width: 120
        },

        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          width: 90
        }
      ], //表头
      tableData: [],
      // 退款信息
      refundVisible: false, // 退款弹窗
      orderId: "", // 订单编号
      ticketDepositOrderId: "", // 退押金需要用到的id
      refundIdType: 1, // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: 2, // 退款类型 1退单， 2退款， 3退押金
      productName: "", // 退款产品名称
      productCategoryId: "" // 产品业务类型id
    };
  },
  created() {
    this.loadPayWay(); // 获取支付方式
    this.loadKeys(); // 获取订单状态
    this.loadSaleChannel(); // 获取销售渠道
    this.loadData();
    changFouce(this, "orderListRef");
  },
  methods: {
    onRefund(row) {
      this.orderId = row.mainOrderId;
      this.refundIdList = [row.id];
      this.productCategoryId = "1365211690130337793";
      this.refundVisible = true;
    },
    // 退款成功, 刷新数据
    refunded() {
      this.loadData();
    },
    //查询支付方式
    loadPayWay() {
      payWayForOrderQuery()
        .then(res => {
          // 过滤出只有交账
          if (res) {
            this.paymentType =
              res.filter(item => item.payWayCode == "OnCredit") || [];
            // this.searchForm.payWay = "";
            this.searchForm.payWay = this.paymentType[0].payWayQuery;
          }
        })
        .catch(() => {});
    },
    // 字典数据 ：payWay 支付方式旧的已废弃
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,orderStatus"
      })
        .then(data => {
          this.status = data.orderStatus;
        })
        .catch(() => {});
    },
    // 加载销售渠道
    loadSaleChannel() {
      orderSaleChannel()
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },
    //加载table数据
    loadData() {
      const obj = { ...this.searchForm };
      orderOrderDetail(
        obj.keyword ? { keyword: obj.keyword, myOrder: obj.myOrder } : obj
      )
        .then(({ records, total }) => {
          this.total = total;
          this.tableData = records;
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.searchForm.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 查询
    reload() {
      this.visibleSearch = false;
      this.searchForm.page = 1;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.searchForm.orderBeginTime = moment()
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment()
        .endOf("date")
        .valueOf();
      this.searchForm.keyword = "";
      this.searchForm.buyChannel = "";
      this.searchForm.status = "";
      this.searchForm.visitors = "";
      this.searchForm.remarks = "";
      this.searchForm.purchaserMemberId = "";
      this.searchForm.purchaserMemberPhone = "";
      this.searchForm.displayRender = "";
      this.searchForm.marketingStaffId = "";
      this.searchForm.salespersonName = "";
      this.reload();
    },
    // 条数改变
    sizeChange(current, size) {
      this.searchForm.page = 1;
      this.searchForm.size = size;
      this.reload();
    },
    // 销售渠道选择
    fromChange(value, selectOptions) {
      this.searchForm.buyChannel = selectOptions[selectOptions.length - 1].id;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    }
  }
};
</script>
<style lang="less" scoped>
.input {
  width: 220px;
}
/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
