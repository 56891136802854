<template>
  <div class="wrapper flex-start flex-col">
    <!-- 搜索组件 -->
    <SearchTool
      ref="SearchTool"
      :filters="isfilters"
      :tabActive="tabActive"
      @changeSearchTab="changeSearchTab"
      @loadData="loadData"
    >
    </SearchTool>
    <div class="main mt-2x">
      <currency-table
        :order-begin-time="formData.payBeginTime"
        :order-end-time="formData.payEndTime"
        :filter-api="orderOrderRecordCondition"
        :columns="filterColumns"
        :data-source="tableData.data"
        :check-searchs.sync="checkSearchs"
        @filter-change="filterChange"
      >
        <template slot="action">
          <a-table-column width="150px" title="操作" fixed="right">
            <div slot-scope="row">
              <a-button
                v-permission="'appOrderNewOrderDetail'"
                class="ph-x"
                type="link"
                @click="toDetail(row)"
                >详情</a-button
              >
              <a-dropdown @click="e => e.preventDefault()">
                <span class="pointer primary">
                  更多操作
                  <a-icon type="down" />
                </span>
                <a-menu slot="overlay" @click="dropdownClick($event, row)">
                  <a-menu-item
                    v-permission="'appOrderNewChargeback'"
                    :disabled="!chargeBackList.includes(row.orderStatus)"
                    key="refund"
                    >退单</a-menu-item
                  >
                  <a-menu-item
                    key="writeOff"
                    v-permission="'appOrderNewForcedchargeback'"
                    >强制退单</a-menu-item
                  >
                  <a-menu-item
                    key="closeoutAccount"
                    :disabled="row.payWayCode !== 'OnCredit'"
                    v-permission="'appOrderNewSettleaccounts'"
                    >清账</a-menu-item
                  >
                </a-menu>
              </a-dropdown>
            </div>
          </a-table-column>
        </template>
      </currency-table>
      <!--分页-->
      <div class="flex-between p-2x">
        <div>
          <div class="flex-start">
            <span class="bold flex-shrink">汇总：</span>
            <span class="flex-shrink">总单实收总额：</span>
            <span class="red flex-shrink">{{ amountSum | money("") }}元</span>
            <span class="ml-1x gray" v-if="payWayInfo">({{ payWayInfo }})</span>
            <div class="flex-shrink mh-3x">
              总单数：<span>{{ tableData.total }} 单</span>
            </div>
          </div>
          <span class="red">说明：总单实收总额未减去退款，并非实收</span>
        </div>
        <div class="flex-shrink">
          <a-pagination
            v-model="tableData.page"
            :default-page-size="tableData.size"
            :total="tableData.total"
            :page-size-options="['10', '20', '50', '100']"
            show-size-changer
            @change="pageChange"
            @showSizeChange="sizeChange"
          />
        </div>
      </div>
    </div>
    <!-- 退款弹窗 -->
    <refund-public
      :title="title"
      :refund-show="refundShow"
      :refund-info="refundInfo"
      @success="refundOk"
      @cancel="refundShow = false"
    ></refund-public>
    <!-- 强制退款弹窗 -->
    <force-refund-public
      :title="forceTitle"
      :refund-show="forceRefundShow"
      @success="forceRefundOk"
      @cancel="forceRefundShow = false"
    ></force-refund-public>
    <!-- 清账 -->
    <closeout-account
      :visible.sync="showCloseoutAccount"
      :order-id="orderId"
      ref="CloseoutAccount"
    ></closeout-account>
  </div>
</template>

<script>
import SearchTool from "./components/SearchTool";
import CurrencyTable from "@/components/table/CurrencyTable";
import { orderSalesorderRecord, orderOrderRecordCondition } from "@/api/search";
import { refundOrderCheck, refundOrder, refundOrderForce } from "@/api/order";
import RefundPublic from "./components/RefundPublic.vue";
import ForceRefundPublic from "./components/ForceRefundPublic.vue";
import CloseoutAccount from "../../../components/sell/commonents/CloseoutAccount";
import moment from "moment";
export default {
  name: "OrderList",
  components: {
    SearchTool,
    CurrencyTable,
    RefundPublic,
    ForceRefundPublic,
    CloseoutAccount
  },
  data() {
    return {
      orderOrderRecordCondition,
      moment,
      fullPath: "", //路由路径地址
      orderId: "", // 订单id
      title: "退整单",
      forceTitle: "强制退单",
      formData: {}, // 搜索表单
      tabActive: 0,
      isfilters: [],
      amountSum: 0, // 销售额
      payWayInfo: "",
      checkSearchs: [], // 表格头过滤后选中的列表数组
      // 这是订单状态：1未支付 2支付中 3已支付 4待发货 5待收货 6已完成 7已取消 8已退款 9部分退款 10退款中 11退款审核中 12退款失败 13挂账 14 清账 15支付失败 16未使用 17已使用 18部分使用 19强制退款 10部分强制退款
      chargeBackList: [3, 9, 16, 18], // 退单按钮可选中状态值
      refundShow: false, // 整单退款弹框
      refundInfo: {}, // 整单退款校验信息
      forceRefundShow: false, // 强制退单弹框
      filtersList: [
        {
          label: "支付时间",
          key: "main_pay_time",
          value: [moment(), moment()],
          type: "time",
          placeholder: ""
        },
        {
          label: "下单时间",
          key: "main_create_time",
          value: [],
          type: "time",
          placeholder: ""
        },
        {
          label: "开票时间",
          key: "invoice_time",
          value: "",
          type: "time",
          placeholder: ""
        },
        {
          label: "",
          key: "subFilters",
          width: "325px",
          value: "",
          type: "subFilters",
          placeholder: "请输入单号/产品编号/会员手机号/会员证件号"
        }
      ],
      //搜索条件
      tableData: {
        data: [],
        columns: [
          {
            key: "id",
            title: "总单号",
            show: true,
            fixed: "left",
            width: 200
          },
          {
            key: "ownerFormatTypeInfos",
            title: "总单业态集",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "ownerFormatType",
            fieldList: []
          },
          {
            key: "ownerMerchants",
            title: "总单商户集",
            show: true,
            width: 200,
            ellipsis: true,
            filters: true, // 开启表格头部过滤
            filterKey: "belongToMerchant",
            fieldList: []
          },
          {
            key: "salespersonName",
            title: "销售员",
            show: true,
            width: 100,
            filters: true, // 开启表格头部过滤
            filterKey: "salesperson",
            fieldList: []
          },
          {
            key: "purchaserMemberPhone",
            title: "会员手机号",
            show: true,
            width: 120
          },
          {
            key: "orderBusinessTypeText",
            title: "业务类型",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "orderBusinessType",
            fieldList: []
          },
          {
            key: "payTime",
            title: "支付时间",
            show: true,
            width: 170,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "createTime",
            title: "下单时间",
            show: true,
            width: 170,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "actualAmount",
            title: "总单实收",
            primary: "red", // 设置金额红色
            show: true,
            width: 100,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "totalRefundAmount",
            title: "总单退款金额",
            primary: "red", // 设置金额红色
            show: true,
            width: 120,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "balanceAmount",
            title: "总单实收余额",
            primary: "red", // 设置金额红色
            show: true,
            width: 120,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "specialActivity",
            title: "总单优惠活动",
            show: true,
            width: 150
          },
          {
            key: "payWayName",
            title: "支付方式",
            show: true,
            width: 135,
            filters: true, // 开启表格头部过滤
            filterKey: "payWayInfo",
            fieldList: []
          },
          {
            key: "payChannels",
            title: "支付通道",
            show: true,
            width: 135
          },
          {
            key: "buyChannelsText",
            title: "下单渠道",
            show: true,
            width: 135,
            filters: true, // 开启表格头部过滤
            filterKey: "buyChannels",
            fieldList: []
          },
          {
            key: "groupName",
            title: "团体名称",
            show: true,
            width: 135
          },
          {
            key: "groupType",
            title: "团体类型",
            show: true,
            width: 120
          },
          {
            key: "orderStatusText",
            title: "总单状态",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "orderStatus",
            fieldList: []
          },
          {
            key: "outsideCode",
            title: "外部单号",
            show: true,
            width: 100
          },
          {
            key: "billingStatus",
            title: "总单开票状态",
            show: true,
            width: 120
          },
          {
            key: "billingTime",
            title: "总单开票时间",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date" }
          }
        ],
        multipleSelection: [],
        // multiple:false,
        total: 1,
        page: 1,
        size: 10
      },
      showCloseoutAccount: false // 显示清账
    };
  },
  created() {
    this.fullPath = this.$route.fullPath;
  },
  mounted() {
    this.changeSearchTab(0);
    this.$nextTick(() => {
      this.$refs.SearchTool.loadData();
    });
  },
  computed: {
    filterColumns() {
      return this.tableData.columns.filter(column => column.show);
    }
  },
  methods: {
    getDataList() {
      let obj = this.filtration();
      orderSalesorderRecord(obj).then(res => {
        let status = {
          0: "未开票",
          4: "已开票",
          5: "已红冲"
        };
        res.records.forEach(item => {
          // 1未支付,2支付中，3已支付，4代发货，5待收货，6已完成，7已取消，8已退款，9部分退款，10退款中，13挂账，15支付失败，16未使用，17已使用，18部分使用
          // 总单开票状态本地转换
          item.billingStatus = status[item.billingStatus];
        });
        this.tableData.data = res.records;
        this.amountSum = res.actualAmountSum || 0;
        this.payWayInfo = res.payWay;
        this.tableData.total = res.total;
      });
    },
    filtration() {
      let formats = "";
      let merchants = "";
      let salesperson = "";
      let businessType = "";
      let favourableActivity = "";
      let payment = "";
      let orderChannel = "";
      // let groupName = "";
      // let groupType = "";
      let status = "";
      if (this.checkSearchs.length) {
        this.checkSearchs.forEach(item => {
          switch (item.column.filterKey) {
            case "ownerFormatType":
              formats = item.tagList.map(tem => tem.key).join(",");
              break;
            case "belongToMerchant":
              merchants = item.tagList.map(tem => tem.key).join(",");
              break;
            case "salesperson":
              salesperson = item.tagList.map(tem => tem.key).join(",");
              break;
            case "orderBusinessType":
              businessType = item.tagList.map(tem => tem.key).join(",");
              break;
            case "discountActivity":
              favourableActivity = item.tagList.map(tem => tem.key).join(",");
              break;
            case "payWayInfo":
              payment = item.tagList.map(tem => tem.key).join(",");
              break;
            case "buyChannels":
              orderChannel = item.tagList.map(tem => tem.key).join(",");
              break;
            // case "distributorGroup":
            //   groupName = item.tagList.map(tem => tem.key).join(",");
            //   break;
            // case "groupType":
            //   groupType = item.tagList.map(tem => tem.key).join(",");
            //   break;
            case "orderStatus":
              status = item.tagList.map(tem => tem.key).join(",");
              break;
          }
        });
      }
      let info = {
        page: this.tableData.page,
        size: this.tableData.size,
        // 开票时间 缺少
        formats, // 总单业态集
        merchants, // 总单商务集
        salesperson, // 销售员
        businessType, // 业务类型
        favourableActivity, // 总单优惠活动
        payment, // 支付方式
        orderChannel, // 下单渠道
        // groupName, // 团体名称
        // groupType, // 团体类型
        status, // 总单状态
        ...this.formData
        // billingStatus: "" // 总开开票状态
        // 外部单号缺少 （不作筛选条件 忽略）
      };
      return info;
    },
    // 2022-03-24 start
    changeSearchTab(value) {
      this.tabActive = value;
      if (value) {
        //单号
        this.isfilters = this.filtersList.filter(
          item => item.type === "subFilters"
        );
      } else {
        //时间
        this.isfilters = this.filtersList.filter(item => item.type === "time");
      }
    },
    loadData(val) {
      this.tableData.columns.forEach(item => {
        if (item.fieldList && item.fieldList.length) {
          item.fieldList = [];
        }
      });
      this.checkSearchs = [];
      this.tableData.page = 1;
      // //0时间 1单号
      this.formData = {};
      if (this.tabActive) {
        this.formData.keyword = val.subFilters;
      } else {
        // 支付时间
        if (val.main_pay_time) {
          this.formData.payBeginTime = val.main_pay_time.length
            ? moment(val.main_pay_time[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.payEndTime = val.main_pay_time.length
            ? moment(val.main_pay_time[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        //下单时间
        if (val.main_create_time) {
          this.formData.orderBeginTime = val.main_create_time.length
            ? moment(val.main_create_time[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.orderEndTime = val.main_create_time.length
            ? moment(val.main_create_time[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        if (val.invoice_time) {
          //开票开始时间
          this.formData.billingBeginTime = val.invoice_time.length
            ? moment(val.invoice_time[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.billingEndTime = val.invoice_time.length
            ? moment(val.invoice_time[1])
                .endOf("date")
                .valueOf()
            : "";
        }
      }
      this.getDataList();
    },
    pageChange(current) {
      this.tableData.page = current;
      this.getDataList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.tableData.page = current;
      this.tableData.size = size;
      this.getDataList();
    },
    // 表格过滤触发的时间
    filterChange() {
      this.tableData.page = 1;
      this.getDataList();
    },
    // 退单校验
    chargeBack(id) {
      this.orderId = id;
      refundOrderCheck({
        mainOrderId: id
      })
        .then(res => {
          this.refundShow = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    forceChargeBack(id) {
      this.orderId = id;
      this.forceRefundShow = true;
    },
    // 完成退款
    refundOk(obj) {
      this.refundShow = false;
      refundOrder({
        mainOrderId: this.orderId,
        remarks: obj.remarks
      })
        .then(res => {
          this.getDataList();
          this.$message.success(
            res.refundFailMsg ? res.refundFailMsg : "退单成功！"
          );
        })
        .catch(() => {});
    },
    // 强制完成退款
    forceRefundOk(obj) {
      this.forceRefundShow = false;
      refundOrderForce({
        mainOrderId: this.orderId,
        remarks: obj.remarks
      })
        .then(() => {
          this.getDataList();
          this.$message.success("强制退单成功！");
        })
        .catch(() => {});
    },
    toDetail(row) {
      // 去详情
      sessionStorage.setItem(
        "orderManageOrderDetail",
        JSON.stringify({
          id: row.id,
          ownerFormatTypeInfos: row.ownerFormatTypeInfos,
          ownerMerchants: row.ownerMerchants,
          salespersonName: row.salespersonName,
          orderBusinessTypeText: row.orderBusinessTypeText,
          createTime: row.createTime,
          actualAmount: row.actualAmount,
          totalRefundAmount: row.totalRefundAmount,
          balanceAmount: row.balanceAmount,
          specialActivity: row.specialActivity,
          payWayName: row.payWayName,
          payChannels: row.payChannels,
          buyChannelsText: row.buyChannelsText,
          groupName: row.groupName,
          groupType: row.groupType,
          orderStatusText: row.orderStatusText,
          outsideCode: row.outsideCode,
          billingStatus: row.billingStatus,
          billingTime: row.billingTime,
          purchaserMemberName: row.purchaserMemberName,
          purchaserMemberPhone: row.purchaserMemberPhone
        })
      );
      this.$router.push({
        path: "/home/orderNew/OrderDetail",
        query: {
          orderId: row.id,
          fullPath: this.fullPath
        }
      });
    },
    // 更多操作
    dropdownClick({ key }, row) {
      switch (key) {
        case "refund":
          this.chargeBack(row.id);
          break;
        case "writeOff":
          this.forceChargeBack(row.id);
          break;
        case "closeoutAccount":
          this.orderId = row.id;
          this.$nextTick(() => {
            this.$refs.CloseoutAccount.onSearch();
            this.showCloseoutAccount = true;
          });
          break;
      }
    }
  }
};
</script>
