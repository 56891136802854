<template>
  <div class="wrapper flex-between flex-col">
    <!-- 头部 -->
    <panel-head title="查看优惠券信息"></panel-head>
    <!-- 主体 -->
    <div class="main mt-2x">
      <a-tabs
        class="p-2x"
        default-active-key="1"
        type="card"
        tabPosition="top"
        @change="tabChange"
      >
        <a-tab-pane key="1" tab="可用">
          <div v-if="notUsedList.length">
            <a-row :gutter="[24, 24]">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in notUsedList"
                :key="index"
              >
                <div class="coupon-wrapper">
                  <header class="coupon-amount">
                    <b class="font-xxl">
                      {{ item.discountedPriceText }}
                    </b>
                    <p class="mv-1x">{{ item.useConditionsText }}</p>
                    <p class="date">
                      {{ item.activityStartDate | date }} ~
                      {{ item.activityEndDate | date }}
                    </p>
                    <span class="iconfont icon-keshiyong icon-tip"></span>

                    <div class="circle"></div>
                    <div class="circle left"></div>
                  </header>

                  <div class="p-2x">
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券名称：</a-col>
                      <a-col :span="18">{{ item.giftCouponNames }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券编号：</a-col>
                      <a-col :span="18">{{ item.giftCouponId }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">备注：</a-col>
                      <a-col :span="18" class="gray text-1">{{
                        item.remark
                      }}</a-col>
                    </a-row>
                    <div class="mb-2x text-r">
                      <span
                        class="pointer blue"
                        @click="getMarketingActivity(item.id)"
                        >查看全部适用产品</span
                      >
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-empty v-else />
        </a-tab-pane>
        <a-tab-pane key="2" tab="已过期">
          <div v-if="expiredList.length">
            <a-row :gutter="[24, 24]">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in expiredList"
                :key="index"
              >
                <div class="coupon-wrapper">
                  <header class="coupon-amount disabled">
                    <b class="font-xxl">
                      {{ item.discountedPriceText }}
                    </b>
                    <p class="mb-2x">{{ item.useConditionsText }}</p>
                    <p class="date">
                      {{ item.activityStartDate | date }} ~
                      {{ item.activityEndDate | date }}
                    </p>
                    <span class="iconfont iconyiguoqi2 icon-tip"></span>

                    <div class="circle"></div>
                    <div class="circle left"></div>
                  </header>
                  <div class="p-2x">
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券名称：</a-col>
                      <a-col :span="18">{{ item.giftCouponNames }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">券编号：</a-col>
                      <a-col :span="18">{{ item.giftCouponId }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">备注：</a-col>
                      <a-col :span="18" class="gray text-1">{{
                        item.remark
                      }}</a-col>
                    </a-row>
                    <div class="mb-2x text-r">
                      <span
                        class="pointer blue"
                        @click="getMarketingActivity(item.id)"
                        >查看全部适用产品</span
                      >
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-empty v-else />
        </a-tab-pane>
        <a-tab-pane key="3" tab="已使用">
          <div v-if="usedList.length" class="coupon-list">
            <a-row :gutter="[24, 24]">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in usedList"
                :key="index"
              >
                <div class="coupon-wrapper ">
                  <header class="coupon-amount disabled">
                    <b class="font-xxl">
                      {{ item.discountedPriceText }}
                    </b>
                    <p class="mv-1x">{{ item.useConditionsText }}</p>
                    <p class="date">
                      {{ item.activityStartDate | date }} ~
                      {{ item.activityEndDate | date }}
                    </p>
                    <span class="iconfont iconyishiyong3 icon-tip"></span>

                    <div class="circle"></div>
                    <div class="circle left"></div>
                  </header>
                  <div class="p-2x">
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券名称：</a-col>
                      <a-col :span="18">{{ item.giftCouponNames }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券编号：</a-col>
                      <a-col :span="18">{{ item.giftCouponId }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">备注：</a-col>
                      <a-col :span="18" class="gray text-1">{{
                        item.remark
                      }}</a-col>
                    </a-row>
                    <div class="mb-2x text-r">
                      <span
                        class="pointer blue"
                        @click="getMarketingActivity(item.id)"
                        >查看全部适用产品</span
                      >
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-empty v-else />
        </a-tab-pane>
        <a-tab-pane key="4" tab="已取消">
          <div v-if="cancelledList.length">
            <a-row :gutter="[24, 24]">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="(item, index) in usedList"
                :key="index"
              >
                <div class="coupon-wrapper">
                  <header class="coupon-amount disabled">
                    <b class="font-xxl">
                      {{ item.discountedPriceText }}
                    </b>
                    <p class="mb-2x">{{ item.useConditionsText }}</p>
                    <p class="date">
                      {{ item.activityStartDate | date }} ~
                      {{ item.activityEndDate | date }}
                    </p>
                    <span class="iconfont iconyiquxiao9 icon-tip"></span>
                  </header>
                  <div class="p-2x">
                    <a-row class="mb-2x">
                      <a-col :span="6" class="text">券名称：</a-col>
                      <a-col :span="18">{{ item.giftCouponNames }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">券编号：</a-col>
                      <a-col :span="18">{{ item.giftCouponId }}</a-col>
                    </a-row>
                    <a-row class="mb-2x">
                      <a-col :span="6">备注：</a-col>
                      <a-col :span="18" class="gray text-1">{{
                        item.remark
                      }}</a-col>
                    </a-row>
                    <div class="mb-2x text-r">
                      <span
                        class="pointer blue"
                        @click="getMarketingActivity(item.id)"
                        >查看全部适用产品</span
                      >
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <a-empty v-else />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        type="primary"
        shape="round"
        class="btn-lg"
        size="large"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
    </footer>
    <!-- 适用产品 弹窗 -->
    <a-modal
      v-model="showLookAppProducts"
      title="查看适用产品"
      :footer="null"
      :width="1000"
    >
      <look-app-products :merchantIds="merchantIds"></look-app-products>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "@/components/common/PanelHead";
import LookAppProducts from "./components/LookAppProducts";
import { mapGetters } from "vuex";
import { marketingCouponList, getMarketingActivityById } from "@/api/marketing";

export default {
  name: "MemberCoupon",
  components: { PanelHead, LookAppProducts },
  data() {
    return {
      showLookAppProducts: false, //查看适用产品
      merchantIds: [], //商户id数组
      notUsedList: [], // 未使用优惠券列表
      usedList: [], // 已使用优惠券列表
      expiredList: [], // 已过期优惠券列表
      cancelledList: [] // 已取消优惠券列表
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getNotUsedList(); // 获取未使用优惠券列表
  },
  methods: {
    // 获取未使用优惠券列表
    getNotUsedList() {
      marketingCouponList({ memberId: this.memberId, status: 1 })
        .then(res => {
          this.notUsedList = res;
        })
        .catch(() => {});
    },

    // 获取已使用优惠券列表
    getUsedList() {
      marketingCouponList({ memberId: this.memberId, status: 2 })
        .then(res => {
          this.usedList = res;
        })
        .catch(() => {});
    },

    // 获取已过期优惠券列表
    getExpiredList() {
      marketingCouponList({ memberId: this.memberId, status: 3 })
        .then(res => {
          this.expiredList = res;
        })
        .catch(() => {});
    },

    // 获取已取消优惠券列表
    getCancelledList() {
      marketingCouponList({ memberId: this.memberId, status: 4 })
        .then(res => {
          this.cancelledList = res;
        })
        .catch(() => {});
    },

    // tab切换
    tabChange(key) {
      switch (key) {
        case "1":
          this.getNotUsedList(); // 获取未使用优惠券列表
          break;
        case "2":
          this.getExpiredList(); // 获取已过期优惠券列表
          break;
        case "3":
          this.getUsedList(); // 获取已使用优惠券列表
          break;
        case "4":
          this.getCancelledList(); // 获取已取消优惠券列表
          break;
        default:
          this.$message.warning("出现未知错误，请联系管理员");
          break;
      }
    },

    // 根据活动id查询活动详情
    getMarketingActivity(id) {
      getMarketingActivityById({ id }).then(res => {
        this.showLookAppProducts = true;
        this.merchantIds = res.applicableProducts.map(item => {
          return item.products;
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom";
.coupon-wrapper {
  border-radius: 5px;
  box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
  height: 298px;
  background: @primary-light2;
  .coupon-amount {
    border-radius: 5px;
    position: relative;
    padding: 16px;
    text-align: center;
    background: @primary;
    border-bottom: 1px dashed @border;
    color: #fff;
    &.disabled {
      background: #eee;
      color: @disabled;
    }
    .circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: -10px;
      bottom: -10px;
      background: #fff;
      &.left {
        left: unset;
        right: -10px;
      }
    }
  }
  .icon-tip {
    position: absolute;
    right: 16px;
    bottom: 0;
    font-size: 60px;
  }
}
</style>
