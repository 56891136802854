<template>
  <div class="wrapper flex-between flex-col">
    <a-row class="flex-between main">
      <a-col :span="6">
        <!--选择时段-->
        <date-list
          @hand-time="handTime"
          @changeTimeData="changeTimeData"
          @clear="clear"
        ></date-list>
      </a-col>
      <a-col class="flex-grow">
        <!--选择产品加入购物车:span="12"-->
        <list
          ref="SaleDetail"
          :sub-scenic-id="subScenicId"
          :sub-scenic-name="subScenicName"
          :play-date="playDate"
          :time-data="timeData"
          :cart-id="cartId"
          @update-cart="updateCart"
        ></list>
      </a-col>
      <a-col>
        <!-- 右侧购物车 -->
        <combination-cart
          ref="cart"
          @cancel-cart="cancelCart"
          class="res"
        ></combination-cart>
      </a-col>
    </a-row>

    <combin-my-footer />
  </div>
</template>
<script>
import CombinationCart from "../../combination-product/sell/components/CombinationCart.vue";
import DateList from "./DateList.vue";
import List from "./List.vue";
import CombinMyFooter from "@/components/hardware/myfooter";

export default {
  components: { DateList, CombinationCart, List, CombinMyFooter },
  name: "ticketHomePage",
  props: {},
  data() {
    return {
      // 13809858591
      // 450422199011140813
      subScenicId: "", // 子景区id
      subScenicName: "", // 子景区名称
      playDate: 0, // 游玩日期
      timeData: {}, // 时段
      cartId: 0
    };
  },
  methods: {
    handTime(subScenicId, subScenicName, playDate, timeData) {
      this.subScenicId = subScenicId;
      this.subScenicName = subScenicName;
      this.playDate = playDate;
      this.timeData = timeData;
      // 防止加载数据，props未更新及时
      this.$nextTick(() => {
        this.$refs.SaleDetail.reLoadData();
      });
    },
    // 自动切换下个时段-时段改变了
    changeTimeData(timeData) {
      this.timeData = timeData;
    },
    // 更新购物车
    updateCart(id) {
      this.cartId = id;
      this.$refs.cart.loadData(id);
    },
    // 取消购物车-整单取消
    cancelCart() {
      this.cartId = 0;
    },
    // 可预约时段为空 清空产品列表、购物车
    clear() {
      this.$refs.SaleDetail.clearData();
      this.$refs.cart.clearCart();
    }
  }
};
</script>
<style lang="less" scoped>
.date {
  // width: 433px;
  border-right: 1px solid #ebebeb;
}
.cart {
  border-left: 1px solid #ebebeb;
}
</style>
