<!--
@name: -票务新版-
@date: 2022-01-18
@author: 易远胜
-->
<template>
  <div>
    <div
      v-show="!showMember && !showPayOnly"
      class="flex-between flex-col wrapper-new"
    >
      <!--body-->
      <div class="main">
        <!--筛选表单-->
        <product-filter
          :cart-id="cart.id"
          @change="filterChange"
          @appointment="getAppointment"
          @clear="changeScenic"
          @update-date="updateCartDate"
          @update-time="updateCartAppointment"
          @date-range="dateRange"
        ></product-filter>

        <!--门票列表+购买信息-->
        <product-list
          ref="productList"
          @update-cart="updateCart"
          @change-cart="changeCart"
        ></product-list>

        <!--导游信息-->
        <product-guide
          v-if="getSettings.IsShowGroupOrder"
          ref="productGuide"
          :params="params"
          @add="guideChange"
          @del="guideDel"
        ></product-guide>

        <!--购物信息-->
        <product-player
          :format-type="6"
          ref="productPlayer"
          :cart="cart"
          :appointment="appointment"
          :times="times"
          :params="params"
          :start-date="startDate"
          :end-date="endDate"
          @update-cart="updateCart"
          @change-cart="changeCart"
          @update-add-buy="changeAddBuy"
          @update-player="updatePlayer"
          @update-fast="updateFast"
          @update-time="updateTime"
          @update-date="updateDate"
        ></product-player>

        <!--销售记录-->
        <product-sale-record
          ref="productSaleRecord"
          class="mb-2x"
        ></product-sale-record>
      </div>
      <!--底部下单按钮-->
      <product-calc-footer
        ref="productCalcFooter"
        @clear="clearCart"
        @member="showMember = true"
        @pay-only="showPayOnly = true"
        @change-cart="changeCart"
        @success="success"
      ></product-calc-footer>
    </div>

    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-if="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>

    <!--直接付款-->
    <pay-only :visible.sync="showPayOnly"></pay-only>
  </div>
</template>

<script>
import ProductFilter from "../../../components/sell/ProductFilter";
import ProductList from "../../../components/sell/ProductList";
import ProductPlayer from "../../../components/sell/ProductPlayer";
import ProductSaleRecord from "../../../components/sell/ProductSaleRecord";
import ProductCalcFooter from "../../../components/sell/ProductCalcFooter";
import ProductGuide from "../../../components/sell/ProductGuide";
import MemberDetail from "../member/MemberDetail.vue";
import PayOnly from "../../../components/sell/commonents/PayOnly";
import { productFrontSilkyTicket } from "../../../api/search";
import {
  cartTicket,
  postTicketAddBuy,
  ticketAppointmentDate,
  ticketDate,
  ticketFast,
  ticketGroupGuide,
  ticketMore,
  ticketTraveller,
  commonDeleteCart
} from "../../../api/cart";
import { mapGetters } from "vuex";
export default {
  name: "SellProduct",
  components: {
    ProductFilter,
    ProductList,
    ProductPlayer,
    ProductSaleRecord,
    ProductCalcFooter,
    ProductGuide,
    MemberDetail,
    PayOnly
  },

  data() {
    return {
      productFrontSilkyTicket,
      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        appointmentRules: "",
        timeId: "",
        name: "", // 产品名称
        categoryId: "" // 分类id
      },
      cart: {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      },
      showMember: false,
      appointment: 0, //分时预约选择 1.产品  2.子景区 3.景区
      times: [], //按子景区的时段列表
      showPayOnly: false,
      productList: [], // 产品列表
      // 下单规则-日期可选范围
      startDate: "",
      endDate: ""
    };
  },

  computed: {
    // ...mapGetters("common", ["memberInfo"]),
    ...mapGetters({
      memberInfo: "common/memberInfo",
      // 页面布局-团体下单
      getSettings: "setting/getSettings"
    })
  },

  // provide() {
  //   return {
  //     validate: this.validate
  //   };
  // },

  methods: {
    // 下单规则-日期可选范围
    dateRange(start, end) {
      this.startDate = start;
      this.endDate = end;
    },
    // 更改预约日期
    updateDate(date, time) {
      ticketDate(date)
        .then(res => {
          this.changeCart(res);
          // 更改完日期才能更改时段
          this.updateTime(time);
        })
        .catch(() => {});
    },
    // 更改预约时段
    updateTime(data) {
      ticketAppointmentDate(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 筛选条件改变
    filterChange(params) {
      this.params = params;
      this.$refs.productList.loadData(params);
    },
    // 添加/更新到购物车 - 调接口
    async updateCart(obj) {
      // 添加购物车
      let api = cartTicket;
      // 更新购物车
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        api = ticketMore;
      }
      // 添加会员信息
      else {
        // 如果已经有会员信息
        if (this.memberInfo.id) {
          obj.purchaserMemberId = this.memberInfo.id;
        }
        // 如果本地存有默认打印方式
        if (this.getSettings.PrintType) {
          obj.cartPrintType = this.getSettings.PrintType;
        }
      }

      let ok = true;
      await api(obj)
        .then(res => {
          if (res) {
            this.cart = res;
          }
          // 清空购物车
          else {
            this.clearCart();
          }
        })
        .catch(() => {
          ok = false;
        })
        .finally(() => {
          // 更新产品列表数量
          this.broadcast();
        });
      return ok;
    },
    // 已存在购物车，更新到购物车 - 不调接口
    changeCart(cart) {
      this.cart = cart;
      this.broadcast();
    },
    // 通知子组件购物车更新
    broadcast() {
      this.$refs.productList.updateNum(this.cart);
      this.$refs.productPlayer.updateCart(this.cart);
      this.$refs.productCalcFooter.updateCart(this.cart);
    },
    // 修改全局游玩日期
    updateCartDate(data) {
      ticketDate(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 修改全局预约时段
    updateCartAppointment(data) {
      ticketAppointmentDate(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 导游信息更新
    async guideChange({ groupTypeId, guideIds, guideProductId, managementId }) {
      let ok = await this.updateCart({
        playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        subScenicId: this.params.subScenicId,
        distributorGroupId: managementId, // 团体名称id 旅行社id
        groupTypeId, // 团体分类id
        guideIds, // 导游s
        guideProductId // 导游产品id
      });
      // 如果添加失败，则清空导游
      if (!ok) this.clearGuide(guideIds);
    },
    // 删除导游信息
    guideDel(obj) {
      if (this.cart.id) {
        obj.cartId = this.cart.id;
        ticketGroupGuide(obj)
          .then(res => {
            if (res.totalProductList.length === 0) {
              this.clearCart();
            } else {
              this.cart = res;
              this.broadcast();
            }
          })
          .catch(() => {});
      }
    },
    // 加购更新
    changeAddBuy(obj) {
      postTicketAddBuy({
        cartId: this.cart.id,
        ...obj
      })
        .then(res => {
          this.cart = res;
          this.broadcast();
        })
        .catch(() => {});
    },
    // 更新游客信息
    updatePlayer(data, callback) {
      ticketTraveller(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {
          callback();
        });
    },
    // 更新快速通行
    updateFast(data) {
      ticketFast(data)
        .then(res => {
          this.changeCart(res);
        })
        .catch(() => {});
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id,
        orderBusinessType: 0,
        scenicId: "",
        subScenicId: "",
        buyChannels: 0,
        totalPrice: 0,
        discountAmount: 0,
        settlementPrice: 0,
        playDate: 0,
        totalProductList: [], // 所有产品
        detailProductList: [] // 需要出游人的产品
      };
      this.broadcast();
    },
    // 切换景区
    changeScenic() {
      this.clearCart();
      this.clearGuide();
    },
    // 支付成功
    success() {
      // 刷新今日销售
      this.$refs.productSaleRecord.loadOrderSalesrecord();
      // 支付成功
      this.clearGuide();
      // 下单后清除购物车
      if (this.getSettings.IsDeleteCart) {
        this.deleteCart();
      } else {
        // 下单后清空会员信息
        this.$store.commit("common/UpdateMember", { id: "" });
      }
    },
    // 下单后清除购物车
    deleteCart() {
      commonDeleteCart({
        cartId: this.cart.id
      })
        .then(() => {
          this.clearCart();
          // 下单后清空会员信息
          this.$store.commit("common/UpdateMember", { id: "" });
        })
        .catch({});
    },
    // 清空导游信息
    clearGuide() {
      if (this.$refs.productGuide) this.$refs.productGuide.guideCheckData = [];
    },
    // 获取分时预约规则
    getAppointment(appointment, times) {
      this.appointment = appointment;
      this.times = times || [];
    },
    // 校验购物车表单
    validate() {
      return this.$refs.productPlayer.validate();
    }
  }
};
</script>

<style scoped lang="less">
@import "../../../assets/less/custom";
@import "../../../components/sell/ticket-order-common";
.wrapper-new {
  box-sizing: border-box;
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow: auto;
  transition: height 0.3s ease-in-out;
  .main {
    margin-left: 16px;
    flex-grow: 1;
    overflow-y: auto;
    background: #f5f5f5;
  }
}
</style>
