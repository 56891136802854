<template>
  <div class="order-info m-1x bd round">
    <panel-head title="确认订单信息"></panel-head>
    <div class="main">
      <div class="order-list">
        <a-row class="table-header">
          <a-col :span="4"></a-col>
          <a-col :span="12">收款事项</a-col>
          <a-col :span="4">金额</a-col>
        </a-row>

        <a-row class="mv-2x">
          <a-col :span="4"></a-col>
          <a-col :span="12">
            {{ customerGoods.remark }}
          </a-col>
          <a-col :span="4">{{ customerGoods.totalAmount | money }}</a-col>
        </a-row>
      </div>
      <OrderInfoCoupon
        :orderData="orderData"
        @setMemberBalance="setMemberBalance"
      ></OrderInfoCoupon>
      <div class="accounts">
        <a-row type="flex" justify="end" :gutter="[16, 16]">
          <a-col :span="4">
            应收合计：
          </a-col>
          <a-col :span="3">
            {{ customerGoods.totalAmount | money }}
          </a-col>
          <a-col :span="3"></a-col>
        </a-row>
        <a-row type="flex" justify="end" :gutter="[16, 16]">
          <a-col :span="4">
            会员余额：
          </a-col>
          <a-col :span="3"> -￥{{ balance }} </a-col><a-col :span="3"></a-col>
        </a-row>
        <a-row type="flex" justify="end" :gutter="[16, 16]">
          <a-col :span="4">
            积分抵扣：
          </a-col>
          <a-col :span="3"> -￥{{ integral }} </a-col><a-col :span="3"></a-col>
        </a-row>
        <a-row type="flex" justify="end" class="sum" :gutter="[16, 16]">
          <a-col :span="4">
            应付总额：
          </a-col>
          <a-col :span="3" class="red">
            {{
              (customerGoods.totalAmount - balance - integral) | money
            }} </a-col
          ><a-col :span="3"></a-col>
        </a-row>
      </div>
    </div>
    <div class="footer bd-t">
      <a-button type="primary" class="btn-lg" @click="submitOrder"
        >订单支付
      </a-button>
      <a-button size="large" @click="goBack" class="btn-lg">返回</a-button>
    </div>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import OrderInfoCoupon from "./components/OrderInfoCoupon.vue";
import { mapGetters } from "vuex";
import { createOrder } from "../../../api/order";

export default {
  name: "orderCustomerInfo",
  components: {
    PanelHead,
    OrderInfoCoupon
  },
  computed: {
    ...mapGetters("common", ["customerGoods"])
  },
  data() {
    return {
      balance: 0, //会员余额
      integral: 0 //积分抵扣
    };
  },
  created() {},
  methods: {
    submitOrder() {
      let data = {
        orderTradeType: 3,
        orderChannel: 1,
        totalAmount: this.customerGoods.totalAmount,
        remark: this.customerGoods.remark
      };
      createOrder(data)
        .then(() => {})
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.order-info {
  min-height: 120px;
  flex: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  .title-wrapper {
    align-items: center;
    .title {
      font-size: 20px;
    }
    .btn-close {
      font-size: 24px;
      color: @gray;
    }
  }
  .main {
    height: 100%;
    overflow-y: auto;
    .order-list {
      width: 90%;
      margin: 10px auto;
    }
    .table-header {
      background: @bgGray;
      height: 40px;
      line-height: 40px;
    }
    .table-row {
      padding: 10px 0;
      color: @gray;
    }
    .accounts {
      text-align: right;
      width: 90%;
      margin: 15px auto;
      background-color: @bgGray;
      .sum {
        font-size: 20px;
      }
    }
  }
  .footer {
    height: 70px;
    line-height: 70px;
    text-align: right;
    button {
      margin-right: 20px;
    }
  }
}
</style>
