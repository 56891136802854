<!-- 门票改签 弹窗 -->

<template>
  <a-modal
    :visible="vis"
    title="门票改签"
    width="780px"
    :body-style="{
      'max-height': '460px'
    }"
    @cancel="close"
    :mask-closable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
      label-align="left"
      ref="refTicketChangesForm"
    >
      <a-form-model-item label="门票名称">
        <span>{{ detail.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="原使用有效期">
        <span class="mr-4x">
          {{ detail.beginValidTime | date }} ~
          {{ detail.endValidTime | date }}
        </span>
        <a-checkbox v-model="form.check">延长使用有效期</a-checkbox>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.check"
        label="修改后有效期"
        prop="changePeriod"
      >
        <a-date-picker
          v-model="form.changePeriod"
          placeholder="请选择日期"
          class="wid"
        />
      </a-form-model-item>
      <a-form-model-item label="原游玩日期">
        <span>{{ detail.useDate | date }}</span>
      </a-form-model-item>

      <a-form-model-item
        label="原预约时段"
        v-if="
          detail.appointmentPeriodStartTime && detail.appointmentPeriodEndTime
        "
      >
        <span>
          {{ detail.appointmentPeriodStartTime | date("hh:mm") }} ~
          {{ detail.appointmentPeriodEndTime | date("hh:mm") }}
        </span>
      </a-form-model-item>
      <a-form-model-item label="改签后日期" prop="changeTime">
        <a-date-picker
          :disabled-date="disabledDate"
          v-model="form.changeTime"
          placeholder="请选择日期"
          class="wid"
        />
      </a-form-model-item>

      <a-form-model-item
        label="改签后时段"
        prop="changeInterval"
        v-if="
          detail.appointmentPeriodStartTime && detail.appointmentPeriodEndTime
        "
      >
        <a-select
          class="wid"
          v-model="form.changeInterval"
          placeholder="请选择时段"
        >
          <a-select-option
            v-for="item in timePeriod"
            :key="item.id"
            :value="item.id"
          >
            {{ item.beginTime | date("hh:mm") }} ~
            {{ item.endTime | date("hh:mm") }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="改签说明" prop="changeExplain">
        <a-input
          class="wid"
          v-model="form.changeExplain"
          placeholder="请输入"
        />
      </a-form-model-item>
    </a-form-model>
    <div slot="footer">
      <a-button key="cancel" @click="close">取消</a-button>
      <a-button type="primary" key="refund" @click="confirm" :loading="loading"
        >确定改签</a-button
      >
    </div>
  </a-modal>
</template>
<script>
import { ticketChangingOperation } from "../../../../api/order";
import { appointmentTimeList } from "../../../../api/product";
import moment from "moment";
export default {
  name: "TicketChanges",
  components: {},
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        check: false,
        changePeriod: "",
        changeTime: "",
        changeInterval: "",
        changeExplain: ""
      },
      timePeriod: [],
      rules: {
        changeInterval: [
          { required: true, message: "请选择时段", trigger: "change" }
        ],
        changeTime: [
          { required: true, message: "请选择日期", trigger: "change" }
        ],
        changePeriod: [
          { required: true, message: "请选择日期", trigger: "change" }
        ],
        changeExplain: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      loading: false
    };
  },
  created() {
    // 获取分时预约时段
    appointmentTimeList()
      .then(res => {
        this.timePeriod = res.reverse();
      })
      .catch(() => {});
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment(new Date()).add(-1, "days");
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
      this.$refs.refTicketChangesForm.resetFields();
    },
    // 确定改签
    confirm() {
      this.$refs.refTicketChangesForm.validate(valid => {
        if (!valid) {
          this.$message.warning("填写的内容校验不通过");
          return;
        }
        this.loading = true;
        ticketChangingOperation({
          id: this.detail.id, // 订单详情id
          changeOrRenew: 2, // 门票改签传固定值2
          operateChannel: 6, // 客户端类型
          endValidTime: moment(this.form.changePeriod).valueOf(), //修改后有效期
          whetherDelayExtension: this.form.check, // 是否修改有效期
          useDate: moment(this.form.changeTime).valueOf(), // 改签后日期
          appointmentPeriodId: this.form.changeInterval, // 改签后时段id
          appointmentPeriodName: this.formatInterval().name, // 改签后时段名称
          appointmentPeriodStartTime: this.formatInterval().beginTime, // 改签后开始时段
          appointmentPeriodEndTime: this.formatInterval().endTime, // 改签后结束时段
          remarks: this.form.changeExplain // 改签 说明
        })
          .then(() => {
            this.$emit("updateParentData", true);
            this.$emit("update:vis", false);
            this.$refs.refTicketChangesForm.resetFields();
            this.form.check = false;
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 格式时间段数据，符合接口参数
    formatInterval() {
      const obj = {
        beginTime: "",
        endTime: "",
        name: ""
      };
      this.timePeriod.forEach(item => {
        if (item.id == this.form.changeInterval) {
          obj.beginTime = item.beginTime;
          obj.endTime = item.endTime;
          obj.name = item.timeIntervalName;
        }
      });
      return obj;
    }
  }
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.wid {
  width: 300px;
}
</style>
