<template>
  <div class="wrapper flex-start flex-col">
    <!--导航-->
    <panel-head title="组合产品详情"></panel-head>
    <!-- 头部 -->
    <div class="main mt-2x flex-1">
      <div v-if="isTrue" class="pv-1x pr-2x flex-end">
        <a-dropdown type="primary" @click="e => e.preventDefault()">
          <a-button type="primary">
            批量操作 <a-icon type="down" class="pointer" />
          </a-button>
          <a-menu slot="overlay" @click="dropdownClick($event)">
            <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
            <a-menu-item
              :disabled="operator.verificationDisable"
              key="verification"
              >核销</a-menu-item
            >
            <a-menu-item
              :disabled="operator.forceVerificationDisable"
              key="forceVerification"
              >强制核销</a-menu-item
            >
            <a-menu-item :disabled="operator.refundDisable" key="refund"
              >退款</a-menu-item
            >
            <a-menu-item
              :disabled="operator.forceRefundDisable"
              key="forceRefund"
              >强制退款</a-menu-item
            >
            <a-menu-item
              :disabled="operator.changeAppointment"
              key="changeAppointment"
              >修改预约</a-menu-item
            >
          </a-menu>
        </a-dropdown>
      </div>
      <!--列表-->
      <!--      :row-selection="rowSelection" 临时注释多选-->
      <currency-table :columns="filterColumns" :data-source="tableData.data">
        <template slot="popover">
          <a-table-column width="225px" title="游客信息" align="center">
            <template slot-scope="row">
              <a-popover
                v-if="row.userInfoList && row.userInfoList.length"
                :trigger="row.whetherCombineProduct === 2 ? 'click' : 'hover'"
                placement="right"
              >
                <template #content>
                  <div v-for="(item, index) in row.userInfoList" :key="index">
                    <template
                      v-if="item.fieldType == 100 || item.fieldType == 9"
                    >
                      <div class="flex-start mb-1x" v-if="item.fieldValue">
                        <p class="w-80">{{ item.fieldName }}</p>
                        <img
                          class="pointer"
                          @click="imgClick(item.fieldValue)"
                          style="width: 100px; height: 100px"
                          v-if="
                            item.fieldValue &&
                              item.fieldValue.indexOf('https://') !== -1
                          "
                          :width="40"
                          :src="item.fieldValue"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex-start mb-1x">
                        <p class="w-80">{{ item.fieldName }}:</p>
                        <p>{{ item.fieldValue || "-" }}</p>
                      </div>
                    </template>
                  </div>
                </template>

                <div>
                  <a-tag v-if="row.whetherCombineProduct === 2" size="medium"
                    >点击查看</a-tag
                  >
                  <div v-else>
                    {{ row.userInfoList[0] && row.userInfoList[0].fieldName }}:
                    <img
                      class="pointer"
                      style="width: 30px; height: 30px"
                      v-if="
                        row.userInfoList[0] &&
                          row.userInfoList[0].fieldValue &&
                          row.userInfoList[0].fieldValue.indexOf('https://') !==
                            -1
                      "
                      :src="row.userInfoList[0].fieldValue"
                    />
                    <span v-else>{{
                      (row.userInfoList[0] && row.userInfoList[0].fieldValue) ||
                        "-"
                    }}</span>
                  </div>
                </div>
              </a-popover>
            </template>
          </a-table-column>
        </template>
        <!-- 操作列 -->
        <template slot="action">
          <a-table-column width="180px" title="操作" fixed="right">
            <!--            <div slot="title">-->
            <!--              操作-->
            <!--            </div>-->
            <div slot-scope="row">
              <a-dropdown @click="e => e.preventDefault()">
                <span class="pointer blue">
                  更多操作
                  <a-icon type="down" />
                </span>
                <a-menu slot="overlay" @click="dropdownClick($event, row)">
                  <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
                  <!--                  组合产品不显示-->
                  <a-menu-item
                    v-permission="'appOrderNewProductDetailWriteoff'"
                    :disabled="
                      !checkAuthorityBtn(row).includes('核销') ||
                        (checkAuthorityBtn(row).includes('核销') &&
                          row.canUseNum !== -1 &&
                          row.canUseNum === row.useNum)
                    "
                    key="verification"
                    >核销
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductDetailForcedwriteoff'"
                    key="forceVerification"
                    >强制核销
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductDetailrefund'"
                    :disabled="!checkAuthorityBtn(row).includes('退款')"
                    key="refund"
                    >退款
                  </a-menu-item>
                  <!--<a-menu-item-->
                  <!--  key="forceRefund"-->
                  <!--  v-permission="'appOrderNewProductDetailMandatoryrefund'"-->
                  <!--  >强制退款-->
                  <!--</a-menu-item>-->
                  <a-menu-item
                    key="changeValidity"
                    v-permission="'appOrderNewProductDetailtermofvalidity'"
                    >延长有效期
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductDetailModifyappointment'"
                    key="changeAppointment"
                    :disabled="!checkAuthorityBtn(row).includes('修改预约')"
                    >修改预约
                  </a-menu-item>
                  <!--                  <a-menu-item-->
                  <!--                    :disabled="!checkAuthorityBtn(row).includes('补办')"-->
                  <!--                    key="replace"-->
                  <!--                    >补办-->
                  <!--                  </a-menu-item>-->
                  <!--                  :disabled="!checkAuthorityBtn(row).includes('补录人脸')"-->
                  <a-menu-item
                    v-permission="'appOrderNewProductDetailFace'"
                    :disabled="!checkAuthorityBtn(row).includes('补录人脸')"
                    key="recordingFace"
                    >补录人脸
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-table-column>
        </template>
      </currency-table>
      <!--    查看图片-->
      <a-modal v-model="tableFaceShow" title="查看图片" centered>
        <img width="400" :src="tableFaceUrl" alt="" />
        <div slot="footer" class="text-c">
          <a-button type="primary" @click="tableFaceShow = false"
            >关闭</a-button
          >
        </div>
      </a-modal>
      <div class="p-2x flex-end">
        <a-pagination
          v-model="tableData.page"
          :default-page-size="tableData.size"
          :total="tableData.total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="pageChange"
          @showSizeChange="sizeChange"
        />
      </div>

      <!-- 退款弹窗 -->
      <refund-public
        title="退款"
        :refund-show="refundShow"
        :refund-info="refundInfo"
        @success="refundOk"
        @cancel="refundShow = false"
      ></refund-public>
      <!-- 强制退款弹窗 -->
      <force-refund-public
        :title="forceTitle"
        :code-type="codeType"
        :refund-show="forceRefundShow"
        @success="forceRefundOk"
        @cancel="forceRefundShow = false"
      ></force-refund-public>
      <!--    修改预约-->
      <change-validity
        :visible.sync="appointmentPop"
        :detail-item="detailItem"
        :validity-type="validityType"
        @submit="submitForm"
      ></change-validity>
    </div>
    <!--    人脸录入-->
    <add-face
      :vis.sync="faceShow"
      :detail="detailItem"
      @updateParentData="updateParentData"
    ></add-face>
  </div>
</template>

<script>
import {
  orderDetailSubProductDetailsInfo,
  productWriteOff,
  refundProductCheck,
  productWriteOffForce,
  refundProductForce,
  refundProduct
} from "@/api/order";
import CurrencyTable from "@/components/table/CurrencyTable";
import RefundPublic from "./components/RefundPublic.vue";
import ForceRefundPublic from "./components/ForceRefundPublic.vue";
import { dateFormat, checkAuthorityBtn } from "@/utils/global";
import PanelHead from "@/components/common/PanelHead";
import AddFace from "./components/AddFace.vue";
import ChangeValidity from "./components/ChangeValidity";
import moment from "moment";
export default {
  name: "ProductDetail",
  components: {
    CurrencyTable,
    ForceRefundPublic,
    RefundPublic,
    PanelHead,
    AddFace,
    ChangeValidity
  },
  computed: {
    filterColumns() {
      return this.tableData.columns.filter(column => column.show);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.selectionChange
      };
    }
  },
  data() {
    return {
      isTrue: false, // 临时注释多选
      moment,
      checkAuthorityBtn,
      detailItem: {}, // 退款点击当前产品对象的
      appointmentPop: false, // 预约弹框
      validityType: "", // 修改预约类型，延长有效期
      selectedRowKeys: [],
      checkSearchs: [], // 表格头过滤后选中的列表数组
      forceType: "",
      forceTitle: "强制退款",
      codeType: "FORCED_TO_REFUND",
      refundShow: false, // 退款弹框
      forceRefundShow: false, // 强制核销，退款弹框
      refundInfo: {}, //退款信息
      tabActive: 0,
      isfilters: [],
      amountSum: 0, // 销售额
      totalRefundAmountSum: 0, // 退款
      operator: {
        verificationDisable: true, // 核销
        forceVerificationDisable: true, // 强制核销
        refundDisable: true, // 退款
        forceRefundDisable: true, // 强制退款
        changeAppointment: true // 修改预约
      },
      cartId: 0, //  补办后生成的购物车id
      faceShow: false, // 人脸录入
      tableData: {
        data: [],
        columns: [
          {
            key: "auxiliaryId",
            title: "单品编号（票号）",
            fixed: "left",
            show: true,
            width: 200
          },
          {
            key: "productName",
            title: "单品名称",
            show: true,
            width: 180
          },
          {
            key: "orderBusinessTypeText",
            title: "业务类型",
            show: true,
            width: 120
          },
          {
            key: "ownerSubMerchantInfoName",
            title: "单品所属子景区",
            show: true,
            width: 180
          },
          {
            key: "orderOwnerMerchantName",
            title: "单品所属商户",
            show: true,
            width: 120
          },
          {
            key: "totalAmount",
            title: "单品单价",
            show: true,
            width: 135,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "receivedAmount",
            title: "明细实收",
            show: true,
            width: 135,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "statusText",
            title: "明细状态",
            show: true,
            width: 135
          },
          // {
          //   key: "proofOfProductName",
          //   title: "产品凭证名称",
          //   show: true,
          //   width: 135
          // },
          // {
          //   key: "proofOfProductInfo",
          //   title: "产品凭证信息",
          //   show: true,
          //   width: 185,
          //   ellipsis: true,
          //   scopedSlots: { customRender: "face" } // 存在人脸情况
          // },
          // 格式为：姓名：张三；手机号：1234567890；身份证：1534164362
          {
            key: "userInfoList",
            title: "游客信息",
            show: true,
            width: 220,
            popover: true
          },
          {
            key: "visitorTypeName",
            title: "游客类型",
            show: true,
            width: 140
          },
          {
            key: "visitorDiscountTypeName",
            title: "游客优惠",
            show: true,
            width: 170
          },
          {
            key: "useDate",
            title: "预约日期",
            show: true,
            width: 180,
            scopedSlots: { customRender: "date", valueFormat: "YYYY-MM-DD" }
          },
          {
            key: "cumAppointTime",
            title: "预约时段",
            show: true,
            width: 120
          },
          {
            key: "cumValidityTime",
            title: "产品有效期",
            show: true,
            width: 180,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "afterWriteOffValidTime",
            title: "核销后有效期",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "canUseNum",
            title: "可核销次数",
            show: true,
            width: 120
          },
          {
            key: "useNum",
            title: "已核销次数",
            show: true,
            width: 120
          },
          {
            key: "writeOffPersonName",
            title: "核销员",
            show: true,
            width: 120
          },
          {
            key: "writeOffTime",
            title: "核销时间",
            show: true,
            width: 180,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "writeOffChannelText",
            title: "核销渠道",
            show: true,
            width: 180
          }
        ],
        multipleSelection: [],
        // multiple:false,
        total: 1,
        page: 1,
        size: 10
      },
      routeQuery: {},
      tableFaceShow: false,
      tableFaceUrl: ""
    };
  },
  created() {
    this.routeQuery = this.$route.query;
    if (this.routeQuery.id) {
      this.getDataList();
    }
  },
  methods: {
    // 点击人脸
    imgClick(url) {
      this.tableFaceShow = true;
      this.tableFaceUrl = url;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current &&
        current < this.moment(this.detailItem.beginValidTime).add(0, "day")
      );
    },
    getDataList() {
      this.selectedRowKeys = [];
      orderDetailSubProductDetailsInfo({
        orderId: this.routeQuery.id
      }).then(res => {
        res.forEach(item => {
          if (item.beginValidTime && item.endValidTime) {
            // 处理有效期
            item.cumValidityTime = `${dateFormat(
              item.beginValidTime,
              "YYYY-MM-DD hh:mm"
            )} ~ ${dateFormat(item.endValidTime, "YYYY-MM-DD hh:mm")}`;
          }
          // 处理核销次数
          item.canUseNum = item.canUseNum !== -1 ? item.canUseNum : "无限次";
          // 预约时段处理
          if (item.appointmentPeriodStartTime) {
            item.cumAppointTime = `${dateFormat(
              item.appointmentPeriodStartTime,
              "hh:mm"
            )} ~ ${dateFormat(item.appointmentPeriodEndTime, "hh:mm")}`;
          }
          // 处理卡腕带
          if (item.userInfoConvert && item.userInfoConvert.wristbandsNo) {
            // 处理卡腕带情况
            item.userInfoList.push({
              fieldName: "卡腕带",
              fieldValue: item.userInfoConvert.wristbandsNo
            });
          }
          // 游客信息排序
          if (item.userInfoList && item.userInfoList.length) {
            let valueArr = [];
            let emptyArr = [];
            // 排序，有值最前，图片次，null的放最后
            item.userInfoList.forEach(te => {
              if (te.fieldValue && te.fieldValue.includes("https://")) {
                valueArr.push(te);
              } else if (te.fieldValue) {
                valueArr.unshift(te);
              } else {
                emptyArr.push(te);
              }
            });
            item.userInfoList = valueArr.concat(emptyArr);
          }
        });
        this.tableData.data = res;
      });
    },
    // 多选change
    selectionChange(val) {
      this.selectedRowKeys = val;
      // 初始化
      this.operator = {
        verificationDisable: false,
        forceVerificationDisable: false,
        refundDisable: false,
        forceRefundDisable: false,
        changeAppointment: false
      };
      // 多选产品列表时根据校验规则判定按钮是否可点击
      if (val.length) {
        let arr = [];
        this.tableData.data.forEach(tem => {
          // 查找出选择的对象
          let i = val.findIndex(te => te === tem.id);
          if (i !== -1) {
            arr.push(tem);
          }
        });
        arr.forEach(item => {
          // 组合产品不显示核销，强制核销，退款，强制退款
          if (
            !this.checkAuthorityBtn(item).includes("核销") ||
            item.combineAuxiliaryId
          )
            this.operator.verificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制核销") ||
            item.combineAuxiliaryId
          )
            this.operator.forceVerificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("退款") ||
            item.combineAuxiliaryId
          )
            this.operator.refundDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制退款") ||
            item.combineAuxiliaryId
          )
            this.operator.forceRefundDisable = true;
          if (!this.checkAuthorityBtn(item).includes("修改预约"))
            this.operator.changeAppointment = true;
        });
      } else {
        this.operator = {
          verificationDisable: true,
          forceVerificationDisable: true,
          refundDisable: true,
          forceRefundDisable: true,
          changeAppointment: true
        };
      }
    },
    pageChange(current) {
      this.tableData.page = current;
      this.getDataList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.tableData.page = current;
      this.tableData.size = size;
      this.getDataList();
    },
    // 表格过滤触发的时间
    filterChange() {
      this.getDataList();
    },
    // 产品信息列表表格操作按钮
    dropdownClick({ key }, row) {
      switch (key) {
        case "verification":
          if (row) {
            // 单个 核销
            this.$confirm({
              title: "确定要执行核销操作吗?",
              okText: "确定",
              onOk: () => {
                productWriteOff({
                  code: row.auxiliaryId
                }).then(res => {
                  if (res.code === 500) {
                    this.$message.warning(
                      res.otherMsg ? res.otherMsg : "核销失败！"
                    );
                  } else {
                    this.$message.success(
                      res.otherMsg ? res.otherMsg : "核销成功！"
                    );
                  }
                  this.getDataList();
                });
              }
            });
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceVerification":
          if (row) {
            // 单个
            this.forceType = "verification";
            this.detailItem = row;
            this.forceTitle = "强制核销";
            this.codeType = "FORCED_TO_WRITE_OFF";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "refund":
          if (row) {
            // 单个
            this.refundChecks(row);
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceRefund":
          if (row) {
            // 单个
            this.forceType = "refund";
            this.detailItem = row;
            this.forceTitle = "强制退款";
            this.codeType = "FORCED_TO_REFUND";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "changeValidity":
          if (row) {
            // 单个
            this.validityType = "changeValidity";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "changeAppointment":
          if (row) {
            // 单个
            this.validityType = "changeAppointment";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "recordingFace":
          if (row) {
            // 单个 补录人脸
            this.detailItem = row;
            this.faceShow = true;
          }
          break;
      }
    },
    // 产品退款校验
    refundChecks(row) {
      this.refundType = "productRefund";
      this.detailItem = row;
      refundProductCheck({
        mainOrderId: row.mainOrderId,
        detailId: row.id
      })
        .then(res => {
          this.refundShow = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    //完成退款
    refundOk(obj) {
      this.refundShow = false;
      // 产品退款
      refundProduct({
        mainOrderId: this.detailItem.mainOrderId,
        detailId: this.detailItem.id,
        remarks: obj.remarks
      })
        .then(res => {
          this.$message.success(
            res.refundFailMsg ? res.refundFailMsg : "退款成功！"
          );
          this.getDataList();
        })
        .catch(() => {});
    },
    // 强制完成退款/核销
    forceRefundOk(obj) {
      this.forceRefundShow = false;
      if (this.forceType === "refund") {
        refundProductForce({
          mainOrderId: this.detailItem.mainOrderId,
          detailId: this.detailItem.id,
          remarks: obj.remarks
        })
          .then(() => {
            this.getDataList();
            this.$message.success("强制退款成功！");
          })
          .catch(() => {});
      } else if (this.forceType === "verification") {
        productWriteOffForce({
          code: this.detailItem.id
        })
          .then(() => {
            this.getDataList();
            this.$message.success("强制核销成功！");
          })
          .catch(() => {});
      }
    },
    // 批量修改预约
    submitForm() {
      this.$message.success("修改成功!");
      this.getDataList();
    },
    resetForm(formName) {
      this.appointmentPop = false;
      this.$refs[formName].resetFields();
    },
    // 支付成功
    paySuccess() {
      this.loadDetail();
    },
    // 支付成功
    updateParentData() {
      this.loadDetail();
    }
  }
};
</script>

<style scoped lang="less">
.ps-a {
  position: relative;
}
.w-80 {
  width: 80px;
}
</style>
