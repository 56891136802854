<template>
  <div class="wrapper flex-between flex-col">
    <div class="main">
      <!-- <a-spin :spinning="isloading" tip="加载中..."> -->
      <div class="flex-center container">
        <a-dropdown :trigger="['click']" class="mt-1x">
          <a class="ant-dropdown-link pr-2x" @click="e => e.preventDefault()">
            <span class="font-xxl bold dark pr-1x mb-2x">
              {{ subScenic.subScenicName }}</span
            >
            <span class="font-xl"><a-icon type="swap" />切换</span>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in subScenicsList" :key="item.subScenicId">
              <a
                href="javascript:;"
                @click="setDefault(item)"
                class="flex-between"
              >
                <span class="bold">{{ item.subScenicName }}</span>
                <span
                  @click="setDefault(item)"
                  class="pl-1x"
                  style="float:right"
                  >设为默认</span
                ></a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div
          class="flex-start flex-wrap flex-1"
          style="padding-right: 150px; line-height: 1.5"
        >
          <div class="flex-start flex-wrap align-center">
            <h2 class="mh-2x font-xl flex-start ">使用日期</h2>
            <div
              v-for="(date, index) in dateData"
              :key="index"
              class="date-li text-c pointer relative flex-center align-center"
              @click="dateClick(index, date)"
            >
              <span>{{ date | date }}</span>
              <span class="span" :class="{ 'date-act': dateIndex == index }">
                {{ date | date }}
              </span>
            </div>
            <h2 class="mh-2x font-xl flex-start align-center">使用时段</h2>
            <div
              v-if="playTimes && playTimes.length"
              class=" flex-between flex-wrap date mt-1x"
            >
              <a-button
                v-for="(item, index) in playTimes"
                :key="index"
                :disabled="item.disabled"
                @click="timeClick(item, index)"
                class="btn text-2 ml-1x"
                :class="timeIndex == index ? 'active_date' : ''"
                style="height: 46px;font-size: 12px;"
              >
                <div>
                  {{ item.time }}
                </div>
                <div>余票：{{ item.remainingStock }}</div></a-button
              >
            </div>
          </div>
        </div>
      </div>
      <section class="flex-grow container">
        <!-- 游客信息 -->
        <tourist
          ref="myTourist"
          :subScenic="subScenic"
          :subscenicProductList="subscenicProductList"
          :groupType="groupType"
          @changeTouristType="changeTouristType"
          @changeOrderInfo="changeOrderInfo"
          @changeTouristSumData="changeTouristSumData"
          @changeRadio="changeRadio"
          @onenessOrder="toOrder"
        ></tourist>
        <div>
          <!-- 记录 -->
          <div class="flex-start align-center mv-1x ">
            <h3 class="title-mid" style="width: 120px;">记录</h3>
            <div class="flex-start" style="padding-left:6%">
              <h4 style="width:120px" class="flex-start align-center">
                售票编号
              </h4>
              <a-input
                v-model="ticketNo"
                placeholder="售票编号"
                @pressEnter="seachTicket"
                allow-clear
              />
              <a-button type="primary" class="ml-1x" @click="seachTicket"
                >搜索</a-button
              >
            </div>
            <div style="margin-left: 50%;">
              <a-button
                v-permission="'homeQuickPlaceOrderV2PlaceOrderV2Makeup'"
                type="primary"
                class="ml-6x"
                @click="batchPrint"
                >批量补打</a-button
              >
            </div>
          </div>
          <a-table
            size="middle"
            :columns="historyColumns"
            :data-source="snList"
            row-key="orderDetailId"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange
            }"
            :pagination="false"
            :scroll="{ y: '32vh' }"
          >
            <template slot="name" slot-scope="scope">{{ scope.name }}</template
            ><template slot="createTime" slot-scope="scope">{{
              scope.createTime | date("YYYY-MM-DD hh:mm:ss")
            }}</template>
            <template slot="sellingPrice" slot-scope="{ sellingPrice }">{{
              sellingPrice | money
            }}</template>
            <template slot="action" slot-scope="scope">
              <a-button
                @click="toQuickDetailPrint(scope.orderDetailId)"
                type="link"
                class="blue"
                >补打</a-button
              >
              <a-button @click="toRefund(scope)" type="link" class="blue"
                >退票</a-button
              >
            </template>
          </a-table>
          <div class="flex-end mt-1x">
            <a-pagination
              v-model="snObj.page"
              :default-page-size="snObj.size"
              :total="snTotal"
              :page-size-options="['10', '20', '50', '100']"
              show-size-changer
              @change="querySnList"
              @showSizeChange="querySnListBySize"
            />
          </div>
        </div>
      </section>
      <!-- </a-spin> -->
    </div>
    <!-- 底部 -->
    <div class="flex-start footer-start">
      <div class="flex-between align-center">
        <div class="flex-start" style="width:80px">
          <span>票数：</span>
          <div class="red bold">{{ orderInfo.sumNum || 0 }} 人</div>
        </div>
        <div class="flex-start" style="width:140px">
          <span>总金额：</span>
          <div class="red bold">{{ orderInfo.sumPrice || 0 | money }}</div>
        </div>
        <div
          v-permission="'homeQuickPlaceOrderV2Paymentmethod'"
          style="width:70px"
        >
          支付方式
        </div>
        <!-- @change="onenessOrder" -->
        <a-radio-group
          v-permission="'homeQuickPlaceOrderV2Paymentmethod'"
          v-model="payType"
          class="flex-start align-center"
        >
          <div style="width: 600px;">
            <a-radio :value="0">
              <span>线上</span>
              <a-input
                placeholder="直接扫码或手动输入付款码"
                v-model="paymentCode"
                allow-clear
                size="large"
                class="prefix-input"
                ref="scanRef"
                :disabled="flag !== null"
                @pressEnter="confirm"
                @focus="payType = 0"
              >
                <span slot="prefix" class="primary">付款码</span>
                <a-icon type="scan" slot="suffix" class="primary" />
              </a-input>
            </a-radio>
          </div>
          <div class="flex-start align-center" v-if="offLinePaymentId">
            <a-radio :value="1">
              <span>现金结算</span>
            </a-radio>
          </div>
        </a-radio-group>
      </div>
      <a-button
        type="primary"
        shape="round"
        size="large"
        @click="toOrder(orderInfo)"
        class="ml-2x"
        style="width:190px"
        >提交订单</a-button
      >
    </div>
    <a-modal
      :maskClosable="false"
      :centered="true"
      title="请稍候"
      v-model="showState"
      :width="400"
    >
      <div class="text-c m-3x">{{ tips }}</div>
      <div class="text-c m-3x">
        <span class="bold">{{ orderInfo.name }}</span> 下单数量 ：{{
          orderInfo.sumNum
        }}
        <a-input
          style="opacity: 0;"
          v-model="paymentCode"
          ref="scanRefHide"
          @pressEnter="confirm"
        ></a-input>
      </div>
      <div slot="footer" class="text-c">
        <a-button @click="showState = false">关闭</a-button>
        <!-- <a-button type="primary" @click="showState = false">确定</a-button> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import { findUserPageList, findUserInfo } from "@/api/user";
import { selectTouristManagementInfo } from "@/api/member";
import {
  tradePaymentInfoMicropayV2,
  tradePaymentInfoStatus,
  orderPrint,
  payOfflineOrder,
  productSnList,
  refund
} from "@/api/order";
import { orderQuickorderprint, orderQuickdetailprint } from "@/api/search";
import { subScenics } from "@/api/merchant.js";
import { subscenicProducts, appointmentStockDate } from "@/api/product.js";
import { fastOrder, discountInfoCalculate, cbsFastOrder } from "@/api/cart.js";
import moment from "moment";
import tourist from "./componets/tourist.vue";
import HardwareService from "@/common/domains/hardware-domain/hardwareService";
import { merchantuthPayFormWayInfo } from "../../../api/pay";
// import { checkIdCard, checkPhone, checkText } from "@/utils/global";
export default {
  name: "placeOrder",
  components: { tourist },
  data() {
    return {
      subScenic: { subScenicId: "", subScenicName: "" }, //当前已选子景区
      subScenicId: "", //子景区id
      subScenicsList: [], //子景区列表
      orderType: "1", //下单类型 1个人快速下单 2团体快速下单
      moment,
      playDate: "", //游玩日期
      playTime: undefined,
      playTimes: [], //游玩时段
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      selectTouristShow: false, //添加游客信息框
      searchGuideValue: "",
      historyColumns: [
        {
          title: "售票编号",
          key: "productSn",
          dataIndex: "productSn",
          width: 120
        },
        {
          title: "产品名称",
          key: "productName",
          dataIndex: "productName",
          width: 180
        },
        {
          title: "下单时间",
          key: "createTime",
          scopedSlots: { customRender: "createTime" },
          width: 180
        },
        {
          title: "金额",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" },
          width: 180
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 160,
          align: "center"
        }
      ],
      groupType: {
        //团体分销商&团体分类
        //selectDistributor: "", //已选分销商id
        selectGroupId: undefined, //已选团体分类
        orderType: "",
        discountCategory: "",
        fold: "",
        selectGroupName: "", //已选团体分类名称
        touristDiscountStatus: 0, //团体-游客优惠 0无 1 开启 折中折
        guideProductId: undefined, //导游票id
        guideFare: "", //团体导游折扣类型 导游票价 1全免 2免一人 3全价
        guideProductPice: 0 //团体导游票种票单价
      },
      subscenicProductList: [], //子景区产品列表
      orderInfo: {},
      selectionTouristData: [],
      userName: "",
      user: {},
      users: [],
      page: 1,
      size: 1000,
      nowPlayTimeInfo: {}, //当前选择时段库存信息
      dateData: [], // 日期数据
      dateIndex: 0, //游玩日期当前索引
      timeIndex: 0, //当前时段
      buyNum: 0, //人数
      ticketNo: "", //搜索售票编号
      paymentCode: "",
      payType: 0, //0线上 1现金
      isFocus: false,
      loading: false, // 是否支付中
      isloading: false, //全局加载中
      loopTimer: null, // 轮询支付定时器
      orderId: "",
      snList: [],
      selectedRowKeys: [], //售票记录已选
      showState: false,
      tips: "请扫付款码", //弹窗提示语
      flag: "", //pc/移动端
      snObj: { page: 1, size: 10 }, //售票记录查询参数
      snTotal: 0,
      offLinePaymentId: "" // 记录现金支付id
    };
  },
  created() {
    try {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag === null) {
        //pc端
        this.autoFocus("scanRef");
      } else {
        window.test.optScan("1"); //（传参数为1那么回调的js方法是payScan，0是scanCode方法）
        window.payScan = this.payScan; // 获取付款码
      }
      // window.test.readEwm();
    } catch (error) {
      console.log("created：扫码支付，硬件启用失败：", error);
    }
    this.subScenic = localStorage.getItem("placeSubScenic")
      ? JSON.parse(localStorage.getItem("placeSubScenic"))
      : {}; //默认子景区
    this.querySubScenics();
    this.querySubscenicProducts();
    // 获取后台配置的支付方式
    this.getPayMode();
  },

  beforeDestroy() {
    clearTimeout(this.loopTimer);
    this.loopTimer = null;
    window.payScan = null;
    try {
      window.test.optScan("0");
    } catch (error) {
      console.log("destroyed：扫码支付，硬件启用失败：", error);
    }
  },

  mounted() {
    this.queryTouristType(); //获取游客分类
    this.querySnList();
    // this.$refs.scanRef.focus();
  },
  methods: {
    getPayMode() {
      merchantuthPayFormWayInfo()
        .then(res => {
          let noCash =
            localStorage.getItem("noCash") &&
            localStorage.getItem("noCash") == 1; //是否有现金支付 noCash=1无
          res.forEach(item => {
            // 线下支付
            if (item.payFormCategory === 2) {
              item.authPayWayVOS.forEach(tem => {
                // 现金
                if (tem.payWayCode === "OfflinePayment" && !noCash) {
                  this.offLinePaymentId = tem.payWayId;
                }
              });
            }
          });
        })
        .catch(() => {});
    },
    //自动获取焦点
    autoFocus(ref) {
      this.payType = 0;
      this.$nextTick(() => {
        this.$refs[ref].focus();
      });
    },
    changeRadio(val) {
      this.payType = val;
      // this.onenessOrder();
    },
    seachTicket() {
      this.snObj.productSn = this.ticketNo;
      // this.snObj.productName = this.ticketNo;
      this.querySnList();
      //if (this.ticketNo) {
      //  this.snList = this.snList.filter(
      //    item => (item.productSn + "").indexOf(this.ticketNo) != -1
      //  );
      //} else {
      //  this.querySnList();
      //}
    },
    toOrder(obj) {
      if (this.payType == 0) {
        this.orderInfo = obj;
        this.showState = true;
        this.tips = "请扫付款码";
        if (this.flag === null) {
          //pc
          let t = setTimeout(() => {
            this.autoFocus("scanRefHide");
            clearTimeout(t);
            t = null;
          }, 100);
        }
      } else {
        this.onenessOrder();
      }
    },
    //二合一
    onenessOrder() {
      if (this.isloading) {
        this.$message.warning("已在下单中！");
        return;
      }
      if (this.payType == 0 && !this.paymentCode) {
        HardwareService.speak("请先输入付款码");
        return;
      }
      if (this.flag === null) {
        //pc端要失焦一下
        this.$refs.scanRef.blur();
        if (this.$refs.scanRefHide) {
          this.$refs.scanRefHide.blur();
        }
      }
      let data = {
        subScenicId: this.subScenic.subScenicId,
        playDate: this.playDate, //游玩日期
        timeShareAppointmentRuleId: this.nowPlayTimeInfo
          .timeShareAppointmentRuleId, //时段规则id
        timeIntervalId: this.nowPlayTimeInfo.id, //时段id
        productTravelerList: [
          {
            productId: this.orderInfo.sybSystemIdProductId,
            buyNum: this.orderInfo.sumNum
          }
        ]
      };
      this.tips = "下单中，请稍候";
      this.isloading = true;
      //购物车-下单
      cbsFastOrder(data)
        .then(res => {
          this.orderId = res.orderId;
          this.tips = "支付中，请稍候";
          if (this.payType == 1) {
            //现金
            this.offLinePay();
          } else {
            this.doPay();
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    //退票
    toRefund(row) {
      refund({
        orderId: row.mainOrderId,
        detailIdType: 2,
        detailIdList: [row.orderDetailId]
      }).then(({ refundFailMsg }) => {
        this.$message.success(refundFailMsg);
        this.querySnList();
      });
    },
    onSelectChange(val) {
      this.selectedRowKeys = val;
    },
    //批量补打
    batchPrint() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.warning("请先选择门票");
        return;
      }
      this.toQuickDetailPrint(this.selectedRowKeys.toString());
    },
    //补打
    toQuickDetailPrint(detailIds) {
      orderQuickdetailprint({ detailIds: detailIds }).then(res => {
        let flag = HardwareService.printTicket(JSON.stringify(res));
        //如果成功调用打印门票，就掉接口通知后端已经取票
        if (flag) {
          // this.$message.info("打印成功");
          HardwareService.speak("打印成功");
        } else {
          this.$message.info("打印失败，请稍后重试！");
        }
      }); //打印模板
    },
    querySnListBySize(current, size) {
      this.snObj.page = 1;
      this.snObj.size = size;
      this.querySnList();
    },
    //查询下单记录
    querySnList() {
      productSnList({ ...this.snObj }).then(res => {
        this.snTotal = res.total;
        this.snList = res.records;
      });
    },
    timeClick(item, index) {
      this.nowPlayTimeInfo = item;
      this.timeIndex = index;
    },

    confirm() {
      // this.$message.warning("请先选择下单产品！");
      // this.addCart();
      this.onenessOrder();
    },
    // 扫码直接支付
    payScan(res) {
      this.payType = 0; //扫码 支付回到0线上
      if (this.isloading) {
        this.$message.warning("下单中，请稍候！");
        return;
      }
      // this.showState = false;
      if (!this.orderInfo.sybSystemIdProductId) {
        this.$message.warning("请先选择下单产品！");
        HardwareService.speak("请先选择下单产品！");
        return;
      }
      if (!res || res == "") {
        this.$message.warning("请扫码！");
        HardwareService.speak("请扫码！");
        return;
      }
      // if (!this.loading) {
      // 先清空再赋值，避免扫码太快
      this.paymentCode = "";
      this.paymentCode = res;
      // }
      HardwareService.speak("下单中，请稍候");
      // this.addCart();
      this.onenessOrder();
    },
    //1、加入购物车
    addCart() {
      let data = {
        subScenicId: this.subScenic.subScenicId,
        playDate: this.playDate, //游玩日期
        timeShareAppointmentRuleId: this.nowPlayTimeInfo
          .timeShareAppointmentRuleId, //时段规则id
        timeIntervalId: this.nowPlayTimeInfo.id, //时段id
        productTravelerList: [
          {
            productId: this.orderInfo.sybSystemIdProductId,
            buyNum: this.orderInfo.sumNum
          }
        ]
      };
      fastOrder(data).then(res => {
        // this.$store.dispatch("order/setCartList", { id: res.id });
        this.orderId = res.id;
        this.createOrder();
        // this.$router.push("/home/sell/OrderInfo");
      });
    },
    //2、创建订单
    createOrder() {
      this.isloading = true;
      discountInfoCalculate({ id: this.orderId }).then(res => {
        if (!res) {
          HardwareService.speak("下单失败，请重新尝试");
          this.$message.error("下单失败，请重新尝试");
          return;
        }
        // if (res.settlementPrice === 0) {
        //   console.log("0元票");
        // }
        this.orderId = res.orderId;
        if (this.payType == 1) {
          //现金
          this.offLinePay();
        } else {
          this.doPay();
        }
      });
    },
    //3、线上扫码支付
    doPay() {
      if (!this.paymentCode) {
        HardwareService.speak("请先输入付款码");
        this.$message.warning("请先输入付款码");
        return;
      }
      if (this.loading) return;
      this.loading = true;
      const data = {
        orderNo: this.orderId,
        authCode: this.paymentCode,
        productName: ""
      };
      this.$store.commit("common/updateLoading", true);
      tradePaymentInfoMicropayV2(data)
        .then(res => {
          if (res === "PAYING" || res === "NOT_PAY") {
            this.$message.info("等待用户支付中...");
            this.getStatusLoop();
          }
          // 支付失败
          else if (res === "PAY_FAIL") {
            this.isloading = false;
            HardwareService.speak("支付失败，请重新支付");
            this.$message.error("支付失败，请重新支付");
            this.resetData();
          }
          // 支付成功
          else {
            this.paySuccess();
            this.resetData();
          }
          // // 支付超时or订单已经失效（30分钟未支付失效）
          // // 这种场景，已经走接口的catch了，不会在这边执行
          // if (res == "CANCEL") {
          //   console.log("订单已经失效，请重新下单+++++");
          //   return;
          // }
        })
        .catch(() => {
          this.resetData();
        });
    },
    //3、 线下支付接口
    offLinePay() {
      payOfflineOrder({
        orderId: this.orderId,
        payWayCode: this.offLinePaymentId, // 线下支付传subPayModeId,其他支付传subPayModeCode
        payWayName: "现金", // 支付方式名称
        payFlowNo: "", // 支付流水号
        payChannelCode: "", // 支付通道code
        payChannelName: "", // 支付通道名称
        ignoreFee: ""
      })
        .then(() => {
          this.paySuccess();
        })
        .catch(() => {});
    },
    //4、支付成功
    paySuccess() {
      this.tips = "下单成功";
      this.$message.success("下单成功");
      orderQuickorderprint({ orderId: this.orderId }).then(res => {
        this.showState = false;
        this.tips = "打印中";
        this.isloading = false;
        // this.$message.info(JSON.stringify(res));
        if (res) {
          let flag = HardwareService.printTicket(JSON.stringify(res));
          //如果成功调用打印门票，就掉接口通知后端已经取票
          if (flag) {
            clearTimeout(this.loopTimer);
            this.$message.info("打印成功");
            HardwareService.speak("打印成功");
            orderPrint({
              channel: 6, //6是窗口端
              orderId: this.orderId
            })
              .then(() => {})
              .catch(() => {});
          }
        }
      }); //打印模板
      if (this.flag === null) {
        if (this.payType) {
          this.$message.success("已自动从现金支付切回线上支付");
        }
        //pc
        this.payType = 0; //下完单回到线上支付
        this.autoFocus("scanRef");
      }
      this.isloading = false;
      this.querySnList();
    },
    resetData() {
      clearTimeout(this.loopTimer);
      this.loopTimer = null;
      this.paymentCode = "";
      this.loading = false;
      this.isloading = false;
      if (this.flag === null) {
        //pc端
        this.autoFocus("scanRef");
      }
      this.$store.commit("common/updateLoading", false);
      if (this.loopTimer) {
        this.tips = "请扫付款码";
        clearTimeout(this.loopTimer);
        this.loopTimer = null;
      }
    },
    // 轮询支付状态
    getStatusLoop() {
      if (this.loopTimer) {
        clearTimeout(this.loopTimer);
        this.loopTimer = null;
      }
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus({ orderNo: this.orderId }, { loading: false })
          .then(res => {
            if (res === "PAYING" || res === "NOT_PAY") {
              this.$message.info("等待用户支付中...");
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              this.isloading = false;
              HardwareService.speak("支付失败，请重新支付");
              this.$message.error("支付失败，请重新支付");
              this.resetData();
            }
            // 支付成功
            else {
              this.paySuccess();
              this.resetData();
            }
          })
          .catch(() => {
            this.isloading = false;
            this.resetData();
          });
      }, 1000);
    },
    // 选择日期
    dateClick(i, date) {
      this.dateIndex = i;
      this.playDate = date;
      this.queryappointmentStockDate();
    },
    // 生成日期(返回时间戳数组): 获取当天到len天
    genarateDate(len) {
      const today = new Date();
      for (let i = 0; i < len; i++) {
        this.dateData.push(today.getTime() + 1000 * 60 * 60 * 24 * i);
      }
      this.playDate = this.dateData[0];
      this.queryappointmentStockDate();
    },
    //获取时段
    queryappointmentStockDate() {
      appointmentStockDate({
        subScenicId: this.subScenic.subScenicId,
        date: this.playDate
      }).then(res => {
        if (res.length > 0) {
          let arr = [];
          let nowtime = new Date().getTime();
          let time = {};
          let timeIndex = 0;
          res.forEach((item, index) => {
            let obj = {
              id: item.id,
              timeShareAppointmentRuleId: item.ruleId,
              time: `${moment(item.beginTime).format("HH:mm")}-${moment(
                item.endTime
              ).format("HH:mm")}`,
              remainingStock: item.remainingStock,
              disabled:
                item.endTime < new Date().getTime() && this.dateIndex === 0
            };
            arr.push(obj);
            if (item.beginTime <= nowtime && nowtime <= item.endTime) {
              time = obj;
              timeIndex = index;
            }
          });
          this.playTimes = arr;
          if (arr.length > 0) {
            this.timeClick(time, timeIndex);
            this.playTime = time;
          }
        }
      });
    },

    //选择团体分类
    changeGroupType(val) {
      let touristType = this.groupTypes.find(item => item.id == val);
      this.groupType.selectGroupId = touristType.id;
      this.groupType.touristDiscountStatus = touristType.touristDiscountStatus; //游客优惠
      this.groupType.discountCategory = touristType.discountCategory; //折扣类型
      this.groupType.touristCategoryName = touristType.touristCategoryName; //折扣类型
      this.groupType.fold = touristType.fold;
      this.groupType.orderType = this.orderType;
      this.groupType.guideProductId = touristType.sybSystemIdProductId;
      if (this.groupType.guideProductId) {
        this.changeGroupProduct(this.groupType.guideProductId);
      }
      let automaticallyMatchProducts = JSON.parse(
        touristType.automaticallyMatchProducts
      );
      //导游票价
      //guideFare	1全免 2免一人 3全价 4限制
      // 游客数量	numberVisitors
      // 封顶设置	isCapped	1封顶免  2不封顶
      // 免几人	freePeople
      this.groupType.guideFare = automaticallyMatchProducts.guideFare;
      this.groupType.numberVisitors = automaticallyMatchProducts.numberVisitors;
      this.groupType.isCapped = automaticallyMatchProducts.isCapped;
      this.groupType.freePeople = automaticallyMatchProducts.freePeople;
      this.groupType.beyondExemption =
        automaticallyMatchProducts.beyondExemption;
      // let groupType = this.groupTypes.find(item => item.id == val);
      // this.groupType.selectDistributor = Number(groupType.id);
      // console.log(this.groupType);
    },
    //选择导游票种
    changeGroupProduct(val) {
      let selectProduct = this.subscenicProductList.find(
        item => item.id == val
      );
      this.groupType.guideProductPice = selectProduct.sellingPrice;
      this.groupType.guideProductId = selectProduct.id;
    },
    //切换个人/团体
    orderTypeChange() {
      this.queryTouristType(this.orderType); //获取游客分类
      this.$refs.myTourist.delAllTourist(this.orderType);
      this.groupType.touristDiscountStatus = 0;
      this.groupType.orderType = this.orderType;
    },
    //获取游客分类
    queryTouristType(chooseType = 1) {
      // chooseType	1.个人下单 2.团体下单 this.orderType
      selectTouristManagementInfo({ chooseType: chooseType }).then(res => {
        if (this.orderType == 1) {
          res.forEach(item => {
            let obj = JSON.parse(item.automaticallyMatchProducts);
            if (obj.choose == 1) {
              //有产品
              item.sybSystemIdProductId =
                obj.automaticallyMatchProductsList[0].productId;
            }
          });
          this.touristType = res;
        } else {
          this.groupTypes = res; //所有的 团体下单
        }
      });
    },
    //查询用户列表
    queryUsers() {
      findUserPageList({
        page: this.page,
        size: this.size,
        userName: this.userName
      }).then(res => {
        this.users = res.records;
        this.pages = res.pages;
      });
    },
    selectUser(userId) {
      this.users.forEach(item => {
        if (item.userId == userId) {
          Object.assign(this.ruleForm, item);
        }
      });
    },
    queryUserInfo() {
      findUserInfo({ userId: this.ruleForm.userId }).then(res => {
        this.ruleForm.userName = res.userName;
        this.ruleForm.phone = res.phone;
        this.ruleForm.guideLicenseId = this.ruleForm.guideInfoDTO.guideLicenseId;
        this.ruleForm.guideLicensePic = this.ruleForm.guideInfoDTO.guideLicensePic;
      });
    },
    //游客分类
    changeTouristType(val) {
      this.touristType = val;
    },
    //订单信息
    changeOrderInfo(val) {
      this.orderInfo = val;
    },
    changeTouristSumData(val) {
      this.orderInfo = val;
    },
    //不能选过去的日期
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    //选择团体分类
    checkTouristCategory(item) {
      this.groupType.selectGroupId = item.id;
      this.groupType.selectGroupName = item.touristCategoryName;
    },

    //选择子景区
    checkSubScenics(subScenic) {
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
    },
    //设置为默认子景区
    setDefault(subScenic) {
      localStorage.setItem("placeSubScenic", JSON.stringify(subScenic));
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
    },
    //子景区列表
    querySubScenics() {
      subScenics({ filterSubScenic: true }).then(res => {
        this.subScenicsList = res.subScenics;
        let find = res.subScenics.find(
          item => item.subScenicId === this.subScenic.subScenicId
        );
        if (!find) {
          //子景区默认设置第一个
          this.subScenic = res.subScenics[0];
          localStorage.setItem(
            "placeSubScenic",
            JSON.stringify(res.subScenics[0])
          );
        }
        this.genarateDate(1);
        // this.queryappointmentStockDate(
        //   moment(this.playDate).format("YYYY-MM-DD")
        // ); //获取时段
      });
    },
    //子景区产品列表
    querySubscenicProducts() {
      subscenicProducts().then(res => {
        res.forEach(item => {
          if (item.subScenicId == this.subScenic.subScenicId) {
            //该子景区下的产品
            this.subscenicProductList = item.skuList;
          }
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.addTouristBtn {
  background-color: #13191d;
  border-color: #30acf3;
}
.a-modal__body .main {
  width: 95%;
  margin: 0 auto;
}
.guide-box {
  height: 300px;
  overflow: auto;
}
.form-item-width {
  width: 170px;
}
.date-li {
  width: 140px;
  height: 40px;
  padding: 10px 0;
  .span {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 0;
    padding: 10px 0;
  }
  .date-act {
    border-radius: @4x;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    background: @primary;
    color: #fff;
    text-align: center;
  }
}
.active_date {
  background-color: @primary;
  color: #fff;
}
</style>
