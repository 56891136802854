<template>
  <div class="wrapper flex-col flex-start">
    <header class="header">
      <span class="font-l flex-grow">选择使用时间段</span>
      <a-icon class="font-xxl pointer gray" type="close" @click="close" />
    </header>
    <div class="main mt-2x">
      <ul class="flex-start align-center flex-wrap pl-3x">
        <li
          class="item flex-center flex-col"
          @click="selectItem(item)"
          :class="{ disable: isDisable(item) }"
          v-for="item in appointmentList"
          :key="item.id"
        >
          <p class="font-xxxl mb-x">
            {{ item.beginTime | date("hh:mm") }} -
            {{ item.endTime | date("hh:mm") }}
          </p>
          <p class="font-l">剩余库存：{{ item.remainStock }}</p>
        </li>
      </ul>
    </div>

    <footer class="footer">
      <a-button
        type="primary"
        ghost
        size="large"
        class="btn-lg"
        shape="round"
        @click="close"
        >返回</a-button
      >
    </footer>
  </div>
</template>
<script>
import { appointmentTimeRuleStock } from "@/api/product";
import moment from "moment";

export default {
  name: "usage-period",
  props: {
    ruleId: String,
    date: Number
  },
  data() {
    return {
      moment,
      params: {}, // 参数对象
      appointmentList: [], // 分时预约库存信息列表
      nowTime: 0
    };
  },
  created() {
    this.getAppointmentList(); // 获取分时预约库存信息列表
  },
  methods: {
    // 获取分时预约库存信息列表
    getAppointmentList() {
      appointmentTimeRuleStock({ ruleId: this.ruleId, date: this.date })
        .then(res => {
          this.nowTime = moment(res[0].currentTime).format("HH:mm");
          this.appointmentList = res;
        })
        .catch(() => {});
    },
    // 选择项目，根据库存和时间判断能否可选
    selectItem(item) {
      // 时间过后，不触发点击
      if (this.isDisable(item)) {
        return;
      }
      //通知父组件选中的规则id
      this.$emit("selectTimeId", item.timeIntervalId);
    },
    //关闭分时预约
    close() {
      this.$emit("close");
    },
    //计算是否不能选择
    isDisable(item) {
      //判断是不是今天
      let isToday = moment(this.date).format("L") === moment().format("L");
      //如果是今天并且时间已经过了，就disable
      if (this.nowTime >= moment(item.endTime).format("HH:mm") && isToday) {
        return true;
      } else if (item.remainStock == 0) {
        //剩余库存为0
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.item {
  margin: 24px 24px 24px 0;
  width: 240px;
  height: 120px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 0 9px 0 rgba(0, 0, 0, 0.26);
  background: @primary;
  color: #fff;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
    box-shadow: 1px 0 9px 0 rgba(0, 0, 0, 0.1);
  }
  &.disable {
    box-shadow: 1px 0 9px 0 rgba(0, 0, 0, 0.1);
    background: #f5f5f5;
    color: @disabled;
    cursor: not-allowed;
  }
}
</style>
