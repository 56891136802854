<!--票务-头部筛选表单
@props: {
  cartId: 购物车id
}
@event: {
  change:筛选条件改变
  update-date:切换游玩日期
  update-time:切换时段
}
-->

<template>
  <div>
    <!--筛选表单-->
    <section class="section filter">
      <!--筛选表单-->
      <div class="flex-between">
        <div class="flex-grow flex-start align-center">
          <a-select
            placeholder="请选择"
            class="scenic-select"
            v-model="subScenicId"
            @change="scenicChange"
          >
            <a-select-option
              v-for="item in subScenics"
              :key="item.subScenicId"
              :value="item.subScenicId"
              >{{ item.subScenicName }}</a-select-option
            >
          </a-select>
          <template v-if="appointmentSelection !== 1">
            <div class="line"></div>
            <div
              class="flex-between appointment-time"
              @click="showTimeFilter('IsShowDate')"
            >
              <div>预约时间：{{ datesId | date }} {{ timeIdTxt }}</div>
              <div>
                {{ getSettings.IsShowDate ? "收起" : "展开" }}
                <a-icon :type="getSettings.IsShowDate ? 'up' : 'down'" />
              </div>
            </div>
          </template>
        </div>
        <a-input-search
          v-inputfocus
          allow-clear
          class="search-input"
          placeholder="请输入产品名称"
          v-model="searchKey"
          @search="onSearch"
        >
          <a-button slot="enterButton" icon="search" type="primary" ghost>
            搜索
          </a-button>
        </a-input-search>
      </div>
      <!--选择日期时段-->
      <template v-if="appointmentSelection !== 1">
        <div class="select-date" v-show="getSettings.IsShowDate">
          <div class="flex-start align-center">
            <p class="flex-shrink">选择日期：</p>
            <!--2限制范围-->
            <ul
              class="flex-start flex-wrap align-center"
              v-if="dateRange === 2"
            >
              <li
                class="date-item"
                :class="{ active: item === datesId }"
                v-for="item in dates"
                :key="item"
                @click="chenckTimes(item)"
              >
                {{ item | date }}
              </li>
            </ul>
            <!--1、3日期不限/T后不限-->
            <a-date-picker
              v-else
              class="ml-2x"
              v-model="date"
              :disabled-date="disabledDate"
              format="YYYY-MM-DD"
              :allow-clear="false"
              @change="dateChange"
            />
          </div>
          <div class="flex-start align-center" v-show="isShowTime">
            <p class="flex-shrink">选择时段：</p>
            <ul class="flex-start flex-wrap">
              <li
                class="time-item flex-center align-center"
                v-for="item in times"
                :class="{
                  active: item.id === timeId,
                  disabled: item.remainingStock <= 0
                }"
                :key="item.id"
                @click="checkTime(item)"
              >
                <div>
                  <b>
                    {{ item.beginTime | date("hh:mm") }}
                    -
                    {{ item.endTime | date("hh:mm") }}</b
                  >
                  <p>库存：{{ item.remainingStock }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </section>
    <!--门票类型-->
    <section
      class="ticket-type bd-t flex-start align-center flex-wrap"
      v-if="getSettings.IsShowProductClass"
    >
      <div
        class="ticket-type-item"
        :class="{ active: classId == '' }"
        @click="checkClass('')"
      >
        全部
      </div>
      <div
        class="ticket-type-item"
        :class="{ active: classId === item.id }"
        v-for="item in productClass"
        :key="item.id"
        @click="checkClass(item.id)"
      >
        {{ item.name }}
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  merchantSettingOrderInfo,
  subScenics as getSubScenic
} from "../../api/merchant";
import { appointmentStockDate } from "../../api/product";
import { productFrontAggregatecategory } from "../../api/search";
import moment from "moment";

export default {
  name: "ProductFilter",

  props: {
    cartId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      searchKey: "", // 搜索的key
      subScenics: [], // 子景区列表
      subScenicId: "", // 选中的子景区id
      appointmentRules: "", // 分时预约规则id
      scenicDpotReservation: [], // 景区设置-子景区、景区分时预约规则
      dateRange: 1, // 日期范围
      dates: [], // 日期列表
      datesId: "", // 选中的日期
      date: moment(),
      defaultData: "", // T+n天后可选
      playDate: "", // 游玩日期
      times: [], // 时段列表
      timeId: "", // 选中的时段id
      timeIdTxt: "", // 选中的时间段时间显示
      isShowTime: true, // 是否显示时间段
      productClass: [], // 类型列表
      classId: "", // 选中类型id
      originAppointmentSelection: 1, // 景区后台设置的分时预约设置 1 产品维度 2 子景区, 3景区
      appointmentSelection: 1 // 当前分时预约设置 1 产品维度 2 子景区, 3景区，切换子景区后，如果未配置分时预约，则为产品维度
    };
  },

  computed: {
    ...mapGetters({
      // 页面布局开关
      getSettings: "setting/getSettings"
    }),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    }
  },

  created() {
    this.loadScenics()
      .then(() => {
        this.change();
      })
      .catch(() => {});
  },

  methods: {
    moment,
    // 加载子景区列表 和时段
    async loadScenics() {
      // 窗口端控制 filterSubScenic
      const { subScenics } = await getSubScenic({ filterSubScenic: true });
      if (subScenics && subScenics.length) {
        this.subScenics = subScenics;
        this.subScenicId = subScenics[0].subScenicId;
        this.loadProductClass(); //加载子景区下的产品分类
        // 系统分类
        const commercialClass = {
          // 票务
          appNewHome: 6,
          // 游乐
          appPlayNew: 7,
          // 组合
          appCombinationNew: 13,
          // 餐饮
          appCateringNew: 10
        };
        // 获取景区设置
        // 最新业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
        const setting = await merchantSettingOrderInfo({
          formatType: commercialClass[this.currentTopMenuKey]
        });
        if (setting.orderRules.length === 0) {
          this.$error({
            title: "提示",
            content:
              "该景区未配置下单规则，请到后台管理系统的“景区设置”配置或联系管理员！"
          });
          return;
        }
        // 处理可选日期
        this.loadDate(setting);
        this.originAppointmentSelection = setting.appointmentSelection;
        this.appointmentSelection = setting.appointmentSelection;
        this.scenicDpotReservation = setting.scenicDpotReservation; // 景区、子景区维度分时预约规则
        // appointmentSelection 分时预约选择 1.产品  2.子景区 3.景区
        this.isShowTime = setting.appointmentSelection !== 1;

        this.$emit("appointment", setting.appointmentSelection);
        if (setting.appointmentSelection !== 1) {
          // 景区维度 可以从配置获取到分时预约规则id
          if (setting.appointmentSelection === 3) {
            this.appointmentRules =
              setting.scenicDpotReservation[0].appointmentRules;
          }

          // 获取时段列表
          await this.getAppointmentStockDate();
        }
      }
    },
    // 获取时段列表
    async getAppointmentStockDate() {
      if (this.originAppointmentSelection === 2) {
        // 如果景区设置-子景区维度没有选择分时预约规则，则按产品维度走（即下单时先选产品再选时段）
        if (
          !this.scenicDpotReservation.find(
            item => item.systemId === this.subScenicId
          )
        ) {
          this.appointmentSelection = 1;
          this.$emit("appointment", this.appointmentSelection);
          return false;
        } else {
          this.appointmentSelection = 2;
          this.$emit("appointment", this.appointmentSelection);
        }
      }
      const obj = {
        date: this.datesId
      };
      // 子景区维度的分时预约需要根据子景区id获取时段
      if (this.appointmentSelection === 2) {
        obj.subScenicId = this.subScenicId;
      }
      // 景区维度则需要根据后台设置选中的规则id获取
      else {
        obj.ruleId = this.appointmentRules; // 规则id
      }
      const data = await appointmentStockDate(obj);
      this.times = data;
      this.$emit("appointment", this.appointmentSelection, data);
      // 子景区分时预约规则
      if (data.length) this.appointmentRules = data[0].ruleId;
      // 查找库存大于0的时段
      const temp = data.find(val => val.remainingStock > 0);
      if (temp) {
        this.timeId = temp.id;
        this.timeIdTxt =
          moment(temp.beginTime).format("HH:mm") +
          "-" +
          moment(temp.endTime).format("HH:mm");
      }
      // 如果没有可预约时段，则清空购物车，清空产品列表
      else {
        this.timeId = "";
        this.$message.warning("暂无可预约时段");
        this.$emit("clear");
      }

      return data;
    },
    // 禁用日期
    disabledDate(current) {
      return (
        current &&
        current <
          (this.defaultData == "" ? moment().add(-1, "day") : this.defaultData)
      );
    },
    // 加载日期 1不限 2 T+N后N天 3：T+N
    loadDate({ correspondOrderRules }) {
      if (correspondOrderRules && correspondOrderRules.length) {
        const obj = correspondOrderRules[0];
        if (obj.orderDateRange == 1) {
          this.dateRange = 1;
          this.datesId = moment()
            .startOf("day")
            .valueOf();
          // 抛出日期可选日期范围，空为不限
          this.$emit("date-range", this.datesId, "");
        } else if (obj.orderDateRange == 2) {
          this.dateRange = 2;
          // 计算可选日期范围
          let t = Number(obj.ttoDay);
          let dates = [];
          for (let i = 0; i <= t; i++) {
            let day = moment()
              .startOf("day")
              .add(+Number(i + obj.tafterDay), "day")
              .valueOf();
            if (i == 0) {
              this.datesId = day;
            }

            dates.push(day);
          }
          this.dates = dates;
          // 抛出日期可选日期范围，空为不限
          this.$emit("date-range", dates[0], dates[dates.length - 1]);
        } else if (obj.orderDateRange == 3) {
          this.defaultData = moment()
            .startOf("day")
            .add(+Number(obj.tafterDay), "day")
            .valueOf();
          this.date = moment().add(+Number(obj.tafterDay), "day");
          this.datesId = this.defaultData;
          // 抛出日期可选日期范围，空为不限
          this.$emit("date-range", this.defaultData, "");
        }
      } else {
        this.dateRange = 1;
        this.datesId = moment()
          .startOf("day")
          .valueOf();
        // 抛出日期可选日期范围，空为不限
        this.$emit("date-range", this.datesId, "");
        this.$message.warning(
          '该渠道未配置下单规则，请到"景区后台-设置-景区设置"配置或联系管理员'
        );
      }
    },
    // 加载景区设置 加载时段
    // async loadScenicSetting() {
    //
    // },
    // 加载产品分类
    loadProductClass() {
      // 系统分类
      const customClass = {
        // 票务
        appNewHome: 6,
        // 游乐
        appPlayNew: 7,
        // 组合
        appCombinationNew: 13
      };
      productFrontAggregatecategory({
        // 业态：16会员 6票务 7游乐 8剧院 9酒店 10餐饮 11零售 12租赁 13组合
        formats: customClass[this.currentTopMenuKey],
        subScenicId: this.subScenicId
      })
        .then(res => {
          this.productClass = res;
          // if (res.length) this.classId = res[0].id;
        })
        .catch(() => {});
    },

    // 日期选择
    async dateChange(val) {
      this.datesId = val.valueOf();
      // 获取时段列表
      await this.getAppointmentStockDate();
      this.updateCartDate();
      this.change();
    },

    // 选择日期
    async chenckTimes(item) {
      this.datesId = item;
      // 获取时段列表
      await this.getAppointmentStockDate();
      this.updateCartDate();
      this.change();
    },

    // 更新购物车游玩日期
    updateCartDate() {
      // 购物车和可预约时段同时满足
      if (this.cartId && this.timeId) {
        this.$emit("update-date", {
          cartId: this.cartId,
          playDate: this.datesId
        });
      }
    },

    // 选择时段
    checkTime(item) {
      if (item.remainingStock <= 0) return;
      this.timeId = item.id;
      this.timeIdTxt =
        moment(item.beginTime).format("HH:mm") +
        "-" +
        moment(item.endTime).format("HH:mm");
      this.updateCartAppointment();
      this.change();
    },
    // 更新购物车时段
    updateCartAppointment() {
      if (this.cartId) {
        this.$emit("update-time", {
          cartId: this.cartId,
          timeIntervalId: this.timeId
        });
      }
    },
    // 选择分类
    checkClass(id) {
      this.classId = id;
      this.change();
    },
    // 搜索
    onSearch(val) {
      this.change(val);
    },
    // 景区切换
    async scenicChange() {
      this.classId = ""; // 切换子景区恢复分类为全部
      this.loadProductClass();
      if (this.originAppointmentSelection !== 1) {
        // 获取时段列表
        await this.getAppointmentStockDate();
      }
      this.change();
      this.$emit("clear", true);
    },
    // 筛选条件改变
    change() {
      const obj = {
        subScenicId: this.subScenicId,
        name: this.searchKey,
        categoryId: this.classId
      };
      // 如果景区设置开启分时预约
      if (this.appointmentSelection !== 1) {
        obj.playDate = this.datesId;
        obj.appointmentRules = this.appointmentRules;
        obj.timeId = this.timeId;
      }
      this.$emit("change", obj);
    },

    // 收起时间选择
    showTimeFilter(name) {
      let item = this.getSettings[name];
      this.$store.commit(`setting/set${this.currentTopMenuKey + name}`, !item);
    }
  }
};
</script>

<style scoped lang="less">
@import "../../assets/less/custom";
@import "ticket-order-common";
/*筛选表单*/
.filter {
  .scenic-select {
    width: 150px;
    margin-right: 24px;
  }
  .line {
    margin-right: 24px;
    height: 24px;
    border-right: 1px solid #d1d1d1;
  }
  .appointment-time {
    width: 305px;
    height: 30px;
    margin-right: 24px;
    padding: 0 12px;
    line-height: 30px;
    align-self: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    user-select: none;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    &:hover {
      cursor: pointer;
      border-color: @primary;
    }
  }
  .search-input {
    width: 389px;
  }

  .select-date {
    padding: 16px 16px 0;
    margin-left: 186px;
    .date-item {
      margin-left: 18px;
      width: 130px;
      height: 36px;
      line-height: 34px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #b8b8b8;
      border-radius: 30px;
      color: #333;
      font-weight: bold;
      cursor: pointer;
    }

    .time-item {
      margin-left: 16px;
      margin-top: 16px;
      width: 130px;
      height: 50px;
      border: 1px solid #b8b8b8;
      border-radius: 14px;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}

/*门票类型*/
.ticket-type {
  padding: 16px 16px 0;
  background: #fff;
  .ticket-type-item {
    margin: 0 16px 16px 0;
    padding: 0 10px;
    width: max-content;
    min-width: 120px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #b8b8b8;
    opacity: 1;
    border-radius: 31px;
    cursor: pointer;
  }
}

.active {
  border-color: @primary !important;
  background: @primary !important;
  color: #fff !important;
}
</style>
