<template>
  <a-modal
    :visible="vis"
    title="门票补办"
    width="780px"
    @cancel="close"
    :body-style="{
      'max-height': '460px'
    }"
    :mask-closable="false"
  >
    <div class="mb-2x font-l">
      <a-icon class="primary mr-1x" type="exclamation-circle" />
      <span v-if="activateInfo.voucherType != 0" class="bold"
        >补办后，原绑定门票将失效</span
      >
      <span v-if="activateInfo.voucherType == 0" class="bold"
        >补办后，原打印门票将失效</span
      >
    </div>
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="refTicketReissue"
    >
      <div class="flex-start">
        <!-- 不需要绑定介质 -->
        <div>
          <a-form-model-item :label-col="subLabelCol" label="门票名称">
            <span>{{ activateInfo.productName }}</span>
          </a-form-model-item>
          <a-form-model-item :label-col="subLabelCol" label="已办理次数">
            <span>{{ activateInfo.reissueTimes }}</span>
          </a-form-model-item>
          <a-form-model-item :label-col="subLabelCol" label="补办费用">
            <!-- 永远免费补办 -->
            <span v-if="activateInfo.reprintFeeType == 0">0</span>
            <!-- 有免费次数，免费次数用完，需要转为收费：免费补办次数 大于 已补办次数: 免费 -->
            <span
              v-if="
                activateInfo.reprintFeeType == 1 &&
                  activateInfo.freePrintTimes > activateInfo.printTimes
              "
              >0</span
            >
            <!-- 免费补办次数 大于 已补办次数: 收费 -->
            <a-input
              v-if="
                activateInfo.reprintFeeType == 1 &&
                  activateInfo.freePrintTimes <= activateInfo.printTimes
              "
              class="wid"
              v-model="activateInfo.printFee"
              placeholder="请输入"
            />
          </a-form-model-item>
          <a-form-model-item :label-col="subLabelCol" label="补办说明">
            <a-input class="wid" v-model="form.remark" placeholder="请输入" />
          </a-form-model-item>
        </div>

        <!-- 主票号：需要绑定腕带 -->
        <div
          class="ml-3x"
          v-if="
            (activateInfo.voucherType != 0 &&
              !activateInfo.subTicketListInfo) ||
              !(
                activateInfo.subTicketListInfo &&
                activateInfo.subTicketListInfo.length
              )
          "
        >
          <a-form-model-item
            :label-col="subLabelCol"
            :wrapper-col="subWrapperCol"
            label="绑定介质"
          >
            <div class="mb-3x">
              <IcCardinput v-model="number"></IcCardinput>
            </div>
          </a-form-model-item>
        </div>

        <!-- 有子票号：需要绑定介质 -->
        <div
          class="ml-3x"
          v-if="
            activateInfo.subTicketListInfo &&
              activateInfo.subTicketListInfo.length
          "
        >
          <a-form-model-item
            v-if="activateInfo.voucherType != 0"
            :label-col="subLabelCol"
            :wrapper-col="subWrapperCol"
            label="绑定介质"
          >
            <template v-if="activateInfo.subTicketListInfo">
              <div
                v-for="(item, index) in activateInfo.subTicketListInfo"
                :key="index"
                class="mb-3x"
              >
                <IcCardinput v-model="item.number"></IcCardinput>
              </div>
            </template>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
    <div class="flex-between align-center" slot="footer">
      <div>
        <span>
          押金：
          <!-- 免费补办次数 大于 已补办次数: 免费 -->
          <span
            class="red"
            v-if="activateInfo.freePrintTimes > activateInfo.printTimes"
          >
            {{ 0 | money }}
          </span>
          <!-- 免费补办次数 大于 已补办次数: 收费 -->
          <span v-else class="red">
            {{ (activateInfo.printFee * 100) | money }}
          </span>
        </span>
      </div>
      <div>
        <a-button key="cancel" @click="close">取消</a-button>
        <a-button type="primary" @click="sure" :loading="loading"
          >确定补办</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  findTicketReissueInfo
  // payOfflineOrder
} from "../../../../api/order";
import {
  getTicketOrReissue,
  discountInfoCalculate
} from "../../../../api/cart";
import { orderTicketprintinfo } from "../../../../api/search";
import IcCardinput from "../../../../components/hardware/IcCardinput.vue";
import HardwareService from "../../../../common/domains/hardware-domain/hardwareService";
export default {
  name: "TicketReissue",
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    IcCardinput
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 10 },
      subLabelCol: { span: 8 },
      subWrapperCol: { span: 16 },
      form: {
        remark: "" // 补办说明
      },
      number: "",
      rules: {},
      activateInfo: {}, // 门票补办查询数据
      loading: false // 加载中
    };
  },
  mounted() {
    this.queryTicket();
  },
  methods: {
    // 门票补办 查询
    queryTicket() {
      findTicketReissueInfo({
        id: this.detail.id
      })
        .then(res => {
          // console.log("门票补办查询--数据", res);
          // 有绑定介质的场景
          if (res.subTicketListInfo && res.subTicketListInfo.length) {
            const deepCopyRes = JSON.parse(JSON.stringify(res));
            deepCopyRes.subTicketListInfo.forEach(item => {
              item.number = ""; // 存储卡腕带信息
            });
            this.activateInfo = deepCopyRes;
          } else {
            res && (this.activateInfo = res);
          }
          // 把价格单位 分 转为元
          this.activateInfo.printFee = this.activateInfo.printFee / 100;
        })
        .catch(() => {});
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 确定补办
    sure() {
      let flag = true;
      let arr = [];
      // 有子票号的场景，校验卡腕带是否填写
      if (
        this.activateInfo.subTicketListInfo &&
        this.activateInfo.subTicketListInfo.length &&
        this.activateInfo.voucherType != 0
      ) {
        this.activateInfo.subTicketListInfo.forEach(item => {
          if (!item.number) {
            flag = false;
          }
          arr.push({
            activateNow: "", // 是否立即激活
            travelerList: [
              {
                personId: "", // 人脸Id
                requiredPersonId: false, // 是否需要人脸Id
                voucherNo: item.number, // 绑定的腕带
                requiredVoucherNo: true, // 是否需要绑定的腕带
                visitorFieldList: [] // 身份证相关
              }
            ]
          });
        });
      }
      // 校验主票号卡腕带是否填写(有子票号时，不需要校验主票号)
      if (
        !this.number &&
        this.activateInfo.voucherType != 0 &&
        (!this.activateInfo.subTicketListInfo ||
          !this.activateInfo.subTicketListInfo.length)
      ) {
        flag = false;
      }
      // 主票号，绑腕带
      arr = [
        {
          activateNow: "", // 是否立即激活
          travelerList: [
            {
              personId: "", // 人脸Id
              requiredPersonId: false, // 是否需要人脸Id
              voucherNo: this.number, // 绑定的腕带
              requiredVoucherNo: true, // 是否需要绑定的腕带
              visitorFieldList: [] // 身份证相关
            }
          ]
        }
      ];
      if (!flag) {
        this.$message.warning("还有卡腕带未填写");
        return;
      }

      const params = {
        productId: this.activateInfo.productId, // 产品id
        spuId: this.activateInfo.spuId, // 产品spuid
        businessCategory: 7, // 业务类型，6取票，7补办
        buyChannels: 6, // 购买渠道，窗口端6，自助机8
        ticketNo: this.activateInfo.auxiliaryId, // 票号
        totalPrice:
          this.activateInfo.freePrintTimes > this.activateInfo.printTimes
            ? 0
            : this.activateInfo.printFee * 100, // 取票或补办的金额(单位：分)
        remark: this.form.remark, // 备注
        spuSnapshotId: this.activateInfo.spuRuleSnapshotId, // spu快照id
        productTravelerList: arr // 出游人列表
      };
      this.loading = true;
      getTicketOrReissue(params)
        .then(res => {
          this.$store.dispatch("order/setCartList", { id: res.id });

          // 补办费用为0的话，不跳转到确认订单及支付页，静默处理
          if (params.totalPrice <= 0) {
            this.sureOrder(res.id);
            return;
          }
          // 补办需要费用，去确认订单页面
          this.$router.push({ path: "/home/sell/OrderInfo" });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 补办费用为0时，确认订单及支付
    sureOrder(id) {
      discountInfoCalculate({
        id // 购物车id
      })
        .then(() => {
          // console.log(res, "0000");
          this.$emit("updateParentData");
          this.$message.success("补办成功");
          this.form.remark = "";
          this.$emit("update:vis", false);
          if (this.activateInfo.voucherType == 0) {
            this.printTicket();
          }
          // 现金支付
          // payOfflineOrder({
          //   orderId: res.orderId,
          //   payWay: 3 //3现金支付
          // })
          //   .then(() => {
          //     this.$emit("updateParentData");
          //     this.$message.success("补办成功");
          //     this.form.remark = "";
          //     this.$emit("update:vis", false);
          //     // 纸质票 - 打印
          //     if (this.activateInfo.voucherType == 0) {
          //       this.printTicket();
          //     }
          //   })
          //   .catch(() => {})
          //   .finally(() => {
          //     this.loading = false;
          //   });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 纸质门票 打印
    printTicket() {
      //门票打印逻辑
      orderTicketprintinfo({ orderId: this.detail.orderId })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.wid {
  width: 200px;
}
</style>
