<!--门票资产概况-->
<template>
  <div class="ph-2x">
    <!--门票资产列表-->
    <ul class="flex-start pv-1x">
      <li class="card-item" v-for="item in list" :key="item.id">
        <p class="text-1">{{ item.name }}</p>
        <p class="mt-1x text-1 font-l bold">{{ item.num / 100 }}</p>
      </li>
    </ul>
    <!--变更日期-->
    <div class="mb-2x">
      <span>变更日期</span>
      <a-range-picker
        class="ml-2x mr-2x"
        v-model="date"
        @change="pickerChange"
        valueFormat="YYYY-MM-DD"
        style="width: 300px"
      >
      </a-range-picker>
      <span class="ml-2x mr-3x">门票资产类型</span>
      <a-select
        v-model="assetsType"
        placeholder="请选择"
        allow-clear
        style="width: 200px"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option v-for="item in typaist" :key="item.id" :value="item.id"
          >{{ item.name }}
        </a-select-option>
      </a-select>
      <a-button class="ml-3x" type="primary" @click="onSearch">查询</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="ticketassetsstateData"
      :pagination="false"
      :scroll="{ x: true }"
      size="middle"
      row-key="id"
    >
      <template slot="createTime" slot-scope="row">
        {{ row.createTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template slot="changeNumber" slot-scope="row">{{
        row.changeNumber / 100
      }}</template>
      <template slot="balance" slot-scope="row"
        >{{ row.balance / 100 }}
      </template>
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x mb-4x" v-if="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        @change="currentChange"
        @showSizeChange="sizeChange"
      ></a-pagination>
    </footer>
  </div>
</template>

<script>
import { findTicketAssetsList } from "@/api/order";
import { orderTicketassetsstate } from "@/api/search";
import { productMerchantCategoryList } from "@/api/product";

export default {
  name: "TicketAccount",

  data() {
    return {
      assetsType: "", //选中门票资产类型id
      typaist: [], //门票资产类型列表
      changeBeginTime: "", //变更开始日期
      changeEndTime: "", //变更结束日期
      date: [],
      list: [], // 资产列表
      ticketassetsstateData: [], //变更记录
      total: 0,
      page: 1,
      size: 5,
      columns: [
        {
          width: 150,
          title: "变更时间",
          scopedSlots: { customRender: "createTime" },
          key: "createTime"
        },
        {
          width: 150,
          title: "变更资产类型",
          dataIndex: "assetsType",
          key: "assetsType"
        },
        {
          width: 100,
          title: "变更数量",
          scopedSlots: { customRender: "changeNumber" },
          key: "changeNumber"
        },
        {
          width: 110,
          title: "变更后余额",
          scopedSlots: { customRender: "balance" },
          key: "balance"
        },
        {
          width: 150,
          title: "变更单据编号",
          dataIndex: "changeOrderId",
          key: "changeOrderId"
        },
        {
          width: 120,
          title: "操作商户",
          dataIndex: "changeOperatorMerchantName",
          key: "changeOperatorMerchantName"
        },
        {
          width: 120,
          title: "操作渠道",
          dataIndex: "changeChannels",
          key: "changeChannels"
        },
        {
          width: 120,
          title: "操作人",
          dataIndex: "changeOperatorName",
          key: "changeOperatorName"
        },
        {
          width: 120,
          title: "操作备注",
          dataIndex: "remarks",
          key: "remarks"
        }
        // {
        //   width: 120,
        //   title: "操作",
        //   scopedSlots: { customRender: "action" },
        //   key: "action"
        // }
      ]
    };
  },
  created() {
    this.loadData();
    this.getOrderTicketassetsstate();
    this.getCategoryList();
  },
  methods: {
    // 条数改变
    sizeChange(p, s) {
      this.page = p;
      this.size = s;
      this.getOrderTicketassetsstate();
    },
    // 页面改变
    currentChange(p) {
      this.page = p;
      this.getOrderTicketassetsstate();
    },
    onSearch() {
      this.page = 1;
      this.getOrderTicketassetsstate();
    },
    //获取门票资产类型
    getCategoryList() {
      productMerchantCategoryList({ type: "3" })
        .then(res => {
          this.typaist = res;
        })
        .catch(() => {});
    },
    // 日期选择
    pickerChange(val) {
      if (val) {
        this.changeBeginTime = new Date(val[0]).getTime();
        this.changeEndTime = new Date(val[1]).getTime();
      } else {
        this.changeBeginTime = "";
        this.changeEndTime = "";
      }
    },
    //查询资产列表（游乐金，喂食金...）
    loadData() {
      findTicketAssetsList({
        id: this.$route.query.id
      })
        .then(res => {
          this.list = res;
        })
        .catch(() => {});
    },

    //获取变更记录
    getOrderTicketassetsstate() {
      orderTicketassetsstate({
        orderDetailId: this.$route.query.id,
        changeBeginTime: this.changeBeginTime,
        changeEndTime: this.changeEndTime,
        assetsType: this.assetsType,
        page: this.page,
        size: this.size
      })
        .then(res => {
          this.ticketassetsstateData = res.records;
          this.total = res.total;
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped lang="less">
.card-item {
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  margin-right: 16px;
  margin-bottom: 16px;
  padding-top: 24px;
  border-radius: 8px;
  text-align: center;
  background-color: #f6f6f6;
  // background: @bg-gray;
}
</style>
