/**
 * 根据检索某个key达到去重数据对象
 * @param { Array } arr
 * @param { String } key
 * @returns Array
 */
export function clearRepeatArrayObject(arr, key) {
  if (!arr.length) return [];
  const obj = {};
  const newArr = [];
  for (let i in arr) {
    if (obj[arr[i][key]]) {
      continue;
    } else {
      obj[arr[i][key]] = true;
      newArr.push(arr[i]);
    }
  }
  return newArr;
}
