<template>
  <a-modal
    :visible="vis"
    title="激活门票"
    width="780px"
    @cancel="close"
    :body-style="{
      'max-height': '460px'
    }"
    :mask-closable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="refExtensionServiceTime"
    >
      <!-- 只需要修改状态 -->
      <a-form-model-item label="门票名称">
        <span>{{ activateInfo.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="主票票号">
        <a-row type="flex" justify="start">
          <a-col :span="6">
            {{ activateInfo.auxiliaryId }}
          </a-col>
          <a-col :span="14">
            <a-checkbox v-model="form.check">立即激活</a-checkbox>
          </a-col>
        </a-row>
      </a-form-model-item>

      <!-- （主票号）激活需要补充人脸及身份证 -->
      <a-form-model-item
        v-if="
          !activateInfo.subTicketListInfo ||
            activateInfo.subTicketListInfo.length <= 0
        "
        :colon="false"
        label=" "
        :label-col="labelCol"
      >
        <a-row
          type="flex"
          justify="start"
          v-if="activateInfo.byTicketId || activateInfo.bindIdCardNo"
        >
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <IdCardInput v-model="idCardNumber"></IdCardInput>
          </a-col>
        </a-row>
        <a-row
          class="mt-2x"
          type="flex"
          justify="start"
          v-if="activateInfo.byFaces || activateInfo.bindFaceURL"
        >
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <FaceInput
              :echoimgurl="faceUrl"
              v-model="faceNumber"
              @imgUrlChange="imgUrl"
            ></FaceInput>
          </a-col>
        </a-row>
      </a-form-model-item>

      <!-- （子票号）激活需要补充人脸及身份证 -->
      <a-form-model-item
        v-if="
          (activateInfo.byTicketId || activateInfo.byFaces) &&
            activateInfo.subTicketListInfo &&
            activateInfo.subTicketListInfo.length
        "
        label="子票票号"
      >
        <div
          v-for="(item, index) in activateInfo.subTicketListInfo"
          :key="index"
          class="mb-3x"
        >
          <a-row type="flex" justify="start" v-if="activateInfo.byTicketId">
            <a-col :span="6">
              <span v-if="activateInfo.byTicketId">
                {{ item.auxiliaryId }}
              </span>
            </a-col>
            <a-col :span="10">
              <IdCardInput v-model="item.subIdCardNumber"></IdCardInput>
            </a-col>
          </a-row>
          <a-row
            class="mt-x"
            type="flex"
            justify="start"
            v-if="activateInfo.byFaces"
          >
            <a-col :span="6">
              <span v-if="!activateInfo.byTicketId">
                {{ item.auxiliaryId }}
              </span>
            </a-col>
            <a-col :span="10">
              <FaceInput
                v-model="item.subFaceNumber"
                :echoimgurl="item.subFaceUrl"
                @imgUrlChange="
                  url => {
                    subImgUrl(url, index);
                  }
                "
              ></FaceInput>
            </a-col>
          </a-row>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="flex-end" slot="footer">
      <a-button key="cancel" @click="close">取消</a-button>
      <a-button type="primary" @click="goActive" :loading="loading"
        >激活</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { findTicketActivateInfo, ticketActivate } from "../../../../api/order";
import IdCardInput from "../../../../components/hardware/IdCardInput.vue";
import FaceInput from "../../../../components/hardware/FaceInput.vue";
export default {
  name: "TicketActivition",
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    IdCardInput,
    FaceInput
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      form: {
        check: true
      },
      loading: false,
      rules: {},
      activateInfo: {},
      idCardNumber: "", // 身份证输入框
      faceNumber: "", // 人脸信息框
      faceUrl: "" // 人脸图片地址
    };
  },
  mounted() {
    this.findActivateInfo();
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 门票激活查询
    async findActivateInfo() {
      const res = await findTicketActivateInfo({
        id: this.detail.id
      }).catch(() => {});
      // 没有子票号场景
      if (res) {
        this.faceUrl = res.bindFaceURL || "";
        this.activateInfo = res;
      }
      // 有子票号场景
      if (res.subTicketListInfo) {
        const deepCopyRes = JSON.parse(JSON.stringify(res));
        deepCopyRes.subTicketListInfo.forEach(item => {
          item.subIdCardNumber = ""; // 存储硬件读取的身份证
          item.subFaceNumber = ""; // 存储硬件读取的人脸
          item.subFaceUrl = item.bindFaceURL || ""; // 存储硬件读取的人脸
        });
        this.activateInfo = deepCopyRes;
      }
      // console.log("门票激活查询数据--", this.activateInfo);
    },
    // 主票号-人脸图片
    imgUrl(url) {
      this.faceUrl = url;
    },
    // 子票号 - 人脸图片
    subImgUrl(url, index) {
      this.activateInfo.subTicketListInfo[index].subFaceUrl = url;
    },
    // 确定激活按钮
    goActive() {
      if (!this.form.check) {
        this.$message.warning("还没勾选激活");
        return;
      }
      if (!this.activateInfo.subTicketListInfo) {
        if (!this.idCardNumber && this.activateInfo.byTicketId) {
          this.$message.warning("请先录入身份证");
          return;
        }
        if (!this.faceUrl && this.activateInfo.byFaces) {
          this.$message.warning("请先采集人脸");
          return;
        }
      }
      const arr = [];
      // 子票号情况校验: (门票激活：不需要填卡腕带，只有身份证和人脸的情况)
      if (
        this.activateInfo.subTicketListInfo &&
        (this.activateInfo.byTicketId || this.activateInfo.byFaces)
      ) {
        if (!this.check()) {
          this.$message.warning("信息填写不完整");
          return;
        }
        this.activateInfo.subTicketListInfo.forEach(item => {
          arr.push({
            id: item.id, // 门票订单详情记录id
            idCardNo: item.subIdCardNumber, // 子票号绑定的身份证号
            faceId: item.subFaceNumber, // 子票号绑定的人脸id
            faceUrl: item.subFaceUrl
          });
        });
      }
      this.loading = true;
      ticketActivate({
        channel: "", // 操作渠道, 非必填
        auxiliaryInfoList: [
          {
            ifActivateNow: this.form.check, // 是否立即激活
            id: this.activateInfo.id, // 门票订单详情记录id
            auxiliaryId: this.activateInfo.auxiliaryId, // 主票号
            idCardNo: this.idCardNumber || "", // 主票号绑定的身份证号
            faceId: this.faceNumber || "", // 主票号绑定的人脸id
            faceUrl: this.faceUrl,
            // 子票号
            ticketPrintSubAuxiliaryInfoList: arr
          }
        ]
      })
        .then(() => {
          this.$emit("updateParentData");
          this.$emit("update:vis", false);
          this.$message.success("激活成功");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    check() {
      const idCardArr = [];
      const faceArr = [];
      let flag = true;
      // 用f、t来存储是否填写了身份证及人脸内容
      this.activateInfo.subTicketListInfo.forEach(item => {
        !item.subIdCardNumber ? idCardArr.push("f") : idCardArr.push("t");
        // 人脸
        !item.subFaceUrl ? faceArr.push("f") : faceArr.push("t");
      });
      // 只有身份证校验
      if (this.activateInfo.byTicketId && !this.activateInfo.byFaces) {
        idCardArr.indexOf("f") == -1 ? (flag = true) : (flag = false);
      } else if (!this.activateInfo.byTicketId && this.activateInfo.byFaces) {
        // 只有人脸校验
        faceArr.indexOf("f") == -1 ? (flag = true) : (flag = false);
      } else {
        // 既要校验身份证，也要校验人脸
        idCardArr.indexOf("f") == -1 && faceArr.indexOf("f") == -1
          ? (flag = true)
          : (flag = false);
      }
      return flag;
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
/deep/.ant-modal-body {
  overflow-y: auto;
}
</style>
