var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-form ml-2x mt-3x"},[(_vm.formItemData.requiredVoucherNo)?_c('a-form-model',{ref:"voucherForm",attrs:{"label-col":_vm.labelCol,"model":_vm.formItemData,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"卡/腕带编号","prop":"voucherNo","rules":[
        {
          required: true,
          message: '请输入卡/腕带编号',
          trigger: 'blur'
        }
      ]}},[_c('a-input',{attrs:{"placeholder":"请输入卡/腕带编号"},model:{value:(_vm.formItemData.voucherNo),callback:function ($$v) {_vm.$set(_vm.formItemData, "voucherNo", $$v)},expression:"formItemData.voucherNo"}})],1)],1):_vm._e(),(_vm.formItemData.requiredPersonId)?_c('a-form-model',{ref:"personIdForm",attrs:{"model":_vm.formItemData,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"人脸信息","prop":"personId","required":false,"rules":[
        {
          required: false,
          message: '请录入人脸'
        }
      ]}},[_c('face-input',{key:Math.random(),attrs:{"echoimgurl":_vm.formItemData.personFaceUrl},on:{"imgUrlChange":function (res) {
            _vm.formItemData.personFaceUrl = res;
          }},model:{value:(_vm.formItemData.personId),callback:function ($$v) {_vm.$set(_vm.formItemData, "personId", $$v)},expression:"formItemData.personId"}})],1)],1):_vm._e(),_vm._l((_vm.formItemData.visitorFieldList),function(item,index){return _c('a-form-model',{key:index,ref:"ticketsInfoForm",refInFor:true,attrs:{"model":item,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[(item.whetherSystemField && item.fieldType == 5)?_c('a-form-model-item',{ref:"phone",refInFor:true,attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入手机号',
          trigger: ['change', 'blur']
        },
        {
          type: 'number',
          message: '手机号需为数字'
        },
        {
          validator: _vm.checkPhone
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", _vm._n($$v))},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 6)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入身份证号',
          trigger: ['change', 'blur']
        },
        {
          message: '身份证格式错误'
        },
        {
          validator: _vm.checkIdCard
        }
      ]}},[_c('IdCardInput',{on:{"readCard":_vm.readCard},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 21)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入社保卡号',
          trigger: ['change', 'blur']
        },
        {
          message: '社保卡号格式错误'
        },
        {
          validator: _vm.checkNormal
        }
      ]}},[_c('IdCardInput',{attrs:{"medical-card":"","placeholder":"请刷社保卡/输入社保卡号"},on:{"readCard":_vm.readXbCard},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 4)?_c('a-form-model-item',{ref:"name",refInFor:true,attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入姓名',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 12)?_c('a-form-model-item',{ref:"address",refInFor:true,attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入地址',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 7)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择生日时间',
          triggger: 'change'
        }
      ]}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","disabled-date":_vm.disabledDate,"format":"YYYY-MM-DD"},on:{"change":function () {
            var params = [], len = arguments.length;
            while ( len-- ) params[ len ] = arguments[ len ];

            item.fieldValue = params[1];
          }},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 8)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择性别',
          triggger: 'change'
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v("女")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v("男")])],1)],1):_vm._e(),(item.whetherSystemField && item.fieldType == 9)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请拍摄证件照',
          triggger: 'change'
        }
      ]}},[_c('picture-input',{staticClass:"m-x",model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 10)?_c('a-form-model-item',{ref:"name",refInFor:true,attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入司机姓名',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 11)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入司机车牌号',
          trigger: ['change', 'blur']
        },
        {
          message: '错误的车牌号'
        },
        {
          validator: _vm.checkLicensePlate
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 12)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入地址',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 13)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入邮箱',
          trigger: ['change', 'blur']
        },
        {
          message: '邮箱格式错误'
        },
        {
          validator: _vm.checkEmail
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 14)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入微信号',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 15)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择证件类型',
          triggger: 'change'
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},_vm._l((item.fieldOptions),function(temp,index){return _c('a-select-option',{key:index,attrs:{"value":temp}},[_vm._v(_vm._s(temp))])}),1)],1):_vm._e(),(item.whetherSystemField && item.fieldType == 16)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择你的国家',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},_vm._l((item.fieldOptions),function(temp,index){return _c('a-select-option',{key:index,attrs:{"value":temp}},[_vm._v(_vm._s(temp))])}),1)],1):_vm._e(),(item.whetherSystemField && item.fieldType == 17)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入台胞证',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 18)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入港澳通行证',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 19)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请输入护照',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(item.whetherSystemField && item.fieldType == 20)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择游客类型',
          trigger: ['change', 'blur']
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 1)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '必填项不能为空',
          triggger: 'blur'
        }
      ]}},[_c('a-input',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 2)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '请选择日期',
          triggger: 'change'
        }
      ]}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","format":"YYYY-MM-DD"},on:{"change":function () {
            var params = [], len = arguments.length;
            while ( len-- ) params[ len ] = arguments[ len ];

            item.fieldValue = params[1];
          }},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}})],1):_vm._e(),(!item.whetherSystemField && item.fieldType == 3)?_c('a-form-model-item',{attrs:{"label":item.fieldName,"prop":"fieldValue","rules":[
        {
          required: item.required,
          message: '必填项不能为空',
          triggger: 'blur'
        }
      ]}},[_c('a-select',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},_vm._l((item.fieldOptions),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son}},[_vm._v(_vm._s(son))])}),1)],1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }