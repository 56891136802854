<!--
  @name: 窗口售票，门票列表部分
  @author: fengyanlong
  @date:2021-03-31
-->
<!--废弃页面待删除-->
<template>
  <div>
    <!--门票列表-->
    <div
      class="wrapper flex-between flex-col relative"
      v-show="!visible1 && !visible2 && !showMember"
    >
      <!-- 头部开始  -->
      <div class="header">
        <a-form-model ref="ruleForm" :model="form" layout="inline">
          <a-form-model-item label="游玩日期" class="ml-x">
            <a-date-picker
              placeholder="选择游玩日期"
              :disabled-date="disabledDate"
              :default-value="moment(form.playDate)"
              @change="dateChange"
              style="width: 160px"
            />
          </a-form-model-item>
          <a-form-model-item label="门票分类" class="ml-x">
            <a-select
              v-model="form.categoryId"
              defaultValue=""
              placeholder="选择门票分类"
              style="width: 160px"
              @change="selectChange"
            >
              <a-select-option value="">所有门票</a-select-option>
              <a-select-option
                v-for="item in ticketTypeList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="产品名称" class="ml-x">
            <a-input-search
              style="width: 215px"
              v-model="form.name"
              placeholder="输入产品名称搜索"
              @search="onSearch"
              v-inputfocus
              enter-button
              allowClear
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 头部结束  -->

      <!-- 列表开始 -->
      <a-empty description="暂无数据" v-if="ticketList.length === 0" />
      <div
        class="main flex-grow"
        v-if="ticketList.length > 0"
        v-show="!visible1 && !visible2"
      >
        <a-row :gutter="[16, 16]">
          <a-col
            :xxl="8"
            :xl="12"
            :lg="24"
            v-for="(item, index) in ticketList"
            :key="item.id"
          >
            <div class="ticket-card" @click="addTicket(item)">
              <img
                alt="图片"
                class="image"
                :src="item.skuImages || ''"
                v-show="isShowProductImg"
              />
              <div class="flex-between flex-col flex-grow ml-2x">
                <div class="title text-1" v-html="item.name"></div>
                <div class="text-1 mv-1x" @click.stop="() => {}">
                  <a-popover title="标签" trigger="click">
                    <template slot="content">
                      <p v-for="(tags, index) in item.tags" :key="index">
                        {{ tags }}
                      </p>
                    </template>
                    <a-tag
                      color="blue"
                      v-for="(tags, index) in item.tags"
                      :key="index"
                    >
                      {{ tags }}
                    </a-tag>
                  </a-popover>
                </div>
                <div class="flex-start align-center">
                  <p class="flex-grow font-l money ">
                    {{ item.sellingPrice | money }}
                  </p>
                  <p class="f-key" v-if="index < 10 && shortcutKey">
                    按
                    <span class="red">{{ index === 9 ? 0 : index + 1 }}</span>
                    快捷键购买
                  </p>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- 列表结束 -->

      <!-- 底部开始 -->
      <footer class="footer">
        <member-menu
          v-permission="'appHomeSellMember'"
          @click="showMember = true"
        ></member-menu>
        <div
          v-permission="'appHomeSellCustomMoney'"
          class="menu"
          @click="$router.push('/home/sell/CustomAmount')"
        >
          直接收款<span class="f-key" v-if="shortcutKey">(F2)</span>
        </div>
        <div
          v-permission="'appHomeSellEntryOrder'"
          class="menu"
          :class="{ disabled: !cartList.id }"
          @click="cartList.id ? handlePause() : showTips('pause')"
        >
          <span>挂单<span class="f-key" v-if="shortcutKey">(F3)</span> </span>
        </div>
        <div
          v-permission="'appHomeSellTakeTicket'"
          class="menu"
          :class="{ disabled: !isEnableTakeOrderBtn }"
          @click="isEnableTakeOrderBtn ? toTakeOrderList() : showTips('take')"
        >
          <span>取单<span class="f-key" v-if="shortcutKey">(F4)</span> </span>
        </div>
        <div
          v-permission="'appHomeSellMember'"
          class="menu"
          @click="$router.push('/home/ticket/GetTicket')"
        >
          取票<span class="f-key" v-if="shortcutKey">(F5)</span>
        </div>
        <div
          v-permission="'appHomeSellTicketAction'"
          class="menu"
          @click="$router.push('/home/ticket/TicketOperate')"
        >
          门票操作<span class="f-key" v-if="shortcutKey">(F6)</span>
        </div>
        <div
          v-permission="'appHomeSellOrderMenage'"
          class="menu"
          @click="$router.push('/home/sell/OrderManage')"
        >
          订单管理<span class="f-key" v-if="shortcutKey">(F7)</span>
        </div>
      </footer>
      <!-- 底部结束 -->
    </div>

    <!-- 会员详情 -->
    <member-detail
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>

    <!-- 分时预约开始 -->
    <usage-period
      @close="visible1 = false"
      v-if="visible1"
      :ruleId="ruleId"
      :date="form.playDate"
      @selectTimeId="selectTimeId"
    ></usage-period>
    <!-- 分时预约结束 -->

    <!-- 加购列表开始 -->
    <additional-purchase
      @close="closeAdditional"
      @open="visible2 = true"
      v-show="visible2"
    >
    </additional-purchase>
    <!-- 加购列表结束 -->
  </div>
</template>

<script>
import moment from "moment";
import { productFrontWindowTicket } from "@/api/search"; // MS-搜索接口 3.0
import { productMerchantCategoryList } from "@/api/product"; // MS-产品中心接口 3.0
import {
  shoppingCartList,
  addShoppingCart,
  updateShoppingCart,
  pauseCart
} from "@/api/cart"; // MS-产品中心接口 3.0
import { mapActions, mapGetters } from "vuex";
import UsagePeriod from "./UsagePeriod.vue";
import AdditionalPurchase from "./AdditionalPurchase";
import { onkeydown } from "@/utils/global";
import { registerSettleAccounts } from "@/api/cart";
import MemberMenu from "../../../components/member/MemberMenu.vue";
import MemberDetail from "../member/MemberDetail";

export default {
  name: "HomeTicketList",
  components: { UsagePeriod, AdditionalPurchase, MemberMenu, MemberDetail },
  data() {
    return {
      /**全局变量 */
      visible1: false, //是否显示分时预约的窗口
      visible2: false, //是否显示加购列表
      showMember: false, // 展示会员弹窗
      /**头部变量 */
      moment, // 初始化moment
      form: {
        playDate: moment().valueOf(), // 游玩日期
        categoryId: "", // 门票分类id
        name: "" // 产品名称
      },
      ticketTypeList: [], // 门票分类列表
      /**列表变量 */
      ticketList: [], // 门票列表
      tmpTicket: null, //临时存放一个ticket数据
      ruleId: "", //分时预约规则id
      timeId: null //选中某个预约时段的id
    };
  },
  created() {
    this.getTicketTypeList(); // 获取门票分类列表
    this.getTicketList(); // 获取门票列表
    this.getShoppingCartList(); // 获取挂单购物车列表
    this.setEndOfPayConfig({ backUrl: "/home", logoutMember: true }); //设置支付完成回跳地址
    localStorage.setItem("showcartT2", "true"); // 共享数据，给T2副屏用（副屏是另外一个VUE实例）,用于告知副屏是否显示购物车组件
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    ...mapActions("order", ["setCartList", "setEndOfPayConfig"]),
    ...mapActions("common", ["updateMember"]),
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    //根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          // console.log("按了F1");
          // this.$router.push({ path: "/home/sell/MemberDetail" });
          this.showMember = true;
          break;

        case 113:
          // console.log("按了F2");
          this.$router.push({ path: "/home/sell/CustomAmount" });
          break;

        case 114:
          // console.log("按了F3");
          this.cartList.id ? this.handlePause() : this.showTips("pause");
          break;

        case 115:
          // console.log("按了F4");
          this.isEnableTakeOrderBtn
            ? this.toTakeOrderList()
            : this.showTips("take");
          break;

        case 116:
          // console.log("按了F5");
          this.$router.push("/home/ticket/GetTicket");
          break;

        case 117:
          // console.log("按了F6");
          this.$router.push("/home/ticket/TicketOperate");
          break;

        case 118:
          // console.log("按了F7");
          this.$router.push({ path: "/home/sell/OrderManage" });
          break;

        case 48:
        case 96:
          // console.log("按了键盘0");
          this.addTicket(this.ticketList[9]);
          break;

        case 49:
        case 97:
          // console.log("按了键盘1");
          this.addTicket(this.ticketList[0]);
          break;

        case 50:
        case 98:
          // console.log("按了键盘2");
          this.addTicket(this.ticketList[1]);
          break;

        case 51:
        case 99:
          // console.log("按了键盘3");
          this.addTicket(this.ticketList[2]);
          break;

        case 52:
        case 100:
          // console.log("按了键盘4");
          this.addTicket(this.ticketList[3]);
          break;

        case 53:
        case 101:
          // console.log("按了键盘5");
          this.addTicket(this.ticketList[4]);
          break;

        case 54:
        case 102:
          // console.log("按了键盘6");
          this.addTicket(this.ticketList[5]);
          break;

        case 55:
        case 103:
          // console.log("按了键盘7");
          this.addTicket(this.ticketList[6]);
          break;

        case 56:
        case 104:
          // console.log("按了键盘8");
          this.addTicket(this.ticketList[7]);
          break;

        case 57:
        case 105:
          // console.log("按了键盘9");
          this.addTicket(this.ticketList[8]);
          break;
        case 13:
          console.log("按了键盘enter");
          if (!this.cartList.ticketNum)
            return this.$message.warning("请先选择商品");
          if (this.doingAddPurchase)
            return this.$message.warning("请先完成推荐加购产品的选择");
          if (this.$store.state.common.windowType === "sellTicket") {
            //请求出游人信息
            //请求接口的方法
            let submit = () => {
              registerSettleAccounts({ id: this.cartList.id })
                .then(res => {
                  if (res.needVisitorInfo) {
                    this.$router.push({
                      name: "homeRegisterTouristInfo",
                      params: {
                        data: res
                      }
                    });
                  } else {
                    this.$router.push("/home/sell/OrderInfo");
                  }
                })
                .catch(() => {});
            };
            submit();
          } else {
            this.$router.push("/home/sell/OrderInfo");
          }
          break;

        default:
          break;
      }
    },
    // 获取挂单购物车列表
    getShoppingCartList() {
      shoppingCartList({ id: this.cartList.id })
        .then(res => {
          if (res.length > 0) {
            this.$store.commit("common/updateTakeOrderBtnStatus", true); // 更改vuex中的isEnableTakeOrderBtn的值为true
          } else {
            this.$store.commit("common/updateTakeOrderBtnStatus", false); // 更改vuex中的isEnableTakeOrderBtn的值为false
          }
        })
        .catch(() => {});
    },
    // 获取门票列表
    getTicketList() {
      productFrontWindowTicket({
        name: this.form.name,
        categoryId: this.form.categoryId,
        playDate: this.form.playDate,
        page: 1,
        size: 999
      })
        .then(res => {
          this.ticketList = res.records;
        })
        .catch(() => {});
    },
    // 获取门票类型列表 类型 1：门票，2：二销产品，3：资产类型，4：项目
    getTicketTypeList() {
      productMerchantCategoryList({ type: 1 })
        .then(res => {
          this.ticketTypeList = res;
          this.ticketTypeList.unshift({
            id: 99,
            name: "热销产品"
          });
        })
        .catch(() => {});
    },
    /**页面交互的方法 */
    // 日期选择
    dateChange(data) {
      let that = this;
      if (!this.cartList.id) {
        that.form.playDate = data.valueOf();
        that.$nextTick(() => {
          that.getTicketList();
        });
        return;
      }
      this.$confirm({
        title: "修改日期将清空购物车！",
        content: "若想买多日票请分开下单",
        onOk() {
          that.setCartList({}); // 清空购物车缓存
          that.form.playDate = data.valueOf();
          that.$nextTick(() => {
            that.getTicketList();
          });
        },
        onCancel() {}
      });
    },
    // 下拉框选择
    selectChange(value) {
      this.form.categoryId = value;
      this.getTicketList();
    },
    // 搜索
    onSearch(value) {
      this.form.name = value;
      this.getTicketList();
    },
    //点击列表里的门票
    addTicket(row) {
      if (!row) return;
      this.visible1 = false; //预防键盘事件进行购买，选择时间的弹窗一直都在
      let api = addShoppingCart,
        data = {
          productId: row.id, // 产品id
          spuId: row.spuId, // 产品的spuId
          buyNum: 1, // 购买数量
          buyChannels: 6, // 购买渠道
          businessCategory: 1, // 业务类型
          timeShareAppointmentRuleId: row.timeShareAppointmentRuleId, // 分时预约时段Id
          timeIntervalId: this.timeId,
          playDate: this.form.playDate //游玩日期
        };
      if (this.cartList.id) {
        api = updateShoppingCart;
        data.id = this.cartList.id;
      }
      api(data).then(res => {
        // 如果后端告诉我需要分时预约id
        if (res.needTimeShareAppointmentRuleId == true) {
          this.ruleId = row.timeShareAppointmentRuleId;
          this.tmpTicket = row;
          this.visible1 = true;
        } else {
          this.setCartList(res); //更新购物车到vuex
          localStorage.setItem("T2cartList", JSON.stringify(res)); // 共享数据，给T2副屏用（副屏是另外一个VUE实例）
        }
      });
    },
    //选好了分时预约id
    selectTimeId(timeId) {
      this.timeId = timeId;
      this.addTicket(this.tmpTicket);
      //用完后就清空临时变量
      this.timeId = null;
      this.tmpTicket = null;
      this.visible1 = false;
    },
    // 处理挂单状态
    handlePause() {
      if (this.cartList.id) {
        pauseCart({ id: this.cartList.id })
          .then(() => {
            // this.clearCar(); // 清空购物车缓存
            this.setCartList({});
            this.$emit("updateCart", {}); // 清空购物车显示
            this.$store.commit("common/updateTakeOrderBtnStatus", true); // 更改vuex中的isEnableTakeOrderBtn的值为true
          })
          .catch(() => {});
      }
    },
    // 去取单页面
    toTakeOrderList() {
      this.$router.push({
        path: "/home/sell/TakeOrderList",
        query: { cartId: this.cartList.id }
      });
    },
    //不能选过去的日期
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().startOf("day");
    },
    // 显示提示语
    showTips(val) {
      if (val === "pause") {
        this.$message.warning("购物车无产品，不能挂单");
      } else if (val === "take") {
        this.$message.warning("无取单列表，不能取单");
      }
    },
    closeAdditional() {
      this.visible2 = false;
      //通知购物车加购完成了
      this.$store.commit("ticketSales/setdoingAddPurchase", false);
    }
  },
  computed: {
    ...mapGetters({
      isShowProductImg: "setting/getisShowProductImg",
      shortcutKey: "setting/getshortcutKey",
      doingAddPurchase: "ticketSales/getdoingAddPurchase"
    }),
    ...mapGetters("order", ["cartList"]),

    // 查询是否启用取单按钮的值(默认值：false)
    isEnableTakeOrderBtn() {
      return this.$store.state.common.isEnableTakeOrderBtn;
    }
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.wrapper {
  margin-right: 0;
  /*头部*/
  .header {
    padding: 12px 0 12px 16px;
  }
  /*中部*/
  .main {
    padding: 16px;
    margin-top: 16px;
    /*margin-bottom: 10px;*/
    .ticket-card {
      display: flex;
      height: 130px;
      box-sizing: border-box;
      padding: 12px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover,
      &:active {
        box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
      }
      .image {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        flex-shrink: 0; // 防止图片被压缩
      }
      .title {
        font-size: 18px;
      }
      .text-1 {
        width: fit-content;
      }
      .money {
        font-size: 22px;
      }
      .ant-tag {
        border: none;
      }
    }
    .ticket-card-no-img {
      display: none;
    }
  }
  /*底部*/
  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px;
    .menu {
      position: relative;
      flex-shrink: 0;
      width: 13%;
      padding: 0 4px;
      border: 1px solid @primary;
      height: 40px;
      line-height: 38px;
      text-align: center;
      color: @primary;
      border-radius: 20px;
      cursor: pointer;
      &.action {
        color: #ffffff;
        background-color: @primary;
      }
      &.disabled {
        border-color: @disabled;
        color: @disabled;
        cursor: not-allowed;
        background: #f5f5f5;
      }
      .iconfont {
        font-size: 22px;
        position: absolute;
        top: -15px;
        right: -13px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}
</style>
