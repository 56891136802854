<template>
  <a-row class="ph-2x">
    <a-col :span="detail.ifExistTicketSub ? 24 : 12">
      <a-row>
        <a-col :span="detail.ifExistTicketSub ? 12 : 24">
          <div class="flex-start mt-2x">
            <div :span="5" class="gray">票号</div>
            <div :span="19">
              <span>{{ detail.auxiliaryId }}</span>
              <a-popover class="ml-2x" title="票号" width="250" trigger="click">
                <span class="blue pointer">详情</span>
                <section
                  slot="content"
                  class="gray"
                  v-for="(item, index) in ticketSubDetailList || []"
                  :key="index"
                  :class="{ 'mt-2x': index > 0 }"
                >
                  <p>主票号：{{ item.mainTicketNo }}</p>
                  <p v-if="item.subTicketNo" class="mt-x">
                    子票号：{{ item.subTicketNo }}
                  </p>
                  <p v-if="item.voucherNo" class="mt-x">
                    卡/腕带编号：{{ item.voucherNo }}
                  </p>
                </section>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">游玩日期</div>
            <div>{{ detail.useDate | date }}</div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">预约时段</div>
            <div>
              {{ detail.appointmentPeriodStartTime | date("hh:mm") }} ~
              {{ detail.appointmentPeriodEndTime | date("hh:mm") }}
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">使用有效期</div>
            <div>
              {{ detail.beginValidTime | date }} ~
              {{ detail.endValidTime | date }}
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">实收金额</div>
            <div>
              <span class="red">{{ detail.receivedAmount | money }}</span>
              <a-popover class="ml-2x" title="价格详情" trigger="click">
                <span class="blue pointer">详情</span>
                <div class="gray" slot="content">
                  <p>产品单价：{{ detail.totalAmount | money }}</p>
                  <p class="mt-2x">
                    优惠余额：{{ detail.discountAmount | money }}
                  </p>
                  <p class="mt-2x">
                    优惠类型：{{ discountInfo.discountTypeName }}
                  </p>
                  <p class="mt-2x">活动名称：{{ discountInfo.activityName }}</p>
                  <p class="mt-2x">
                    实收金额：{{ detail.receivedAmount | money }}
                  </p>
                </div>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">门票状态</div>
            <div class="primary">{{ detail.statusText }}</div>
          </div>
        </a-col>
        <a-col :span="detail.ifExistTicketSub ? 12 : 24">
          <div class="flex-start mt-2x">
            <div class="gray">出游人</div>
            <div>
              <span>{{ playerInfo }}</span>
              <a-popover class="ml-2x" title="出游人信息">
                <span class="blue pointer">详情</span>
                <section
                  slot="content"
                  class="gray pv-2x"
                  :class="{ 'bd-t': index > 0 }"
                  v-for="(item, index) in visitorInfo || []"
                  :key="index"
                >
                  <div
                    v-if="item.bindFaceUrl"
                    class="flex-start align-center mb-1x"
                  >
                    <p class="mr-x">人脸照:</p>
                    <img
                      width="80"
                      height="80"
                      class="pointer"
                      :src="item.bindFaceUrl"
                      alt="人脸图片"
                      @click="enlargeImg(item.bindFaceUrl)"
                    />
                  </div>
                  <p
                    v-for="(temp, i) in item.userFieldInfoList || []"
                    :key="'userFieldInfoList' + i"
                    :class="{ 'mt-1x': i > 0 }"
                  >
                    {{ temp.fieldName }}:
                    <span v-if="temp.fieldType === 8">{{
                      temp.fieldValue === "1"
                        ? "女"
                        : temp.fieldValue === "2"
                        ? "男"
                        : "未知"
                    }}</span>
                    <img
                      v-else-if="temp.fieldType === 9"
                      :src="temp.fieldValue"
                      alt=""
                      width="80"
                      height="80"
                      class="pointer"
                      @click="enlargeImg(temp.fieldValue)"
                    />
                    <span v-else>{{ temp.fieldValue }}</span>
                  </p>
                </section>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">取票人</div>
            <div>
              <span>{{ takerInfo }}</span>
              <a-popover
                class="ml-2x"
                title="取票人信息"
                width="250"
                trigger="click"
              >
                <span class="blue pointer">详情</span>
                <section
                  slot="content"
                  class="gray mb-1x"
                  v-for="(item, index) in receiverInfo"
                  :key="index"
                >
                  {{ item.fieldName }}:
                  <!-- <span v-if="item.fieldType === 8">{{
                    item.fieldValue === "1"
                      ? "女"
                      : item.fieldValue === "2"
                      ? "男"
                      : "未知"
                  }}</span> -->
                  <img
                    v-if="item.fieldType === 9"
                    :src="item.fieldValue"
                    alt=""
                    width="80"
                    height="80"
                    class="pointer"
                    @click="enlargeImg(item.fieldValue)"
                  />
                  <span v-else>{{ item.fieldValue }}</span>
                </section>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">会员信息</div>
            <div>
              <span>{{
                ticketMemberInfo.idCardNumber || ticketMemberInfo.mobile
              }}</span>
              <a-popover
                class="ml-2x"
                title="会员信息"
                width="250"
                trigger="click"
              >
                <span class="blue pointer">详情</span>
                <div class="gray" slot="content">
                  <p>姓名: {{ ticketMemberInfo.name }}</p>
                  <p class=" mt-1x">手机号: {{ ticketMemberInfo.mobile }}</p>
                  <p class=" mt-1x">
                    身份证: {{ ticketMemberInfo.idCardNumber }}
                  </p>
                </div>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x">
            <div class="gray">订单信息</div>
            <div>
              <span>{{ detail.orderId }}</span>
              <a-popover
                class="ml-2x"
                title="取票人信息"
                width="250"
                trigger="click"
              >
                <span class="blue pointer">详情</span>
                <div class="gray" slot="content">
                  <p>订单编号: {{ orderBasisInfo.orderId }}</p>
                  <p class=" mt-1x">
                    下单时间:
                    {{
                      orderBasisInfo.createTime | date("yyyy-mm-dd hh:mm:ss")
                    }}
                  </p>
                  <p class=" mt-1x">
                    销售商户:
                    {{ orderBasisInfo.orderSaleMerchantName }}
                  </p>
                  <p class=" mt-1x">
                    销售渠道: {{ orderBasisInfo.buyChannelsText }}
                  </p>
                  <p class=" mt-1x">收款备注: {{ orderBasisInfo.remarks }}</p>
                  <p class=" mt-1x">
                    销售操作人: {{ orderBasisInfo.salespersonName }}
                  </p>
                  <p class=" mt-1x">
                    支付方式: {{ orderBasisInfo.payWayText }}
                  </p>
                  <p class=" mt-1x">
                    支付通道: {{ orderBasisInfo.payChannels }}
                  </p>
                  <p class=" mt-1x">
                    外部订单号: {{ orderBasisInfo.outsideCode }}
                  </p>
                </div>
              </a-popover>
            </div>
          </div>
          <div class="flex-start mt-2x" v-if="detail.orderRefundInfo">
            <div class="gray">退款信息</div>
            <div>
              <span>{{ detail.orderRefundInfo.refundId }}</span>
              <a-popover
                class="ml-2x"
                title="取票人信息"
                width="250"
                trigger="click"
              >
                <span class="blue pointer">详情</span>
                <div class="gray" slot="content">
                  <p>退款单号: {{ orderRefundInfo.refundId }}</p>
                  <p class=" mt-1x">
                    退款时间:
                    {{
                      orderRefundInfo.refundCompleteTime
                        | date("yyyy-mm-dd hh:mm:ss")
                    }}
                  </p>
                  <p class=" mt-1x">
                    退款渠道: {{ orderRefundInfo.refundPasswayName }}
                  </p>
                  <p class=" mt-1x">退款备注: {{ orderRefundInfo.remarks }}</p>
                  <p class=" mt-1x">
                    退款操作人:
                    {{ orderRefundInfo.refundAuditPersonName }}
                  </p>
                  <p class=" mt-1x">
                    退款通道: {{ orderRefundInfo.refundPasswayName }}
                  </p>
                  <p class=" mt-1x">
                    外部退款号: {{ orderRefundInfo.refundutsideCode }}
                  </p>
                </div>
              </a-popover>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="12" v-if="!detail.ifExistTicketSub">
      <div class=" flex-start mt-2x">
        <div class="gray">可使用日期</div>
        <div>
          {{
            ticketWriteOffConfigInfo.checkTravelDateType === 0
              ? "门票有效期内都可核销"
              : "仅预约游玩日期可核销"
          }}
        </div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">可核销次数</div>
        <div v-if="ticketWriteOffConfigInfo.enterTimesType">
          {{ ticketWriteOffConfigInfo.enterTimes }}
        </div>
        <div v-else>不限制</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">每日可核销</div>
        <div v-if="ticketWriteOffConfigInfo.dailyEnterTimesType">
          {{ ticketWriteOffConfigInfo.dailyEnterTimes }}
        </div>
        <div v-else>不限制</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">可核销月份</div>
        <div>{{ ticketWriteOffConfigInfo.validMonths }}</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">可核销星期</div>
        <div>{{ ticketWriteOffConfigInfo.validWeeks }}</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">可核销时段</div>
        <div v-if="ticketWriteOffConfigInfo.useTimeType">
          <template v-if="ticketWriteOffConfigInfo.useTimeRange">
            {{ ticketWriteOffConfigInfo.useTimeRange[0] | date("hh:mm") }} ~
            {{ ticketWriteOffConfigInfo.useTimeRange[1] | date("hh:mm") }}
          </template>
        </div>
        <div v-else>不限制</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">已核销次数</div>
        <div>{{ detail.alreadyWriteOffTimes }}</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">首次核销时间</div>
        <div>{{ detail.firstWriteOffTime | date("yyyy-mm-dd hh:mm:ss") }}</div>
      </div>
      <div class="flex-start mt-2x">
        <div class="gray">核销后有效期</div>
        <div>
          {{ detail.afterWriteOffValidTime | date("yyyy-mm-dd hh:mm:ss") }}
        </div>
      </div>
    </a-col>
    <!-- 放大图片预览 弹窗 -->
    <a-modal
      @cancel="enlargeImgVis = false"
      width="60%"
      :visible.sync="enlargeImgVis"
      title=""
      :footer="null"
    >
      <div class="flex-center preview-img">
        <img :src="enlargeImgUrl" class="res p-1x" />
      </div>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  name: "ticketDetail",
  props: {
    // 基本信息
    detail: {
      type: Object,
      default() {
        return {};
      }
    },
    // 票号详情
    ticketSubDetailList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 门票出游人列表
    visitorInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 优惠信息
    discountInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 门票出游人列表
    customerList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 取票人列表信息
    receiverInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 出游人
    playerInfo: {
      type: String,
      default() {
        return "";
      }
    },
    // 取票人
    takerInfo: {
      type: String,
      default() {
        return "";
      }
    },
    // 门票副票及腕带列表
    ticketVoucherList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 门票核销配置信息
    checkConfVO: {
      type: Object,
      default() {
        return {};
      }
    },
    // 会员详情信息
    ticketMemberInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 订单详情
    orderBasisInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 退款信息
    orderRefundInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 核销配置信息
    ticketWriteOffConfigInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      enlargeImgVis: false,
      enlargeImgUrl: ""
    };
  },
  methods: {
    enlargeImg(imgUrl) {
      this.enlargeImgUrl = imgUrl;
      this.enlargeImgVis = true;
    }
  }
};
</script>

<style lang="less" scoped>
.flex-start > .gray {
  width: 100px;
}
.preview-img {
  img {
    width: 400px;
    height: 400px;
  }
}
</style>
