var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"mb-2x flex-start align-center"},[_c('span',{staticClass:"flex-shrink"},[_vm._v("退款单号")]),_c('div',[_c('a-input-search',{staticClass:"ml-2x",attrs:{"placeholder":"请输入单品票号/退款单号/组合产品票号","suffix-icon":"el-icon-search","allowClear":""},on:{"change":_vm.inputChange},model:{value:(_vm.refundId),callback:function ($$v) {_vm.refundId=$$v},expression:"refundId"}})],1)]),_c('a-table',{attrs:{"scroll":{ x: 1366 },"pagination":false,"rowKey":"auxiliaryId","columns":_vm.tableColumns,"data-source":_vm.refundList,"header-cell-class-name":"bg-gray bd-t"},scopedSlots:_vm._u([{key:"refundTime",fn:function(ref){
var refundTime = ref.refundTime;
return _c('span',{},[_vm._v(_vm._s(_vm._f("date")(refundTime,"YYYY-MM-DD hh:mm:ss")))])}},{key:"refundAmount",fn:function(ref){
var refundAmount = ref.refundAmount;
return [_vm._v(_vm._s(_vm._f("money")(refundAmount)))]}},{key:"actualRefundAmount",fn:function(ref){
var actualRefundAmount = ref.actualRefundAmount;
return [_vm._v(_vm._s(_vm._f("money")(actualRefundAmount)))]}},{key:"refundStatusText",fn:function(ref){
var refundStatusText = ref.refundStatusText;
return _c('span',{staticClass:"primary"},[_vm._v(_vm._s(refundStatusText))])}},{key:"remarks",fn:function(ref){
var remarks = ref.remarks;
return _c('span',{attrs:{"title":remarks}},[_vm._v(_vm._s(remarks))])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }