<!--交账详情-->
<template>
  <div class="flex-col flex-between wrapper">
    <div class="header">
      <p class="font-l mr-4x">交账详情</p>
      <div class="flex-grow flex-start">
        <div class="text-style">
          员 工：{{ userInfos.userName }} / {{ userInfos.phone }}
        </div>
        <div class="text-style flex-start">
          <span style="width:120px">交账日期：</span>
          <a-date-picker
            v-model="nowTime"
            type="date"
            @change="changeDate"
            style="width: 100%"
            format="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>
    <!--交账tab-->
    <div class="flex-grow mt-2x container">
      <AccountslTable :userInfos="userInfos" />
    </div>
    <!--底部-->
    <footer class="footer">
      <!-- <a-button
        v-permission="'accountIndexDetailRecording'"
        class="btn-lg"
        type="primary"
        ghost
        shape="round"
        size="large"
        @click="$router.push({ path: '/account/AccountsRecording' })"
        >查看交账记录</a-button
      > -->
      <div class="flex-grow flex-end align-center">
        <a-switch @change="onChange" v-model="isCheck" />
        <span class="ml-x ">打印销售汇总</span>
        <a-button
          class="btn-lg ml-3x"
          type="primary"
          shape="round"
          size="large"
          @click="submit"
          >确认交账
        </a-button>
      </div>
    </footer>
    <!-- 确认交帐弹窗 -->
    <a-modal
      v-model="dialag"
      @cancel="confirmBtn"
      title="提示"
      :footer="null"
      :width="500"
    >
      <p class="font-m">交账成功, 确定后将退出登录，祝您工作愉快！</p>
      <div class="flex-center mt-3x">
        <a-button type="primary" @click="confirmBtn">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import AccountslTable from "./components/AccountslTable";
import moment from "moment";
import { getHandOverUserInfo } from "@/api/order";
import { orderAccountConfirmsummary } from "@/api/search";
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
export default {
  name: "BaseAccountsDetail",
  components: { AccountslTable },
  data() {
    return {
      dialag: false,
      isCheck: true, //打印销售汇总开关
      printTemplateId: "", //打印模板id
      //员工信息
      userInfos: {
        clockIn: "", //上班时间
        clockOff: "", //交账时间
        phone: "", //手机号
        userName: "" //员工名字
      },
      nowTime: moment().format("YYYY-MM-DD")
    };
  },
  created() {
    this.printTemplateId = localStorage.getItem("accountTemplateId");
    this.getHandOverUserInfo();
  },
  methods: {
    moment,
    changeDate(val) {
      let date = val.format("YYYY-MM-DD");
      this.userInfos.clockIn = date + " 00:00:00";
      this.userInfos.clockOff = date + " 23:59:00";
    },
    onChange(checked) {
      this.isCheck = checked;
    },
    //获取交账的用户信息
    getHandOverUserInfo() {
      getHandOverUserInfo().then(res => {
        let date = moment().format("YYYY-MM-DD");
        res.clockIn = date + " 00:00:00";
        res.clockOff = date + " 23:59:00";
        this.userInfos = res;
      });
    },
    //确认交账
    submit() {
      const hardware = localStorage.getItem("Hardware");
      //拿到最新的打印模板id
      this.printTemplateId =
        hardware && JSON.parse(hardware).accountforTemplate;
      if (this.isCheck && this.printTemplateId === null) {
        this.$message.warning(
          "请在设置页面->硬件设置弹窗->交账打印设置选择打印模板",
          6
        );
        return false;
      }
      // submitHandOver({
      //   printTemplateId: this.printTemplateId
      orderAccountConfirmsummary({
        accountStartTime: this.userInfos.clockIn,
        accountEndTime: this.userInfos.clockOff,
        print: this.isCheck ? 1 : "0",
        repayAccount: 2 //是否补交账 1:不是  2:是
      }).then(res => {
        if (this.isCheck) {
          // let str = JSON.stringify(
          //   res.printTemplateInfoVO.templateLayoutInfoList
          // );
          let str = JSON.stringify(res);
          HardwareService.printDailyReport(str);
        }
        this.dialag = true;
      });
    },
    //交帐弹窗确认
    confirmBtn() {
      this.dialag = false;
      localStorage.removeItem("token");
      // 为了清除vuex状态内容
      let t = setTimeout(() => {
        window.location.reload();
        this.$router.push(`/login`);
        clearTimeout(t);
        t = null;
      }, 300);
    }
  }
};
</script>

<style lang="less" scoped>
// 头部
.header {
  .text-style {
    margin-right: 32px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 40px;
    background-color: #f4f9fc;
  }
}
</style>
