<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="订单详情"></panel-head>

    <section class="ph-2x pb-2x mt-2x main">
      <!--订单信息-->
      <template>
        <div class="flex-between align-center">
          <h2 class="title-mid">订单信息</h2>
          <!--1、只有订单状态为“已支付”-->
          <!--2、业务类型为“会员特权”、“补办”、“自定义收款”的订单不显示退单按钮-->
          <a-button
            type="primary"
            @click="refund(false)"
            v-if="
              detail.orderStatus === 3 &&
                detail.orderBusinessType !== 5 &&
                detail.orderBusinessType !== 7
            "
            >退单</a-button
          >
        </div>
        <a-row class="detail">
          <a-col :span="8">
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总订单号</li>
              <li :span="14">{{ detail.id }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">下单时间</li>
              <li :span="14">
                {{ detail.createTime | date("yyyy-mm-dd hh:mm:ss") }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">支付方式</li>
              <li :span="14">{{ detail.payWayText }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">订单备注</li>
              <li :span="14">{{ detail.remarks }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">会员姓名</li>
              <li :span="14">
                {{
                  detail.orderMemberInfoVO ? detail.orderMemberInfoVO.name : ""
                }}
              </li>
            </ul>
            <!-- <ul class="mb-2x flex-start">
            <li :span="6" class="gray">销售渠道</li>
            <li :span="14">{{ detail.buyChannelsText }}</li>
          </ul>
          <ul class="mb-2x flex-start">
            <li :span="6" class="gray">订单状态</li>
            <li :span="14" class="primary">{{ detail.orderStatusText }}</li>
          </ul>
          <ul class="mb-2x flex-start">
            <li :span="6" class="gray">收款备注</li>
            <li :span="14">{{ detail.remarks }}</li>
          </ul> -->
          </a-col>
          <a-col :span="8">
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">外部单号</li>
              <li :span="14">{{ detail.outsideCode }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">销售渠道</li>
              <li :span="14">{{ detail.buyChannelsText }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">支付渠道</li>
              <li :span="14">{{ detail.payChannels }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">订单状态</li>
              <li :span="14">{{ detail.orderStatusText }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">会员编号</li>
              <li :span="14">
                {{
                  detail.orderMemberInfoVO ? detail.orderMemberInfoVO.id : ""
                }}
              </li>
            </ul>
          </a-col>
          <a-col :span="8">
            <!--//检测产品列表是否包含门票、综合套票，场次票等产品类型，如果有则存在取票号-->
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">订单类型</li>
              <li :span="14">{{ detail.orderBusinessTypeText }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">销售人员</li>
              <li :span="14">{{ detail.salespersonName }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">支付金额</li>
              <li :span="14">{{ detail.totalReceiveAmount | money }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">所属商户</li>
              <li :span="14">{{ detail.orderSaleMerchantName }}</li>
            </ul>
          </a-col>
        </a-row>
      </template>
      <!--团体信息-->
      <template v-if="detail.orderBusinessType == 10">
        <h2 class="title-mid">团体信息</h2>
        <a-table
          :columns="orderGroupColumns"
          :data-source="[detail.orderGroupBuyInfoVO]"
          :scroll="{ x: true }"
          rowKey="id"
          :pagination="false"
        >
        </a-table>
      </template>
      <!--导游信息-->
      <template v-if="detail.orderBusinessType == 10">
        <h2 class="title-mid">导游信息</h2>
        <a-table
          :columns="guideInfoColumns"
          :data-source="detail.guideInfoList"
          :scroll="{ x: true }"
          rowKey="id"
          :pagination="false"
        >
        </a-table>
      </template>
      <!--产品信息-->
      <template>
        <h2 class="title-mid">产品信息</h2>
        <a-table
          :columns="productColumns"
          :data-source="orderSubInfoVOList"
          :scroll="{ x: true }"
          rowKey="id"
          :pagination="false"
        >
          <!-- <span class="primary" slot-scope="row" slot="orderStatusText">{{
            row.orderStatusText
          }}</span> -->
          <span slot-scope="row" slot="totalAmount">{{
            row.totalAmount || 0 | money
          }}</span>
          <span slot-scope="row" slot="receivedAmount">{{
            row.receivedAmount | money
          }}</span>
          <span slot-scope="row" slot="discountAmount">{{
            row.discountAmount | money
          }}</span>
          <span slot-scope="row" slot="totalRefundAmount">{{
            row.totalRefundAmount | money
          }}</span>
          <span slot-scope="row" slot="leftAmount">{{
            row.leftAmount | money
          }}</span>
          <template slot="action" slot-scope="row" v-if="row.id !== '合计'">
            <a-button
              v-if="row.orderBusinessTypeText != '自定义收款'"
              type="link"
              @click="refund(row, 1)"
              :disabled="row.orderStatus < 3 || row.orderStatus > 5"
              >退款
            </a-button>
            <!-- <a-button type="link" @click="linkProductDetail(row)"
              >详情</a-button
            > -->
          </template>
        </a-table>
      </template>
      <!--游客信息-->
      <template>
        <h2 class="title-mid">游客信息</h2>
        <a-table
          :columns="touristColumns"
          :data-source="detail.customerInfoVOs"
          :scroll="{ x: true }"
          rowKey="id"
          :pagination="false"
        >
          <span slot-scope="row" slot="idCard">{{
            row.idCard || row.cardNumber
          }}</span>
          <span slot-scope="row" slot="price">{{ row.price | money }}</span>
          <span slot-scope="row" slot="birthday">{{
            row.birthday | date
          }}</span>
        </a-table>
      </template>
      <!--门票信息-->
      <template>
        <h2 class="title-mid">门票信息</h2>
        <a-table
          :columns="ticketColumns"
          :data-source="detail.orderTicketInfoVOs"
          :scroll="{ x: true }"
          rowKey="id"
          :pagination="false"
        >
          <span slot-scope="row" slot="useDate">{{ row.useDate | date }}</span>
          <span slot="beginValidTime">-</span>
          <span
            :style="{ width: '200px' }"
            slot-scope="row"
            slot="appointmentPeriodStartTime"
            >{{ row.appointmentPeriodStartTime | date("hh:mm") }} -
            {{ row.appointmentPeriodEndTime | date("hh:mm") }}</span
          >
          <span class="primary" slot-scope="row" slot="statusText">{{
            row.statusText
          }}</span>
          <span slot-scope="row" slot="enterTimes">{{
            row.enterTimesType == 0 ? "不限" : row.enterTimes
          }}</span>
          <span slot-scope="row" slot="dailyEnterTimesType">{{
            row.dailyEnterTimesType == 0 ? "不限" : row.dailyEnterTimes
          }}</span>
          <span slot-scope="row" slot="validMonthsType">{{
            row.validMonthsType == 0 ? "不限" : row.validMonths
          }}</span>
          <span slot-scope="row" slot="validWeekType">{{
            row.validWeekType == 0 ? "不限" : row.validWeeks
          }}</span>
          <span slot-scope="row" slot="useTimeType">{{
            row.useTimeType == 0 ? "不限" : row.useTimeRange | date
          }}</span>
          <span slot-scope="row" slot="firstWriteOffTime">{{
            row.firstWriteOffTime | date
          }}</span>
          <span slot-scope="row" slot="afterWriteOffValidTime">{{
            row.afterWriteOffValidTime | date
          }}</span>
          <template slot="action" slot-scope="row" v-if="row.id !== '合计'">
            <a-button
              style="padding: 0"
              v-if="row.orderBusinessTypeText != '自定义收款'"
              type="link"
              @click="refund(row, 2)"
              :disabled="row.status < 3 || row.status > 5"
              >退款
            </a-button>
            <a-button type="link" @click="linkMore(row)">更多</a-button>
            <!--订单状态3已支付，9部分退款，12退款失败 ，13 挂账状态下=》门票3已支付，5未使用，15退款失败下才允许补打-->
            <a-button
              style="padding: 0"
              type="link"
              @click="reprint(row.auxiliaryId)"
              :disabled="reprintFn(row)"
              >补打
            </a-button>
          </template>
        </a-table>
      </template>
      <!--支付信息-->
      <!-- <template>
        <h2 class="title-mid">支付信息</h2>
        <ul class="detail">
          <li class="flex-start mt-2x">
            <p class="gray">产品总额：</p>
            <p>{{ detail.totalAmount | money }}</p>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">优惠金额：</p>
            <div>
              <span>{{ detail.merchantDiscountAmount | money }}</span>
              <a-popover title="优惠信息" trigger="click">
                <template slot="content">
                  <p>优惠类型：{{ discountInfo.discountTypeName }}</p>
                  <p class="mt-2x">活动名称：{{ discountInfo.activityName }}</p>
                  <p class="mt-2x">券码：{{ discountInfo.couponCode }}</p>
                  <p class="mt-2x">
                    优惠金额：{{ detail.discountAmount | money }}
                  </p>
                </template>
                <span class="ml-2x blue pointer">详情</span>
              </a-popover>
            </div>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">会员余额：</p>
            <p>{{ detail.memberBalanceAmount | money }}</p>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">实付金额：</p>
            <p>{{ detail.settlementPrice | money }}</p>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">支付方式：</p>
            <p>{{ detail.payWayText }}</p>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">支付通道：</p>
            <p>{{ detail.payChannels }}</p>
          </li>
          <li class="flex-start mt-2x">
            <p class="gray">外部单号：</p>
            <p>{{ detail.outsideCode }}</p>
          </li>
        </ul>
      </template> -->
      <!--退款信息-->
      <template>
        <h2 class="title-mid">退款记录</h2>
        <a-table
          :columns="refundColumns"
          :data-source="refundList"
          rowKey="id"
          :pagination="false"
        >
          <span slot-scope="row" slot="createTime">{{
            row.createTime | date("yyyy-mm-dd hh:mm:ss")
          }}</span>
          <span slot-scope="row" slot="refundStatusText">{{
            row.refundStatusText
          }}</span>
          <span slot-scope="row" slot="originalReceiveAmount">{{
            row.originalReceiveAmount | money
          }}</span>
          <span slot-scope="row" slot="refundAmount">{{
            row.refundAmount | money
          }}</span>
          <span slot-scope="row" slot="refundMemberBalanceAmount">{{
            row.refundMemberBalanceAmount | money
          }}</span>
          <span slot-scope="row" slot="refundIntegralDeductionAmount">{{
            row.refundIntegralDeductionAmount | money
          }}</span>
          <span slot-scope="row" slot="refundCashAmount">{{
            row.refundCashAmount | money
          }}</span>
          <template
            slot="action"
            slot-scope="row"
            v-if="row.refundStatusText !== '合计'"
          >
            <a-button type="link" @click="goRefundDetail(row)">详情</a-button>
          </template>
        </a-table>
      </template>
    </section>

    <!--退款、退单-->
    <order-refund
      :visible.sync="visible"
      :order-id="orderId"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import OrderRefund from "./components/OrderRefund";
import { findOrderDetailInfo, findOrderRefundRecord } from "../../../api/order";
import { orderReprintSingleTicket } from "@/api/search";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
// 实付金额/退款状态
export default {
  name: "OrderDetail",
  components: { PanelHead, OrderRefund },
  data() {
    return {
      windowType: this.$store.state.common.windowType, // 判断是哪个窗口
      orderId: this.$route.query.orderId,
      detail: {}, // 订单详情， 深层对象已拆分到下面字段
      receiverFieldInfo: [], // 取票人详细信息
      taker: "", // 取票人
      orderMemberInfoVO: {}, // 购票会员信息
      orderMarketingInfoVO: {}, // 营销员信息
      discountInfo: {}, // 优惠详细信息
      orderSubInfoVOList: [], // 销售产品列表
      orderSpecialInfoVO: {}, // 其它特殊信息

      visible: false, // 退款退单
      // 退款信息
      refundList: [], // 退款记录
      refundIdType: "", // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: "", // 退款类型 1退单， 2退款， 3退押金
      productName: "", // 退款产品名称
      productCategoryId: "", // 产品业务类型id
      //团体信息
      orderGroupColumns: [
        {
          title: "团体人数",
          dataIndex: "buyNum",
          key: "buyNum",
          width: 180
        },
        {
          title: "团体类型",
          dataIndex: "guidePriceTypeName",
          key: "guidePriceTypeName",
          width: 180
        },
        {
          title: "团体优惠",
          dataIndex: "",
          key: "",
          width: 180
        }
      ],
      //导游信息
      guideInfoColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: 120
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone",
          width: 120
        },
        {
          title: "身份证",
          dataIndex: "certificateValue",
          key: "certificateValue",
          width: 130
        },
        {
          title: "导游证件",
          dataIndex: "guideLicenseId",
          key: "guideLicenseId",
          width: 130
        },
        {
          title: "导游性别",
          dataIndex: "sex",
          key: "sex",
          width: 100
        },
        {
          title: "导游国别",
          dataIndex: "country",
          key: "country",
          width: 100
        }
      ],
      // 产品table
      productColumns: [
        {
          title: "订单号",
          dataIndex: "id",
          key: "id",
          width: 180
        },
        {
          title: "产品编号",
          dataIndex: "productId",
          key: "productId",
          width: 180
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 180
        },
        {
          title: "产品规格",
          dataIndex: "specs",
          key: "specs",
          width: 140
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryText",
          key: "productCategoryText",
          width: 120
        },
        {
          title: "产品数量",
          dataIndex: "buyNum",
          key: "buyNum",
          width: 100
        },
        {
          title: "应收金额",
          scopedSlots: { customRender: "totalAmount" },
          key: "totalAmount",
          width: 120
        },
        {
          title: "实收金额",
          scopedSlots: { customRender: "receivedAmount" },
          key: "receivedAmount",
          width: 120
        },
        {
          title: "优惠金额",
          scopedSlots: { customRender: "discountAmount" },
          key: "discountAmount",
          width: 120
        },
        {
          title: "积分抵扣",
          dataIndex: "",
          key: "",
          width: 100
        },
        {
          title: "已退数量",
          dataIndex: "totalRefundNum",
          key: "totalRefundNum",
          width: 100
        },
        {
          title: "已退金额",
          scopedSlots: { customRender: "totalRefundAmount" },
          key: "totalRefundAmount",
          width: 120
        },
        {
          title: "未退金额",
          scopedSlots: { customRender: "leftAmount" },
          key: "leftAmount",
          width: 120
        },
        {
          title: "订单状态",
          scopedSlots: { customRender: "orderStatusText" },
          key: "orderStatusText",
          width: 100
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "",
          width: 180,
          fixed: "right"
        }
      ],
      // 游客信息table
      touristColumns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
          width: 130
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          key: "phone",
          width: 130
        },
        {
          title: "证件号",
          scopedSlots: { customRender: "idCard" },
          width: 140
        },
        {
          title: "游客类型",
          dataIndex: "visitorTypeName",
          key: "visitorTypeName",
          width: 140
        },
        {
          title: "匹配产品",
          dataIndex: "productName",
          key: "productName",
          width: 140
        },
        {
          title: "价格",
          scopedSlots: { customRender: "price" },
          key: "price",
          width: 100
        },
        {
          title: "国家",
          key: "",
          width: 120
        },
        {
          title: "出生年月",
          scopedSlots: { customRender: "birthday" },
          key: "birthday",
          width: 120
        }
      ],
      // 门票table
      ticketColumns: [
        {
          title: "票号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId",
          width: 130
        },
        {
          title: "取票号",
          dataIndex: "getTicketNo",
          key: "getTicketNo",
          width: 130
        },
        {
          title: "游客姓名",
          dataIndex: "visitorName",
          key: "visitorName",
          width: 130
        },
        {
          title: "游客身份证",
          dataIndex: "visitorIdCard",
          key: "visitorIdCard",
          width: 130
        },
        {
          title: "使用日期",
          scopedSlots: { customRender: "useDate" },
          key: "useDate",
          width: 130
        },
        {
          title: "使用时段",
          scopedSlots: { customRender: "appointmentPeriodStartTime" },
          key: "appointmentPeriodStartTime",
          width: 200
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 130
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryName",
          key: "productCategoryName",
          width: 120
        },
        {
          title: "产品分类",
          dataIndex: "productTypeName",
          key: "productTypeName",
          width: 130
        },
        {
          title: "门票状态",
          dataIndex: "statusText",
          key: "statusText",
          width: 110
        },
        {
          title: "销售分销渠道",
          dataIndex: "buyChannelName",
          key: "buyChannelName",
          width: 120
        },
        {
          title: "产品所属商户",
          dataIndex: "productOwnerMerchantName",
          key: "productOwnerMerchantName",
          width: 140
        },
        {
          title: "订单编号",
          dataIndex: "orderId",
          key: "orderId",
          width: 180
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "",
          width: 160,
          fixed: "right"
        }
      ],
      // 退款记录
      refundColumns: [
        {
          title: "退款单号",
          key: "refundId",
          dataIndex: "refundId"
        },
        {
          title: "退款时间",
          key: "createTime",
          scopedSlots: { customRender: "createTime" }
        },
        {
          title: "退款状态",
          key: "refundStatusText",
          scopedSlots: { customRender: "refundStatusText" }
        },
        {
          title: "原支付总金额",
          key: "originalReceiveAmount",
          scopedSlots: { customRender: "originalReceiveAmount" }
        },
        {
          title: "本次退款金额",
          key: "refundAmount",
          scopedSlots: { customRender: "refundAmount" }
        },
        {
          title: "会员余额退款金额",
          key: "refundMemberBalanceAmount",
          scopedSlots: { customRender: "refundMemberBalanceAmount" }
        },
        {
          title: "积分抵扣退款金额",
          key: "refundIntegralDeductionAmount",
          scopedSlots: { customRender: "refundIntegralDeductionAmount" }
        },
        {
          title: "实付退款金额",
          key: "refundCashAmount",
          scopedSlots: { customRender: "refundCashAmount" }
        },
        {
          title: "退款操作人",
          key: "refundAuditPersonName",
          dataIndex: "refundAuditPersonName"
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ]
    };
  },
  created() {
    this.loadDetail();
    // console.log(this.windowType);
    // console.log(this.$store.state.common.windowType);
  },
  computed: {
    //检测产品列表是否包含门票、综合套票，场次票等，如果有则存在取票号
    isAuxiliaryId() {
      return this.orderSubInfoVOList.some(item =>
        [
          // 游小铺产品分类
          "1365211685743095809",
          // 畅游宝产品分类
          "1365211690130337793",
          "1365211690470076417",
          "1355221689463443731"
        ].includes(item.productCategoryId)
      );
    }
  },
  methods: {
    reprintFn(row) {
      let detail = this.detail;
      return (
        (detail.orderStatus !== 3 &&
          detail.orderStatus !== 9 &&
          detail.orderStatus !== 12 &&
          detail.orderStatus !== 13) ||
        (row.status !== 3 && row.status !== 5 && row.status !== 15)
      );
    },
    reprint(auxiliaryId) {
      this.$confirm({
        title: "提示",
        content: "确定补打该门票？",
        onOk: () => {
          orderReprintSingleTicket({
            auxiliaryId
          }).then(res => {
            // 打印门票
            if (res) {
              HardwareService.printTicket(JSON.stringify(res));
            }
          });
        },
        onCancel() {}
      });
    },
    // 加载订单详情
    loadDetail() {
      findOrderDetailInfo({
        id: this.orderId
      })
        .then(res => {
          this.detail = res;
          this.receiverFieldInfo = res.receiverFieldInfo || [];
          let taker = this.receiverFieldInfo
            .filter(item => item.fieldType === 4 || item.fieldType === 5)
            .map(temp => temp.fieldValue);
          this.taker = taker.join("/");
          this.orderMemberInfoVO = res.orderMemberInfoVO || {};
          this.orderMarketingInfoVO = res.orderMarketingInfoVO || {};
          this.discountInfo = res.discountInfo || {};
          this.orderSubInfoVOList = res.orderSubInfoVOList || [];
          this.orderSpecialInfoVO = res.orderSpecialInfoVO || {};
          // 合计数据
          let obj = {
            id: "合计",
            buyNum: 0,
            totalAmount: 0,
            receivedAmount: 0,
            discountAmount: 0,
            totalRefundNum: 0,
            totalRefundAmount: 0,
            leftAmount: 0
          };
          this.orderSubInfoVOList.forEach(item => {
            obj.buyNum += item.buyNum || 0;
            obj.totalAmount += item.totalAmount;
            obj.receivedAmount += item.receivedAmount || 0;
            obj.discountAmount += item.discountAmount || 0;
            obj.totalRefundNum += item.totalRefundNum || 0;
            obj.totalRefundAmount += item.totalRefundAmount || 0;
            obj.leftAmount += item.leftAmount || 0;
          });
          this.orderSubInfoVOList.push(obj);
          this.loadRefund(res.id);
        })
        .catch(() => {});
    },
    // 加载退款记录
    loadRefund(id) {
      findOrderRefundRecord({
        orderId: id
      })
        .then(res => {
          // 处理合计
          const obj = {
            refundStatusText: "合计",
            originalReceiveAmount: 0,
            refundAmount: 0,
            refundMemberBalanceAmount: 0,
            refundIntegralDeductionAmount: 0,
            refundCashAmount: 0,
            id: "hj"
          };
          res.forEach(item => {
            obj.originalReceiveAmount += item.originalReceiveAmount;
            obj.refundAmount += item.refundAmount;
            obj.refundMemberBalanceAmount += item.refundMemberBalanceAmount;
            obj.refundIntegralDeductionAmount +=
              item.refundIntegralDeductionAmount;
            obj.refundCashAmount += item.refundCashAmount;
          });
          if (res.length) res.push(obj);
          this.refundList = JSON.parse(JSON.stringify(res));
        })
        .catch(() => {});
    },
    //退款记录跳退款详情
    goRefundDetail(row) {
      this.$router.push({
        path: "/home/order/RefundDetail",
        query: {
          id: row.id
        }
      });
    },
    //跳转产品详情
    linkProductDetail(row) {
      this.$router.push({
        path: "/home/order/ProductDetail",
        query: {
          id: row.id,
          productCategoryId: row.productCategoryId,
          orderStatus: row.orderStatus,
          orderId: this.orderId,
          orderBusinessType: row.orderBusinessType
        }
      });
    },
    // 门票信息- 更多按钮跳转到门票操作页面
    linkMore(row) {
      let path = "/home/ticket/TicketOperateDetail";
      let query = {
        id: row.id
      };
      if (row.productCategoryName == "组合产品") {
        path = "/home/ticket/combinationDetail";
        query.orderId = this.orderId;
      }
      this.$router.push({
        path,
        query
      });
    },
    // 退款
    refund(row, type) {
      // 退单
      this.refundType = 1; //  1
      this.refundIdType = "";
      this.productName = "";
      this.productCategoryId = "";
      this.refundIdList = [];
      // 退子产品
      if (row) {
        this.refundType = 2;
        this.productName = row.productName;
        this.productCategoryId = row.productCategoryId;
        this.refundIdType = type; //产品即子订单传1， 门票用2
        this.refundIdList = [row.id];
      }
      this.visible = true;
    },
    // 退款成功
    refunded() {
      this.loadDetail();
    },
    // 清账
    settleAccounts() {
      this.$router.push({
        path: "/newPay",
        query: {
          orderNumber: this.detail.id, // 订单id
          totalFee: this.detail.settlementPrice, // 总价
          ignorePay: "3" // 不显示哪种支付方式，逗号隔开（1扫码支付2线下支付3挂账支付4抵扣支付）
          // formatId: res.productCategoryId // 业态id
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.detail {
  .gray {
    width: 100px;
  }
}
</style>
