<template>
  <div class="wrapper flex-between flex-col">
    <!-- 头部 -->
    <panel-head>
      <p class="font-l mr-4x">退会员余额</p>
      <div class="flex-grow flex-start align-center pl-4x">
        <b class="mr-4x">{{ memberInfoOne.name }}</b>
        <div class="yellow mr-4x">
          <span class="iconfont icondengji"></span
          >{{ memberInfoOne.currentLevelName }}
        </div>
        <span class="mr-4x">手机号：{{ memberInfoOne.mobile }}</span>
        <span class="mr-4x"
          >余额：{{ memberInfoOne.buyBalance | money }}元</span
        >
      </div>
    </panel-head>
    <!-- 主体 -->
    <div class="main mt-2x">
      <div class="p-2x bd-b">会员可退款充值记录</div>
      <!--列表-->
      <a-table
        :scroll="{ x: 800, y: 1500 }"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        rowKey="id"
      >
        <template slot-scope="{ createTime }" slot="createTime">
          {{ createTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot-scope="{ payStatus }" slot="payStatus">
          {{ payStatus === 2 ? "充值成功" : "已退款" }}
        </template>
        <template slot-scope="{ changeNum }" slot="changeNum">
          {{ changeNum | money }}
        </template>
        <template slot-scope="{ paymentAmount }" slot="paymentAmount">
          {{ paymentAmount | money }}
        </template>
        <template slot="action" slot-scope="row">
          <!-- 禁用退单按钮： 旧的逻辑条件
           :disabled="
              row.orderStatus !== 3 ||
                row.orderBusinessType === 5 ||
                row.orderBusinessType === 7
            "-->
          <!--          新的逻辑条件，当充值状态为0,充值成功时，并且可退金额大于0方才可以点击退款, refundStatus为0不能退置灰 1可退-->
          <a-button
            type="link p-0"
            :disabled="
              row.payStatus !== 2 ||
                row.remainingSum <= 0 ||
                row.refundStatus == 0
            "
            @click="refund(row)"
          >
            退款
          </a-button>
        </template>
      </a-table>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        type="primary"
        shape="round"
        class="btn-lg"
        size="large"
        ghost
        @click="$router.go(-1)"
        >返回</a-button
      >
    </div>
    <!--    &lt;!&ndash; 退款、退单 &ndash;&gt;-->
    <!--    <order-refund-->
    <!--      :visible.sync="visible"-->
    <!--      :order-id="orderId"-->
    <!--      @on-ok="refunded"-->
    <!--    ></order-refund>-->
    <a-modal
      @cancel="refundPop = false"
      title="退款"
      :visible="refundPop"
      @ok="refundSuccess"
      width="600px"
    >
      <div class="flex-start">
        <p class="w-90">订单号：</p>
        <p>{{ detailItemPop.totalOrderId }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">资产名称：</p>
        <p>{{ detailItemPop.productName }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">会员余额：</p>
        <p>{{ detailItemPop.remainingSum | money("") }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">退款数额：</p>
        <p>{{ detailItemPop.changeNum | money("") }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">现金价值：</p>
        <p>{{ detailItemPop.paymentAmount | money }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">折算后应退金额：</p>
        <p>{{ detailItemPop.paymentAmount | money }}</p>
      </div>
      <div class="flex-start mt-1x">
        <p class="w-90">退款备注：</p>
        <p class="flex-1">
          <a-input
            v-model="remark"
            placeholder="请输入备注"
            type="textarea"
          ></a-input>
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import { mapGetters } from "vuex";
import { memberInfoOne } from "@/api/member"; // MS-会员中心接口
import {
  findMemberAssetsInfo,
  memberAssetsInfo,
  memberRefundBalance
} from "@/api/member"; // MS-会员中心接口

const columns = [
  {
    title: "充值时间",
    key: "createTime",
    width: 180,
    fixed: "left",
    scopedSlots: { customRender: "createTime" }
  },
  {
    title: "充值单号",
    key: "totalOrderId",
    width: 180,
    dataIndex: "totalOrderId"
  },
  {
    title: "充值状态",
    key: "payStatus",
    width: 100,
    scopedSlots: { customRender: "payStatus" }
  },
  {
    title: "支付方式",
    key: "payWayName",
    width: 120,
    dataIndex: "payWayName"
  },
  {
    title: "资产方式",
    key: "productName",
    width: 120,
    dataIndex: "productName"
  },
  {
    title: "充值数额",
    key: "changeNum",
    width: 120,
    scopedSlots: { customRender: "changeNum" }
  },
  {
    title: "现金价值",
    key: "paymentAmount",
    width: 120,
    scopedSlots: { customRender: "paymentAmount" }
  },
  {
    title: "购买者会员id",
    key: "helpMemberId",
    width: 200,
    dataIndex: "helpMemberId"
  },
  {
    title: "购买者手机号",
    key: "mobile",
    width: 120,
    dataIndex: "mobile"
  },
  {
    title: "操作",
    key: "action",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "MemberRefundBalanceList",
  components: { PanelHead },
  data() {
    // 自定义校验设置支付密码
    let checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入支付密码"));
      } else if (!/^[0-9]{6}$/.test(value)) {
        callback(new Error("请输入6位纯数字支付密码"));
      } else {
        callback();
      }
    };

    return {
      memberInfoOne: {}, // 会员详情
      columns, // 会员退款余额表头
      tableData: [], // 会员退款余额列表
      /** 退款 begin */
      refundPop: false, // 退款退单
      detailItem: {}, // 点击当前产品对象
      detailItemPop: {}, // 退款弹框详情
      remark: "", // 备注
      // orderId: "", // 订单id
      /** 退款 end */
      // 表单
      passwordForm: {
        password: "" // 支付密码
      },
      // 表单验证规则
      rulesPasswordForm: {
        password: [
          { required: true, validator: checkPassword, trigger: "blur" }
        ]
      }
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getMemberInfoOne(); // 根据会员id查询会员详情
    this.getRefundbalanceList(); // 获取会员退款余额列表
  },
  methods: {
    // 根据会员id查询会员详情
    getMemberInfoOne() {
      memberInfoOne({ id: this.memberId })
        .then(res => {
          this.memberInfoOne = res;
          this.updateMember(this.memberInfoOne);
        })
        .catch(() => {});
    },

    // 获取会员退款余额列表
    getRefundbalanceList() {
      findMemberAssetsInfo({
        memberId: this.memberId
      })
        .then(res => {
          this.tableData = res;
        })
        .catch(() => {});
    },
    refundSuccess() {
      let row = this.detailItem;
      memberAssetsInfo({
        memberBusinessType: 3, // 会员退余额类型 3
        buyChannels: 6, // 会员退余额类型 6
        tradingType: 2, // 退余额/减少 2
        memberAssetsInfoId: row.id, // 流水表id
        originOrderId: row.totalOrderId, // 原订单号id
        merchantId: row.merchantId, // 景区id
        memberId: row.memberId, // 会员id
        remark: this.remark
      }).then(() => {
        this.refundPop = false;
        // 退款成功
        this.getRefundbalanceList();
        this.getMemberInfoOne(); // 根据会员id查询会员详情
      });
    },
    // 退款
    refund(row) {
      this.detailItem = row;
      memberRefundBalance({
        merchantId: row.merchantId,
        memberId: row.memberId,
        memberAssetsInfoId: row.id
      }).then(res => {
        this.remark = "";
        this.refundPop = true;
        this.detailItemPop = res;
      });
    }
  }
};
</script>

<style lang="less" scoped>
.w-90 {
  width: 120px;
}
</style>
