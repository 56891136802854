<template>
  <div class="wrapper flex-between flex-col">
    <div class="main">
      <div class="flex-center container">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link pr-2x" @click="e => e.preventDefault()">
            <span class="font-xxl bold dark pr-1x mb-2x">
              {{ subScenic.subScenicName }}</span
            >
            <span class="font-xl"><a-icon type="swap" />切换</span>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in subScenicsList" :key="item.subScenicId">
              <a
                href="javascript:;"
                @click="setDefault(item)"
                class="flex-between"
              >
                <span class="bold">{{ item.subScenicName }}</span>
                <span
                  @click="setDefault(item)"
                  class="pl-1x"
                  style="float:right"
                  >设为默认</span
                ></a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div class="flex-center flex-1" style="padding-right: 150px;">
          <a-radio-group
            :default-value="orderType"
            v-model="orderType"
            button-style="solid"
            @change="orderTypeChange"
            size="large"
          >
            <a-radio-button value="3">
              个人快速下单
            </a-radio-button>
            <a-radio-button value="4">
              团体快速下单
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <section class="flex-grow container">
        <div class="flex-start flex-wrap align-center" style="line-height: 2.5">
          <!-- <h3 class="title-mid" style="margin: 20px 50px 20px 0;">团体分类</h3> -->
          <!-- 搜索分销商 -->
          <div v-if="orderType == 4">
            <!-- 团体分类 -->
            <span class="mh-1x font-xl"
              ><span class="red">*</span>团体分类
            </span>
            <a-select
              placeholder="选择团体分类"
              show-search
              @change="changeGroupType"
              size="large"
              class="form-item-width"
            >
              <a-select-option
                v-for="item in groupTypes"
                :key="item.id"
                :value="item.id"
              >
                {{ item.touristCategoryName }}
              </a-select-option>
            </a-select>
            <a-select
              v-model="groupType.selectDistributor"
              placeholder="分销商匹配团体分类"
              show-search
              option-filter-prop="children"
              :filter-option="searchDistributor"
              @change="handleChangeDistributor"
              size="large"
              class="ml-1x form-item-width"
            >
              <a-icon slot="suffixIcon" type="search" />
              <a-select-option
                v-for="item in managementList"
                :key="item.distributorId"
                :value="item.distributorId"
              >
                {{ item.distributorName }}
              </a-select-option>
            </a-select>
            <a-select
              placeholder="导游票种"
              show-search
              @change="changeGroupProduct"
              size="large"
              class="ml-1x form-item-width"
            >
              <a-select-option
                v-for="item in subscenicProductList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <span class="mh-2x font-xl">使用日期</span>
            <a-date-picker
              v-model="playDate"
              :disabled-date="disabledDate"
              @change="changePlayDate"
              size="large"
              placeholder="选择游玩日期"
              class="form-item-width"
            />
            <span class="mh-2x font-xl">使用时段</span>
            <a-select
              v-if="playTimes.length > 0"
              v-model="playTime"
              @change="selectPlayTime"
              placeholder="选择游玩时段"
              size="large"
              class="form-item-width"
            >
              <a-select-option
                v-for="item in playTimes"
                :key="item.id"
                :value="item.time"
              >
                {{ item.time }}
              </a-select-option>
            </a-select>
            <span v-else>
              <a-time-picker format="HH:mm" @change="changeStartTime" /> -
              <a-time-picker format="HH:mm" @change="changeEndTime" />
            </span>
            <span v-if="nowPlayTimeInfo.id" class="ml-1x"
              >剩余库存：{{ nowPlayTimeInfo.remainingStock }}</span
            >
          </div>
        </div>
        <!-- 团体下班 -->
        <div v-if="orderType == 4">
          <!-- 导游信息 -->
          <div class="flex-between mt-2x">
            <h3 class="title-mid" style="margin: 10px 0 16px 0">导游信息</h3>
            <div>
              <a-button
                v-permission="'homeQuickPlaceOrderPlaceOrderTeamAdd'"
                size="large"
                type="primary"
                ghost
                @click="toaddGuideShow"
                >添加导游</a-button
              >
              <a-button
                v-permission="'homeQuickPlaceOrderPlaceOrderTeamselect'"
                type="primary"
                plain
                icon="a-icon-plus"
                size="large"
                @click="selectGuide(0)"
                class="ml-1x"
                >选择导游</a-button
              >
            </div>
          </div>
          <a-table
            size="middle"
            :columns="guideCheckColumns"
            :data-source="guideCheckData"
            row-key="id"
          >
            <template slot="name" slot-scope="scope">{{ scope.name }}</template>
            <template slot="phone" slot-scope="scope">{{
              scope.phone
            }}</template>
            <template slot="certificateValue" slot-scope="scope">{{
              scope.certificateValue
            }}</template>
            <template slot="guideLicense" slot-scope="scope">
              {{
                scope.guideLicense
                  ? JSON.parse(scope.guideLicense).guideLicenseId
                  : ""
              }}</template
            >
            <!-- //性别 0:未知 1:女 2:男 -->
            <template slot="sex" slot-scope="scope">{{
              scope.sex == 1 ? "女" : scope.sex == 2 ? "男" : ""
            }}</template>
            <template slot="country" slot-scope="scope">{{
              scope.country
            }}</template>
            <template slot="action" slot-scope="scope">
              <a-button @click="delGuide(scope)" type="link" class="blue"
                >删除</a-button
              >
            </template>
          </a-table>
        </div>
        <!-- 游客信息 -->
        <tourist
          ref="myTourist"
          :subScenic="subScenic"
          :touristType="touristType"
          :subscenicProductList="subscenicProductList"
          :groupType="groupType"
          :guideCheckData="guideCheckData"
          @changeTouristType="changeTouristType"
          @changeOrderInfo="changeOrderInfo"
          @changeTouristSumData="changeTouristSumData"
        ></tourist>
        <h3 class="title-mid mv-1x mt-3x">订单信息</h3>
        <div style="height: 110px;" class="bd round p-2x font-xl">
          <!-- 个人下单 -->
          <a-row type="flex" v-if="orderType == 3">
            <a-col class="flex-start">
              <span>游客：</span>
              <div class="red">{{ orderInfo.sumNum || 0 }}人</div>
            </a-col>
            <!-- <a-col class="flex-start ml-1x" v-if="orderInfo.folds">
              <div>折扣：{{ orderInfo.folds.length > 0 ? "" : "无" }}</div>
              <span
                class="red ml-1x"
                v-for="item in orderInfo.folds"
                :key="item.index"
                >{{ item.foldName }} {{ item.fold }}折
              </span>
            </a-col>
            <a-col class="flex-start ml-2x" v-if="orderInfo.free">
              <span class="red ml-1x">{{ orderInfo.free }} </span>
              <div>人免票</div>
            </a-col> -->
          </a-row>
          <!-- 团体下单 -->
          <a-row v-if="orderType == 4">
            <a-col class="flex-start">
              <span>{{ groupType.selectGroupName }} </span>
              <!-- <div class="red" v-if="orderInfo.discount">{{ orderInfo.sumGuide }}折扣优惠</div> -->
            </a-col>
            <a-col class="flex-start">
              <span> 导游：</span>
              <!-- 导游票价 1全免 2免一人 3全价 -->
              <div class="red">
                {{ guideCheckData.length }}
              </div>
              人
              <span v-if="orderInfo.guideFare == 4" class="ml-1x red"
                >{{ `每${groupType.numberVisitors}个游客免一个导游` }}
                <span v-if="groupType.isCapped == 1">
                  封顶 {{ groupType.freePeople }}人</span
                >
                <span v-else> 不封顶</span>
              </span>
              <span v-if="orderInfo.guideFare == 5" class="ml-1x red"
                >{{
                  `${groupType.numberVisitors ||
                    0}个游客内免${groupType.freePeople ||
                    0}导游，超出免${groupType.beyondExemption || 0}人`
                }}
              </span>
              <span class="ml-1x">{{
                orderInfo.guideFare == 1
                  ? "全免"
                  : orderInfo.guideFare == 2
                  ? "免一人"
                  : orderInfo.guideFare == 3
                  ? "全价"
                  : ""
              }}</span>
              <span class="ml-1x red mr-1x">{{
                orderInfo.guideSumPice || 0 | money
              }}</span>
              <span class="flex-start ml-3x">
                <span>游客：</span>
                <div class="red">{{ orderInfo.sumNum || 0 }}</div>
                人
              </span>
              <div class="flex-start ml-1x" v-if="orderInfo.folds">
                <div>折扣：{{ orderInfo.folds.length > 0 ? "" : "无" }}</div>
                <span
                  class="red ml-1x"
                  v-for="item in orderInfo.folds"
                  :key="item.index"
                >
                  {{ item.foldName }}
                  <span style="text-decoration:underline"
                    >{{ item.fold || "" }}折</span
                  >
                </span>
              </div>
              <div class="flex-start ml-2x" v-if="orderInfo.free">
                <div class="red">{{ orderInfo.free }}</div>
                人免票
              </div>
            </a-col>
          </a-row>
          <div class="mt-3x flex-start">
            <span style="width:120px">订单总金额：</span>
            <div class="red">
              {{ orderInfo.actualSum || 0 | money }}
            </div>
          </div>
        </div>

        <!-- 选择导游 -->
        <a-modal
          title="选择导游"
          :visible="selectGuideShow"
          center
          width="500px"
          @ok="handleCancle"
          @cancel="handleCancle"
        >
          <div class="guide-box mt-2x">
            <a-row
              type="flex"
              v-for="item in guideDatas"
              :key="item.id"
              class="pv-2x bd-t"
            >
              <a-col :span="5">{{ item.name }}</a-col>
              <a-col :span="15">手机号：{{ item.phone }}</a-col>
              <a-col :span="4"
                ><a-checkbox
                  class="res"
                  :label="item.id"
                  v-model="item.check"
                  @change="checkGuide"
                >
                  选择
                </a-checkbox></a-col
              >
            </a-row>
          </div>
        </a-modal>
        <!-- 添加导游 -->
        <a-modal
          title="添加导游"
          :visible="addGuideShow"
          center
          @cancel="addGuideShow = false"
          :footer="null"
          :mask-closable="false"
        >
          <a-form-model
            ref="ruleForm"
            :model="ruleForm"
            :rules="guideFormRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            labelAlign="left"
          >
            <a-form-model-item label="导游姓名" prop="username">
              <a-input
                v-model="ruleForm.username"
                placeholder="请输入姓名"
                clearable
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="导游电话" prop="phone">
              <a-input
                v-model="ruleForm.phone"
                placeholder="请输入手机号码"
                clearable
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="导游性别" prop="sex">
              <a-select :default-value="ruleForm.sex" v-model="ruleForm.sex">
                <a-select-option
                  v-for="item in sexs"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="导游证类型" prop="guideLicenseType">
              <a-select
                :default-value="ruleForm.guideLicenseType"
                v-model="ruleForm.guideLicenseType"
              >
                <a-select-option
                  v-for="item in guideCartType"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="导游证号码" prop="guideLicenseId">
              <a-input
                v-model="ruleForm.guideLicenseId"
                placeholder="请输入导游证号"
                clearable
              ></a-input>
            </a-form-model-item>
            <a-form-model-item label="证件类型" prop="certificateType">
              <a-select
                :default-value="ruleForm.certificateType"
                v-model="ruleForm.certificateType"
              >
                <a-select-option
                  v-for="item in cartType"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="证件号" prop="certificateValue">
              <a-input
                v-model="ruleForm.certificateValue"
                placeholder="请输入证件号"
                clearable
              ></a-input>
            </a-form-model-item>
            <!-- <a-form-model-item label="国家" prop="country">
              <a-input
                v-model="ruleForm.country"
                placeholder="请输入国家"
                clearable
              ></a-input>
            </a-form-model-item> -->
            <!-- <a-form-model-item label="详细地址" prop="detailAddress">
              <a-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 3 }"
                v-model="ruleForm.detailAddress"
                placeholder="请输入详细地址"
                clearable
              ></a-input>
            </a-form-model-item> -->
            <div class="flex-center">
              <a-button type="primary mr-4x" size="large" @click="saveGuide">
                确定
              </a-button>
              <a-button size="large" @click="addGuideShow = false"
                >取消</a-button
              >
            </div>
          </a-form-model>
        </a-modal>
        <!-- 添加游客 -->
      </section>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        class="btn-qp mr-4x"
        size="large"
        shape="round"
        @click="$router.push('/home/ticket/GetTicket')"
      >
        取票
      </a-button>
      <a-button
        class="btn-lg"
        type="primary"
        shape="round"
        size="large"
        @click="submitOrder"
        >提交订单</a-button
      >
    </div>
  </div>
</template>

<script>
import { guideList, guide, findUserPageList, findUserInfo } from "@/api/user";
import {
  touristManagementList,
  selectTouristManagementInfo
} from "@/api/member";
import { merchantGroupList, subScenics } from "@/api/merchant.js";
import { subscenicProducts, appointmentStockDate } from "@/api/product.js";
import { fastOrder } from "@/api/cart.js";
import moment from "moment";
import tourist from "./componets/tourist.vue";
import { checkIdCard, checkPhone, checkText } from "@/utils/global";
export default {
  name: "PlaceOrder",
  components: { tourist },
  data() {
    return {
      subScenic: { subScenicId: "", subScenicName: "" }, //当前已选子景区
      subScenicId: "", //子景区id
      subScenicsList: [], //子景区列表
      orderType: "3", //下单类型 3个人快速下单 4团体快速下单
      moment,
      playDate: "", //游玩日期
      touristPlayDate: "", //游玩日期
      playTime: undefined,
      playTimes: [
        // "08:00-12:00",
        // "09:00-13:00",
        // "09:30-12:59",
        // "13:00-16:00",
        // "12:00-14:00"
      ], //游玩时段
      selectGuideShow: false, //导游信息框
      addGuideShow: false, //添加导游弹框
      ruleForm: {
        userId: "", //用户的id
        phone: "",
        username: "",
        sex: "", //性别 0:未知 1:女 2:男
        certificateType: 1, //证件类型，1身份证、2护照
        certificateValue: "", //证件的值
        guideLicenseType: "", //导游证类型，1国导证、2领队证
        guideLicenseId: "", //导游证号码
        guideLicensePic: "", //导游证图片
        country: "", //国家
        detailAddress: "" //详细地址
      }, //添加导游表单
      guideFormRules: {
        userId: [{ required: true, message: "请选择员工", trigger: "blur" }],
        username: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        idCard: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: checkIdCard, trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      },
      sexs: [
        { id: 0, value: "未知" },
        { id: 2, value: "男" },
        { id: 1, value: "女" }
      ],
      //导游证类型
      guideCartType: [
        {
          id: 1,
          value: "国导证"
        },
        {
          id: 2,
          value: "领队证"
        }
      ],
      //证件类型
      cartType: [
        {
          id: 1,
          value: "身份证"
        },
        {
          id: 2,
          value: "护照"
        }
      ],
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      selectTouristShow: false, //添加游客信息框
      searchGuideValue: "",
      guideCheckColumns: [
        {
          title: "导游姓名",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 170
        },
        {
          title: "导游电话",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          width: 170
        },
        {
          title: "身份证件",
          key: "certificateValue",
          scopedSlots: { customRender: "certificateValue" },
          width: 170
        },
        {
          title: "导游证件",
          key: "guideLicense",
          scopedSlots: { customRender: "guideLicense" },
          width: 170
        },
        {
          title: "导游性别",
          key: "sex",
          scopedSlots: { customRender: "sex" },
          width: 170
        },
        {
          title: "导游国别",
          key: "country",
          scopedSlots: { customRender: "country" },
          width: 170
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 160
        }
      ],
      groupType: {
        //团体分销商&团体分类
        //selectDistributor: "", //已选分销商id
        selectGroupId: undefined, //已选团体分类
        orderType: "",
        discountCategory: "",
        fold: "",
        selectGroupName: "", //已选团体分类名称
        touristDiscountStatus: 0, //团体-游客优惠 0无 1 开启 折中折
        guideProductId: undefined, //导游票id
        guideFare: "", //团体导游折扣类型 导游票价 1全免 2免一人 3全价
        guideProductPice: 0 //团体导游票种票单价
      },
      guideDatas: [], //导游可选数据
      guideCheckData: [], //导游已选数据
      managementList: [], //分销商数据列表
      searchManagementList: [], //搜索后分销商数据
      groupTypes: [], //团体分类数据
      searchGroupTypes: [], //搜索后团体分类数据
      touristType: [], //个人/团体所有分类
      subscenicProductList: [], //子景区产品列表
      orderInfo: {},
      selectionTouristData: [],
      userName: "",
      user: {},
      users: [],
      page: 1,
      size: 1000,
      nowPlayTimeInfo: {} //当前选择时段库存信息
    };
  },
  created() {
    this.subScenic = localStorage.getItem("placeSubScenic")
      ? JSON.parse(localStorage.getItem("placeSubScenic"))
      : {}; //默认子景区
    this.querySubScenics();
    this.querySubscenicProducts();
  },
  mounted() {
    this.queryManagementTypes();
    this.playDate = moment(moment().format("YYYY-MM-DD"));
    this.touristPlayDate = moment().format("YYYY-MM-DD");
    this.queryTouristType(); //获取游客分类
    // this.queryUsers();
  },
  methods: {
    //选择时段
    selectPlayTime(val) {
      this.nowPlayTimeInfo = this.playTimes.find(item => item.time == val);
      // console.log(this.nowPlayTimeInfo);
    },
    // 提交订单
    submitOrder() {
      // console.log(
      //   "团体分类：" + JSON.stringify(this.groupType),
      //   "已选导游：" + JSON.stringify(this.guideCheckData),
      //   "已添加游客信息数据 处理汇总后的:" + JSON.stringify(this.touristSumData)
      // );
      if (!this.playDate) {
        this.$message.warning("请选择使用日期！");
        return;
      }
      if (this.orderType == 4 && !this.groupType.selectGroupId) {
        this.$message.warning("请选择团体分类！");
        return;
      }
      if (!this.touristSumData || this.touristSumData.length < 1) {
        this.$message.warning("请添加游客！");
        return;
      }
      //游客数据
      let touristList = this.touristSumData.map(item => {
        return {
          name: item.name,
          phone: item.phone,
          idCard: item.idCard,
          visitorTypeId: item.touristCategoryId,
          productId: item.sybSystemIdProductId,
          buyNum: item.sumNum
        };
      });
      let playTimeStart = this.nowPlayTimeInfo.startPlayTime;
      let playTimeEnd = this.nowPlayTimeInfo.endPlayTime;
      if (this.playTimes.length > 0) {
        if (!this.playTime) {
          this.$message.warning("请选择时间段！");
          return;
        }
        //有景区时段
        playTimeStart = this.playTime.split("-")[0];
        playTimeEnd = this.playTime.split("-")[1];
      } else {
        if (!playTimeStart || !playTimeEnd) {
          this.$message.warning("请选择时间段！");
          return;
        }
      }
      this.playDate = moment(this.playDate).format("YYYY-MM-DD");
      let data = {
        subScenicId: this.subScenic.subScenicId,
        groupTypeId: this.groupType.selectGroupId, //团体分类的id
        guideIds: this.guideCheckData.map(item => {
          return item.id;
        }), //导游id 的数组
        guideProductId: this.groupType.guideProductId, //导游票种 团体默认的可以改
        playDate: this.playDate, //游玩日期
        // playTime: "14:30",
        playTimeStart: playTimeStart,
        playTimeEnd: playTimeEnd,
        timeShareAppointmentRuleId: this.nowPlayTimeInfo
          .timeShareAppointmentRuleId, //时段规则id
        timeIntervalId: this.nowPlayTimeInfo.id, //时段id
        productTravelerList: touristList
      };
      fastOrder(data).then(res => {
        // console.log(res);
        this.$store.dispatch("order/setCartList", { id: res.id });
        this.$router.push("/home/sell/OrderInfo");
      });
    },
    changeStartTime(val) {
      this.nowPlayTimeInfo.startPlayTime = moment(val).format("HH:mm");
    },
    changeEndTime(val) {
      this.nowPlayTimeInfo.endPlayTime = moment(val).format("HH:mm");
    },
    changePlayDate() {
      this.playDate = moment(this.playDate).format("YYYY-MM-DD");
      this.touristPlayDate = moment(this.playDate).format("YYYY-MM-DD");
      this.queryappointmentStockDate(this.playDate);
      this.queryTouristType(); //获取游客分类
    },
    //获取时段
    queryappointmentStockDate(time) {
      appointmentStockDate({
        subScenicId: this.subScenic.subScenicId,
        date: time
      }).then(res => {
        if (res.length > 0) {
          let arr = [];
          let nowtime = new Date().getTime();
          let time = "";
          res.forEach(item => {
            let obj = {
              id: item.id,
              timeShareAppointmentRuleId: item.ruleId,
              time: `${moment(item.beginTime).format("HH:mm")}-${moment(
                item.endTime
              ).format("HH:mm")}`,
              remainingStock: item.remainingStock
            };
            arr.push(obj);
            if (item.beginTime <= nowtime && nowtime <= item.endTime) {
              time = obj.time;
            }
          });
          this.playTimes = arr;
          if (arr.length > 0 && time) {
            this.selectPlayTime(time);
            this.playTime = time;
          }
        }
      });
    },

    //选择团体分类
    changeGroupType(val) {
      let touristType = this.groupTypes.find(item => item.id == val);
      this.groupType.selectGroupId = touristType.id;
      this.groupType.touristDiscountStatus = touristType.touristDiscountStatus; //游客优惠
      this.groupType.discountCategory = touristType.discountCategory; //折扣类型
      this.groupType.touristCategoryName = touristType.touristCategoryName; //折扣类型
      this.groupType.fold = touristType.fold;
      this.groupType.orderType = this.orderType;
      this.groupType.guideProductId = touristType.sybSystemIdProductId;
      if (this.groupType.guideProductId) {
        this.changeGroupProduct(this.groupType.guideProductId);
      }
      let automaticallyMatchProducts = JSON.parse(
        touristType.automaticallyMatchProducts
      );
      //导游票价
      //guideFare	1全免 2免一人 3全价 4限制
      // 游客数量	numberVisitors
      // 封顶设置	isCapped	1封顶免  2不封顶
      // 免几人	freePeople
      this.groupType.guideFare = automaticallyMatchProducts.guideFare;
      this.groupType.numberVisitors = automaticallyMatchProducts.numberVisitors;
      this.groupType.isCapped = automaticallyMatchProducts.isCapped;
      this.groupType.freePeople = automaticallyMatchProducts.freePeople;
      this.groupType.beyondExemption =
        automaticallyMatchProducts.beyondExemption;
      // let groupType = this.groupTypes.find(item => item.id == val);
      // this.groupType.selectDistributor = Number(groupType.id);
      // console.log(this.groupType);
    },
    //选择导游票种
    changeGroupProduct(val) {
      let selectProduct = this.subscenicProductList.find(
        item => item.id == val
      );
      this.groupType.guideProductPice = selectProduct.sellingPrice;
      this.groupType.guideProductId = selectProduct.id;
    },
    //切换个人/团体
    orderTypeChange() {
      this.queryTouristType(this.orderType); //获取游客分类
      this.$refs.myTourist.delAllTourist(this.orderType);
      this.groupType.touristDiscountStatus = 0;
      this.groupType.orderType = this.orderType;
    },
    //获取游客分类
    queryTouristType(chooseType = 3) {
      // chooseType	1.个人下单 2.团体下单 this.orderType_(新版个人下单3，团体下单4)
      selectTouristManagementInfo({ chooseType: chooseType }).then(res => {
        // 2022-09-01之后暂定不能购买中小学游客类型 53551
        if (this.touristPlayDate >= "2022-09-01") {
          res = res.filter(tem => tem.id !== 53551);
        }
        if (this.orderType == 3) {
          res.forEach(item => {
            let obj = JSON.parse(item.automaticallyMatchProducts);
            if (obj.choose == 1) {
              //有产品
              item.sybSystemIdProductId =
                obj.automaticallyMatchProductsList[0].productId;
            }
          });
          this.touristType = res;
        } else {
          this.groupTypes = res; //所有的 团体下单
        }
      });
    },
    //查询用户列表
    queryUsers() {
      findUserPageList({
        page: this.page,
        size: this.size,
        userName: this.userName
      }).then(res => {
        this.users = res.records;
        this.pages = res.pages;
      });
    },
    selectUser(userId) {
      // console.log(this.users);
      this.users.forEach(item => {
        if (item.userId == userId) {
          Object.assign(this.ruleForm, item);
        }
      });
    },
    queryUserInfo() {
      findUserInfo({ userId: this.ruleForm.userId }).then(res => {
        this.ruleForm.userName = res.userName;
        this.ruleForm.phone = res.phone;
        this.ruleForm.guideLicenseId = this.ruleForm.guideInfoDTO.guideLicenseId;
        this.ruleForm.guideLicensePic = this.ruleForm.guideInfoDTO.guideLicensePic;
      });
    },
    //游客分类
    changeTouristType(val) {
      this.touristType = val;
    },
    //订单信息
    changeOrderInfo(val) {
      this.orderInfo = val;
    },
    changeTouristSumData(val) {
      this.touristSumData = val;
    },

    //不能选过去的日期
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    //选择团体分类
    checkTouristCategory(item) {
      this.groupType.selectGroupId = item.id;
      this.groupType.selectGroupName = item.touristCategoryName;
    },

    //选择子景区
    checkSubScenics(subScenic) {
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
    },
    //设置为默认子景区
    setDefault(subScenic) {
      localStorage.setItem("placeSubScenic", JSON.stringify(subScenic));
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
    },
    //子景区列表
    querySubScenics() {
      subScenics({ filterSubScenic: true }).then(res => {
        this.subScenicsList = res.subScenics;
        let find = res.subScenics.find(
          item => item.subScenicId === this.subScenic.subScenicId
        );
        if (!find) {
          //子景区默认设置第一个
          this.subScenic = res.subScenics[0];
          localStorage.setItem(
            "placeSubScenic",
            JSON.stringify(res.subScenics[0])
          );
        }
        // console.log(this.subScenic.subScenicId);
        this.queryappointmentStockDate(
          moment(this.playDate).format("YYYY-MM-DD")
        ); //获取时段
      });
    },
    //子景区产品列表
    querySubscenicProducts() {
      subscenicProducts().then(res => {
        res.forEach(item => {
          if (item.subScenicId == this.subScenic.subScenicId) {
            //该子景区下的产品
            this.subscenicProductList = item.skuList;
          }
        });
      });
    },
    //选择分销商
    handleChangeDistributor(val) {
      touristManagementList({ distributorGroupId: val }).then(res => {
        this.groupTypes = res;
      });
    },

    //搜索分销商
    searchDistributor(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //取分销商list
    queryManagementTypes() {
      merchantGroupList().then(res => {
        this.managementList = res;
      });
    },
    toaddGuideShow() {
      this.addGuideShow = true;
    },
    //保存导游
    saveGuide() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          this.$message.warning("填写的信息校验不通过，请检查");
          return;
        }
        guide(this.ruleForm).then(res => {
          Object.keys(this.ruleForm).forEach(key => (this.ruleForm[key] = ""));
          this.ruleForm.certificateType = 1;
          this.addGuideShow = false;
          //保存成功
          this.selectGuide(res);
        });
      });
    },
    //选择导游
    selectGuide(guide) {
      guideList()
        .then(res => {
          let oldArr = this.guideDatas;
          this.guideDatas = res;
          if (guide && guide.id) {
            this.selectGuideShow = false;
            //新增后直接添加
            this.guideDatas.forEach(item => {
              if (item.id == guide.id) {
                item.check = true;
                this.checkGuide(oldArr);
              }
            });
          } else {
            this.selectGuideShow = true;
          }
        })
        .catch(() => {});
    },

    handleCancle() {
      this.selectGuideShow = false;
    },
    searchGuide(val) {
      this.guideDatas = this.guideDatas.filter(
        item => item.name.includes(val) || item.mobile.includes(val)
      );
    },
    checkGuide(oldArr) {
      if (oldArr.length > 0) {
        this.guideDatas.forEach(item => {
          oldArr.forEach(temp => {
            if (temp.check && temp.id == item.id) {
              item.check = temp.check;
            }
          });
        });
      }

      this.guideCheckData = this.guideDatas.filter(item => item.check);
    },
    //删除导游
    delGuide(row) {
      let index = this.guideCheckData.findIndex(item => item.id == row.id);
      this.guideDatas.map(item => {
        if (item.id === this.guideCheckData[index].id) {
          item.check = false; //更新选择状态
        }
      });
      this.guideCheckData.splice(index, 1);
    }
  }
};
</script>
<style lang="less" scoped>
.addTouristBtn {
  background-color: #30acf3;
  border-color: #30acf3;
}
.a-modal__body .main {
  width: 95%;
  margin: 0 auto;
}
.guide-box {
  height: 300px;
  overflow: auto;
}
.form-item-width {
  width: 170px;
}
.btn-qp {
  min-width: 170px;
}
</style>
