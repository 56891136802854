<!-- 门票改签 弹窗 -->

<template>
  <a-modal
    :visible="vis"
    title="延长使用期"
    width="780px"
    :body-style="{
      'max-height': '460px'
    }"
    @cancel="close"
    :mask-closable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 14 }"
      label-align="left"
      ref="refExtensionServiceTime"
    >
      <a-form-model-item label="门票名称">
        <span>{{ detail.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="门票使用有效期">
        <span class="mr-4x">
          {{ detail.beginValidTime | date }} ~
          {{ detail.endValidTime | date }}
        </span>
      </a-form-model-item>
      <a-form-model-item v-if="detail.firstWriteOffTime" label="首次核销时间">
        <span>{{
          detail.firstWriteOffTime | date("YYYY-MM-DD hh:mm:ss")
        }}</span>
      </a-form-model-item>
      <a-form-model-item
        v-if="detail.afterWriteOffValidTime"
        label="核销后到期日期"
      >
        <span>{{
          detail.afterWriteOffValidTime | date("YYYY-MM-DD hh:mm:ss")
        }}</span>
      </a-form-model-item>
      <a-form-model-item label="延长后有效期" prop="changeTime">
        <a-date-picker
          v-model="form.changeTime"
          placeholder="请选择日期"
          class="wid"
        />
      </a-form-model-item>
      <a-form-model-item label="延长说明" prop="changeExplain">
        <a-input
          class="wid"
          v-model="form.changeExplain"
          placeholder="请输入"
        />
      </a-form-model-item>
    </a-form-model>
    <div slot="footer">
      <a-button key="cancel" @click="close">取消</a-button>
      <a-button type="primary" key="refund" @click="confirm" :loading="loading"
        >确定修改</a-button
      >
    </div>
  </a-modal>
</template>
<script>
import { ticketChangingOperation } from "../../../../api/order";
import moment from "moment";
export default {
  name: "ExtensionServiceTime",
  components: {},
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      form: {
        changeTime: "",
        changeExplain: ""
      },
      rules: {
        changeTime: [
          { required: true, message: "请选择日期", trigger: "change" }
        ],
        changeExplain: [{ required: true, message: "请输入", trigger: "blur" }]
      },
      loading: false
    };
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
      this.$refs.refExtensionServiceTime.resetFields();
    },
    // 确定修改
    confirm() {
      this.$refs.refExtensionServiceTime.validate(valid => {
        if (!valid) {
          this.$message.warning("填写的内容校验不通过");
          return;
        }
        this.loading = true;
        ticketChangingOperation({
          id: this.detail.id, // 订单详情id
          changeOrRenew: 1, // 延长有效期传固定值1
          operateChannel: 6, // 客户端类型
          endValidTime: moment(this.form.changeTime).valueOf(), // 延长后有效期
          remarks: this.form.changeExplain // 改签 说明
        })
          .then(() => {
            this.$emit("updateParentData", true);
            this.$emit("update:vis", false);
            this.$refs.refExtensionServiceTime.resetFields();
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.wid {
  width: 300px;
}
</style>
