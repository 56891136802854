<template>
  <div class="wrapper flex-start flex-col">
    <!-- 搜索组件 -->
    <SearchTool
      ref="SearchTool"
      :filters="isfilters"
      :tabActive="tabActive"
      @changeSearchTab="changeSearchTab"
      @loadData="searchData"
    >
    </SearchTool>
    <!--列表-->
    <div class="main mt-2x">
      <div v-if="isTrue" class="flex-end pv-1x pr-2x">
        <a-dropdown type="primary" @click="e => e.preventDefault()">
          <a-button type="primary">
            批量操作 <a-icon type="down" class="pointer" />
          </a-button>
          <a-menu slot="overlay" @click="dropdownClick($event)">
            <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
            <template>
              <!--                  组合产品不显示-->
              <a-menu-item
                :disabled="operator.verificationDisable"
                key="verification"
                >核销</a-menu-item
              >
              <a-menu-item
                :disabled="operator.forceVerificationDisable"
                key="forceVerification"
                >强制核销</a-menu-item
              >
              <a-menu-item :disabled="operator.refundDisable" key="refund"
                >退款</a-menu-item
              >
              <a-menu-item
                :disabled="operator.forceRefundDisable"
                key="forceRefund"
                >强制退款</a-menu-item
              >
            </template>
            <a-menu-item
              :disabled="operator.changeAppointment"
              key="changeAppointment"
              >修改预约</a-menu-item
            >
          </a-menu>
        </a-dropdown>
      </div>
      <!--      :row-selection="rowSelection" 临时注释多选-->

      <currency-table
        :order-begin-time="formData.payBeginTime"
        :order-end-time="formData.payEndTime"
        :filter-api="orderProductRecordCondition"
        :columns="filterColumns"
        :data-source="tableData.data"
        :check-searchs.sync="checkSearchs"
        @filter-change="filterChange"
      >
        <template slot="popover">
          <a-table-column
            :ellipsis="true"
            width="225px"
            title="游客信息"
            align="center"
          >
            <template slot-scope="row">
              <a-popover
                v-if="row.userInfoList && row.userInfoList.length"
                :trigger="row.whetherCombineProduct === 2 ? 'click' : 'hover'"
                placement="right"
              >
                <template #content>
                  <div v-for="(item, index) in row.userInfoList" :key="index">
                    <template
                      v-if="item.fieldType == 100 || item.fieldType == 9"
                    >
                      <div class="flex-start mb-1x" v-if="item.fieldValue">
                        <p class="w-80">{{ item.fieldName }}</p>
                        <img
                          class="pointer"
                          @click="imgClick(item.fieldValue)"
                          style="width: 100px; height: 100px"
                          v-if="
                            item.fieldValue &&
                              item.fieldValue.indexOf('https://') !== -1
                          "
                          :width="40"
                          :src="item.fieldValue"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex-start mb-1x">
                        <p class="w-80">{{ item.fieldName }}:</p>
                        <p>{{ item.fieldValue || "-" }}</p>
                      </div>
                    </template>
                  </div>
                </template>

                <a-tag v-if="row.whetherCombineProduct === 2" size="medium"
                  >点击查看</a-tag
                >
                <div v-else>
                  {{ row.userInfoList[0] && row.userInfoList[0].fieldName }}:
                  <img
                    class="pointer"
                    style="width: 30px; height: 30px"
                    v-if="
                      row.userInfoList[0] &&
                        row.userInfoList[0].fieldValue &&
                        row.userInfoList[0].fieldValue.indexOf('https://') !==
                          -1
                    "
                    :src="row.userInfoList[0].fieldValue"
                  />
                  <span v-else>{{
                    (row.userInfoList[0] && row.userInfoList[0].fieldValue) ||
                      "-"
                  }}</span>
                </div>
              </a-popover>
            </template>
          </a-table-column>
        </template>
        <!-- 操作列 -->
        <template slot="action">
          <a-table-column
            width="180px"
            title="操作"
            align="center"
            fixed="right"
          >
            <!--            <div slot="title">-->
            <!--              操作-->
            <!--            </div>-->
            <div slot-scope="row" class="flex-center">
              <template v-if="row.whetherCombineProduct === 2">
                <span
                  v-permission="'appOrderNewProductDetail'"
                  class="primary ph-x mr-1x"
                  @click="goOrderProductDetail(row)"
                  >详情</span
                >
              </template>
              <a-dropdown
                v-permission="'appOrderNewProductMore'"
                @click="e => e.preventDefault()"
              >
                <span class="pointer primary">
                  更多操作
                  <a-icon type="down" />
                </span>
                <a-menu slot="overlay" @click="dropdownClick($event, row)">
                  <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
                  <template>
                    <!-- v-if="row.whetherCombineProduct === 1" 目前不作限制，飞哥-->
                    <a-menu-item
                      v-permission="'appOrderNewProductMoreWriteoff'"
                      :disabled="
                        !checkAuthorityBtn(row).includes('核销') ||
                          (checkAuthorityBtn(row).includes('核销') &&
                            row.canUseNum !== -1 &&
                            row.canUseNum === row.useNum)
                      "
                      key="verification"
                      >核销
                    </a-menu-item>
                    <a-menu-item
                      v-permission="'appOrderNewProductMoreForcedwriteoff'"
                      key="forceVerification"
                      >强制核销
                    </a-menu-item>
                    <a-menu-item
                      v-permission="'appOrderNewProductMorerefund'"
                      :disabled="!checkAuthorityBtn(row).includes('退款')"
                      key="refund"
                      >退款
                    </a-menu-item>
                    <!--<a-menu-item key="forceRefund">强制退款 </a-menu-item>-->
                  </template>
                  <a-menu-item key="changeValidity">延长有效期 </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductMoreModifyappointment'"
                    key="changeAppointment"
                    :disabled="!checkAuthorityBtn(row).includes('修改预约')"
                    >修改预约
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductMorereissue'"
                    key="replace"
                    :disabled="!checkAuthorityBtn(row).includes('补办')"
                    >补办
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductMoreactivation'"
                    key="activate"
                    :disabled="!checkAuthorityBtn(row).includes('激活')"
                    >激活
                  </a-menu-item>
                  <a-menu-item
                    v-permission="'appOrderNewProductMoreFace'"
                    key="recordingFace"
                    :disabled="!checkAuthorityBtn(row).includes('补录人脸')"
                    >补录人脸
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </a-table-column>
        </template>
      </currency-table>
      <!--    查看图片-->
      <a-modal v-model="tableFaceShow" title="查看图片" centered>
        <img width="400" :src="tableFaceUrl" alt="" />
        <div slot="footer" class="text-c">
          <a-button type="primary" @click="tableFaceShow = false"
            >关闭</a-button
          >
        </div>
      </a-modal>
      <div class="p-2x flex-between">
        <div>
          <div class="flex-start">
            <span class="bold flex-shrink">汇总：</span>
            <span class="flex-shrink">总单实收总额：</span>
            <span class="red flex-shrink">{{ amountSum | money("") }}元</span>
            <span class="ml-1x gray" v-if="payWayInfo">({{ payWayInfo }})</span>
            <div class="mh-2x flex-shrink">
              单品数：<span>{{ productNum }} 件</span>
            </div>
            <div class="flex-shrink mr-1x">
              产品数：<span>{{ tableData.total }} 件</span>
            </div>
          </div>
          <span class="red">说明：总单实收总额未减去退款，并非实收</span>
        </div>
        <div class="flex-shrink">
          <a-pagination
            v-model="tableData.page"
            :default-page-size="tableData.size"
            :total="tableData.total"
            :page-size-options="['10', '20', '50', '100']"
            show-size-changer
            @change="pageChange"
            @showSizeChange="sizeChange"
          />
        </div>
      </div>
    </div>

    <!-- 退款弹窗 -->
    <refund-public
      title="退款"
      :refund-show="refundShow"
      :refund-info="refundInfo"
      @success="refundOk"
      @cancel="refundShow = false"
    ></refund-public>
    <!-- 强制退款弹窗 -->
    <force-refund-public
      :title="forceTitle"
      :code-type="codeType"
      :refund-show="forceRefundShow"
      @success="forceRefundOk"
      @cancel="forceRefundShow = false"
    ></force-refund-public>
    <!--    修改预约-->
    <change-validity
      :visible.sync="appointmentPop"
      :detail-item="detailItem"
      :validity-type="validityType"
      @submit="submitForm"
    ></change-validity>
    <!--    补办-->
    <replace
      :replace-info="replaceInfo"
      :replace-show="replaceShow"
      @cancel="replaceShow = false"
      @success="replaceSuccess"
    ></replace>
    <!--支付方式-->
    <pay-way
      ref="payWay"
      :is-modal="true"
      :cart-id="cartId"
      @success="getDataList"
    ></pay-way>
    <!--    人脸录入-->
    <add-face
      :vis.sync="faceShow"
      :detail="detailItem"
      @updateParentData="getDataList"
    ></add-face>
    <!--    激活-->
    <active-ticket
      :auxiliary-id="auxiliaryId"
      :field-data="fieldData"
      :vis.sync="activeTicketShow"
      @success="getDataList"
    ></active-ticket>
  </div>
</template>

<script>
import {
  orderSalesproductrecord,
  orderProductRecordCondition
} from "@/api/search";
import SearchTool from "./components/SearchTool";
import CurrencyTable from "@/components/table/CurrencyTable";
import { checkAuthorityBtn } from "@/utils/global";
import RefundPublic from "./components/RefundPublic.vue";
import ForceRefundPublic from "./components/ForceRefundPublic.vue";
import AddFace from "./components/AddFace.vue";
import Replace from "./components/Replace";
import PayWay from "@/components/sell/commonents/PayWay";
import ActiveTicket from "./components/ActiveTicket.vue";
import ChangeValidity from "./components/ChangeValidity";
import moment from "moment";
import {
  productWriteOff,
  refundProductCheck,
  refundProduct,
  refundProductForce,
  productWriteOffForce,
  productReissueCheck
} from "@/api/order";
import { commonReissue, activateTravellerInfo } from "@/api/cart";
export default {
  name: "RefundManagement",
  components: {
    SearchTool,
    CurrencyTable,
    RefundPublic,
    ForceRefundPublic,
    Replace,
    PayWay,
    AddFace,
    ActiveTicket,
    ChangeValidity
  },
  computed: {
    filterColumns() {
      return this.tableData.columns.filter(column => column.show);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.selectionChange
      };
    }
  },
  data() {
    return {
      orderProductRecordCondition,
      isTrue: false, // 临时隐藏多选
      moment,
      selectedRowKeys: [], // 多选数组
      checkAuthorityBtn, // 校验按钮显示或隐藏公共方法
      refundShow: false, //退款弹窗
      refundInfo: {}, //退款信息
      detailItem: {}, // 退款点击当前产品对象的
      appointmentPop: false, // 修改预约弹框
      validityType: "", // 修改预约类型，延长有效期
      auxiliaryId: "", // 激活时的票号
      faceShow: false, // 人脸录入
      activeTicketShow: false, // 激活弹框
      fieldData: {}, // 激活时需要显示的动态表单
      forceType: "", // 强制退款或强制核销
      forceTitle: "", // 强制弹框标题
      codeType: "",
      forceRefundShow: false, // 强制核销弹框
      checkSearchs: [], // 表格头过滤后选中的列表数组
      tabActive: 0, // 搜索组件当前tab下标
      isfilters: [], // 搜索组件
      amountSum: 0, // 销售额
      productNum: 0, // 单品数
      payWayInfo: "", // 销售额信息
      fullPath: "", //路由路径地址
      formData: {}, // 搜索表单
      operator: {
        verificationDisable: true, // 核销
        forceVerificationDisable: true, // 强制核销
        refundDisable: true, // 退款
        forceRefundDisable: true, // 强制退款
        changeAppointment: true // 修改预约
      },
      // 补办
      replaceShow: false, // 补办弹框
      replaceInfo: {}, // 补办前获取补办信息
      cartId: 0, //  补办后生成的购物车id
      filtersList: [
        {
          label: "支付时间",
          key: "payTime",
          value: [moment(), moment()],
          type: "time",
          placeholder: ""
        },
        {
          label: "下单时间",
          key: "createTime",
          value: [],
          type: "time",
          placeholder: ""
        },
        {
          label: "预约时间",
          key: "appointmentDate",
          value: "",
          type: "time",
          placeholder: ""
        },
        {
          label: "核销时间",
          key: "writeOffTime",
          value: "",
          type: "time",
          placeholder: ""
        },
        {
          label: "",
          width: "325px",
          key: "subFilters",
          value: "",
          type: "subFilters",
          placeholder: "请输入单号/产品编号/会员手机号/会员证件号"
        }
      ], //搜索条件
      tableData: {
        data: [],
        columns: [
          {
            key: "auxiliaryId",
            title: "产品编号（票号）",
            show: true,
            fixed: "left",
            width: 200
          },
          {
            key: "productName",
            title: "产品名称",
            show: true,
            width: 180,
            ellipsis: true,
            filters: true, // 开启表格头部过滤
            filterKey: "productInfo",
            fieldList: []
          },
          {
            key: "payTime",
            title: "支付时间",
            show: true,
            width: 180,
            scopedSlots: {
              customRender: "date"
            }
          },
          {
            key: "createTime",
            title: "下单时间",
            show: true,
            width: 180,
            scopedSlots: {
              customRender: "date"
            }
          },
          {
            key: "mainOrderId",
            title: "总单号",
            show: true,
            width: 200
          },
          {
            key: "orderBusinessTypeText",
            title: "业务类型",
            show: true,
            width: 170,
            filters: true, // 开启表格头部过滤
            filterKey: "orderBusinessType",
            fieldList: []
          },
          {
            key: "ownerSubMerchantInfoName",
            title: "子景区",
            show: true,
            width: 150,
            ellipsis: true,
            filters: true, // 开启表格头部过滤
            filterKey: "subMerchantScenic",
            fieldList: []
          },
          {
            key: "ownerMerchantInfoName",
            title: "商户",
            show: true,
            width: 180,
            ellipsis: true,
            filters: true, // 开启表格头部过滤
            filterKey: "productOwnerMerchant",
            fieldList: []
          },
          {
            key: "totalAmount",
            title: "产品单价",
            primary: "red", // 设置金额红色
            show: true,
            width: 135,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "receivedAmount",
            title: "产品实收",
            primary: "red", // 设置金额红色
            show: true,
            width: 135,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "statusText",
            title: "产品状态",
            show: true,
            width: 135
          },
          {
            key: "beginValidTime",
            title: "产品开始有效期",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "endValidTime",
            title: "产品结束有效期",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "afterWriteOffValidTime",
            title: "核销后有效期",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "parentAuxiliaryId",
            title: "关联产品编号",
            show: true,
            width: 170
          },
          // {
          //   key: "productVoucherName",
          //   title: "产品凭证名称",
          //   show: true,
          //   width: 170
          // },
          // {
          //   key: "productVoucherInfo",
          //   title: "产品凭证信息",
          //   show: true,
          //   width: 180,
          //   scopedSlots: { customRender: "face" }, // 存在人脸情况
          //   ellipsis: true
          // },
          {
            key: "userInfoList",
            title: "游客信息",
            popover: true,
            show: true,
            width: 220
          },
          {
            key: "touristType",
            title: "游客类型",
            show: true,
            width: 170,
            filters: true, // 开启表格头部过滤
            filterKey: "visitorType",
            fieldList: []
          },
          {
            key: "touristDiscount",
            title: "游客优惠",
            show: true,
            width: 170,
            filters: true, // 开启表格头部过滤
            filterKey: "visitorDiscountType",
            fieldList: []
          },
          {
            key: "appointmentDate",
            title: "预约日期",
            show: true,
            width: 160,
            scopedSlots: { customRender: "date", valueFormat: "YYYY-MM-DD" }
          },
          {
            key: "appointmentTime",
            title: "预约时段",
            show: true,
            width: 150
          },
          {
            key: "canUseNum",
            title: "可核销次数",
            show: true,
            width: 120
          },
          {
            key: "useNum",
            title: "已核销次数",
            show: true,
            width: 120
          },
          {
            key: "writeOffPersonName",
            title: "核销员",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "writeOffPerson",
            fieldList: []
          },
          {
            key: "writeOffTime",
            title: "核销时间",
            show: true,
            width: 180,
            scopedSlots: {
              customRender: "date"
            }
          },

          {
            key: "writeOffChannel",
            title: "核销渠道",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "writeOffChannel",
            fieldList: []
          }
        ],
        multipleSelection: [],
        // multiple:false,
        total: 1,
        page: 1,
        size: 10
      },
      tableFaceShow: false,
      tableFaceUrl: ""
    };
  },
  created() {
    this.fullPath = this.$route.fullPath;
  },
  mounted() {
    this.changeSearchTab(0);
    this.$nextTick(() => {
      this.$refs.SearchTool.loadData();
    });
  },
  // 防止父组件没设置validate属性而报错，因为payway组件有引入  inject: ["validate"],
  provide() {
    return {
      validate: {}
    };
  },
  methods: {
    // 点击人脸
    imgClick(url) {
      this.tableFaceShow = true;
      this.tableFaceUrl = url;
    },
    // 预约时段禁止区域
    disabledDate(current) {
      return (
        current && current < this.moment(new Date().getTime()).add(0, "day")
      );
    },
    disabledUseDate(current) {
      return (
        current && current < this.moment(new Date().getTime()).add(-1, "day")
      );
    },
    // 搜索组件tab改变
    changeSearchTab(value) {
      this.tabActive = value;
      if (value) {
        //单号
        this.isfilters = this.filtersList.filter(
          item => item.type === "subFilters"
        );
      } else {
        //时间
        this.isfilters = this.filtersList.filter(item => item.type === "time");
      }
    },
    // 搜索
    searchData(val) {
      this.tableData.columns.forEach(item => {
        if (item.fieldList && item.fieldList.length) {
          item.fieldList = [];
        }
      });
      this.checkSearchs = [];
      this.tableData.page = 1;
      // //0时间 1单号
      this.formData = {};
      if (this.tabActive) {
        this.formData.keyword = val.subFilters;
      } else {
        //支付时间
        if (val.payTime) {
          this.formData.payBeginTime = val.payTime.length
            ? moment(val.payTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.payEndTime = val.payTime.length
            ? moment(val.payTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        //下单时间
        if (val.createTime) {
          this.formData.orderBeginTime = val.createTime.length
            ? moment(val.createTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.orderEndTime = val.createTime.length
            ? moment(val.createTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        if (val.appointmentDate) {
          // 预约开始时间
          this.formData.appointmentBeginTime = val.appointmentDate.length
            ? moment(val.appointmentDate[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.appointmentEndTime = val.appointmentDate.length
            ? moment(val.appointmentDate[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        if (val.writeOffTime) {
          // 核销时间
          this.formData.writeOffBeginTime = val.writeOffTime.length
            ? moment(val.writeOffTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.writeOffEndTime = val.writeOffTime.length
            ? moment(val.writeOffTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
      }
      this.getDataList();
    },
    // 获取产品列表数据
    getDataList() {
      let obj = this.filtration();
      orderSalesproductrecord(obj).then(res => {
        res.records.forEach(item => {
          item.canUseNum = item.canUseNum !== -1 ? item.canUseNum : "无限次";
          if (item.userInfoConvert) {
            // 前端拼接游客信息
            item.userInfoConvert = JSON.parse(item.userInfoConvert) || {};
            if (item.userInfoConvert.wristbandsNo) {
              item.userInfoList.push({
                fieldName: "卡腕带",
                fieldValue: item.userInfoConvert.wristbandsNo
              });
            }
          }
          if (item.userInfoList && item.userInfoList.length) {
            let valueArr = [];
            let emptyArr = [];
            // 某个有值的放最前面
            item.userInfoList.forEach(te => {
              if (te.fieldValue && te.fieldValue.includes("https://")) {
                valueArr.push(te);
              } else if (te.fieldValue) {
                valueArr.unshift(te);
              } else {
                emptyArr.push(te);
              }
            });
            item.userInfoList = valueArr.concat(emptyArr);
          }
        });
        this.tableData.data = res.records;
        this.amountSum = res.actualAmountSum || 0;
        this.productNum =
          res.total - res.combinationNum + res.combinationSubNum;
        this.payWayInfo = res.payWay;
        this.tableData.total = res.total;
      });
    },
    // 公用方法
    filtration() {
      this.selectedRowKeys = [];
      let productId = "";
      let businessType = "";
      let subMerchantScenicId = "";
      let merchantId = "";
      let touristType = "";
      let touristDiscount = "";
      let writeOffPersonName = "";
      let writeOffChannel = "";

      if (this.checkSearchs.length) {
        this.checkSearchs.forEach(item => {
          switch (item.column.filterKey) {
            case "productInfo":
              productId = item.tagList.map(tem => tem.key).join(",");
              break;
            case "orderBusinessType":
              businessType = item.tagList.map(tem => tem.key).join(",");
              break;
            case "subMerchantScenic":
              subMerchantScenicId = item.tagList.map(tem => tem.key).join(",");
              break;
            case "productOwnerMerchant":
              merchantId = item.tagList.map(tem => tem.key).join(",");
              break;
            case "visitorType":
              touristType = item.tagList.map(tem => tem.key).join(",");
              break;
            case "visitorDiscountType":
              touristDiscount = item.tagList.map(tem => tem.key).join(",");
              break;
            case "writeOffPerson":
              writeOffPersonName = item.tagList.map(tem => tem.key).join(",");
              break;
            case "writeOffChannel":
              writeOffChannel = item.tagList.map(tem => tem.key).join(",");
              break;
          }
        });
      }
      let info = {
        page: this.tableData.page,
        size: this.tableData.size,
        // 开票时间 缺少
        productId, // 产品id
        businessType, // 业务类型
        subMerchantScenicId, // 子景区
        merchantId, // 商户id
        touristType, // 游客类型
        touristDiscount, // 游客优惠
        writeOffPersonName, // 核销员
        writeOffChannel, // 核销渠道
        ...this.formData
        // billingStatus: "" // 总开开票状态
        // 外部单号缺少 （不作筛选条件 忽略）
      };
      return info;
    },
    // 产品列表多选
    selectionChange(val) {
      this.selectedRowKeys = val;
      // 初始化
      this.operator = {
        verificationDisable: false,
        forceVerificationDisable: false,
        refundDisable: false,
        forceRefundDisable: false,
        changeAppointment: false
      };
      // 多选产品列表时根据校验规则判定按钮是否可点击
      if (val.length) {
        let arr = [];
        this.tableData.data.forEach(tem => {
          // 查找出选择的对象
          let i = val.findIndex(te => te === tem.id);
          if (i !== -1) {
            arr.push(tem);
          }
        });
        arr.forEach(item => {
          // 组合产品不显示核销，强制核销，退款，强制退款
          if (
            !this.checkAuthorityBtn(item).includes("核销") ||
            item.combineAuxiliaryId
          )
            this.operator.verificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制核销") ||
            item.combineAuxiliaryId
          )
            this.operator.forceVerificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("退款") ||
            item.combineAuxiliaryId
          )
            this.operator.refundDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制退款") ||
            item.combineAuxiliaryId
          )
            this.operator.forceRefundDisable = true;
          if (!this.checkAuthorityBtn(item).includes("修改预约"))
            this.operator.changeAppointment = true;
        });
      } else {
        this.operator = {
          verificationDisable: true,
          forceVerificationDisable: true,
          refundDisable: true,
          forceRefundDisable: true,
          changeAppointment: true
        };
      }
    },
    // 页数改变
    pageChange(current) {
      this.tableData.page = current;
      this.getDataList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.tableData.page = current;
      this.tableData.size = size;
      this.getDataList();
    },
    // 表格过滤触发的时间
    filterChange() {
      this.tableData.page = 1;
      this.getDataList();
    },
    // 产品信息列表表格操作按钮
    dropdownClick({ key }, row) {
      switch (key) {
        case "verification":
          if (row) {
            // 单个
            this.$confirm({
              title: "提示",
              content: "确定要执行核销操作吗？",
              okText: "确定",
              onOk: () => {
                productWriteOff({
                  code: row.auxiliaryId
                }).then(res => {
                  if (res.code === 500) {
                    this.$message.warning(
                      res.otherMsg ? res.otherMsg : "核销失败！"
                    );
                  } else {
                    this.$message.success(
                      res.otherMsg ? res.otherMsg : "核销成功！"
                    );
                  }
                  this.getDataList();
                });
              }
            });
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceVerification":
          if (row) {
            // 单个
            this.forceType = "verification";
            this.detailItem = row;
            this.forceTitle = "强制核销";
            this.codeType = "FORCED_TO_WRITE_OFF";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "refund":
          if (row) {
            // 单个
            this.refundChecks(row);
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceRefund":
          if (row) {
            // 单个
            this.forceType = "refund";
            this.detailItem = row;
            this.forceTitle = "强制退款";
            this.codeType = "FORCED_TO_REFUND";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "changeValidity":
          if (row) {
            // 单个
            this.validityType = "changeValidity";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "changeAppointment":
          if (row) {
            // 单个
            this.validityType = "changeAppointment";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "replace":
          if (row) {
            // 单个 补办
            productReissueCheck({
              id: row.id
            }).then(res => {
              this.detailItem = row;
              this.replaceInfo = res;
              this.replaceShow = true;
            });
          }
          break;
        case "activate":
          if (row) {
            // 激活
            activateTravellerInfo({
              orderDetailId: row.id
            }).then(res => {
              this.auxiliaryId = row.auxiliaryId;
              this.activeTicketShow = true;
              this.fieldData = res;
            });
          }
          break;
        case "recordingFace":
          if (row) {
            // 单个 补录人脸
            this.detailItem = row;
            this.faceShow = true;
          }
          break;
      }
    },
    // 产品退款校验
    refundChecks(row) {
      this.refundType = "productRefund";
      this.detailItem = row;
      refundProductCheck({
        mainOrderId: row.mainOrderId,
        detailId: row.id
      })
        .then(res => {
          this.refundShow = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    //完成退款
    refundOk(obj) {
      this.refundShow = false;
      // 产品退款
      refundProduct({
        mainOrderId: this.detailItem.mainOrderId,
        detailId: this.detailItem.id,
        remarks: obj.remarks
      })
        .then(res => {
          this.$message.success(
            res.refundFailMsg ? res.refundFailMsg : "退款成功！"
          );
          this.getDataList();
        })
        .catch(() => {});
    },
    // 强制完成退款/核销
    forceRefundOk(obj) {
      this.forceRefundShow = false;
      if (this.forceType === "refund") {
        refundProductForce({
          mainOrderId: this.detailItem.mainOrderId,
          detailId: this.detailItem.id,
          remarks: obj.remarks
        })
          .then(() => {
            this.getDataList();
            this.$message.success("强制退款成功！");
          })
          .catch(() => {});
      } else if (this.forceType === "verification") {
        productWriteOffForce({
          code: this.detailItem.id
        })
          .then(() => {
            this.getDataList();
            this.$message.success("强制核销成功！");
          })
          .catch(() => {});
      }
    },
    goOrderProductDetail(row) {
      // go 订单产品详情
      this.$router.push({
        path: "/home/orderNew/ProductDetail",
        query: { id: row.id }
      });
    },
    // 批量修改预约
    submitForm() {
      this.$message.success("修改成功!");
      this.getDataList();
    },
    resetForm() {
      this.appointmentPop = false;
    },
    // 补办
    replaceSuccess(val) {
      this.replaceShow = false;
      commonReissue({
        orderDetailId: this.detailItem.id,
        // changePrice: val.price * 100, // 补办费用转换为分,
        changePrice: Math.round(val.price * 10000) / 100, // 补办费用转换为分,
        remark: val.remark
      }).then(res => {
        this.cartId = res.id;
        this.$nextTick(() => {
          this.$refs.payWay.pay();
        });
      });
    }
  }
};
</script>

<style scoped lang="less">
.wrapper-box {
  padding: 0 16px 32px 16px;
  background-color: #ffffff;
  box-sizing: border-box;
  flex-grow: 1;
  height: calc(100vh - 152px);
  overflow: auto;
  transition: height 0.3s ease-in-out;
}
.w-80 {
  width: 80px;
}
</style>
