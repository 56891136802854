<!--
  @name: 确认订单信息页面
  @author: fengyanlong
  @date:2021-04-12
-->

<template>
  <div class="wrapper flex-start flex-col">
    <ProgressBar :cur-step="1"></ProgressBar>
    <div class="main p-3x">
      <!--订单列表-->
      <a-table
        :columns="columns"
        :data-source="cartData.productItemList"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :pagination="false"
      >
        <!-- 打印规则列 -->
        <div slot="voucherUseType" slot-scope="row">
          <span v-if="row.voucherUseType === 0">一人一票</span>
          <span v-if="row.voucherUseType === 1">一票多人</span>
          <a-button
            v-if="row.whetherChangePrint"
            type="link p-0 ml-2x font-xl"
            @click="editPrintRule(row)"
            >修改打印规则</a-button
          >
        </div>

        <!-- 只有在餐饮时才有的规格信息start -->
        <div
          slot="foodSetSubProduct"
          slot-scope="{ foodSetSubProduct, addPurchaseProductItemList }"
          v-if="category === 'catering'"
        >
          <template v-if="foodSetSubProduct">
            <div
              v-for="(son, index) in subgroup(foodSetSubProduct, 'groupName')"
              :key="index"
            >
              <div>
                <span>{{ son.groupName }}：</span>
                <span v-for="v in son.children" :key="v.productId"
                  >{{ v.productName }};
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(son, index) in subgroup(
                addPurchaseProductItemList,
                'groupName'
              )"
              :key="index"
            >
              <div>
                <span>{{ son.groupName }}：</span>
                <span v-for="v in son.children" :key="v.productId"
                  >{{ v.productName }};
                </span>
              </div>
            </div>
          </template>
        </div>
        <!-- 只有在餐饮时才有的规格信息end -->

        <!-- 剧场票规格 -->
        <div
          slot="theaterSeatInfo"
          slot-scope="{
            playName,
            playDate,
            appointmentBeginTime,
            placeName,
            theaterSeatInfo
          }"
          v-if="theaterSeatInfo"
        >
          <div>{{ playName }}</div>
          <div>
            {{ playDate | date("YYYY-MM-DD") }}
            {{ appointmentBeginTime | date("hh:mm") }}
            {{ `${placeName}` }}
          </div>
          <div
            v-for="item in getSeatInfo(theaterSeatInfo)"
            :key="item.ticketLevelName"
          >
            {{ item.ticketLevelName }} {{ item.seatName.toString() }}
          </div>
        </div>
        <!-- 剧场票规格end -->

        <span slot="totalSellingPrice" slot-scope="{ totalSellingPrice }">{{
          totalSellingPrice | money
        }}</span>
        <span slot="allSettlementPrice" slot-scope="{ allSettlementPrice }">{{
          allSettlementPrice | money
        }}</span>
      </a-table>
      <!--支付密码-->
      <div class="flex-end container" v-show="showPayPassword">
        <a-form layout="inline">
          <a-form-item label="支付密码：">
            <cell-password
              @confirm="submitOrder"
              v-model="memberPaymentPassword"
            ></cell-password>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer-wrap shadow flex-col flex-between">
      <!-- 上：优惠信息 -->
      <div :class="[calcDiscount.length ? 'flex-between' : 'flex-end']">
        <div class="left flex-start" v-if="calcDiscount.length">
          <div
            class="red left-text-wrap flex-start mr-4x"
            v-for="(item, index) in calcDiscount"
            :key="index"
          >
            <i class="ib left-icon"></i>
            <span class="text red">
              {{ item.name }}优惠：{{ item.amount | money("") }}元
            </span>
            <i class="ib right-icon"></i>
          </div>
        </div>
        <div class="right text-r">
          <p class="font-l">
            共
            <span class="">{{ cartData.ticketNum }}</span>
            件，小计：
            <span class="red">{{ cartData.totalPrice | money("") }}元</span>
          </p>
          <div class="mt-1x" v-if="calcDiscount.length">
            <div v-for="(item, index) in calcDiscount" :key="index">
              <span>{{ item.name }}优惠：</span>
              <span class="red">-{{ item.amount | money("") }}元</span>
            </div>
          </div>
          <p class="bold mt-x">
            <span class="font-xl">合计：</span>
            <span class="red font-xxxl"
              >{{ shouldPayMoney | money("") }}元</span
            >
          </p>
        </div>
      </div>
      <!-- 下：按钮组 -->
      <div class="footer-btn-wrap">
        <a-button
          type="primary"
          ghost
          size="large"
          shape="round"
          @click="goBack"
          class="btn-lg"
        >
          返回上一页
        </a-button>
        <div>
          <a-button
            type="primary"
            ghost
            size="large"
            shape="round"
            @click="orderInfoCouponDialog = true"
            class="btn-lg mr-2x"
          >
            <i class="iconfont iconyouhuiquan mr-x"></i>
            查看所有优惠>
          </a-button>
          <a-button
            type="primary"
            size="large"
            shape="round"
            class="btn-lg"
            @click="submitOrder"
            >订单支付
          </a-button>
        </div>
      </div>
    </div>

    <!-- 输入管理员登录密码弹窗 -->
    <a-modal v-model="pwdVisible" title="请输入密码" @ok="checkPwd">
      <p class="mb-2x">订单里存在改价格的商品，需要输入管理员密码和改价原因</p>
      <a-input v-model="loginPassword" placeholder="请输入登录密码"></a-input>
      <a-input
        class="mt-2x"
        v-model="remark"
        placeholder="请输入改价原因"
      ></a-input>
    </a-modal>

    <!-- 选择所有优惠 弹窗 -->
    <OrderInfoCoupon
      :vis.sync="orderInfoCouponDialog"
      :orderData="cartData"
    ></OrderInfoCoupon>
    <!-- 打印规则弹窗 -->
    <a-modal
      v-model="printVisible"
      title="修改打印规则"
      :mask-closable="false"
      @ok="handleOk"
    >
      <a-form-model
        ref="ruleForm"
        :model="printForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="name" prop="name">
          <a-radio-group v-model="printForm.cartPrintType">
            <a-radio class="radio-style" :value="1">
              使用商家后台规则： {{ ticketText }}
            </a-radio>
            <a-radio class="radio-style" :value="2">
              使用窗口打印规则： {{ isPrint }}
            </a-radio>
            <a-radio class="radio-style" :value="3">
              自定义规则
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="本次打印票数"
          v-if="printForm.cartPrintType === 3"
        >
          <a-input-number
            :min="1"
            :max="10"
            :step="1"
            class="print-num"
            v-model="printForm.printNum"
            placeholder="请输入"
            allowClear
            addon-after="张"
            @change="changeNum"
          ></a-input-number>
          <span class="ml-1x">张</span>
        </a-form-model-item>
        <a-form-model-item
          label="每张票支持核销次数"
          v-if="printForm.cartPrintType === 3"
        >
          <a-input-number
            :min="1"
            :max="100"
            :step="1"
            class="print-time"
            v-model="printForm.times[index]"
            placeholder="请输入"
            allowClear
            v-for="(item, index) in printForm.times"
            :key="index"
            onkeyup="this.value=this.value.replace(/\D|^0/g,'')"
            @blur="blurWriteOff"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
      <p v-if="printForm.cartPrintType === 3">
        提示： 当前<span class="red bold mh-1x"> {{ printBuyNum }}</span
        >人团体票，共可以核销<span class="red bold mh-1x">
          {{ printBuyNum }}</span
        >次，本次打印<span class="red bold mh-1x">
          {{ printForm.printNum }}</span
        >张票，占有核销次数<span class="red bold mh-1x">{{ totalNum }}</span
        >次，剩余<span class="red bold mh-1x">{{ remainingNum }}</span
        >次核销次数。
      </p>
    </a-modal>
  </div>
</template>

<script>
import {
  discountInfoCalculate,
  shoppingCart,
  foodCartDetail,
  cartChangePrint,
  cartChangePrintInfo
} from "../../../api/cart";
import OrderInfoCoupon from "./components/OrderInfoCoupon.vue";
import { mapActions, mapGetters } from "vuex";
import CellPassword from "../../../components/common/CellPassword.vue";
import { myMixin } from "../../../components/pay/mixin";
import { unlockTheScreen } from "../../../api/user";
import ProgressBar from "../../../components/common/ProgressBar.vue";

export default {
  components: { OrderInfoCoupon, CellPassword, ProgressBar },
  name: "order-info",
  props: {
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    // 产品列表
    const columns = [
      {
        title: "产品名称",
        key: "productName",
        dataIndex: "productName"
      },
      {
        title: "打印规则",
        key: "voucherUseType",
        scopedSlots: { customRender: "voucherUseType" }
      },
      {
        title: "单价",
        scopedSlots: { customRender: "totalSellingPrice" }
      },
      {
        title: "数量",
        key: "buyNum",
        dataIndex: "buyNum"
      },
      {
        title: "小计",
        key: "allSettlementPrice",
        scopedSlots: { customRender: "allSettlementPrice" }
      }
    ];
    // // 合计表格
    // const tableCol = [
    //   {
    //     title: "合计",
    //     scopedSlots: { customRender: "ticketNum" }
    //   },
    //   {
    //     title: "应收合计",
    //     scopedSlots: { customRender: "totalPrice" }
    //   },
    //   {
    //     title: "订单折扣",
    //     scopedSlots: { customRender: "merchantDiscountAmount" }
    //   },
    //   {
    //     title: "会员余额",
    //     scopedSlots: { customRender: "memberBalancePrice" }
    //   },
    //   {
    //     title: "积分抵扣",
    //     scopedSlots: { customRender: "integralPayPrice" }
    //   },
    //   {
    //     title: "应付总额",
    //     scopedSlots: { customRender: "shouldPayMoney" }
    //   }
    // ];
    return {
      columns, // 产品列表表格
      // tableCol, //合计表格
      category: "ticket", //当前处理的业态,默认票务
      cartData: {
        productItemList: []
      }, //整个购物车对象
      memberPaymentPassword: "", //支付密码
      tableSpuId: "", //餐饮专用字段，桌台的spuid
      pwdVisible: false, //显示输入管理员密码弹窗
      loginPassword: "", //登录密码
      isPwdPass: false, //登录密码是否正确
      remark: "", //改价原因
      orderInfoCouponDialog: false, // 是否显示选择所有优惠 弹窗
      // 修改打印规则
      printVisible: false,
      printForm: {
        cartPrintType: 1, //打印规则：1后台打印规则，2窗口打印规则，3自定义规则
        printNum: 1, //本次打印数量
        times: [1] //每张票支持核销次数，数字数组
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      rules: {},
      ticketText: "", //票的描述：一人一票或一票多人
      isPrint: "", //门票是否打印
      printBuyNum: "", //团体票数量、总共核销次数
      printCartId: "", //购物车id
      printProductId: "", //产品id
      totalNum: 1, //计算输入的核销次数之和
      remainingNum: null //剩余核销次数
    };
  },
  computed: {
    ...mapGetters("common", ["memberId"]),
    ...mapGetters("order", [
      "cartList",
      // "merchantDiscountAmount",
      // "merchantDiscountStr",
      "shouldPayMoney",
      // "discountId",
      // "integralPayNum",
      // "marketingMobile", //营销活动手机号
      // "idCardNo", //身份证号
      // "couponNo", //优惠券号
      // "marketingId", //优惠券id
      "totalPrice", //购物车总金额
      // "settingType", //整单折扣的配置类型：1.立减，2.折扣
      // "customAmount", //整单折扣或者自定义金额
      // "integralPayPrice", //积分抵扣多少钱
      // "memberBalancePrice", //会员余额抵扣多少钱

      "discountTextList", // 支付重构：订单折扣优惠数据
      "integralTextList" // 支付重构：积分抵扣优惠数据
    ]),
    // 算出所有选择的优惠数据信息
    calcDiscount() {
      return [...this.discountTextList, ...this.integralTextList];
    },
    // 有积分抵扣，需要输入支付密码
    showPayPassword() {
      return this.integralTextList.length;
    },
    // // 构造出合计表格结构
    // totalTable() {
    //   return [
    //     {
    //       id: "total",
    //       ticketNum: this.cartData.ticketNum,
    //       totalPrice: this.cartData.totalPrice,
    //       merchantDiscountAmount: this.merchantDiscountAmount,
    //       memberBalancePrice: this.memberBalancePrice,
    //       integralPayPrice: this.integralPayPrice,
    //       shouldPayMoney: this.shouldPayMoney
    //     }
    //   ];
    // }
    getSeatInfo() {
      return theaterSeatInfo => {
        let newArr = [];
        theaterSeatInfo.forEach(item => {
          var index = newArr.findIndex(function(ite) {
            return ite.ticketLevelId === item.ticketLevelId;
          });
          if (index !== -1) {
            newArr[index].seatName.push(item.seatName);
          } else {
            newArr.push({
              ticketLevelId: item.ticketLevelId,
              ticketLevelName: item.ticketLevelName,
              seatName: [item.seatName]
            });
          }
        });
        return newArr;
      };
    }
  },
  mixins: [myMixin],
  created() {
    //如果是从餐饮过来
    if (this.$route.query.format === "catering") {
      this.tableSpuId = this.$route.query.tableSpuId;
      this.getFoodCartDetail();
    } else {
      this.getCartInfo();
    }
    //从缓存获取窗口门票打印配置信息
    let Hardware = JSON.parse(localStorage.getItem("Hardware"));
    if (Hardware && Hardware.printText) {
      this.isPrint = "打印";
    } else {
      this.isPrint = "不打印";
    }
  },
  beforeDestroy() {
    this.clearDiscount();
  },
  methods: {
    ...mapActions("order", ["clearDiscount", "setCartList"]),
    ...mapActions("common", ["updateMember"]),
    getCartInfo() {
      shoppingCart({ id: this.cartList.id }).then(res => {
        this.cartData = res;
        this.printCartId = res.id;
        //剧场票时展示规格
        if (
          res.productItemList.length > 0 &&
          res.productItemList[0].theaterSeatInfo &&
          this.category !== "catering"
        ) {
          this.columns.splice(1, 0, {
            title: "规格",
            key: "theaterSeatInfo",
            scopedSlots: { customRender: "theaterSeatInfo" }
          });
        }
        this.setCartList(res);
      });
    },
    //获取餐饮的购物车详情
    getFoodCartDetail() {
      let cateringCartId = sessionStorage.getItem("cateringCartId");
      foodCartDetail({
        id: cateringCartId,
        tableSpuId: this.tableSpuId
      })
        .then(res => {
          if (Object.keys(res).length) {
            this.cartData = res;
            this.setCartList(res);
            this.category = "catering";
            //餐饮套餐的情况，详情里加上规格一栏
            this.columns.splice(1, 0, {
              title: "规格",
              key: "foodSetSubProduct",
              scopedSlots: { customRender: "foodSetSubProduct" }
            });
          }
        })
        .catch(() => {});
    },
    // 新版支付
    submitOrder() {
      //如果手动修改过单价，需要输入操作员的登录密码
      if (this.cartList.whetherChangePrice) {
        if (!this.isPwdPass) {
          this.pwdVisible = true;
          return false;
        }
        if (this.remark === "") {
          this.$message.warning("请输入改价原因");
          return false;
        }
      }
      // 如果选择了积分抵扣优惠，需输入支付密码
      if (this.showPayPassword) {
        if (this.memberPaymentPassword.length < 6) {
          this.$message.error("请输入会员支付密码");
          return false;
        }
      }
      // 订单折扣-优惠数据
      const orderDis = this.discountTextList[0] || {};
      // 低分抵扣-优惠数据
      const orderInte = this.integralTextList[0] || {};
      let postData = {
        // 购物车id
        id: this.cartList.id,
        // 如果是餐饮
        tableSpuId: this.tableSpuId,
        //（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
        merchantDiscountStr: orderDis.merchantDiscountStr || "",
        // 整单折扣id
        discountId: orderDis.discountId || "",
        // 优惠卷号
        couponNo: orderDis.couponNo || "",
        // 优惠活动id,优惠券id
        marketingId: orderDis.marketingId || "",
        // 配置类型：1立减 2折扣
        settingType: orderDis.settingType || "",
        // 整单折扣 使用 自定义金额
        customAmount: orderDis.customAmount || "",
        // 身份证号
        idCardNo: orderDis.idCardNo || "",
        // 会员手机号
        memberMobile: this.$store.state.common.member.mobile || "",
        // 营销活动手机号
        marketingMobile: orderDis.marketingMobile || "",
        // 会员id
        memberId: this.memberId,
        // 订单折扣抵扣多少钱
        merchantDiscountAmount: orderDis.amount || 0,
        // 会员余额抵扣多少钱
        memberBalancePrice: 0,
        // 会员积分抵扣多少钱
        integralPayPrice: orderInte.amount || 0,
        // 会员积分抵扣数量
        integralPayNum: orderInte.integralPayNum || 0,
        // 会员输入支付密码
        memberPaymentPassword: this.memberPaymentPassword
      };
      //如果有改价，则传入登录密码
      if (this.cartList.whetherChangePrice) {
        postData.password = this.loginPassword;
        postData.remark = this.remark;
      }
      discountInfoCalculate(postData)
        .then(res => {
          if (!res) {
            this.$message.error("确认订单失败，请重新尝试");
            return;
          }
          if (res.settlementPrice === 0) {
            //0元直接完成支付
            this.propParam.formatId = res.productCategoryId;
            this.propParam.orderNumber = res.orderId;
            //只为formatId添加参数
            this.$router.push({
              path: "./",
              query: { formatId: res.productCategoryId }
            });
            this.paySuccess();
          } else {
            this.$router.replace({
              path: "/newPay",
              query: {
                orderNumber: res.orderId, // 订单id
                totalFee: res.settlementPrice, // 总价
                formatId: res.productCategoryId //业态id
              }
            });
          }
          //创建订单成功后，就把购物车清空
          this.setCartList({});
          // 共享数据，给T2副屏用（副屏是另外一个VUE实例）
          localStorage.setItem("T2cartList", JSON.stringify({}));
        })
        .catch(err => {
          console.log("确认订单接口报错：", err);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    /**根据分组key，把数组做分组 */
    subgroup(arr, groupKey) {
      let output = [];
      if (!arr || !arr.length) {
        return [];
      }
      let groupNames = arr.map(item => {
        return item[groupKey];
      });
      //去重
      groupNames = [...new Set(groupNames)];
      groupNames.forEach(son => {
        let children = [];
        arr.forEach(item => {
          if (item[groupKey] === son) {
            children.push(item);
          }
        });
        output.push({
          groupName: son,
          children: children
        });
      });
      return output;
    },
    /**验证登录密码是否正确 */
    checkPwd() {
      if (!this.loginPassword || !this.remark) {
        this.$message.warning("请输入登录密码及改价原因");
        return;
      }
      //校验登录密码是否正确
      unlockTheScreen({ password: this.loginPassword })
        .then(res => {
          if (res) {
            this.isPwdPass = res;
            this.submitOrder();
          } else {
            this.$message.error("密码错误");
          }
        })
        .catch(() => {});
    },
    //打开打印规则弹窗
    editPrintRule(row) {
      this.printVisible = true;
      row.voucherUseType === 0
        ? (this.ticketText = "一人一票")
        : (this.ticketText = "一票多人");
      this.printBuyNum = row.buyNum;
      this.printProductId = row.productId;
      this.remainingNum = this.printBuyNum - this.totalNum;
      //回显打印规则
      cartChangePrintInfo({
        id: this.printCartId,
        productId: row.productId
      })
        .then(res => {
          if (res) {
            this.printForm.cartPrintType = res.cartPrintType;
            this.printForm.printNum = res.printNum;
            this.printForm.times = res.writeOffNum;
          }
        })
        .catch(() => {});
    },
    //计算核销次数之和及剩余核销次数
    calculateNum() {
      this.totalNum = 0;
      for (let i = 0; i < this.printForm.times.length; i++) {
        this.totalNum += this.printForm.times[i];
      }
      this.remainingNum = this.printBuyNum - this.totalNum;
    },
    //本次打印票数改变
    changeNum() {
      this.printForm.times = [];
      for (let i = 0; i < this.printForm.printNum; i++) {
        this.printForm.times.push(1);
      }
      this.calculateNum();
    },
    //核销次数失去焦点
    blurWriteOff() {
      this.calculateNum();
      if (this.totalNum > this.printBuyNum) {
        this.$message.warning(
          "请重新输入,总核销次数为" + this.printBuyNum + "次"
        );
      }
    },
    //确定保存按钮
    handleOk() {
      if (this.totalNum > this.printBuyNum) {
        this.$message.warning(
          "请重新输入,总核销次数为" + this.printBuyNum + "次"
        );
      } else {
        let submitData = {};
        if (this.printForm.cartPrintType === 3) {
          submitData = {
            id: this.printCartId,
            productId: this.printProductId,
            cartPrintType: this.printForm.cartPrintType,
            printNum: this.printForm.printNum,
            writeOffNum: this.printForm.times
          };
        } else {
          submitData = {
            id: this.printCartId,
            productId: this.printProductId,
            cartPrintType: this.printForm.cartPrintType
          };
        }
        cartChangePrint(submitData)
          .then(() => {
            this.printVisible = false;
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.main {
  background-color: #f5f5f5;
}

.footer-wrap {
  background: #fff;
  height: 260px;
  padding: 20px 26px 14px;
  .left {
    .left-text-wrap {
      height: 40px;
      line-height: 40px;
      .left-icon,
      .right-icon {
        height: 40px;
        width: 7px;
      }
      .left-icon {
        background: url("../../../assets/images/youhuiquan_02.gif") no-repeat
          1px;
      }
      .right-icon {
        background: url("../../../assets/images/youhuiquan_06.png") no-repeat -1px;
      }
      .text {
        border-top: 1px solid @red;
        border-bottom: 1px solid @red;
        padding: 0 10px;
      }
    }
  }
  .footer-btn-wrap {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.radio-style {
  display: block;
  height: 30px;
  line-height: 30px;
}

.print-num {
  width: 104px;
}
.print-time {
  width: 68px;
  margin-right: 16px;
}
</style>
