<template>
  <div>
    <a-modal
      :visible="vis"
      title="激活"
      width="880px"
      @cancel="close"
      :mask-closable="false"
    >
      <a-form-model
        :model="formData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="formData"
      >
        <h4 class="title-mid">游客信息</h4>
        <a-row>
          <!--非证件类型字段-->
          <template v-for="(temp, idx) in formData.visitorFieldList">
            <a-col span="8" :key="'v' + idx" v-if="temp.fieldType === 1">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入内容',
                    trigger: 'blur'
                  },
                  {
                    validator: checkText
                  }
                ]"
              >
                <a-input
                  v-inputfocus
                  :disabled="temp.disabled"
                  v-model="temp.fieldValue"
                  type="tel"
                  placeholder="请输入内容"
                  allow-clear
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 2">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请选择日期',
                    trigger: 'change'
                  }
                ]"
              >
                <a-date-picker
                  :disabled="temp.disabled"
                  valueFormat="YYYY-MM-DD"
                  v-model="temp.fieldValue"
                />
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 3">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: `请选择${temp.fieldName}`,
                    trigger: 'change'
                  }
                ]"
              >
                <a-select
                  :disabled="temp.disabled"
                  class="res-w"
                  v-model="temp.fieldValue"
                  placeholder="请选择"
                >
                  <a-select-option
                    :value="val"
                    v-for="(val, i) in temp.fieldOptions"
                    :key="'o' + i"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 4">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入内容',
                    trigger: 'blur'
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  v-model="temp.fieldValue"
                  placeholder="请输入内容"
                  allow-clear
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 5">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入手机号',
                    trigger: 'blur'
                  },
                  {
                    validator: checkPhone
                  }
                ]"
              >
                <a-input
                  v-inputfocus
                  v-model="temp.fieldValue"
                  type="tel"
                  placeholder="请输入内容"
                  allow-clear
                  @blur="blurChange(temp, `visitorFieldList.${idx}.fieldValue`)"
                >
                </a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 7">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请选择日期',
                    trigger: 'change'
                  }
                ]"
              >
                <a-date-picker
                  :disabled="temp.disabled"
                  valueFormat="YYYY-MM-DD"
                  v-model="temp.fieldValue"
                />
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 8">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请选择性别',
                    trigger: 'change'
                  }
                ]"
              >
                <a-select
                  :disabled="temp.disabled"
                  placeholder="请选择"
                  v-model="temp.fieldValue"
                >
                  <a-select-option
                    :value="val"
                    v-for="(val, i) in temp.fieldOptions"
                    :key="'s' + i"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 9">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请上传证件照',
                    trigger: 'change'
                  }
                ]"
              >
                <picture-input
                  class="m-x"
                  v-model="temp.fieldValue"
                  :small="true"
                ></picture-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 10">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入姓名',
                    trigger: 'blur'
                  },
                  {
                    validator: checkText
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  placeholder="请输入姓名"
                  allow-clear
                  v-model="temp.fieldValue"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 11">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入车牌号',
                    trigger: 'blur'
                  },
                  {
                    validator: checkText
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  placeholder="请输入车牌号"
                  allow-clear
                  v-model="temp.fieldValue"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 12">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入地址',
                    trigger: 'blur'
                  },
                  {
                    validator: checkText
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  placeholder="请输入地址"
                  allow-clear
                  v-model="temp.fieldValue"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 13">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: '请输入邮箱',
                    trigger: 'blur'
                  },
                  {
                    validator: checkEmail
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  placeholder="请输入邮箱"
                  allow-clear
                  v-model="temp.fieldValue"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 14">
              <a-form-model-item
                class="res-w"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                :rules="[
                  {
                    required: temp.required,
                    message: `请输入${temp.fieldName}`,
                    trigger: 'blur'
                  },
                  {
                    validator: checkText
                  }
                ]"
              >
                <a-input
                  :disabled="temp.disabled"
                  v-inputfocus
                  placeholder="请输入微信openId"
                  allow-clear
                  v-model="temp.fieldValue"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col span="8" :key="'v' + idx" v-else-if="temp.fieldType === 16">
              <a-form-model-item class="res-w" :label="temp.fieldName">
                <a-select
                  :disabled="temp.disabled"
                  placeholder="请选择国家"
                  v-model="temp.fieldValue"
                >
                  <a-select-option
                    :value="val"
                    v-for="(val, i) in temp.fieldOptions"
                    :key="'c' + i"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </template>
          <a-col span="8" v-if="formData.requiredVoucherNo">
            <a-form-model-item
              class="res-w"
              label="卡腕带"
              prop="voucherNo"
              :rules="[
                {
                  required: true,
                  message: '请输入卡腕带号',
                  trigger: 'blur'
                }
              ]"
            >
              <IcCardInput
                v-inputfocus
                v-model="formData.voucherNo"
                :allow-clear="false"
                placeholder="请输入卡/腕带编号"
              ></IcCardInput>
            </a-form-model-item>
          </a-col>
          <a-col span="8" v-if="formData.requiredPersonId">
            <a-form-model-item
              class="res-w"
              label="人脸"
              prop="voucherNo"
              :rules="[
                {
                  required: formData.requiredPersonTrue === 1 ? true : false,
                  message: '请录入人脸',
                  trigger: 'blur'
                }
              ]"
            >
              <face-input
                :small="true"
                v-model="formData.personId"
                @imgUrlChange="res => faceChange(res)"
                :echoimgurl="formData.personFaceUrl"
              ></face-input>
            </a-form-model-item>
          </a-col>
          <!--出游人信息证件类型归类-->
          <a-col v-if="formData.IdTypeList.length" span="24">
            <a-row :gutter="10">
              <a-col span="5" style="margin-top:4px;">
                <a-select v-model="formData.IdType">
                  <a-select-option
                    :value="val.fieldType"
                    v-for="val in formData.IdTypeList"
                    :key="val.fieldType"
                    >{{ val.fieldName }}</a-select-option
                  >
                </a-select>
              </a-col>
              <a-col span="8">
                <section
                  class="section"
                  v-for="(temp, idx) in formData.IdTypeList"
                  :key="'i' + idx"
                >
                  <a-form-model-item
                    class="res-w"
                    v-if="temp.fieldType === 6 && formData.IdType === 6"
                    :prop="`IdTypeList.${idx}.fieldValue`"
                    :rules="[
                      {
                        required: false,
                        message: '请输入身份证号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkIdCard
                      }
                    ]"
                  >
                    <IdCardInput
                      style="width:100%;"
                      v-inputfocus
                      v-model="temp.fieldValue"
                      @readCard="readCard($event)"
                      @blur="blurChange(temp, `IdTypeList.${idx}.fieldValue`)"
                    ></IdCardInput>
                  </a-form-model-item>
                  <a-form-model-item
                    class="res-w"
                    v-else-if="temp.fieldType === 21 && formData.IdType === 21"
                    :prop="`IdTypeList.${idx}.fieldValue`"
                    :rules="[
                      {
                        required: false,
                        message: '请输入身份证号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkIdCard
                      }
                    ]"
                  >
                    <IdCardInput
                      v-inputfocus
                      v-model="temp.fieldValue"
                    ></IdCardInput>
                  </a-form-model-item>
                  <a-form-model-item
                    class="res-w"
                    v-else-if="temp.fieldType === 17 && formData.IdType === 17"
                    :prop="`IdTypeList.${idx}.fieldValue`"
                    :rules="[
                      {
                        required: false,
                        message: '请输入证件号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkText
                      }
                    ]"
                  >
                    <a-input
                      :key="'i' + idx"
                      v-inputfocus
                      v-model="temp.fieldValue"
                      class="res-w"
                      placeholder="请输入证件号"
                      allow-clear
                    >
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item
                    class="res-w"
                    v-else-if="temp.fieldType === 18 && formData.IdType === 18"
                    :prop="`IdTypeList.${idx}.fieldValue`"
                    :rules="[
                      {
                        required: false,
                        message: '请输入证件号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkText
                      }
                    ]"
                  >
                    <a-input
                      v-inputfocus
                      v-model="temp.fieldValue"
                      placeholder="请输入证件号"
                      allow-clear
                    >
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item
                    class="res-w"
                    v-else-if="temp.fieldType === 19 && formData.IdType === 19"
                    :prop="`IdTypeList.${idx}.fieldValue`"
                    :rules="[
                      {
                        required: false,
                        message: '请输入证件号',
                        trigger: 'blur'
                      },
                      {
                        validator: checkText
                      }
                    ]"
                  >
                    <a-input
                      v-inputfocus
                      v-model="temp.fieldValue"
                      placeholder="请输入证件号"
                      allow-clear
                    >
                    </a-input>
                  </a-form-model-item>
                </section>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="flex-end" slot="footer">
        <a-button key="cancel" @click="close">取消</a-button>
        <a-button type="primary" @click="save('formData')" :loading="loading"
          >激活</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ticketActivite } from "@/api/order";
import { evidenceOneMemberVisitor } from "@/api/member";
import { checkPhone, checkIdCard, checkText, checkEmail } from "@/utils/global";
import PictureInput from "@/components/hardware/PictureInput.vue";
import IdCardInput from "@/components/hardware/IdCardInput";
import FaceInput from "@/components/hardware/FaceInput.vue";
import { fileUploadBase64 } from "../../../../api/upload";
import IcCardInput from "@/components/hardware/IcCardinput";
export default {
  name: "ActiveTicket",
  props: {
    fieldData: {
      type: Object,
      default() {
        return {};
      }
    },
    vis: {
      type: Boolean,
      default: false
    },
    auxiliaryId: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      checkPhone,
      checkIdCard,
      checkText,
      checkEmail,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      loading: false,
      formData: {
        voucherNo: "", // 卡腕带
        visitorFieldList: [], // 游客信息字段
        IdTypeList: [], // 出游人证件类型归类
        IdType: "", // 证件类型
        personId: "", // 人脸id
        personFaceUrl: "" // 人脸url
      }
    };
  },
  watch: {
    fieldData(newVal) {
      this.initialized(newVal);
    }
  },
  components: {
    PictureInput,
    IdCardInput,
    FaceInput,
    IcCardInput
  },
  methods: {
    // 初始数据
    initialized(val) {
      // 如果无需填写任何数据，则直接激活
      if (
        !val.visitorFieldList.length &&
        !val.requiredPersonId &&
        !val.requiredVoucherNo
      ) {
        this.close();
        ticketActivite({
          ticketNumber: this.auxiliaryId // 票号
        }).then(() => {
          this.$message.success("激活成功!");
          this.$emit("success");
        });
        return;
      }
      // 处理出游人信息字段，把身份证、护照、港澳通行证、台胞证统一合并为证件类型，只能填写一个
      const visitorFieldList1 = [];
      const visitorFieldList2 = [];
      let IdType = "";
      let formData = this.formData;
      val.visitorFieldList.forEach(temp => {
        if (
          temp.fieldType === 6 ||
          temp.fieldType === 17 ||
          temp.fieldType === 18 ||
          temp.fieldType === 19 ||
          temp.fieldType === 21
        ) {
          visitorFieldList1.push(temp);
        } else {
          visitorFieldList2.push(temp);
        }
      });
      formData.IdType =
        IdType || (visitorFieldList1[0] ? visitorFieldList1[0].fieldType : ""); // 证件类型
      formData.IdTypeList = visitorFieldList1; // 证件类型列表
      let arr1 = [];
      let arr2 = [];
      // 排序，证件照放置最后
      visitorFieldList2.forEach(item => {
        if (item.fieldType === 9) {
          arr2.push(item);
        } else arr1.push(item);
      });
      formData.visitorFieldList = arr1.concat(arr2); // 游客信息
      formData.requiredPersonId = val.requiredPersonId; // 是否开启人脸 1，开启，0不使用
      formData.requiredPersonTrue = val.requiredPersonTrue; // 人脸是否必填 1 必填 0非必填
      formData.requiredVoucherNo = val.requiredVoucherNo; // 是否开启卡腕带，（卡腕带必填）
    },
    // 人脸、证件识别涉及的方法区域
    readCard(res) {
      this.formData.visitorFieldList.forEach(item => {
        //填充姓名
        if (item.fieldType == 4) {
          item.fieldValue = res.name;
        } else if (item.fieldType == 7) {
          item.fieldValue = res.birth;
        } else if (item.fieldType == 8) {
          //性别(2男，1女)
          if (res.sex === "男") {
            item.fieldValue = "男";
          } else {
            item.fieldValue = "女";
          }
        } else if (item.fieldType == 9) {
          //证件照
          fileUploadBase64({ data: res.photo, ext: "png" })
            .then(value => {
              item.fieldValue = value.src;
            })
            .catch(err => {
              console.log(err);
              this.$message.error("图片上传接口vv错误");
            });
        } else if (item.fieldType == 12) {
          // 地址
          item.fieldValue = res.address;
        }
      });
    },
    blurChange(temp, key) {
      this.$refs["formData"].validateField(key, valid => {
        if (!valid) {
          evidenceOneMemberVisitor({
            merchantId: localStorage.getItem("merchantId"),
            evidence: temp.fieldValue
          }).then(res => {
            if (res && res.visitorFieldList !== null) {
              // 回显
              this.formData.visitorFieldList.forEach(item => {
                res.visitorFieldList.forEach(temp => {
                  if (item.fieldType === temp.fieldType) {
                    item.fieldValue = temp.fieldValue;
                    item.disabled = true;
                  }
                });
              });
              // 人脸回显
              if (this.formData.requiredPersonId && res.personId) {
                this.$set(this.formData, "personId", res.personId);
                this.$set(this.formData, "personFaceUrl", res.facePersonUrl);
              }
            }
          });
        }
      });
    },
    // 关闭弹窗
    close() {
      this.formData = {
        visitorFieldList: [], // 游客信息字段
        IdTypeList: [], // 出游人证件类型归类
        IdType: "", // 证件类型
        voucherNo: "",
        personId: "", // 人脸id
        personFaceUrl: "" // 人脸url
      };
      this.$emit("update:vis", false);
    },
    // 人脸录入
    faceChange(res) {
      this.formData.personFaceUrl = res;
      // this.blurChange(temp, key);
    },
    save(formData) {
      this.$refs[formData].validate(valid => {
        if (valid) {
          this.loading = true;
          let form = this.formData;
          let obj = {
            ticketNumber: this.auxiliaryId, // 票号
            userFieldInfoDTOS: [] // 游客信息集合
          };
          // 卡腕带开启
          if (form.requiredVoucherNo) {
            obj.voucherNo = form.voucherNo; // 卡腕带
          }
          // 人脸开启
          if (form.requiredPersonId) {
            obj.personId = form.personId; // 人脸id
            obj.personFaceUrl = form.personFaceUrl;
          }
          form.visitorFieldList.forEach(item => {
            // 游客信息字段列表
            if (item.fieldValue) {
              // 排除null为空的值不提交
              obj.userFieldInfoDTOS.push({
                fieldName: item.fieldName,
                fieldType: item.fieldType,
                fieldValue: item.fieldValue
              });
            }
          });
          // 如果有归类后的选项，过滤push到userFieldInfoDTOS数组
          form.IdTypeList.forEach(item => {
            if (form.IdType === item.fieldType && item.fieldValue) {
              obj.userFieldInfoDTOS.push({
                fieldName: item.fieldName,
                fieldType: item.fieldType,
                fieldValue: item.fieldValue
              });
            }
          });
          ticketActivite(obj)
            .then(() => {
              this.close();
              this.$message.success("激活成功!");
              this.$emit("success");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log("error");
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.res-w {
  width: 100%;
}
.title-mid {
  margin: 0 0 5px 0 !important;
}
.section {
  /deep/.ant-form-item-control-wrapper {
    width: 100%;
  }
}
</style>
