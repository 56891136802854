<!--
  @name: 下单需要填写出游人信息
  @author: fengyanlong
  @date:2021-04-09
-->

<template>
  <div class="order-info wrapper flex-start flex-col">
    <panel-head title="登记游客信息"></panel-head>
    <!-- 出游人信息 -->
    <div class="main flex-grow mt-2x">
      <!-- 填写购票人信息 -->
      <template
        v-if="
          purchaserMemberInfo.visitorFieldList &&
            purchaserMemberInfo.visitorFieldList.length
        "
      >
        <div class="sub-title">填写购票人信息</div>
        <div class="wrap">
          <div class="sub-content pt-2x pb-2x">
            <TouristInfoBaseForm
              :form-item-data="purchaserMemberInfo"
              ref="buyTicketsInfoForm"
            ></TouristInfoBaseForm>
          </div>
        </div>
      </template>

      <!-- 填写出游人信息 -->
      <template v-if="nowVisitor">
        <div class="sub-title">填写出游人信息</div>
        <div class="wrap">
          <div class="sub-content pt-2x pb-2x">
            <div class="sub-content-wrap bd round">
              <div class="top-title pl-2x pr-2x mb-2x">
                <span v-if="!nowVisitor.voucherUseType"
                  >{{ nowVisitor.productName }}（一个产品购买{{
                    nowVisitor.buyNum
                  }}件，一票一人使用）</span
                >
                <span v-else
                  >{{ nowVisitor.productName }}（一个产品购买{{
                    nowVisitor.buyNum
                  }}件，一票
                  <font v-if="nowVisitor.voucherAdultNum"
                    >{{ nowVisitor.voucherAdultNum }}成人
                  </font>
                  <font v-if="nowVisitor.voucherChildNum"
                    >{{ nowVisitor.voucherChildNum }}小孩
                  </font>
                  <font v-if="nowVisitor.voucherOldNum"
                    >{{ nowVisitor.voucherOldNum }}老人
                  </font>
                  使用）</span
                >
                <!--有座位信息后显示  日期时间 - 展厅-->
                <template v-if="nowVisitor.theaterSeatInfo">
                  {{ nowVisitor.playDate | date() }} -
                  {{ nowVisitor.appointmentBeginTime | date("hh:mm") }} -
                  {{ nowVisitor.appointmentEndTime | date("hh:mm") }}
                  {{ nowVisitor.placeName }}
                  <!-- <span>
                    {{
                      nowVisitor.theaterSeatInfo[
                        curPage - 1 == 1 ? 0 : curPage - 1
                      ].seatName
                    }}</span
                  > -->
                </template>
                <span>第 {{ curPage }} 项（ 共 {{ totalPage }} 项）</span>
              </div>
              <a-row v-if="nowVisitor.needActivate" class="pb-3x pl-3x">
                <a-col :span="3">门票激活：</a-col>
                <a-col>
                  <a-switch v-model="nowVisitor.activateNow"></a-switch
                  ><span class="pl-x">立即激活</span>
                </a-col>
              </a-row>
              <div
                v-for="(item, index) in nowVisitor.travelerList"
                :key="index"
              >
                <p class="title ml-2x">游客{{ index + 1 }}</p>
                <!-- 如果需要录人脸，录腕带，或者有出游人字段，则显示 -->
                <template
                  v-if="
                    item.visitorFieldList ||
                      item.requiredVoucherNo ||
                      item.requiredPersonId
                  "
                >
                  <TouristInfoBaseForm
                    :form-item-data="item"
                    :ref="'touristInfoBaseForm'"
                  ></TouristInfoBaseForm>
                </template>

                <!-- 不需要填写出游人信息 -->
                <template v-else>
                  <p class="m-4x gray">该门票无需填写游客资料</p>
                </template>
              </div>
              <div
                v-if="
                  !nowVisitor.needActivate &&
                    nowVisitor.travelerList.length === 0
                "
              >
                <p class="m-4x gray">该门票无需填写游客资料</p>
              </div>

              <div class="flex-center mt-2x mb-2x">
                <a-pagination
                  :total="totalPage"
                  :current="curPage"
                  :page-size="1"
                  :item-render="itemRender"
                  @change="pageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="footer">
      <a-button
        type="primary"
        ghost
        class="btn-lg"
        size="large"
        @click="goBack"
        shape="round"
        >返回</a-button
      >
      <a-button
        class="btn-lg ml-3x"
        type="primary"
        size="large"
        shape="round"
        show
        @click="onSubmit"
      >
        确认订单
      </a-button>
    </div>
  </div>
</template>

<script>
import TouristInfoBaseForm from "../../../components/TouristInfoBaseForm";
import { visitorFieldInfo } from "../../../api/cart";
import PanelHead from "../../../components/common/PanelHead.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "register-touristInfo",
  components: {
    TouristInfoBaseForm,
    PanelHead
  },
  data() {
    return {
      curPage: 1, //当前页面
      totalPage: 0, //总共页数
      isCanSubmitList: [] //看看是否能够提交
    };
  },
  computed: {
    ...mapGetters("order", ["purchaserMemberInfo", "visitorsList", "cartList"]),
    nowVisitor: function() {
      if (this.visitorsList.length > 0) {
        let target = this.visitorsList[this.curPage - 1];
        return target;
      } else {
        return null;
      }
    }
  },
  created() {
    this.totalPage = this.visitorsList.length;
    this.isCanSubmitList = this.visitorsList.map(item => {
      let bl = item.travelerList.some(temp => {
        return temp.visitorFieldList.some(val => val.required);
      });
      return !bl;
    });
  },
  destroyed() {},
  methods: {
    ...mapActions("order", ["setTouristInfo"]),
    /**页面交互操作 */
    goBack() {
      this.$router.back();
    },
    // 分页点击事件
    pageChange(cur) {
      let bl = this.checkForm();
      this.isCanSubmitList[this.curPage - 1] = bl;
      if (!bl) return this.$message.warning("验证未通过，请检查表单信息！");
      this.curPage = cur;
    },

    // 分页 自定义上下步
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return (
          <a class="font-l">
            <a-icon type="arrow-left" />
            上一项
          </a>
        );
      } else if (type === "next") {
        return (
          <a class="font-l">
            下一项
            <a-icon type="arrow-right" />
          </a>
        );
      }
      return originalElement;
    },
    // form 表单校验
    checkForm() {
      let isTwo = true;
      //处理身份证 6和证件照9 二选一必填 打补丁写死
      this.nowVisitor?.travelerList.forEach(ite => {
        if (ite.isTwo) {
          let fieldType6 = "";
          let fieldType9 = "";
          ite.visitorFieldList.forEach(temp => {
            if (temp.fieldType == 6) {
              fieldType6 = temp.fieldValue;
            }
            if (temp.fieldType == 9) {
              fieldType9 = temp.fieldValue;
            }
          });
          if (!fieldType6 && !fieldType9) {
            this.$message.warning("身份证和证件照必填一个！");
            isTwo = false;
          }
        }
      });
      if (!isTwo) {
        return false;
      }
      // 购票人表单填写校验
      let buyFlag =
        (this.purchaserMemberInfo.length &&
          this.$refs["buyTicketsInfoForm"].validateForm()) ||
        true;
      // 出游人表单填写校验
      const infoFlagArr =
        (this.$refs["touristInfoBaseForm"] &&
          this.$refs["touristInfoBaseForm"].map(item => item.validateForm())) ||
        [];
      if (buyFlag && infoFlagArr.every(item => item)) {
        return true;
      } else {
        return false;
      }
    },
    //确认订单
    onSubmit() {
      // if(this.curPage!==this.visitorsList.length)
      // if (!this.checkForm()) return
      //缓存填写的游客资料
      let { isCanSubmitList } = this;
      isCanSubmitList[this.curPage - 1] = this.checkForm();

      if (
        isCanSubmitList.some(
          (item, i) =>
            !item && this.$message.warning(`第${i + 1}页表单未验证通过，请检查`)
        )
      )
        return;
      this.setTouristInfo({
        visitorsList: this.visitorsList,
        purchaserMemberInfo: this.purchaserMemberInfo
      });
      let productTravelerList = this.visitorsList.map(item => {
        return {
          productId: item.productId,
          activateNow: item.activateNow,
          travelerList: item.travelerList
        };
      });
      let submitData = {
        id: this.cartList.id,
        purchaserMemberInfo: {
          visitorFieldList: this.purchaserMemberInfo.visitorFieldList
        },
        productTravelerList
      };
      visitorFieldInfo(submitData)
        .then(() => {
          this.$router.push("/home/sell/OrderInfo");
        })
        .catch(() => {});
    },
    setVoucherNo(val, item) {
      item.voucherNo = val;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.order-info {
  position: relative;
  // /deep/ .ant-pagination-item {
  //   display: none;
  // }
  .main {
    .sub-title {
      height: 40px;
      line-height: 40px;
      color: #fff;
      background-color: @primary;
      text-indent: 16px;
    }
    .wrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background: #fff;
    }
    .sub-content {
      width: 60%;
      min-width: 800px;
    }
    // 填写出游人信息
    .sub-content-wrap {
      .top-title {
        height: 40px;
        background-color: @bgGray;
        color: #333;
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-sizing: border-box;
      }
      .title {
        height: 22px;
        line-height: 22px;
        color: @primary;
        font-size: 16px;
        text-indent: 8px;
        border-left: 4px solid @primary;
      }
    }
  }
}
</style>
