<!-- 
整单优惠
-->
<template>
  <div class="flex-between">
    <div class="left flex-shrink" style="width:48%">
      <div class="flex-start title-min pv-2x pl-x">
        <div class="line"></div>
        <p>整单折扣</p>
      </div>
      <ul class="flex-start flex-wrap align-center pl-2x ul-wrap">
        <!-- o折扣item -->
        <li
          v-for="item in discountData"
          :key="item.id"
          :class="{ active: item.id == checkedId }"
          @click="setSelectId(item.id, item.settingType)"
          class="card flex-around"
        >
          {{ item.settingValue / 10 }}折
        </li>
        <!-- o自定义折扣按钮 -->
        <li
          class="card flex-center"
          :class="{ active: !!discount }"
          @click="customDiscount"
        >
          <div>
            <p>自定义折扣</p>
            <p v-show="discount">{{ discount }}折</p>
          </div>
        </li>
      </ul>
      <div class="title-min flex-start pv-2x pl-x">
        <div class="line"></div>
        <p>整单减价</p>
      </div>
      <ul class="flex-start pl-2x align-center flex-wrap ul-wrap">
        <!-- o减价item -->
        <li
          v-for="item in reduceData"
          :key="item.id"
          class="card flex-center"
          :class="{ active: item.id == checkedId }"
          @click="setSelectId(item.id, item.settingType)"
        >
          减 {{ item.settingValue / 100 }}元
        </li>
        <!-- o自定义减价按钮 -->
        <li
          class="card flex-center"
          :class="{ active: !!reduce }"
          @click="customReduce"
        >
          <div>
            <p>自定义减价</p>
            <p v-show="reduce">减{{ reduce }}元</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 右侧键盘 -->
    <div class="right flex-shrink">
      <!-- 自定义折扣键盘 -->
      <keyboard-pro
        v-show="visible === 1"
        type="2"
        v-model="discount"
        @ok="submitDiscount"
      >
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              :max-length="3"
              v-model="discount"
              @pressEnter="confirm()"
              allow-clear
              class="prefix-input"
              size="large"
              placeholder="请输入折扣"
            >
              <span slot="prefix" class="primary">整单折扣</span>
              <span slot="suffix" class="primary">折</span>
            </a-input>
          </a-form-model>
        </template>
      </keyboard-pro>
      <!-- 自定义整单减价键盘 -->
      <keyboard-pro
        v-show="visible === 2"
        type="2"
        v-model="reduce"
        @ok="submitReduce"
      >
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input
              :max-length="8"
              v-model="reduce"
              @pressEnter="confirm()"
              allow-clear
              class="prefix-input"
              size="large"
              placeholder="请输入金额"
            >
              <span slot="prefix" class="primary">整单减价</span>
              <span slot="suffix" class="primary">元</span>
            </a-input>
          </a-form-model>
        </template>
      </keyboard-pro>
    </div>
  </div>
</template>

<script>
import {
  windowDiscountFindList,
  windowDiscountDiscountedPrice
} from "../../../../api/marketing";
import KeyboardPro from "../../../../components/common/KeyboardPro.vue";
import { fix2Reg } from "../../../../utils/global";
import { mapActions } from "vuex";

export default {
  name: "order-zdzk",
  components: {
    KeyboardPro
  },
  props: {
    orderData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      checkedId: "", //选中的折扣id
      discountData: [], //折扣数据
      reduceData: [], //整单减价数据
      discount: "", //自定义折扣
      reduce: "", //自定义减价
      visible: "" // 1显示折扣键盘，2显示减价键盘
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    ...mapActions("order", [
      "removeOrderDiscountTextList",
      "setOrderDiscountTextList"
    ]),
    getList() {
      windowDiscountFindList()
        .then(res => {
          if (res) {
            this.discountData = res.filter(item => {
              return item.settingType === 2;
            });
            this.reduceData = res.filter(item => {
              return item.settingType === 1;
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //记录选择的折扣id
    setSelectId(id, type) {
      this.discount = ""; //清空自定义折扣
      this.reduce = ""; //清空自定义减价
      if (id === this.checkedId) {
        this.checkedId = "";
        this.cancleDiscount();
      } else {
        this.reset();
        this.doSubmit(id, type);
      }
    },
    // 自定义折扣
    customDiscount() {
      if (this.discount) this.cancleDiscount();
      else this.visible = 1;
    },
    customReduce() {
      if (this.reduce) this.cancleDiscount();
      else this.visible = 2;
    },
    //取消折扣，通知上层
    cancleDiscount() {
      this.reset();
    },
    //自定义折扣(type:2)
    submitDiscount() {
      this.checkedId = ""; //先清空选中的折扣
      this.reduce = ""; //清空自定义减价
      let reg = /^[1-9]{1}$|^[0-9]{1}\.[0-9]{1}$|^0$/;
      if (!reg.test(this.discount)) {
        this.$message.warning("请输入正确的折扣格式，整数或者1位小数");
      } else {
        this.visible = "";
        this.doSubmit(null, 2);
      }
    },
    //自定义整单减价(type:1)
    submitReduce() {
      this.checkedId = ""; //先清空选中的折扣
      this.discount = ""; //清空自定义折扣
      if (!fix2Reg.test(this.reduce)) {
        this.$message.warning("金钱格式不正确，请输入数字，最多支持2位小数");
      } else {
        this.visible = "";
        this.doSubmit(null, 1);
      }
    },
    //提交整单折扣计算金额优惠
    doSubmit(businessId, type) {
      //过滤下单产品的列表
      let productItems = [];
      this.orderData.productItemList.forEach(item => {
        let { addPurchaseProductItemList } = item;
        productItems.push({
          productId: item.productId,
          salePrice: item.sellingPrice,
          count: item.buyNum,
          merchantId: item.merchantId
        });
        //餐饮有规格需传子产品信息
        if (addPurchaseProductItemList && addPurchaseProductItemList.length) {
          addPurchaseProductItemList.forEach(list => {
            productItems.push({
              merchantId: item.merchantId,
              count: item.buyNum,
              productId: list.productId,
              salePrice: list.sellingPrice
            });
          });
        }
      });
      let customAmount = 0;
      if (type == 1) {
        //立减的数值
        customAmount = Math.round(this.reduce * 100);
      } else {
        //折扣的数值
        customAmount = Math.round(this.discount * 10);
      }
      let postData = {
        orderChannel: "6",
        preferentialList: [
          {
            preferentialType: "A",
            businessId: businessId,
            customAmount: businessId ? "" : customAmount,
            settingType: businessId ? "" : type
          }
        ],
        productItems
      };
      windowDiscountDiscountedPrice(postData)
        .then(res => {
          if (res.amount || res.amount === 0) {
            // 计算成功,显示选中状态
            this.checkedId = businessId;
            // 抛出给父元素，点击确定在执行，存储优惠到vuex
            this.$emit("triggerCalc", {
              name: type == 1 ? "整单减价" : "整单折扣",
              amount: res.amount,
              merchantDiscountStr: "A",
              discountId: businessId,
              customAmount: businessId ? "" : customAmount,
              settingType: businessId ? "" : type
            });
          }
        })
        .catch(() => {
          //如果计算失败了，把自定义折扣和自定义减单清空
          this.discount = "";
          this.reduce = "";
        });
    },
    //重置数据
    reset() {
      this.checkedId = "";
      this.discount = ""; //自定义折扣
      this.reduce = ""; //自定义减价
      this.visible = "";
      this.removeOrderDiscountTextList("orderDiscount");
      this.$emit("triggerDel");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.title-min {
  align-items: center;
  font-size: 16px;
  .line {
    margin-right: 12px;
    width: 6px;
    height: 28px;
    background: #4182fc;
    border-radius: 3px;
  }
}
.ul-wrap {
  max-height: 116px;
  overflow-y: auto;
  overflow-x: hidden;
}
/*卡片*/
.card {
  flex-shrink: 0;
  align-items: center;
  width: 140px;
  height: 56px;
  margin: 0 19px 19px 0;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
  }
  &.active {
    border: none;
    box-shadow: 3px 0 9px 0 rgba(116, 116, 116, 0.26);
    border-radius: 10px;
    background: linear-gradient(181deg, #44b8fb, #4d8afb);
    color: #fff;
  }
}
/*键盘  */
.keyword {
  width: 540px;
  margin: 32px auto;
}
</style>
