<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="门票操作"></panel-head>

    <section class="main mt-2x">
      <!--查询门票-->
      <keyboard-pro
        v-if="tableData.length === 0"
        @ok="search"
        v-model="numbers"
        type="3"
        class="keyword"
      >
        <template v-slot:input="{ confirm }">
          <a-form-model autocomplete="off">
            <a-input-search
              class=" mb-2x"
              placeholder="订单号/票号/手机号/身份证/会员号/卡或腕带号"
              v-model="numbers"
              allow-clear
              size="large"
              @pressEnter="confirm()"
              @focus="listen"
              @blur="stop"
            >
              <a-icon class="primary" slot="prefix" type="search" />
              <a-button slot="enterButton">
                <a-icon type="scan" class="primary" />
              </a-button>
            </a-input-search>
          </a-form-model>
        </template>
      </keyboard-pro>

      <template v-else>
        <!--门票table-->
        <ticket-list-table
          :table-data="tableData"
          :permission="'appHomeSellTicketAction'"
        ></ticket-list-table>
      </template>
    </section>

    <footer class="footer" v-if="tableData.length">
      <a-button
        type="primary"
        size="large"
        shape="round"
        class="btn-lg"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
    </footer>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "../../../components/common/KeyboardPro";
import TicketListTable from "./components/TicketListTable";
import { orderTicketOperation } from "../../../api/search";
export default {
  name: "ticketOperate",
  components: {
    PanelHead: PanelHead,
    KeyboardPro,
    TicketListTable
  },
  data() {
    return {
      numbers: "",
      tableData: [],
      ready: false
    };
  },
  methods: {
    search() {
      if (this.numbers) {
        orderTicketOperation({
          keyword: this.numbers
        })
          .then(res => {
            if (res.length) {
              this.tableData = res;
            } else {
              this.$message.warning("没有查询到相应门票");
            }
          })
          .catch(function() {});
      } else {
        this.$message.error("请输入查询条件");
      }
    },

    // 身份证读卡
    readIdCard(res) {
      this.cardInfo = JSON.parse(res);
      if (this.ready) {
        this.numbers = this.cardInfo.id;
        this.search();
      }
    },
    // 身份证读卡
    listen() {
      window.readIdCard = this.readIdCard;
      this.ready = true;
    },
    // 身份证读卡
    stop() {
      this.ready = false;
    }
  }
};
</script>

<style lang="less" scoped>
.keyword {
  margin-top: 60px;
}
</style>
