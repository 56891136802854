<template>
  <div class="ph-2x pb-2x">
    <!--搜索-->
    <header class="mb-2x">
      <span>套餐名称</span>
      <a-input
        class="mh-2x"
        style="width: 200px"
        v-model="searchKey"
        placeholder="请输入套餐名称"
        clearable
      ></a-input>
      <a-button type="primary" @click="search">查询</a-button>
    </header>

    <!--表格-->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      rowKey="id"
    >
      <span class="primary" slot="statusText" slot-scope="row">{{
        row.statusText
      }}</span>
      <template slot="canUseNum" slot-scope="row">
        <!-- canUseTimesType:使用次数，0限制，1不限 -->
        <span v-if="row.canUseNum === -1">不限</span>
        <span v-else>{{ row.canUseNum }}</span>
      </template>
      <template slot="remainUseNum" slot-scope="row">
        <span v-if="row.remainUseNum === -1">不限</span>
        <span v-else>{{ row.remainUseNum }}</span>
      </template>
      <template slot="action" slot-scope="row">
        <a-button type="link" @click="showDetail(row)">详情</a-button>
      </template>
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x" v-show="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
      />
    </footer>

    <!--详情弹窗-->
    <ticket-product-detail
      :visible.sync="visible"
      :id="detailId"
      :productId="productId"
    ></ticket-product-detail>
  </div>
</template>

<script>
import TicketProductDetail from "./TicketProductDetail";
import { findTicketPlayItemPackagePage } from "../../../../api/order";

export default {
  name: "ItemPack",
  components: { TicketProductDetail },
  data() {
    return {
      searchKey: "",
      tableData: [],
      total: 0,
      page: 1,
      size: 10,
      visible: false,
      detailId: "",
      productId: "", //该项目的产品id
      columns: [
        {
          title: "套餐名称",
          dataIndex: "productName",
          key: "productName",
          width: 200
        },
        {
          title: "产品来源",
          dataIndex: "orderSourceTypeText",
          key: "orderSourceTypeText",
          width: 150
        },
        {
          title: "产品状态",
          scopedSlots: { customRender: "statusText" },
          key: "statusText",
          width: 100
        },
        {
          title: "剩余可玩次数",
          scopedSlots: { customRender: "remainUseNum" },
          key: "remainUseNum",
          width: 150
        },
        {
          title: "套餐可使用次数",
          scopedSlots: { customRender: "canUseNum" },
          key: "canUseNum",
          width: 150
        },
        {
          title: "套餐已使用次数",
          dataIndex: "useNum",
          key: "useNum",
          width: 150
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 100
        }
      ]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 加载数据
    loadData() {
      findTicketPlayItemPackagePage({
        page: this.page,
        size: this.size,
        id: this.$route.query.id,
        productName: this.searchKey
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 展示详情
    showDetail(row) {
      this.detailId = row.id;
      this.productId = row.productId;
      this.visible = true;
      // orderPackageusableProject({
      //   id: row.productId,
      //   orderDetailId: row.id,
      //   page: 1,
      //   size: 100
      // })
      //   .then(({ records }) => {
      //     this.playData = records;
      //   })
      //   .catch(() => {});
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
