<!--新下单产品列表组件-->
<template>
  <div>
    <!--门票列表-->
    <section class="section ticket-box" :class="{ less: list.length < 4 }">
      <a-row class="ticket-list" v-if="list.length" :gutter="16">
        <a-col :xxl="6" :xl="8" v-for="(item, index) in list" :key="item.id">
          <div class="ticket-list-item flex-start align-center">
            <img
              v-show="getSettings.IsShowProductImg"
              class="img flex-shrink"
              :src="item.skuImages"
              alt=""
            />
            <div class="right-content flex-grow flex-between flex-col">
              <p class="bold text-2 font-n res" :title="item.name">
                {{ item.name }}
              </p>
              <div>
                <a-tag class="font-xs" color="blue">{{
                  item.merchantName
                }}</a-tag>
              </div>
              <div class="flex-between">
                <div class="flex-start align-center">
                  <!--whetherChangePrice： 客户端销售改价: 0不允许 1允许-->
                  <!-- billingSettings 游客人工计次可改价 -->
                  <!-- canCustomPrice 新版按钮允许改价权限会跟旧版的角色-高级权限-窗口允许改价混淆，
                        2022-07-11去除该条件，新旧版都只用产品设置的whetherChangePrice，
                        权限由接口处理当前角色是否有改价权限
                  -->
                  <template
                    v-if="item.whetherChangePrice || item.billingSettings === 3"
                  >
                    <b class="price">¥</b>
                    <input
                      v-inputfocus
                      v-model="item.sellingPrice"
                      @focus="e => customPriceFocus(e, index)"
                      type="text"
                      class="custom-price price"
                    />
                    <a-button
                      v-show="showSureIndex === index"
                      type="primary"
                      size="small"
                      @click="surePrice(item)"
                      >确定</a-button
                    >
                  </template>

                  <span class="price" v-else>¥ {{ item.sellingPrice }}</span>
                  <del class="font-s disabled ml-1x"
                    >¥ {{ item.marketPrice / 100 }}</del
                  >
                </div>
                <div class="flex-start">
                  <a-icon
                    type="minus-circle"
                    class="minus"
                    v-show="item.buyNum > 0"
                    @click="updateCart(item, item.buyNum - 1)"
                  />
                  <a-icon
                    v-show="item.buyNum === 0"
                    type="minus-circle"
                    class="minus-disabled"
                  />
                  <input
                    v-inputfocus
                    :value="item.buyNum"
                    class="input-number"
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    @blur="e => inputBlur(e, item)"
                  />
                  <a-icon
                    type="plus-circle"
                    theme="filled"
                    class="plus"
                    @click="updateCart(item, item.buyNum + 1)"
                  />
                </div>
              </div>

              <!--快捷键-->
              <div class="fast-key flex-start">
                <div class="del" @click="onClickSetting(item, index)">
                  <i class="iconfont iconhuabanfuben mr-x"></i>
                  <span>设置</span>
                </div>
                <div
                  v-if="shortcutKey && index < 6"
                  class="buy ml-1x"
                  @click="setFastKey('buy', index)"
                >
                  按{{ keyCode[getSettings.TicketOrderFastKey.buy[index]] }}购买
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>

      <a-empty v-else :image="simpleImage" :style="{ height: '224px' }" />
    </section>

    <!--设置快捷键-->
    <a-modal
      title="请直接在键盘上输入新的快捷键"
      :visible="visibleFastKey"
      width="300px"
      @ok="fastKeyOk"
      @cancel="fastKeyCancel"
    >
      <h3 class="text-c">{{ currentKey }}</h3>
    </a-modal>

    <!-- 产品有效期设置弹窗 -->
    <a-modal
      class="modal"
      v-model="isShowtSettingModal"
      title="设置"
      :width="600"
      :maskClosable="false"
    >
      <section style="max-height:300px;" class="modal-item">
        <span class="modal-item-title">产品有效期：</span>
        <div class="modal-item-content">
          <div
            v-for="(item, idx) in showProductValiditySetting"
            :key="idx"
            class="content flex-between align-center pv-2x"
          >
            <div class="product-setting-name">
              {{ item.ruleName }}
            </div>
            <div class="flex-between align-center">
              <template v-if="item.isDefault">
                <span class="primary not-allow">已设默认</span>
              </template>
              <template v-else>
                <span class="pointer" @click="changeProductValidDefault(idx)"
                  >设为默认</span
                >
              </template>
            </div>
          </div>
        </div>
      </section>

      <span class="separate"></span>

      <section class="modal-item">
        <span class="modal-item-title margin-left">取票操作：</span>

        <div class="modal-item-content">
          <div
            class="content flex-between align-center pb-2x"
            v-for="(item, index) in takeTicketSettingList"
            :key="index"
          >
            <div class="product-setting-name">{{ item.name }}</div>
            <div class="flex-between align-center">
              <span
                v-if="item.value === currentTakeTicketSetting"
                class="primary not-allow"
                >已设默认</span
              >
              <span
                v-else
                class="pointer"
                @click="changeTakeTicketDefault(index)"
                >设为默认</span
              >
            </div>
          </div>
        </div>
      </section>

      <footer slot="footer" class="flex-center">
        <a-button
          type="primary"
          size="large"
          shape="round"
          style="width: 200px"
          @click="onClickCancelProductSetting"
          >确定</a-button
        >
      </footer>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import keyCode from "../../utils/keycode";
import { fix2Reg, integerReg } from "../../utils/global";
import { commonChangePrice } from "../../api/cart";
import { Empty } from "ant-design-vue";
import {
  productFrontCombinationProduct,
  productFrontSilkyTicket,
  productFrontAmusement
} from "../../api/search";
export default {
  name: "ProductList",

  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      keyCode,
      way: "buy", // 快捷键方法
      visibleFastKey: false,
      currentKey: "", // 当前快捷键key
      fastKeyI: 0, // 当前快捷键下标
      currentCode: "",

      params: {
        subScenicId: "", // 子景区id
        playDate: "", // 游玩日期
        name: "", // 产品名称
        categoryId: "", // 分类id
        appointmentRules: this.appointmentRules, // 分时预约规则id
        timeId: this.timeId // 时段id
      },
      page: 1,
      size: 99,
      list: [], // 产品列表
      // 购物车数据
      cartId: 0, // 购物车id
      // cart: {
      //   id: 0, // 购物车id
      //   cartFlagPO: {},
      //   detailProductList: [], // 需要填写出游人的产品
      //   totalProductList: [], // 不需要填写出游人的产品
      //   productList: []
      // }
      isShowtSettingModal: false, // 是否显示设置窗口
      setProductId: "", // 设置产品有效期产品id
      currentProductIndex: null, //当前设置的产品索引
      showProductValiditySetting: [], // 单个产品的产品有效期设置
      currentTakeTicketSetting: 0, //当前产品取票操作的值
      takeTicketSettingList: [
        {
          name: "无操作",
          value: 0
        },
        {
          name: "取票后自动核销",
          value: 1
        }
      ],

      appNewHomeProductTakeTicketDefault: 0, // 默认取票操作

      tempValue: "",
      showSureIndex: "",
      // 系统分类
      apis: {
        // 票务
        appNewHome: productFrontSilkyTicket,
        // 游乐
        appPlayNew: productFrontAmusement,
        // 组合
        appCombinationNew: productFrontCombinationProduct
      }
    };
  },

  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings",
      shortcutKey: "setting/getshortcutKey",
      btnPermission: "common/getCurrentBtnPermission"
    }),
    // 是否包含自定义金额菜单按钮，正则支持票务、游乐、组合不同的key
    canCustomPrice() {
      return this.btnPermission.some(item =>
        /app[A-Z]+CustomMoney/gi.test(item)
      );
    },
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    },
    // 当前业态下的自定义分类
    currentApi() {
      return this.apis[this.currentTopMenuKey];
    }
  },

  created() {
    if (this.getSettings.TicketOrderFastKey) {
      this.fastKey = this.getSettings.TicketOrderFastKey;
    }
  },

  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },

  //关闭键盘监听
  beforeDestroy() {
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },

  methods: {
    loadData(params) {
      // 如果是先选时段再选产品：时段库存为0或时段不可选，则清空产品列表
      if (params.playDate && !params.timeId) {
        this.list = [];
      } else {
        this.params = params;
        this.currentApi({
          ...params,
          page: this.page,
          size: this.size
        })
          .then(({ records }) => {
            records.forEach(item => {
              item.defaultValidityRule = item.validDefault || 0; // 初始化
              item.sellingPrice = item.sellingPrice / 100;
              item.oldPrice = item.sellingPrice;
              item.buyNum = 0;
            });
            this.list = records;
            // 查找产品的有效期
            this.findProductValidity();
          })
          .catch(() => {});
      }
    },

    // 查找产品的产品有效期和取票操作
    findProductValidity() {
      const appNewHomeProductTakeTicket = this.getSettings.ProductTakeTicket;
      this.list.forEach(item => {
        // 重置默认产品有效期 下标从 0开始
        item.defaultValidityRule = item.validDefault - 1;
        let productRuleObj = this.getSettings.ProductValidityRule.find(
          val => val.setProductId === item.id
        );
        if (productRuleObj) {
          // 窗口设置为最后一条规则，后台删了最后一条规则，就重新默认为后台设置的默认
          if (productRuleObj.idx < item.validRulesList.length) {
            item.defaultValidityRule = productRuleObj.idx;
          } else {
            item.defaultValidityRule = 0;
          }
        }
        // 指定有效期规则
        else if (item.validDefault === 3) {
          item.defaultValidityRule = 2 + item.defaultRuleIndex;
        }
        // 默认取票无操作
        item.writeOffRightNow = 0;
        // 获取单个产品取票操作
        if (appNewHomeProductTakeTicket.length) {
          appNewHomeProductTakeTicket.forEach(tval => {
            if (tval.setProductId === item.id) {
              item.writeOffRightNow = tval.type;
            }
          });
        }
      });
    },

    // 产品设置
    onClickSetting(item, index) {
      if (this.cartId) {
        this.$message.warning("请清空购物车再进行产品设置");
        return;
      }
      this.setProductId = item.id;
      this.currentProductIndex = index;

      this.getProductValid(item);
      this.getTakeTicketSetting(item);

      this.isShowtSettingModal = true;
    },

    //获取产品有效期
    getProductValid(item) {
      let validRulesList = item.validRulesList || [];
      if (validRulesList.length) {
        // 获取产品设置的产品有效期
        let productRuleObj = this.getSettings.ProductValidityRule.find(
          val => val.setProductId === item.id
        );
        // console.log(item.defaultValidityRule);
        // 初始化默认产品有效期
        validRulesList.forEach(item => {
          this.$set(item, "isDefault", false)
        });
        // 有设置过默认产品有效期就拿默认的
        if (productRuleObj) {
          // 当窗口自己设置的默认有效期，后台删了有效期的一些配置就拿默认的
          let idx =
            productRuleObj.idx < validRulesList.length ? productRuleObj.idx : 0;
          validRulesList[idx].isDefault = true;
        } else {
          // 没有就拿系统设置的
          let idx =
            item.defaultValidityRule < validRulesList.length
              ? item.defaultValidityRule
              : 0;
          validRulesList[idx].isDefault = true;
        }
      }

      this.showProductValiditySetting = validRulesList;
    },

    //获取单个产品的取票操作
    getTakeTicketSetting(item) {
      //默认是无操作
      item.writeOffRightNow = 0;

      const takeTicketSettingStore = this.getSettings.ProductTakeTicket;
      if (takeTicketSettingStore.length) {
        takeTicketSettingStore.forEach(storeItem => {
          if (storeItem.setProductId === item.id) {
            item.writeOffRightNow = storeItem.type;
          }
        });
      }

      this.currentTakeTicketSetting = item.writeOffRightNow;
    },

    //点击退出产品设置
    onClickCancelProductSetting() {
      this.isShowtSettingModal = false;
      //还原默认值
      this.currentTakeTicketSetting = 0;
      this.currentProductIndex = null;
      this.showProductValiditySetting = [];
    },

    //改变产品有效期
    changeProductValidDefault(index) {
      this.showProductValiditySetting.forEach((item, itemIndex) => {
        item.isDefault = index === itemIndex;
      });
      this.saveProductSetting()
    },

    //改变取票操作
    changeTakeTicketDefault(index) {
      this.currentTakeTicketSetting =  this.takeTicketSettingList.[index]?.value;
      this.saveProductSetting()
    },

    //保存产品设置
    saveProductSetting() {
      //公共变量赋值
      let productId = this.setProductId;
      // let productIndex = this.currentProductIndex;
      let currentTopMenuKey = this.currentTopMenuKey;
      let currentTakeTicketSetting = this.currentTakeTicketSetting;
      // 获取默认选中的下标
      let defaultValidIndex = this.showProductValiditySetting.findIndex(
        item => {
          return item.isDefault === true;
        }
      );

      //保存产品有效期设置
      let validValue = {
        setProductId: productId,
        idx: defaultValidIndex
      };
      // 查询该产品是否设置有效期
      let productValidityRule = this.getSettings.ProductValidityRule;
      let isSetValid = false;
      if (productValidityRule.length) {
        productValidityRule.forEach(item => {
          // 设置过的产品就编辑产品有效期
          if (item.setProductId === productId) {
            item.idx = defaultValidIndex;
            isSetValid = true;
          }
        });
      }

      // 没设置过的产品就添加
      if (!isSetValid) {
        productValidityRule.push(validValue);
      }

      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "ProductValidityRule"}`,
        JSON.stringify(productValidityRule)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "ProductValidityRule"}`,
        productValidityRule
      );

      //保存取票操作
      let takeTicketSettingStore = this.getSettings.ProductTakeTicket;
      let isSetTicket = false;

      if (takeTicketSettingStore.length) {
        takeTicketSettingStore.forEach(item => {
          // 设置过的就编辑取票操作
          if (item.setProductId === productId) {
            item.type = currentTakeTicketSetting;
            isSetTicket = true;
          }
        });
      }

      let takeTicketSetting = {
        setProductId: productId,
        type: currentTakeTicketSetting
      };

      // 没设置过的就添加取票操作
      if (!isSetTicket) {
        takeTicketSettingStore.push(takeTicketSetting);
      }

      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "ProductTakeTicket"}`,
        JSON.stringify(takeTicketSettingStore)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "ProductTakeTicket"}`,
        takeTicketSettingStore
      );
      // 更新该产品默认有效期到产品列表
      this.findProductValidity();
    },

    // 监听快捷键
    windowKeyDown(e) {
      if (!this.shortcutKey) return; // 未开启快捷键
      if (this.$store.getters["system/getSYSTEM_INPUT_IS_DEFAULT_DOWN"]) return; // 输入框获取焦点快捷键要失效
      // 键盘按下，处理相关事件
      let ev = window.event || e;
      let code = ev.keyCode || ev.which;
      if (ev.preventDefault) {
        ev.preventDefault();
      } else {
        ev.keyCode = 0;
        ev.returnValue = false;
      }
      this.code = this.keyCode[code];

      // 设置快捷键
      if (this.visibleFastKey) {
        if (
          this.getSettings.TicketOrderFastKey[this.way][this.fastKeyI] === code
        )
          return;
        if (
          this.getSettings.TicketOrderFastKey.buy.includes(code) ||
          this.getSettings.TicketOrderFastKey.del.includes(code) ||
          !this.keyCode[code]
        ) {
          this.$message.warning("该快捷键已被占用，请更换其它快捷键");
        } else {
          this.currentKey = this.keyCode[code];
          this.currentCode = code;
        }
      }
      // 快捷购买
      else {
        let buyI = this.getSettings.TicketOrderFastKey.buy.findIndex(
          item => item === code
        );
        let delI = this.getSettings.TicketOrderFastKey.del.findIndex(
          item => item === code
        );
        // 购买产品
        if (buyI !== -1) {
          const item = this.list[buyI];
          if (item) {
            this.updateCart(item, item.buyNum + 1);
          }
        }
        // 删除产品
        else if (delI !== -1) {
          const item = this.list[delI];
          if (item) {
            this.minus(item);
          }
        }
      }
    },
    // 设置快捷键
    setFastKey(way, i) {
      this.way = way;
      this.fastKeyI = i;
      this.currentKey = this.keyCode[
        this.getSettings.TicketOrderFastKey[way][i]
      ];
      this.currentCode = this.getSettings.TicketOrderFastKey[way][i];
      this.visibleFastKey = true;
    },
    // 设置快捷键确定
    fastKeyOk() {
      this.fastKey[this.way][this.fastKeyI] = this.currentCode;
      let currentTopMenuKey = this.currentTopMenuKey;
      this.fastKeyCancel();
      // 存缓存
      localStorage.setItem(
        `${currentTopMenuKey + "TicketOrderFastKey"}`,
        JSON.stringify(this.getSettings.TicketOrderFastKey)
      );
      this.$store.commit(
        `setting/set${currentTopMenuKey + "TicketOrderFastKey"}`,
        this.getSettings.TicketOrderFastKey
      );
    },
    fastKeyCancel() {
      this.visibleFastKey = false;
    },

    // 减少1购物车
    minus(item) {
      if (item.buyNum > 0) {
        this.updateCart(item, item.buyNum - 1);
      }
    },

    // 输入框失去焦点
    inputBlur(e, item) {
      const val = e.target.value;
      if (integerReg.test(val)) {
        this.updateCart(item, val);
      }
    },

    // 获取产品有效期规则
    checkProductRule(item) {
      // 产品有效期
      let defaultType = 0; // 不传就是拿产品默认的，1固定有效期，2预约当天生效，3指定有效期规则
      let defaultRuleIndex = ""; // 当默认类型是指定有效期规则时，有效期规则的下标
      if (item.defaultValidityRule - 2 > 0) {
        defaultType = 3;
        defaultRuleIndex = item.defaultValidityRule - 2;
      } else {
        defaultType = item.defaultValidityRule + 1;
      }
      return {
        writeOffRightNow: item.writeOffRightNow, // 取票操作
        validType: 1, //是否按照前端传的有效期来计算 1按照 0不按照
        defaultType,
        defaultRuleIndex
      };
    },

    customPriceFocus(e, index) {
      this.tempValue = e.target.value;
      this.showSureIndex = index;
    },

    // 确认修改价格
    surePrice(item) {
      if (!item.buyNum) {
        this.$message.error("请先加购该产品");
        return;
      }
      if (!fix2Reg.test(item.sellingPrice)) {
        this.$message.error("请输入正确的金额");
        item.sellingPrice = this.tempValue;
        return;
      }
      commonChangePrice({
        cartId: this.cartId,
        productId: item.id,
        changePrice: Math.round(item.sellingPrice * 10000) / 100
      })
        .then(res => {
          this.$emit("change-cart", res);
        })
        .catch(() => {
          item.sellingPrice = this.tempValue;
        })
        .finally(() => {
          this.showSureIndex = "";
        });
    },

    // 添加到购物车
    updateCart(item, val) {
      // 获取产品有效期规则
      let ruleData = this.checkProductRule(item);
      const obj = {
        productId: item.id,
        playDate: this.params.playDate,
        timeShareAppointmentRuleId: this.params.appointmentRules,
        timeIntervalId: this.params.timeId,
        subScenicId: this.params.subScenicId,
        buyNum: val,
        ...ruleData
      };
      this.$emit("update-cart", obj);
    },
    // 更新添加的数量
    updateNum({ totalProductList, id }) {
      this.cartId = id;
      this.list.forEach(item => {
        item.buyNum = 0;
        item.sellingPrice = item.oldPrice;
        for (let temp of totalProductList) {
          if (item.id === temp.productId) {
            item.buyNum = temp.productFlagPO.buyNum;
            item.sellingPrice = temp.sellingAmount / 100;
            break;
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
/*充值antd表单样式*/
.ant-form {
  color: #333;
}
.ant-form-inline .ant-form-item {
  margin-right: 0;
  width: 100%;
  /deep/.ant-form-item-control-wrapper {
    width: 100%;
  }
}

/*加减号*/
.minus {
  color: #a4a4a4;
  font-size: 24px;
  cursor: pointer;
}
.minus-disabled {
  color: #a4a4a4;
  font-size: 24px;
  cursor: not-allowed;
}
.number {
  margin: 0 16px;
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  color: #25a2f2;
}
/*减号*/
.plus {
  font-size: 24px;
  color: #25a2f2;
  cursor: pointer;
}
//.门票列表
.ticket-box {
  overflow-y: auto;
  height: 312px;
  margin: 12px 0;
  padding: 0 16px 16px;
  background: #fff;
  &.less {
    height: 164px;
  }
  .ticket-list {
    .ticket-list-item {
      box-sizing: border-box;
      position: relative;
      margin-right: 12px;
      margin-top: 38px;
      width: 100%;
      height: 110px;
      border: 1px solid #e6e6e6;
      padding: 12px;
      border-radius: 10px;
      &:hover {
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.23);
      }
      .img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
        border-radius: 8px;
      }
      .right-content {
        height: 100%;
        line-height: 18px;
        .custom-price {
          padding: 0 4px;
          border: none;
          width: 50px;
          height: 24px;
          // line-height: 24px;
          font-size: 18px;
          &:focus {
            background: #eeeeee;
          }
          outline: none;
        }
        .input-number {
          margin: 0 4px;
          border: none;
          width: 30px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          font-size: 18px;
          outline-color: #dcdfe6;
          color: #25a2f2;
          background: #f1f1f1;
        }
      }
      .price {
        font-size: 18px;
      }
      .fast-key {
        position: absolute;
        top: -23px;
        right: 9px;
        font-size: 12px;
        .ext {
          padding: 0 4px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          border-radius: 6px 6px 0 0;
          color: #fff;
          cursor: pointer;
        }
        .del {
          .ext;
          min-width: 60px;
          display: flex;
          justify-content: center;
          background: #fff;
          color: #25a2f2;
          border: 1px solid #25a2f2;
          border-bottom: none;
          & > i {
            font-size: 12px;
          }
        }
        .buy {
          .ext;
          min-width: 70px;
          background: #25a2f2;
        }
      }
    }
  }
}

.modal {
  /deep/.ant-modal-header {
    text-align: center;
  }
  /deep/.ant-modal-body {
    padding: 0;
  }
  .separate {
    border-top: 1px solid #e8e8e8;
    display: block;
  }
  .modal-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 18px 24px;

    .modal-item-title {
      color: #3566d9;
      font-size: 16px;
    }

    .margin-left {
      margin-left: 16px;
    }

    .modal-item-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  .not-allow {
    cursor: not-allowed;
  }
}
</style>
