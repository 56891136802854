<!--门票组合产品操作详情-->
<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="门票操作"></panel-head>
    <!--门票详情-start-->
    <section class="main bg-white mt-2x">
      <!-- 头部 start -->
      <div class="flex-between align-center p-2x">
        <h2 class="bold">{{ detail.productName }}</h2>
        <a-dropdown v-if="!isRetail" :trigger="['click']">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item :key="1" v-if="isShowTakeTicket">
              取票
            </a-menu-item>
            <a-menu-item :key="2" v-if="detail.status == 4">
              门票激活
            </a-menu-item>
            <a-menu-item
              :key="3"
              v-if="
                detail.travelerFaceType == 1 &&
                  detail.travelerFaceChannel.indexOf(6) >= 0
              "
            >
              补充人脸信息
            </a-menu-item>
            <a-menu-item :key="4">门票充值</a-menu-item>
            <a-menu-item :key="5" v-if="detail.whetherTakeTicket == 1"
              >门票补办</a-menu-item
            >
            <a-menu-item :key="6">门票改签</a-menu-item>
            <a-menu-item :key="7">延长使用期</a-menu-item>
            <!--有门票有收门票押金，并且 门票押金为已支付、未使用时，才显示-->
            <a-menu-item
              :key="8"
              v-if="
                detail.ifExistTicketDeposit &&
                  (detail.ticketDepositStatus === 3 ||
                    detail.ticketDepositStatus === 5)
              "
              >退押金
            </a-menu-item>
            <a-menu-item
              :key="9"
              v-if="
                detail.ifExistTicketFast &&
                  (detail.fastTicketStatus === 3 ||
                    detail.fastTicketStatus === 5)
              "
              >退快速通行服务</a-menu-item
            >
            <a-menu-item :key="10" v-if="detail.status !== 12"
              >退票</a-menu-item
            >
          </a-menu>
          <!-- 长白山不允许门票操作1455730894637690881 -->
          <a-button
            type="primary"
            v-if="detail.status !== 12 && merchantId != '1455730894637690881'"
            >门票操作 <a-icon type="caret-down"
          /></a-button>
        </a-dropdown>
      </div>
      <!-- 头部 end -->

      <!-- 门票详情-body start -->
      <ticket-detail
        :detail="detail"
        :ticketSubDetailList="ticketSubDetailList"
        :visitorInfo="visitorInfo"
        :discountInfo="discountInfo"
        :customerList="customerList"
        :receiverInfo="receiverInfo"
        :playerInfo="playerInfo"
        :takerInfo="takerInfo"
        :ticketVoucherList="ticketVoucherList"
        :checkConfVO="checkConfVO"
        :ticketMemberInfo="ticketMemberInfo"
        :orderBasisInfo="orderBasisInfo"
        :orderRefundInfo="orderRefundInfo"
        :ticketWriteOffConfigInfo="ticketWriteOffConfigInfo"
      ></ticket-detail>
      <!-- 门票详情-body end -->

      <!--tabs标签页-->
      <a-tabs v-model="activeName" class="mt-2x" type="card">
        <a-tab-pane tab="门票核销记录" key="first">
          <ticket-cancel :isRetail="isRetail"></ticket-cancel>
        </a-tab-pane>
        <a-tab-pane tab="门票资产概况" key="second" v-if="detail.ifExistAsset">
          <ticket-account v-if="activeName === 'second'"></ticket-account>
        </a-tab-pane>
        <a-tab-pane
          tab="项目套餐概况"
          key="third"
          v-if="detail.ifExistPlayItemPackage"
        >
          <item-pack
            :isRetail="isRetail"
            v-if="activeName === 'third'"
          ></item-pack>
        </a-tab-pane>
        <a-tab-pane
          tab="综合套票子产品"
          key="fourth"
          v-if="detail.ifExistTicketSub"
        >
          <ticket-mix-sub
            :isRetail="isRetail"
            v-if="activeName === 'fourth'"
          ></ticket-mix-sub>
        </a-tab-pane>
        <a-tab-pane
          tab="门票补办记录"
          key="fifth"
          v-if="detail.ifExistTicketReissued"
        >
          <ticket-replace
            ref="ticketReplace"
            :isRetail="isRetail"
            v-if="activeName === 'fifth'"
          ></ticket-replace>
        </a-tab-pane>
        <a-tab-pane
          tab="快速通行服务"
          key="sixth"
          v-if="detail.ifExistTicketFast"
        >
          <fast-service
            :isRetail="isRetail"
            v-if="activeName === 'sixth'"
            ref="fastService"
          ></fast-service>
        </a-tab-pane>
        <a-tab-pane
          tab="卡/腕带押金"
          key="seventh"
          v-if="detail.ifExistTicketDeposit"
        >
          <card-pledge
            :isRetail="isRetail"
            v-if="activeName === 'seventh'"
            ref="cardPledge"
          ></card-pledge>
        </a-tab-pane>
        <a-tab-pane
          tab="推荐加购记录"
          key="eighth"
          v-if="detail.ifExistTicketAdd"
        >
          <recommend-purchased
            :order-id="orderId"
            :isRetail="isRetail"
            v-if="activeName === 'eighth'"
          ></recommend-purchased>
        </a-tab-pane>
        <a-tab-pane
          tab="门票充值记录"
          key="ninth"
          v-if="detail.ifExistTicketRecharge"
        >
          <ticket-charge
            ref="ticketCharge"
            :order-id="orderId"
            :isRetail="isRetail"
            v-if="activeName === 'ninth'"
          ></ticket-charge>
        </a-tab-pane>
        <a-tab-pane
          tab="项目消费记录"
          key="ten"
          v-if="detail.ifExistTicketConssume"
        >
          <item-play
            v-if="detail.auxiliaryId && activeName === 'ten'"
            :isRetail="isRetail"
            :auxiliary-id="detail.auxiliaryId"
            :name="detail.productName"
          ></item-play>
        </a-tab-pane>
        <a-tab-pane
          tab="门票操作记录"
          key="eleven"
          v-if="detail.ifExistTicketOptChange"
        >
          <ticket-handle
            :isRetail="isRetail"
            v-if="detail.auxiliaryId && activeName === 'eleven'"
            :auxiliary-id="detail.auxiliaryId"
            ref="ticketHandle"
          ></ticket-handle>
        </a-tab-pane>
      </a-tabs>
    </section>
    <!--门票详情-end-->

    <!-- 取票 -->
    <TicketCollection
      v-if="detail.whetherTakeTicket == 0"
      :vis.sync="showDialog1"
      :detail="{
        id: id,
        productName: detail.productName,
        orderId: detail.orderId,
        status: detail.status
      }"
      @updateParentData="updateParentData"
      ref="ticketCollectionRef"
    ></TicketCollection>
    <!-- 门票激活 -->
    <TicketActivition
      :vis.sync="showDialog2"
      :detail="{
        id: id
      }"
      @updateParentData="updateParentData"
      ref="ticketActivitionRef"
    ></TicketActivition>
    <!-- 补充人脸信息 -->
    <AddFaceInfor
      v-if="
        detail.travelerFaceType == 1 &&
          detail.travelerFaceChannel.indexOf(6) >= 0
      "
      :vis.sync="showDialog3"
      :detail="{
        id: id
      }"
      @updateParentData="updateParentData"
      ref="addFaceInforRef"
    ></AddFaceInfor>
    <!-- 门票充值 -->
    <TicketRecharge
      :vis.sync="showDialog4"
      :detail="{
        auxiliaryId: detail.auxiliaryId
      }"
      @updateParentData="updateParentData"
    ></TicketRecharge>
    <!-- 门票补办 -->
    <TicketReissue
      :vis.sync="showDialog5"
      :detail="{
        id: id,
        orderId: detail.orderId
      }"
      @updateParentData="updateParentData"
      ref="ticketReissueRef"
    ></TicketReissue>
    <!-- 门票改签 -->
    <TicketChanges
      :vis.sync="showDialog6"
      :detail="{
        id: id,
        productName: detail.productName,
        beginValidTime: detail.beginValidTime,
        endValidTime: detail.endValidTime,
        useDate: detail.useDate,
        appointmentPeriodStartTime: detail.appointmentPeriodStartTime,
        appointmentPeriodEndTime: detail.appointmentPeriodEndTime
      }"
      @updateParentData="updateParentData"
    ></TicketChanges>
    <!-- 延长使用期 -->
    <ExtensionServiceTime
      :vis.sync="showDialog7"
      :detail="{
        id: id,
        productName: detail.productName,
        beginValidTime: detail.beginValidTime,
        endValidTime: detail.endValidTime,
        firstWriteOffTime: detail.firstWriteOffTime,
        afterWriteOffValidTime: detail.afterWriteOffValidTime
      }"
      @updateParentData="updateParentData"
    ></ExtensionServiceTime>
    <!--退押金、退款、退票-->
    <order-refund
      :visible.sync="refundVisible"
      :order-id="refundType === 3 ? ticketDepositOrderId : orderId"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import TicketDetail from "./ticketOperatDetail/combinationTicketDetail.vue"; //门票详情
// tabs
import TicketAccount from "./ticketOperatDetail/TicketAccount"; //门票账户概况
import ItemPack from "./ticketOperatDetail/ItemPack"; //项目套餐概况
import TicketMixSub from "./ticketOperatDetail/TicketMixSub"; // 综合套票
import TicketReplace from "./ticketOperatDetail/TicketReplace"; //门票补办记录
import FastService from "./ticketOperatDetail/FastService.vue"; //快速通行服务
import CardPledge from "./ticketOperatDetail/CardPledge"; // 卡腕带押金
import RecommendPurchased from "./ticketOperatDetail/RecommendPurchased"; //推荐加购记录
import TicketCharge from "./ticketOperatDetail/TicketCharge.vue"; //门票充值记录
import TicketCancel from "./ticketOperatDetail/TicketCancel.vue"; //门票核销记录
import ItemPlay from "./ticketOperatDetail/ItemPlay.vue"; //项目游玩记录
import TicketHandle from "./ticketOperatDetail/TicketHandle.vue"; //门票操作记录
// action
import TicketRecharge from "./components/TicketRecharge.vue"; // 门票充值
import TicketActivition from "./components/TicketActivition.vue"; //门票激活
import TicketCollection from "./components/TicketCollection.vue"; //门票取票
import TicketChanges from "./components/TicketChanges"; // 门票改签
import AddFaceInfor from "./components/AddFaceInfor.vue"; // 补充人脸信息
import TicketReissue from "./components/TicketReissue.vue"; // 门票补办
import ExtensionServiceTime from "./components/ExtensionServiceTime"; // 延长使用期
import OrderRefund from "../order/components/OrderRefund";
import { findTicketDetail } from "../../../api/order";

export default {
  name: "ticketOperateDetail",
  components: {
    TicketDetail,
    PanelHead,
    TicketRecharge,
    TicketActivition,
    TicketCollection,
    AddFaceInfor,
    TicketReissue,
    // tabs
    TicketAccount,
    ItemPack,
    TicketMixSub,
    TicketReplace,
    FastService,
    CardPledge,
    RecommendPurchased,
    TicketCharge,
    TicketCancel,
    ItemPlay,
    TicketHandle,
    // 操作
    TicketChanges, // 门票改签
    ExtensionServiceTime, // 延长使用期
    OrderRefund // 退押金、退款、退票
  },
  computed: {
    isShowTakeTicket: function() {
      //判断是否能取票
      //门票状态id1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款
      let notStatus = [1, 2, 6, 9, 10, 12];
      //如果包含以上任意一种状态，就不能取票了
      return (
        this.detail.whetherTakeTicket == 0 &&
        !notStatus.includes(this.detail.status)
      );
    }
  },
  data() {
    return {
      //是否来自商户页面  -  如果是则不可操作
      isRetail: 0,
      // 详情和tabs
      activeName: "first",
      id: this.$route.query.id,
      detail: {},
      ticketSubDetailList: [], // 票号详情
      visitorInfo: [], // 出游人信息
      discountInfo: {}, // 优惠信息
      customerList: [], // 门票出游人列表
      receiverInfo: [], // 取票人列表信息
      playerInfo: "", // 出游人
      takerInfo: "", //   取票人
      ticketVoucherList: [], // 门票副票及腕带列表
      checkConfVO: {}, // 门票核销配置信息
      ticketMemberInfo: {}, // 会员详情信息
      orderBasisInfo: {}, // 订单详情
      orderRefundInfo: {}, // 退款信息
      ticketWriteOffConfigInfo: {}, // 核销配置信息
      actionType: -1, // 操作编号
      showDialog1: false, // 是否显示模态对话框 门票操作-->取票
      showDialog2: false, // 是否显示模态对话框 门票操作-->激活
      showDialog3: false, // 是否显示模态对话框 门票操作-->补充人脸
      showDialog4: false, // 是否显示模态对话框 门票操作-->充值
      showDialog5: false, // 是否显示模态对话框 门票操作-->补办
      showDialog6: false, // 是否显示模态对话框 门票操作-->改签
      showDialog7: false, // 是否显示模态对话框 门票操作-->延长使用期
      showDialog8: false, // 是否显示模态对话框 门票操作-->退押金
      showDialog9: false, // 是否显示模态对话框 门票操作-->退快速通行
      showDialog10: false, // 是否显示模态对话框 门票操作-->退票
      // 退款信息
      refundVisible: false, // 退款退单弹窗
      orderId: "", // 订单编号
      ticketDepositOrderId: "", // 退押金需要用到的id
      refundIdType: 2, // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: "", // 退款类型 1退单， 2退款， 3退押金
      productName: "", // 退款产品名称
      productCategoryId: "", // 产品业务类型id
      merchantId: "" //商户id 长白山 1455730894637690881
    };
  },
  created() {
    this.merchantId = localStorage.getItem("merchantId");
    this.loadData();
    this.isRetail = this.$route.query.isRetail;
  },
  methods: {
    // 加载详情
    loadData() {
      findTicketDetail({ id: this.id })
        .then(res => {
          // console.log("这里的", res);
          const weeks = {
            1: "星期一",
            2: "星期二",
            3: "星期三",
            4: "星期四",
            5: "星期五",
            6: "星期六",
            7: "星期七"
          };
          const months = {
            1: "一月",
            2: "二月",
            3: "三月",
            4: "四月",
            5: "五月",
            6: "六月",
            7: "七月",
            8: "八月",
            9: "九月",
            10: "十月",
            11: "十一月",
            12: "十二月"
          };
          this.detail = res;
          this.orderId = this.detail.orderId;
          this.ticketSubDetailList = res.ticketSubDetailList; // 票号详情
          this.visitorInfo = res.visitorInfo || []; // 出游人信息
          this.discountInfo = res.discountInfo || {}; // 优惠信息
          this.customerList = res.customerList || []; // 门票出游人列表
          this.receiverInfo = res.receiverInfo || []; // 取票人列表
          this.ticketVoucherList = res.ticketVoucherList || []; // 门票副票及腕带列表
          this.checkConfVO = res.checkConfVO || {}; // 门票核销配置信息
          this.ticketMemberInfo = res.ticketMemberInfo || {}; // 会员详情信息
          this.orderBasisInfo = res.orderBasisInfo || {}; // 订单详情
          this.orderRefundInfo = res.orderRefundInfo || {}; // 退款信息
          this.ticketWriteOffConfigInfo = res.ticketWriteOffConfigInfo || {}; // 核销配置信息
          // 处理可核销星期
          if (
            res.ticketWriteOffConfigInfo.validWeekType === 1 &&
            res.ticketWriteOffConfigInfo.validWeeks.length
          ) {
            let week = "";
            res.ticketWriteOffConfigInfo.validWeeks.forEach(item => {
              week += weeks[item] || "";
              week += "，";
            });
            this.ticketWriteOffConfigInfo.validWeeks = week;
          } else {
            this.ticketWriteOffConfigInfo.validWeeks = "不限制";
          }
          // 处理可核销月份
          if (
            res.ticketWriteOffConfigInfo.validMonthsType === 1 &&
            res.ticketWriteOffConfigInfo.validMonths.length
          ) {
            let month = "";
            res.ticketWriteOffConfigInfo.validMonths.forEach(item => {
              month += months[item] || "";
              month += "，";
            });
            this.ticketWriteOffConfigInfo.validMonths = month;
          } else {
            this.ticketWriteOffConfigInfo.validMonths = "不限制";
          }
          // 提取出游人
          if (res.visitorInfo.length) {
            const item = res.visitorInfo[0];
            const temp = item.userFieldInfoList.find(
              val => val.fieldType === 4 || val.fieldType === 5
            );
            this.playerInfo = temp ? temp.fieldValue : "";
          }
          // 提取取票人信息
          if (res.receiverInfo.length) {
            const temp = res.receiverInfo.find(
              val => val.fieldType === 4 || val.fieldType === 5
            );
            this.takerInfo = temp ? temp.fieldValue : "";
          }
        })
        .catch(() => {});
    },
    // 点击门票操作
    handleMenuClick(e) {
      this.orderId = this.detail.orderId;
      this.productName = this.detail.productName;
      this.actionType = e.key;
      switch (e.key) {
        case 1: // 取票
          this.$refs.ticketCollectionRef.ticketQuery();
          this.showDialog1 = true;
          break;
        case 2: // 激活
          this.$refs.ticketActivitionRef.findActivateInfo();
          this.showDialog2 = true;
          break;
        case 3: // 补充人脸
          this.$refs.addFaceInforRef.findActivateInfo();
          this.showDialog3 = true;
          break;
        case 5: // 门票补办
          this.$refs.ticketReissueRef.queryTicket();
          this.showDialog5 = true;
          break;
        case 8: // 退押金
          // 退押金需要用到的ticketDepositOrderId，因为有可能押金是买票后交的，所以不能直接用orderId
          this.ticketDepositOrderId = this.detail.ticketDepositOrderId;
          this.refundIdList = [this.detail.ticketDepositDetailId];
          this.refundType = 3;
          this.productCategoryId = "1365211694970564609";
          this.refundVisible = true;
          break;
        case 9: // 退快票
          this.refundIdList = [this.detail.fastTicketDetailId];
          this.refundType = 2;
          this.productCategoryId = "1365211690130337793";
          this.refundVisible = true;
          break;
        case 10: // 退门票
          this.refundIdList = [this.detail.id];
          this.refundType = 2;
          this.productCategoryId = "1365211690130337793";
          this.refundVisible = true;
          break;
        default:
          this[`showDialog${e.key}`] = true;
      }
    },
    // 刷新详情接口数据
    updateParentData() {
      this.loadData();
      // 刷新门票操作记录
      const ticketHandle = () => {
        if (this.$refs.ticketHandle) this.$refs.ticketHandle.loadData();
      };
      switch (this.actionType) {
        case 1: //取票
          ticketHandle();
          break;
        case 3: //补充人脸信息
          ticketHandle();
          break;
        case 5: //门票补办
          if (this.$refs.ticketReplace) this.$refs.ticketReplace.loadData();
          break;
        case 6: //门票改签
          ticketHandle();
          break;
        case 7: //延长使用期
          ticketHandle();
          break;
        default:
      }
    },
    // 退款成功, 刷新数据
    refunded() {
      this.loadData();
      // 刷新卡腕带记录
      if (this.refundType === 3) {
        if (this.$refs.cardPledge) this.$refs.cardPledge.loadData();
      }
      // 刷新快速通行服务
      else {
        if (this.$refs.fastService) {
          this.$refs.fastService.loadFast();
          this.$refs.fastService.loadGive();
        }
      }
    }
  }
};
</script>
