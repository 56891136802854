<template>
  <a-modal
    :visible="vis"
    title="取票"
    width="780px"
    :body-style="{
      'max-height': '460px',
      overflow: 'auto'
    }"
    @cancel="close"
    :maskClosable="false"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="refTicketCollection"
    >
      <a-form-model-item label="门票名称">
        <span>{{ activateInfo.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="主票票号">
        <a-row type="flex" justify="start">
          <a-col :span="6">
            {{ activateInfo.auxiliaryId }}
          </a-col>
          <a-col :span="16">
            <a-checkbox
              :disabled="!activateInfo.isNeedActivate || detail.status != 4"
              v-model="form.check"
            >
              立即激活
            </a-checkbox>
          </a-col>
        </a-row>
      </a-form-model-item>

      <!-- 主票号，需要身份证及人脸的情况 -->
      <a-form-model-item
        v-if="
          !activateInfo.subTicketListInfo ||
            activateInfo.subTicketListInfo.length <= 0
        "
        :colon="false"
        label=" "
        :label-col="labelCol"
      >
        <a-row
          type="flex"
          justify="start"
          v-if="activateInfo.byTicketId || activateInfo.bindIdCardNo"
        >
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <IdCardInput v-model="idCardNumber"></IdCardInput>
          </a-col>
        </a-row>
        <!-- 卡腕带 -->
        <a-row
          type="flex"
          justify="start"
          v-if="
            !activateInfo.ifPayDeposit && activateInfo.productVoucherType != 0
          "
        >
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <IcCardinput
              placeholder="请输入卡/腕带编号"
              v-model="voucherType"
            ></IcCardinput>
          </a-col>
        </a-row>
        <a-row
          class="mt-2x"
          type="flex"
          justify="start"
          v-if="activateInfo.byFaces || activateInfo.bindFaceURL"
        >
          <a-col :span="6"></a-col>
          <a-col :span="10">
            <FaceInput
              :echoimgurl="activateInfo.bindFaceURL"
              v-model="faceNumber"
            ></FaceInput>
          </a-col>
        </a-row>
      </a-form-model-item>

      <!-- 子票号 -->
      <a-form-model-item
        v-if="
          activateInfo.subTicketListInfo &&
            activateInfo.subTicketListInfo.length
        "
        label="子票票号"
      >
        <div
          v-for="(item, index) in activateInfo.subTicketListInfo"
          :key="index"
          class="mb-3x"
        >
          <!-- 身份证 -->
          <a-row type="flex" justify="start" v-if="activateInfo.byTicketId">
            <a-col :span="6">
              <span v-if="activateInfo.byTicketId">
                {{ item.auxiliaryId }}
              </span>
            </a-col>
            <a-col :span="10" v-if="activateInfo.byTicketId">
              <IdCardInput v-model="item.subIdCardNumber"></IdCardInput>
            </a-col>
          </a-row>
          <!-- 卡腕带 -->
          <a-row
            type="flex"
            justify="start"
            v-if="
              !activateInfo.ifPayDeposit && activateInfo.productVoucherType != 0
            "
          >
            <a-col :span="6">
              <!-- 上面身份证里没显示、且为腕带,才在这列显示子票号 -->
              <span
                v-if="
                  !activateInfo.byTicketId &&
                    activateInfo.productVoucherType != 0
                "
              >
                {{ item.auxiliaryId }}
              </span>
            </a-col>
            <a-col :span="10">
              <IcCardinput
                placeholder="请输入卡/腕带编号"
                v-model="item.subVoucherType"
              ></IcCardinput>
            </a-col>
          </a-row>
          <!-- 人脸 -->
          <a-row
            class="mt-x"
            type="flex"
            justify="start"
            v-if="activateInfo.byFaces"
          >
            <a-col :span="6">
              <span
                v-if="
                  !activateInfo.byTicketId &&
                    activateInfo.productVoucherType == 0
                "
              >
                {{ item.auxiliaryId }}
              </span>
            </a-col>
            <a-col :span="10">
              <FaceInput
                :echoimgurl="item.bindFaceURL"
                v-model="item.subFaceNumber"
              ></FaceInput>
            </a-col>
          </a-row>
          <!-- 不需身份证、人脸、腕带 -->
          <a-row
            class="mt-x"
            type="flex"
            justify="start"
            v-if="
              !activateInfo.byFaces &&
                !activateInfo.byTicketId &&
                activateInfo.productVoucherType == 0
            "
          >
            <a-col :span="10">
              <span>
                {{ item.auxiliaryId }}
              </span>
            </a-col>
          </a-row>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div
      class="flex-between align-center"
      :class="{ 'flex-end': activateInfo.ifPayDeposit }"
      slot="footer"
    >
      <div v-if="!activateInfo.ifPayDeposit">
        <span>
          押金：<span class="red">{{
            activateInfo.voucherDeposit | money
          }}</span>
        </span>
      </div>
      <div>
        <a-button key="cancel" @click="close">取消</a-button>
        <a-button type="primary" @click="getTicketBtn" :loading="loading"
          >确定取票</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ticketPrint, findTicketPreprintInfo } from "../../../../api/order";
import { getTicketOrReissue } from "../../../../api/cart";
import { orderAuxiliaryprintinfo } from "../../../../api/search";
import IdCardInput from "../../../../components/hardware/IdCardInput.vue";
import IcCardinput from "../../../../components/hardware/IcCardinput.vue";
import FaceInput from "../../../../components/hardware/FaceInput.vue";
import HardwareService from "../../../../common/domains/hardware-domain/hardwareService";
import { isIdcard } from "../../../../utils/global";
export default {
  name: "ticketCollection",
  components: {
    IdCardInput,
    FaceInput,
    IcCardinput
  },
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      form: {
        check: true
      },
      rules: {},
      loading: false, // 加载中
      activateInfo: {}, // 取票查询接口数据
      idCardNumber: "", // 主票号：身份证输入框
      faceNumber: "", // 主票号：人脸信息框
      voucherType: "" // 主票号：卡腕带
    };
  },
  mounted() {
    this.ticketQuery();
  },
  methods: {
    // 取票查询
    ticketQuery() {
      findTicketPreprintInfo({
        id: this.detail.id
      })
        .then(res => {
          // 没有子票号场景
          res && (this.activateInfo = res);
          this.faceNumber = res.bindFaceId || "";
          // 有子票号场景
          if (res.subTicketListInfo) {
            const deepCopyRes = JSON.parse(JSON.stringify(res));
            deepCopyRes.subTicketListInfo.forEach(item => {
              item.subIdCardNumber = ""; // 子票号：身份证
              item.subVoucherType = ""; // 子票号：卡腕带
              item.subFaceNumber = ""; // 子票号：人脸
            });
            this.activateInfo = deepCopyRes;
          }
          this.activateInfo.orderId = this.detail.orderId;
        })
        .catch(() => {});
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 确定取票按钮
    getTicketBtn() {
      if (!this.check()) {
        this.$message.warning("信息填写不完整");
        return;
      }
      this.loading = true;
      // 取票需押金情况
      // 如：窗口端下单的票，押金在下单时一起支付了，自助机下单买的票，押金还未支付
      if (
        !this.activateInfo.ifPayDeposit &&
        this.activateInfo.productVoucherType != 0
      ) {
        // console.log("需要交押金,且不是纸质票");
        this.getTicketByDeposit();
        return;
      }
      // 纸质票、且不需要押金：先调打印接口，再调取票接口（2021-07-13修改）
      if (this.activateInfo.voucherDeposit == 0) {
        this.printTicket();
        return;
      }
      // 取票不需要押金的情况
      this.noDepositRequired();
    },
    check() {
      const idCardArr = [];
      const faceArr = [];
      let flag = true;
      // 用f、t来存储是否填写了身份证及人脸内容
      this.activateInfo.subTicketListInfo.forEach(item => {
        !item.subIdCardNumber ? idCardArr.push("f") : idCardArr.push("t");
        // 人脸
        !item.subFaceNumber ? faceArr.push("f") : faceArr.push("t");
      });
      // 只有身份证校验
      if (this.activateInfo.byTicketId && !this.activateInfo.byFaces) {
        idCardArr.indexOf("f") == -1 ? (flag = true) : (flag = false);
      } else if (!this.activateInfo.byTicketId && this.activateInfo.byFaces) {
        // 只有人脸校验
        faceArr.indexOf("f") == -1 ? (flag = true) : (flag = false);
      } else if (this.activateInfo.byTicketId && this.activateInfo.byFaces) {
        // 既要校验身份证，也要校验人脸
        idCardArr.indexOf("f") == -1 && faceArr.indexOf("f") == -1
          ? (flag = true)
          : (flag = false);
      } else {
        // 不需要校验身份证、也不需要校验人脸
        console.log("不需要校验身份证、人脸");
      }
      return flag;
    },
    // 取票需要押金
    getTicketByDeposit() {
      // 只有主票号、且需要输入：卡腕带或身份证或人脸时的参数
      let arr = [
        {
          personId: this.faceNumber, // 人脸Id
          requiredPersonId: true, // 是否需要人脸Id
          voucherNo: this.voucherType, // 绑定的腕带
          requiredVoucherNo: true, // 是否需要绑定的腕带
          // 身份证
          visitorFieldList: [
            {
              fieldType: 6, // 字段类型：6身份证，7生日，8性别，9证件照
              fieldValue: this.idCardNumber
            }
          ]
        }
      ];
      // 有子票号情况：且需要输入：卡腕带或身份证或人脸时的参数
      if (
        this.activateInfo.subTicketListInfo &&
        this.activateInfo.subTicketListInfo.length
      ) {
        let arr2 = [];
        this.activateInfo.subTicketListInfo.forEach(item => {
          arr2.push({
            personId: item.subFaceNumber, // 人脸Id
            requiredPersonId: true, // 是否需要人脸Id
            voucherNo: item.subVoucherType, // 绑定的腕带
            requiredVoucherNo: true, // 是否需要绑定的腕带
            // 身份证
            visitorFieldList: [
              {
                fieldType: 6, // 字段类型：6身份证，7生日，8性别，9证件照
                fieldValue: item.subIdCardNumber
              }
            ]
          });
        });
        arr = arr2;
      }
      // 校验卡腕带是否填写
      const flag = arr.some(item => {
        if (!item.voucherNo) {
          this.$message.warning("卡腕带信息未填写完整");
          this.loading = false;
          return true;
        }
      });
      if (flag) {
        this.loading = false;
        return;
      }
      getTicketOrReissue({
        productId: this.activateInfo.productId, // 产品id
        spuId: this.activateInfo.spuId, // 产品spuid
        businessCategory: 6, // 业务类型，6取票，7补办
        buyChannels: 6, // 购买渠道，窗口端6，自助机8
        ticketNo: this.activateInfo.auxiliaryId, // 票号
        totalPrice: this.activateInfo.voucherDeposit, // 取票或补办的金额(单位：分)
        remark: "", // 备注
        productTravelerList: [
          {
            activateNow: this.form.check, // 是否立即激活
            travelerList: arr
          }
        ]
      })
        .then(res => {
          this.$store.dispatch("order/setCartList", { id: res.id });
          this.$router.push({ path: "/home/sell/OrderInfo" });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    // 取票不需要押金
    noDepositRequired() {
      const arr = [];
      if (
        this.activateInfo.subTicketListInfo &&
        this.activateInfo.subTicketListInfo.length
      ) {
        this.activateInfo.subTicketListInfo.forEach(item => {
          arr.push({
            id: item.id, // 门票订单详情记录id
            idCardNo: item.subIdCardNumber, // 子票号绑定的身份证号
            faceId: item.subFaceNumber // 子票号绑定的人脸id
          });
        });
      } else {
        // if (this.activateInfo.byTicketId || this.activateInfo.byFaces) {
        if (!this.idCardNumber && this.activateInfo.byTicketId) {
          this.$message.warning("请先录入身份证");
          this.loading = false;
          return;
        }
        if (
          this.idCardNumber &&
          this.activateInfo.byTicketId &&
          isIdcard(this.idCardNumber)
        ) {
          this.$message.warning(isIdcard(this.idCardNumber));
          this.loading = false;
          return;
        }
        if (!this.faceNumber && this.activateInfo.byFaces) {
          this.$message.warning("请先采集人脸");
          this.loading = false;
          return;
        }
        // }
      }
      ticketPrint({
        channel: "", // 打印渠道,非必填
        auxiliaryInfoList: [
          {
            ifActivateNow: this.form.check, // 是否立即激活
            id: this.detail.id, // 门票订单详情记录id
            auxiliaryId: this.activateInfo.auxiliaryId, // 主票号或子票号
            idCardNo: this.idCardNumber, // 票号绑定的身份证号
            faceId: this.faceNumber, // 票号绑定的人脸id
            // 子票绑定的身份证人脸信息
            ticketPrintSubAuxiliaryInfoList: arr
          }
        ]
      })
        .then(() => {
          this.$emit("updateParentData");
          this.$emit("update:vis", false);
          this.$message.success("取票成功");
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    // 纸质门票 打印
    printTicket() {
      // if (this.activateInfo.byTicketId || this.activateInfo.byFaces) {
      if (!this.idCardNumber && this.activateInfo.byTicketId) {
        this.$message.warning("请先录入身份证");
        this.loading = false;
        return;
      }
      if (
        this.idCardNumber &&
        this.activateInfo.byTicketId &&
        isIdcard(this.idCardNumber)
      ) {
        this.$message.warning(isIdcard(this.idCardNumber));
        this.loading = false;
        return;
      }
      if (!this.faceNumber && this.activateInfo.byFaces) {
        this.$message.warning("请先采集人脸");
        this.loading = false;
        return;
      }
      // }
      orderAuxiliaryprintinfo({ auxiliaryId: this.activateInfo.auxiliaryId })
        .then(res => {
          if (res) {
            let flag = HardwareService.printTicket(JSON.stringify(res));
            if (flag) {
              // 打印完，调取票接口
              this.noDepositRequired();
            } else {
              this.$message.warning("门票打印失败，取不了票");
              this.loading = false;
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
</style>
