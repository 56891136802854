<!--交账记录-->
<template>
  <div class="wrapper flex-between flex-col">
    <panel-head>
      <div class="flex-start align-center">
        <p class="font-l mr-4x">交账记录</p>
        <div class="text-style">
          员 工：{{ userInfos.userName }} / {{ userInfos.phone }}
        </div>
        <div class="text-style">
          上班时间：{{ userInfos.clockIn | date("YYYY-MM-DD hh:mm:ss") }}
        </div>
        <div class="text-style">
          交账时间：{{ userInfos.clockOff | date("YYYY-MM-DD hh:mm:ss") }}
        </div>
      </div>
    </panel-head>
    <!--body-->
    <section class="main mt-2x">
      <a-row>
        <!-- 左侧列表 -->
        <a-col :span="5" class="bd-r" style="min-height:700px;">
          <header class="pv-2x pl-2x">
            <span class="text-v">交账时间：</span>
            <a-range-picker
              class="text-v"
              style="width: 200px;"
              v-model="date"
              :show-time="{ format: 'HH:mm' }"
              format="YYYY-MM-DD"
              :placeholder="['开始时间', '结束时间']"
              @ok="dateChange"
            />
          </header>
          <section
            v-for="(item, index) in records"
            :key="index"
            class="p-2x bd-t pointer"
            :class="{
              active: seleceInex === index
            }"
            @click="selectRecord(index)"
          >
            <p>
              {{ item.accountBeginTime | date("YYYY-MM-DD hh:mm:ss") }} -
              {{ item.accountEndTime | date("YYYY-MM-DD hh:mm:ss") }}
            </p>
            <div class="flex-between align-center mt-2x">
              <p>售票金额合计：</p>
              <p class="font-l">{{ item.actualSaleAmount | money }}</p>
            </div>
          </section>
          <!-- 分页 -->
          <footer class="flex-end container bd-t">
            <a-pagination
              v-model="pageCurrent"
              :total="total"
              class="pagination"
              @change="paginationChange"
              show-less-items
            />
          </footer>
        </a-col>
        <!-- 右侧表格内容 -->
        <a-col :span="19">
          <a-tabs v-model="activeIndex">
            <a-tab-pane key="0" tab="商户汇总">
              <a-table
                v-if="activeIndex === '0'"
                :defaultExpandAllRows="true"
                :defaultExpandedRowKeys="[1]"
                :columns="merchantListColumns"
                :data-source="merchantList"
                :pagination="false"
                :expandRowByClick="true"
                :expandIconColumnIndex="-1"
                :expandIconAsCell="false"
                :indentSize="20"
                :scroll="{ y: 400 }"
                rowKey="id"
              >
                <div slot="money" slot-scope="row">
                  {{ row | money }}
                </div>
                <template slot="action" slot-scope="row">
                  <a-button
                    type="link p-0"
                    v-if="row.orderFormatTypeText != '合计'"
                    >明细</a-button
                  >
                </template>
                <!-- 子表格 -->
                <a-table
                  class="pt-2x child-table"
                  slot="expandedRowRender"
                  slot-scope="record"
                  :scroll="{ y: 300 }"
                  :columns="innerColumns"
                  :data-source="record.orderHandoverSalesSummaryVOS"
                  :pagination="false"
                  bordered
                  :expandedRowKeys="innerColumns.map(item => item.dataIndex)"
                  :row-key="
                    (r, i) => {
                      i.toString();
                    }
                  "
                >
                  <div slot="money" slot-scope="record">
                    {{ record | money }}
                  </div>
                </a-table>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="1" tab="产品汇总" force-render>
              <a-table
                :columns="productColumns"
                :data-source="productData"
                :pagination="false"
                :indentSize="20"
                :scroll="{ y: 400 }"
                rowKey="id"
              >
                <div slot="money" slot-scope="row">
                  {{ row | money }}
                </div>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </section>
    <!-- 底部操作栏 -->
    <div class="footer">
      <a-button
        type="primary"
        size="large"
        shape="round"
        ghost
        class="btn-lg"
        @click="$router.back()"
      >
        返回
      </a-button>
    </div>
  </div>
</template>

<script>
import PanelHead from "../../components/common/PanelHead";
import {
  orderHandoverMainInfoPage,
  getHandOverUserInfo,
  orderHandoverMainInfo,
  orderHandoverAccountInfo
} from "@/api/order";
import moment from "moment";

const merchantListColumns = [
  {
    title: "产品业态",
    dataIndex: "orderFormatTypeText",
    key: "orderFormatTypeText"
  },
  {
    title: "子景区名称",
    dataIndex: "subMerchantScenicName",
    key: "subMerchantScenicName"
  },
  {
    title: "商户名称",
    dataIndex: "merchantName",
    key: "merchantName"
  },
  {
    title: "收款金额",
    key: "totalSaleAmount",
    dataIndex: "totalSaleAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "实收小计",
    key: "actualSaleAmount",
    dataIndex: "actualSaleAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

const innerColumns = [
  {
    title: "收款方式",
    dataIndex: "paywayName",
    key: "paywayName"
  },
  {
    title: "收款金额",
    key: "saleAmount",
    dataIndex: "saleAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "金额小计",
    key: "actualSaleAmount",
    dataIndex: "actualSaleAmount",
    scopedSlots: { customRender: "money" }
  }
];

const productColumns = [
  {
    title: "产品业态",
    dataIndex: "orderFormatTypeText",
    key: "orderFormatTypeText"
  },
  {
    title: "子景区名称",
    dataIndex: "subMerchantScenicName",
    key: "subMerchantScenicName"
  },
  {
    title: "产品编号",
    dataIndex: "productId",
    key: "productId"
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    key: "productName"
  },
  {
    title: "产品所属商户",
    dataIndex: "merchantNames",
    key: "merchantNames"
  },
  {
    title: "产品类型",
    dataIndex: "productCategoryName",
    key: "productCategoryName"
  },
  {
    title: "产品分类",
    dataIndex: "productTypeName",
    key: "productTypeName"
  },
  {
    title: "业务类型",
    dataIndex: "orderBusinessName",
    key: "orderBusinessName"
  },
  // {
  //   title: "收款方式",
  //   dataIndex: "payWayName",
  //   key: "payWayName"
  // },
  {
    title: "销售数量",
    dataIndex: "saleNum",
    key: "saleNum"
  },
  {
    title: "退款数量",
    dataIndex: "refundNum",
    key: "refundNum"
  },
  {
    title: "数量小计",
    dataIndex: "actualSaleNum",
    key: "actualSaleNum"
  },
  {
    title: "销售金额",
    key: "saleAmount",
    dataIndex: "saleAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "金额小计",
    key: "actualSaleAmount",
    dataIndex: "actualSaleAmount",
    scopedSlots: { customRender: "money" }
  }
];

export default {
  name: "BaseAccountRecord",
  components: { PanelHead },
  data() {
    return {
      //0 商户汇总  1 产品汇总
      activeIndex: "0",
      //销售汇总表格标题
      merchantListColumns,
      //销售汇总数据
      merchantList: [],
      //明细表格标题
      innerColumns,
      //明细数据
      innerData: [],
      //产品表格标题
      productColumns,
      //产品表格数据
      productData: [],
      date: [moment().add(-7, "day"), moment()], //交账时间
      startTime: "0", //交账开始时间
      endTime: "1", //交账结束时间
      pageCurrent: 1, //列表页码
      total: 0, //列表总页数
      seleceInex: 0, //选中的项
      records: [], // 交账记录列表数据
      saleDataId: "", //列表id
      //员工信息
      userInfos: {
        clockIn: "", //上班时间
        clockOff: "", //交账时间
        phone: "", //手机号
        userName: "" //员工名字
      }
    };
  },
  created() {
    this.startTime = moment()
      .add(-7, "day")
      .unix();
    this.endTime = moment().unix();
    // 获取交账记录列表
    this.getList();
    //获取员工信息
    this.getHandOverUserInfo();
  },
  methods: {
    //获取交账的用户信息
    getHandOverUserInfo() {
      getHandOverUserInfo().then(res => {
        this.userInfos = res;
      });
    },
    //获取交账记录列表
    getList() {
      orderHandoverMainInfoPage({
        page: this.pageCurrent,
        size: 10,
        startTime: String(this.startTime),
        endTime: String(this.endTime)
      }).then(res => {
        this.total = res.total;
        this.pageCurrent = res.page;
        this.records = res.records || [];
        if (!Object.keys(res.records).length) {
          return false;
        }
        // 默认打开，选择第一条数据的id
        this.saleDataId = res.records[0].id;
        //获取商户汇总第一条
        this.getMerchantData(this.saleDataId);
        //获取产品汇总第一条
        this.getProductData(this.saleDataId);
      });
    },
    //获取商户交账汇总
    getMerchantData(id) {
      orderHandoverMainInfo({
        id: id
      })
        .then(res => {
          this.merchantList = res;
        })
        .catch(() => {});
    },
    //获取产品汇总数据
    getProductData(id) {
      orderHandoverAccountInfo({
        id: id
      })
        .then(res => {
          this.productData = res;
        })
        .catch(() => {});
    },
    //查看明细
    checkDetails(record) {
      this.innerData = record.orderHandoverSalesSummaryVOS;
    },
    //点击页码
    paginationChange() {
      this.getList();
    },
    //日期改变
    dateChange() {
      let { date } = this;
      this.startTime = date[0].unix();
      this.endTime = date[1].unix();
      this.getList();
    },
    //选中左侧表格的值
    selectRecord(index) {
      this.seleceInex = index;
      this.saleDataId = this.records[index].id;
      //获取商户汇总
      this.getMerchantData(this.saleDataId);
      //获取产品汇总
      this.getProductData(this.saleDataId);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

// 头部
.text-style {
  margin-right: 32px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  background-color: #f4f9fc;
}

.active {
  background: @primary;
  color: #fff;
}
</style>
