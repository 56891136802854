<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="订单详情"></panel-head>
    <main class="main mv-2x ph-2x">
      <!--订单信息-->
      <div class="pt-1x">
        <h3 class="title-mid" style="margin: 10px 0 16px 0">总单信息</h3>
        <a-row class="detail">
          <a-col :span="8">
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单号</li>
              <li :span="14">{{ mainOrderInfo.id }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单业态集</li>
              <li :span="14">{{ mainOrderInfo.ownerFormatTypeInfos }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单商户集</li>
              <li :span="14">{{ mainOrderInfo.ownerMerchants }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">销售人员</li>
              <li :span="14">{{ mainOrderInfo.salespersonName }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">会员姓名</li>
              <li :span="14">
                {{ mainOrderInfo.purchaserMemberName }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">会员手机号</li>
              <li :span="14">
                {{ mainOrderInfo.purchaserMemberPhone }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">总单实收</li>
              <li :span="14">{{ mainOrderInfo.actualAmount | money }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">总单退款金额</li>
              <li :span="14">{{ mainOrderInfo.totalRefundAmount | money }}</li>
            </ul>
            <!-- <ul class="mb-2x flex-start">
            <li :span="6" class="gray">销售渠道</li>
            <li :span="14">{{ detail.buyChannelsText }}</li>
          </ul>
          <ul class="mb-2x flex-start">
            <li :span="6" class="gray">订单状态</li>
            <li :span="14" class="primary">{{ detail.orderStatusText }}</li>
          </ul>
          <ul class="mb-2x flex-start">
            <li :span="6" class="gray">收款备注</li>
            <li :span="14">{{ detail.remarks }}</li>
          </ul> -->
          </a-col>
          <a-col :span="8">
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单实收余额</li>
              <li :span="14">{{ mainOrderInfo.balanceAmount | money }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单优惠活动</li>
              <li :span="14">{{ mainOrderInfo.specialActivity }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">支付方式</li>
              <li :span="14">{{ mainOrderInfo.payWayName }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">支付渠道</li>
              <li :span="14">{{ mainOrderInfo.payChannels }}</li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">下单渠道</li>
              <li :span="14">{{ mainOrderInfo.buyChannelsText }}</li>
            </ul>

            <ul class="mb-2x flex-start" v-if="mainOrderInfo.groupName">
              <li :span="6" class="gray">团体名称</li>
              <li :span="14">
                {{ mainOrderInfo.groupName }}
              </li>
            </ul>
            <ul class="mb-2x flex-start" v-if="mainOrderInfo.groupType">
              <li :span="6" class="gray">团体类型</li>
              <li :span="14">
                {{ mainOrderInfo.groupType }}
              </li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单状态</li>
              <li :span="14">{{ mainOrderInfo.orderStatusText }}</li>
            </ul>
          </a-col>
          <a-col :span="8">
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">外部单号</li>
              <li :span="14">{{ mainOrderInfo.outsideCode }}</li>
            </ul>
            <!--//检测产品列表是否包含门票、综合套票，场次票等产品类型，如果有则存在取票号-->
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单开票状态</li>
              <li :span="14">{{ mainOrderInfo.billingStatus }}</li>
            </ul>
            <!-- <ul class="flex-start mb-2x">
            <li :span="6" class="gray">分销渠道</li>
            <li :span="14">{{ detail.buyChannelsText }}</li>
          </ul> -->
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">总单开票时间</li>
              <li :span="14">
                {{ mainOrderInfo.billingTime | date("yyyy-mm-dd hh:mm:ss") }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">优惠金额</li>
              <li :span="14">
                {{ mainOrderInfo.merchantDiscountAmount | money }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">积分抵扣</li>
              <li :span="14">
                {{ mainOrderInfo.integralDeductionAmount | money }}
              </li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">总单备注</li>
              <li :span="14">{{ mainOrderInfo.remarks }}</li>
            </ul>
            <ul class="mb-2x flex-start">
              <li :span="6" class="gray">下单时间</li>
              <li :span="14">
                {{ mainOrderInfo.createTime | date("yyyy-mm-dd hh:mm:ss") }}
              </li>
            </ul>
            <ul class="flex-start mb-2x">
              <li :span="6" class="gray">业务类型</li>
              <li :span="14">{{ mainOrderInfo.orderBusinessTypeText }}</li>
            </ul>
          </a-col>
        </a-row>
      </div>
      <div>
        <h3 class="title-mid">订单信息</h3>
        <!--订单信息-->
        <currency-table
          :columns="orderColumns"
          :data-source="orderBasisInfoVOS"
        >
          <template slot="action">
            <a-table-column width="100px" title="操作" fixed="right">
              <!--            <div slot="title">-->
              <!--              操作-->
              <!--            </div>-->
              <div
                slot-scope="row"
                v-permission="'appOrderNewOrderDetailMorerefund'"
              >
                <a-button
                  type="link"
                  class="ph-x"
                  v-if="row.id !== '合计'"
                  :disabled="!refundList.includes(row.orderStatus)"
                  @click="orderRefundChecks(row)"
                  >退款</a-button
                >
              </div>
            </a-table-column>
          </template>
        </currency-table>
      </div>
      <!--产品信息-->
      <div class="relative">
        <h3 class="title-mid">产品信息</h3>
        <!--      批量操作-->
        <div v-if="isTrue" class="mb-1x flex-end top-dropdown res">
          <a-dropdown type="primary" @click="e => e.preventDefault()">
            <a-button type="primary">
              批量操作 <a-icon type="down" class="pointer" />
            </a-button>
            <a-menu slot="overlay" @click="dropdownClick($event)">
              <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
              <template>
                <!--                  组合产品不显示-->
                <a-menu-item
                  :disabled="operator.verificationDisable"
                  key="verification"
                  >核销</a-menu-item
                >
                <a-menu-item
                  :disabled="operator.forceVerificationDisable"
                  key="forceVerification"
                  >强制核销</a-menu-item
                >
                <a-menu-item :disabled="operator.refundDisable" key="refund"
                  >退款</a-menu-item
                >
                <a-menu-item
                  :disabled="operator.forceRefundDisable"
                  key="forceRefund"
                  >强制退款</a-menu-item
                >
              </template>
              <a-menu-item
                :disabled="operator.changeAppointment"
                key="changeAppointment"
                >修改预约</a-menu-item
              >
            </a-menu>
          </a-dropdown>
        </div>
        <!--        :row-selection="rowSelection" 临时注释多选-->
        <currency-table
          :columns="productColumns"
          :data-source="orderDetailInfoVOS"
        >
          <template slot="popover">
            <a-table-column width="225px" title="游客信息" align="center">
              <template slot-scope="row">
                <a-popover
                  v-if="row.userInfoList && row.userInfoList.length"
                  :trigger="row.whetherCombineProduct === 2 ? 'click' : 'hover'"
                  placement="right"
                >
                  <template #content>
                    <div v-for="(item, index) in row.userInfoList" :key="index">
                      <template
                        v-if="item.fieldType == 100 || item.fieldType == 9"
                      >
                        <div class="flex-start mb-1x" v-if="item.fieldValue">
                          <p class="w-80">{{ item.fieldName }}</p>
                          <img
                            class="pointer"
                            @click="imgClick(item.fieldValue)"
                            style="width: 100px; height: 100px"
                            v-if="
                              item.fieldValue &&
                                item.fieldValue.indexOf('https://') !== -1
                            "
                            :width="40"
                            :src="item.fieldValue"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex-start mb-1x">
                          <p class="w-80">{{ item.fieldName }}:</p>
                          <p>{{ item.fieldValue || "-" }}</p>
                        </div>
                      </template>
                    </div>
                  </template>

                  <div>
                    <a-tag v-if="row.whetherCombineProduct === 2" size="medium"
                      >点击查看</a-tag
                    >
                    <div v-else>
                      {{
                        row.userInfoList[0] && row.userInfoList[0].fieldName
                      }}:
                      <img
                        class="pointer"
                        style="width: 30px; height: 30px"
                        v-if="
                          row.userInfoList[0] &&
                            row.userInfoList[0].fieldValue &&
                            row.userInfoList[0].fieldValue.indexOf(
                              'https://'
                            ) !== -1
                        "
                        :src="row.userInfoList[0].fieldValue"
                      />
                      <span v-else>{{
                        (row.userInfoList[0] &&
                          row.userInfoList[0].fieldValue) ||
                          "-"
                      }}</span>
                    </div>
                  </div>
                </a-popover>
              </template>
            </a-table-column>
          </template>
          <template slot="action">
            <a-table-column
              width="180px"
              title="操作"
              align="center"
              fixed="right"
            >
              <!--            <div slot="title">-->
              <!--              操作-->
              <!--            </div>-->
              <div slot-scope="row" class="flex-center">
                <span
                  v-if="row.whetherCombineProduct === 2"
                  class="primary ph-x mr-1x"
                  @click="goOrderProductDetail(row)"
                  >详情</span
                >
                <a-dropdown
                  @click="e => e.preventDefault()"
                  v-permission="'appOrderNewOrderDetailMore'"
                >
                  <span class="pointer primary">
                    更多操作
                    <a-icon type="down" />
                  </span>
                  <a-menu slot="overlay" @click="dropdownClick($event, row)">
                    <!--                //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用-->
                    <!-- v-if="row.whetherCombineProduct === 1"目前不作限制，飞哥-->
                    <a-menu-item
                      v-permission="'appOrderNewOrderDetailMoreWriteoff'"
                      :disabled="
                        !checkAuthorityBtn(row).includes('核销') ||
                          (checkAuthorityBtn(row).includes('核销') &&
                            row.canUseNum !== -1 &&
                            row.canUseNum === row.useNum)
                      "
                      key="verification"
                      >核销
                    </a-menu-item>
                    <a-menu-item
                      v-permission="'appOrderNewOrderDetailMoreForcedwriteoff'"
                      key="forceVerification"
                      >强制核销
                    </a-menu-item>
                    <a-menu-item
                      v-permission="'appOrderNewOrderDetailMorerefund'"
                      :disabled="!checkAuthorityBtn(row).includes('退款')"
                      key="refund"
                      >退款
                    </a-menu-item>
                    <!--<a-menu-item-->
                    <!--  key="forceRefund"-->
                    <!--  v-permission="'appOrderNewOrderDetailMoreMandatoryrefund'"-->
                    <!--  >强制退款-->
                    <!--</a-menu-item>-->
                    <a-menu-item
                      key="changeValidity"
                      v-permission="
                        'appOrderNewOrderDetailMoreExtensionofvalidity'
                      "
                      >延长有效期
                    </a-menu-item>
                    <a-menu-item
                      key="changeAppointment"
                      v-permission="
                        'appOrderNewOrderDetailMoreModifyappointment'
                      "
                      :disabled="!checkAuthorityBtn(row).includes('修改预约')"
                      >修改预约
                    </a-menu-item>
                    <a-menu-item
                      key="replace"
                      v-permission="'appOrderNewOrderDetailMorereissue'"
                      :disabled="!checkAuthorityBtn(row).includes('补办')"
                      >补办
                    </a-menu-item>
                    <a-menu-item
                      key="activate"
                      v-permission="'appOrderNewOrderDetailMoreactivation'"
                      :disabled="!checkAuthorityBtn(row).includes('激活')"
                      >激活
                    </a-menu-item>
                    <a-menu-item
                      key="recordingFace"
                      v-permission="'appOrderNewOrderDetailMoreFace'"
                      :disabled="!checkAuthorityBtn(row).includes('补录人脸')"
                      >补录人脸
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </a-table-column>
          </template>
        </currency-table>
        <!--    查看图片-->
        <a-modal v-model="tableFaceShow" title="查看图片" centered>
          <img width="400" :src="tableFaceUrl" alt="" />
          <div slot="footer" class="text-c">
            <a-button type="primary" @click="tableFaceShow = false"
              >关闭</a-button
            >
          </div>
        </a-modal>
      </div>

      <template>
        <h3 class="title-mid">核销记录</h3>
        <verification-record
          @verificationOk="verificationOk"
          :data="productWriteOffInfoVOS"
        ></verification-record>
      </template>
      <template>
        <h3 class="title-mid">退款记录</h3>
        <refund-record-new :data="productRefundInfoVOS"></refund-record-new>
      </template>
    </main>

    <!-- 退款弹窗 -->
    <refund-public
      title="退款"
      :refund-show="refundShow"
      :refund-info="refundInfo"
      @success="refundOk"
      @cancel="refundShow = false"
    ></refund-public>
    <!-- 强制退款弹窗 -->
    <force-refund-public
      :title="forceTitle"
      :code-type="codeType"
      :refund-show="forceRefundShow"
      @success="forceRefundOk"
      @cancel="forceRefundShow = false"
    ></force-refund-public>
    <!--    修改预约-->
    <change-validity
      :visible.sync="appointmentPop"
      :detail-item="detailItem"
      :validity-type="validityType"
      @submit="submitForm"
    />
    <!--    补办-->
    <replace
      :replace-info="replaceInfo"
      :replace-show="replaceShow"
      @cancel="replaceShow = false"
      @success="replaceSuccess"
    ></replace>
    <!--支付方式-->
    <pay-way
      ref="payWay"
      :is-modal="true"
      :cart-id="cartId"
      @success="loadDetail"
    ></pay-way>
    <!--    人脸录入-->
    <add-face
      :vis.sync="faceShow"
      :detail="detailItem"
      @updateParentData="loadDetail"
    ></add-face>
    <!--    激活-->
    <active-ticket
      :auxiliary-id="auxiliaryId"
      :field-data="fieldData"
      :vis.sync="activeTicketShow"
      @success="loadDetail"
    ></active-ticket>
  </div>
</template>

<script>
import CurrencyTable from "@/components/table/CurrencyTable";
import RefundPublic from "./components/RefundPublic.vue";
import ForceRefundPublic from "./components/ForceRefundPublic.vue";
import PanelHead from "@/components/common/PanelHead";
import PayWay from "@/components/sell/commonents/PayWay";
import Replace from "./components/Replace";
import AddFace from "./components/AddFace.vue";
import ActiveTicket from "./components/ActiveTicket.vue";
import ChangeValidity from "./components/ChangeValidity";
import {
  orderDetailInfoNew,
  productWriteOff,
  productWriteOffForce,
  refundSuborderCheck,
  refundSuborder,
  refundProductCheck,
  refundProduct,
  refundProductForce,
  productReissueCheck
} from "@/api/order";
import { commonReissue, activateTravellerInfo } from "@/api/cart";
import RefundRecordNew from "./components/RefundRecordNew";
import VerificationRecord from "./components/VerificationRecord";
import { checkAuthorityBtn } from "../../../utils/global";
import { dateFormat } from "@/utils/global";
import moment from "moment";
const orderColumns = [
  {
    key: "id",
    title: "订单号",
    show: true,
    width: 200
  },
  {
    key: "ownerFormatTypeInfo",
    title: "业态",
    show: true,
    width: 170
  },
  {
    key: "productId",
    title: "产品id",
    show: true,
    width: 200
  },
  {
    key: "productCategoryName",
    title: "产品类型",
    show: true,
    width: 120
  },
  {
    key: "productTypeName",
    title: "自定义分类",
    show: true,
    width: 120
  },
  {
    key: "productName",
    title: "产品名称",
    show: true,
    width: 170
  },
  {
    key: "sellingPrice",
    title: "产品单价",
    show: true,
    width: 100,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "buyNum",
    title: "购买数量",
    show: true,
    width: 100
  },
  {
    key: "merchantDiscountAmount",
    title: "优惠金额",
    show: true,
    width: 100,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "integralDeductionAmount",
    title: "积分抵扣",
    show: true,
    width: 120
  },
  {
    key: "settlementPrice",
    title: "订单实收",
    show: true,
    width: 120,
    primary: "primary", // 设置金额红色
    scopedSlots: { customRender: "money" }
  },
  {
    key: "totalRefundNum",
    title: "已退数量",
    show: true,
    width: 120
  },
  {
    key: "totalRefundAmount",
    title: "已退金额",
    show: true,
    width: 100,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "cumOrderBalance",
    title: "订单实收余额",
    show: true,
    width: 150,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "orderStatusText",
    title: "订单状态",
    show: true,
    width: 100
  }
];
const productColumns = [
  {
    key: "auxiliaryId",
    title: "产品编号（票号）",
    show: true,
    width: 200
  },
  {
    key: "productName",
    title: "产品名称",
    show: true,
    width: 150
  },
  {
    key: "createTime",
    title: "下单时间",
    show: true,
    width: 170,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "orderId",
    title: "订单号",
    show: true,
    width: 200
  },
  {
    key: "mainOrderId",
    title: "总单号",
    show: true,
    width: 200
  },
  {
    key: "orderBusinessTypeText",
    title: "业务类型",
    show: true,
    width: 150
  },
  {
    key: "subMerchantScenicName",
    title: "子景区",
    show: true,
    width: 150
  },
  {
    key: "productOwnerMerchantName",
    title: "商户",
    show: true,
    width: 150
  },
  {
    key: "totalAmount",
    title: "产品单价",
    show: true,
    width: 120,
    scopedSlots: { customRender: "money" }
  },
  {
    key: "receivedAmount",
    title: "产品实收",
    show: true,
    width: 120,
    primary: "primary",
    scopedSlots: { customRender: "money" }
  },
  {
    key: "statusText",
    title: "产品状态",
    show: true,
    width: 120
  },
  {
    key: "cumProductValidity",
    title: "产品有效期",
    show: true,
    width: 230
  },
  {
    key: "afterWriteOffValidTime",
    title: "核销后有效期",
    show: true,
    width: 170,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "combineAuxiliaryId",
    title: "关联产品编号（票号）",
    show: true,
    width: 200
  },
  // {
  //   key: "proofOfProductName",
  //   title: "产品凭证名称",
  //   show: true,
  //   width: 150
  // },
  // {
  //   key: "proofOfProductInfo",
  //   title: "产品凭证信息",
  //   show: true,
  //   width: 180,
  //   ellipsis: true,
  //   scopedSlots: { customRender: "face" } // 存在人脸情况
  // },
  {
    key: "userInfoList",
    title: "游客信息",
    popover: true,
    show: true,
    width: 220
  },
  {
    key: "touristTypeName",
    title: "游客类型",
    show: true,
    width: 150
  },
  {
    key: "touristDiscountType",
    title: "游客优惠",
    show: true,
    width: 150
  },
  {
    key: "useDate",
    title: "预约日期",
    show: true,
    width: 180,
    scopedSlots: { customRender: "date", valueFormat: "YYYY-MM-DD" }
  },
  {
    key: "cumAppointmentDate",
    title: "预约时段",
    show: true,
    width: 150
  },
  {
    key: "canUseNum",
    title: "可核销次数",
    show: true,
    width: 120
  },
  {
    key: "useNum",
    title: "已核销次数",
    show: true,
    width: 120
  },
  {
    key: "writeOffPersonName",
    title: "核销员",
    show: true,
    width: 170
  },
  {
    key: "writeOffTime",
    title: "核销时间",
    show: true,
    width: 170,
    scopedSlots: { customRender: "date" }
  },
  {
    key: "writeOffChannelText",
    title: "核销渠道",
    show: true,
    width: 170
  }
];
export default {
  name: "TabDetail",
  components: {
    VerificationRecord,
    RefundRecordNew,
    PanelHead,
    RefundPublic,
    ForceRefundPublic,
    CurrencyTable,
    Replace,
    PayWay,
    AddFace,
    ActiveTicket,
    ChangeValidity
  },
  data() {
    return {
      isTrue: false, // 临时隐藏多选
      moment,
      orderColumns,
      productColumns,
      checkAuthorityBtn,
      selectedRowKeys: [], // 多选
      forceType: "",
      forceTitle: "强制退款",
      codeType: "FORCED_TO_REFUND",
      forceRefundShow: false, // 强制退单弹框
      refundShow: false, //退款弹窗
      refundInfo: {}, //退款信息
      orderId: this.$route.query.orderId,
      detailItem: {}, // 点击操作当前产品对象的
      refundList: [3, 9, 16, 18], //可退款状态
      mainOrderInfo: {}, // 总单信息
      orderBasisInfoVOS: [], // 订单信息列表
      orderDetailInfoVOS: [], // 产品信息列表
      productWriteOffInfoVOS: [], // 核销纪录
      productRefundInfoVOS: [], // 退款纪录
      multipleSelection: [], // 产品信息列表多选
      operator: {
        verificationDisable: true, // 核销
        forceVerificationDisable: true, // 强制核销
        refundDisable: true, // 退款
        forceRefundDisable: true, // 强制退款
        changeAppointment: true // 修改预约
      },
      // 补办
      replaceShow: false, // 补办弹框
      replaceInfo: {}, // 补办前获取补办信息
      cartId: 0, //  补办后生成的购物车id
      faceShow: false, // 人脸录入
      activeTicketShow: false, // 激活弹框
      fieldData: {}, // 激活时需要显示的动态表单
      appointmentPop: false, // 修改预约弹框
      validityType: "", // 修改预约类型，延长有效期
      auxiliaryId: "", // 激活时的票号
      tableFaceShow: false,
      tableFaceUrl: ""
    };
  },
  created() {
    this.loadDetail();
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.selectionChange
      };
    }
  },
  // 防止父组件没设置validate属性而报错，因为payway组件有引入  inject: ["validate"],
  provide() {
    return {
      validate: {}
    };
  },
  methods: {
    // 点击人脸
    imgClick(url) {
      this.tableFaceShow = true;
      this.tableFaceUrl = url;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return (
        current &&
        current < this.moment(this.detailItem.beginValidTime).add(0, "day")
      );
    },
    // 订单信息退款
    orderRefundChecks(row) {
      this.refundType = "orderRefund";
      this.detailItem = row;
      refundSuborderCheck({
        mainOrderId: this.orderId,
        productOrderId: row.id
      })
        .then(res => {
          this.refundShow = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    // 产品退款校验
    refundChecks(row) {
      this.refundType = "productRefund";
      this.detailItem = row;
      refundProductCheck({
        mainOrderId: this.orderId,
        detailId: row.id
      })
        .then(res => {
          this.refundShow = true;
          this.refundInfo = res || {};
        })
        .catch(() => {});
    },
    //完成退款
    refundOk(obj) {
      this.refundShow = false;
      if (this.refundType === "orderRefund") {
        // 二级订单退款
        refundSuborder({
          mainOrderId: this.orderId,
          productOrderId: this.detailItem.id, //产品属于子订单1, 门票属于子产品2
          remarks: obj.remarks
        })
          .then(res => {
            this.$message.success(
              res.refundFailMsg ? res.refundFailMsg : "退款成功！"
            );
            this.loadDetail();
          })
          .catch(() => {});
      } else if (this.refundType === "productRefund") {
        // 产品退款
        refundProduct({
          mainOrderId: this.orderId,
          detailId: this.detailItem.id, //产品属于子订单1, 门票属于子产品2
          remarks: obj.remarks
        })
          .then(res => {
            this.$message.success(
              res.refundFailMsg ? res.refundFailMsg : "退款成功！"
            );
            this.loadDetail();
          })
          .catch(() => {});
      }
    },
    // 强制完成退款/核销
    forceRefundOk(obj) {
      this.forceRefundShow = false;
      if (this.forceType === "forceRefund") {
        refundProductForce({
          mainOrderId: this.orderId,
          detailId: this.detailItem.id,
          remarks: obj.remarks
        })
          .then(() => {
            this.loadDetail();
            this.$message.success("强制退款成功！");
          })
          .catch(() => {});
      } else if (this.forceType === "forceVerification") {
        productWriteOffForce({
          code: this.detailItem.auxiliaryId
        })
          .then(() => {
            this.loadDetail();
            this.$message.success("强制核销成功！");
          })
          .catch(() => {});
      }
    },
    // 加载订单详情
    loadDetail() {
      orderDetailInfoNew({
        id: this.orderId
      })
        .then(res => {
          let orderManageOrderDetail = JSON.parse(
            sessionStorage.getItem("orderManageOrderDetail")
          );
          this.mainOrderInfo = {
            ...orderManageOrderDetail,
            ...res.mainOrderInfo
          }; // 总单信息
          this.orderBasisInfoVOS = res.orderBasisInfoVOS || []; // 订单信息列表
          res.orderDetailInfoVOS.forEach(item => {
            item.canUseNum = item.canUseNum !== -1 ? item.canUseNum : "无限次";
            // dateFormat
            if (item.beginValidTime && item.endValidTime) {
              // 前端拼接产品有效期
              item.cumProductValidity =
                dateFormat(item.beginValidTime, "YYYY-MM-DD hh:mm:ss") +
                "~" +
                dateFormat(item.endValidTime, "YYYY-MM-DD hh:mm:ss");
            }
            if (
              item.appointmentPeriodStartTime &&
              item.appointmentPeriodEndTime
            ) {
              // 前端拼接产品预约时段
              item.cumAppointmentDate =
                dateFormat(item.appointmentPeriodStartTime, "hh:mm") +
                "~" +
                dateFormat(item.appointmentPeriodEndTime, "hh:mm");
            }
            // 游客信息
            if (item.userInfoConvert) {
              // 前端拼接游客信息
              item.userInfoConvert = JSON.parse(item.userInfoConvert) || {};
              if (item.userInfoConvert.wristbandsNo) {
                item.userInfoList.push({
                  fieldName: "卡腕带",
                  fieldValue: item.userInfoConvert.wristbandsNo
                });
              }
            }
            if (item.userInfoList && item.userInfoList.length) {
              let valueArr = [];
              let emptyArr = [];
              // 排序，有值最前，图片次，null的放最后
              item.userInfoList.forEach(te => {
                if (te.fieldValue && te.fieldValue.includes("https://")) {
                  valueArr.push(te);
                } else if (te.fieldValue) {
                  valueArr.unshift(te);
                } else {
                  emptyArr.push(te);
                }
              });
              item.userInfoList = valueArr.concat(emptyArr);
            }
          });
          this.orderDetailInfoVOS = res.orderDetailInfoVOS || []; // 产品信息列表
          this.productWriteOffInfoVOS = res.productWriteOffInfoVOS || []; // 产品核销纪录
          this.productRefundInfoVOS = res.productRefundInfoVOS || []; // 产品退款纪录
          let obj = {
            id: "合计",
            buyNum: 0,
            merchantDiscountAmount: 0,
            integralDeductionAmount: 0,
            settlementPrice: 0,
            totalRefundNum: 0,
            totalRefundAmount: 0,
            cumOrderBalance: 0
          };
          this.orderBasisInfoVOS.forEach(item => {
            item.cumOrderBalance =
              Math.floor(
                (item.settlementPrice - item.totalRefundAmount) * 100
              ) / 100; // 订单实收金额
            obj.buyNum += item.buyNum;
            obj.merchantDiscountAmount += item.merchantDiscountAmount;
            obj.integralDeductionAmount += item.integralDeductionAmount;
            obj.settlementPrice += item.settlementPrice;
            obj.totalRefundNum += item.totalRefundNum;
            obj.totalRefundAmount += item.totalRefundAmount;
            obj.cumOrderBalance += item.cumOrderBalance;
          });
          if (this.orderBasisInfoVOS.length) this.orderBasisInfoVOS.push(obj);
        })
        .catch(() => {});
    },
    // 产品列表多选
    selectionChange(val) {
      this.selectedRowKeys = val;
      // 初始化
      this.operator = {
        verificationDisable: false,
        forceVerificationDisable: false,
        refundDisable: false,
        forceRefundDisable: false,
        changeAppointment: false
      };
      // 多选产品列表时根据校验规则判定按钮是否可点击
      if (val.length) {
        let arr = [];
        this.orderDetailInfoVOS.forEach(tem => {
          // 查找出选择的对象
          let i = val.findIndex(te => te === tem.id);
          if (i !== -1) {
            arr.push(tem);
          }
        });
        arr.forEach(item => {
          // 组合产品不显示核销，强制核销，退款，强制退款
          if (
            !this.checkAuthorityBtn(item).includes("核销") ||
            item.combineAuxiliaryId
          )
            this.operator.verificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制核销") ||
            item.combineAuxiliaryId
          )
            this.operator.forceVerificationDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("退款") ||
            item.combineAuxiliaryId
          )
            this.operator.refundDisable = true;
          if (
            !this.checkAuthorityBtn(item).includes("强制退款") ||
            item.combineAuxiliaryId
          )
            this.operator.forceRefundDisable = true;
          if (!this.checkAuthorityBtn(item).includes("修改预约"))
            this.operator.changeAppointment = true;
        });
      } else {
        this.operator = {
          verificationDisable: true,
          forceVerificationDisable: true,
          refundDisable: true,
          forceRefundDisable: true,
          changeAppointment: true
        };
      }
    },
    // 产品列表dropdown按钮操作
    goOrderProductDetail(row) {
      // go 订单产品详情
      this.$router.push({
        path: "/home/orderNew/ProductDetail",
        query: { id: row.id }
      });
    },
    // 产品信息列表表格操作按钮
    dropdownClick({ key }, row) {
      switch (key) {
        case "verification":
          if (row) {
            // 单个 核销
            this.$confirm({
              title: "确定要执行核销操作吗?",
              okText: "确定",
              onOk: () => {
                productWriteOff({
                  code: row.auxiliaryId
                }).then(res => {
                  if (res.code === 500) {
                    this.$message.warning(
                      res.otherMsg ? res.otherMsg : "核销失败！"
                    );
                  } else {
                    this.$message.success(
                      res.otherMsg ? res.otherMsg : "核销成功！"
                    );
                  }
                  this.loadDetail();
                });
              }
            });
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceVerification":
          if (row) {
            // 单个 强制核销
            this.forceType = "forceVerification";
            this.detailItem = row;
            this.forceTitle = "强制核销";
            this.codeType = "FORCED_TO_WRITE_OFF";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "refund":
          if (row) {
            // 单个 退款
            this.refundChecks(row);
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "forceRefund":
          if (row) {
            // 单个 强制退款
            this.forceType = "forceRefund";
            this.detailItem = row;
            this.forceTitle = "强制退款";
            this.codeType = "FORCED_TO_REFUND";
            this.forceRefundShow = true;
          } else {
            // 批量
            console.log("批量");
          }
          break;
        case "changeValidity":
          if (row) {
            // 单个
            this.validityType = "changeValidity";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "changeAppointment":
          if (row) {
            // 单个
            this.validityType = "changeAppointment";
            this.appointmentPop = true;
            this.detailItem = row;
          }
          break;
        case "replace":
          if (row) {
            // 单个 补办
            this.detailItem = row;
            productReissueCheck({
              id: row.id
            }).then(res => {
              this.replaceInfo = res;
              this.replaceShow = true;
            });
          }
          break;
        case "activate":
          if (row) {
            // 激活
            activateTravellerInfo({
              orderDetailId: row.id
            }).then(res => {
              this.auxiliaryId = row.auxiliaryId;
              this.activeTicketShow = true;
              this.fieldData = res;
            });
          }
          break;
        case "recordingFace":
          if (row) {
            // 单个 补录人脸
            this.detailItem = row;
            this.faceShow = true;
          }
          break;
        // case "activate":
        //   if (row) {
        //     // 单个 补办
        //     console.log('激活')
        //   }
        //   break;
      }
    },
    // 批量修改预约
    submitForm() {
      this.$message.success("修改成功!");
      this.loadDetail();
    },
    resetForm(formName) {
      this.appointmentPop = false;
      this.$refs[formName].resetFields();
    },
    // 反核销操作成功后刷新数据
    verificationOk() {
      this.loadDetail();
    },
    // 补办
    replaceSuccess(val) {
      this.replaceShow = false;
      commonReissue({
        orderDetailId: this.detailItem.id,
        changePrice: Math.round(val.price * 10000) / 100, // 补办费用转换为分,
        remark: val.remark
      }).then(res => {
        this.cartId = res.id;
        this.$nextTick(() => {
          this.$refs.payWay.pay();
        });
      });
    }
  }
};
</script>

<style scoped lang="less">
.detail {
  .gray {
    width: 100px;
  }
}
.top-dropdown {
  position: absolute;
  margin-top: -38px;
}
.w-90 {
  width: 120px;
}
.w-80 {
  width: 80px;
}
</style>
