<template>
  <div class="wrapper flex-start flex-col">
    <!-- 头部 -->
    <panel-head title="取单"></panel-head>
    <div class="main flex-grow">
      <template v-if="cartList.length">
        <section v-for="item in cartList" :key="item.id" class="item shadow">
          <header class="flex-between header bd-b">
            <p>
              订单总价：<span class="price">{{
                item.totalPrice || 0 | money
              }}</span>
            </p>
            <p>
              挂单时间：{{
                item.pauseCartTime || "-" | date("YYYY-MM-DD hh:mm:ss")
              }}
            </p>
          </header>
          <section class="p-2x">
            <a-row :gutter="[16, 16]">
              <a-col
                :xxl="6"
                :xl="8"
                :lg="12"
                v-for="productItem in item.productItemList"
                :key="productItem.productId"
              >
                <div class="card flex-between flex-col shadow">
                  <p class="text-1 font-xl">{{ productItem.productName }}</p>
                  <p>数量：{{ productItem.buyNum }}</p>
                  <p>单价：{{ productItem.sellingPrice || 0 | money }}</p>
                  <p>小计：{{ productItem.allSettlementPrice || 0 | money }}</p>
                </div>
              </a-col>
            </a-row>
          </section>
          <footer class="flex-end footer">
            <a-button
              type="primary"
              shape="round"
              ghost
              class="mr-2x"
              @click="deleteOrder(item.id)"
              >删除</a-button
            >
            <a-button type="primary" shape="round" @click="takeOrder(item.id)"
              >取单</a-button
            >
          </footer>
        </section>
      </template>
      <a-empty v-else :image="simpleImage" />
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        type="primary"
        size="large"
        class="btn-lg"
        shape="round"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
    </footer>
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import PanelHead from "@/components/common/PanelHead";
import { mapActions } from "vuex";
import { shoppingCartList, takeOut, deleteOrCancel } from "@/api/cart"; // MS-产品中心接口 3.0

export default {
  name: "TakeOrderList",
  components: {
    PanelHead
  },
  data() {
    return {
      cartId: this.$route.query.cartId, // 购物车id
      cartList: [] // 购物车列表
    };
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  created() {
    this.getShoppingCartList(); // 获取挂单购物车列表
  },
  methods: {
    /**** 数据交互方法 **/
    ...mapActions("car", ["SetCartId"]),
    ...mapActions("order", ["setCartList"]),
    // 获取挂单购物车列表
    getShoppingCartList() {
      shoppingCartList({ id: this.cartId })
        .then(res => {
          this.cartList = res;
          if (res.length > 0) {
            this.$store.commit("common/updateTakeOrderBtnStatus", true); // 更改vuex中的isEnableTakeOrderBtn的值为true
          } else {
            this.$store.commit("common/updateTakeOrderBtnStatus", false); // 更改vuex中的isEnableTakeOrderBtn的值为false
          }
        })
        .catch(() => {});
    },

    // 获取订单列表
    getOrder() {
      takeOut({ id: this.cartId })
        .then(res => {
          this.getShoppingCartList();
          this.setCartList(res); // 更新购物车
          localStorage.setItem("cartList", JSON.stringify(res));
          this.$router.back();
        })
        .catch(() => {});
    },

    // 取单
    takeOrder(cartId) {
      let that = this;
      that.cartId = cartId;
      that.$confirm({
        title: "提示",
        content: "将覆盖购物车中已选择的产品，是否继续？",
        onOk() {
          that.getOrder();
          that.getShoppingCartList();
        }
      });
    },

    // 删除取单
    deleteOrder(cartId) {
      deleteOrCancel({ id: cartId })
        .then(() => {
          this.getShoppingCartList();
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom";
.wrapper {
  margin-right: 0;
  .main {
    margin-top: 16px;
    background: @bgColor;
    overflow-x: hidden;
    overflow-y: auto;
    .item {
      margin-bottom: 16px;
      background: #fff;
      border-radius: 5px;
      &:first-child {
        border: none;
      }
      .header {
        padding: 12px 16px;
        font-size: 16px;
        background: @primary-light1;
      }
      .card {
        height: 130px;
        padding: 12px;
        border-radius: 5px;
      }
      .footer {
        padding: 0 16px 16px 16px;
      }
    }
  }
}
</style>
