<template>
  <div class="wrapper flex-col flex-start">
    <!-- 头部 -->
    <panel-head>
      <div>
        <span class="mr-4x font-xl text-v">开通会员特权</span>
        <b class="text-v">{{ memberInfoOne.name }}</b>
        <span class="yellow ml-2x text-v">
          <span class="iconfont icondengji"></span>
          <span> {{ memberInfoOne.currentLevelName }}</span>
        </span>
        <span class="text-v ml-2x">{{ memberInfoOne.mobile }}</span>
      </div>
    </panel-head>

    <!-- 主体 -->
    <div class="main mt-2x">
      <div class="p-2x bd-b">选择会员特权</div>
      <ul class="cards flex-start align-center pl-2x">
        <li
          v-for="item in privilegeList"
          :key="item.id"
          class="item level shadow flex-between flex-col"
          :style="`background-image: url(${item.skuImages})`"
          @click="selectProduct(item)"
        >
          <p class="font-xxl text-1">{{ item.name }}</p>
          <a-row>
            <a-col :span="12">
              <p class="font-l">会员折扣</p>
              <p class="mv-x">
                消费折扣：
                <!-- 消费折扣(counsumeDiscount)1:无折扣 2:消费享折扣 -->
                <template v-if="item.counsumeDiscount === 1">无折扣</template>
                <template v-else>
                  消费享{{ item.discountRate / 10 }}折
                </template>
              </p>
              <p>
                积分特权：
                <span v-if="item.integralMultiple > 100">
                  {{ item.integralMultiple / 100 }} 倍积分</span
                >
                <span v-if="item.integralMultiple === 100"> 无倍数</span>
              </p>
            </a-col>
            <a-col :span="12">
              <p class="font-l">开通会员礼包</p>
              <p class="mv-x">
                赠送积分：送{{ item.spuSpecInfo.giftIntegralNum }}积分
              </p>
              <div>
                优惠券：
                <span
                  v-for="coupon in item.giftCoupons"
                  :key="coupon.giftCouponNames"
                  >{{ coupon }}
                </span>
              </div>
            </a-col>
          </a-row>
          <p>
            有效期： {{ item.spuSpecInfo.expirationNum
            }}{{ expirationTimeUnit[item.spuSpecInfo.expirationTimeUnit] }}
          </p>
          <div class="checked" v-show="currOrderId === item.id">
            <img src="../../../assets/images/member-checked.png" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <!-- 底部 -->
    <div class="footer" v-if="privilegeList.length">
      <a-button
        type="primary"
        size="large"
        shape="round"
        class="flex-between align-center btn-lg"
        @click="createOrder"
      >
        <span>结账</span>
        <span> {{ productParams.sellingPrice || 0 | money }}</span>
      </a-button>
    </div>
    <!--支付方式-->
    <pay-way
      :cart-id="cartId"
      is-modal
      ref="PayWay"
      @cancel="clearCart"
      @success="success"
    ></pay-way>
  </div>
</template>

<script>
import PanelHead from "@/components/common/PanelHead";
import { mapGetters } from "vuex";
import { productFrontMemberprivilege } from "@/api/search";
import { memberInfoOne } from "@/api/member"; // MS-会员中心接口
import { privilegeOrder } from "@/api/cart"; // MS-购物车中心接口
import PayWay from "../../../components/sell/commonents/PayWay.vue";

export default {
  name: "MemberUpgrade",
  components: { PanelHead, PayWay },
  data() {
    return {
      memberInfoOne: {}, // 会员详情
      currOrderId: 0, // 当前充值套餐id
      productParams: {}, // 产品参数对象
      privilegeList: [], // 会员特权列表
      // 开通有效期单位：1年，2月，3日
      expirationTimeUnit: {
        1: "年",
        2: "月",
        3: "日"
      },
      cartId: 0
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getMemberInfoOne(); // 根据会员id获取会员详情
    this.getPrivilegeList(); // 获取会员特权列表
  },
  methods: {
    clearCart() {
      this.cartId = 0;
    },
    success() {
      this.$emit("success", true);
    },
    // 根据会员id获取会员详情
    getMemberInfoOne() {
      memberInfoOne({ id: this.memberId })
        .then(res => {
          this.memberInfoOne = res;
        })
        .catch(() => {});
    },

    // 获取会员等级列表
    getPrivilegeList() {
      productFrontMemberprivilege()
        .then(res => {
          this.privilegeList = res;
        })
        .catch(() => {});
    },

    // 选择会员的套餐
    selectProduct(item) {
      this.currOrderId = item.id; // 变更充值套餐id
      this.productParams = item; // 变更产品参数对象
    },

    // 会员特权
    createOrder() {
      if (!this.productParams.id) {
        this.$message.warning("请先选择会员特权");
        return;
      }
      privilegeOrder({
        productId: this.currOrderId,
        purchaserMemberId: this.memberInfoOne.id
      }).then(({ id }) => {
        this.cartId = id;
      });
      let t = setTimeout(() => {
        this.$refs.PayWay.pay();
        clearTimeout(t);
        t = null;
      }, 1000);
    },
    validate() {
      if (!this.cartId) return false;
      return true;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

/*卡片*/
.cards {
  .item {
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    width: 388px;
    height: 186px;
    margin: 16px 16px 16px 0;
    border-radius: 16px;
    background-size: cover;
    padding: 16px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    color: #fff;
    text-shadow: 2px 2px 2px #666;
    .checked {
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
  }
}
</style>
