<template>
  <div>
    <a-modal
      :title="title"
      @cancel="cancel"
      :visible.sync="replaceShow"
      width="600px"
    >
      <div>
        <a-row class="mb-1x">
          <a-col :span="5">产品名称：</a-col>
          <a-col :span="19">{{ replaceInfo.productName }}</a-col>
        </a-row>
        <a-row class="mb-1x">
          <a-col :span="5">已办理次数：</a-col>
          <a-col :span="19">{{ replaceInfo.reissueTimes }}</a-col>
        </a-row>
        <a-form-model
          :model="replaceForm"
          ref="form"
          :rules="rules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
        >
          <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
          <a-form-model-item prop="price" label="补办费用">
            <a-input
              placeholder="请输入金额"
              prefix="￥"
              v-model="replaceForm.price"
            />
          </a-form-model-item>
          <a-form-model-item label="补办说明" prop="remark">
            <a-input
              type="textarea"
              placeholder="请输入备注"
              v-model="replaceForm.remark"
            ></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="confirmRefund">确定补办</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { checkMoney } from "../../../../utils/global";

export default {
  name: "Replace",
  props: {
    title: {
      type: String,
      default: "补办"
    },
    replaceInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    replaceShow: { type: Boolean, default: false }
  },
  data() {
    return {
      replaceForm: {
        remark: "",
        price: ""
      },
      // 表单验证规则
      rules: {
        price: [
          { required: true, message: "请输入补办费用", trigger: "change" },
          { validator: checkMoney, trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    replaceInfo: {
      immediate: true,
      handler(newValue) {
        if (!isNaN(newValue.reissueFee)) {
          this.replaceForm.price = newValue.reissueFee / 100 || ""; // 统一的分转为元
        } else {
          this.replaceForm.price = "";
        }
      }
    }
  },
  created() {},
  methods: {
    // 关闭
    cancel() {
      this.$emit("cancel");
      this.replaceForm.remark = "";
      this.$refs.form.resetFields();
    },
    //确认补办
    confirmRefund() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit("success", this.replaceForm);
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped></style>
