<template>
  <div>
    <header class="mb-2x flex-start align-center">
      <span class="flex-shrink">核销单号</span>
      <div>
        <a-input-search
          class="ml-2x"
          placeholder="请输入核销票号/核销单号/组合产品票号"
          suffix-icon="el-icon-search"
          allowClear
          @change="inputChange"
        ></a-input-search>
      </div>
    </header>
    <!--列表-->
    <a-table
      :scroll="{ x: 1366 }"
      :pagination="false"
      rowKey="writeOffId"
      :columns="tableColumns"
      :data-source="refundList"
      header-cell-class-name="bg-gray bd-t"
    >
      <template slot="writeOffTime" slot-scope="{ writeOffTime }">{{
        writeOffTime | date("YYYY-MM-DD hh:mm:ss")
      }}</template>
      <template slot="action" slot-scope="row">
        <a-button
          type="link"
          class="p-0 mr-1x"
          :disabled="row.writeOffStatus === 2"
          @click="cancelVerificationClick(row)"
          >反核销</a-button
        >
      </template>
    </a-table>
    <a-modal
      @cancel="onRefundClose"
      title="产品反核销"
      :visible="refund"
      width="700px"
    >
      <div>
        <a-form-model
          class="refundForm"
          ref="refundForm"
          :model="refundForm"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-form-model-item label="门票名称:">
            <span>{{ refundData.productName }}</span>
          </a-form-model-item>
          <a-form-model-item label="票号:">
            <span>{{ refundData.auxiliaryId }}</span>
          </a-form-model-item>
          <!--          接口无子票号-->
          <!--          <a-form-model-item label="子票票号:">-->
          <!--            <span>{{ refundData.subAuxiliaryId }}</span>-->
          <!--          </a-form-model-item>-->
          <a-form-model-item label="核销时间:">
            <span>{{
              refundData.writeOffTime | date("YYYY-MM-DD HH:MM:SS")
            }}</span>
          </a-form-model-item>
          <a-form-model-item label="核销人:">
            <span>{{ refundData.writeOffPersonName || "-" }}</span>
          </a-form-model-item>
          <a-form-model-item label="核销渠道:">
            <span>{{ refundData.writeOffChannelText }}</span>
          </a-form-model-item>
          <a-form-model-item label="反核销说明:" prop="refundRemark">
            <a-input v-model="refundForm.refundRemark"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="onRefundSave">保存</a-button>
        <a-button @click="onRefundClose">取 消</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { productTicketUncheck } from "@/api/order";
const tableColumns = [
  {
    title: "单品编号（票号）",
    dataIndex: "auxiliaryId",
    key: "auxiliaryId",
    width: 182
  },
  {
    title: "单品名称",
    dataIndex: "productName",
    key: "productName",
    width: 120
  },
  {
    title: "组合产品编号（票号）",
    dataIndex: "combineAuxiliaryId",
    key: "combineAuxiliaryId",
    width: 180
  },
  {
    title: "组合产品名称",
    dataIndex: "combineProductName",
    key: "combineProductName",
    width: 120
  },
  {
    title: "核销时间",
    key: "writeOffTime",
    scopedSlots: { customRender: "writeOffTime" },
    width: 180
  },
  {
    title: "核销员",
    dataIndex: "writeOffPersonName",
    key: "writeOffPersonName",
    width: 120
  },
  {
    title: "核销渠道",
    dataIndex: "writeOffChannelText",
    key: "writeOffChannelText",
    width: 120
  },
  {
    title: "核销单号",
    dataIndex: "writeOffId",
    key: "writeOffId",
    width: 180
  },
  {
    title: "核销状态",
    dataIndex: "writeOffStatusText",
    key: "writeOffStatusText",
    width: 120
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    width: 100
  }
];
export default {
  name: "VerificationRecord",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newValue) {
        this.refundList = newValue || [];
        this.refundListOld = this.refundList;
      }
    }
  },
  data() {
    return {
      tableColumns,
      refundList: [],
      refundListOld: [], // 复制数据
      refund: false,
      refundData: {}, //核销详情
      refundForm: {
        refundRemark: ""
      },
      rules: {
        refundRemark: [
          { required: true, message: "请输入说明", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 核销单号change
    inputChange({ target }) {
      // 单品号，组合产品号，核销单号
      this.refundList = this.refundListOld.filter(
        item =>
          item.auxiliaryId.toString().indexOf(target.value) !== -1 ||
          (item.combineAuxiliaryId &&
            item.combineAuxiliaryId.toString().indexOf(target.value) !== -1) ||
          (item.writeOffId &&
            item.writeOffId.toString().indexOf(target.value) !== -1)
      );
      if (!target.value) this.refundList = this.refundListOld;
    },
    // 反核销
    cancelVerificationClick(row) {
      this.refund = true;
      this.refundData = row;
    },
    //取消核销取消
    onRefundClose() {
      this.refundForm.refundRemark = "";
      this.$refs.refundForm.clearValidate();
      this.refund = false;
    },
    //反核销保存
    onRefundSave() {
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          productTicketUncheck({
            id: this.refundData.writeOffId,
            remark: this.refundForm.refundRemark
          })
            .then(() => {
              this.refund = false;
              this.$emit("verificationOk");
            })
            .catch(() => {});
        } else {
          return this.$message.warning("验证不通过，请重新检查");
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.refundForm {
  /deep/.ant-form-item {
    margin-bottom: 0px;
  }
}
</style>
