<!--
  @name: 身份证输入框
  @author: fengyanlong
  @attr: {
    v-model:{
      type: String   //身份证ID
    }
  }
  @event:{
    change:fn()   //组件内身份证id改变时触发
    readCard:return{
      sex:男、女
      photo:base64图片
      name:姓名
      birth: 生日  yyyy-mm-dd
    } //把读到的身份证信息返回到组件外
  }
-->
<template>
  <a-input
    :size="size"
    :value="value"
    :placeholder="currentPlaceholder"
    @focus="listen"
    @pressEnter="pressEnter"
    :maxLength="18"
    @change="handleChange"
    :allow-clear="allowClear"
    ref="focus"
  >
    <span
      @click="pressEnter"
      slot="addonAfter"
      class="iconfont iconcredentials_icon myicon primary"
    ></span>
  </a-input>
</template>

<script>
import CameraService from "@/common/domains/hardware-domain/cameraService";
export default {
  name: "IdCardInput",
  data() {
    return {
      currentPlaceholder: "请刷身份证/输入身份证号",
      cardNo: "",
      cardInfo: null,
      ready: false,
      interval: "",
      timer: null
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "default"
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    // 记录当前刷身份证号
    currentCardId: {
      type: String,
      default: ""
    }
  },

  created() {
    if (this.placeholder) {
      this.currentPlaceholder = this.placeholder;
    } else if (this.medicalCard) {
      this.currentPlaceholder = "请刷社保卡/输入社保卡号";
    }
  },

  mounted() {
    // this.stop()
    // this.listen();
  },
  methods: {
    readIdCard(res) {
      if (res) {
        this.cardInfo = JSON.parse(res);
        let id = this.medicalCard ? this.cardInfo.xbCard : this.cardInfo.id;
        // 如果购物信息读取身份证发现与上一次读取是同一个证件号，不作处理
        if (this.currentCardId === this.cardInfo.id) {
          // if (this.timer) {
          //   return;
          // }
          // this.timer = setTimeout(() => {
          //   this.$message.warning("证件号读取重复!");
          //   clearTimeout(this.timer);
          //   this.timer = null;
          // }, 1000);
          return;
        }
        this.$emit("input", id);
        this.$emit("change", id);
        this.$emit("readCard", this.cardInfo);
      }
    },
    //因为扫码不触发 readIdCard
    pressEnter() {
      this.$emit("pressEnter");
    },
    listen(e) {
      if (e && e.target.value) {
        // 如果当前有值，则赋值
        this.$emit("update:current-card-id", e.target.value);
      }
      let Hardware = localStorage.getItem("Hardware");
      // 不用这个配置了 这个也能用 window.readIdCard = this.readIdCard;
      if (Hardware && JSON.parse(Hardware).gaoPaiYi) {
        //开启了高拍仪 则用高拍仪识别身份证
        CameraService.myReadIDCard()
          .then(res => {
            this.cardNo = res.IDCardInfo.cardID;
            this.$emit("input", res.IDCardInfo.cardID);
            this.$emit("change", res.IDCardInfo.cardID);
            res.IDCardInfo.photo = res.IDCardInfo.photoBase64;
            this.$emit("readCard", res.IDCardInfo);
          })
          .catch(() => {
            this.$message.warning("请重新点击输入框识别！");
          });
      } else {
        window.readIdCard = this.readIdCard;
      }
    },
    handleChange(e) {
      if (!e.target.value) {
        this.$emit("update:current-card-id", "");
      }
      this.$emit("input", e.target.value);
      this.$emit("change", e.target.value);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.readIdCard = () => {};
  }
};
</script>

<style lang="less" scoped>
.iconfont.myicon {
  font-size: 20px;
}
</style>
