<template>
  <a-modal
    @cancel="resetForm"
    :title="validityType === 'changeValidity' ? '延长有效期' : '修改预约'"
    :visible="visible"
    @ok="submitForm('form')"
    width="600px"
  >
    <a-row>
      <a-col span="6">产品名称：</a-col>
      <a-col span="18">{{ detailItem.productName }}</a-col>
    </a-row>
    <a-row class="mt-1x">
      <a-col span="6">使用有效期：</a-col>
      <a-col span="18">
        {{ detailItem.beginValidTime | date("YYYY-MM-DD hh:mm:ss") }} -
        {{ detailItem.endValidTime | date("YYYY-MM-DD hh:mm:ss") }}
      </a-col>
    </a-row>
    <a-row class="mt-1x" v-if="detailItem.afterWriteOffValidTime">
      <a-col span="6">核销后有效期：</a-col>
      <a-col span="18">
        {{ detailItem.afterWriteOffValidTime | date("YYYY-MM-DD hh:mm:ss") }}
      </a-col>
    </a-row>
    <template v-if="validityType === 'changeAppointment'">
      <a-row class="mt-1x">
        <a-col span="6">预约日期：</a-col>
        <a-col span="18" v-if="detailItem.appointmentDate">{{
          detailItem.appointmentDate | date("YYYY-MM-DD")
        }}</a-col>
        <a-col span="18" v-else>{{
          detailItem.useDate | date("YYYY-MM-DD")
        }}</a-col>
      </a-row>
      <a-row class="mt-1x">
        <a-col span="6">预约时段：</a-col>
        <a-col span="18" v-if="detailItem.appointmentTime">
          {{ detailItem.appointmentTime }}
        </a-col>
        <a-col span="18" v-else-if="detailItem.appointmentPeriodStartTime">
          {{ detailItem.appointmentPeriodStartTime | date("hh:mm") }} ~
          {{ detailItem.appointmentPeriodEndTime | date("hh:mm") }}
        </a-col>
      </a-row>
    </template>
    <a-divider></a-divider>
    <!--      修改预约-->
    <a-form-model
      :model="appointmentForm"
      ref="form"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
      <a-form-model-item :key="1" prop="endValidTime" label="延长使用有效期">
        <a-date-picker
          :defaultPickerValue="defaultDate"
          :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
          :disabled-date="disabledDate"
          placeholder="请选择延长使用有效期"
          v-model="appointmentForm.endValidTime"
        />
      </a-form-model-item>
      <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
      <a-form-model-item
        v-if="detailItem.afterWriteOffValidTime"
        label="延长核销后有效期"
      >
        <a-date-picker
          :defaultPickerValue="defaultDate"
          :show-time="{ defaultValue: moment('23:59:59', 'HH:mm:ss') }"
          :disabled-date="disabledDate"
          placeholder="请选择延长核销后有效期"
          v-model="appointmentForm.afterWriteOffValidTime"
        />
      </a-form-model-item>
      <template v-if="validityType === 'changeAppointment'">
        <a-form-model-item prop="useDate" label="改签日期">
          <a-date-picker
            :defaultPickerValue="defaultDateUse"
            v-model="appointmentForm.useDate"
            :disabled-date="disabledUseDate"
            placeholder="请选择日期"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="appointmentPeriodId"
          label="改签时段"
          v-if="detailItem.appointmentRuleId"
        >
          <a-select
            v-model="appointmentForm.appointmentPeriodId"
            placeholder="请选择改签时段"
          >
            <a-select-option
              v-for="item in appointmentList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.value }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="修改备注" prop="remark">
          <a-input
            type="textarea"
            placeholder="请输入备注"
            v-model="appointmentForm.remarks"
          ></a-input>
        </a-form-model-item>
      </template>
    </a-form-model>
  </a-modal>
</template>

<script>
import { dateFormat } from "@/utils/global";
import { getAppointmentTimeRule } from "@/api/product";
import { ticketChanging, ticketValidTime } from "@/api/order";
import moment from "moment";
export default {
  name: "ChangeValidity",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      }
    },
    detailItem: {
      type: Object,
      default() {
        return {};
      }
    },
    validityType: {
      type: String,
      default() {
        return "";
      }
    }
  },
  watch: {
    detailItem(row) {
      this.appointmentForm = {
        endValidTime: "",
        afterWriteOffValidTime: "",
        useDate: "",
        appointmentPeriodId: "",
        remarks: ""
      }; // 修改预约form表单值
      this.defaultDate = this.moment(this.detailItem.endValidTime);
      this.defaultDateUse = this.moment(this.detailItem.appointmentDate);
      if (row.appointmentRuleId) {
        console.log("row.appointmentRuleId");
        console.log(row.appointmentRuleId);
        this.visibleChange();
      }
    }
  },
  data() {
    return {
      moment,
      appointmentForm: {}, // 修改预约form表单值
      defaultDate: "", // 预约默认延长时间
      defaultDateUse: "", // 预约改签时间
      appointmentList: [], // 预约时段列表
      // 表单验证规则
      rules: {
        endValidTime: [
          {
            required: false,
            message: "请选择延长使用有效期",
            trigger: "change"
          }
        ],
        useDate: [
          { required: true, message: "请选择改签日期", trigger: "change" }
        ],
        appointmentPeriodId: [
          { required: true, message: "请选择改签时段", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    // 预约时段禁止区域
    disabledDate(current) {
      return (
        current && current < this.moment(new Date().getTime()).add(0, "day")
      );
    },
    disabledUseDate(current) {
      return (
        current && current < this.moment(new Date().getTime()).add(-1, "day")
      );
    },
    // 获取时段列表
    visibleChange() {
      getAppointmentTimeRule({
        id: this.detailItem.appointmentRuleId
      }).then(res => {
        res.relationList.forEach(item => {
          item.value =
            dateFormat(item.beginTime, "hh:mm") +
            "~" +
            dateFormat(item.endTime, "hh:mm");
        });
        this.appointmentList = res.relationList;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (
            !this.appointmentForm.endValidTime &&
            !this.appointmentForm.afterWriteOffValidTime &&
            this.validityType !== "changeAppointment"
          ) {
            this.$message.error("请选择使用有效期或核销后有效期!");
            return;
          }
          let obj = {
            ticketNo: this.detailItem.auxiliaryId, // 票号
            endValidTime: this.moment(
              this.appointmentForm.endValidTime
            ).valueOf(), // 有效期，最后 以23:59:59结尾
            useDate: this.moment(this.appointmentForm.useDate).valueOf(), // 预约日期
            remarks: this.appointmentForm.remarks // 修改预约备注
          };
          if (this.appointmentForm.appointmentPeriodId) {
            obj.appointmentPeriodId = this.appointmentForm.appointmentPeriodId;
          }
          if (this.appointmentForm.afterWriteOffValidTime) {
            obj.afterWriteOffValidTime = this.moment(
              this.appointmentForm.afterWriteOffValidTime
            ).valueOf(); // 有效期，最后 以23:59:59结尾
          }
          if (this.validityType === "changeAppointment") {
            // 修改预约
            ticketChanging(obj).then(() => {
              this.$emit("submit");
            });
            this.$emit("update:visible", false);
          } else {
            // 延长有效期
            let val = {
              ticketNo: obj.ticketNo,
              endValidTime: obj.endValidTime
            };
            if (this.appointmentForm.afterWriteOffValidTime) {
              val.afterWriteOffValidTime = this.moment(
                this.appointmentForm.afterWriteOffValidTime
              ).valueOf(); // 有效期，最后 以23:59:59结尾
            }
            ticketValidTime(val).then(() => {
              this.$emit("submit");
            });
            this.$emit("update:visible", false);
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style scoped></style>
