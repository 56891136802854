<template>
  <div
    class="combination-cart bd-l bg-white flex-between flex-col"
    :class="{ 'full-height': fullheight }"
  >
    <p class="header bd-b font-xl">待结账清单</p>
    <!-- 已加入购物车的产品 -->
    <div class="cart-empty" v-if="cart.productItemList.length === 0">
      <a-empty
        :image="emptyCar"
        :image-style="{
          width: '238px',
          height: '238px',
          margin: '0 auto 50px'
        }"
        :description="false"
      />
    </div>

    <ul class="cart-main" v-else>
      <li
        class="container bd-b"
        v-for="item in cart.productItemList"
        :key="item.specialId"
      >
        <div class="flex-between align-center">
          <h3 class="text-2">{{ item.productName }}</h3>
          <p class="ml-1x">x{{ item.buyNum }}</p>
        </div>

        <template v-if="item.travelerList">
          <div
            class="gray font-s"
            v-for="(temp, idx) in item.travelerList"
            :key="idx"
          >
            <p class="mt-1x" v-for="(val, i) in temp.visitorFieldList" :key="i">
              {{ val.fieldName }}: {{ val.fieldValue }}
            </p>
          </div>
        </template>

        <!-- 捆绑商品的列表展示 -->
        <div
          class="mt-1x flex-between"
          v-for="temp in item.addPurchaseProductItemList"
          :key="temp.productId"
          v-show="temp.whetheChangeNum === true || temp.checkDefault === 1"
        >
          <a-checkbox
            @change="changeCheckbox($event, temp, item)"
            :disabled="!temp.whetheChangeNum"
            :default-checked="temp.checkDefault === 1 ? true : false"
          />
          <span>{{ temp.productName }}</span>
          <span>{{ temp.sellingPrice | money }}</span>
          <span>{{ temp.buyNum }}</span>
        </div>
        <div
          class="mt-1x"
          v-show="item.addPurchaseProductItemList.length === 0"
        >
          <span>加购商品推荐</span>
        </div>
        <div class="flex-between align-center">
          <b>{{ item.totalSellingPrice | money }}</b>
          <a-button
            type="link"
            size="large"
            @click="handleDelete(item.specialId)"
            >删除</a-button
          >
        </div>
      </li>
    </ul>
    <!-- 底部按钮 -->
    <div class="cart-footer shadow">
      <!--<div class="flex-between align-center ph-3x pt-x">-->
      <!--  <span class="gray">共{{ cart.ticketNum }}项， 合计</span>-->
      <!--  <span class="font-xxl">{{ cart.totalPrice | money }}</span>-->
      <!--</div>-->
      <p class="detail">
        <span
          v-for="(item, index) in cart.cbsTicketTypePrice"
          :key="'k' + index"
          >{{ item.productName }}：{{ item.buyNum }}张，共{{
            item.totalPrice / 100
          }}元；</span
        >
      </p>
      <div class="btn-group flex-between">
        <a-button
          class="flex-grow"
          size="large"
          shape="round"
          @click="cancelAll"
          :disabled="!cart.productItemList.length"
        >
          整单取消
        </a-button>
        <a-button
          class="flex-grow ml-2x"
          type="primary"
          size="large"
          shape="round"
          @click="checkOut"
          :disabled="!cart.productItemList.length"
        >
          <span
            v-permission="'appHomeSellOrderCalc'"
            class="mr-3x font-n"
            @click="checkOut"
            >结账：</span
          >
          <span>{{ cart.totalPrice | money }}</span>
        </a-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  addBuyUpdateCartText,
  deleteMultiCart,
  deleteOrCancel,
  getBuyCartText,
  shoppingCart
} from "../../../../api/cart";

export default {
  name: "CombinationCart",
  components: {},
  props: {
    // 购物车，是否满屏（用于副屏页面设置）
    fullheight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emptyCar: require("../../../../assets/images/empty_car.png"),
      cart: {
        id: 0, // 购物车id
        productItemList: [], // 产品列表
        cbsTicketTypePrice: [], // 明细
        totalPrice: 0, // 总价
        ticketNum: 0 // 数量
      }
    };
  },
  methods: {
    loadData(id) {
      shoppingCart({ id })
        .then(res => {
          this.cart = res;
        })
        .catch(() => {});
    },
    // 小程序 更新加购产品接口
    getBuyNumCartText(id) {
      getBuyCartText({ id })
        .then(res => {
          this.cart = res;
        })
        .catch(() => {});
    },
    // 删除购物车
    handleDelete(specialId) {
      deleteMultiCart({ id: this.cart.id, specialId })
        .then(res => {
          if (this.cart.productItemList.length === 1) {
            this.clearCart();
          } else {
            this.getBuyNumCartText(res.id);
          }
        })
        .catch(() => {});
    },
    //整单取消
    cancelAll() {
      this.$confirm({
        title: "整单取消",
        content: "确定要执行此操作吗？",
        onOk: () => {
          deleteOrCancel({
            id: this.cart.id
          })
            .then(() => {
              this.clearCart();
            })
            .catch(() => {});
        },
        onCancel() {}
      });
    },
    // 清空购物车
    clearCart() {
      this.cart = {
        id: 0, // 购物车id
        productItemList: [], // 产品列表
        totalPrice: 0, // 总价
        ticketNum: 0 // 数量
      };
      this.$emit("cancel-cart", 0);
    },
    // 结账
    checkOut() {
      this.$store.commit("order/setCartList", this.cart);
      this.$router.push("/home/sell/OrderInfo");
    },
    changeCheckbox(e, value, item) {
      const data = {
        id: this.cart.id,
        productId: item.productId,
        specialId: item.specialId,
        addProductId: value.productId,
        addSpuId: value.spuId,
        addBuyNum: value.buyNum
      };
      if (e.target.checked) {
        addBuyUpdateCartText({ ...data, addBuyNum: 1 })
          .then(res => {
            this.getBuyNumCartText(res.id);
          })
          .catch(() => {});
      } else {
        addBuyUpdateCartText({ ...data, addBuyNum: 0 })
          .then(res => {
            this.cart = res;
            this.getBuyNumCartText(res.id);
          })
          .catch(() => {});
      }
    }
  }
};
</script>
<style lang="less" scoped>
.combination-cart {
  margin-left: 0;
  width: 364px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  border-left: 1px solid #ebebeb;
  overflow-y: auto;
}
.cart-footer {
  .detail {
    padding: 12px 16px 8px;
    font-size: 12px;
    color: #999;
  }
  .btn-group {
    padding: 4px 16px 12px;
  }
}
.header {
  padding: 12px 16px;
}
.cart-empty {
  margin-top: 6vh;
}
.cart-main {
  flex-grow: 1;
  overflow-y: auto;
}

.choice {
  font-size: 16px;
  cursor: pointer;
  color: rgb(19, 129, 219);
}
</style>
