<template>
  <div class="ph-2x pb-2x">
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      rowKey="id"
      size="middle"
    >
      <template slot="totalAmount" slot-scope="row">
        {{ row.totalAmount | money }}
      </template>
      <template slot="createTime" slot-scope="row">{{
        row.createTime | date("YYYY-MM-DD hh:mm:ss")
      }}</template>
    </a-table>
  </div>
</template>

<script>
import { findTicketReissuePage } from "../../../../api/order";

export default {
  name: "TicketReplace",
  data() {
    return {
      tableData: [],
      columns: [
        {
          width: 150,
          title: "补办后票号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId"
        },
        {
          width: 150,
          title: "原票号",
          dataIndex: "relationOrderDetailId",
          key: "relationOrderDetailId"
        },
        {
          width: 100,
          title: "补办费用",
          key: "totalAmount",
          scopedSlots: { customRender: "totalAmount" }
        },
        {
          width: 150,
          title: "补办说明",
          dataIndex: "remarks",
          key: "remarks"
        },
        {
          width: 150,
          title: "补办时间",
          key: "createTime",
          scopedSlots: { customRender: "createTime" }
        },
        {
          width: 120,
          title: "补办操作人",
          dataIndex: "salespersonName",
          key: "salespersonName"
        },
        {
          width: 150,
          title: "补办订单编号",
          dataIndex: "mainOrderId",
          key: "mainOrderId"
        }
      ]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      findTicketReissuePage({
        id: this.$route.query.id
      })
        .then(({ records }) => {
          this.tableData = records;
        })
        .catch(function() {});
    }
  }
};
</script>
