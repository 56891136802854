//keyCode键码对照表
export default {
  // 字母和数字键的键码值(keyCode)
  65: "A",
  66: "B",
  67: "C",
  68: "D",
  69: "E",
  70: "F",
  71: "G",
  72: "H",
  73: "I",
  74: "J",
  75: "K",
  76: "L",
  77: "M",
  78: "N",
  79: "O",
  80: "P",
  81: "Q",
  82: "R",
  83: "S",
  84: "T",
  85: "U",
  86: "V",
  87: "W",
  88: "X",
  89: "Y",
  90: "Z",
  48: "0",
  49: "1",
  50: "2",
  51: "3",
  52: "4",
  53: "5",
  54: "6",
  55: "7",
  56: "8",
  57: "9",
  // 数字键盘上的键的键码值(keyCode)
  96: "0",
  97: "1",
  98: "2",
  99: "3",
  100: "4",
  101: "5",
  102: "6",
  103: "7",
  104: "8",
  105: "9",
  106: "*",
  107: "+",
  108: "Enter",
  109: "-",
  110: ".",
  111: "/",
  // 功能键键码值(keyCode)
  112: "F1",
  113: "F2",
  114: "F3",
  115: "F4",
  116: "F5",
  117: "F6",
  118: "F7",
  119: "F8",
  120: "F9",
  121: "F10",
  122: "F11",
  123: "F12",
  // 控制键键码值(keyCode)
  8: "BackSpace",
  9: "Tab",
  12: "Clear",
  13: "Enter",
  16: "Shift",
  17: "Shift",
  18: "Control",
  20: "Cape Lock",
  27: "Esc",
  32: "Spacebar空格",
  33: "Page Up",
  34: "Page Down",
  35: "End",
  36: "Home",
  37: "左箭头",
  38: "上箭头",
  39: "右箭头",
  40: "下箭头",
  45: "Insert",
  46: "Delete",
  144: "Num Lock",
  186: ";:",
  187: "=+",
  188: ",<",
  189: "-_",
  190: ".>",
  191: "/?",
  192: "`~",
  219: "[{",
  220: "|",
  221: "]}",
  222: `'"`
};
