<template>
  <a-modal
    :visible="vis"
    title="补录人脸"
    width="780px"
    @cancel="close"
    :body-style="{
      'max-height': '460px'
    }"
    :mask-closable="false"
  >
    <a-form-model
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
    >
      <a-form-model-item label="产品名称">
        <span>{{ detail.productName }}</span>
      </a-form-model-item>
      <a-form-model-item label="产品编号">
        <span>{{ detail.auxiliaryId }}</span>
      </a-form-model-item>
      <a-form-model-item
        prop="faceNumber"
        label="录入人脸"
        :label-col="labelCol"
      >
        <FaceInput
          ref="faceInput"
          :echoimgurl="form.faceUrl"
          :faceid="form.faceid"
          v-model="form.faceNumber"
          @imgUrlChange="imgUrl"
        ></FaceInput>
      </a-form-model-item>
    </a-form-model>
    <div class="flex-end" slot="footer">
      <a-button key="cancel" @click="close">取消</a-button>
      <a-button type="primary" @click="save" :loading="loading">保存</a-button>
    </div>
  </a-modal>
</template>

<script>
import FaceInput from "@/components/hardware/FaceInput.vue";
import { additionalRecordingFace } from "@/api/order";

export default {
  name: "AddFace",
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    FaceInput
  },
  data() {
    return {
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      form: {
        faceNumber: "", // 人脸信息框
        faceUrl: "" // 人脸图片地址
      },
      loading: false,
      idCardNumber: "" // 身份证输入框
    };
  },
  watch: {
    detail: {
      immediate: true,
      handler(newValue) {
        if (newValue.userInfoConvert) {
          this.form.faceid = newValue.userInfoConvert.faceId;
          this.form.faceUrl = newValue.userInfoConvert.faceUrl;
        }
      }
    }
  },
  methods: {
    // 主票号-人脸图片
    imgUrl(url) {
      this.form.faceUrl = url;
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 保存
    save() {
      if (!this.form.faceUrl) {
        // 不判断faceNumber，因为用身份证读卡获取的时候，是没有faceid参数
        this.$message.warning("没获取到人脸图片");
        return;
      }
      additionalRecordingFace({
        ticketNumber: this.detail.auxiliaryId, // 票号
        faceId: this.form.faceNumber, // 人脸id
        faceUrl: this.form.faceUrl // 人脸图片地址
      })
        .then(() => {
          this.$emit("updateParentData");
          this.$emit("update:vis", false);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
/deep/.ant-modal-body {
  overflow-y: auto;
}
</style>
