var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.vis,"title":"门票补办","width":"780px","body-style":{
    'max-height': '460px'
  },"mask-closable":false},on:{"cancel":_vm.close}},[_c('div',{staticClass:"mb-2x font-l"},[_c('a-icon',{staticClass:"primary mr-1x",attrs:{"type":"exclamation-circle"}}),(_vm.activateInfo.voucherType != 0)?_c('span',{staticClass:"bold"},[_vm._v("补办后，原绑定门票将失效")]):_vm._e(),(_vm.activateInfo.voucherType == 0)?_c('span',{staticClass:"bold"},[_vm._v("补办后，原打印门票将失效")]):_vm._e()],1),_c('a-form-model',{ref:"refTicketReissue",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('div',{staticClass:"flex-start"},[_c('div',[_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"label":"门票名称"}},[_c('span',[_vm._v(_vm._s(_vm.activateInfo.productName))])]),_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"label":"已办理次数"}},[_c('span',[_vm._v(_vm._s(_vm.activateInfo.reissueTimes))])]),_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"label":"补办费用"}},[(_vm.activateInfo.reprintFeeType == 0)?_c('span',[_vm._v("0")]):_vm._e(),(
              _vm.activateInfo.reprintFeeType == 1 &&
                _vm.activateInfo.freePrintTimes > _vm.activateInfo.printTimes
            )?_c('span',[_vm._v("0")]):_vm._e(),(
              _vm.activateInfo.reprintFeeType == 1 &&
                _vm.activateInfo.freePrintTimes <= _vm.activateInfo.printTimes
            )?_c('a-input',{staticClass:"wid",attrs:{"placeholder":"请输入"},model:{value:(_vm.activateInfo.printFee),callback:function ($$v) {_vm.$set(_vm.activateInfo, "printFee", $$v)},expression:"activateInfo.printFee"}}):_vm._e()],1),_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"label":"补办说明"}},[_c('a-input',{staticClass:"wid",attrs:{"placeholder":"请输入"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1),(
          (_vm.activateInfo.voucherType != 0 &&
            !_vm.activateInfo.subTicketListInfo) ||
            !(
              _vm.activateInfo.subTicketListInfo &&
              _vm.activateInfo.subTicketListInfo.length
            )
        )?_c('div',{staticClass:"ml-3x"},[_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"wrapper-col":_vm.subWrapperCol,"label":"绑定介质"}},[_c('div',{staticClass:"mb-3x"},[_c('IcCardinput',{model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)])],1):_vm._e(),(
          _vm.activateInfo.subTicketListInfo &&
            _vm.activateInfo.subTicketListInfo.length
        )?_c('div',{staticClass:"ml-3x"},[(_vm.activateInfo.voucherType != 0)?_c('a-form-model-item',{attrs:{"label-col":_vm.subLabelCol,"wrapper-col":_vm.subWrapperCol,"label":"绑定介质"}},[(_vm.activateInfo.subTicketListInfo)?_vm._l((_vm.activateInfo.subTicketListInfo),function(item,index){return _c('div',{key:index,staticClass:"mb-3x"},[_c('IcCardinput',{model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})],1)}):_vm._e()],2):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"flex-between align-center",attrs:{"slot":"footer"},slot:"footer"},[_c('div',[_c('span',[_vm._v(" 押金： "),(_vm.activateInfo.freePrintTimes > _vm.activateInfo.printTimes)?_c('span',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm._f("money")(0))+" ")]):_c('span',{staticClass:"red"},[_vm._v(" "+_vm._s(_vm._f("money")((_vm.activateInfo.printFee * 100)))+" ")])])]),_c('div',[_c('a-button',{key:"cancel",on:{"click":_vm.close}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.sure}},[_vm._v("确定补办")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }