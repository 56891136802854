<template>
  <div class="ph-2x pb-2x">
    <!--快速通行服务-->
    <template>
      <h2 class="title-mid m-0">快速通行服务</h2>
      <p class="mt-2x">
        <span class="gray mr-4x">服务费用</span>
        <span>{{ fast.totalAmount | money }}</span>
      </p>
      <p class="mt-2x">
        <span class="gray mr-4x">费用状态</span>
        <span class="primary">{{ fast.statusText }}</span>
      </p>
      <p class="mt-2x">
        <span class="gray mr-4x">已退金额</span>
        <span>{{ fast.totalRefundAmount | money }}</span>
      </p>
      <p class="mt-2x">
        <span class="gray mr-4x">费用余额</span>
        <span>{{ fast.totalRemainAmount | money }}</span>
      </p>
    </template>

    <!--购买快速通行服务赠送-->
    <template>
      <p class="title-mid">购买快速通行服务赠送</p>
      <!--搜索-->
      <header class="mb-2x">
        <span>产品名称</span>
        <a-input
          class="mh-2x"
          style="width: 200px"
          v-model="searchKey"
          placeholder="请输入产品名称"
          allow-clear
        ></a-input>
        <a-button type="primary" @click="search">查询</a-button>
      </header>
      <!--表格-->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        size="middle"
        rowKey="id"
      >
        <span class="primary" slot="statusText" slot-scope="row">{{
          row.statusText
        }}</span>
        <a-button
          slot="action"
          class="btn-link p-0"
          type="link"
          slot-scope="row"
          @click="showDetail(row)"
          >详情</a-button
        >
      </a-table>

      <!--分页-->
      <footer class="flex-end mt-2x" v-show="total > 0">
        <a-pagination
          v-model="page"
          :default-page-size="size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadGive"
          @showSizeChange="sizeChange"
        />
      </footer>
    </template>

    <!--详情弹窗-->
    <ticket-product-detail
      :visible.sync="visible"
      :id="detailId"
      :productId="productId"
    ></ticket-product-detail>
  </div>
</template>

<script>
import TicketProductDetail from "./TicketProductDetail";
import {
  findTicketFastInfo,
  findTicketFastProductPage
} from "../../../../api/order";

// 快速通行服务table start
const columns = [
  {
    width: 200,
    title: "所属商户",
    dataIndex: "productOwnerMerchantName",
    key: "productOwnerMerchantName"
  },
  {
    width: 200,
    title: "赠送产品名称",
    dataIndex: "productName",
    key: "productName"
  },
  {
    width: 150,
    title: "产品类型",
    dataIndex: "productCategoryText",
    key: "productCategoryText"
  },
  {
    width: 100,
    title: "状态",
    scopedSlots: { customRender: "statusText" },
    key: "statusText"
  },
  {
    width: 100,
    title: "操作",
    scopedSlots: { customRender: "action" },
    fixed: "right"
  }
];

export default {
  name: "FastService",
  components: { TicketProductDetail },
  data() {
    return {
      visible: false,
      detailId: "", // 详情id
      productId: "", //该项目的产品id
      fast: {}, // 快速通行服务
      searchKey: "",
      tableData: [], // 快票赠送服务
      total: 0,
      page: 1,
      size: 10,
      columns
    };
  },
  created() {
    this.loadFast();
    this.loadGive();
  },
  methods: {
    loadFast() {
      findTicketFastInfo({
        id: this.$route.query.id
      })
        .then(res => {
          this.fast = res || {};
        })
        .catch(function() {});
    },
    loadGive() {
      findTicketFastProductPage({
        page: this.page,
        size: this.size,
        id: this.$route.query.id,
        productName: this.searchKey
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(function() {});
    },
    // 弹出详情
    showDetail(row) {
      this.detailId = row.id;
      this.productId = row.productId;
      this.visible = true;
    },
    // 条数改变
    sizeChange(s) {
      this.page = 1;
      this.size = s;
      this.loadGive();
    },
    // 页面改变
    currentChange(p) {
      this.page = p;
      this.loadGive();
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadGive();
    }
  }
};
</script>

<style lang="less" scoped></style>
