<template>
  <div class="wrapper flex-col flex-start">
    <panel-head title="直接收款" @close="handleClose"></panel-head>
    <div class="main">
      <div class="flex-center mb-2x">
        <a-radio-group
          v-model="payType"
          button-style="solid"
          @change="changePayType"
          size="large"
        >
          <a-radio-button value="1">
            无产品收银
          </a-radio-button>
          <a-radio-button value="2">
            产品补差价
          </a-radio-button>
        </a-radio-group>
      </div>
      <a-divider />
      <div
        v-if="payType == 2"
        class="flex-center flex-col flex-wrap align-center"
      >
        <div class="flex-start align-center pb-1x" style="width:531px">
          <span style="width:60px">产品号</span>
          <!--<a-input-->
          <!--  placeholder="请输入票号/手机号/身份证/卡腕带编号"-->
          <!--  allow-clear-->
          <!--  v-model.trim="ticketNo"-->
          <!--  ref="ticketRef"-->
          <!--  @pressEnter="queryTicket"-->
          <!--&gt;</a-input>-->
          <IcCardInput
            ref="ticketRef"
            v-inputfocus
            v-model="ticketNo"
            :allow-clear="false"
            @pressEnter="queryTicket"
            placeholder="请输入票号/手机号/身份证/卡腕带编号"
          ></IcCardInput>
          <a-button type="primary" @click="queryTicket" class="ml-2x"
            >查询</a-button
          >
        </div>
        <a-table
          :columns="ticketColumns"
          :data-source="ticketList"
          rowKey="auxiliaryId"
          :pagination="false"
          class="table-w"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            type: 'radio'
          }"
          :customRow="loadCustomRow"
          :scroll="{ y: 430 }"
        >
          <!--<template slot="action" slot-scope="row">-->
          <!--  <a-button type="link" @click="goRefundDetail(row)">补差价</a-button>-->
          <!--</template>-->
        </a-table>
      </div>
      <a-form autocomplete="off" class="text-c">
        <a-input
          class="prefix-input"
          placeholder="请输入收款事项"
          allow-clear
          v-model.trim="remark"
        >
          <div slot="prefix" class="font-xl primary">备注收款事项</div>
        </a-input>

        <keyboard-pro type="2" v-model="numbers" @ok="currentPay">
          <template v-slot:input="{ confirm }">
            <a-input
              @pressEnter="confirm()"
              size="large"
              class="prefix-input"
              allow-clear
              v-model="numbers"
            >
              <div slot="prefix" class="font1 primary">本次收款金额</div>
            </a-input>
          </template>
        </keyboard-pro>
      </a-form>
    </div>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "@/components/common/KeyboardPro";
import { skuNoProduct } from "../../../api/product";
import { addShoppingCart } from "../../../api/cart";
import { orderTicketPremium } from "@/api/search";
import { mapActions } from "vuex";
import IcCardInput from "@/components/hardware/IcCardinput";

export default {
  name: "CustomAmount",
  data() {
    return {
      form: this.$form.createForm(this, { name: "myform" }),
      remark: "",
      numbers: "",
      productInfo: {},
      payType: "1", //1无票支付 2门票补差价
      ticketColumns: [
        {
          title: "产品号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId",
          width: 130
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 170,
          ellipsis: true
        },
        {
          title: "产品状态",
          key: "statusText",
          dataIndex: "statusText",
          width: 90
        },
        {
          title: "销售渠道",
          dataIndex: "buyChannelsText",
          key: "buyChannelsText",
          width: 100
        }
      ],
      ticketList: [],
      ticketNo: "",
      refundInfo: {},
      selectedRowKeys: [] // 选中项
    };
  },
  components: {
    PanelHead,
    KeyboardPro,
    IcCardInput
  },
  created() {
    //获取无收银产品
    this.getProduct();
  },
  methods: {
    ...mapActions("order", ["setCartList"]),
    // goRefundDetail(row) {
    //   this.refundInfo.ticketNo = row.auxiliaryId; //票号
    //   this.remark = "补差价";
    // },
    loadCustomRow(record) {
      return {
        on: {
          click: () => {
            //监听的是单选框的点击事件
            this.selectedRowKeys = [record.auxiliaryId];
            this.refundInfo.ticketNo = record.auxiliaryId; //票号
            this.remark = "补差价";
          },
          change: () => {
            //监听的是行的点击事件
            this.selectedRowKeys = [record.auxiliaryId];
            this.refundInfo.ticketNo = record.auxiliaryId; //票号
            this.remark = "补差价";
          }
        }
      };
    },
    //切换是否补差价
    changePayType() {
      if (this.payType == 2) {
        this.$nextTick(() => {
          this.$refs.ticketRef.focus();
        });
      }
      this.selectedRowKeys = [];
      this.ticketList = [];
      this.numbers = "";
      this.remark = "";
    },
    queryTicket() {
      if (!this.ticketNo) {
        this.$message.warning("请输入票号/手机号/身份证/卡腕带编号");
        return;
      }
      this.selectedRowKeys = [];
      orderTicketPremium({ keyword: this.ticketNo }).then(res => {
        this.ticketList = res;
      });
    },
    currentPay() {
      if (this.remark == "") {
        this.$message.warning("请输入收款事项");
        return false;
      }
      if (this.numbers == "") {
        this.$message.warning("请输入收款金额");
        return false;
      }
      let refundInfo = {};
      if (this.payType == 2) {
        if (!this.ticketNo) {
          this.$message.warning("请输入票号");
          return;
        }
        if (!this.selectedRowKeys.length) {
          this.$message.warning("暂无选中产品");
          return;
        }
        //业务类型（1.正常下单，2.自定义收款，3.加购，4.充值，5.会员特权，6.取票，7.补办，8赠送，9补差价）
        this.refundInfo.businessCategory = 9;
        refundInfo = this.refundInfo;
      }
      //加入购物车
      let pd = this.productInfo;
      let data = {
        productId: pd.id,
        spuId: pd.spuId,
        buyNum: 1,
        buyChannels: 6, //6是客户端
        businessCategory: 2, //2是自定义收款
        remark: this.remark,
        customPrice: this.numbers * 100,
        ...refundInfo //补差价时 参数
      };
      addShoppingCart(data)
        .then(res => {
          this.setCartList({ id: res.id });
          //去结账页面
          this.$router.push("/home/sell/OrderInfo");
        })
        .catch(err => {
          console.log(err);
        });
    },
    getProduct() {
      skuNoProduct()
        .then(res => {
          //console.log(res);
          this.productInfo = res;
        })
        .catch(() => {});
    },
    handleClose() {
      //this.$router.push({ path: "/home" });
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-top: 40px;
  margin-top: 16px;
}
.prefix-input {
  width: 532px;
  margin-bottom: 16px;
}
.table-w {
  width: 632px;
  margin-bottom: 16px;
}
</style>
