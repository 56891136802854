var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper flex-between flex-col"},[_c('div',{staticClass:"header "},[_c('a-form-model',{ref:"searchForm",attrs:{"layout":"inline","model":_vm.searchForm}},[_c('a-form-model-item',{attrs:{"label":"下单日期"}},[_c('a-range-picker',{staticClass:"input",attrs:{"allow-clear":false},on:{"change":_vm.dateChange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('a-form-model-item',{attrs:{"label":"订单号"}},[_c('a-input',{ref:"orderListRef",staticClass:"input",attrs:{"placeholder":"订单号/总单号/外部单号/票号","allow-clear":""},on:{"pressEnter":_vm.reload},model:{value:(_vm.searchForm.keyword),callback:function ($$v) {_vm.$set(_vm.searchForm, "keyword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchForm.keyword"}})],1),_c('a-form-model-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.reload}},[_vm._v("查询")])],1),_c('a-form-model-item',[_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('section',{staticClass:"main mt-2x"},[_c('a-table',{attrs:{"scroll":{ x: 1366, y: _vm.tableHeight },"columns":_vm.columns,"data-source":_vm.tableData,"pagination":false,"rowKey":"id"},scopedSlots:_vm._u([{key:"appointmentPeriodStartTime",fn:function(ref){
var appointmentPeriodStartTime = ref.appointmentPeriodStartTime;
return [_vm._v(_vm._s(_vm._f("date")(appointmentPeriodStartTime,"YYYY-MM-DD hh:mm:ss")))]}},{key:"totalAmount",fn:function(ref){
var totalAmount = ref.totalAmount;
return [_vm._v(_vm._s(_vm._f("money")(totalAmount)))]}},{key:"actualAmount",fn:function(ref){
var actualAmount = ref.actualAmount;
return [_vm._v(_vm._s(_vm._f("money")(actualAmount)))]}},{key:"discountAmount",fn:function(ref){
var discountAmount = ref.discountAmount;
return [_vm._v(_vm._s(_vm._f("money")(discountAmount)))]}},{key:"totalRefundAmount",fn:function(ref){
var totalRefundAmount = ref.totalRefundAmount;
return [_vm._v(_vm._s(_vm._f("money")(totalRefundAmount)))]}},{key:"noRefundAmount",fn:function(ref){
var noRefundAmount = ref.noRefundAmount;
return [_vm._v(_vm._s(_vm._f("money")(noRefundAmount)))]}},{key:"integralDeductionAmount",fn:function(ref){
var integralDeductionAmount = ref.integralDeductionAmount;
return [_vm._v(_vm._s(_vm._f("money")(integralDeductionAmount)))]}},{key:"useDate",fn:function(ref){
var useDate = ref.useDate;
return _c('span',{staticClass:"primary"},[_vm._v(_vm._s(_vm._f("date")(useDate)))])}},{key:"action",fn:function(row){return [_c('a-button',{staticClass:"p-0 mr-1x",attrs:{"type":"link","disabled":row.orderStatus == 8},on:{"click":function($event){return _vm.onRefund(row)}}},[_vm._v("退款")])]}}])}),_c('div',{staticClass:"flex-end p-2x"},[_c('a-pagination',{attrs:{"default-page-size":_vm.searchForm.size,"total":_vm.total,"page-size-options":['10', '20', '50', '100'],"show-size-changer":""},on:{"change":_vm.loadData,"showSizeChange":_vm.sizeChange},model:{value:(_vm.searchForm.page),callback:function ($$v) {_vm.$set(_vm.searchForm, "page", $$v)},expression:"searchForm.page"}})],1)],1),_c('order-refund',{attrs:{"visible":_vm.refundVisible,"order-id":_vm.refundType === 3 ? _vm.ticketDepositOrderId : _vm.orderId,"product-name":_vm.productName,"product-category-id":_vm.productCategoryId,"refund-type":_vm.refundType,"refund-id-list":_vm.refundIdList,"refund-id-type":_vm.refundIdType},on:{"update:visible":function($event){_vm.refundVisible=$event},"on-ok":_vm.refunded}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }