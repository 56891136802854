<template>
  <div>
    <!-- 游客信息 -->
    <div class="flex-between mv-1x">
      <h3 class="title-mid" style="margin: 10px 0 16px 0">产品</h3>
      <!-- <div>
        <a-button size="large" type="primary" ghost @click="addTouristOne"
          >添加游客</a-button
        >
        <a-button
          size="large"
          type="primary"
          ghost
          @click="touristShow"
          class="ml-1x"
          >导入游客</a-button
        >
        <a-button
          size="large"
          type="primary"
          class="ml-1x"
          @click="checkTourist"
          >核对游客信息</a-button
        >
      </div> -->
    </div>
    <!-- 游客信息汇总table -->
    <a-table
      ref="touristSumtable"
      size="small"
      :columns="touristColumns"
      :data-source="touristSumData"
      :pagination="false"
      row-key="touristCategoryId"
    >
      <!-- 匹配产品 -->
      <template slot="name" slot-scope="text, scope, index">
        <!-- {{ scope.name }} -->
        <a-select
          :default-value="scope.sybSystemIdProductId"
          v-model="scope.sybSystemIdProductId"
          size="large"
          @change="switchSumTouristProDataV2(scope.sybSystemIdProductId, index)"
          class="res"
        >
          <a-select-option
            v-for="item in subscenicProductList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <!-- 单价 -->
      <template slot="sellingPrice" slot-scope="scope">{{
        scope.sellingPrice || 0 | money
      }}</template>
      <!-- 游客人数 -->
      <template slot="sumNum" slot-scope="text, scope, index">
        <!-- {{ scope.sumNum }} -->
        <a-input-number
          v-model="sumNum"
          size="large"
          ref="inputRef"
          @pressEnter="toOrder"
          @change="switchSumTouristProDataV2(scope.sybSystemIdProductId, index)"
        />
        <span>输入完回车即可下单</span>
      </template>
      <template slot="sumPrice" slot-scope="scope">{{
        scope.sumPrice | money
      }}</template>
      <!-- 删除游客 -->
      <!-- <template slot="action" slot-scope="text, scope, index">
        <a-button @click="delTourist(scope, index)" type="link" class="blue"
          >删除</a-button
        >
      </template> -->
    </a-table>
  </div>
</template>
<script>
import { checkIdCard, checkPhone, checkText, onkeydown } from "@/utils/global";
//changFouce;
// import { touristInfoBycard } from "@/api/member";
// import order from "../../../../store/order";
export default {
  name: "tourist",
  props: {
    // 子景区
    subScenic: {
      type: Object
    },
    //子景区产品
    subscenicProductList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 团体时信息
    groupType: {
      type: Object
    }
  },
  data() {
    return {
      orderType: "1", //下单类型 1个人快速下单 2团体快速下单
      selectTouristShow: false, //添加游客信息框
      //游客信息汇总table
      touristColumns: [
        {
          title: "选择",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 170
        },
        {
          title: "单价",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" },
          width: 170
        },
        {
          title: "游客人数",
          key: "sumNum",
          scopedSlots: { customRender: "sumNum" },
          width: 220
        },
        {
          title: "总金额",
          key: "sumPrice",
          scopedSlots: { customRender: "sumPrice" },
          width: 170
        }
      ],
      //核对游客信息table
      checkTouristColumns: [
        {
          title: "姓名",
          key: "username",
          scopedSlots: { customRender: "username" },
          width: 170
        },
        {
          title: "手机号",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          width: 170
        },
        {
          title: "证件类型",
          key: "cartType",
          scopedSlots: { customRender: "cartType" },
          width: 170
        },
        {
          title: "证件号",
          key: "idCard",
          scopedSlots: { customRender: "idCard" },
          width: 170
        },
        {
          title: "游客类型",
          key: "touristCategoryName",
          scopedSlots: { customRender: "touristCategoryName" },
          width: 170
        },

        {
          title: "匹配产品",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 170
        }
      ],
      touristCardType: "1", //添加-游客证件类型 1身份证 2护照
      //添加游客-游客类型table-data
      touristTypeData: {
        idCard: "",
        touristCategoryId: "", //游客类型id
        sybSystemIdProductId: "", //产品id
        sellingPrice: "", //
        discountCategory: "", //优惠类型(1.折扣 2.无 3.免费)
        fold: "" //折
      },
      touristRules: {
        name: [
          { required: false, message: "请输入姓名", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        idCard: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: checkIdCard, trigger: "blur" }
        ],
        phone: [
          { required: false, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      },
      touristData: [], //当前添加游客信息数据 未处理汇总
      touristSumData: [], //已添加游客信息数据 处理汇总后的
      checkTouristShow: false, //核对游客弹出
      sumNum: 1,
      subscenicProduct: {}
    };
  },
  watch: {
    subscenicProductList: {
      handler() {
        this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
      },
      deep: true
    }
  },
  mounted() {
    this.addTouristOne();
    let t = setTimeout(() => {
      if (this.subscenicProductList && this.subscenicProductList[0]) {
        this.subscenicProductList[0].sumNum = 1;
        this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
        this.$emit("changeTouristSumData", this.touristSumData[0]);
      }
      clearTimeout(t);
      t = null;
    }, 700);
    // changFouce(this, "inputRef");
    // this.autoFocus("idCardInput");
    //开启键盘监听
    document.addEventListener("keydown", this.onkeydown);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
    document.removeEventListener("keydown", this.onkeydown);
  },
  methods: {
    onkeydown(e) {
      var evt = window.event || e;
      let key = evt.keyCode || evt.which;
      if (key == 112) {
        this.$emit("changeRadio", 0);
      }
      if (key == 115) {
        //f4
        if (this.sumNum <= 1) {
          this.$message.warning("数量不能为0！");
          return;
        }
        this.sumNum -= 1;
        this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
      }
      if (key == 116) {
        //f5
        if (evt.preventDefault) {
          //阻止f5刷新
          evt.preventDefault();
        }
        this.sumNum += 1;
        this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
      }
    },

    toOrder() {
      //下单
      this.$refs.inputRef.blur();
      this.$emit("onenessOrder", this.touristSumData[0]);
    },
    windowKeyDown(e) {
      // if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    //根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        // case 109:
        //   this.sumNum = this.sumNum
        //     ? this.sumNum.substring(0, this.sumNum.length - 1)
        //     : "";
        //   break;
        // case 48:
        //   this.sumNum = this.sumNum ? this.sumNum + "0" : 0;
        //   break;
        case 112:
          // console.log("按了F1");
          //微信或支付宝
          this.$emit("changeRadio", 0);
          break;

        // case 113:
        //   // console.log("按了F2");现金
        //   this.$emit("changeRadio", 1);
        //   break;

        // case 114:
        // console.log("按了F3");
        // this.cartList.id ? this.handlePause() : this.showTips("pause");
        // break;

        case 115:
          // console.log("按了F4"); 减1
          if (this.sumNum <= 1) {
            this.$message.warning("数量不能为0！");
            return;
          }
          this.sumNum -= 1;
          this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
          break;

        case 116:
          // console.log("按了F5");
          this.sumNum += 1;
          this.switchSumTouristProDataV2(this.subscenicProductList[0].id, 0);
          break;
        // case 96:
        //   // console.log("按了键盘0");
        //   this.sumNum = this.sumNum ? this.sumNum + "0" : 0;
        //   break;
        // case 49:
        // case 97:
        //   // console.log("按了键盘1");
        //   this.sumNum = this.sumNum ? this.sumNum + "1" : 1;
        //   break;
        // case 50:
        // case 98:
        //   // console.log("按了键盘2");
        //   this.sumNum = this.sumNum ? this.sumNum + "2" : 2;
        //   break;
        // case 51:
        // case 99:
        //   // console.log("按了键盘3");
        //   this.sumNum = this.sumNum ? this.sumNum + "3" : 3;
        //   break;
        // case 52:
        // case 100:
        //   // console.log("按了键盘4");
        //   this.sumNum = this.sumNum ? this.sumNum + "4" : 4;
        //   break;
        // case 53:
        // case 101:
        //   // console.log("按了键盘5");
        //   this.sumNum = this.sumNum ? this.sumNum + "5" : 5;
        //   break;
        // case 54:
        // case 102:
        //   // console.log("按了键盘6");
        //   this.sumNum = this.sumNum ? this.sumNum + "6" : 0;
        //   break;
        // case 55:
        // case 103:
        //   // console.log("按了键盘7");
        //   this.sumNum = this.sumNum ? this.sumNum + "7" : 7;
        //   break;
        // case 56:
        // case 104:
        //   // console.log("按了键盘8");
        //   this.sumNum = this.sumNum ? this.sumNum + "8" : 8;
        //   break;
        // case 57:
        // case 105:
        //   // console.log("按了键盘9");
        //   this.sumNum = this.sumNum ? this.sumNum + "9" : 9;
        //   break;
        // case 13:
        // console.log("回车");
        // break;
        // default:
        //   console.log(code);
        //   alert(code);
        //   break;
      }
    },
    checkTourist() {
      this.checkTouristShow = true;
    },
    //添加一行游客
    addTouristOne() {
      this.touristSumData.push({
        sybSystemIdProductId: "", //产品id
        touristCategoryId: "", //游客类型id
        sumNum: 1,
        sellingPrice: 0,
        sumPrice: 0
      });
    },
    //自动获取焦点
    autoFocus(ref) {
      this.$nextTick(() => {
        this.$refs[ref].focus();
      });
    },
    //选择产品-数量切换
    switchSumTouristProDataV2(id, index) {
      if (this.sumNum == "") {
        this.sumNum = 1;
      }
      //改变价格
      let product = this.subscenicProductList.find(item => item.id == id);
      let obj = this.touristSumData[index];
      obj.pice = product.sellingPrice;
      obj.sellingPrice = product.sellingPrice;
      obj.sumPrice = product.sellingPrice * this.sumNum;
      obj.name = product.name;
      // obj.sumNum = this.sumNum;
      obj.sumNum = this.sumNum;
      obj.sybSystemIdProductId = product.id;
      this.$emit("changeTouristSumData", obj);
      // this.getOrderInfo();
    },
    //删除所有游客
    delAllTourist(orderType) {
      this.touristSumData = [];
      this.orderType = orderType;
    },
    handleSelectionTourist(vals) {
      this.selectionTouristData = vals;
    },
    //添加游客
    addTourist(size) {
      let i = 0;
      let arr = [];
      while (i < size) {
        arr.push({}); //默认数量1
        i++;
      }
      this.touristData = this.touristData.concat(arr);
    }
  }
};
</script>
