<template>
  <div>
    <ChangeRecord
      v-if="visible"
      title="资产变更记录"
      :columns="columns"
      :data="data"
      :total="total"
      :is-show-acount-type="true"
      :is-show-business-type="true"
      @dataChange="dataChange"
      @onSearch="onSearch"
    ></ChangeRecord>
  </div>
</template>

<script>
import ChangeRecord from "@/components/ChangeRecord.vue";
import { mapGetters } from "vuex";
import { balanceRecord } from "@/api/member.js"; // MS-会员中心接口
import { moneyFormat } from "@/utils/global";

const columns = [
  {
    title: "变更时间",
    scopedSlots: { customRender: "createTime" },
    width: 170
  },
  { title: "单据编号", dataIndex: "totalOrderId", width: 190 },
  { title: "业务类型", dataIndex: "memberBusinessTypeText", width: 150 },
  { title: "交易商户", dataIndex: "merchantName", width: 200 },
  { title: "交易渠道", dataIndex: "tradingTypeText", width: 100 },
  { title: "变更账户", dataIndex: "belongAccountText", width: 100 },
  { title: "变更金额", dataIndex: "changeNum", width: 120 },
  { title: "变更后余额", dataIndex: "changeTotal", width: 120 },
  { title: "操作人", dataIndex: "creatorName", width: 120 },
  { title: "支付备注", dataIndex: "remark", width: 150 }
];

export default {
  name: "AssetChangeRecord",
  components: {
    ChangeRecord
  },
  data() {
    return {
      columns, // 表格表头
      data: [], // 表格数据列表
      // 接口参数
      interFaceParams: {
        page: 1,
        size: 10
      },
      total: 0, // 总条数
      visible: false // 是否显示
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getBalanceRecordList(); // 查询资产变更记录
  },
  methods: {
    // 查询资产变更记录
    getBalanceRecordList() {
      this.interFaceParams.id = this.memberId;
      balanceRecord(this.interFaceParams)
        .then(res => {
          this.data = res.records;
          this.data.map(item => {
            item.changeNum = moneyFormat(item.changeNum);
            item.changeTotal = moneyFormat(item.changeTotal);
          });

          this.total = res.total;
          this.visible = true; // 等父组件数据回来再显示子组件
        })
        .catch(() => {});
    },

    // 分页变化时触发
    dataChange(pagination) {
      this.interFaceParams.page = pagination.current;
      this.interFaceParams.size = pagination.pageSize;
      this.getBalanceRecordList();
    },

    // 搜索
    onSearch(params) {
      this.interFaceParams.beginTime = params.beginTime;
      this.interFaceParams.endTime = params.endTime;
      this.interFaceParams.totalOrderId = params.orderNo;
      this.interFaceParams.belongAccount = params.accountType;
      this.interFaceParams.memberBusinessType = params.businessType;
      this.getBalanceRecordList();
    }
  }
};
</script>
