<!-- 门票项目消费记录 -->
<template>
  <div class="ph-2x pb-2x">
    <header class="mb-2x">
      <span class="mr-2x">操作日期</span>
      <a-date-picker type="date" @change="dateChange"> </a-date-picker>
      <span class="ml-4x mr-2x">项目名称</span>
      <a-select
        v-model="projectId"
        placeholder="请选择项目"
        allow-clear
        class="mr-4x"
        style="width: 200px"
        @change="onSearch"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option
          v-for="item in playItems"
          :key="item.id"
          :value="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-button @click="onSearch" type="primary">查询</a-button>
    </header>

    <!--表格-->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :scroll="{ x: true }"
      size="middle"
      row-key="id"
    >
      <template slot="createTime" slot-scope="row">{{
        row.createTime | date("YYYY-MM-DD hh:mm:ss")
      }}</template>
      <!--consumeMode === 0 为项目套餐按次， 其它则都要除以100-->
      <template slot="consumePrice" slot-scope="row">{{
        row.consumeMode ? row.consumePrice / 100 : row.consumePrice
      }}</template>

      <template slot="consumeStatus" slot-scope="row">
        <span v-if="row.consumeStatus == 1"> 正常</span>
        <span v-else-if="row.consumeStatus == 2"> 反充审核中</span>
        <span v-else-if="row.consumeStatus == 3"> 已反充</span>
      </template>
      <template slot="consumeWayType" slot-scope="row">
        <span v-for="(item, i) in JSON.parse(row.consumeWayType)" :key="i"
          >{{ item.name }}
          <i v-if="i < Object.keys(JSON.parse(row.consumeWayType)).length - 1"
            >,</i
          >
        </span>
      </template>
      <template slot="action" slot-scope="row">
        <a-button
          class="blue"
          v-if="row.consumeStatus == 1"
          type="link"
          @click="onRecharge(row)"
          >申请反充</a-button
        >
        <a-button class="blue" type="link" @click="showDetail(row)"
          >详情</a-button
        >
      </template>
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x" v-show="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="currentChange"
        @showSizeChange="sizeChange"
      />
    </footer>

    <!--详情弹窗-->
    <a-modal
      title="项目消费记录"
      :visible.sync="details"
      @cancel="details = false"
      width="600px"
      :body-style="{
        maxHeight: '500px',
        overflowY: 'auto'
      }"
    >
      <a-spin tip="加载中..." :spinning="loading">
        <div style="min-height: 350px">
          <!-- 正常-按次或手工-项目套餐 或者 反充审核中11 -->
          <template
            v-if="
              detailStatus == 'Projectpackage' || detailStatus == 'underReview'
            "
          >
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 正常-计时-项目套餐 22-->
          <template v-else-if="detailStatus == 'timeProjectpackage'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 反充-按次或手工-项目套餐 11-->
          <template v-else-if="detailStatus == 'ReverseProjectpackage'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 反充-计时-项目套餐 22-->
          <template v-else-if="detailStatus == 'timeReverseProjectpackage'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
          <!-- 正常-按次或手工-门票资产 11-->
          <template v-else-if="detailStatus == 'TicketAssets'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="memberInfo"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 正常-计时-门票资产 22-->
          <template v-else-if="detailStatus == 'timeTicketAssets'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 反充-按次或手工-门票资产 -->
          <template v-else-if="detailStatus == 'ReverseTicketAssets'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="memberInfo"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
          <!-- 反充-计时-门票资产 33-->
          <template v-else-if="detailStatus == 'timeReverseTicketAssets'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
          <!-- 正常-按次或手工-会员余额 11-->
          <template v-else-if="detailStatus == 'MemberBalance'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="memberInfo"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 正常-计时-会员余额 33-->
          <template v-else-if="detailStatus == 'timeMemberBalance'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 反充-按次或手工-会员余额 11-->
          <template v-else-if="detailStatus == 'ReverseMemberBalance'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="memberInfo"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
          <!-- 反充-计时-会员余额 22-->
          <template v-else-if="detailStatus == 'timeReverseMemberBalance'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>

          <!-- 正常-按次或手工-付款码 11-->
          <template v-else-if="detailStatus == 'PaymentCode'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="operationOrder"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 正常-计时-付款码 22-->
          <template v-else-if="detailStatus == 'timePaymentCode'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationOrder"></div>
              <div v-html="operationBottom"></div>
            </div>
          </template>
          <!-- 反充-按次或手工-付款码 11 -->
          <template v-else-if="detailStatus == 'ReversePaymentCode'">
            <div class="record">
              <div v-html="operationHead"></div>
              <div v-html="operationOrder"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
          <!-- 反充-计时-付款码  22 -->
          <template v-else-if="detailStatus == 'timeReversePaymentCode'">
            <div class="record">
              <div v-html="operationHeadTwo"></div>
              <div v-html="operationOrder"></div>
              <div v-html="operationBottom"></div>
              <div v-html="reverseHtml"></div>
            </div>
          </template>
        </div>
      </a-spin>
      <footer slot="footer" class="text-c">
        <a-button @click="details = false">关闭</a-button>
      </footer>
    </a-modal>
    <!--申请反充弹窗-->
    <a-modal
      title="项目反充申请"
      :visible.sync="visible"
      @cancel="closeVisible"
      width="600px"
      :body-style="{
        maxHeight: '500px',
        overflowY: 'auto'
      }"
    >
      <a-spin tip="加载中..." :spinning="loading">
        <div style="min-height: 350px">
          <a-row>
            <a-col :span="4">门票名称:</a-col>
            <a-col :span="20">{{ name }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">消费票号：</a-col>
            <a-col :span="20">{{ refundData.mainOrderDetailId }} </a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">消费时间:</a-col>
            <a-col :span="20">{{
              refundData.createTime | date("YYYY-MM-DD HH:MM:SS")
            }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">游玩项目:</a-col>
            <a-col :span="20">{{ refundData.productName || "-" }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">消费数量:</a-col>
            <!--consumeMode === 0 为项目套餐按次， 其它则都要除以100-->
            <a-col :span="20">{{
              refundData.consumeMode
                ? refundData.consumePrice / 100
                : refundData.consumePrice
            }}</a-col>
          </a-row>

          <a-row class="mt-2x">
            <a-col :span="4">消费详情:</a-col>
            <a-col :span="20"
              >{{ refundData.consumeMode ? detailsa.num / 100 : detailsa.num }}
              {{ detailsa.name }}</a-col
            >
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">消费操作人:</a-col>
            <a-col :span="20">{{ refundData.consumeOperatorPersonName }}</a-col>
          </a-row>
          <a-row class="mt-2x">
            <a-col :span="4">消费渠道:</a-col>
            <a-col :span="20">{{ refundData.consumeChannelsText }}</a-col>
          </a-row>
          <a-form-model
            class="mt-2x"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
            labelAlign="left"
            ref="refundForm"
            :model="refundForm"
            :rules="rules"
          >
            <a-form-model-item
              label="申请说明"
              prop="refundMoney"
              class="flex-start"
            >
              <a-input
                v-model="refundForm.refundMoney"
                class="form-item-width"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
      <footer slot="footer" class="text-c">
        <a-button @click="closeVisible">取消</a-button>
        <a-button type="primary" @click="onRefundSave">提交申请</a-button>
      </footer>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
// import { findTicketWriteOffInfoPage } from "../../../../api/order";
import { orderConsumerecord, productBackProject } from "@/api/search";
import { itemwriteOffBack, getItemWriteOff } from "@/api/order";

export default {
  name: "ItemPlay",
  props: {
    auxiliaryId: {
      type: [Number, String],
      required: true
    },
    name: String
  },
  data() {
    return {
      detailStatus: "", //详情类型
      itemwriteoffDetail: {}, //项目消费详情
      details: false, //详情弹窗
      loading: false, // 加载中
      visible: false, //申请反充
      date: "", //操作日期
      playItemName: "", // 项目名称
      projectId: "", //项目id
      playItems: [],
      tableData: [], //项目消费记录列表
      refundData: {}, //申请反充详情
      detailsa: {}, //消费详情
      reverseHtml: "", //反充模板
      operationHead: "", //详情头部模板
      operationBottom: "", //详情底部模板
      memberInfo: "", //会员信息模板
      operationHeadTwo: "",
      operationOrder: "",
      refundForm: {
        refundMoney: ""
      },
      rules: {
        refundMoney: [
          { required: true, message: "请输入说明", trigger: "blur" }
        ]
      },
      row: {}, //详情row

      total: 0,
      page: 1,
      size: 10,
      columns: [
        {
          width: 150,
          title: "消费票号",
          dataIndex: "mainOrderDetailId",
          key: "mainOrderDetailId"
        },
        {
          width: 150,
          title: "消费时间",
          scopedSlots: { customRender: "createTime" },
          key: "createTime"
        },
        {
          width: 120,
          title: "消费单号",
          dataIndex: "id",
          key: "id"
        },
        {
          width: 120,
          title: "游玩项目",
          dataIndex: "productName",
          key: "playItemName"
        },
        {
          width: 80,
          title: "消费数量",
          scopedSlots: { customRender: "consumePrice" },
          key: "consumePrice"
        },
        {
          width: 150,
          title: "消费详情",
          dataIndex: "",
          scopedSlots: { customRender: "consumeWayType" },
          key: "consumeWayType"
        },
        {
          width: 120,
          title: "消费操作人",
          dataIndex: "consumeOperatorPersonName",
          key: "consumeOperatorPersonName"
        },
        {
          width: 120,
          title: "消费渠道",
          dataIndex: "consumeChannelsText",
          key: "consumeChannelsText"
        },
        {
          width: 100,
          title: "消费状态",
          scopedSlots: { customRender: "consumeStatus" },
          key: "consumeStatus"
        },
        {
          width: 120,
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "action"
        }
      ]
    };
  },
  created() {
    this.loadData();
    this.getProductBackProject();
  },
  methods: {
    //返回相差分钟数
    onDateFilter(starts, ends) {
      let date = ends - starts;
      //计算出相差天数
      let days = Math.floor(date / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      let mins = hours * 60 + minutes;
      if (days) {
        mins = days * 24 * 60 + mins;
      }
      return mins;
    },
    //项目反充取消
    onRefundClose() {
      this.refundForm.refundMoney = "";
      this.$refs.refundForm.resetFields();
      this.refund = false;
    },
    //项目反充提交
    onRefundSave() {
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          this.loading = true;
          itemwriteOffBack({
            id: this.refundData.id,
            instructions: this.refundForm.refundMoney
          })
            .then(() => {
              this.loadData();
              this.refundForm.refundMoney = "";
              this.closeVisible();
              this.$message.success("提交成功");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$message.warning("验证不通过，请重新检查");
        }
      });
    },
    closeVisible() {
      this.visible = false;
    },
    //过滤消费详情
    consumeWayTypeFilter(data) {
      try {
        let str = "";
        let consumeWay = JSON.parse(data);
        for (let key in consumeWay) {
          this.detailsa = consumeWay[key];
          let num = this.row.consumeMode
            ? consumeWay[key].num / 100
            : consumeWay[key].num;
          str += num;
          str += " ";
          str += consumeWay[key].name;
        }
        return str;
      } catch (e) {
        console.log(e);
      }
    },
    //获取项目列表
    getProductBackProject() {
      productBackProject({ page: 1, size: 500 })
        .then(({ records }) => {
          this.playItems = records;
        })
        .catch(() => {});
    },
    //获取项目消费记录列表
    loadData() {
      this.loading = true;
      orderConsumerecord({
        page: this.page,
        size: this.size,
        consumeNumber: this.auxiliaryId,
        operaTime: this.date,
        projectId: this.projectId
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    //申请反充
    onRecharge(row) {
      this.visible = true;
      this.refundData = row;
      try {
        let consumeWayType = JSON.parse(row.consumeWayType);
        for (let key in consumeWayType) {
          this.detailsa = consumeWayType[key];
        }
      } catch (e) {
        console.log(e);
      }
    },
    //正常状态
    normal() {
      //按次或手工
      if (this.row.deductionType == 0 || this.row.deductionType == 2) {
        if (this.row.consumeMode == 0) {
          //项目套餐
          this.detailStatus = "Projectpackage";
        } else if (this.row.consumeMode == 1) {
          //门票资产
          this.detailStatus = "TicketAssets";
        } else if (this.row.consumeMode == 2) {
          //会员余额
          this.detailStatus = "MemberBalance";
        } else if (this.row.consumeMode == 3 || this.row.consumeMode == 4) {
          //付款码 (3, "微信支付"),4, "支付宝支付")
          this.detailStatus = "PaymentCode";
        }
      } else {
        //计时
        if (this.row.consumeMode == 0) {
          //项目套餐
          this.detailStatus = "timeProjectpackage";
        } else if (this.row.consumeMode == 1) {
          //门票资产
          this.detailStatus = "timeTicketAssets";
        } else if (this.row.consumeMode == 2) {
          //会员余额
          this.detailStatus = "timeMemberBalance";
        } else if (this.row.consumeMode == 3 || this.row.consumeMode == 4) {
          //付款码 (3, "微信支付"),4, "支付宝支付")
          this.detailStatus = "timePaymentCode";
        }
      }
    },
    //反充状态
    Backfilled() {
      //按次或手工
      if (this.row.deductionType == 0 || this.row.deductionType == 2) {
        if (this.row.consumeMode == 0) {
          //项目套餐
          this.detailStatus = "ReverseProjectpackage";
        } else if (this.row.consumeMode == 1) {
          //门票资产
          this.detailStatus = "ReverseTicketAssets";
        } else if (this.row.consumeMode == 2) {
          //会员余额
          this.detailStatus = "ReverseMemberBalance";
        } else if (this.row.consumeMode == 3 || this.row.consumeMode == 4) {
          //付款码 (3, "微信支付"),4, "支付宝支付")
          this.detailStatus = "ReversePaymentCode";
        }
      } else {
        //计时
        if (this.row.consumeMode == 0) {
          //项目套餐
          this.detailStatus = "timeReverseProjectpackage";
        } else if (this.row.consumeMode == 1) {
          //门票资产
          this.detailStatus = "timeReverseTicketAssets";
        } else if (this.row.consumeMode == 2) {
          //会员余额
          this.detailStatus = "timeReverseMemberBalance";
        } else if (this.row.consumeMode == 3 || this.row.consumeMode == 4) {
          //付款码 (3, "微信支付"),4, "支付宝支付")
          this.detailStatus = "timeReversePaymentCode";
        }
      }
    },
    //详情
    showDetail(row) {
      //手工和按次内容一样，但是手工扣费时没有刷项目套餐
      this.row = row;
      this.onGetItemWriteOff(row.id);
      this.operationHead = `<div>
              <span>门票名称:</span>
              <span class="ml-2x">
                <span>${this.name}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费票号:</span>
              <span class="ml-2x">
                <span>${row.mainOrderDetailId}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>游玩项目:</span>
              <span class="ml-2x">
                <span>${row.productName || "-"}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费时间:</span>
              <span class="ml-2x">
                <span>${this.$root.$options.filters.date(
                  row.createTime,
                  "YYYY-MM-DD HH:MM:SS"
                )}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费数量:</span>
              <span class="ml-2x">
                <span>${
                  row.consumeMode ? row.consumePrice / 100 : row.consumePrice
                }</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费详情:</span>
              <span class="ml-2x">
                <span>${this.consumeWayTypeFilter(
                  row.consumeWayType
                )} </span></span
              >
            </div>`;
      this.operationBottom = `<div class="mt-2x">
              <span>消费操作人:</span>
              <span class="ml-2x">
                <span>${row.consumeOperatorPersonName}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费渠道:</span>
              <span class="ml-2x">
                <span>${row.consumeChannelsText}</span></span
              >
            </div>
            <div class="mt-2x">
              <span>消费状态:</span>
              <span class="ml-2x primary">
              ${
                row.consumeStatus == 1
                  ? "正常"
                  : row.consumeStatus == 2
                  ? "反充审核中"
                  : "已反充"
              }</span>
              </span>
            </div>`;
      if (row.consumeStatus == 1) {
        //正常
        this.normal();
      } else if (row.consumeStatus == 3) {
        //已反充
        this.Backfilled();
      } else {
        //反充审核中
        this.detailStatus = "underReview";
      }
      this.details = true;
    },
    //项目消费详情
    onGetItemWriteOff(id) {
      getItemWriteOff({ id })
        .then(res => {
          this.itemwriteoffDetail = res;
          this.reverseHtml = `<div class="mt-2x">
                <span>反充申请时间:</span>
                <span class="ml-2x">
                  <span>${this.$root.$options.filters.date(
                    res.backConsumeApplyTime,
                    "YYYY-MM-DD HH:MM:SS"
                  ) || "-"}</span>
                </span>
              </div>
              <div class="mt-2x">
                <span>反充申请人:</span>
                <span class="ml-2x">
                  <span>${res.backConsumePersonName || "-"}</span>
                </span>
              </div>
              <div  class="mt-2x">
                <span>反充申请渠道:</span>
                <span class="ml-2x">
                  <span>${res.backConsumeChannasText || "-"}</span>
                </span>
              </div>
              <div  class="mt-2x">
                <span>反充申请说明:</span>
                <span class="ml-2x">
                  <span>${res.backConsumeRemarks}</span>
                </span>
              </div>
              <div class="mt-2x">
                <span>反充审核时间:</span>
                <span class="ml-2x">
                  <span>${this.$root.$options.filters.date(
                    res.backConsumeExamineTime,
                    "YYYY-MM-DD HH:MM:SS"
                  )}</span>
                </span>
              </div>
               <div class="mt-2x">
                <span>反充审核人:</span>
                <span class="ml-2x">
                  <span>${res.backConsumeOperatorPersonName}</span>
                </span>
              </div>`;
          this.memberInfo = `<div class="mt-2x">
                <span>会员信息:</span>
                <span class="ml-2x">
                  <span
                    >${res.purchaserMemberPhone || ""} /
                    ${res.purchaserMemberName || ""}</span
                  ></span
                >
              </div>`;
          this.operationHeadTwo = `<div class="mt-2x">
                <span>门票名称:</span>
                <span class="ml-2x">
                  <span>${this.name}</span></span
                >
              </div>
              <div class="mt-2x">
                <span>消费票号:</span>
                <span class="ml-2x">
                  <span>${this.row.mainOrderDetailId}</span></span
                >
              </div>
              <div class="mt-2x">
                <span>游玩项目:</span>
                <span class="ml-2x">
                  <span>${this.row.productName || "-"}</span></span
                >
              </div>
              <div  class="mt-2x">
                <span>游玩开始时间:</span>
                <span class="ml-2x">
                  <span>${this.$root.$options.filters.date(
                    res.playBeginTime,
                    "YYYY-MM-DD HH:MM:SS"
                  )}</span></span
                >
              </div>
               <div class="mt-2x">
                <span>押金:</span>
                <span class="ml-2x">
                  <span>${res.depositInfo || "-"}</span></span
                >
              </div>
              <div class="mt-2x">
                <span>游玩结束时间:</span>
                <span class="ml-2x">
                   <span>${this.$root.$options.filters.date(
                     res.playEndTime,
                     "YYYY-MM-DD HH:MM:SS"
                   )}</span></span
                >
              </div>
              <div class="mt-2x">
                <span>游玩时长:</span>
                <span class="ml-2x">
                    <span>${this.onDateFilter(
                      res.playBeginTime,
                      res.playEndTime
                    )}分钟</span>
                    </span
                >
              </div>
              <div class="mt-2x">
                <span>应收:</span>
                <span class="ml-2x">
                  <span>${res.receivableInfo || "-"}</span></span
                >
              </div>
              <div class="mt-2x">
                <span>收费详情:</span>
                <span class="ml-2x">
                  <span>${this.consumeWayTypeFilter(
                    this.row.consumeWayType
                  )} </span></span
                >
              </div>
              <div class="mt-2x">
                <span>会员信息:</span>
                <span class="ml-2x">
                  <span
                    >${res.purchaserMemberPhone || ""} /
                    ${res.purchaserMemberName || ""}</span
                  ></span
                >
              </div>
              <div  class="mt-2x">
                <span>消费数量:</span>
                <span>
                  <span>${
                    this.row.consumeMode
                      ? this.row.consumePrice / 100
                      : this.row.consumePrice
                  }</span></span
                >
              </div>
           `;
          this.operationOrder = `<div  class="mt-2x">
                <span>订单编号:</span>
                <span class="ml-2x">
                  <span>${res.orderId || "-"}</span></span
                >
              </div>
           `;
        })
        .catch(() => {});
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    dateChange(val) {
      if (val) this.date = moment(val).valueOf();
      else this.date = "";
      this.onSearch();
    },
    // 页面改变
    currentChange(p) {
      this.page = p;
      this.loadData();
    },
    // 查询
    onSearch() {
      this.page = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="less" scoped>
.form-item-width {
  width: 300px;
}
</style>
