<!--重新打印门票-->
<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="重新打印门票"></panel-head>

    <section class="main mt-2x">
      <header class="container">
        <a-form layout="inline">
          <a-form-item label="订单号/票号">
            <a-input-search
              placeholder="请输入订单号/票号"
              @search="onSearch"
              style="width: 400px;"
              v-inputfocus
              enter-button
              allowClear
            />
          </a-form-item>

          <a-form-item label="起始打印序号">
            <a-input
              v-model.lazy="printStartNo"
              type="number"
              style="width: 100px;"
              allowClear
            />
          </a-form-item>
        </a-form>
      </header>
      <!--列表-->
      <p class="center disabled font-xxxl" v-if="!searched">
        请输入起始票号查询出相关联的未打印成功的门票
      </p>
      <a-table
        v-else
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange
        }"
      >
        <!-- 门票状态 -->
        <span slot="templateLayoutInfo" class=" primary">已取票</span>
      </a-table>
    </section>

    <footer class="footer">
      <a-button
        type="primary"
        shape="round"
        size="large"
        @click="rePrintAll"
        :loading="printing"
        >重打已选门票</a-button
      >
      <a-button size="large" shape="round" class="ml-3x" @click="$router.go(-1)"
        >返回</a-button
      >
    </footer>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import { orderAgainTakeTicket } from "../../../api/search";
import HardwareService from "../../../common/domains/hardware-domain/hardwareService";
export default {
  name: "RePrint",
  components: { PanelHead },
  data() {
    const columns = [
      {
        title: "序号",
        dataIndex: "productSn",
        width: 60
      },
      {
        title: "票号",
        dataIndex: "auxiliaryId",
        width: 130
      },
      {
        title: "门票名称",
        dataIndex: "productName",
        ellipsis: true,
        width: 250
      },

      {
        title: "取票状态",
        scopedSlots: { customRender: "templateLayoutInfo" },
        width: 90
      },
      {
        title: "游玩日期",
        dataIndex: "playDate",
        width: 120
      },
      {
        title: "使用有效期",
        dataIndex: "validTime",
        width: 200
      },
      {
        title: "订单编号",
        dataIndex: "mainOrderId",
        width: 180
      },
      {
        title: "预订时间",
        dataIndex: "createTime",
        width: 150
      }
    ];
    return {
      columns,
      tableData: [],
      searched: false,
      selectedRowKeys: [],
      printing: false, // 打印中
      printStartNo: "" //起始打印序号
    };
  },
  watch: {
    printStartNo: function(val) {
      this.onInputPrintStartNo({ data: val });
    }
  },
  methods: {
    // 查询
    onSearch(val) {
      orderAgainTakeTicket({
        ticketNo: val
      })
        .then(res => {
          //将返回结果的字符串转换成数字
          res?.forEach(item => {
            if (
              item?.productSn != undefined &&
              typeof item.productSn == "string"
            ) {
              //转换失败则不再转换
              item.productSn = !isNaN(parseInt(item.productSn))
                ? parseInt(item.productSn)
                : item.productSn;
            }
          });
          this.tableData = res;
          this.selectedRowKeys = res.map((item, index) => {
            return index;
          });
          this.searched = true;
        })
        .catch(() => {});
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 打印所有
    rePrintAll() {
      const arr = [];
      this.selectedRowKeys.forEach(item => {
        arr.push(this.tableData[item]);
      });
      if (arr.length === 0) {
        this.$message.error("请选择要重新打印的门票");
        return;
      }
      HardwareService.printTicket(JSON.stringify(arr));
      this.printing = true;
      let timer = setTimeout(() => {
        this.printing = false;
        clearTimeout(timer);
        timer = null;
      }, 3000);
    },
    //起始打印序号输入事件
    onInputPrintStartNo(e) {
      //从当前序号（包括）到结束序号
      let no = e.data;
      let length = this.tableData.length;
      if (!length) {
        return;
      }

      let keys = [];
      for (let index = parseInt(no) - 1; index < length; index++) {
        keys.push(index);
      }
      this.selectedRowKeys = keys;
    }
  }
};
</script>

<style scoped></style>
