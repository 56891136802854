<template>
  <a-tabs v-model="activeIndex">
    <a-tab-pane key="0" tab="商户汇总">
      <a-table
        v-if="activeIndex === '0'"
        rowKey="merchantId"
        :defaultExpandAllRows="true"
        :expandedRowKeys.sync="expandedRowKeys"
        @expand="topExpand"
        :columns="merchantColumns"
        :data-source="merchantData"
        :pagination="false"
        :expandRowByClick="true"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        :indentSize="20"
        :scroll="{ y: 400 }"
      >
        <div slot="money" slot-scope="row">
          {{ row | money }}
        </div>
        <template slot="action" slot-scope="row">
          <a-button
            type="link"
            class="p-0"
            v-if="row.productFormsName != '合计'"
            >明细</a-button
          >
        </template>
        <!-- 子表格 -->
        <a-table
          class="pt-2x child-table"
          slot="expandedRowRender"
          slot-scope="record"
          :scroll="{ y: 300 }"
          :columns="innerColumns"
          :data-source="record.paymentWayVOList"
          :pagination="false"
          bordered
        >
          <div slot="money" slot-scope="row">
            {{ row | money }}
          </div>
        </a-table>
      </a-table>
    </a-tab-pane>
    <a-tab-pane key="1" tab="产品汇总" force-render>
      <a-table
        childrenColumnName="childrenDataList"
        :columns="productColumns"
        :data-source="productData"
        :pagination="false"
        :expandRowByClick="true"
        :indentSize="20"
        :scroll="{ y: 400 }"
        rowKey="id"
      >
        <div slot="money" slot-scope="row">
          {{ row | money }}
        </div>
      </a-table>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import {
  orderAccountMerchantssummary,
  orderAccountProductsummary
} from "@/api/search";

const merchantColumns = [
  {
    title: "业态",
    dataIndex: "productFormsName",
    key: "productFormsName"
  },
  {
    title: "子景区名称",
    dataIndex: "subMerchantScenicName",
    key: "subMerchantScenicName"
  },
  {
    title: "商户名称",
    dataIndex: "merchantName",
    key: "merchantName"
  },
  {
    title: "销售数量",
    dataIndex: "buyNumSum",
    key: "buyNumSum"
  },
  {
    title: "收款金额",
    key: "actualAmountSum",
    dataIndex: "actualAmountSum",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "退票数量",
    dataIndex: "refundNumSum",
    key: "refundNumSum"
  },
  {
    title: "退款金额",
    key: "totalRefundAmount",
    dataIndex: "refundAmountSum",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "实收票数",
    dataIndex: "paidInNumSum",
    key: "paidInNumSum"
  },
  {
    title: "实收小计",
    key: "paidInAmountSum",
    dataIndex: "paidInAmountSum",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

const innerColumns = [
  {
    title: "收款方式",
    dataIndex: "payWayName",
    key: "payWayName"
  },
  {
    title: "收款金额",
    key: "actualAmount",
    dataIndex: "actualAmount",
    scopedSlots: { customRender: "money" },
    className: "table_title"
  },
  {
    title: "销售数量",
    key: "buyNum",
    dataIndex: "buyNum"
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    scopedSlots: { customRender: "money" },
    className: "table_title"
  },
  {
    title: "金额小计",
    key: "paidInAmount",
    dataIndex: "paidInAmount",
    scopedSlots: { customRender: "money" },
    className: "table_title"
  }
];

const productColumns = [
  {
    title: "业态",
    dataIndex: "productFormsName",
    key: "productFormsName"
  },
  {
    title: "子景区名称",
    dataIndex: "subMerchantScenicName",
    key: "subMerchantScenicName"
  },
  {
    title: "产品编号",
    dataIndex: "productId",
    key: "productId"
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    key: "productName"
  },
  {
    title: "产品所属商户",
    dataIndex: "productMerchants",
    key: "productMerchants"
  },
  {
    title: "产品类型",
    dataIndex: "productCategory",
    key: "productCategory"
  },
  {
    title: "产品分类",
    dataIndex: "productType",
    key: "productType"
  },
  {
    title: "业务类型",
    dataIndex: "businessType",
    key: "businessType"
  },
  // {
  //   title: "收款方式",
  //   dataIndex: "payWayName",
  //   key: "payWayName"
  // },
  {
    title: "销售数量",
    dataIndex: "salesNum",
    key: "salesNum"
  },
  {
    title: "退款数量",
    dataIndex: "refundNum",
    key: "refundNum"
  },
  {
    title: "数量小计",
    dataIndex: "actualNum",
    key: "actualNum"
  },
  {
    title: "销售金额",
    key: "actualAmount",
    dataIndex: "actualAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "金额小计",
    key: "paidInAmount",
    dataIndex: "paidInAmount",
    scopedSlots: { customRender: "money" }
  }
];

export default {
  name: "AccountslTable",
  props: {
    // 子景区
    userInfos: {
      type: Object
    }
  },
  data() {
    return {
      //0 商户汇总  1 产品汇总
      activeIndex: "0",
      merchantColumns, //商户汇总表格标题
      merchantData: [
        {
          saleAmount: 100
        }
      ], //商户汇总数据
      //明细表格标题
      innerColumns,
      //明细数据
      innerData: [],
      //产品表格标题
      productColumns,
      //产品表格数据
      productData: [],
      money: {}, //销售汇总合计
      productTotal: {}, //产品汇总合计
      dialag: false,
      expandedRowKeys: []
    };
  },
  watch: {
    userInfos: {
      handler() {
        this.loadData();
      },
      deep: true
    }
  },
  created() {},
  methods: {
    //自定义展开事件-合计行不允许展开
    topExpand(expanded, record) {
      if (expanded && record.merchantName !== "合计") {
        this.expandedRowKeys.push(record.merchantId);
      } else if (!expanded && record.merchantName !== "合计") {
        this.expandedRowKeys.splice(
          this.expandedRowKeys.indexOf(record.merchantId),
          1
        );
      }
    },
    //加载列表
    loadData() {
      //销售汇总
      orderAccountMerchantssummary({
        accountStartTime: this.userInfos.clockIn,
        accountEndTime: this.userInfos.clockOff
      })
        .then(res => {
          let {
            records,
            totalActualAmount,
            totalPaidInAmount,
            totalRefundAmount
          } = res;
          let buyNumSum = 0;
          let refundNumSum = 0;
          let paidInNumSum = 0;
          records.forEach(item => {
            buyNumSum += item.buyNumSum;
            refundNumSum += item.refundNumSum;
            paidInNumSum += item.paidInNumSum;
          });
          this.merchantData = records;
          this.expandedRowKeys = records.map(item => item.merchantId);
          this.money.buyNumSum = buyNumSum;
          this.money.totalActualAmount = totalActualAmount;
          this.money.totalPaidInAmount = totalPaidInAmount;
          this.money.totalRefundAmount = totalRefundAmount;
          this.money.refundNumSum = refundNumSum;
          this.money.paidInNumSum = paidInNumSum;
          this.addMerchantRowTotal();
        })
        .catch(() => {});

      //交账产品汇总
      orderAccountProductsummary({
        accountStartTime: this.userInfos.clockIn,
        accountEndTime: this.userInfos.clockOff
      })
        .then(res => {
          let {
            totalSalesNum,
            totalRefundNum,
            totalPaidInNum,
            totalActualAmount,
            totalPaidInAmount,
            totalRefundAmount
          } = res;
          this.productData = res.records;
          sessionStorage.setItem("productData", JSON.stringify(res.records));
          this.productTotal.totalSalesNum = totalSalesNum;
          this.productTotal.totalRefundNum = totalRefundNum;
          this.productTotal.totalPaidInNum = totalPaidInNum;

          this.productTotal.totalActualAmount = totalActualAmount;
          this.productTotal.totalPaidInAmount = totalPaidInAmount;
          this.productTotal.totalRefundAmount = totalRefundAmount;
          this.addCalcRowTotal();
        })
        .catch(() => {});
    },
    //查看明细
    checkDetails(record) {
      this.innerData = record.paymentWayVOList;
    },
    // 销售汇总 表格增加一行：合计
    addMerchantRowTotal() {
      this.merchantData.push({
        productFormsName: "合计",
        buyNumSum: this.money.buyNumSum,
        actualAmountSum: this.money.totalActualAmount,
        refundAmountSum: this.money.totalRefundAmount,
        paidInAmountSum: this.money.totalPaidInAmount,
        refundNumSum: this.money.refundNumSum,
        paidInNumSum: this.money.paidInNumSum
      });
    },
    // 产品汇总 表格增加一行：合计
    addCalcRowTotal() {
      this.productData.push({
        productFormsName: "合计",
        salesNum: this.productTotal.totalSalesNum,
        refundNum: this.productTotal.totalRefundNum,
        actualNum: this.productTotal.totalPaidInNum,
        actualAmount: this.productTotal.totalActualAmount,
        refundAmount: this.productTotal.totalRefundAmount,
        paidInAmount: this.productTotal.totalPaidInAmount
      });
    }
  }
};
</script>
<style lang="less" scoped>
th.table_title {
  background-color: #e5e5e5 !important;
}
</style>
