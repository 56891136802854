<!-- 组件：资产变更记录、积分变更记录、成长值变更记录-->
<template>
  <div class="flex-col flex-between wrapper">
    <panel-head :title="title"></panel-head>

    <section class="main mt-2x">
      <!-- 查询 -->
      <a-form-model
        class="pv-2x pl-2x"
        layout="inline"
        ref="searchForm"
        :model="searchForm"
      >
        <a-form-model-item label="订单日期">
          <a-range-picker
            style="width: 190px"
            v-model="orderDate"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="订单号" prop="orderNo">
          <a-input
            class="input"
            v-model="searchForm.orderNo"
            placeholder="请输入订单号"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          label="变更账户"
          prop="accountType"
          v-if="isShowAcountType"
        >
          <a-select
            class="input"
            v-model="searchForm.accountType"
            placeholder="请选择变更账户"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="item in belongAccountList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="业务类型"
          prop="businessType"
          v-if="isShowBusinessType"
        >
          <a-select
            class="input"
            v-model="searchForm.businessType"
            placeholder="请选择业务类型"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="item in businessTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="变更类型"
          prop="changeType"
          v-if="isShowIntegralChangeType"
        >
          <a-select
            class="input"
            v-model="searchForm.changeType"
            placeholder="请选择变更类型"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              :value="item.value"
              v-for="item in integralChangeTypeList"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="变更类型"
          prop="changeType"
          v-if="isShowGrowChangeType"
        >
          <a-select
            class="input"
            v-model="searchForm.changeType"
            placeholder="请选择变更类型"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              :value="item.value"
              v-for="item in growChangeTypeList"
              :key="item.id"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onSearch">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="onReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 表格 -->
      <a-table
        :scroll="{ x: 1300, y: 430 }"
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="onChange"
        :row-key="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="createTime" slot-scope="row">
          {{ row.createTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot="changeNum" slot-scope="row">
          {{ row.changeNum | money }}
        </template>
        <template slot="changeTotal" slot-scope="row">
          {{ row.changeTotal | money }}
        </template>
      </a-table>
    </section>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        type="primary"
        shape="round"
        class="btn-lg"
        size="large"
        ghost
        @click="$router.go(-1)"
        >返回</a-button
      >
    </div>
  </div>
</template>

<script>
import { dataDictionary } from "@/api/setting";
import moment from "moment";
import panelHead from "./common/PanelHead.vue";

export default {
  name: "ChangeRecord",
  components: {
    panelHead
  },
  props: {
    // 页面标题
    title: {
      type: String,
      default: "",
      required: true
    },
    // 表格表头
    columns: {
      type: Array,
      default() {
        return [];
      },
      required: true
    },
    // 表格数据
    data: {
      type: Array,
      default() {
        return [];
      },
      required: true
    },
    // 总条数
    total: {
      type: Number,
      default: 0,
      required: true
    },
    // 是否显示变更账户
    isShowAcountType: {
      type: Boolean,
      default: false
    },
    // 是否显示业务类型
    isShowBusinessType: {
      type: Boolean,
      default: false
    },
    // 是否显示积分变更类型
    isShowIntegralChangeType: {
      type: Boolean,
      default: false
    },
    // 是否显示变成长值更类型
    isShowGrowChangeType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      belongAccountList: [], // 所属账户列表
      businessTypeList: [], // 业务类型列表
      integralChangeTypeList: [], // 积分变更类型列表
      growChangeTypeList: [], // 成长值变更类型列表
      orderDate: ["", ""], // 订单日期
      searchForm: {
        beginTime: "", // 开始日期
        endTime: "", // 结束日期
        orderNo: "", // 单据编号
        businessType: "", // 业务类型
        accountType: "", // 变更账户
        changeType: "" // 变更类型
      },
      // 分页参数
      pagination: {
        defaultCurrent: 1, // 默认的当前页数
        total: 0, // 总条数(用于分页)
        showTotal: total => {
          return `共 ${total} 条`;
        }, // 显示总条数
        pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示多少条
        showSizeChanger: true // 是否可以改变 pageSize
      }
    };
  },
  created() {
    this.pagination.total = this.total; // 总条数

    this.getKeyData(); // 获取字典数据
  },
  methods: {
    // 获取字典数据
    getKeyData() {
      dataDictionary({
        keys: "belongAccount,memberBusinessType,integralChangeType,changeType"
      })
        .then(res => {
          this.belongAccountList = res.belongAccount; // 获取所属账户列表
          this.businessTypeList = res.memberBusinessType; // 获取业务类型列表
          this.integralChangeTypeList = res.integralChangeType; // 获取积分变更类型列表
          this.growChangeTypeList = res.changeType; // 获取成长值变更类型列表
        })
        .catch(() => {});
    },

    // 日期选择
    dateChange([start, end]) {
      if (start && end) {
        this.searchForm.beginTime = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.endTime = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.beginTime = "";
        this.searchForm.endTime = "";
      }
    },

    // 分页变化时触发
    onChange(pagination) {
      this.$emit("dataChange", pagination);
    },

    // 搜索
    onSearch() {
      this.$emit("onSearch", this.searchForm);
    },

    // 重置
    onReset() {
      this.$refs.searchForm.resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 170px;
}
</style>
