/**
 *@date:  过滤接口
 *@version: 3.0
 */

import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/report/ck";

//查询过滤字段查询-刘院民 - http://192.168.1.50:40001/project/41/interface/api/3963 改为/api/report/ck前缀了
export const orderFilterCondition = data => {
  return http.get(`${prefix}/order/filter/condition`, data);
};
