<template>
  <div class="wrapper flex-between flex-col">
    <!--查询-->
    <div class="header-wrap">
      <div class="header">
        <a-form-model layout="inline" :model="searchForm" ref="searchForm">
          <a-form-model-item label="订单日期">
            <a-range-picker
              :allow-clear="false"
              class="input"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="单号" prop="keyword">
            <!-- 处理 输入空格过滤的问题 -->
            <a-input
              v-model.trim="searchForm.keyword"
              placeholder="订单号/总单号/外部单号/票号"
              class="input"
              style="width:250px"
              allow-clear
              ref="orderListRef"
              @pressEnter="reload"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="reload">查询</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="link" @click="visibleSearch = true"
              >高级查询</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <!--高级查询-->
        <a-drawer
          title="高级查询"
          placement="right"
          :width="400"
          :closable="false"
          :visible="visibleSearch"
          @close="visibleSearch = false"
        >
          <a-form-model
            ref="heightForm"
            :model="searchForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <!-- <a-form-model-item label="下单日期">
              <a-range-picker
                :allow-clear="false"
                v-model="dateRange"
                @change="dateChange"
              />
            </a-form-model-item> -->
            <!-- <a-form-model-item label="单号" prop="orderId">
              <a-input
                v-model="searchForm.orderId"
                placeholder="输入订单号/总单号或外部单号"
                allow-clear
              >
              </a-input>
            </a-form-model-item> -->
            <a-form-model-item label="销售渠道" prop="buyChannel">
              <a-cascader
                :display-render="displayRender"
                :fieldNames="{
                  value: 'id',
                  label: 'channelName',
                  children: 'orderSaleChannelVOS'
                }"
                change-on-select
                :options="buyChannel"
                placeholder="请选择销售渠道"
                @change="fromChange"
              />
            </a-form-model-item>
            <a-form-model-item label="订单状态" prop="status">
              <a-select
                v-model="searchForm.status"
                placeholder="请选择订单状态"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in status"
                  :key="index"
                  :value="item.value"
                  >{{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="支付方式" prop="payWay">
              <a-select
                v-model="searchForm.payWay"
                placeholder="请选择支付方式"
                @change="changePayWay"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in paymentType"
                  :key="index"
                  :value="item.payWayQuery"
                  >{{ item.payWayName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="支付通道" prop="payWay">
              <a-select
                v-model="searchForm.payChannels"
                placeholder="请选择支付通道"
              >
                <a-select-option value="">{{ labelText }}</a-select-option>
                <a-select-option
                  v-for="(item, index) in channels"
                  :key="index"
                  :value="item.payChannelId"
                  >{{ item.payChannelName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 商户独立查询条件 -->
            <!-- <template v-if="searchForm.orderType === 2"> -->
            <!-- <a-form-model-item label="产品业态" prop="productCategoryId">
                <a-select
                  v-model="searchForm.productCategoryId"
                  placeholder="请选择产品业态"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option
                    v-for="(item, index) in productFormatList"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item> -->
            <a-form-model-item label="商户" prop="merchantId">
              <a-select
                v-model="searchForm.merchantId"
                placeholder="请选择商户"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in merchantList"
                  :key="index"
                  :value="item.id"
                  >{{ item.merchantName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="销售员" prop="salespersonId">
              <a-select
                showSearch
                optionFilterProp="children"
                v-model="searchForm.salespersonId"
                placeholder="请选择销售员"
              >
                <a-select-option
                  v-for="(item, index) in merchantSeller"
                  :key="index"
                  :value="item.userId"
                >
                  {{ item.userName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- </template> -->
            <!-- <a-form-model-item label="会员" prop="purchaserMember">
              <a-input
                v-model="searchForm.purchaserMember"
                placeholder="请输入会员编号或手机号"
                allow-clear
              >
              </a-input>
            </a-form-model-item> -->
            <a-form-model-item label="优惠活动">
              <a-select v-model="searchForm.discountId" placeholder="请选择">
                <a-select-option
                  v-for="(item, index) in discountActivityList"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="游客信息" :colon="false">
              <a-input
                v-model="searchForm.visitors"
                placeholder="请输入姓名/手机号/身份证"
                allow-clear
              />
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
              <a-button type="primary" class="mr-2x" @click="reload"
                >查询</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-drawer>
      </div>
      <!-- 各业态快速搜索 -->
      <div>
        <a-button
          @click="formatQuickSearchClick(item, index)"
          v-for="(item, index) in formatList"
          :key="index"
          :type="btnType(index)"
          :style="
            formatQuickSearchIndex !== index
              ? {
                  borderColor: 'rgb(226, 226, 226)',
                  color: 'rgba(0, 0, 0, 0.65)'
                }
              : ''
          "
          class="mr-2x mt-1x"
        >
          {{ item.text }}
        </a-button>
      </div>
    </div>

    <section class="main mt-2x">
      <!--表格-->
      <order-list-table
        :table-data="tableData"
        @refunded="loadData"
        @loadData="loadData"
      ></order-list-table>

      <!--分页-->
      <div class="flex-between p-2x">
        <div class="mt-2x flex-start font-l">
          <p class="bold">汇总：</p>
          <div>
            销售额：<span class="red">{{ salesVolume | money("") }}元</span>
          </div>
          <div class="mh-3x">
            退款：<span class="red"> {{ refund | money("") }}元</span>
          </div>
          <div>
            实收：<span class="red"
              >{{ (salesVolume - refund) | money("") }}元</span
            >
          </div>
        </div>
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
    </section>
  </div>
</template>

<script>
import {
  orderWindoworder
  // orderProductformats
} from "@/api/search";
import { dataDictionary } from "@/api/setting";
import moment from "moment";
import { changFouce } from "@/utils/global";
import OrderListTable from "./components/OrderListTable";
import { subMerchantList, orderSaleChannel } from "../../../api/merchant";
import {
  payWayForOrderQuery,
  findPayMerchantChannelSettingAll
} from "@/api/pay";
import { findUserList } from "@/api/user";

export default {
  name: "BaseOrderList",
  props: {
    isRetail: {
      type: Boolean,
      default: () => false
    }
  },
  components: { OrderListTable },
  data() {
    return {
      moment,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      dateRange: [moment(), moment()],
      searchForm: {
        page: 1,
        size: 10,
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        keyword: "", // 订单编号/外部单号/会员编号/会员手机号/会员姓名
        buyChannel: "", // 销售渠道
        status: "", //订单状态
        payWay: "", // 支付方式
        payChannels: "", //支付通道
        merchantId: "", // 商户id
        salespersonId: "", // 销售员i
        discountId: "", // 优惠活动id
        visitors: "", // 出游人
        productForms: "" // 新版（业态：16会员 6票务 7游乐 8剧院 9酒店 10餐饮 11零售 12租赁 13组合）

        // purchaserMember: "", // 会员编号或手机号
        // productCategoryId: "", //产品业态
        // merchantId: "", //商户
        // salespersonId: "", //销售员
        // discountId: "", // 优惠活动

        // orderType: 1, //订单类型，查询用
        // salespersonIds: [] //待删除
      },
      visibleSearch: false,
      // 字典s
      merchantList: [], //商户列表
      // productFormatList: [], //产品业态
      merchantSeller: [], //商户销售员
      buyChannel: [], // 销售渠道
      paymentType: [], // 支付方式
      channels: [], //支付通道列表
      status: [], // 订单状态
      // 订单表格
      total: 0, // 总条数
      tableData: [],
      // size: "large",
      labelText: "请先选择支付方式", //支付通道提示文字
      // 各业态快速搜索
      formatQuickSearchIndex: "",
      formatList: [
        { text: "会员", value: 1 },
        { text: "票务", value: 2 },
        { text: "游乐", value: 3 },
        { text: "剧院", value: 4 },
        { text: "酒店", value: 5 },
        { text: "餐饮", value: 6 },
        { text: "零售", value: 7 },
        { text: "租赁", value: 8 },
        { text: "组合", value: 9 }
      ],
      discountActivityList: [
        { name: "满减优惠", id: "1364396390214012929" },
        { name: "特惠产品", id: "1461232124585140225" },
        { name: "限时抢购", id: "1364398273544589313" },
        { name: "优惠券", id: "1364398345405599745" },
        { name: "窗口折扣", id: "1364398349222416385" },
        { name: "消费有礼", id: "1364398353810984961" },
        { name: "游玩有礼", id: "1364398359448129537" }
      ],
      salesVolume: 0, // 销售额
      refund: 0 // 退款
    };
  },
  created() {
    this.loadData();
    this.loadKeys();
    this.loadSaleChannel();
    this.loadPayWay();
    this.loadMerchant();
    // this.loadProductformats();
    this.loadProductSalesman();
    changFouce(this, "orderListRef");
    // if (this.$route.query.orderType) {
    //   this.typeBtn(Number(this.$route.query.orderType));
    // }
  },
  methods: {
    // //订单类型
    // typeBtn(type) {
    //   this.searchForm.orderType = type;
    //   this.loadData();
    //   if (type === 1) {
    //     this.size = "large";
    //   } else {
    //     this.size = "default";
    //   }
    // },
    // 字典数据 ：payWay 支付方式旧的已废弃
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,orderStatus,formatType"
      })
        .then(data => {
          this.status = data.orderStatus;
          this.formatList = data.formatType; // 业态
        })
        .catch(() => {});
    },
    //查询支付方式
    loadPayWay() {
      payWayForOrderQuery()
        .then(res => {
          this.paymentType = res;
        })
        .catch(() => {});
    },
    // 加载销售渠道
    loadSaleChannel() {
      orderSaleChannel()
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },
    //获取商户列表
    loadMerchant() {
      subMerchantList()
        .then(res => {
          this.merchantList = res;
        })
        .catch(() => {});
    },
    // //加载产品业态
    // loadProductformats() {
    //   orderProductformats()
    //     .then(res => {
    //       this.productFormatList = res;
    //     })
    //     .catch(() => {});
    // },
    //支付方式改变时，获取支付通道
    changePayWay(val) {
      if (!val) {
        this.labelText = "请先选择支付方式";
        return;
      }
      const item = this.paymentType.find(temp => temp.payWayQuery === val);
      findPayMerchantChannelSettingAll({
        payWayId: item.payWayId
      })
        .then(res => {
          this.channels = [];
          if (res.length > 0) {
            this.channels = res;
            this.labelText = "全部";
          } else {
            this.labelText = "查询无数据";
          }
        })
        .catch(() => {});
    },
    //加载商户销售员
    loadProductSalesman() {
      findUserList()
        .then(res => {
          this.merchantSeller = res;
        })
        .catch(() => {});
    },
    // 加载table数据
    loadData() {
      // const obj = { ...this.searchForm };
      // obj.salespersonIds = obj.salespersonIds.join(",");
      //this.searchForm.keyword有keyword时其他条件不传
      orderWindoworder(
        this.searchForm.keyword
          ? { keyword: this.searchForm.keyword }
          : this.searchForm
      )
        .then(({ records, total, actualAmountSum, totalRefundAmountSum }) => {
          this.total = total;
          this.tableData = [];
          this.tableData = JSON.parse(JSON.stringify(records));
          this.salesVolume = actualAmountSum || 0; // 销售额
          this.refund = totalRefundAmountSum || 0; // 退款
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.searchForm.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 查询
    reload() {
      this.visibleSearch = false;
      this.searchForm.page = 1;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.searchForm.orderBeginTime = moment()
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment()
        .endOf("date")
        .valueOf();
      this.searchForm.keyword = "";
      this.searchForm.buyChannel = "";
      this.searchForm.payWay = "";
      this.searchForm.payChannels = "";
      this.searchForm.merchantId = "";
      this.searchForm.status = "";
      this.searchForm.salespersonId = "";
      this.searchForm.visitors = "";
      this.searchForm.discountId = "";
      this.searchForm.productForms = "";
      this.formatQuickSearchIndex = "";

      this.reload();
    },
    // 条数改变
    sizeChange(current, size) {
      this.searchForm.page = 1;
      this.searchForm.size = size;
      this.reload();
    },
    // 销售渠道选择
    fromChange(value, selectOptions) {
      this.searchForm.buyChannel = selectOptions[selectOptions.length - 1].id;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 各业态快速搜索
    formatQuickSearchClick(item, i) {
      if (this.formatQuickSearchIndex !== i) {
        this.searchForm.productForms = item.value;
        this.formatQuickSearchIndex = i;
      } else {
        this.formatQuickSearchIndex = "";
        this.searchForm.productForms = "";
      }
      this.$nextTick(() => {
        this.reload();
      });
    },
    btnType(index) {
      return this.formatQuickSearchIndex === index ? "primary" : "";
    }
  }
};
</script>

<style scoped>
.input {
  width: 220px;
}

/*头部*/
.header-wrap {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
}
</style>
