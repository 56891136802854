<!--
  查看所有优惠弹窗
-->
<template>
  <a-modal
    :visible="vis"
    :footer="null"
    title=""
    width="1100px"
    @cancel="close"
    :body-style="bodyStyle"
  >
    <a-tabs default-active-key="1" @change="tabChange">
      <a-tab-pane key="1" tab="订单折扣">
        <div class="flex-start custome-tab-wrap">
          <div
            v-for="(item, i) in customeTabList"
            :key="i"
            :class="{ act: orderDiscountTab == i }"
            class="custome-tab pointer mr-3x"
            @click="switchZK(i)"
          >
            {{ item }}
          </div>
        </div>
        <!-- 整单优惠 -->
        <order-zdzk
          ref="mytab0"
          v-show="orderDiscountTab == 0"
          :orderData="orderData"
          @triggerCalc="triggerCalc"
          @triggerDel="triggerDel"
        ></order-zdzk>
        <!-- 会员折扣 -->
        <order-hyzk
          ref="mytab1"
          v-show="orderDiscountTab == 1"
          :orderData="orderData"
          @triggerCalc="triggerCalc"
          @triggerDel="triggerDel"
        ></order-hyzk>
        <!-- 优惠券 -->
        <order-yhq
          ref="mytab2"
          v-show="orderDiscountTab == 2"
          :orderData="orderData"
          @triggerCalc="triggerCalc"
          @triggerDel="triggerDel"
        ></order-yhq>
        <!-- 营销活动 -->
        <order-yxhd
          ref="mytab3"
          v-show="orderDiscountTab == 3"
          :orderData="orderData"
          @triggerCalc="triggerCalc"
          @triggerDel="triggerDel"
          @tell="tell"
        ></order-yxhd>
      </a-tab-pane>

      <a-tab-pane key="2" tab="积分抵扣" v-if="pointOpen">
        <order-jfdk
          ref="jfdk"
          v-if="memberId"
          @triggerIntegral="triggerIntegral"
          @triggerIntegralDel="triggerIntegralDel"
        ></order-jfdk>
        <div v-else>
          <find-member></find-member>
        </div>
      </a-tab-pane>
    </a-tabs>
    <div class="flex-center mt-2x">
      <!-- <a-button
        type="primary"
        ghost
        size="large"
        shape="round"
        class="btn-lg mr-2x"
        @click="close"
      >
        取消
      </a-button> -->
      <a-button
        @click="sure"
        type="primary"
        size="large"
        shape="round"
        class="btn-lg"
      >
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import MemberBalance from "./MemberBalance.vue";
import FindMember from "../../../../components/member/FindMember.vue"; //导入搜索会员的键盘
import OrderYxhd from "./OrderYxhd.vue"; //营销活动
import OrderYhq from "../components/OrderYhq"; //优惠券
import OrderJfdk from "./OrderJfdk.vue"; //积分抵扣
import OrderZdzk from "./OrderZdzk.vue"; //整单优惠
import OrderHyzk from "./OrderHyzk.vue"; //会员折扣
import { integralRule } from "../../../../api/member";
import { moneyFormat } from "../../../../utils/global";

export default {
  name: "orderInfoCoupon",
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    OrderYxhd, //营销活动
    OrderYhq, //优惠券
    // MemberBalance, //会员余额
    OrderHyzk, //会员折扣
    FindMember,
    OrderJfdk,
    OrderZdzk //整单折扣
  },
  data() {
    return {
      pointOpen: false,
      bodyStyle: {},
      orderDiscountTab: 0,
      refName: "mytab0",
      customeTabList: ["整单优惠", "会员折扣", "优惠券", "营销活动"],
      vuexOrderDiscount: {}, // 订单折扣：选择的优惠数据
      vuexOrderIntegral: {}, // 积分折扣：选择的优惠数据
      tellObj: {}, // 处理营销活动:是否窗口折扣类型，输入了手机号或者身份证
      tabKey: "1" // 订单折扣、积分抵扣tab key
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" }),
    ...mapGetters("order", ["discountTextList", "shouldPayMoney", "totalPrice"])
  },
  created() {
    this.getPointRule();
    this.initBodyStyle();
  },
  methods: {
    ...mapActions("order", [
      "removeOrderDiscountTextList",
      "setOrderDiscountTextList",
      "setIntegralDeduction"
    ]),
    // 配置弹窗的最大高度
    initBodyStyle() {
      // T2设备，设置弹窗最大高度
      if (document.documentElement.clientHeight < 1000) {
        this.bodyStyle = {
          "max-height": "600px",
          overflow: "auto"
        };
      } else {
        this.bodyStyle = {};
      }
    },
    //启用积分功能开关 并且 积分抵扣现金开关 时
    getPointRule() {
      integralRule().then(res => {
        if (res.integralSwitch && res.offsetCashSwitch) {
          this.pointOpen = true;
        }
      });
    },
    // 选择优惠组件，传来的数据指令
    triggerCalc(obj) {
      this.vuexOrderDiscount = obj;
    },
    // 积分抵扣
    triggerIntegral(obj) {
      this.vuexOrderIntegral = obj;
    },
    triggerIntegralDel() {
      this.vuexOrderIntegral = {};
    },
    // 选择优惠组件，传来的清空数据指令
    triggerDel() {
      this.vuexOrderDiscount = {};
      this.tellObj = {};
    },
    // 处理营销活动:是否窗口折扣类型，输入了手机号或者身份证
    tell(obj) {
      this.tellObj = obj;
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 确定弹窗
    sure() {
      // 处理营销活动，窗口折扣类型。是否能关闭弹窗
      if (this.tellObj.type && !this.tellObj.showWindow && this.tabKey == 1) {
        const msg = `请先输入${
          this.tellObj.type == 1 ? "身份证" : "手机号"
        }确认优惠信息`;
        this.$message.warning(msg);
        return;
      }
      // 是否有订单折扣数据
      const selectDisc = Object.keys(this.vuexOrderDiscount).length;
      // 是否有积分抵扣数据
      const selectInte = Object.keys(this.vuexOrderIntegral).length;
      let money = 0;
      money = selectDisc && this.vuexOrderDiscount.amount + money;
      money = selectInte && this.vuexOrderIntegral.amount + money;
      if (money > this.totalPrice) {
        this.$notification.open({
          message: "温馨提示",
          top: "80px",
          description: h => {
            return h("div", [
              h("p", {}, [
                `选择的优惠总金额：`,
                h(
                  "span",
                  {
                    class: "red font-l"
                  },
                  `${moneyFormat(money)}`
                )
              ]),
              h(
                "p",
                {
                  class: "mv-x"
                },
                [
                  `当前应付金额：`,
                  h(
                    "span",
                    {
                      class: "red font-l"
                    },
                    `${moneyFormat(this.totalPrice)}`
                  )
                ]
              ),
              h("p", "优惠总金额不能大于应付金额")
            ]);
          },
          duration: 5
        });
        return;
      }
      // 有选择订单折扣的优惠，才赋值到vuex
      selectDisc && this.setOrderDiscountTextList(this.vuexOrderDiscount);
      // 有使用了积分抵扣，才赋值到vuex
      selectInte && this.setIntegralDeduction(this.vuexOrderIntegral);
      this.$emit("update:vis", false);
      this.triggerDel();
    },
    // 订单折扣里的 tab切换
    switchZK(i) {
      this.orderDiscountTab = i;
      // 切换，清空订单折扣的优惠
      this.refName = "mytab" + i;
      this.vuexOrderDiscount = {};
      this.$refs[this.refName] && this.$refs[this.refName].reset();
    },
    // 订单折扣、积分抵扣切换
    tabChange(key) {
      this.tabKey = key;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
/deep/.ant-tabs-nav-container {
  font-size: 18px;
}
/deep/.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 60px;
}
/deep/.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
}
/deep/.ant-tabs-ink-bar {
  height: 6px;
}

.custome-tab-wrap {
  margin-bottom: 30px;
}
.custome-tab {
  width: 100px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  &.act {
    background: @primary;
    border-radius: 19px;
    color: #fff;
  }
}

.btn-lg {
  width: 280px;
  height: 50px;
  background: linear-gradient(0deg, #45b5fb 0%, #4c8ffb 100%);
  border-radius: 25px;
}
</style>
