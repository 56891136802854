<!--门票充值记录-->
<template>
  <div class="ph-2x pb-2x">
    <!--搜索-->
    <header class="mb-2x">
      <span>产品名称</span>
      <a-input
        class="mh-2x"
        style="width: 200px"
        v-model="searchKey"
        placeholder="请输入产品名称"
        allow-clear
        @pressEnter="search"
      ></a-input>
      <a-button type="primary" @click="search">查询</a-button>
    </header>

    <!-- 表格start -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      size="middle"
      row-key="id"
    >
      <span slot="totalAmount" slot-scope="row" class="red">{{
        row.totalAmount | money
      }}</span>
      <span slot="statusText" class="primary" slot-scope="row">{{
        row.statusText
      }}</span>
      <span slot="createTime" slot-scope="row">{{
        row.createTime | date("yyyy-mm-dd hh:mm:ss")
      }}</span>

      <template slot="action" slot-scope="row">
        <a-button
          class="mr-2x p-0"
          type="link"
          @click="showRefund(row)"
          v-if="!isRetail"
          :disabled="row.status < 3 || row.status > 5"
          >退款</a-button
        >
        <a-button class="p-0" type="link" @click="showDetail(row)"
          >详情</a-button
        >
      </template>
    </a-table>
    <!-- 表格end -->
    <!--分页-->
    <footer class="flex-end mt-2x" v-show="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        show-quick-jumper
        :default-current="1"
        :page-size-options="['5', '10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
      />
    </footer>

    <!--详情弹窗-->
    <ticket-product-detail
      :visible.sync="visible"
      :id="detailId"
      :productId="productId"
    ></ticket-product-detail>

    <!--退押金、退款、退票-->
    <order-refund
      :visible.sync="refundVisible"
      :order-id="mainOrderId"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import TicketProductDetail from "./TicketProductDetail";
import { findTicketRechargeProductPage } from "../../../../api/order";
import OrderRefund from "../../order/components/OrderRefund";

// 门票充值记录table start
const columns = [
  {
    width: 150,
    title: "充值产品名称",
    dataIndex: "productName",
    key: "productName"
  },
  {
    width: 120,
    title: "产品类型",
    dataIndex: "productCategoryText",
    key: "productCategoryText"
  },
  {
    width: 100,
    title: "产品单价",
    scopedSlots: { customRender: "totalAmount" },
    key: "totalAmount"
  },
  {
    width: 100,
    title: "状态",
    scopedSlots: { customRender: "statusText" },
    key: "statusText"
  },
  {
    width: 130,
    title: "充值时间",
    scopedSlots: { customRender: "createTime" },
    key: "createTime"
  },
  {
    width: 120,
    title: "充值订单编号",
    dataIndex: "mainOrderId",
    key: "mainOrderId"
  },
  {
    width: 120,
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    fixed: "right"
  }
];

// 门票充值记录table end

export default {
  name: "TicketCharge",
  props: {
    isRetail: {
      type: String,
      default: () => ""
    },
    // 主单号
    orderId: {
      type: String,
      default: ""
    }
  },
  components: { TicketProductDetail, OrderRefund },
  data() {
    return {
      searchKey: "",
      tableData: [],
      total: 0,
      page: 1,
      size: 5,
      visible: false,
      detailId: "",
      productId: "", //该项目的产品id
      columns,
      // 退款信息
      refundVisible: false, // 退款退单弹窗
      refundIdType: 2, // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: "", // 退款类型 1退单， 2退款， 3退押金
      productName: "", // 退款产品名称
      productCategoryId: "", // 产品业务类型id
      mainOrderId: "" // 退款订单id
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 加载数据
    loadData() {
      findTicketRechargeProductPage({
        page: this.page,
        size: this.size,
        id: this.$route.query.id,
        productName: this.searchKey
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 展示详情
    showDetail(row) {
      this.detailId = row.id;
      this.productId = row.productId;
      this.visible = true;
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    // 页面改变
    currentChange(p) {
      this.page = p;
      this.loadData();
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadData();
    },
    // 退款
    showRefund(row) {
      this.mainOrderId = row.mainOrderId;
      this.refundType = 2;
      this.productName = row.productName;
      this.productCategoryId = row.productCategoryId;
      this.refundIdType = 2;
      this.refundIdList = [row.id];
      this.refundVisible = true;
    },
    // 退款成功
    refunded() {
      this.loadData();
    }
  }
};
</script>
