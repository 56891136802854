<!-- 会员模块 消费记录 -->
<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="消费记录"></panel-head>

    <section class="main mt-2x">
      <!-- 查询 -->
      <a-form-model
        class="p-2x"
        layout="inline"
        ref="searchForm"
        :model="searchForm"
      >
        <a-form-model-item label="订单日期">
          <a-range-picker
            class="input"
            v-model="orderDate"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="订单号" prop="orderId">
          <a-input
            class="input"
            v-model="searchForm.orderId"
            placeholder="请输入订单号"
            allow-clear
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="业务类型" prop="orderBusinessType">
          <a-select
            class="input"
            v-model="searchForm.orderBusinessType"
            placeholder="请选择业务类型"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option
              v-for="item in businessTypeList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onSearch">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="onReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 表格模块 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="dataChange"
        row-key="id"
        :scroll="{ x: 1300, y: 500 }"
      >
        <template slot="createTime" slot-scope="row">
          {{ row.createTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot="orderStatusText" slot-scope="row">
          <span class="primary">{{ row.orderStatusText }}</span>
        </template>
        <template slot="totalAmount" slot-scope="row">
          {{ row.totalAmount | money }}
        </template>
        <template slot="discountAmount" slot-scope="row">
          {{ row.discountAmount | money }}
        </template>
        <template slot="memberBalanceAmount" slot-scope="row">
          {{ row.memberBalanceAmount | money }}
        </template>
        <template slot="integralDeductionAmount" slot-scope="row">
          {{ row.integralDeductionAmount | money }}
        </template>
        <template slot="actualAmount" slot-scope="row">
          {{ row.actualAmount | money }}
        </template>
        <template slot="action" slot-scope="row">
          <!-- 禁用退单按钮：
          1、订单状态为“已支付”
          2、业务类型为“会员特权”、“补办”、“自定义收款” -->
          <a-button
            type="link p-0"
            @click="refund(row)"
            :disabled="
              row.orderStatus !== 3 ||
                row.orderBusinessType === 5 ||
                row.orderBusinessType === 7
            "
          >
            退单
          </a-button>
          <a-button class="ml-2x" type="link p-0" @click="toDetail(row.id)">
            详情
          </a-button>
        </template>
      </a-table>
    </section>
    <!-- 返回按钮 -->
    <footer class="footer">
      <a-button
        type="primary"
        shape="round"
        class="btn-lg"
        size="large"
        ghost
        @click="$router.go(-1)"
        >返回</a-button
      >
    </footer>
    <!-- 退单、退款、退票 -->
    <order-refund
      :order-id="orderId"
      :visible.sync="visible"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="1"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import panelHead from "@/components/common/PanelHead";
import OrderRefund from "./components/OrderRefund";
import { mapGetters } from "vuex";
import moment from "moment";
import { orderMemberconsumerecord } from "@/api/search"; // MS-搜索中心接口
import { dataDictionary } from "@/api/setting";

const columns = [
  { title: "订单编号", dataIndex: "id", width: 190 },
  {
    title: "下单时间",
    scopedSlots: { customRender: "createTime" },
    width: 150
  },
  { title: "销售渠道", dataIndex: "buyChannelsText", width: 120 },
  {
    title: "订单状态",
    scopedSlots: { customRender: "orderStatusText" },
    width: 80
  },
  {
    title: "应收金额",
    scopedSlots: { customRender: "totalAmount" },
    width: 120
  },
  {
    title: "优惠金额",
    scopedSlots: { customRender: "discountAmount" },
    width: 120
  },
  {
    title: "会员余额",
    scopedSlots: { customRender: "memberBalanceAmount" },
    width: 120
  },
  {
    title: "积分抵扣",
    scopedSlots: { customRender: "integralDeductionAmount" },
    width: 120
  },
  {
    title: "实收价格",
    scopedSlots: { customRender: "actualAmount" },
    width: 120
  },
  { title: "支付方式", dataIndex: "payWayText", width: 120 },
  { title: "外部单号", dataIndex: "outsideCode", width: 190 },
  { title: "消费会员编号", dataIndex: "purchaserMemberId", width: 190 },
  { title: "操作人", dataIndex: "salespersonName", width: 120 },
  { title: "订单备注", dataIndex: "remarks", width: 150 },
  {
    title: "操作",
    fixed: "right",
    scopedSlots: { customRender: "action" },
    width: 120
  }
];

export default {
  name: "ConsumeRecord",
  components: {
    panelHead,
    OrderRefund
  },
  data() {
    return {
      columns, // 主表格表头
      data: [], // 主表格数据
      // 接口参数
      interFaceParams: {
        page: 1,
        size: 10
      },
      businessTypeList: [], // 业务类型列表
      orderDate: [], // 订单日期
      searchForm: {
        orderBeginDate: "", // 开始日期
        orderEndDate: "", // 结束日期
        orderId: "", // 订单号
        orderBusinessType: "" // 业务类型
      },
      // 分页参数
      pagination: {
        defaultCurrent: 1, // 默认的当前页数
        total: 0, // 总条数(用于分页)
        showTotal: total => {
          return `共 ${total} 条`;
        }, // 显示总条数
        pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示多少条
        showSizeChanger: true // 是否可以改变 pageSize
      },
      // 退单退款
      visible: false,
      // 退款信息
      refundList: [], // 退款记录
      orderId: "", // 退款订单编号
      refundIdType: "", // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      productName: "", // 退款产品名称
      productCategoryId: "" // 产品业务类型id
    };
  },
  computed: {
    ...mapGetters("common", { memberId: "memberId" })
  },
  created() {
    this.getKeyData(); // 获取字典数据
    this.getConsumerRecordList(); // 查询会员消费记录
  },
  methods: {
    // 获取字典数据
    getKeyData() {
      dataDictionary({
        keys: "memberBusinessType"
      })
        .then(res => {
          this.businessTypeList = res.memberBusinessType; // 获取业务类型列表
        })
        .catch(() => {});
    },

    // 日期选择
    dateChange([start, end]) {
      if (start && end) {
        this.searchForm.orderBeginDate = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.orderEndDate = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.orderBeginDate = "";
        this.searchForm.orderEndDate = "";
      }
    },

    // 查询会员消费记录
    getConsumerRecordList() {
      this.interFaceParams.memberId = this.memberId;
      orderMemberconsumerecord(this.interFaceParams)
        .then(res => {
          this.data = res.records;
          this.pagination.total = res.total;
        })
        .catch(() => {});
    },

    // 分页变化时触发
    dataChange(pagination) {
      this.interFaceParams.page = pagination.current;
      this.interFaceParams.size = pagination.pageSize;
      this.getConsumerRecordList();
    },

    // 搜索
    onSearch() {
      this.interFaceParams.orderBeginDate = this.searchForm.orderBeginDate;
      this.interFaceParams.orderEndDate = this.searchForm.orderEndDate;
      this.interFaceParams.orderId = this.searchForm.orderId;
      this.interFaceParams.orderBusinessType = this.searchForm.orderBusinessType;
      this.getConsumerRecordList();
    },

    // 重置
    onReset() {
      this.$refs.searchForm.resetFields();
    },

    // 退单、退款、退票
    refund(row) {
      this.productName = row.productName;
      this.productCategoryId = row.productCategoryId;
      this.orderId = row.id;
      this.visible = true;
    },
    // 退款提交成功
    refunded() {
      this.$emit("refunded", true);
    },

    // 去详情页
    toDetail(orderId) {
      this.$router.push({
        path: "/home/order/OrderDetail",
        query: { orderId }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 200px;
}
</style>
