<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="订单管理"></panel-head>
    <!--列表-->
    <section class="main mt-2x">
      <header class="p-2x">
        <!--查询-->
        <a-form-model layout="inline" :model="searchForm" ref="searchForm">
          <a-form-model-item label="订单日期">
            <a-range-picker
              :allow-clear="false"
              class="input"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="订单号" prop="orderId">
            <a-input
              v-model="searchForm.orderId"
              placeholder="请输入订单号"
              class="input"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="reload">查询</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="link" @click="visibleSearch = true"
              >高级查询</a-button
            >
          </a-form-model-item>
        </a-form-model>
        <!--高级查询-->
        <a-drawer
          title="高级查询"
          placement="right"
          :width="400"
          :closable="false"
          :visible="visibleSearch"
          @close="visibleSearch = false"
        >
          <a-form-model
            ref="heightForm"
            :model="searchForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <a-form-model-item label="下单日期">
              <a-range-picker
                :allow-clear="false"
                v-model="dateRange"
                @change="dateChange"
              />
            </a-form-model-item>
            <a-form-model-item label="订单编号" prop="orderId">
              <a-input
                v-model="searchForm.orderId"
                placeholder="请输入订单编号"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="消费会员编号" prop="memberId">
              <a-input
                v-model="searchForm.memberId"
                placeholder="请输入消费会员编号"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="会员手机号" prop="phone">
              <a-input
                v-model="searchForm.phone"
                placeholder="请输入会员手机号"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="订单来源" prop="buyChannel">
              <a-cascader
                :display-render="displayRender"
                :fieldNames="{
                  value: 'id',
                  label: 'channelName',
                  children: 'orderSaleChannelVOS'
                }"
                change-on-select
                :options="buyChannel"
                placeholder="请选择订单来源"
                @change="fromChange"
              />
            </a-form-model-item>
            <a-form-model-item label="订单状态" prop="status">
              <a-select
                v-model="searchForm.status"
                placeholder="请选择订单状态"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in status"
                  :key="index"
                  :value="item.value"
                  >{{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="支付方式" prop="payWay">
              <a-select
                v-model="searchForm.payWay"
                placeholder="请选择支付方式"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="(item, index) in payWay"
                  :key="index"
                  :value="item.value"
                  >{{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="外部订单号" prop="outsideCode">
              <a-input
                v-model="searchForm.outsideCode"
                placeholder="请输入外部订单号"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="订单备注" prop="remark">
              <a-input
                v-model="searchForm.remark"
                placeholder="请输入订单备注"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="营销员编号" prop="marketingStaffId">
              <a-input
                v-model="searchForm.marketingStaffId"
                placeholder="请输入营销员编号"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
              <a-button type="primary" class="mr-2x" @click="reload"
                >查询</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-drawer>
      </header>

      <order-list-table
        :table-data="tableData"
        @refunded="loadData"
      ></order-list-table>
      <div class="text-r p-2x">
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
    </section>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        type="primary"
        shape="round"
        size="large"
        class="btn-lg"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
    </footer>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import OrderListTable from "@/views/home/order/components/OrderListTable";
import moment from "moment";
import { dataDictionary } from "../../../api/setting";
import { deptUserList } from "../../../api/user";
import { orderUserOrder } from "../../../api/search";
import { orderSaleChannel } from "../../../api/merchant";
export default {
  name: "OrderManage",
  components: { PanelHead, OrderListTable },
  data() {
    return {
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      dateRange: [moment(), moment()],
      searchForm: {
        // productType: 1365211690130337793,
        page: 1,
        size: 10,
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        orderId: "", // 订单编号 O2222222221232
        outsideCode: "", // 第三方订单号
        creatorId: "", // 操作人用户ID
        phone: "",
        buyChannel: "", // 销售渠道
        payWay: "", // 支付方式
        status: "",
        memberId: "", // 会员号码
        remark: "", // 订单备注
        marketingStaffId: "" // 营销员编号
      },
      visibleSearch: false,
      // 字典s
      buyChannel: [], // 销售渠道
      payWay: [], // 支付方式
      status: [], // 订单状态
      creator: [], // 操作人列表
      // 订单表格
      total: 0, // 总条数
      tableData: []
    };
  },
  created() {
    this.loadData();
    this.loadKeys();
    this.loadSaleChannel();
    this.loadCreator();
  },
  methods: {
    // 字典数据
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,payWay,orderStatus"
      })
        .then(data => {
          this.payWay = data.payWay;
          this.status = data.orderStatus;
        })
        .catch(() => {});
    },
    // 加载销售渠道
    loadSaleChannel() {
      orderSaleChannel()
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },
    // 加载操作人列表
    loadCreator() {
      deptUserList()
        .then(res => {
          this.creator = res;
        })
        .catch(() => {});
    },
    // 加载table数据
    loadData() {
      orderUserOrder({
        ...this.searchForm
      })
        .then(({ records, total }) => {
          this.total = total;
          this.tableData = records;
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.searchForm.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 查询
    reload() {
      this.visibleSearch = false;
      this.searchForm.page = 1;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.searchForm.orderBeginTime = moment()
        .startOf("month")
        .valueOf();
      this.searchForm.orderEndTime = moment()
        .endOf("month")
        .valueOf();
      this.searchForm.orderId = "";
      this.searchForm.outsideCode = "";
      this.searchForm.creatorId = "";
      this.searchForm.phone = "";
      this.searchForm.buyChannel = "";
      this.searchForm.payWay = "";
      this.searchForm.status = "";
      this.searchForm.memberId = "";
      this.searchForm.remark = "";
      this.searchForm.marketingStaffId = "";
      this.reload();
    },
    // 条数改变
    sizeChange(current, size) {
      this.searchForm.page = 1;
      this.searchForm.size = size;
      this.reload();
    },
    // 订单来源选择
    fromChange(value, selectOptions) {
      this.searchForm.buyChannel = selectOptions[selectOptions.length - 1].id;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    }
  }
};
</script>

<style scoped>
.input {
  width: 200px;
}
</style>
