<template>
  <div class="ph-2x pb-2x">
    <!--搜索-->
    <header class="mb-2x">
      <span>操作日期</span>
      <a-date-picker class="mh-2x" type="date" @change="dateChange">
      </a-date-picker>
      <a-button @click="dateChange" type="primary">查询</a-button>
    </header>
    <!-- 表格start -->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      size="middle"
      row-key="id"
    >
      <span slot="createTime" slot-scope="row">{{
        row.createTime | date("yyyy-mm-dd hh:mm:ss")
      }}</span>
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x" v-show="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
      />
    </footer>
  </div>
</template>

<script>
import moment from "moment";
import { ticketOperateRecordShowPages } from "../../../../api/order";

export default {
  name: "TicketHandle",
  props: {
    auxiliaryId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      date: "",
      tableData: [],
      total: 0,
      page: 1,
      size: 10,
      columns: [
        {
          width: 150,
          title: "操作时间",
          scopedSlots: { customRender: "createTime" },
          key: "createTime"
        },
        {
          width: 120,
          title: "操作人",
          dataIndex: "operatorName",
          key: "operatorName"
        },
        {
          width: 120,
          title: "操作渠道",
          dataIndex: "operateChannelText",
          key: "operateChannelText"
        },
        {
          width: 120,
          title: "操作类型",
          dataIndex: "operateTypeText",
          key: "operateTypeText"
        },
        {
          width: 250,
          title: "操作内容",
          dataIndex: "remarks",
          key: "remarks"
        }
      ]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      ticketOperateRecordShowPages({
        page: this.page,
        size: this.size,
        auxiliaryId: this.auxiliaryId,
        operateTime: this.date
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    // 操作日期
    dateChange(val) {
      if (val)
        this.date = moment(val)
          .endOf("date")
          .valueOf();
      else this.date = "";
      this.page = 1;
      this.loadData();
    }
  }
};
</script>
