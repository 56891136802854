<template>
  <div v-if="visible">
    <a-modal
      :visible="visible2"
      :title="title"
      :width="1100"
      @cancel="handleCancel"
      :mask-closable="false"
    >
      <a-spin :spinning="loading" tip="退款中...">
        <template v-if="step === 1">
          <!--插槽-->
          <div class="text">
            <a-row class="mb-2x">
              <a-col :span="3">订单编号：</a-col>
              <a-col :span="21">{{ refundDetail.orderId }}</a-col>
            </a-row>
            <a-row class="mb-2x">
              <a-col :span="3">订单状态：</a-col>
              <a-col :span="21" class="primary">{{
                refundDetail.orderStatusText
              }}</a-col>
            </a-row>
            <a-row class="mb-2x">
              <a-col :span="3">订单可退金额：</a-col>
              <a-col :span="21"
                >{{
                  refundDetail.orderLeftAmountInfo.totalAmount | money
                }}
                (其中：{{ refundDetail.payWayText }}:
                {{ refundDetail.orderLeftAmountInfo.payAmount | money }},
                会员余额：{{
                  refundDetail.orderLeftAmountInfo.memberBalanceAmount | money
                }}, 积分抵扣：{{
                  refundDetail.orderLeftAmountInfo.integralDeductionAmount
                    | money
                }})
              </a-col>
            </a-row>
            <a-row class="mb-2x" v-if="productName">
              <a-col :span="3">本次退款产品：</a-col>
              <a-col :span="21"
                >{{ productName }}
                <template v-if="refundType === 3">押金</template>
              </a-col>
            </a-row>
            <a-row class="mb-2x">
              <a-col :span="3">本次应退金额：</a-col>
              <a-col :span="21">{{
                refundDetail.totalOriginalRefundableAmount | money
              }}</a-col>
            </a-row>
            <a-row class="mb-2x">
              <a-col :span="3">本次退款手续费：</a-col>
              <a-col :span="21"> {{ form1.commission | money }}</a-col>
            </a-row>
            <a-row class="mb-2x">
              <a-col :span="3">本次退款金额：</a-col>
              <a-col :span="21" class="red"
                >{{ form1.refundMoney | money }}
                <!-- 长白山不允许调 影响清分1455730894637690881 -->
                <span
                  v-if="merchantId != '1455730894637690881'"
                  class="ml-2x primary pointer"
                  @click="step = 0"
                  >调整本次退款金额</span
                ></a-col
              >
            </a-row>
          </div>
          <!--表单1-->
          <a-form-model
            ref="form1"
            :model="form1"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            labelAlign="left"
          >
            <a-form-model-item label="退款备注" prop="remark">
              <a-input
                placeholder="请输入退款备注"
                v-model="form1.remark"
                allow-clear
              />
            </a-form-model-item>
          </a-form-model>
        </template>
        <template v-else-if="step === 2">
          <b class="text"
            >本次退款金额：<span class="red">{{
              this.form1.refundMoney | money
            }}</span></b
          >
          <a-form-model
            class="mt-2x"
            ref="form2"
            :model="form2"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <a-form-model-item
              :label="refundDetail.payWayText"
              v-if="refundDetail.orderLeftAmountInfo.payAmount > 0"
            >
              <section class="flex-start">
                <div class="mr-2x">
                  <a-checkbox
                    @change="e => checked(e, 'main')"
                    :checked="this.form2.mainChecked"
                    >{{ refundDetail.payWayText }}最多可退{{
                      refundDetail.orderLeftAmountInfo.payAmount | money
                    }}， </a-checkbox
                  >本次退款
                </div>
                <div>
                  <a-form-model-item prop="main" class="m-0">
                    <a-input
                      placeholder="请输入本次退款金额"
                      v-model="form2.main"
                      prefix="¥"
                      allow-clear
                      :disabled="!form2.mainChecked"
                    />
                  </a-form-model-item>
                </div>
              </section>
            </a-form-model-item>
            <a-form-model-item
              label="会员余额退款"
              v-if="refundDetail.orderLeftAmountInfo.memberBalanceAmount > 0"
            >
              <section class="flex-start">
                <div class="mr-2x">
                  <a-checkbox
                    @change="e => checked(e, 'member')"
                    :checked="this.form2.memberChecked"
                    >会员余额退款最多可退{{
                      refundDetail.orderLeftAmountInfo.memberBalanceAmount
                        | money
                    }}， </a-checkbox
                  >本次退款
                </div>
                <div>
                  <a-form-model-item prop="member" class="m-0">
                    <a-input
                      placeholder="请输入本次退款金额"
                      v-model="form2.member"
                      :disabled="!form2.memberChecked"
                      prefix="¥"
                      allow-clear
                    />
                  </a-form-model-item>
                </div>
              </section>
            </a-form-model-item>
            <a-form-model-item
              v-if="
                refundDetail.orderLeftAmountInfo.integralDeductionAmount > 0
              "
              label="积分抵扣退款"
            >
              <section class="flex-start">
                <div class="mr-2x">
                  <a-checkbox
                    @change="e => checked(e, 'integral')"
                    :checked="this.form2.integralChecked"
                    >积分抵扣退款最多可退{{
                      refundDetail.orderLeftAmountInfo.integralDeductionAmount
                        | money
                    }}， </a-checkbox
                  >本次退款
                </div>
                <div>
                  <a-form-model-item prop="integral" class="m-0">
                    <a-input
                      placeholder="请输入本次退款金额"
                      v-model="form2.integral"
                      :disabled="!form2.integralChecked"
                      prefix="¥"
                      allow-clear
                    />
                  </a-form-model-item>
                </div>
              </section>
            </a-form-model-item>
          </a-form-model>
        </template>
        <!--调整退款金额-->
        <template v-else-if="step === 0">
          <div class="flex-start align-center mb-2x" v-if="searchTitle">
            <p class="flex-shrink mr-2x">{{ searchTitle }}</p>
            <a-input-search
              v-model="searchKey"
              :placeholder="`请输入${searchTitle}`"
              style="width: 200px"
              allow-clear
              @change="search"
            >
            </a-input-search>
          </div>
          <a-table
            :columns="columns"
            :data-source="tableData"
            rowKey="id"
            :pagination="false"
            :scroll="{ y: 600 }"
          >
            <span
              :class="{
                primary: row.statusText !== '合计',
                disabled: row.status < 3 || row.status > 5
              }"
              slot-scope="row"
              slot="statusText"
              >{{ row.statusText }}</span
            >
            <span slot-scope="row" slot="totalReceivedAmount">{{
              row.totalReceivedAmount | money
            }}</span>
            <span slot-scope="row" slot="totalCommissionAmount">{{
              row.totalCommissionAmount | money
            }}</span>
            <!--可编辑单元格-->
            <template v-for="col in ['editMoney']" :slot="col" slot-scope="row">
              <div :key="col">
                <a-input
                  v-if="row.statusText !== '合计'"
                  prefix="¥"
                  style="margin: -5px 0"
                  v-model="row.editMoney"
                  @blur="e => handleChange(e.target.value, row)"
                  :disabled="row.status < 3 || row.status > 5"
                />
                <span v-else>{{ row.totalCommissionAmount | money }}</span>
              </div>
            </template>
            <span slot-scope="row" slot="refundMoney">
              <template v-if="row.refundMoney >= 0">{{
                row.refundMoney | money
              }}</template>
              <template v-else>¥ 0</template>
            </span>
          </a-table>
        </template>
      </a-spin>
      <!--插槽按钮-->
      <template slot="footer">
        <footer v-if="step === 1">
          <a-button @click="handleCancel" :disabled="loading">取消</a-button>
          <a-button type="primary" @click="next" :loading="loading">{{
            title
          }}</a-button>
        </footer>
        <footer v-else-if="step === 2">
          <a-button @click="step = 1">上一步</a-button>
          <a-button type="primary" @click="sure" :loading="loading">{{
            title
          }}</a-button>
        </footer>
        <footer v-else-if="step === 0">
          <a-button @click="step = 1">返回</a-button>
          <a-button type="primary" @click="changeOk">调整完毕</a-button>
        </footer>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { refund, refundCheck } from "@/api/order";
import { fix2Reg, checkText } from "@/utils/global";
export default {
  name: "OrderRefund",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    //订单编号 退押金orderId要用订单详情的ticketDepositOrderId
    orderId: {
      type: [Number, String],
      default: ""
    },
    // 详情id类型 1子订单id 2产品详情id
    refundIdType: {
      type: [Number, String],
      default: ""
    },
    // 子订单id列表或订单详情id列表
    refundIdList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 退款类型 1退单， 2退款， 3退押金
    refundType: {
      type: [Number, String],
      default: 1
    },
    // 退款产品名称
    productName: {
      type: String,
      default: ""
    },
    // 产品业务类型id-用于退款金额跳转是否展示搜索
    productCategoryId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible2: false,
      labelCol: { span: 3 },
      wrapperCol: { span: 21 },
      loading: false,
      step: 1,
      // 退款金额信息
      refundDetail: {
        orderId: "",
        orderStatus: "",
        orderStatusText: "",
        payWay: "",
        payWayText: "",
        totalOriginalRefundableAmount: 0, // 本机应退金额
        totalCommissionAmount: 0, // 本次退款手续费
        totalRefundableAmount: 0, // 本次退款金额
        orderReceivedAmountInfo: {}, // 订单收款金额信息
        orderRefundedAmountInfo: {}, // 订单已退款金额信息
        orderLeftAmountInfo: {}, // 订单剩余可退金额信息
        refundableProductList: [] // 本次退款包含的退款产品信息列表
      },
      // 第一步提交的数据
      form1: {
        refundMoney: 0, // 本次退款金额
        commission: 0, // 退款手续费
        remark: "" // 退款说明
      },
      // 第二步提交的数据
      form2: {
        main: "", // 主要退款
        mainChecked: true,
        member: "", // 会员余额退款
        memberChecked: false,
        integral: "", // 积分退款
        integralChecked: false
      },
      rules: {
        remark: [
          {
            validator: checkText,
            trigger: "blur"
          }
        ],
        main: [
          {
            validator: (rule, value, callback) => {
              if (this.form2.mainChecked) {
                value === ""
                  ? callback(new Error("请输入退款金额"))
                  : !fix2Reg.test(value)
                  ? callback(new Error("请输入数字，最多保留两位小数"))
                  : value >
                    this.refundDetail.orderLeftAmountInfo.payAmount / 100
                  ? callback(
                      new Error(
                        `输入的金额不能大于${this.refundDetail
                          .orderLeftAmountInfo.payAmount / 100}`
                      )
                    )
                  : value < 0
                  ? callback(new Error("输入的金额不能小于0"))
                  : this.than()
                  ? callback(new Error("输入金额的总和不能大于本次退款金额"))
                  : callback();
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        member: [
          {
            validator: (rule, value, callback) => {
              if (this.form2.memberChecked) {
                value === ""
                  ? callback(new Error("请输入退款金额"))
                  : !fix2Reg.test(value)
                  ? callback(new Error("请输入数字，最多保留两位小数"))
                  : value >
                    this.refundDetail.orderLeftAmountInfo.memberBalanceAmount /
                      100
                  ? callback(
                      new Error(
                        `输入的金额不能大于${this.refundDetail
                          .orderLeftAmountInfo.memberBalanceAmount / 100}`
                      )
                    )
                  : value < 0
                  ? callback(new Error("输入的金额不能小于0"))
                  : this.than()
                  ? callback(new Error("输入金额的总和不能大于本次退款金额"))
                  : callback();
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        integral: [
          {
            validator: (rule, value, callback) => {
              if (this.form2.integralChecked) {
                value === ""
                  ? callback(new Error("请输入退款金额"))
                  : !fix2Reg.test(value)
                  ? callback(new Error("请输入数字，最多保留两位小数"))
                  : value >
                    this.refundDetail.orderLeftAmountInfo
                      .integralDeductionAmount /
                      100
                  ? callback(
                      new Error(
                        `输入的金额不能大于${this.refundDetail
                          .orderLeftAmountInfo.integralDeductionAmount / 100}`
                      )
                    )
                  : value < 0
                  ? callback(new Error("输入的金额不能小于0"))
                  : this.than()
                  ? callback(new Error("输入金额的总和不能大于本次退款金额"))
                  : callback();
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      // 调整退款金额
      searchKey: "", // 搜索关键字
      searchTitle: "", // 搜索
      tableData: [],
      columns: [],
      merchantId: "" //商户id 长白山 1455730894637690881
    };
  },
  computed: {
    _title() {
      return this.refundType === 1
        ? "退单"
        : this.refundType === 3
        ? "退押金"
        : "退款";
    },
    title() {
      return this.step === 0
        ? "调整本次退款金额"
        : this.step === 1
        ? this._title
        : "确认" + this._title;
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.merchantId = localStorage.getItem("merchantId");
        this.loadData();
      }
    }
  },
  methods: {
    // 加载退款数据
    loadData() {
      refundCheck({
        orderId: this.orderId,
        detailIdType: this.refundIdType,
        detailIdList: this.refundIdList
      })
        .then(res => {
          this.form1.refundMoney = res.totalRefundableAmount;
          this.form1.commission = res.totalCommissionAmount;
          // 本次应退金额 = 本次退款金额 + 手续费 = res. totalRefundableAmount + res.totalCommissionAmount
          // res.totalRefundableAmount =
          //   res.totalRefundableAmount + res.totalCommissionAmount;
          // 2021-07-14 玉峰把 本次应退金额 字段改为totalOriginalRefundableAmount
          this.refundDetail = res;
          this.visible2 = true;
          // 处理不同产品类型是否需要展示搜索
          // 更多分类请看接口文档的产品分类
          // http://www.docway.net/project/1dAc1O6WB4z/1dVp80ii0sC
          const arr = [
            "1365211690130337793",
            "1365211690470076417",
            "1365211693271871490",
            "1365211694463053826",
            "1365211694970564609",
            "1365211695276748801",
            "1365211696811864066"
          ];
          let title = "";
          const columns = [
            {
              title: "产品名称",
              dataIndex: "productName",
              key: "productName",
              width: 150
            },
            {
              title: "业务类型",
              dataIndex: "productCategoryText",
              key: "productCategoryText",
              width: 100
            },
            {
              title: "产品数量",
              dataIndex: "buyNum",
              key: "buyNum",
              width: 100
            },
            {
              title: "产品状态",
              scopedSlots: { customRender: "statusText" },
              key: "statusText",
              width: 100
            },
            {
              title: "原收款金额",
              scopedSlots: { customRender: "totalReceivedAmount" },
              key: "totalReceivedAmount",
              width: 100
            },
            {
              title: "退款手续费",
              scopedSlots: { customRender: "editMoney" },
              key: "editMoney",
              width: 120
            },
            {
              title: "本次退款金额",
              scopedSlots: { customRender: "refundMoney" },
              key: "refundMoney",
              width: 100
            }
          ];
          // 门票类型
          if (arr.includes(this.productCategoryId)) {
            title = "票号";
            columns.unshift({
              title,
              dataIndex: "auxiliaryId",
              key: "auxiliaryId",
              width: 120
            });
          }
          // 会员类型
          else if (
            this.productCategoryId === "1365211695767482369" ||
            this.productCategoryId === "1365211695767482369"
          ) {
            title = "会员编号";
            columns.unshift({
              title,
              dataIndex: "purchaserMemberId",
              key: "purchaserMemberId",
              width: 120
            });
          }
          // 项目类型
          else if (
            this.productCategoryId === "1365211691942277121" ||
            this.productCategoryId === "1368872739652091905"
          ) {
            title = "套餐编号";
            columns.unshift({
              title,
              dataIndex: "auxiliaryId",
              key: "auxiliaryId",
              width: 120
            });
          }
          this.searchTitle = title;
          this.columns = columns;
          this.total(res.refundableProductList);
        })
        .catch(() => {
          this.$emit("update:visible", false);
        });
    },
    // 计时调整金额表格合计
    total(res) {
      let obj = {
        totalReceivedAmount: 0,
        totalCommissionAmount: 0,
        refundMoney: 0,
        statusText: "合计",
        id: "hj"
      };
      // 合计并生成新的table数组
      const arr = res.map(item => {
        // 处理已退款和未退款的产品
        if (item.status < 3 || item.status > 5) {
          // 已退款写死退款手续费为0不能编辑
          item.totalCommissionAmount = 0;
          // 已退款的产品的本次退款金额为0 且不能修改
          item.refundMoney = 0;
        }
        // 未退的产品则计算本次退款手续费和退款金额
        else {
          // 计算本次退款金额
          item.refundMoney =
            Math.round(
              (item.totalReceivedAmount - item.totalCommissionAmount) * 100
            ) / 100 || 0;
        }
        // 复制一份退款手续费转为元用于编辑
        this.$set(item, "editMoney", item.totalCommissionAmount / 100);
        obj.totalReceivedAmount += item.totalReceivedAmount;
        obj.totalCommissionAmount += item.totalCommissionAmount;
        if (item.refundMoney) obj.refundMoney += item.refundMoney;
        return item;
      });
      if (arr.length) arr.push(obj);
      this.tableData = arr;
    },
    // 调整退款金额改变
    handleChange(value, row) {
      if (value > row.totalReceivedAmount / 100) {
        this.$message.error("退款手续费不能大于原收款金额");
        row.editMoney = row.totalCommissionAmount / 100;
        return false;
      } else if (!fix2Reg.test(value)) {
        row.editMoney = row.totalCommissionAmount / 100;
        this.$message.error("请输入数字，最多保留两位小数");
        return false;
      } else {
        row.totalCommissionAmount = Math.round(value * 10000) / 100;
        this.total(this.tableData.slice(0, -1));
        return true;
      }
    },
    // 勾选
    checked(e, type) {
      let checked = e.target.checked;
      this.form2[type + "Checked"] = checked;
      if (!checked) {
        this.form2[type] = "";
        this.$refs.form2.validateField(type);
      }
    },
    // 输入金额总和跟可退金额比较
    than() {
      let main = this.form2.main * 100 || 0;
      let member = this.form2.member * 100 || 0;
      let integral = this.form2.integral * 100 || 0;
      return (
        Math.round((main + member + integral) * 100) / 100 >
        this.form1.refundMoney
      );
      // if (!than) this.$refs.form2.clearValidate();
      // return than;
    },
    // 搜索
    search(e) {
      let key =
        this.searchTitle === "会员编号" ? "purchaserMemberId" : "auxiliaryId";
      this.total(
        this.refundDetail.refundableProductList.filter(item => {
          let temp = item[key] + "";
          return temp.includes(e.target.value);
        })
      );
    },
    // 调整完毕
    changeOk() {
      this.form1.commission = this.refundDetail.refundableProductList.reduce(
        (pre, current) => {
          return pre + current.totalCommissionAmount;
        },
        0
      );
      this.form1.refundMoney =
        this.refundDetail.totalOriginalRefundableAmount - this.form1.commission;
      if (this.form1.refundMoney < 0) this.form1.refundMoney = 0;
      this.step = 1;
    },
    // 退款 / 下一步
    next() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          // 没有手续费，直接退
          if (this.form1.commission === 0) {
            this.submit({
              orderId: this.orderId,
              remarks: this.form1.remark,
              detailIdType: this.refundIdType,
              detailIdList: this.refundIdList
            });
          }
          // 有手续费需要分摊到支付方式
          else {
            const {
              payAmount,
              memberBalanceAmount
            } = this.refundDetail.orderLeftAmountInfo;
            // 逐层分摊退款金额，分摊主要支付=》会员余额=》积分抵扣
            let lastMain = this.form1.refundMoney - payAmount;
            // 填充表单2主要支付
            if (lastMain > 0) {
              this.form2.main = payAmount / 100;
              // 填充表单2会员余额
              this.form2.memberChecked = true;
              let lastMember = lastMain - memberBalanceAmount;
              if (lastMember > 0) {
                this.form2.member = memberBalanceAmount / 100;
                // 填充2积分
                this.form2.integralChecked = true;
                this.form2.integral = lastMember / 100;
              } else {
                this.form2.member = lastMain / 100;
              }
            } else {
              this.form2.main = this.form1.refundMoney / 100;
            }
            this.step = 2;
          }
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },
    // 确认退款
    sure() {
      this.$refs.form2.validate(valid => {
        if (valid) {
          const {
            main,
            mainChecked,
            member,
            memberChecked,
            integral,
            integralChecked
          } = this.form2;
          let main2 = mainChecked ? main * 100 : 0;
          let member2 = memberChecked ? member * 100 : 0;
          let integral2 = integralChecked ? integral * 100 : 0;
          let ok =
            Math.round((main + member + integral) * 10000) / 100 ===
            this.form1.refundMoney;
          if (!ok) {
            this.$message.error("输入的退款金额总和与本次退款金额不一致");
            return;
          }
          const data = {
            orderId: this.orderId,
            detailIdType: this.refundIdType,
            detailIdList: this.refundIdList,
            remarks: this.form1.remark,
            refundableDTO: {
              totalCommissionAmount: this.form1.commission,
              totalRefundAmount: this.form1.refundMoney,
              totalRefundPayAmount: main2,
              totalMemberBalanceRefundAmount: member2,
              totalIntegralDeductionRefundAmount: integral2,
              refundableProductList: this.refundDetail.refundableProductList
                .map(item => {
                  return {
                    id: item.id,
                    totalCommissionAmount: item.totalCommissionAmount
                  };
                })
                .filter(temp => temp.totalCommissionAmount > 0)
            }
          };
          this.submit(data);
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },
    // 提交数据
    submit(data) {
      this.loading = true;
      refund(data)
        .then(({ refundFailMsg }) => {
          this.$message.success(refundFailMsg);
          this.handleCancel();
          this.$emit("refunded");
          this.$emit("on-ok", true);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 取消
    handleCancel() {
      if (this.$refs[`form${this.step}`])
        this.$refs[`form${this.step}`].clearValidate();
      this.form1.remark = "";
      this.form1.refundMoney = 0;
      this.form1.commission = 0;
      this.form2 = {
        main: "", // 主要退款
        mainChecked: true,
        member: "", // 会员余额退款
        memberChecked: false,
        integral: "", // 积分退款
        integralChecked: false
      };
      this.visible2 = false;
      this.step = 1;
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style scoped></style>
