<template>
  <div>
    <!-- 游客信息 -->
    <div class="flex-between mv-1x mt-3x">
      <h3 class="title-mid" style="margin: 10px 0 16px 0">游客信息</h3>
      <div>
        <a-button
          v-permission="'homeQuickPlaceOrderPlaceOrderAdd'"
          size="large"
          type="primary"
          ghost
          @click="addTouristOne"
          >添加游客</a-button
        >
        <a-button
          v-permission="'homeQuickPlaceOrderPlaceOrderExport'"
          size="large"
          type="primary"
          ghost
          @click="touristShow"
          class="ml-1x"
          >导入游客</a-button
        >
        <a-button
          v-permission="'homeQuickPlaceOrderPlaceOrderCheck'"
          size="large"
          type="primary"
          class="ml-1x"
          @click="checkTourist"
          >核对游客信息</a-button
        >
      </div>
    </div>
    <!-- 游客信息汇总table -->
    <a-table
      ref="touristSumtable"
      size="small"
      :columns="touristColumns"
      :data-source="touristSumData"
      :pagination="false"
      row-key="touristCategoryId"
    >
      <!-- 游客类型 -->
      <template slot="touristCategoryName" slot-scope="text, scope, index">
        <!-- {{ scope.touristCategoryName }} -->
        <a-select
          :default-value="scope.touristCategoryId"
          v-model="scope.touristCategoryId"
          @change="switchSumTouristData(scope.touristCategoryId, index)"
          class="res"
        >
          <a-select-option
            v-for="item in touristType"
            :key="item.id"
            :value="item.id"
          >
            {{ item.touristCategoryName }}
          </a-select-option>
        </a-select>
      </template>
      <!-- 匹配产品 -->
      <template slot="name" slot-scope="text, scope, index">
        <!-- {{ scope.name }} -->
        <a-select
          :default-value="scope.sybSystemIdProductId"
          v-model="scope.sybSystemIdProductId"
          @change="switchSumTouristProData(scope.sybSystemIdProductId, index)"
          class="res"
        >
          <a-select-option
            v-for="item in subscenicProductList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <!-- 单价 -->
      <template slot="sellingPrice" slot-scope="scope"
        >{{ scope.sellingPrice || 0 | money }}
      </template>
      <!-- 优惠 个人时 && 团体开启游客优惠 -->
      <template slot="discountCategoryText" slot-scope="scope">{{
        scope.discountCategory == 1
          ? scope.fold / 10 + " 折"
          : scope.discountCategoryText
      }}</template>
      <!-- 游客人数 -->
      <template slot="sumNum" slot-scope="text, scope, index">
        <!-- {{ scope.sumNum }} -->
        <a-input-number
          v-model="scope.sumNum"
          :min="0"
          @change="switchSumTouristProData(scope.sybSystemIdProductId, index)"
        />
      </template>
      <template slot="sumPrice" slot-scope="scope">{{
        scope.sumPrice | money
      }}</template>
      <!-- 删除游客 -->
      <template slot="action" slot-scope="text, scope, index">
        <a-button
          @click="setDefaultProduct(scope, index)"
          type="link"
          class="blue"
          >设为默认</a-button
        >
        <a-button @click="delTourist(scope, index)" type="link" class="blue"
          >删除</a-button
        >
      </template>
    </a-table>
    <!-- 添加游客 -->
    <a-modal
      title="添加游客"
      :visible="selectTouristShow"
      center
      @cancel="selectTouristShow = false"
    >
      <!-- 添加游客信息 -->
      <a-form-model
        ref="touristForm"
        :model="touristTypeData"
        :rules="touristRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        labelAlign="left"
        :mask-closable="false"
      >
        <a-form-model-item label="证件号码" prop="idCard">
          <a-select
            default-value="1"
            v-model="touristCardType"
            size="large"
            style="width: 100px"
          >
            <a-select-option value="1">
              身份证
            </a-select-option>
            <a-select-option value="2">
              护照
            </a-select-option>
          </a-select>
          <a-input
            placeholder="请输入证件号码"
            ref="idCardInput"
            v-model="touristTypeData.idCard"
            @blur="queryTouristInfoBycard(touristTypeData.idCard)"
            @pressEnter="queryTouristInfoBycard(touristTypeData.idCard)"
            size="large"
            style="width: 212px"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="name">
          <a-input
            size="large"
            v-model="touristTypeData.name"
            placeholder="请输入姓名"
          />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input
            size="large"
            v-model="touristTypeData.phone"
            placeholder="请输入手机号"
          />
        </a-form-model-item>
        <a-form-model-item label="游客类型">
          <a-select
            :default-value="touristTypeData.touristCategoryId"
            v-model="touristTypeData.touristCategoryId"
            @change="changeTouristCategory"
            class="res"
            size="large"
            placeholder="游客类型"
          >
            <a-select-option
              v-for="item in touristType"
              :key="item.id"
              :value="item.id"
            >
              {{ item.touristCategoryName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="匹配产品" prop="sybSystemIdProductId">
          <a-select
            :default-value="touristTypeData.sybSystemIdProductId"
            v-model="touristTypeData.sybSystemIdProductId"
            :filter-option="searchProduct"
            @change="selectProduct"
            show-search
            class="res"
            size="large"
            placeholder="匹配产品"
          >
            <a-select-option
              v-for="item in subscenicProductList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="selectTouristShow = false">取消</a-button>
        <a-button type="primary" ghost @click="continueSumTouristData"
          >继续添加</a-button
        >
        <a-button type="primary" @click="okSumTouristData">确定</a-button>
      </template>
    </a-modal>
    <!-- 游客信息核对 -->
    <a-modal
      title="游客信息"
      :visible="checkTouristShow"
      center
      width="70%"
      @ok="checkTouristShow = false"
      @cancel="checkTouristShow = false"
    >
      <a-table
        size="small"
        :columns="checkTouristColumns"
        :data-source="touristSumData"
        :pagination="false"
        row-key="touristCategoryId"
      >
        <!-- 姓名 -->
        <template slot="username" slot-scope="scope">
          {{ scope.name }}
        </template>
        <!-- 手机号 -->
        <template slot="phone" slot-scope="scope">
          {{ scope.phone }}
        </template>
        <!-- 证件类型 -->
        <template slot="cartType">
          {{ touristCardType == 1 ? "身份证" : "护照" }}
        </template>
        <!-- 证件号 -->
        <template slot="idCard" slot-scope="scope">
          {{ scope.idCard }}
        </template>
        <!-- 游客类型 -->
        <template slot="touristCategoryName" slot-scope="text, scope, index">
          <!-- {{ scope.touristCategoryName }} -->
          <a-select
            :default-value="scope.touristCategoryId"
            v-model="scope.touristCategoryId"
            @change="switchSumTouristData(scope.touristCategoryId, index)"
            disabled
            class="res"
          >
            <a-select-option
              v-for="item in touristType"
              :key="item.id"
              :value="item.id"
            >
              {{ item.touristCategoryName }}
            </a-select-option>
          </a-select>
        </template>
        <!-- 匹配产品 -->
        <template slot="name" slot-scope="text, scope, index">
          <!-- {{ scope.name }} -->
          <a-select
            :default-value="scope.sybSystemIdProductId"
            v-model="scope.sybSystemIdProductId"
            @change="switchSumTouristProData(scope.sybSystemIdProductId, index)"
            disabled
            class="res"
          >
            <a-select-option
              v-for="item in subscenicProductList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { isIdcard, checkIdCard, checkPhone, checkText } from "@/utils/global";
import { touristInfoBycard } from "@/api/member";
// import order from "../../../../store/order";
export default {
  name: "tourist",
  props: {
    // 子景区
    subScenic: {
      type: Object
    },
    //有空类型
    touristType: {
      type: Array,
      default() {
        return [];
      }
    },
    //子景区产品
    subscenicProductList: {
      type: Array,
      default() {
        return [];
      }
    },
    //导游数据
    guideCheckData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 团体时信息
    groupType: {
      type: Object
    }
  },
  data() {
    return {
      orderType: "1", //下单类型 1个人快速下单 2团体快速下单
      selectTouristShow: false, //添加游客信息框
      //游客信息汇总table
      touristColumns: [
        {
          title: "游客类型",
          key: "touristCategoryName",
          scopedSlots: { customRender: "touristCategoryName" },
          width: 170
        },
        {
          title: "匹配产品",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 170
        },
        {
          title: "单价",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" },
          width: 170
        },
        {
          title: "优惠",
          key: "discountCategoryText",
          scopedSlots: { customRender: "discountCategoryText" },
          width: 170
        },
        {
          title: "游客人数",
          key: "sumNum",
          scopedSlots: { customRender: "sumNum" },
          width: 170
        },
        {
          title: "总金额",
          key: "sumPrice",
          scopedSlots: { customRender: "sumPrice" },
          width: 150
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: 170
        }
      ],
      //核对游客信息table
      checkTouristColumns: [
        {
          title: "姓名",
          key: "username",
          scopedSlots: { customRender: "username" },
          width: 170
        },
        {
          title: "手机号",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          width: 170
        },
        {
          title: "证件类型",
          key: "cartType",
          scopedSlots: { customRender: "cartType" },
          width: 170
        },
        {
          title: "证件号",
          key: "idCard",
          scopedSlots: { customRender: "idCard" },
          width: 170
        },
        {
          title: "游客类型",
          key: "touristCategoryName",
          scopedSlots: { customRender: "touristCategoryName" },
          width: 170
        },

        {
          title: "匹配产品",
          key: "name",
          scopedSlots: { customRender: "name" },
          width: 170
        }
      ],
      touristCardType: "1", //添加-游客证件类型 1身份证 2护照
      //添加游客-游客类型table
      touristTypeColumns: [
        {
          title: "证件类型和证件号码",
          key: "idCard",
          scopedSlots: { customRender: "idCard" },
          align: "center",
          width: 260
        },
        {
          title: "游客类型",
          key: "touristCategoryId",
          scopedSlots: { customRender: "touristCategoryId" },
          align: "center",
          width: 150
        },
        {
          title: "票种类型",
          key: "sybSystemIdProductId",
          scopedSlots: { customRender: "sybSystemIdProductId" },
          align: "center",
          width: 170
        },
        {
          title: "价格",
          key: "sellingPrice",
          scopedSlots: { customRender: "sellingPrice" },
          align: "center",
          width: 120
        }
      ],
      //添加游客-游客类型table-data
      touristTypeData: {
        idCard: "",
        touristCategoryId: "", //游客类型id
        sybSystemIdProductId: "", //产品id
        sellingPrice: "", //
        discountCategory: "", //优惠类型(1.折扣 2.无 3.免费)
        fold: "" //折
      },
      touristRules: {
        name: [
          { required: false, message: "请输入姓名", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        idCard: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: checkIdCard, trigger: "blur" }
        ],
        phone: [
          { required: false, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      },
      touristData: [], //当前添加游客信息数据 未处理汇总
      touristSumData: [], //已添加游客信息数据 处理汇总后的
      checkTouristShow: false //核对游客弹出
    };
  },
  watch: {
    groupType: {
      handler() {
        let index = this.touristColumns.findIndex(
          item => item.key == "discountCategoryText"
        );
        if (index > 0) {
          if (this.orderType == 2 && !this.groupType.touristDiscountStatus) {
            this.touristColumns.splice(index, 1); //把优惠去掉
          }
        } else if (
          this.orderType == 2 &&
          this.groupType.touristDiscountStatus
        ) {
          this.touristColumns.splice(3, 0, {
            title: "优惠",
            key: "discountCategoryText",
            scopedSlots: { customRender: "discountCategoryText" },
            width: 170
          });
        } else if (this.orderType == 1) {
          this.touristColumns.splice(3, 0, {
            title: "优惠",
            key: "discountCategoryText",
            scopedSlots: { customRender: "discountCategoryText" },
            width: 170
          });
        }
        let t = setTimeout(() => {
          this.sumTouristData();
          clearTimeout(t);
          t = null;
        }, 200);
      },
      deep: true
    },
    guideCheckData() {
      this.getOrderInfo();
    }
  },
  mounted() {
    this.addTouristOne();
    let placeDefaultTourist = localStorage.getItem("placeDefaultTourist");
    if (placeDefaultTourist) {
      let t = setTimeout(() => {
        this.switchSumTouristDataAndProduct(JSON.parse(placeDefaultTourist), 0);
        clearTimeout(t);
        t = null;
      }, 2000);
    } else {
      let time = setTimeout(() => {
        if (
          this.touristSumData &&
          this.touristSumData.length > 0 &&
          this.touristType &&
          this.touristType.length > 0
        ) {
          this.switchSumTouristData(this.touristType[0].id, 0);
        }
        clearTimeout(time);
        time = null;
      }, 2000);
    }
  },
  methods: {
    checkTourist() {
      this.checkTouristShow = true;
    },
    //身份证查游客类型
    queryTouristInfoBycard(idCard) {
      if (!idCard) {
        this.$message.warning("证件号为空！");
        return;
      }
      let err = isIdcard(idCard);
      if (err) {
        this.$message.warning(err);
        return;
      }
      touristInfoBycard({
        type: 1, //1窗口  2.游小铺
        sybSystemId: this.subScenic.subScenicId,
        idCard: idCard
      }).then(res => {
        this.touristType = res[0].memberTouristManagementVOList;
        this.$emit("changeTouristType", res[0].memberTouristManagementVOList);
        res[0].memberTouristManagementVOList.forEach(item => {
          //默认匹配游客分类
          if (item.default) {
            this.touristTypeData.touristCategoryId = item.id; //游客分类id
            this.touristTypeData.touristCategoryName = item.touristCategoryName; //游客分类name
            this.touristTypeData.discountCategory = item.discountCategory; //优惠类型(1.折扣 2.无 3.免费)
            this.touristTypeData.fold = item.fold; //折

            let productId = item.sybSystemIdProductId;
            this.touristTypeData.sybSystemIdProductId = productId
              ? productId
              : ""; //默认产品
          }
        });
        //设置价格
        if (this.touristTypeData.sybSystemIdProductId) {
          this.subscenicProductList.forEach(ite => {
            if (ite.id == this.touristTypeData.sybSystemIdProductId) {
              this.touristTypeData.sellingPrice = ite.sellingPrice;
            }
          });
        }
        this.touristData.push(this.touristTypeData); //未处理汇总
      });
    },
    //添加游客弹框
    touristShow() {
      this.selectTouristShow = true;
      this.autoFocus("idCardInput");
    },
    //添加一行游客
    addTouristOne() {
      this.touristSumData.push({
        sybSystemIdProductId: "", //产品id
        touristCategoryId: "", //游客类型id
        sumNum: 1,
        sumPrice: 0
      });
    },
    //自动获取焦点
    autoFocus(ref) {
      let t = setTimeout(() => {
        this.$refs[ref].focus();
        clearTimeout(t);
        t = null;
      }, 100);
    },

    //继续添加游客
    continueSumTouristData() {
      this.sumTouristData("add");
      this.touristTypeData.idCard = "";
      this.autoFocus("idCardInput");
    },
    //添加游客确定
    okSumTouristData() {
      if (!this.touristTypeData.sybSystemIdProductId) {
        this.$message.warning("请选择游客类型或票种");
        return;
      }
      this.sumTouristData("add");
      let t = setTimeout(() => {
        this.selectTouristShow = false;
        clearTimeout(t);
        t = null;
      }, 100);
    },
    //确定&处理游客分类汇总数据
    sumTouristData(type) {
      let item = this.touristTypeData;
      if (!item.touristCategoryId) {
        this.getOrderInfo();
        return;
      }
      // if (!item.sybSystemIdProductId) {
      //   this.$message.warning("请选择游客类型或票种");
      //   return;
      // }
      let obj = {
        sybSystemIdProductId: item.sybSystemIdProductId, //产品id
        name: item.name, //游客名称
        phone: item.phone,
        idCard: item.idCard,
        sellingPrice: item.sellingPrice,
        touristCategoryId: item.touristCategoryId, //游客类型id
        touristCategoryName: item.touristCategoryName, //游客类型name
        discountCategory: item.discountCategory, //优惠类型(1.折扣 2.无 3.免费)
        fold: item.fold //折
      };
      //遍历已汇总-已有数据才进这
      this.touristSumData.forEach((ite, index) => {
        //存在同一游客类型&产品 update
        if (
          item.touristCategoryId == ite.touristCategoryId &&
          item.sybSystemIdProductId == ite.sybSystemIdProductId
        ) {
          obj.sumNum = type == "add" ? ++ite.sumNum : ite.sumNum;
          obj.sumPrice = obj.sumNum * item.sellingPrice || 0;
          let list = this.touristSumData;
          list[index] = obj;
          this.touristSumData = [...list]; //解构一下触发table更新
          this.switchSumTouristProData(item.sybSystemIdProductId, index);
        } else if (
          !this.touristSumData.some(temp => {
            return (
              temp.touristCategoryId == obj.touristCategoryId &&
              temp.sybSystemIdProductId == obj.sybSystemIdProductId
            );
          })
        ) {
          //类型和产品id 都不一致才add
          // obj.sumPrice = item.sellingPrice || 0;
          obj.sellingPrice = item.sellingPrice;
          obj.sumNum = 1;
          this.touristSumData.push(obj);
          this.switchSumTouristProData(
            item.sybSystemIdProductId,
            this.touristSumData.length - 1
          );
        }
      });
      //没有数据时这
      if (this.touristSumData.length == 0) {
        obj.sumPrice = item.sellingPrice || 0;
        obj.sumNum = 1;
        this.touristSumData.push(obj);
        this.switchSumTouristProData(item.sybSystemIdProductId, 0);
      }
      this.$emit("changeTouristSumData", this.touristSumData);
      this.getOrderInfo();
    },
    //汇总订单信息-计算优惠
    getOrderInfo() {
      //分个人 团体(是否开启游客优惠) 0关闭 只算团体优惠 1 开启 折上折
      let orderInfo = {
        free: 0, //免费人数
        folds: [], //折扣
        discount: 0, //已折扣
        actualSum: 0, //实际总金额
        sumPrice: 0, //原始总金额
        sumNum: 0, //总游客数
        sumGuide: 0, //总导游数
        guidePrice: 0, //导游总价
        freeGuide: 0, //减免导游数
        guideFare: "", //导游票价 1全免 2免一人 3全价
        guideProductPice: "", //导游票单价
        guideSumPice: 0 //导游所需总价
      };
      this.touristSumData.forEach(item => {
        orderInfo.sumNum += item.sumNum; //总游客数
        if (this.orderType == 1) {
          //个人仅算总价
          orderInfo.actualSum += item.sumPrice;
        } else {
          orderInfo.actualSum += item.sumPrice;
        }
      });
      //团体票
      if (this.orderType == 2) {
        let groupType = this.groupType;
        orderInfo.guideFare = groupType.guideFare;
        orderInfo.guideProductPice = groupType.guideProductPice;
        orderInfo.sumGuide = this.guideCheckData.length; //总导游数
        //guideFare: "", //导游票价 1全免 2免一人 3全价
        if (orderInfo.guideFare == 1) {
          orderInfo.guideSumPice = 0;
        } else if (orderInfo.guideFare == 2) {
          orderInfo.guideSumPice =
            orderInfo.guideProductPice * (orderInfo.sumGuide - 1);
        } else if (orderInfo.guideFare == 3) {
          orderInfo.guideSumPice =
            orderInfo.guideProductPice * orderInfo.sumGuide;
        } else if (orderInfo.guideFare == 4) {
          //4限制
          // 游客数量	numberVisitors
          // 封顶设置	isCapped	1封顶免  2不封顶
          // 免几人	freePeople
          //每 numberVisitors 个游客免一个导游 ，isCapped 不封顶/封顶-免 freePeople
          if (orderInfo.sumNum >= groupType.numberVisitors) {
            // console.log(orderInfo.sumNum);
            let num = Number(
              (orderInfo.sumNum / groupType.numberVisitors).toFixed(0)
            );
            orderInfo.freeGuide = num > 0 ? num : 0; //减免导游数
            if (groupType.isCapped == 1) {
              //1封顶免-最多免 freePeople
              orderInfo.freeGuide =
                orderInfo.freeGuide >= groupType.freePeople
                  ? groupType.freePeople
                  : orderInfo.freeGuide;
            }
          }
          orderInfo.guideSumPice =
            orderInfo.guideProductPice *
            (orderInfo.sumGuide - orderInfo.freeGuide);
        } else if (orderInfo.guideFare == 5) {
          //5 限制内
          // 游客数量	numberVisitors
          // 封顶设置	isCapped	1封顶免  2不封顶
          // 免几人	freePeople
          //numberVisitors 个游客内免 freePeople 导游 ，超出免 beyondExemption
          if (orderInfo.sumNum <= groupType.numberVisitors) {
            let num = groupType.freePeople;
            orderInfo.freeGuide = num > 0 ? num : 0; //减免导游数
            if (groupType.isCapped == 1) {
              //1封顶免-最多免 freePeople
              orderInfo.freeGuide =
                orderInfo.freeGuide >= groupType.freePeople
                  ? groupType.freePeople
                  : orderInfo.freeGuide;
            }
          } else if (orderInfo.sumNum > groupType.numberVisitors) {
            orderInfo.freeGuide =
              orderInfo.sumNum > groupType.numberVisitors
                ? groupType.beyondExemption
                : orderInfo.freeGuide;
          }
          orderInfo.guideSumPice =
            orderInfo.guideProductPice *
            (orderInfo.sumGuide - orderInfo.freeGuide);
        }
        // 折扣类型 (1.折扣 2.无 3.免费)
        if (groupType.discountCategory == 1) {
          let op = orderInfo.actualSum * (groupType.fold / 100);
          orderInfo.actualSum = op < 0 ? 0 : Number(op.toFixed(0));
          orderInfo.folds.push({
            foldName: groupType.touristCategoryName,
            fold: groupType.fold / 10
          });
        } else if (groupType.discountCategory == 3) {
          orderInfo.actualSum = 0;
        }
        orderInfo.actualSum = orderInfo.actualSum + orderInfo.guideSumPice; //游客+导游
      }

      this.orderInfo = orderInfo;
      this.$emit("changeOrderInfo", orderInfo);
    },
    //切换add游客类型
    changeTouristCategory(touristCategoryId) {
      let touristCategory = this.touristType.find(
        item => item.id == touristCategoryId
      );
      this.touristTypeData.touristCategoryName =
        touristCategory.touristCategoryName; //游客分类id
      this.touristTypeData.fold = touristCategory.fold; //折扣
      this.touristTypeData.discountCategory = touristCategory.discountCategory; //折扣类型
      this.touristTypeData.sybSystemIdProductId =
        touristCategory.sybSystemIdProductId;
      //设置价格
      if (this.touristTypeData.sybSystemIdProductId) {
        this.subscenicProductList.forEach(ite => {
          if (ite.id == this.touristTypeData.sybSystemIdProductId) {
            this.touristTypeData.sellingPrice = ite.sellingPrice;
          }
        });
      }
      this.getOrderInfo();
    },
    switchSumTouristDataAndProduct(temp, index) {
      let touristType = this.touristType.find(
        item => item.id == temp.touristCategoryId
      );
      let obj = this.touristSumData[index];
      obj.touristCategoryId = touristType.id;
      obj.sybSystemIdProductId = temp.sybSystemIdProductId;
      obj.discountCategory = touristType.discountCategory; //折扣类型 (1.折扣 2.无 3.免费)
      obj.discountCategoryText = touristType.discountCategoryText;
      obj.fold = touristType.fold; //折扣
      let list = this.touristSumData;
      list[index] = obj;
      this.touristSumData = [...list];
      //设置价格
      this.switchSumTouristProData(obj.sybSystemIdProductId, index);
    },
    //切换游客table的游客类型
    switchSumTouristData(id, index) {
      let touristType = this.touristType.find(item => item.id == id);
      let obj = this.touristSumData[index];
      obj.touristCategoryId = touristType.id;
      obj.sybSystemIdProductId = touristType.sybSystemIdProductId;
      obj.discountCategory = touristType.discountCategory; //折扣类型 (1.折扣 2.无 3.免费)
      obj.discountCategoryText = touristType.discountCategoryText;
      obj.fold = touristType.fold; //折扣
      let list = this.touristSumData;
      list[index] = obj;
      this.touristSumData = [...list];
      //设置价格
      this.switchSumTouristProData(obj.sybSystemIdProductId, index);
      // this.getOrderInfo();
    },
    //切换游客table的产品类型
    switchSumTouristProData(id, index) {
      //改变价格
      // 团体 关闭游客优惠
      // if (this.orderType == 2 && !this.groupType.touristDiscountStatus) {
      //   return;
      // }
      let product = this.subscenicProductList.find(item => item.id == id);
      if (!product) product = { sellingPrice: 0 };
      let obj = this.touristSumData[index];
      //不匹配
      if (!id || !this.subscenicProductList.some(item => item.id == id)) {
        obj.sellingPrice = 0;
        obj.sybSystemIdProductId = "";
      } else {
        obj.sellingPrice = product.sellingPrice || 0;
      }
      //优惠类型(1.折扣 2.无 3.免费)
      if (obj.discountCategory == 1) {
        // 1.折扣
        obj.fold = obj.fold ? obj.fold : "-";
        if (obj.fold !== "-") {
          let op = obj.sellingPrice * (obj.fold / 100) * obj.sumNum;
          obj.sumPrice = op < 0 ? 0 : Number(op.toFixed(0));
        } else {
          obj.sumPrice = obj.sumNum * product.sellingPrice || 0;
        }
      } else if (obj.discountCategory == 3) {
        obj.sumPrice = 0;
      } else if (obj.discountCategory == 2) {
        //优惠 无
        obj.sumPrice = obj.sumNum * product.sellingPrice || 0;
      }
      let list = this.touristSumData;
      list[index] = obj;
      this.touristSumData = [...list];
      this.$emit("changeTouristSumData", list);
      this.getOrderInfo();
    },
    //选择产品
    selectProduct(val) {
      let product = this.subscenicProductList.find(item => item.id == val);
      this.touristTypeData.sellingPrice = product.sellingPrice;
      this.touristTypeData.sybSystemIdProductId = product.id;
      this.getOrderInfo();
    },
    //搜索产品
    searchProduct(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    //设为默认产品
    setDefaultProduct(row) {
      localStorage.setItem("placeDefaultTourist", JSON.stringify(row));
      this.$message.success("设置成功");
    },
    //删除游客
    delTourist(row, index) {
      this.touristSumData.splice(index, 1);
      this.getOrderInfo();
    },
    //删除所有游客
    delAllTourist(orderType) {
      this.touristSumData = [];
      this.orderType = orderType;
      this.getOrderInfo();
    },
    handleSelectionTourist(vals) {
      this.selectionTouristData = vals;
    },
    //添加游客
    addTourist(size) {
      let i = 0;
      let arr = [];
      while (i < size) {
        arr.push({}); //默认数量1
        i++;
      }
      this.touristData = this.touristData.concat(arr);
    }
  }
};
</script>
