<template>
  <div class="wrapper flex-start flex-col">
    <!-- 搜索组件 -->
    <header class="p-2x bg-white">
      <a-form layout="inline">
        <a-form-item label="退款申请时间" class="pr-x">
          <a-range-picker
            :allow-clear="true"
            v-model="refundDate"
            class="input"
          />
        </a-form-item>
        <a-form-item>
          <a-button @click="loadData" type="primary" plain class="mr-2x"
            >查询</a-button
          >
          <a-button @click="reset" plain>重置</a-button>
        </a-form-item>
      </a-form>
    </header>
    <div class="main mt-2x">
      <div v-if="isTrue" class="flex-end pv-1x pr-2x">
        <a-button type="primary" @click="batchApprove">批量审批</a-button>
      </div>
      <!--      :row-selection="rowSelection" 临时注释多选-->
      <currency-table
        :columns="filterColumns"
        :data-source="tableData.data"
        :check-searchs.sync="checkSearchs"
        @filter-change="filterChange"
      >
        <template slot="action">
          <a-table-column width="80px" title="操作" fixed="right">
            <!--            <div slot="title">-->
            <!--              操作-->
            <!--            </div>-->
            <div
              slot-scope="row"
              v-permission="'appOrderNewRefundApproveApproval'"
            >
              <a-button
                :disabled="row.refundAuditStatus !== 2"
                type="link"
                class="ph-x"
                @click="oneAudit(row)"
                >审批</a-button
              >
            </div>
          </a-table-column>
        </template>
      </currency-table>
      <div class="p-2x flex-end">
        <a-pagination
          v-model="tableData.page"
          :default-page-size="tableData.size"
          :total="tableData.total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="pageChange"
          @showSizeChange="sizeChange"
        />
      </div>
    </div>
    <!-- 退款审批 dialog -->
    <a-modal
      title="退款审批"
      :visible="dialogVisible"
      width="600px"
      @cancel="resetForm"
      @ok="submitForm('form')"
    >
      <a-form-model
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        :model="form"
        ref="form"
        :rules="rules"
      >
        <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
        <a-form-model-item label="审批结果" required>
          <a-radio-group v-model="form.refundAuditStatus">
            <a-radio :value="4">通过</a-radio>
            <a-radio :value="3">不通过</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item
          class="mt-2x"
          label="审批说明"
          prop="refundAuditRemarks"
        >
          <a-input
            type="textarea"
            placeholder="请输入审批说明"
            v-model="form.refundAuditRemarks"
          ></a-input>
        </a-form-model-item>
        <!--        <a-form&#45;&#45;model-item>-->
        <!--          <a-button type="primary" @click="submitForm('form')">确定</a-button>-->
        <!--          <a-button @click="resetForm">取消</a-button>-->
        <!--        </a-form&#45;&#45;model-item>-->
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { orderRefundAuditManagement } from "@/api/search";
import CurrencyTable from "@/components/table/CurrencyTable";
import { refundRecordAudit } from "@/api/order";
import { checkText } from "@/utils/global";
import moment from "moment";
export default {
  name: "RefundApprove",
  components: {
    CurrencyTable
  },
  data() {
    return {
      isTrue: false, // 临时注释多选
      moment,
      formData: {},
      refundDate: [], // 申请时间
      selectedRowKeys: [], // 搜索头部过滤数据
      checkSearchs: [], // 表格头过滤后选中的列表数组
      //退款审批
      dialogVisible: false, // 是否显示退款审批 dialog
      form: {
        refundAuditStatus: 4, // 审批状态：1免审核，2审核中，3拒绝，4通过
        refundAuditRemarks: "" // 审批说明
      },
      // 表单验证规则
      rules: {
        refundAuditRemarks: [
          { required: true, message: "请输入审批说明", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ]
      },
      itemObj: {}, // 审批当前对象
      tableData: {
        data: [],
        columns: [
          {
            key: "parentId",
            title: "退款总单号",
            show: true,
            width: 200,
            fixed: "left"
          },
          {
            key: "orderId",
            title: "销售总单号",
            show: true,
            width: 200,
            ellipsis: true
          },
          {
            key: "refundApplyTime",
            title: "退款申请时间",
            show: true,
            width: 180,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "originalReceiveAmount",
            title: "支付金额",
            show: true,
            width: 120,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "refundAmount",
            title: "退款申请金额",
            primary: "red",
            show: true,
            width: 140,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "refundIntegralDeductionAmount",
            title: "退积分数量",
            isMoney: false,
            show: true,
            width: 120
          },
          {
            key: "refundWayName",
            title: "退款方式",
            show: true,
            width: 150
          },
          {
            key: "refundChannelsText",
            title: "退款申请渠道",
            show: true,
            width: 135,
            filters: true, // 开启表格头部过滤
            filterKey: "refundApplyChannels",
            fieldList: []
          },
          {
            key: "refundAuditStatusText",
            title: "退款审核状态",
            show: true,
            width: 135,
            filters: true, // 开启表格头部过滤
            filterKey: "refundAuditStatus",
            fieldList: []
          }
        ],
        multipleSelection: [],
        // multiple:false,
        total: 1,
        page: 1,
        size: 10
      }
    };
  },
  computed: {
    filterColumns() {
      return this.tableData.columns.filter(column => column.show);
    },
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.selectionChange
      };
    }
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 产品列表多选
    selectionChange(val) {
      this.selectedRowKeys = val;
      // 多选产品列表时根据校验规则判定按钮是否可点击
      // if (val.length) {
      //   let arr = [];
      //   this.tableData.data.forEach(tem => {
      //     // 查找出选择的对象
      //     let i = val.findIndex(te => te === tem.id);
      //     if (i !== -1) {
      //       arr.push(tem);
      //     }
      //   });
      //   console.log(arr)
      // } else {
      //   console.log('批量')
      // }
    },
    pageChange(current) {
      this.tableData.page = current;
      this.getDataList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.tableData.page = current;
      this.tableData.size = size;
      this.getDataList();
    },
    // 表格过滤触发的时间
    filterChange() {
      this.tableData.page = 1;
      this.getDataList();
    },
    loadData() {
      this.tableData.page = 1;
      let val = this.refundDate;
      if (val.length) {
        this.formData.refundApplyBeginTime = moment(val[0])
          .startOf("date")
          .valueOf();
        this.formData.refundApplyEndTime = moment(val[1])
          .endOf("date")
          .valueOf();
      } else {
        this.formData = {};
      }
      this.getDataList();
    },
    getDataList() {
      this.selectedRowKeys = [];
      let refundAuditStatus = ""; // 退款审核状态
      let refundApplyChannels = ""; // 申请渠道
      if (this.checkSearchs.length) {
        this.checkSearchs.forEach(item => {
          switch (item.column.filterKey) {
            case "refundAuditStatus":
              refundAuditStatus = item.tagList.map(tem => tem.key).join(",");
              break;
            case "refundApplyChannels":
              refundApplyChannels = item.tagList.map(tem => tem.key).join(",");
              break;
          }
        });
      }
      orderRefundAuditManagement({
        page: this.tableData.page,
        size: this.tableData.size,
        refundAuditStatus,
        refundApplyChannels,
        ...this.formData
      }).then(res => {
        this.tableData.data = res.records;
        this.tableData.total = res.total;
      });
    },
    // 审批
    oneAudit(row) {
      this.dialogVisible = true;
      this.form.refundAuditRemarks = "";
      this.itemObj = row;
    },
    // 批量退款审批
    batchApprove() {
      console.log(this.selectedRowKeys);
    },
    // 审批
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.dialogVisible = false;
          let data = {
            refundId: this.itemObj.parentId,
            refundAuditStatus: this.form.refundAuditStatus, // 审批状态：1免审核，2审核中，3拒绝，4通过
            refundAuditRemarks: this.form.refundAuditRemarks // 审批说明
          };
          refundRecordAudit(data)
            .then(() => {
              this.getDataList(); // 重新加载当前页数据
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },
    //重置
    reset() {
      this.refundDate = [];
      this.formData = {};
      this.loadData();
    },
    resetForm() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped></style>
