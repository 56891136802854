<template>
  <div class="wrapper flex-between flex-col">
    <!--查询-->
    <div class="header ">
      <a-form-model layout="inline" :model="searchForm" ref="searchForm">
        <a-form-model-item label="订单日期">
          <a-range-picker
            :allow-clear="false"
            class="input"
            v-model="dateRange"
            @change="dateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="单号" prop="orderId">
          <!-- 处理 输入空格过滤的问题 -->
          <a-input
            v-model.trim="searchForm.orderId"
            placeholder="订单号/总单号或外部单号"
            class="input"
            allow-clear
            ref="orderListRef"
            @pressEnter="reload"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="reload">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="link" @click="visibleSearch = true"
            >高级查询</a-button
          >
        </a-form-model-item>
      </a-form-model>

      <!--高级查询-->
      <a-drawer
        title="高级查询"
        placement="right"
        :width="400"
        :closable="false"
        :visible="visibleSearch"
        @close="visibleSearch = false"
      >
        <a-form-model
          ref="heightForm"
          :model="searchForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item label="下单日期">
            <a-range-picker
              :allow-clear="false"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="单号" prop="orderId">
            <a-input
              v-model="searchForm.orderId"
              placeholder="输入订单号/总单号或外部单号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="销售渠道" prop="buyChannel">
            <a-cascader
              :display-render="displayRender"
              :fieldNames="{
                value: 'id',
                label: 'channelName',
                children: 'orderSaleChannelVOS'
              }"
              change-on-select
              :options="buyChannel"
              placeholder="请选择销售渠道"
              @change="fromChange"
            />
          </a-form-model-item>
          <a-form-model-item label="订单状态" prop="status">
            <a-select v-model="searchForm.status" placeholder="请选择订单状态">
              <a-select-option value="">全部</a-select-option>
              <a-select-option
                v-for="(item, index) in status"
                :key="index"
                :value="item.value"
                >{{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="会员" prop="purchaserMember">
            <a-input
              v-model="searchForm.purchaserMember"
              placeholder="请输入会员编号或手机号"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="出游人" :colon="false">
            <a-input
              v-model="searchForm.visitors"
              placeholder="请输入姓名/手机号/身份证"
              allow-clear
            />
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false">
            <a-button type="primary" class="mr-2x" @click="reload"
              >查询</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
    </div>

    <section class="main mt-2x">
      <!--表格-->
      <order-list-table
        :orderType="searchForm.orderType"
        :table-data="tableData"
        :is-settle-accounts="true"
        @refunded="loadData"
      ></order-list-table>

      <!--分页-->
      <div class="flex-end p-2x">
        <a-pagination
          v-model="searchForm.page"
          :default-page-size="searchForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { orderWindoworder } from "@/api/search";
import { dataDictionary } from "@/api/setting";
import moment from "moment";
import { changFouce } from "@/utils/global";
import OrderListTable from "./components/OrderListTable";
import { orderSaleChannel } from "../../../api/merchant";
import { payWayForOrderQuery } from "@/api/pay";

export default {
  name: "SettleAccounts",
  components: { OrderListTable },
  data() {
    return {
      moment,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      dateRange: [moment(), moment()],
      searchForm: {
        page: 1,
        size: 10,
        orderBeginTime: moment()
          .startOf("date")
          .valueOf(),
        orderEndTime: moment()
          .endOf("date")
          .valueOf(),
        orderId: "", // 总单号或外部订单号
        buyChannel: "", // 销售渠道
        status: "", //订单状态
        payWay: "", // 支付方式
        payChannels: "", //支付通道
        purchaserMember: "", // 会员编号或手机号
        productCategoryId: "", //产品业态
        merchantId: "", //商户
        salespersonId: "", //销售员
        visitors: "", // 出游人
        orderType: 1, //订单类型，查询用
        salespersonIds: [] //待删除
      },
      visibleSearch: false,
      buyChannel: [], // 销售渠道
      paymentType: [], // 支付方式
      status: [], // 订单状态
      // 订单表格
      total: 0, // 总条数
      tableData: []
    };
  },
  created() {
    this.loadPayWay(); // 获取支付方式
    this.loadKeys(); // 获取订单状态
    this.loadSaleChannel(); // 获取销售渠道
    changFouce(this, "orderListRef");
  },
  methods: {
    //查询支付方式
    loadPayWay() {
      payWayForOrderQuery()
        .then(res => {
          // 过滤出只有交账
          if (res) {
            this.paymentType =
              res.filter(item => item.payWayCode == "OnCredit") || [];
            // this.searchForm.payWay = "";
            this.searchForm.payWay = this.paymentType[0].payWayQuery;
            this.loadData();
          }
        })
        .catch(() => {});
    },
    // 字典数据 ：payWay 支付方式旧的已废弃
    loadKeys() {
      dataDictionary({
        keys: "sales_channel,orderStatus"
      })
        .then(data => {
          this.status = data.orderStatus;
        })
        .catch(() => {});
    },
    // 加载销售渠道
    loadSaleChannel() {
      orderSaleChannel()
        .then(res => {
          this.buyChannel = res;
        })
        .catch(function() {});
    },
    // 加载table数据
    loadData() {
      const obj = { ...this.searchForm };
      obj.salespersonIds = obj.salespersonIds.join(",");
      orderWindoworder(obj)
        .then(({ records, total }) => {
          this.total = total;
          this.tableData = [];
          this.tableData = JSON.parse(JSON.stringify(records));
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.searchForm.orderBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 查询
    reload() {
      this.visibleSearch = false;
      this.searchForm.page = 1;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.searchForm.orderBeginTime = moment()
        .startOf("date")
        .valueOf();
      this.searchForm.orderEndTime = moment()
        .endOf("date")
        .valueOf();
      this.searchForm.orderId = "";
      this.searchForm.buyChannel = "";
      // this.searchForm.payWay = "";
      this.searchForm.payChannels = "";
      this.searchForm.productCategoryId = "";
      this.searchForm.merchantId = "";
      this.searchForm.status = "";
      this.searchForm.purchaserMember = "";
      this.searchForm.salespersonId = "";
      this.searchForm.visitors = "";
      this.reload();
    },
    // 条数改变
    sizeChange(current, size) {
      this.searchForm.page = 1;
      this.searchForm.size = size;
      this.reload();
    },
    // 销售渠道选择
    fromChange(value, selectOptions) {
      this.searchForm.buyChannel = selectOptions[selectOptions.length - 1].id;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 220px;
}

/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
