<template>
  <div class="wrapper flex-between flex-col">
    <div class="main">
      <div class="flex-start container">
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link pr-2x" @click="e => e.preventDefault()">
            <span class="font-xxl bold dark pr-1x mb-2x">
              {{ subScenic.subScenicName }}</span
            >
            <span class="font-xl"><a-icon type="swap" />切换</span>
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-for="item in subScenicsList" :key="item.subScenicId">
              <a
                href="javascript:;"
                @click="setDefault(item)"
                class="flex-between"
              >
                <span class="bold">{{ item.subScenicName }}</span>
                <span
                  @click="setDefault(item)"
                  class="pl-1x"
                  style="float:right"
                  >设为默认</span
                ></a
              >
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <section class="flex-grow container">
        <div class="flex-start flex-wrap align-center" style="line-height: 2.5">
          <div>
            <span class="mh-2x font-xl">使用日期</span>
            <a-date-picker
              v-model="playDate"
              :disabled-date="disabledDate"
              @change="changePlayDate"
              size="large"
              placeholder="选择游玩日期"
              class="form-item-width"
            />
            <span class="mh-2x font-xl">使用时段</span>
            <a-select
              v-if="playTimes.length > 0"
              v-model="playTime"
              @change="selectPlayTime"
              placeholder="选择游玩时段"
              size="large"
              class="form-item-width"
            >
              <a-select-option
                v-for="item in playTimes"
                :key="item.id"
                :value="item.time"
              >
                {{ item.time }}
              </a-select-option>
            </a-select>
            <span v-else>
              <a-time-picker format="HH:mm" @change="changeStartTime" /> -
              <a-time-picker format="HH:mm" @change="changeEndTime" />
            </span>
            <span v-if="nowPlayTimeInfo.id" class="ml-1x"
              >剩余库存：{{ nowPlayTimeInfo.remainingStock }}</span
            >
          </div>
        </div>
        <!-- 游客信息 -->
        <tourist
          v-if="subScenic.subScenicId"
          :play-date="playDate"
          :play-time="playTime"
          ref="myTourist"
          :subScenic="subScenic"
          :subscenicProductList="subscenicProductList"
          @changeOrderInfo="changeOrderInfo"
          @changeTouristSumData="changeTouristSumData"
        ></tourist>
        <h3 class="title-mid mv-1x mt-3x">订单信息</h3>
        <div class="bd round p-2x font-xl">
          <!-- 个人下单 -->
          <a-row type="flex">
            <a-col class="flex-start">
              <span>游客：</span>
              <div class="red">{{ orderInfo.sumNum || 0 }}人</div>
            </a-col>
          </a-row>
          <div class="mt-3x flex-start">
            <span style="width:120px">订单总金额：</span>
            <div class="red">
              {{ orderInfo.actualSum || 0 | money }}
            </div>
          </div>
          <div class="mt-3x flex-start flex-wrap">
            <p v-for="(item, idx) in touristSumDataTotal" :key="idx">
              <span
                >{{ item.productName }}: {{ item.sumNum }} 张, 共
                {{ item.sumPrice | money }}元；</span
              >
            </p>
          </div>
        </div>
      </section>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <a-button
        class="btn-qp mr-4x"
        size="large"
        shape="round"
        @click="$router.push('/home/ticket/GetTicket')"
      >
        取票
      </a-button>
      <a-button
        class="btn-lg"
        type="primary"
        shape="round"
        size="large"
        @click="submitOrder"
        >提交订单</a-button
      >
    </div>
  </div>
</template>

<script>
import { findUserPageList } from "@/api/user";
import { subScenics } from "@/api/merchant.js";
import { subscenicProducts, appointmentStockDate } from "@/api/product.js";
import { fastOrder } from "@/api/cart.js";
import moment from "moment";
import tourist from "./componets/tourist.vue";
export default {
  name: "PlaceOrder",
  components: { tourist },
  data() {
    return {
      subScenic: { subScenicId: "", subScenicName: "" }, //当前已选子景区
      subScenicId: "", //子景区id
      subScenicsList: [], //子景区列表
      moment,
      playDate: "", //游玩日期
      playTime: undefined,
      playTimes: [
        // "08:00-12:00",
        // "09:00-13:00",
        // "09:30-12:59",
        // "13:00-16:00",
        // "12:00-14:00"
      ], //游玩时段
      subscenicProductList: [], //子景区产品列表
      orderInfo: {},
      userName: "",
      user: {},
      users: [],
      page: 1,
      size: 1000,
      nowPlayTimeInfo: {}, //当前选择时段库存信息
      touristSumData: [], // 已选中产品列表
      touristSumDataTotal: [] // 已选中产品列表total总金额合计
    };
  },
  beforeDestroy() {
    window.readIdCard = () => {};
  },
  created() {
    this.playDate = moment(moment().format("YYYY-MM-DD"));
    this.subScenic = localStorage.getItem("placeSubScenicFlying")
      ? JSON.parse(localStorage.getItem("placeSubScenicFlying"))
      : {}; //默认子景区
    this.querySubScenics();
    this.querySubscenicProducts();
  },
  methods: {
    //选择时段
    selectPlayTime(val) {
      this.nowPlayTimeInfo = this.playTimes.find(item => item.time == val);
    },
    // 提交订单
    submitOrder() {
      if (!this.playDate) {
        this.$message.warning("请选择使用日期！");
        return;
      }
      if (!this.touristSumData || this.touristSumData.length < 1) {
        this.$message.warning("请添加游客！");
        return;
      }
      //游客数据
      let touristList = this.touristSumData.map(item => {
        return {
          name: item.userName,
          phone: item.phone,
          idCard: item.idCard,
          visitorTypeId: item.touristCategoryId,
          productId: item.sybSystemIdProductId,
          buyNum: item.sumNum
        };
      });
      let playTimeStart = this.nowPlayTimeInfo.startPlayTime;
      let playTimeEnd = this.nowPlayTimeInfo.endPlayTime;
      if (this.playTimes.length > 0) {
        if (!this.playTime) {
          this.$message.warning("请选择时间段！");
          return;
        }
        //有景区时段
        playTimeStart = this.playTime.split("-")[0];
        playTimeEnd = this.playTime.split("-")[1];
      } else {
        if (!playTimeStart || !playTimeEnd) {
          this.$message.warning("请选择时间段！");
          return;
        }
      }
      this.playDate = moment(this.playDate).format("YYYY-MM-DD");
      let data = {
        subScenicId: this.subScenic.subScenicId,
        // groupTypeId: this.groupType.selectGroupId, //团体分类的id
        // guideIds: this.guideCheckData.map(item => {
        //   return item.id;
        // }), //导游id 的数组
        // guideProductId: this.groupType.guideProductId, //导游票种 团体默认的可以改
        playDate: this.playDate, //游玩日期
        // playTime: "14:30",
        playTimeStart: playTimeStart,
        playTimeEnd: playTimeEnd,
        timeShareAppointmentRuleId: this.nowPlayTimeInfo
          .timeShareAppointmentRuleId, //时段规则id
        timeIntervalId: this.nowPlayTimeInfo.id, //时段id
        productTravelerList: touristList
      };
      fastOrder(data).then(res => {
        this.$store.dispatch("order/setCartList", { id: res.id });
        this.$router.push("/home/sell/OrderInfo");
      });
    },
    changeStartTime(val) {
      this.nowPlayTimeInfo.startPlayTime = moment(val).format("HH:mm");
    },
    changeEndTime(val) {
      this.nowPlayTimeInfo.endPlayTime = moment(val).format("HH:mm");
    },
    changePlayDate() {
      this.playDate = moment(this.playDate).format("YYYY-MM-DD");
      this.queryappointmentStockDate(this.playDate);
    },
    //获取时段
    queryappointmentStockDate(time) {
      appointmentStockDate({
        subScenicId: this.subScenic.subScenicId,
        date: time
      }).then(res => {
        if (res.length > 0) {
          let arr = [];
          let nowtime = new Date().getTime();
          let time = "";
          res.forEach(item => {
            let obj = {
              id: item.id,
              timeShareAppointmentRuleId: item.ruleId,
              time: `${moment(item.beginTime).format("HH:mm")}-${moment(
                item.endTime
              ).format("HH:mm")}`,
              remainingStock: item.remainingStock
            };
            arr.push(obj);
            if (item.beginTime <= nowtime && nowtime <= item.endTime) {
              time = obj.time;
            }
          });
          this.playTimes = arr;
          if (arr.length > 0 && time) {
            this.selectPlayTime(time);
            this.playTime = time;
          }
        }
      });
    },
    //查询用户列表
    queryUsers() {
      findUserPageList({
        page: this.page,
        size: this.size,
        userName: this.userName
      }).then(res => {
        this.users = res.records;
        this.pages = res.pages;
      });
    },
    //订单信息
    changeOrderInfo(val) {
      this.orderInfo = val;
    },
    changeTouristSumData(val) {
      let arr = [];
      this.touristSumData = val;
      this.touristSumData.forEach(item => {
        if (!arr.length) {
          arr.push({
            productName: item.productName,
            sumPrice: item.sumPrice,
            sumNum: item.sumNum
          });
        } else {
          let i = arr.findIndex(tem => tem.productName === item.productName);
          if (i !== -1) {
            arr[i].sumPrice += item.sumPrice;
            arr[i].sumNum += item.sumNum;
          } else {
            arr.push({
              productName: item.productName,
              sumPrice: item.sumPrice,
              sumNum: item.sumNum
            });
          }
        }
      });
      this.touristSumDataTotal = arr;
    },

    //不能选过去的日期
    disabledDate(current) {
      return current && current < moment().startOf("day");
    },

    //选择子景区
    checkSubScenics(subScenic) {
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
    },
    //设置为默认子景区
    setDefault(subScenic) {
      localStorage.setItem("placeSubScenicFlying", JSON.stringify(subScenic));
      this.subScenic = subScenic;
      this.querySubscenicProducts();
      this.queryappointmentStockDate(
        moment(this.playDate).format("YYYY-MM-DD")
      ); //获取时段
      this.$nextTick(() => {
        this.$refs.myTourist.getProductList();
      });
    },
    //子景区列表
    querySubScenics() {
      subScenics({ filterSubScenic: true }).then(res => {
        this.subScenicsList = res.subScenics;
        let find = res.subScenics.find(
          item => item.subScenicId === this.subScenic.subScenicId
        );
        if (!find) {
          //子景区默认设置第一个
          this.subScenic = res.subScenics[0];
          localStorage.setItem(
            "placeSubScenicFlying",
            JSON.stringify(res.subScenics[0])
          );
        }
        this.queryappointmentStockDate(
          moment(this.playDate).format("YYYY-MM-DD")
        ); //获取时段
      });
    },
    //子景区产品列表
    querySubscenicProducts() {
      subscenicProducts().then(res => {
        res.forEach(item => {
          if (item.subScenicId == this.subScenic.subScenicId) {
            //该子景区下的产品
            this.subscenicProductList = item.skuList;
          }
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.addTouristBtn {
  background-color: #30acf3;
  border-color: #30acf3;
}
.a-modal__body .main {
  width: 95%;
  margin: 0 auto;
}
.guide-box {
  height: 300px;
  overflow: auto;
}
.form-item-width {
  width: 170px;
}
.btn-qp {
  min-width: 170px;
}
</style>
