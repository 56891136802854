var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.vis,"title":"激活","width":"880px","mask-closable":false},on:{"cancel":_vm.close}},[_c('a-form-model',{ref:"formData",attrs:{"model":_vm.formData,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('h4',{staticClass:"title-mid"},[_vm._v("游客信息")]),_c('a-row',[_vm._l((_vm.formData.visitorFieldList),function(temp,idx){return [(temp.fieldType === 1)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入内容',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkText
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"type":"tel","placeholder":"请输入内容","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 2)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请选择日期',
                  trigger: 'change'
                }
              ]}},[_c('a-date-picker',{attrs:{"disabled":temp.disabled,"valueFormat":"YYYY-MM-DD"},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 3)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: ("请选择" + (temp.fieldName)),
                  trigger: 'change'
                }
              ]}},[_c('a-select',{staticClass:"res-w",attrs:{"disabled":temp.disabled,"placeholder":"请选择"},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}},_vm._l((temp.fieldOptions),function(val,i){return _c('a-select-option',{key:'o' + i,attrs:{"value":val}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1)],1):(temp.fieldType === 4)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入内容',
                  trigger: 'blur'
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入内容","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 5)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入手机号',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkPhone
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"type":"tel","placeholder":"请输入内容","allow-clear":""},on:{"blur":function($event){return _vm.blurChange(temp, ("visitorFieldList." + idx + ".fieldValue"))}},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 7)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请选择日期',
                  trigger: 'change'
                }
              ]}},[_c('a-date-picker',{attrs:{"disabled":temp.disabled,"valueFormat":"YYYY-MM-DD"},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 8)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请选择性别',
                  trigger: 'change'
                }
              ]}},[_c('a-select',{attrs:{"disabled":temp.disabled,"placeholder":"请选择"},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}},_vm._l((temp.fieldOptions),function(val,i){return _c('a-select-option',{key:'s' + i,attrs:{"value":val}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1)],1):(temp.fieldType === 9)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请上传证件照',
                  trigger: 'change'
                }
              ]}},[_c('picture-input',{staticClass:"m-x",attrs:{"small":true},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 10)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入姓名',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkText
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入姓名","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 11)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入车牌号',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkText
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入车牌号","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 12)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入地址',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkText
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入地址","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 13)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: '请输入邮箱',
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkEmail
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入邮箱","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 14)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName,"prop":("visitorFieldList." + idx + ".fieldValue"),"rules":[
                {
                  required: temp.required,
                  message: ("请输入" + (temp.fieldName)),
                  trigger: 'blur'
                },
                {
                  validator: _vm.checkText
                }
              ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"disabled":temp.disabled,"placeholder":"请输入微信openId","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1)],1):(temp.fieldType === 16)?_c('a-col',{key:'v' + idx,attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":temp.fieldName}},[_c('a-select',{attrs:{"disabled":temp.disabled,"placeholder":"请选择国家"},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}},_vm._l((temp.fieldOptions),function(val,i){return _c('a-select-option',{key:'c' + i,attrs:{"value":val}},[_vm._v(" "+_vm._s(val)+" ")])}),1)],1)],1):_vm._e()]}),(_vm.formData.requiredVoucherNo)?_c('a-col',{attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":"卡腕带","prop":"voucherNo","rules":[
              {
                required: true,
                message: '请输入卡腕带号',
                trigger: 'blur'
              }
            ]}},[_c('IcCardInput',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"allow-clear":false,"placeholder":"请输入卡/腕带编号"},model:{value:(_vm.formData.voucherNo),callback:function ($$v) {_vm.$set(_vm.formData, "voucherNo", $$v)},expression:"formData.voucherNo"}})],1)],1):_vm._e(),(_vm.formData.requiredPersonId)?_c('a-col',{attrs:{"span":"8"}},[_c('a-form-model-item',{staticClass:"res-w",attrs:{"label":"人脸","prop":"voucherNo","rules":[
              {
                required: _vm.formData.requiredPersonTrue === 1 ? true : false,
                message: '请录入人脸',
                trigger: 'blur'
              }
            ]}},[_c('face-input',{attrs:{"small":true,"echoimgurl":_vm.formData.personFaceUrl},on:{"imgUrlChange":function (res) { return _vm.faceChange(res); }},model:{value:(_vm.formData.personId),callback:function ($$v) {_vm.$set(_vm.formData, "personId", $$v)},expression:"formData.personId"}})],1)],1):_vm._e(),(_vm.formData.IdTypeList.length)?_c('a-col',{attrs:{"span":"24"}},[_c('a-row',{attrs:{"gutter":10}},[_c('a-col',{staticStyle:{"margin-top":"4px"},attrs:{"span":"5"}},[_c('a-select',{model:{value:(_vm.formData.IdType),callback:function ($$v) {_vm.$set(_vm.formData, "IdType", $$v)},expression:"formData.IdType"}},_vm._l((_vm.formData.IdTypeList),function(val){return _c('a-select-option',{key:val.fieldType,attrs:{"value":val.fieldType}},[_vm._v(_vm._s(val.fieldName))])}),1)],1),_c('a-col',{attrs:{"span":"8"}},_vm._l((_vm.formData.IdTypeList),function(temp,idx){return _c('section',{key:'i' + idx,staticClass:"section"},[(temp.fieldType === 6 && _vm.formData.IdType === 6)?_c('a-form-model-item',{staticClass:"res-w",attrs:{"prop":("IdTypeList." + idx + ".fieldValue"),"rules":[
                    {
                      required: false,
                      message: '请输入身份证号',
                      trigger: 'blur'
                    },
                    {
                      validator: _vm.checkIdCard
                    }
                  ]}},[_c('IdCardInput',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],staticStyle:{"width":"100%"},on:{"readCard":function($event){return _vm.readCard($event)},"blur":function($event){return _vm.blurChange(temp, ("IdTypeList." + idx + ".fieldValue"))}},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1):(temp.fieldType === 21 && _vm.formData.IdType === 21)?_c('a-form-model-item',{staticClass:"res-w",attrs:{"prop":("IdTypeList." + idx + ".fieldValue"),"rules":[
                    {
                      required: false,
                      message: '请输入身份证号',
                      trigger: 'blur'
                    },
                    {
                      validator: _vm.checkIdCard
                    }
                  ]}},[_c('IdCardInput',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1):(temp.fieldType === 17 && _vm.formData.IdType === 17)?_c('a-form-model-item',{staticClass:"res-w",attrs:{"prop":("IdTypeList." + idx + ".fieldValue"),"rules":[
                    {
                      required: false,
                      message: '请输入证件号',
                      trigger: 'blur'
                    },
                    {
                      validator: _vm.checkText
                    }
                  ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],key:'i' + idx,staticClass:"res-w",attrs:{"placeholder":"请输入证件号","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1):(temp.fieldType === 18 && _vm.formData.IdType === 18)?_c('a-form-model-item',{staticClass:"res-w",attrs:{"prop":("IdTypeList." + idx + ".fieldValue"),"rules":[
                    {
                      required: false,
                      message: '请输入证件号',
                      trigger: 'blur'
                    },
                    {
                      validator: _vm.checkText
                    }
                  ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"placeholder":"请输入证件号","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1):(temp.fieldType === 19 && _vm.formData.IdType === 19)?_c('a-form-model-item',{staticClass:"res-w",attrs:{"prop":("IdTypeList." + idx + ".fieldValue"),"rules":[
                    {
                      required: false,
                      message: '请输入证件号',
                      trigger: 'blur'
                    },
                    {
                      validator: _vm.checkText
                    }
                  ]}},[_c('a-input',{directives:[{name:"inputfocus",rawName:"v-inputfocus"}],attrs:{"placeholder":"请输入证件号","allow-clear":""},model:{value:(temp.fieldValue),callback:function ($$v) {_vm.$set(temp, "fieldValue", $$v)},expression:"temp.fieldValue"}})],1):_vm._e()],1)}),0)],1)],1):_vm._e()],2)],1),_c('div',{staticClass:"flex-end",attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{key:"cancel",on:{"click":_vm.close}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save('formData')}}},[_vm._v("激活")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }