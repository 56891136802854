<!--
  @name: 查询到的会员信息卡片
-->
<template>
  <div
    class="info-card flex-center"
    :class="{ infoActive: isChecked }"
    @click="infoCardClick"
  >
    <div class="flex-start align-center">
      <div class="mr-2x">
        <p class="font-l bold">{{ memberInfo.name }}</p>
        <p class="gray-text font-s text-1" style="width:106px;">
          {{ memberInfo.currentLevelName }}
        </p>
        <p class="gray-text font-s" v-if="memberInfo.mobile">
          手机号：{{
            memberInfo.mobile.substr(0, 3) +
              "****" +
              memberInfo.mobile.substr(7, 11)
          }}
        </p>
        <p class="bold font-xl ">
          <span v-if="!memberInfo.memberLevelRate">无折扣</span>
          <span v-else>消费{{ memberInfo.memberLevelRate / 10 }}折</span>
        </p>
        <div
          class="change-member gray-text font-s mt-1x pointer"
          @click="changeMember"
        >
          更换会员
        </div>
      </div>
      <a-avatar
        :size="86"
        :src="
          memberInfo.photo ||
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
        "
      />
    </div>
    <div class="checked" v-if="isChecked">
      <a-icon type="check" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "topMemberInfo",
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("common", ["memberInfo"])
  },
  methods: {
    ...mapActions("common", ["updateMember"]),
    changeMember() {
      this.updateMember({ id: "" });
      this.$store.commit("order/clearDiscount");
      this.$emit("changeMember");
    },
    infoCardClick() {
      if (!this.memberInfo.memberLevelRate) {
        this.$message.warning("该会员暂无折扣!");
        return;
      }
      this.$emit("handleClick");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
/*会员信息卡片*/
.info-card {
  cursor: pointer;
  position: relative;
  width: 250px;
  height: 146px;
  background: #ffffff;
  border-radius: 14px;
  margin-right: 24px;
  overflow: hidden;
  background: url("../../../../assets/images/ka1.png") no-repeat center center;
  background-size: cover;

  .change-member {
    width: 70px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #535353;
    border-radius: 12px;
  }

  .gray-text {
    color: #666;
  }
}

.infoActive {
  box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
  border: 1px solid @primary;
}

.info-card:hover {
  box-shadow: 0 0 9px 0 rgba(116, 116, 116, 0.22);
  border: 1px solid @primary;
}

.checked {
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  font-size: 22px;
  color: @primary;
}
</style>
