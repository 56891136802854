<template>
  <div class="wrapper flex-start flex-col">
    <!-- 搜索组件 -->
    <SearchTool
      ref="SearchTool"
      :filters="isfilters"
      :tabActive="tabActive"
      @changeSearchTab="changeSearchTab"
      @loadData="loadData"
    >
    </SearchTool>
    <div class="main mt-2x">
      <currency-table
        :order-begin-time="formData.orderBeginTime"
        :order-end-time="formData.orderEndTime"
        :filter-api="orderRefundRecordCondition"
        :columns="filterColumns"
        :data-source="tableData.data"
        :check-searchs.sync="checkSearchs"
        @filter-change="filterChange"
      >
      </currency-table>
      <div class="p-2x flex-between">
        <div class="flex-start">
          <span class="bold flex-shrink">汇总：</span>
          <span class="flex-shrink">总退款额：</span>
          <span class="red flex-shrink">{{ amountSum | money("") }}元</span>
          <span class="ml-1x gray" v-if="payWayInfo">({{ payWayInfo }})</span>
          <div class="mh-3x flex-shrink">
            退款总单品数：<span>{{ tableData.total }} 件</span>
          </div>
        </div>
        <div class="flex-shrink">
          <a-pagination
            v-model="tableData.page"
            :default-page-size="tableData.size"
            :total="tableData.total"
            :page-size-options="['10', '20', '50', '100']"
            show-size-changer
            @change="pageChange"
            @showSizeChange="sizeChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchTool from "./components/SearchTool";
import {
  orderRefundmanagement,
  orderRefundRecordCondition
} from "@/api/search";
import CurrencyTable from "@/components/table/CurrencyTable";
import moment from "moment";
export default {
  name: "RefundRecordList",
  components: {
    SearchTool,
    CurrencyTable
  },
  data() {
    return {
      orderRefundRecordCondition,
      moment,
      amountSum: 0,
      payWayInfo: "",
      isfilters: [],
      tabActive: 0,
      checkSearchs: [], // 表格头过滤后选中的列表数组
      formData: {}, // 搜索表单
      filtersList: [
        {
          label: "退款时间",
          key: "refundCompleteTime",
          value: [moment(), moment()],
          type: "time",
          placeholder: ""
        },
        {
          label: "发起退款时间",
          labelWidth: "100px",
          key: "refundApplyTime",
          value: "",
          type: "time",
          placeholder: ""
        },
        {
          label: "退款审核时间",
          labelWidth: "100px",
          key: "refundAuditTime",
          value: "",
          type: "time",
          placeholder: ""
        },
        {
          label: "",
          key: "subFilters",
          value: "",
          type: "subFilters",
          placeholder: "单号查询"
        }
      ], //搜索条件
      tableData: {
        data: [],
        columns: [
          {
            key: "productId",
            title: "单品编号（票号）",
            show: true,
            width: 200,
            fixed: "left"
          },
          {
            key: "productName",
            title: "单品名称",
            show: true,
            width: 180,
            ellipsis: true
          },
          {
            key: "orderId",
            title: "销售总单号",
            show: true,
            width: 200
          },
          {
            key: "parentId",
            title: "退款单号",
            show: true,
            width: 200
          },
          {
            key: "originalReceiveAmount",
            title: "产品实收金额",
            primary: "red",
            show: true,
            width: 150,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "refundIntegralDeductionAmount",
            title: "退积分数量",
            isMoney: false,
            show: true,
            width: 120
          },
          {
            key: "refundAmount",
            title: "实退金额",
            show: true,
            width: 120,
            scopedSlots: { customRender: "money" }
          },
          {
            key: "refundWayName",
            title: "退款方式名称",
            show: true,
            width: 150
          },
          {
            key: "refundChannelsText",
            title: "退款渠道",
            show: true,
            width: 135
          },
          {
            key: "refundApplyTime",
            title: "发起退款时间",
            show: true,
            width: 170,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "refundAuditTime",
            title: "退款审核时间",
            show: true,
            width: 170,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "refundAuditStatusText",
            title: "退款审核状态",
            show: true,
            width: 150,
            filters: true, // 开启表格头部过滤
            filterKey: "refundAuditStatus",
            fieldList: []
          },
          {
            key: "refundAuditPersonName",
            title: "退款审核操作员",
            show: true,
            width: 150,
            filters: true, // 开启表格头部过滤
            filterKey: "refundAuditPerson",
            fieldList: []
          },
          {
            key: "refundCompleteTime",
            title: "退款时间",
            show: true,
            width: 170,
            scopedSlots: { customRender: "date" }
          },
          {
            key: "refundStatusText",
            title: "退款状态",
            show: true,
            width: 120,
            filters: true, // 开启表格头部过滤
            filterKey: "refundStatus",
            fieldList: []
          },
          {
            key: "refundApplyPersonName",
            title: "退款发起人",
            show: true,
            width: 120
          },
          {
            key: "refundOutsideCode",
            title: "外部退款号",
            show: true,
            width: 180
          },
          {
            key: "remarks",
            title: "退款备注",
            show: true,
            width: 120,
            ellipsis: true,
            fixed: "right"
          }
        ],
        multipleSelection: [],
        // multiple:false,
        total: 1,
        page: 1,
        size: 10
      }
    };
  },
  computed: {
    filterColumns() {
      return this.tableData.columns.filter(column => column.show);
    }
  },
  mounted() {
    this.changeSearchTab(0);
    this.$nextTick(() => {
      this.$refs.SearchTool.loadData();
    });
  },
  methods: {
    pageChange(current) {
      this.tableData.page = current;
      this.getDataList();
    },
    // 条数改变
    sizeChange(current, size) {
      this.tableData.page = current;
      this.tableData.size = size;
      this.getDataList();
    },
    // 表格过滤触发的时间
    filterChange() {
      this.tableData.page = 1;
      this.getDataList();
    },
    loadData(val) {
      this.tableData.columns.forEach(item => {
        if (item.fieldList && item.fieldList.length) {
          item.fieldList = [];
        }
      });
      this.checkSearchs = [];
      this.tableData.page = 1;
      // //0时间 1单号
      this.formData = {};
      if (this.tabActive) {
        this.formData.keyword = val.subFilters;
      } else {
        //发起退款时间
        if (val.refundApplyTime) {
          this.formData.refundApplyBeginTime = val.refundApplyTime.length
            ? moment(val.refundApplyTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.refundApplyEndTime = val.refundApplyTime.length
            ? moment(val.refundApplyTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        if (val.refundAuditTime) {
          // 退款审核时间
          this.formData.refundAuditBeginTime = val.refundAuditTime.length
            ? moment(val.refundAuditTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.refundAuditEndTime = val.refundAuditTime.length
            ? moment(val.refundAuditTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
        if (val.refundCompleteTime) {
          // 退款时间
          this.formData.refundCompleteBeginTime = val.refundCompleteTime.length
            ? moment(val.refundCompleteTime[0])
                .startOf("date")
                .valueOf()
            : "";
          this.formData.refundCompleteEndTime = val.refundCompleteTime.length
            ? moment(val.refundCompleteTime[1])
                .endOf("date")
                .valueOf()
            : "";
        }
      }
      this.getDataList();
    },
    getDataList() {
      let refundAuditStatus = "";
      let refundAuditPerson = "";
      let refundStatus = "";
      if (this.checkSearchs.length) {
        this.checkSearchs.forEach(item => {
          switch (item.column.filterKey) {
            case "refundAuditStatus":
              refundAuditStatus = item.tagList.map(tem => tem.key).join(",");
              break;
            case "refundAuditPerson":
              refundAuditPerson = item.tagList.map(tem => tem.key).join(",");
              break;
            case "refundStatus":
              refundStatus = item.tagList.map(tem => tem.key).join(",");
              break;
          }
        });
      }
      orderRefundmanagement({
        page: this.tableData.page,
        size: this.tableData.size,
        refundAuditStatus,
        refundAuditPerson,
        refundStatus,
        ...this.formData
      }).then(res => {
        this.tableData.data = res.records;
        this.amountSum = res.actualAmountSum || 0;
        this.payWayInfo = res.payWay;
        this.tableData.total = res.total;
      });
    },
    changeSearchTab(value) {
      this.tabActive = value;
      if (value) {
        //单号
        this.isfilters = this.filtersList.filter(
          item => item.type === "subFilters"
        );
      } else {
        //时间
        this.isfilters = this.filtersList.filter(item => item.type === "time");
      }
    }
  }
};
</script>

<style scoped></style>
