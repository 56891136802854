<template>
  <div
    class="product-list flex-col flex-between"
    @scroll.passive="onScroll($event)"
  >
    <!-- 产品详情列表 -->
    <ul class="ul-wrap">
      <li
        class="align-center bd-b p-2x"
        v-for="(item, index) in liData"
        :key="index"
      >
        <div class="flex-between align-center">
          <!--单规格-->
          <div class="flex-start" v-if="item.whetherMultiSpec === 0">
            <img class="product-img" :src="item.skuImages" alt="产品图片" />
            <div class="ml-2x">
              <h3>{{ item.name }}</h3>
              <p class="mv-1x flex-start align-center">
                <span class="red font-xxl" style="width: 120px;">{{
                  item.sellingPrice | money
                }}</span>
                <span class=" prompt-btn ml-1x">加购前须填写游客信息 </span>
              </p>
            </div>
          </div>
          <!--多规格-->
          <div class="flex-start" v-else>
            <img
              class="product-img"
              :src="item.specCombination[item.index].skuImages"
              alt="产品图片"
            />
            <div class="ml-2x">
              <h3>
                {{ item.name }} - {{ item.specCombination[item.index].name }}
              </h3>
              <p class="mv-1x flex-start align-center">
                <span class="red font-xxl" style="width: 120px;">{{
                  item.specCombination[item.index].sellingPrice | money
                }}</span>
                <span class="prompt-btn ml-1x">加购前须填写游客信息</span>
              </p>
              <div class="tag-wrap flex-start flex-wrap">
                <a-button
                  class="mr-2x mv-1x"
                  v-for="(tag, k) in item.specCombination"
                  :key="k"
                  :type="item.index === k ? 'primary' : 'default'"
                  @click="item.index = k"
                >
                  {{ tag.name }}
                </a-button>
              </div>
            </div>
          </div>
          <a-button
            class="align-end"
            type="primary"
            size="large"
            @click="addPlayer(item)"
          >
            加入购物车
          </a-button>
        </div>
        <div class="gray mt-2x text-1" v-if="item.touristCategoryName">
          游客类型限制：{{ item.touristCategoryName }}
          <!-- <span class="blue pointer">查看更多></span> -->
        </div>
      </li>
    </ul>
    <a-empty v-if="liData.length === 0" class="mt-4x" />
    <p class="text-c gray mv-2x" v-else-if="liData.length === total">
      我是有底线的~
    </p>
    <!-- 填写游客信息弹窗 -->
    <tourist-dialog
      :play-date="playDate"
      :vis.sync="visible"
      @change="surePlayer"
      ref="touristDialog"
    ></tourist-dialog>
  </div>
</template>
<script>
import { productFrontWindowTicketTwo } from "../../../api/search";
import { moreMultiCart, multiCart, visitorBuyLimit } from "../../../api/cart";
import TouristDialog from "../../combination-product/sell/components/TouristDialog.vue";
import { dateFormat } from "../../../utils/global";
import { newSelectTouristWindowInfoByProductId } from "@/api/member";

export default {
  components: { TouristDialog },
  name: "SaleDetail",
  props: {
    // 子景区id
    subScenicId: {
      type: String,
      default: ""
    },
    // 子景区id
    subScenicName: {
      type: String,
      default: ""
    },
    // 游玩日期
    playDate: {
      type: Number,
      default: 0
    },
    // 时段id
    timeData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 购物车id
    cartId: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      liData: [],
      visible: false,
      editPlayerItem: {} // 编辑的游客信息对象
    };
  },
  created() {
    // this.loadData();
  },
  // watch: {
  //   subScenicId() {
  //     this.page = 1;
  //     this.liData = [];
  //   }
  // },
  methods: {
    //查询游客类型
    queryTourist(productIds) {
      newSelectTouristWindowInfoByProductId({ productIds: productIds }).then(
        res => {
          res.forEach(item => {
            this.liData.forEach(ite => {
              if (item.productId == ite.id) {
                ite.touristCategoryName = item.memberTouristManagementVOList
                  ? item.memberTouristManagementVOList
                      .map(temp => {
                        return temp.touristCategoryName;
                      })
                      .join("，")
                  : "";
              }
            });
          });
          this.$forceUpdate();
        }
      );
    },
    // 重新加载
    reLoadData() {
      this.page = 1;
      this.total = 0;
      this.liData = [];
      this.loadData();
    },
    //加载产品数据
    loadData() {
      this.loading = true;
      productFrontWindowTicketTwo({
        page: this.page,
        size: this.size,
        subScenicId: this.subScenicId,
        playDate: dateFormat(this.playDate)
      })
        .then(res => {
          const liData = res.records.map(item => {
            // 添加游客信息字段
            item.player = {
              name: "",
              phone: "",
              idCard: "",
              touristId: ""
            };
            item.index = 0;
            return item;
          });
          let productIds = res.records.map(item => {
            return item.id;
          });
          this.total = res.total;
          this.queryTourist(productIds.toString());
          this.liData = this.liData.concat(liData);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    // 清空列表
    clearData() {
      this.page = 1;
      this.liData = [];
      this.total = 0;
    },
    // 添加游客
    addPlayer(item) {
      let orderInformationCollectionInfo = sessionStorage.getItem(
        "orderInformationCollectionInfo"
      );
      if (orderInformationCollectionInfo != "undefined") {
        this.editPlayerItem = item;
        this.$refs.touristDialog.queryTourist(item.id);
        this.visible = true;
      } else {
        this.addToCart(item); //直接加入购物车
      }
    },
    // 确认游客信息
    surePlayer(form) {
      this.editPlayerItem.player = { ...form };
      this.addToCart(this.editPlayerItem);
    },
    addToCart(item) {
      // 处理证件类型
      let id = "";
      let fieldValue = "";
      switch (item.player.cardType) {
        case "身份证":
          id = "1380460304389713921";
          fieldValue = "身份证";
          break;
        case "护照":
          id = "1468138752729358337";
          fieldValue = "护照";

          break;
        case "港澳通行证":
          id = "1467789074700779522";
          fieldValue = "港澳通行证";
          break;
        case "台胞证":
          id = "1467789116815106050";
          fieldValue = "台胞证";
          break;
      }

      let obj = {
        playDate: this.playDate,
        timeIntervalId: this.timeData.id,
        timeShareAppointmentRuleId: this.timeData.ruleId,
        // 多规格 item.specCombination === 1, 否则单规格
        productId: item.whetherMultiSpec
          ? item.specCombination[item.index].id
          : item.id,
        visitor: {
          visitorFieldList: [
            { id: "1380352491692818433", fieldValue: item.player.name }, // 姓名
            { id: "1380352491692818434", fieldValue: item.player.phone }, // 手机号
            { id: "1467786872876040193", fieldValue }, // 证件类型
            { id, fieldValue: item.player.idCard } // 证件号
          ],
          // touristId: item.player.touristId, // 游客类型
          visitorTypeId: item.player.visitorTypeId
        },
        subScenicId: this.subScenicId //子景区id 特惠产品要用到

        // ...item.player
      };
      let api = multiCart;
      if (this.cartId) {
        obj.id = this.cartId;
        api = moreMultiCart;
      }
      //购买数量限制
      visitorBuyLimit({
        id: this.cartId ? this.cartId : "",
        playDate: this.playDate,
        productTravelerList: [
          {
            productId: item.id,
            spuId: item.spuId,
            phone: item.player.phone,
            idCard: item.player.idCard,
            appointmentBeginTime: this.timeData.beginTime,
            appointmentEndTime: this.timeData.endTime
          }
        ]
      }).then(temp => {
        if (!temp || !temp.length) {
          //限制接口无返回则可加入购物车
          api(obj)
            .then(res => {
              this.$emit("update-cart", res.id);
              this.$message.success("添加成功");
            })
            .catch(() => {});
        } else {
          this.$message.warning("已被限制购买！");
        }
      });
    },
    // 返回
    back() {
      this.page = 1;
      this.liData = [];
      this.$emit("hand-back", "SaleList");
    },
    //产品下拉加载
    onScroll(e) {
      let { clientHeight, scrollTop, scrollHeight } = e.target;
      if (clientHeight + scrollTop === scrollHeight) {
        if (this.liData.length < this.total) {
          this.page++;
          this.loadData();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.product-list {
  height: 100%;
  overflow-x: auto;
}

.ul-wrap {
  .product-img {
    width: 116px;
    height: 116px;
    border-radius: 12px;
  }
  .prompt-btn {
    font-size: 12px;
    color: @primary;
  }
  .tag-wrap {
    p {
      width: 80px;
      padding: 6px 0;
      text-align: center;
      border-radius: 4px;
    }
  }
}
</style>
