<!--
  @name: 导游信息组件,
  @author: chenshiti,
  @attr: {
  },
  @methods{
    add：数据变动触发事件
    del：删除导游事件
  }
-->
<template>
  <div class="section">
    <div class="flex-between">
      <p class="font-20">导游信息</p>
      <div class="flex-grow flex-end search-box">
        <template>
          <a-input
            v-model="managementName"
            class="management-name"
            placeholder="请选择或填写团体名称"
            @change="onChangeManagementName"
            allow-clear
          >
            <span
              style="cursor: pointer;"
              slot="addonAfter"
              @click="onSelectManagement"
              >选择</span
            >
          </a-input>

          <a-select
            show-search
            v-inputfocus
            :filter-option="filterOption"
            placeholder="请选择团体分类"
            class="scenic-select"
            @change="changeTourist"
          >
            <a-select-option
              v-for="item in touristType"
              :key="item.id"
              :value="item.id"
              >{{ item.touristCategoryName }}</a-select-option
            >
          </a-select>
          <a-select
            show-search
            v-inputfocus
            :filter-option="filterOption"
            class="scenic-select last"
            v-model="guideProductId"
            placeholder="请选择导游票"
            @change="changeGuideProductId"
            :allowClear="true"
          >
            <a-select-option
              v-for="item in subscenicProductList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </div>

      <div>
        <a-button @click="toAddGuideShow" shape="round">添加导游</a-button>
        <a-button
          type="primary"
          plain
          ghost
          shape="round"
          icon="a-icon-plus"
          @click="selectGuide"
          class="ml-1x"
          >选择导游</a-button
        >
      </div>
    </div>
    <a-form-model ref="ruleForm" :rules="guideFormRules" class="mt-2x">
      <a-table
        :columns="guideCheckColumns"
        :data-source="guideCheckData"
        row-key="colId"
        :pagination="false"
      >
        <template
          v-for="(col, i) in guideCheckColumns"
          :slot="col.key"
          slot-scope="text, record"
        >
          <div :key="i">
            <template v-if="record.id">
              <span v-if="col.key === 'sex'">{{
                record[col.key] == 1 ? "女" : record[col.key] == 2 ? "男" : ""
              }}</span>

              <span v-else>{{ record[col.key] }}</span>
            </template>
            <!-- :prop="`guideCheckData[${index}].${col.key}`"
              :rules="[
                {
                  required: true,
                  message: `请输入${col.title}`,
                  trigger: 'blur'
                },
                {
                  validator: col.key === 'phone' ? checkPhone : ''
                }
              ]" -->
            <a-form-model-item v-else style="margin-bottom:0">
              <a-input
                v-if="col.key !== 'sex'"
                v-inputfocus
                v-model="record[col.key]"
                @change="e => inputChange(e.target.value, col.key)"
                :placeholder="`输入${col.title}`"
                allowClear
              />
              <a-select
                v-else
                :default-value="record[col.key]"
                v-model="record[col.key]"
                placeholder="选择性别"
              >
                <a-select-option
                  v-for="item in sexs"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
        </template>
        <template slot="action" slot-scope="record">
          <a-button
            v-if="!record.id"
            @click="saveGuide(record)"
            type="link"
            class="blue"
            >确定</a-button
          >
          <a-button @click="delGuide(record)" type="link" class="blue"
            >删除</a-button
          >
        </template>
      </a-table>
    </a-form-model>
    <!-- 选择导游 -->
    <a-modal
      title="选择导游"
      :visible="selectGuideShow"
      center
      width="500px"
      @ok="handleCancle"
      @cancel="handleCancle"
    >
      <div class="guide-box mt-2x">
        <a-row
          type="flex"
          v-for="item in guideDatas"
          :key="item.id"
          class="pv-2x bd-t"
        >
          <a-col :span="5">{{ item.name }}</a-col>
          <a-col :span="15">手机号：{{ item.phone }}</a-col>
          <a-col :span="4"
            ><a-checkbox
              class="res"
              :label="item.id"
              v-model="item.check"
              @change="checkGuide"
            >
              选择
            </a-checkbox></a-col
          >
        </a-row>
      </div>
    </a-modal>

    <!-- 选择团体/旅行社 -->
    <a-modal
      title="选择团体/旅行社"
      :visible="selectManagementShow"
      center
      width="500px"
      @ok="onConfirmManagementModal"
      @cancel="onCancleManagementModal"
    >
      <div class="guide-box mt-2x">
        <a-row
          type="flex"
          v-for="item in managementList"
          :key="item.distributorId"
          class="pv-2x bd-t"
        >
          <a-col :span="20">
            {{ item.distributorName }}
          </a-col>
          <a-col :span="4">
            <a-button @click="onSelectDistributor(item)" shape="round"
              >选择</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { guideList, guide } from "@/api/user";
import { merchantGroupList } from "../../api/merchant";
import { selectTouristManagementInfo } from "../../api/member";
import { subscenicProducts } from "../../api/product";
import { checkIdCard, checkPhone, checkText } from "@/utils/global";
export default {
  name: "ProductGuide",
  props: {
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "" // 子景区id
        };
      }
    }
  },
  watch: {
    params: {
      handler(params) {
        if (params) {
          this.loadScenics(); // 获取选中的子景区
        }
      },
      deep: true // 深度监听父组件传过来对象变化
    }
  },
  data() {
    return {
      addGuideShow: false,
      guideDatas: [], //导游列表
      guideCheckColumns: [
        {
          title: "导游姓名",
          key: "username",
          scopedSlots: { customRender: "username" },
          width: 170
        },
        {
          title: "导游电话",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          width: 170
        },
        {
          title: "身份证件",
          key: "certificateValue",
          scopedSlots: { customRender: "certificateValue" },
          width: 170
        },
        {
          title: "导游证件",
          key: "guideLicenseId",
          scopedSlots: { customRender: "guideLicenseId" },
          width: 170
        },
        {
          title: "导游性别",
          key: "sex",
          scopedSlots: { customRender: "sex" },
          width: 100
        },
        {
          title: "导游国别",
          key: "country",
          scopedSlots: { customRender: "country" },
          width: 170
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 160
        }
      ],
      guideCheckData: [], //已选导游数据
      guideFormRules: {
        username: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        //idCard certificateValue
        certificateValue: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
          { validator: checkIdCard, trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      },
      sexs: [
        { id: 0, value: "未知" },
        { id: 2, value: "男" },
        { id: 1, value: "女" }
      ],
      nowAddIndex: 0,
      selectGuideShow: false, //选择导游弹框
      touristType: [], // 团体分类
      groupTypeId: "", // 选中团体票id
      subscenicProductList: [], // 子景区产品
      guideProductId: "", // 选中子景区产品id
      managementList: [], // 分销商数据列表
      managementId: "", // 选中旅行社id
      managementName: "", //临时旅行社名称
      //选择导游弹框
      selectManagementShow: false
    };
  },

  created() {
    //获取旅行社列表
    this.queryManagementTypes();
    // 子景区产品列表
    this.querySubscenicProducts();
    this.queryTouristType(2); // 获取团体分类
  },

  methods: {
    onChangeManagementName() {
      let oneGuide = this.managementList?.find(
        item => item.distributorName == this.managementName
      );
      if (oneGuide?.distributorId != undefined) {
        this.managementId = oneGuide.distributorId;
      }
    },
    confirm() {
      let oneGuide = this.managementList?.find(
        item => item.distributorId == this.managementId
      );
      if (oneGuide?.distributorName != undefined) {
        this.managementName = oneGuide?.distributorName;
      }
    },
    cancel() {},
    checkPhone,
    check() {
      if (!this.managementId && !this.managementName) {
        this.$message.warning("请选择或填写团体名称/旅行社名称");
        return false;
      } else if (!this.groupTypeId) {
        this.$message.warning("请选择团体分类");
        return false;
      } else if (!this.guideProductId) {
        this.$message.warning("请选择导游票");
        return false;
      } else {
        return true;
      }
    },
    // 选择导游和添加导游事件
    handleChange() {
      let obj = {
        guideIds: this.guideCheckData.map(item => item.id), //当前已选导游id
        managementId: this.managementId, //选中旅行社id
        groupTypeId: this.groupTypeId, //选中团体票id
        guideProductId: this.guideProductId //选中子景区产品id
      };
      this.$emit("add", obj);
    },

    // 删除、更新团体分类、导游票
    handleDel(guideIds) {
      if (this.guideCheckData.length || guideIds) {
        let obj = {
          managementId: this.managementId, //选中旅行社id
          groupTypeId: this.groupTypeId, //选中团体票id
          guideProductId: this.guideProductId //选中子景区产品id
        };
        if (guideIds) {
          obj.guideIds = guideIds;
        }
        this.$emit("del", obj);
      }
    },
    // 加载子景区列表
    loadScenics() {
      this.queryManagementTypes(); // 获取旅行社
      this.querySubscenicProducts(); // 子景区产品列表
    },

    //输入框实时赋值
    inputChange(value, key) {
      this.guideCheckData[this.nowAddIndex][key] = value; //赋值
      this.guideFormRules.username = value;
    },
    //新增一行
    toAddGuideShow() {
      if (!this.check()) return;
      let obj = {};
      this.guideCheckColumns.forEach(item => {
        obj[item.key] = "";
        this.guideFormRules[item.key] = "";
      });
      obj.sex = 2;
      obj.colId = `col_${this.guideCheckData.length}`;
      this.guideCheckData.push(obj);
      this.nowAddIndex = this.guideCheckData.length - 1;
    },
    //保存导游
    saveGuide(row) {
      row.name = row.username;
      row.idCard = row.certificateValue;
      row.certificateType = 1;
      if (!row.name) {
        this.$message.warning("导游姓名不能为空");
        return;
      }
      if (!row.idCard) {
        this.$message.warning("导游身份证不能为空");
        return;
      }
      if (!row.phone) {
        this.$message.warning("导游手机号不能为空");
        return;
      }
      guide(row).then(res => {
        //保存成功
        res.username = res.name;
        let arr = this.guideCheckData;
        arr[this.nowAddIndex] = res;
        this.guideCheckData = [...arr];
        this.handleChange();
      });
    },
    handleCancle() {
      this.selectGuideShow = false;
      this.handleChange();
    },
    //点击选择导游按钮
    selectGuide() {
      if (!this.check()) return;
      guideList()
        .then(res => {
          this.guideDatas = res;
          //把新增后的导游选中
          this.guideDatas.forEach(item => {
            this.guideCheckData.forEach(ite => {
              if (item.id === ite.id) {
                item.check = true;
              }
            });
          });
          this.selectGuideShow = true;
        })
        .catch(() => {});
    },
    //选择导游
    checkGuide() {
      this.guideCheckData = this.guideDatas.filter(item => {
        item.guideLicenseId = item.guideLicense
          ? JSON.parse(item.guideLicense).guideLicenseId
          : "";
        item.username = item.name;
        return item.check;
      });
    },
    //删除导游
    delGuide(row) {
      let index = this.guideCheckData.findIndex(item => item.id == row.id);
      this.guideDatas.map(item => {
        if (item.id === this.guideCheckData[index].id) {
          item.check = false; //更新选择状态
        }
      });
      this.guideCheckData.splice(index, 1);
      if (row.id) this.handleDel([row.id]);
    },

    // 查询分销商列表
    queryManagementTypes() {
      merchantGroupList().then(res => {
        this.managementList = res;
      });
    },

    // 选择分销商
    changeManagement(id) {
      this.managementId = id;
      this.handleDel();
    },

    //获取团体分类
    queryTouristType(chooseType = 2) {
      // chooseType	1.个人下单 2.团体下单 this.orderType
      selectTouristManagementInfo({ chooseType: chooseType }).then(res => {
        res.forEach(item => {
          let obj = JSON.parse(item.automaticallyMatchProducts);
          if (obj.choose == 1) {
            //有产品
            item.sybSystemIdProductId =
              obj.automaticallyMatchProductsList[0].productId;
          }
        });
        this.touristType = res;
      });
    },

    //子景区产品列表
    querySubscenicProducts() {
      subscenicProducts().then(res => {
        res.forEach(item => {
          if (item.subScenicId == this.params.subScenicId) {
            //该子景区下的产品
            this.subscenicProductList = item.skuList;
          }
        });
      });
    },

    // 选择团体
    changeTourist(idx) {
      this.groupTypeId = idx;
      const obj = this.touristType.find(item => {
        if (item.id == idx) {
          return item;
        }
      });
      let automaticallyMatchProducts = JSON.parse(
        obj.automaticallyMatchProducts
      );
      if (automaticallyMatchProducts.automaticallyMatchProductsList) {
        automaticallyMatchProducts.automaticallyMatchProductsList.forEach(
          item => {
            if (item.systemId == this.params.subScenicId) {
              this.subscenicProductList.forEach(val => {
                if (item.productId == val.id) {
                  this.guideProductId = val.id;
                  this.handleDel();
                }
              });
            }
          }
        );
      }
    },

    // 选择导游票
    changeGuideProductId() {
      // this.guideProductId = idx;
      this.handleDel();
    },
    // 过滤
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onSelectManagement() {
      this.selectManagementShow = true;
    },
    onCancleManagementModal() {
      this.selectManagementShow = false;
    },
    onConfirmManagementModal() {
      this.selectManagementShow = false;
    },
    onSelectDistributor(item) {
      this.managementName = item.distributorName;
      this.onCancleManagementModal();
    }
  }
};
</script>

<style scoped lang="less">
@import "./ticket-order-common";
.scenic-select {
  width: 150px;
  // margin-right: 24px;
}
.search-box {
  gap: 24px;

  .management-name {
    width: 15rem;
  }

  .last {
    margin-right: 24px;
  }
}
.guide-box {
  height: 700px;
  overflow: auto;
}
</style>
