var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.vis,"title":"补充人脸信息","width":"780px","body-style":{
    'max-height': '460px'
  },"mask-closable":false},on:{"cancel":_vm.close}},[_c('a-form-model',{ref:"refAddFaceInfor",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label":"门票名称"}},[_c('span',[_vm._v(_vm._s(_vm.activateInfo.productName))])]),(
        !_vm.activateInfo.subTicketListInfo ||
          _vm.activateInfo.subTicketListInfo.length <= 0
      )?_c('a-form-model-item',{attrs:{"colon":false,"label":"主票票号","label-col":_vm.labelCol}},[_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[_vm._v(_vm._s(_vm.activateInfo.auxiliaryId))]),_c('a-col',{attrs:{"span":10}},[_c('IdCardInput',{on:{"readCard":_vm.readCard},model:{value:(_vm.idCardNumber),callback:function ($$v) {_vm.idCardNumber=$$v},expression:"idCardNumber"}})],1)],1),_c('a-row',{staticClass:"mt-2x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":10}},[_c('FaceInput',{ref:"faceInput",attrs:{"echoimgurl":_vm.faceUrl,"faceid":_vm.faceid},on:{"imgUrlChange":_vm.imgUrl},model:{value:(_vm.faceNumber),callback:function ($$v) {_vm.faceNumber=$$v},expression:"faceNumber"}})],1)],1)],1):_vm._e(),(
        _vm.activateInfo.subTicketListInfo &&
          _vm.activateInfo.subTicketListInfo.length
      )?_c('a-form-model-item',{attrs:{"label":"子票票号"}},_vm._l((_vm.activateInfo.subTicketListInfo),function(item,index){return _c('div',{key:index,staticClass:"mb-3x"},[_c('a-row',{attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}},[_vm._v(" "+_vm._s(item.auxiliaryId)+" ")]),_c('a-col',{attrs:{"span":10}},[_c('IdCardInput',{on:{"readCard":function (data) {
                  _vm.subReadCard(data, index);
                }},model:{value:(item.subIdCardNumber),callback:function ($$v) {_vm.$set(item, "subIdCardNumber", $$v)},expression:"item.subIdCardNumber"}})],1)],1),_c('a-row',{staticClass:"mt-x",attrs:{"type":"flex","justify":"start"}},[_c('a-col',{attrs:{"span":6}}),_c('a-col',{attrs:{"span":10}},[_c('FaceInput',{ref:"faceInput",refInFor:true,attrs:{"echoimgurl":item.subFaceUrl,"faceid":_vm.faceid},on:{"imgUrlChange":function (url) {
                  _vm.subImgUrl(url, index);
                }},model:{value:(item.subFaceNumber),callback:function ($$v) {_vm.$set(item, "subFaceNumber", $$v)},expression:"item.subFaceNumber"}})],1)],1)],1)}),0):_vm._e()],1),_c('div',{staticClass:"flex-end",attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{key:"cancel",on:{"click":_vm.close}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }