<!--综合套票子产品-->
<template>
  <div class="ph-2x pb-2x">
    <!--搜索-->
    <header class="mb-2x">
      <span>产品名称</span>
      <a-input
        class="mh-2x"
        style="width: 200px"
        v-model="searchKey"
        placeholder="请输入产品名称"
        allow-clear
      ></a-input>
      <a-button type="primary" @click="search">查询</a-button>
    </header>

    <!--项目套餐表格-->
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      rowKey="id"
      size="middle"
    >
      <span slot="totalAmount" slot-scope="row">{{
        row.totalAmount | money
      }}</span>
      <span slot="discountAmount" slot-scope="row">{{
        row.discountAmount | money
      }}</span>
      <span slot="receivedAmount" slot-scope="row">{{
        row.receivedAmount | money
      }}</span>
      <span slot="statusText" slot-scope="row">
        {{ row.statusText }}
        <span
          @click="activeClick(row)"
          v-if="row.status == 4"
          class="pointer blue ml-1x"
          >立即激活</span
        >
      </span>
      <a-button
        slot="action"
        slot-scope="row"
        class="blue"
        type="link"
        @click="showDetail(row)"
        >详情</a-button
      >
    </a-table>

    <!--分页-->
    <footer class="flex-end mt-2x" v-show="total > 0">
      <a-pagination
        v-model="page"
        :default-page-size="size"
        :total="total"
        :page-size-options="['10', '20', '50', '100']"
        show-size-changer
        @change="loadData"
        @showSizeChange="sizeChange"
      />
    </footer>

    <!--详情弹窗-->
    <ticket-product-detail
      :visible.sync="visible"
      :id="detailId"
      :productId="productId"
    ></ticket-product-detail>

    <!-- 门票激活弹窗 -->
    <TicketActivition
      v-if="activeData.id"
      :vis.sync="showTicketActive"
      :detail="{
        id: activeData.id
      }"
      @updateParentData="updateParentData"
      ref="ticketActivitionRef"
    ></TicketActivition>
  </div>
</template>

<script>
import TicketProductDetail from "./TicketProductDetail";
import { findTicketPackageProductPage } from "../../../../api/order";
import TicketActivition from "../components/TicketActivition.vue";
export default {
  name: "TicketMixSub",
  components: { TicketProductDetail, TicketActivition },
  data() {
    return {
      searchKey: "",
      tableData: [],
      total: 0,
      page: 1,
      size: 10,
      visible: false,
      detailId: "",
      productId: "", //该项目的产品id
      columns: [
        {
          width: 180,
          title: "所属商户",
          dataIndex: "productOwnerMerchantName",
          key: "productOwnerMerchantName"
        },
        {
          width: 180,
          title: "子产品名称",
          dataIndex: "productName",
          key: "productName"
        },
        {
          width: 120,
          title: "产品类型",
          dataIndex: "productCategoryText",
          key: "productCategoryText"
        },
        {
          width: 120,
          title: "原子产品金额",
          key: "totalAmount",
          scopedSlots: { customRender: "totalAmount" }
        },
        {
          width: 120,
          title: "优惠分摊金额",
          key: "discountAmount",
          scopedSlots: { customRender: "discountAmount" }
        },
        {
          width: 120,
          title: "优惠后子产品金额",
          key: "receivedAmount",
          scopedSlots: { customRender: "receivedAmount" }
        },
        {
          width: 120,
          title: "状态",
          // dataIndex: "statusText",
          scopedSlots: { customRender: "statusText" },
          key: "statusText"
        },
        {
          width: 100,
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      showTicketActive: false, // 门票激活弹窗
      activeData: {} // 要激活的门票数据
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // 加载数据
    loadData() {
      findTicketPackageProductPage({
        page: this.page,
        size: this.size,
        id: this.$route.query.id,
        productName: this.searchKey
      })
        .then(({ records, total }) => {
          this.tableData = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 展示详情
    showDetail(row) {
      this.detailId = row.id;
      this.productId = row.productId;
      this.visible = true;
    },
    // 条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.loadData();
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadData();
    },
    // 立即激活
    activeClick(row) {
      this.activeData = row;
      this.showTicketActive = true;
    },
    // 立即激活后，更新数据
    updateParentData() {
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
