<!--门票列表-->
<template>
  <div class="wrapper flex-col flex-between">
    <!--查询-->
    <header class="header">
      <a-form-model ref="myform" layout="inline" :model="searchForm">
        <a-form-model-item label="订单号" prop="orderId">
          <a-input
            v-model="searchForm.orderId"
            placeholder="请输入订单号"
            class="input"
            allow-clear
            @pressEnter="searchNormal"
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="票号" prop="ticketNo">
          <a-input
            v-model="searchForm.ticketNo"
            placeholder="请输入票号"
            class="input"
            ref="ticketRef"
            allow-clear
            @pressEnter="searchNormal"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="searchNormal">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="link" @click="visible = true">高级查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </header>
    <!--列表-->
    <section v-if="tableData.length" class="mt-2x main bg-white">
      <ticket-list-table
        :table-data="tableData"
        :permission="permission || 'appHomeTicketListAction'"
        @jump="jump"
      ></ticket-list-table>
    </section>

    <section class="flex-grow relative" v-else>
      <p class="center">请输入订单号/票号查询</p>
    </section>

    <!-- 高级查询 右侧弹出抽屉 -->
    <a-drawer
      title="高级查询"
      placement="right"
      :closable="false"
      :width="400"
      :visible="visible"
      :body-style="{ paddingBottom: '20px' }"
      @close="onClose"
    >
      <a-form-model
        :model="searchForm"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
        label-align="left"
      >
        <a-form-model-item label="订单号" :colon="false">
          <a-input
            v-model="searchForm.orderId"
            placeholder="请输入订单号"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="取票号" :colon="false">
          <a-input
            v-model="searchForm.takeTicketNo"
            placeholder="请输入取票号"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="取票人手机号" :colon="false">
          <a-input
            v-model="searchForm.phone"
            placeholder="请输入取票人手机号"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="取票人身份证" :colon="false">
          <id-card-input v-model="searchForm.idCard"></id-card-input>
        </a-form-model-item>
        <a-form-model-item label="票号" :colon="false">
          <a-input
            v-model="searchForm.ticketNo"
            placeholder="请输入票号"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="卡/腕带编号" :colon="false">
          <IcCardinput
            v-model="searchForm.cardWristStrapNO"
            placeholder="请输入卡/腕带编号"
          ></IcCardinput>
        </a-form-model-item>
        <a-form-model-item label="会员号码" :colon="false">
          <a-input
            v-model="searchForm.memberId"
            placeholder="请输入会员号码"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="会员手机号" :colon="false">
          <a-input
            v-model="searchForm.memberPhone"
            placeholder="请输入会员手机号"
            allow-clear
          />
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ offset: 8 }">
          <a-button type="primary" @click="searchHigh">
            查询
          </a-button>
          <a-button class="ml-2x" @click="reset('myform')">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
import IdCardInput from "../../../components/hardware/IdCardInput";
import moment from "moment";
import { changFouce } from "@/utils/global";
import { orderTicketOrder } from "../../../api/search";
import TicketListTable from "./components/TicketListTable";
import IcCardinput from "../../../components/hardware/IcCardinput.vue";

export default {
  name: "TicketList",
  props: {
    permission: {
      type: String,
      default: ""
    }
  },
  components: {
    IdCardInput,
    TicketListTable,
    IcCardinput
  },
  data() {
    return {
      moment,
      tableData: [],
      visible: false,
      searchForm: {
        orderId: "", // 订单编号 1866567940260259341
        ticketNo: "",
        takeTicketNo: "", // 取票号
        phone: "", // 取票人手机号
        idCard: "", // 取票人手机号
        memberId: "", // 会员号码
        memberPhone: "", // 会员手机号
        cardWristStrapNO: "" // 卡腕带编号
      }
    };
  },
  created() {
    // 获取缓存筛选数据
    let searchForm = sessionStorage.getItem("ticketListFilters");
    if (searchForm) {
      searchForm = JSON.parse(searchForm);
      for (let key in this.searchForm) {
        this.searchForm[key] = searchForm[key];
      }
      this.searchNormal();
      sessionStorage.removeItem("ticketListFilters");
    }
    changFouce(this, "ticketRef");
  },
  methods: {
    getList(data) {
      orderTicketOrder(data)
        .then(res => {
          if (res.length === 0) this.$message.warning("没有查询到数据");
          this.tableData = res;
        })
        .catch(() => {});
    },
    // 普通查询
    searchNormal() {
      if (this.searchForm.orderId || this.searchForm.ticketNo) {
        this.reload({
          orderId: this.searchForm.orderId, // 订单编号 1866567940260259341
          ticketNo: this.searchForm.ticketNo
        });
      } else {
        this.$message.warning("请输入查询条件");
      }
    },
    // 高级查询
    searchHigh() {
      let condution = 0;
      for (let i in this.searchForm) {
        if (this.searchForm[i] !== "") {
          condution++;
        }
      }
      if (condution === 0) {
        this.$message.warning("请输入查询条件");
        return false;
      }
      this.reload({ ...this.searchForm });
    },
    // 查询
    reload(data) {
      this.page = 1;
      this.visible = false;
      this.getList(data);
    },
    // 重置表单
    reset() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.tableData = [];
    },
    onClose() {
      this.visible = false;
    },
    // 跳转
    jump() {
      sessionStorage.setItem(
        "ticketListFilters",
        JSON.stringify(this.searchForm)
      );
    }
  }
};
</script>

<style scoped>
/*头部*/
.header {
  padding: 12px 16px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.center {
  font-size: 30px;
  color: #ccc;
}
</style>
