<!--购物信息组件-->
<!--
  @props: {
      params: Object 筛选条件信息
    appointment: 0    // 分时预约选择 1.产品  2.子景区 3.景区
    times：Array 时段列表
  }
  
  @event: {
      update-cart // 更新购物车 调接口
      change-cart  // 更新购物车 不调接口
      update-add-buy // 加购修改
      update-player // 出游人改动
      
  }
-->
<template>
  <div>
    <!--购物信息-->
    <section class="clear-margin-top section">
      <div class="flex-between font-20">
        <span>购物信息</span>
        <product-id-card @changeCard="changeCard"></product-id-card>
      </div>
      <div class="list-box">
        <a-row class="th">
          <a-col :span="1" class="td">序号</a-col>
          <a-col :span="4" class="td">产品名称</a-col>
          <a-col :span="4" class="td">商户名称</a-col>
          <a-col :span="3" class="td">价格</a-col>
          <a-col :span="3" class="td">数量</a-col>
          <a-col :span="4" class="td">游客类型</a-col>
          <a-col :span="4" class="td">预约时段</a-col>
          <a-col :span="1" class="td">操作</a-col>
        </a-row>
        <a-form-model
          layout="inline"
          :model="formData"
          ref="formData"
          v-if="formData.cartList.length"
        >
          <div
            class="t-body"
            v-for="(item, index) in formData.cartList"
            :key="item.id"
          >
            <!--使用规则如果是一子产品一游客(注意维护时要同时维护一种产品一游客)-->
            <template v-if="item.combUseRule === 2">
              <a-row class="tr">
                <a-col :span="1" class="td">{{ item.index + 1 }}</a-col>
                <a-col :span="5" class="td">{{ item.productName }}</a-col>
                <a-col :span="2" class="td"
                  >¥{{ item.sellingAmount / 100 }}</a-col
                >
                <a-col :span="1" :offset="15" class="td">
                  <p class="del flex-shrink P-1x" @click="delItem(item, 0)">
                    删除
                  </p>
                </a-col>
              </a-row>

              <div class="ph-2x pb-2x">
                <a-row class="th sub">
                  <a-col :span="2" class="td">序号</a-col>
                  <a-col :span="4" class="td">产品名称</a-col>
                  <a-col :span="4" class="td">商户名称</a-col>
                  <a-col :span="4" class="td">价格</a-col>
                  <a-col :span="4" class="td">数量</a-col>
                  <a-col :span="6" class="td">预约时段</a-col>
                </a-row>
                <div v-for="(obj, i) in item.subProductList" :key="obj.id">
                  <a-row class="tr sub">
                    <!--序号-->
                    <a-col :span="2" class="td">{{ obj.index + 1 }}</a-col>
                    <!--产品名称-->
                    <a-col :span="4" class="td">
                      <div>
                        <p>
                          <!--whetherSpecialDiscount: 1是特惠产品 0 非特惠产品-->
                          <span class="hui" v-if="obj.whetherSpecialDiscount"
                            >惠</span
                          >
                          <span class="text-v">{{ obj.productName }}</span>
                        </p>
                        <!--oldProductName: 旧产品名称-->
                        <del v-if="obj.oldProductName" class="disabled">{{
                          obj.oldProductName
                        }}</del>
                      </div>
                    </a-col>
                    <!--商户名称-->
                    <a-col :span="4" class="td">{{
                      obj.ownerMerchantName
                    }}</a-col>
                    <!--价格-->
                    <a-col :span="4" class="td">¥{{ obj.sellingAmount }}</a-col>
                    <!--数量-->
                    <a-col :span="4" class="td">
                      {{ obj.buyNum }}
                    </a-col>
                    <!--选择时段-->
                    <a-col :span="6" class="td">
                      <a-form-model-item
                        :prop="
                          `cartList.${index}.subProductList.${i}.timeIntervalId`
                        "
                        :rules="{
                          required: item.timeShareAppointmentRuleId,
                          message: '请选择预约日期和时段',
                          trigger: 'change'
                        }"
                      >
                        <!--如果分时预约按景区走，或者按产品走是未开启分时预约则禁用-->
                        <a-button v-if="appointment !== 1" disabled class="res"
                          >{{ obj.playDate | date }}
                          {{ obj.appointmentBeginTime | date("hh:mm") }} ~
                          {{ obj.appointmentEndTime | date("hh:mm") }}
                        </a-button>
                        <!--如果分时预约按产品走且开启了分时预约-->
                        <a-button
                          v-else
                          :disabled="!item.timeShareAppointmentRuleId"
                          class="font-s res"
                          @click="showVisibleTime(item, obj)"
                        >
                          <span v-if="!item.timeShareAppointmentRuleId"
                            >无</span
                          >
                          <span v-else>
                            <span v-if="obj.appointmentEndTime"
                              >{{ obj.playDate | date }}
                              {{ obj.appointmentBeginTime | date("hh:mm") }}~{{
                                obj.appointmentEndTime | date("hh:mm")
                              }}</span
                            >
                            <span v-else>请选择预约日期和时段</span>
                          </span>
                        </a-button>
                      </a-form-model-item>
                      <!--以产品维度的时候时段必选-->
                    </a-col>
                  </a-row>

                  <!--更多游客信息-->
                  <!--字段类型：1文本,2日期,3下拉选择,4姓名,5手机,6身份证,7生日,8性别,9证件照,10司机姓名,
            11司机车牌号,12地址,13邮箱,14微信openId,15证件类型,16国家,17台胞证,18港澳通行证 19护照
          -->
                  <a-row
                    v-if="
                      obj.visitorFieldList.length ||
                        obj.IdTypeList.length ||
                        obj.requiredPersonId
                    "
                    class="pv-2x  bg-gray"
                  >
                    <a-col :span="23" :offset="1" class="flex-start">
                      <b class="flex-shrink label">游客信息：</b>
                      <ul class="flex-start flex-wrap">
                        <!--非证件类型字段-->
                        <li
                          v-for="(temp, idx) in obj.visitorFieldList"
                          :key="'v' + idx"
                          class="flex-start"
                        >
                          <template v-if="temp.fieldType === 1">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 180px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入内容',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                v-model="temp.fieldValue"
                                type="tel"
                                placeholder="请输入内容"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 2">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请选择日期',
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <a-date-picker
                                valueFormat="YYYY-MM-DD"
                                @change="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                                v-model="temp.fieldValue"
                              />
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 3">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: `请选择${temp.fieldName}`,
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <a-select
                                style="width: 120px"
                                v-model="temp.fieldValue"
                                placeholder="请选择"
                                @change="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                                <a-select-option
                                  :value="val"
                                  v-for="(val, i) in temp.fieldOptions"
                                  :key="'o' + i"
                                >
                                  {{ val }}
                                </a-select-option>
                              </a-select>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 4">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 150px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入内容',
                                  trigger: 'blur'
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                v-model="temp.fieldValue"
                                placeholder="请输入内容"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 5">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 150px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入手机号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkPhone
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                v-model="temp.fieldValue"
                                type="tel"
                                placeholder="请输入内容"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 7">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请选择日期',
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <a-form-model-item style="width: 128px;">
                                <a-date-picker
                                  valueFormat="YYYY-MM-DD"
                                  @change="
                                    changePlayer(
                                      item,
                                      `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                      {},
                                      obj
                                    )
                                  "
                                  v-model="temp.fieldValue"
                                />
                              </a-form-model-item>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 8">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 80px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请选择性别',
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <a-select
                                placeholder="请选择"
                                v-model="temp.fieldValue"
                                style="width: 80px"
                                @change="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                                <a-select-option
                                  :value="val"
                                  v-for="(val, i) in temp.fieldOptions"
                                  :key="'s' + i"
                                >
                                  {{ val }}
                                </a-select-option>
                              </a-select>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 9">
                            <p class="ml-3x mr-1x label flex-shrink">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 100px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请上传证件照',
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <picture-input
                                class="m-x"
                                v-model="temp.fieldValue"
                                :small="true"
                                @input="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></picture-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 10">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入姓名',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                placeholder="请输入姓名"
                                allow-clear
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 11">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入车牌号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                placeholder="请输入车牌号"
                                allow-clear
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 12">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 200px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入地址',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                placeholder="请输入地址"
                                allow-clear
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 13">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 200px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入邮箱',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkEmail
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                placeholder="请输入邮箱"
                                allow-clear
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 14">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 200px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: `请输入${temp.fieldName}`,
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                placeholder="请输入微信openId"
                                allow-clear
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              ></a-input>
                            </a-form-model-item>
                          </template>
                          <template v-else-if="temp.fieldType === 16">
                            <p class="ml-3x mr-1x label">
                              <span class="red" v-if="temp.required">*</span>
                              {{ temp.fieldName }}
                            </p>
                            <a-form-model-item
                              style="width: 128px;"
                              :prop="
                                `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: `请输入${temp.fieldName}`,
                                  trigger: 'change'
                                }
                              ]"
                            >
                              <a-select
                                placeholder="请选择国家"
                                v-model="temp.fieldValue"
                                style="width: 128px"
                                @change="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.visitorFieldList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                                <a-select-option
                                  :value="val"
                                  v-for="(val, i) in temp.fieldOptions"
                                  :key="'c' + i"
                                >
                                  {{ val }}
                                </a-select-option>
                              </a-select>
                            </a-form-model-item>
                          </template>
                        </li>
                        <!--出游人信息证件类型归类-->
                        <li
                          v-if="obj.IdTypeList.length"
                          class="ml-2x flex-start idCard"
                          style="width: 350px"
                        >
                          <a-select
                            v-model="obj.IdType"
                            style="width:100px;margin-top: 4px;"
                          >
                            <a-select-option
                              :value="val.fieldType"
                              v-for="val in obj.IdTypeList"
                              :key="val.fieldType"
                              >{{ val.fieldName }}</a-select-option
                            >
                          </a-select>

                          <section
                            v-for="(temp, idx) in obj.IdTypeList"
                            :key="'i' + idx"
                          >
                            <a-form-model-item
                              style="width:250px"
                              v-if="temp.fieldType === 6 && obj.IdType === 6"
                              :prop="
                                `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入身份证号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkIdCard
                                }
                              ]"
                            >
                              <IdCardInput
                                :is-cart-verify="true"
                                :current-card-id.sync="currentCardId"
                                :ref="'idCardInput' + index + i"
                                v-inputfocus
                                v-model="temp.fieldValue"
                                @focus="idCardFocus"
                                @readCard="
                                  readCard(
                                    $event,
                                    obj,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    temp,
                                    item
                                  )
                                "
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    temp,
                                    obj
                                  )
                                "
                              ></IdCardInput>
                            </a-form-model-item>
                            <!--社保卡-->
                            <a-form-model-item
                              style="width:250px"
                              v-if="temp.fieldType === 21 && obj.IdType === 21"
                              :prop="
                                `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入身份证号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkIdCard
                                }
                              ]"
                            >
                              <IdCardInput
                                v-inputfocus
                                v-model="temp.fieldValue"
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    temp,
                                    obj
                                  )
                                "
                              ></IdCardInput>
                            </a-form-model-item>
                            <a-form-model-item
                              style="width:250px"
                              v-else-if="
                                temp.fieldType === 17 && obj.IdType === 17
                              "
                              :prop="
                                `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入证件号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                :key="'i' + idx"
                                v-inputfocus
                                v-model="temp.fieldValue"
                                class="ib"
                                placeholder="请输入证件号"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                            <a-form-model-item
                              style="width:250px"
                              v-else-if="
                                temp.fieldType === 18 && obj.IdType === 18
                              "
                              :prop="
                                `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入证件号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                v-model="temp.fieldValue"
                                placeholder="请输入证件号"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                            <a-form-model-item
                              style="width:250px"
                              v-else-if="
                                temp.fieldType === 19 && obj.IdType === 19
                              "
                              :prop="
                                `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`
                              "
                              :rules="[
                                {
                                  required: temp.required,
                                  message: '请输入证件号',
                                  trigger: 'blur'
                                },
                                {
                                  validator: checkText
                                }
                              ]"
                            >
                              <a-input
                                v-inputfocus
                                v-model="temp.fieldValue"
                                placeholder="请输入证件号"
                                allow-clear
                                @blur="
                                  changePlayer(
                                    item,
                                    `cartList.${index}.subProductList.${i}.IdTypeList.${idx}.fieldValue`,
                                    {},
                                    obj
                                  )
                                "
                              >
                              </a-input>
                            </a-form-model-item>
                          </section>
                        </li>
                        <li v-if="obj.requiredPersonId">
                          <a-form-model-item
                            :prop="
                              `cartList.${index}.subProductList.${i}.personId`
                            "
                            :required="
                              obj.requiredPersonTrue === 1 ? true : false
                            "
                            :rules="[
                              {
                                required: false,
                                message: '请录入人脸'
                              }
                            ]"
                          >
                            <face-input
                              :small="true"
                              v-model="obj.personId"
                              :key="Math.random()"
                              @imgUrlChange="res => faceChange(item, res, obj)"
                              :echoimgurl="obj.personFaceUrl"
                            ></face-input>
                          </a-form-model-item>
                        </li>
                      </ul>
                    </a-col>
                  </a-row>
                  <!--其它信息fast:是否有快票；whetherRecommend：是否有加购； requiredVoucherNo：是否有卡腕带 -->
                  <!--              item.whetherRecommend || 加购，后期加上到下面的判断-->
                  <a-row
                    v-if="
                      obj.fast === 1 ||
                        item.requiredVoucherNo ||
                        item.whetherRecommend
                    "
                    :class="{
                      'pt-2x':
                        !obj.visitorFieldList.length && !obj.IdTypeList.length
                    }"
                    class="pb-2x bg-gray"
                  >
                    <a-col
                      :span="22"
                      :offset="1"
                      class="flex-start align-center"
                    >
                      <span>其它：</span>
                      <b class="mr-4x" v-show="obj.requiredVoucherNo"
                        >卡腕带押金
                        <span class="price ml-x">{{
                          obj.wristbandPrice | money
                        }}</span>
                      </b>
                      <template v-if="obj.requiredVoucherNo">
                        <a-form-model-item
                          style="width:170px"
                          label="卡腕带"
                          :prop="
                            `cartList.${index}.subProductList.${i}.voucherNo`
                          "
                          :rules="[
                            {
                              required: true,
                              message: '请输入卡腕带号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <IcCardInput
                            v-inputfocus
                            v-model="obj.voucherNo"
                            :allow-clear="false"
                            placeholder="请输入卡/腕带编号"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.subProductList.${i}.voucherNo`,
                                {},
                                obj
                              )
                            "
                          ></IcCardInput>
                        </a-form-model-item>
                      </template>
                      <template v-if="obj.fast === 1">
                        <b class="mr-x">快速通行服务</b>
                        <a-switch
                          color="#25a2f2"
                          v-model="obj.whetherFastTicket"
                          @change="fastChange(item, obj)"
                        />
                        <b class="price ml-x mr-4x">{{
                          obj.fastChannelFee | money
                        }}</b>
                      </template>
                      <!--是否有推荐加购-组合产品是否有加购-->
                      <template v-if="item.whetherRecommend">
                        <b>加购产品：</b>
                        <a-button
                          type="primary"
                          size="small"
                          shape="round"
                          ghost
                          @click="selectAdd(obj, item)"
                          >选择</a-button
                        >
                        <ul class="align-start ml-3x">
                          <li
                            class="flex-start align-center"
                            v-for="(temp, idx) in obj.subProductList"
                            :key="temp.productId"
                            :class="{ 'mt-2x': idx > 0 }"
                          >
                            <div
                              class="flex-start flex-shrink"
                              style="max-width: 250px;"
                            >
                              <p class="text-1">{{ temp.productName }}</p>
                              <p class="flex-shrink price ml-1x">
                                {{ temp.sellingAmount | money }}
                              </p>
                            </div>
                            <a-checkbox
                              class="ml-3x mr-1x"
                              :checked="!!temp.productFlagPO.whetherCheck"
                              @click="checkAddBuy(item, temp, obj)"
                            >
                            </a-checkbox>
                            <p class="flex-shrink">数量：</p>
                            <!--不能修改数量：!temp.productFlagPO.whetheChangeNum-->
                            <div
                              v-if="temp.productFlagPO.whetheChangeNum"
                              class="flex-start mr-1x"
                            >
                              <a-icon
                                type="minus-circle"
                                class="minus"
                                @click="minusAddBuy(item, temp, obj)"
                              />
                              <input
                                v-inputfocus
                                :value="temp.productFlagPO.buyNum"
                                class="input-number"
                                onkeyup="this.value=this.value.replace(/\D/g,'')"
                                @blur="e => addInputBlur(e, item, temp, obj)"
                              />
                              <a-icon
                                type="plus-circle"
                                theme="filled"
                                class="plus"
                                @click="plusAddBuy(item, temp, obj)"
                              />
                            </div>
                            <p class="mr-2x" v-else>
                              {{ temp.productFlagPO.buyNum }}
                            </p>
                            <!--加购时段-->
                            <a-form-model-item
                              :prop="
                                `cartList.${index}.subProductList.${i}.subProductList.${idx}.timeIntervalId`
                              "
                              :rules="{
                                required:
                                  appointment !== 1 ||
                                  temp.timeShareAppointmentRuleId,
                                message: '请选择',
                                trigger: 'change'
                              }"
                            >
                              <!--如果分时预约按产品走且开启了分时预约-->
                              <a-button
                                class="gray font-s res"
                                @click="showVisibleTime(item, obj, temp)"
                                v-if="temp.timeShareAppointmentRuleId"
                              >
                                <!--已选中分时预约-->
                                <span
                                  v-if="
                                    temp.appointmentTimePO &&
                                      temp.appointmentTimePO.appointmentEndTime
                                  "
                                  >{{ temp.playDate | date }}
                                  {{
                                    temp.appointmentTimePO.appointmentBeginTime
                                      | date("hh:mm")
                                  }}~{{
                                    temp.appointmentTimePO.appointmentEndTime
                                      | date("hh:mm")
                                  }}</span
                                >
                                <span v-else>请选择预约日期和时段</span>
                              </a-button>
                            </a-form-model-item>

                            <div
                              v-if="temp.productFlagPO.whetheChangeNum"
                              class="pointer flex-shrink"
                              @click="delAddBuy(item, temp, obj)"
                            >
                              <b
                                class="iconfont icondelete ml-3x font-xl mr-x red"
                              ></b>
                              <span>删除</span>
                            </div>
                          </li>
                        </ul>
                      </template>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </template>

            <!--使用规则一种产品一游客(注意维护时要同时维护一子产品一游客)-->
            <template v-else>
              <a-row class="tr">
                <a-col :span="1" class="td">{{ item.index + 1 }}</a-col>
                <a-col :span="4" class="td">
                  <div>
                    <p>
                      <!--whetherSpecialDiscount: 1是特惠产品 0 非特惠产品-->
                      <span class="hui" v-if="item.whetherSpecialDiscount"
                        >惠</span
                      >
                      <span class="text-v">{{ item.productName }}</span>
                    </p>
                    <!--oldProductName: 旧产品名称-->
                    <del v-if="item.oldProductName" class="disabled">{{
                      item.oldProductName
                    }}</del>
                  </div>
                </a-col>
                <a-col :span="4" class="td">{{ item.ownerMerchantName }}</a-col>
                <a-col :span="3" class="td">¥{{ item.sellingAmount }}</a-col>
                <!--数量-->
                <a-col :span="3" class="td">
                  <!--有加购、快速通行、卡腕带押金则禁用修改数量-->
                  <a-input
                    v-inputfocus
                    v-model="item.buyNum"
                    type="number"
                    placeholder="请输入"
                    allow-clear
                    :disabled="
                      item.fast === 1 ||
                        item.whetherRecommend ||
                        !!item.requiredVoucherNo
                    "
                    onkeyup="this.value=this.value.replace(/\D/g,'')"
                    @blur="e => changeNum(e, item)"
                  >
                  </a-input>
                </a-col>
                <!--选择游客类型-->
                <a-col :span="4" class="td">
                  <a-form-model-item
                    :prop="`cartList.${index}.visitorTypeId`"
                    :rules="{
                      required: false,
                      message: '请选择',
                      trigger: 'change'
                    }"
                  >
                    <a-select
                      v-if="item.memberTouristList.length"
                      placeholder="请选择"
                      v-model="item.visitorTypeId"
                      @change="
                        changePlayer(item, `cartList.${index}.visitorTypeId`)
                      "
                    >
                      <a-select-option
                        :value="temp.id"
                        v-for="temp in item.memberTouristList"
                        :key="temp.id"
                        >{{ temp.touristCategoryName }}</a-select-option
                      >
                    </a-select>
                    <a-input v-else disabled placeholder="普通类型" />
                  </a-form-model-item>
                </a-col>
                <!--选择时段-->
                <a-col :span="4" class="td">
                  <!--以产品维度的时候时段必选-->
                  <a-form-model-item
                    :prop="`cartList.${index}.timeIntervalId`"
                    :rules="{
                      required: item.timeShareAppointmentRuleId,
                      message: '请选择预约日期和时段',
                      trigger: 'change'
                    }"
                  >
                    <!--如果分时预约按景区走，或者按产品走是未开启分时预约则禁用-->
                    <a-button v-if="appointment !== 1" disabled class="res"
                      >{{ item.playDate | date }}
                      {{ item.appointmentBeginTime | date("hh:mm") }} ~
                      {{ item.appointmentEndTime | date("hh:mm") }}
                    </a-button>
                    <!--如果分时预约按产品走且开启了分时预约-->
                    <a-button
                      v-else
                      class="gray font-s res"
                      :disabled="!item.timeShareAppointmentRuleId"
                      @click="showVisibleTime(item)"
                    >
                      <!--产品未启用分时预约-->
                      <span v-if="!item.timeShareAppointmentRuleId">无</span>
                      <!--产品启用分时预约-->
                      <span v-else
                        ><span v-if="item.appointmentEndTime"
                          >{{ item.playDate | date }}
                          {{ item.appointmentBeginTime | date("hh:mm") }}~{{
                            item.appointmentEndTime | date("hh:mm")
                          }}</span
                        >
                        <span v-else>请选择预约日期和时段</span></span
                      >
                    </a-button>
                  </a-form-model-item>
                </a-col>
                <a-col :span="1" class="td">
                  <p class="del flex-shrink P-1x" @click="delItem(item, 0)">
                    删除
                  </p>
                </a-col>
              </a-row>

              <!--更多游客信息-->
              <!--字段类型：1文本,2日期,3下拉选择,4姓名,5手机,6身份证,7生日,8性别,9证件照,10司机姓名,
        11司机车牌号,12地址,13邮箱,14微信openId,15证件类型,16国家,17台胞证,18港澳通行证 19护照
      -->
              <a-row
                v-if="
                  item.visitorFieldList.length ||
                    item.IdTypeList.length ||
                    item.requiredPersonId
                "
                class="pv-2x  bg-gray"
              >
                <a-col :span="23" :offset="1" class="flex-start">
                  <b class="flex-shrink label">游客信息：</b>
                  <ul class="flex-start flex-wrap">
                    <!--非证件类型字段-->
                    <li
                      v-for="(temp, idx) in item.visitorFieldList"
                      :key="'v' + idx"
                      class="flex-start"
                    >
                      <template v-if="temp.fieldType === 1">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 180px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入内容',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            v-model="temp.fieldValue"
                            type="tel"
                            placeholder="请输入内容"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 2">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请选择日期',
                              trigger: 'change'
                            }
                          ]"
                        >
                          <a-date-picker
                            valueFormat="YYYY-MM-DD"
                            @change="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                            v-model="temp.fieldValue"
                          />
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 3">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: `请选择${temp.fieldName}`,
                              trigger: 'change'
                            }
                          ]"
                        >
                          <a-select
                            style="width: 120px"
                            v-model="temp.fieldValue"
                            placeholder="请选择"
                            @change="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                            <a-select-option
                              :value="val"
                              v-for="(val, i) in temp.fieldOptions"
                              :key="'o' + i"
                            >
                              {{ val }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 4">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 150px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入内容',
                              trigger: 'blur'
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            v-model="temp.fieldValue"
                            placeholder="请输入内容"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 5">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 150px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入手机号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkPhone
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            v-model="temp.fieldValue"
                            type="tel"
                            placeholder="请输入内容"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 7">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请选择日期',
                              trigger: 'change'
                            }
                          ]"
                        >
                          <a-form-model-item style="width: 128px;">
                            <a-date-picker
                              type="date"
                              valueFormat="YYYY-MM-DD"
                              @change="
                                changePlayer(
                                  item,
                                  `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                                )
                              "
                              v-model="temp.fieldValue"
                            />
                          </a-form-model-item>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 8">
                        <p class="ml-3x mr-1x label ">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 80px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请选择性别',
                              trigger: 'change'
                            }
                          ]"
                        >
                          <a-select
                            placeholder="请选择"
                            v-model="temp.fieldValue"
                            style="width: 80px"
                            @change="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                            <a-select-option
                              :value="val"
                              v-for="(val, i) in temp.fieldOptions"
                              :key="'s' + i"
                            >
                              {{ val }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 9">
                        <p class="ml-3x mr-1x label flex-shrink">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 100px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请上传证件照',
                              trigger: 'change'
                            }
                          ]"
                        >
                          <picture-input
                            class="m-x"
                            v-model="temp.fieldValue"
                            :small="true"
                            @input="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></picture-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 10">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入姓名',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            placeholder="请输入姓名"
                            allow-clear
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 11">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入车牌号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            placeholder="请输入车牌号"
                            allow-clear
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 12">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 200px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入地址',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            placeholder="请输入地址"
                            allow-clear
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 13">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 200px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入邮箱',
                              trigger: 'blur'
                            },
                            {
                              validator: checkEmail
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            placeholder="请输入邮箱"
                            allow-clear
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 14">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 200px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: `请输入${temp.fieldName}`,
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            placeholder="请输入微信openId"
                            allow-clear
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          ></a-input>
                        </a-form-model-item>
                      </template>
                      <template v-else-if="temp.fieldType === 16">
                        <p class="ml-3x mr-1x label">
                          <span class="red" v-if="temp.required">*</span>
                          {{ temp.fieldName }}
                        </p>
                        <a-form-model-item
                          style="width: 128px;"
                          :prop="
                            `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: `请输入${temp.fieldName}`,
                              trigger: 'change'
                            }
                          ]"
                        >
                          <a-select
                            placeholder="请选择国家"
                            v-model="temp.fieldValue"
                            style="width: 128px"
                            @change="
                              changePlayer(
                                item,
                                `cartList.${index}.visitorFieldList.${idx}.fieldValue`
                              )
                            "
                          >
                            <a-select-option
                              :value="val"
                              v-for="(val, i) in temp.fieldOptions"
                              :key="'c' + i"
                            >
                              {{ val }}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </template>
                    </li>
                    <!--出游人信息证件类型归类-->
                    <li
                      v-if="item.IdTypeList.length"
                      class="ml-2x flex-start idCard"
                      style="width: 350px"
                    >
                      <a-select
                        v-model="item.IdType"
                        style="width:100px;margin-top: 4px;"
                      >
                        <a-select-option
                          :value="val.fieldType"
                          v-for="val in item.IdTypeList"
                          :key="val.fieldType"
                          >{{ val.fieldName }}</a-select-option
                        >
                      </a-select>

                      <section
                        v-for="(temp, idx) in item.IdTypeList"
                        :key="'i' + idx"
                      >
                        <a-form-model-item
                          style="width:250px"
                          v-if="temp.fieldType === 6 && item.IdType === 6"
                          :prop="
                            `cartList.${index}.IdTypeList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入身份证号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkIdCard
                            }
                          ]"
                        >
                          <IdCardInput
                            :is-cart-verify="true"
                            :current-card-id.sync="currentCardId"
                            :ref="'idCardInput' + index"
                            v-inputfocus
                            v-model="temp.fieldValue"
                            @focus="idCardFocus"
                            @readCard="
                              readCard(
                                $event,
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                temp,
                                ''
                              )
                            "
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                temp
                              )
                            "
                          ></IdCardInput>
                        </a-form-model-item>
                        <!--社保卡-->
                        <a-form-model-item
                          style="width:250px"
                          v-if="temp.fieldType === 21 && item.IdType === 21"
                          :prop="
                            `cartList.${index}.IdTypeList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入身份证号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkIdCard
                            }
                          ]"
                        >
                          <IdCardInput
                            v-inputfocus
                            v-model="temp.fieldValue"
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                temp
                              )
                            "
                          ></IdCardInput>
                        </a-form-model-item>
                        <a-form-model-item
                          style="width:250px"
                          v-else-if="
                            temp.fieldType === 17 && item.IdType === 17
                          "
                          :prop="
                            `cartList.${index}.IdTypeList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入证件号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            :key="'i' + idx"
                            v-inputfocus
                            v-model="temp.fieldValue"
                            class="ib"
                            placeholder="请输入证件号"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                {}
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                        <a-form-model-item
                          style="width:250px"
                          v-else-if="
                            temp.fieldType === 18 && item.IdType === 18
                          "
                          :prop="
                            `cartList.${index}.IdTypeList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入证件号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            v-model="temp.fieldValue"
                            placeholder="请输入证件号"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                {}
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                        <a-form-model-item
                          style="width:250px"
                          v-else-if="
                            temp.fieldType === 19 && item.IdType === 19
                          "
                          :prop="
                            `cartList.${index}.IdTypeList.${idx}.fieldValue`
                          "
                          :rules="[
                            {
                              required: temp.required,
                              message: '请输入证件号',
                              trigger: 'blur'
                            },
                            {
                              validator: checkText
                            }
                          ]"
                        >
                          <a-input
                            v-inputfocus
                            v-model="temp.fieldValue"
                            placeholder="请输入证件号"
                            allow-clear
                            @blur="
                              changePlayer(
                                item,
                                `cartList.${index}.IdTypeList.${idx}.fieldValue`,
                                {}
                              )
                            "
                          >
                          </a-input>
                        </a-form-model-item>
                      </section>
                    </li>
                    <li v-if="item.requiredPersonId" class="flex-start">
                      <p class="ml-3x mr-1x flex-shrink label">
                        <span class="red" v-if="item.requiredPersonTrue"
                          >*</span
                        >
                        人脸
                      </p>
                      <a-form-model-item
                        :prop="`cartList.${index}.personId`"
                        :required="false"
                        :rules="[
                          {
                            required:
                              item.requiredPersonTrue === 1 ? true : false,
                            message: '请录入人脸'
                          }
                        ]"
                      >
                        <face-input
                          :small="true"
                          v-model="item.personId"
                          :key="Math.random()"
                          @imgUrlChange="res => faceChange(item, res)"
                          :echoimgurl="item.personFaceUrl"
                        ></face-input>
                      </a-form-model-item>
                    </li>
                  </ul>
                </a-col>
              </a-row>

              <!--其它信息-->
              <!--              item.whetherRecommend || 加购，后期加上到下面的判断-->
              <a-row
                v-if="
                  item.fast === 1 ||
                    item.requiredVoucherNo ||
                    item.whetherRecommend
                "
                :class="{
                  'pt-2x':
                    !item.visitorFieldList.length && !item.IdTypeList.length
                }"
                class="pb-2x bg-gray"
              >
                <a-col :span="22" :offset="1" class="flex-start">
                  <span class="label">其它：</span>
                  <b class="mr-2x label" v-show="item.requiredVoucherNo"
                    >卡腕带押金
                    <span class="price ml-x label">{{
                      item.wristbandPrice | money
                    }}</span>
                  </b>
                  <template v-if="item.requiredVoucherNo">
                    <p class="mr-1x flex-shrink label">
                      <span class="red">*</span>卡腕带
                    </p>
                    <a-form-model-item
                      style="width:170px"
                      :prop="`cartList.${index}.voucherNo`"
                      :rules="[
                        {
                          required: true,
                          message: '请输入卡腕带号',
                          trigger: 'blur'
                        },
                        {
                          validator: checkText
                        }
                      ]"
                    >
                      <IcCardInput
                        v-inputfocus
                        v-model="item.voucherNo"
                        :allow-clear="false"
                        placeholder="请输入卡/腕带编号"
                        @blur="
                          changePlayer(item, `cartList.${index}.voucherNo`)
                        "
                      ></IcCardInput>
                    </a-form-model-item>
                  </template>
                  <template v-if="item.fast === 1">
                    <b class="ml-2x label">快速通行服务：</b>
                    <a-switch
                      class="label"
                      color="#25a2f2"
                      v-model="item.whetherFastTicket"
                      @change="fastChange(item)"
                    />
                    <b class="price ml-x mr-4x">{{
                      item.fastChannelFee | money
                    }}</b>
                  </template>
                  <!--是否有推荐加购-->
                  <template v-if="item.whetherRecommend">
                    <b class="ml-2x label">加购产品：</b>
                    <a-button
                      class="label"
                      type="primary"
                      size="small"
                      shape="round"
                      ghost
                      @click="selectAdd(item, {})"
                      >选择</a-button
                    >
                    <ul class="align-start ml-3x">
                      <li
                        class="flex-start align-center"
                        v-for="(temp, idx) in item.subProductList"
                        :key="temp.productId"
                        :class="{ 'mt-2x': idx > 0 }"
                      >
                        <div
                          class="flex-start align-center flex-shrink"
                          style="max-width: 250px;"
                        >
                          <p class="text-1">{{ temp.productName }}</p>
                          <p class="flex-shrink price ml-1x">
                            {{ temp.sellingAmount | money }}
                          </p>
                        </div>
                        <a-checkbox
                          class="ml-3x mr-1x"
                          :checked="!!temp.productFlagPO.whetherCheck"
                          @click="checkAddBuy(item, temp)"
                        >
                        </a-checkbox>
                        <p class="flex-shrink">数量：</p>
                        <div
                          class="flex-start align-center mr-1x"
                          v-if="temp.productFlagPO.whetheChangeNum"
                        >
                          <a-icon
                            type="minus-circle"
                            class="minus"
                            @click="minusAddBuy(item, temp)"
                          />
                          <input
                            v-inputfocus
                            :value="temp.productFlagPO.buyNum"
                            class="input-number"
                            onkeyup="this.value=this.value.replace(/\D/g,'')"
                            @blur="e => addInputBlur(e, temp, item)"
                          />
                          <a-icon
                            type="plus-circle"
                            theme="filled"
                            class="plus"
                            @click="plusAddBuy(item, temp)"
                          />
                        </div>
                        <p class="mr-2x" v-else>
                          {{ temp.productFlagPO.buyNum }}
                        </p>
                        <!--加购时段-->
                        <a-form-model-item
                          :prop="
                            `cartList.${index}.subProductList.${idx}.timeIntervalId`
                          "
                          :rules="{
                            required: temp.timeShareAppointmentRuleId,
                            message: '请选择',
                            trigger: 'change'
                          }"
                        >
                          <!--如果分时预约按产品走且开启了分时预约-->
                          <a-button
                            class="gray font-s"
                            @click="showVisibleTime(item, null, temp)"
                            v-if="temp.timeShareAppointmentRuleId"
                          >
                            <!--产品启用分时预约-->
                            <span
                              v-if="
                                temp.appointmentTimePO &&
                                  temp.appointmentTimePO.appointmentEndTime
                              "
                              >{{ temp.playDate | date }}
                              {{
                                temp.appointmentTimePO.appointmentBeginTime
                                  | date("hh:mm")
                              }}~{{
                                temp.appointmentTimePO.appointmentEndTime
                                  | date("hh:mm")
                              }}</span
                            >
                            <span v-else>请选择预约日期和时段</span>
                          </a-button>
                        </a-form-model-item>

                        <div
                          v-if="temp.productFlagPO.whetheChangeNum"
                          class="pointer flex-shrink"
                          @click="delAddBuy(item, temp)"
                        >
                          <b
                            class="iconfont icondelete ml-3x font-xl mr-x red"
                          ></b>
                          <span>删除</span>
                        </div>
                      </li>
                    </ul>
                  </template>
                </a-col>
              </a-row>
            </template>
          </div>
        </a-form-model>
        <a-empty v-else :image="simpleImage" />
      </div>
    </section>
    <!--产品维度/加购- 修改预约日期和时段弹窗 -->
    <a-modal v-model="visibleTime" title="预约时段" @ok="handleOk">
      <a-date-picker
        v-if="visibleTime"
        class="mr-2x"
        v-model="visibleItemDate"
        :disabled-date="disabledDate"
        format="YYYY-MM-DD"
        :allow-clear="false"
        @change="dateChange(visibleItem)"
      />
      <a-select
        style="width:140px;"
        placeholder="请选择时段"
        v-model="visibleItem.timeIntervalId"
      >
        <a-select-option
          v-for="temp in visibleTimes"
          :key="temp.id"
          :value="temp.timeIntervalId"
          >{{ temp.beginTime | date("hh:mm") }} ~
          {{ temp.endTime | date("hh:mm") }}</a-select-option
        >
      </a-select>
    </a-modal>
    <!--选择加购产品-->
    <product-add-buy
      ref="productAddBuy"
      :params="params"
      :visible.sync="visible"
      @change="updateAddBuyBatch"
    ></product-add-buy>

    <!-- 选择特惠产品 -->
    <a-modal
      :visible="showDiscountSku"
      title="选择特惠产品"
      :footer="null"
      @cancel="showDiscountSku = false"
      :mask-closable="false"
    >
      <a-form-model
        :model="discountSku"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        labelAlign="left"
      >
        <a-form-model-item label="特惠产品">
          <a-select
            :default-value="discountSku.selectProductSkuId"
            v-model="discountSku.selectProductSkuId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in discountSkus"
              :key="item.selectProductSkuId"
              :value="item.selectProductSkuId"
            >
              {{ item.selectProductName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <div class="flex-center">
          <a-button type="primary mr-4x" size="large" @click="sureDiscountSku">
            确定
          </a-button>
          <a-button size="large" @click="showDiscountSku = false"
            >取消</a-button
          >
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import ProductAddBuy from "./ProductAddBuy";
import {
  commonAddBuyBatch,
  getTicketAddBuy,
  selectDiscountSku,
  ticketCommonlyVisitiorBatch,
  combinationVisitiorBatch
} from "../../api/cart";
import {
  checkPhone,
  checkIdCard,
  checkText,
  checkEmail,
  integerReg
} from "../../utils/global";

import { Empty } from "ant-design-vue";
import IdCardInput from "@/components/hardware/IdCardInput";
import IcCardInput from "@/components/hardware/IcCardinput";
import { fileUploadBase64 } from "../../api/upload";
import PictureInput from "../hardware/PictureInput.vue";
import FaceInput from "../hardware/FaceInput.vue";
import { faceAnalyzeUpload, face } from "@/api/iot";
import { appointmentTimeRuleStock } from "../../api/product";
import ProductIdCard from "@/components/sell/ProductIdCard";
import moment from "moment";
export default {
  name: "ProductPlayer",

  components: {
    ProductAddBuy,
    IdCardInput,
    IcCardInput,
    PictureInput,
    FaceInput,
    ProductIdCard
  },

  props: {
    formatType: {
      // 业态：16会员，6票务，7游乐，8剧院，9酒店，10餐饮，11零售，12租赁，13组合
      type: [Number, String],
      default: ""
    },
    params: {
      type: Object,
      default() {
        return {
          subScenicId: "", // 子景区id
          playDate: "", // 游玩日期
          appointmentRules: "", // 分时预约id
          timeId: "", // 时段id
          name: "", // 产品名称
          categoryId: "" // 分类id
        };
      }
    },
    // 分时预约选择 1.产品  2.子景区 3.景区（产品维度或景区维度）
    appointment: {
      type: Number,
      default: 0
    },
    // 时段列表
    times: {
      type: Array,
      default() {
        return [];
      }
    },
    // 下单规则-日期可选范围
    endDate: {
      type: [Number, String],
      default: ""
    },
    startDate: {
      type: [Number, String],
      default: ""
    }
  },

  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      checkPhone,
      checkIdCard,
      checkText,
      checkEmail,
      cartId: 0,
      formData: {
        cartList: []
      },
      visibleTime: false, // 是否显示预约时间弹窗
      subVisibleTime: false, // 是否显示一个产品一个预约时间弹窗
      visibleItem: {}, // 临时修改预约日期和时段弹窗行-票务
      visibleTimes: [], // 可选时段列表
      visibleItemDate: moment(), // 产品维度预约日期
      visibleObj: {}, // 临时修改预约日期和时段弹窗行-组合产品
      subObjParent: {}, // 一子产品一游客时，子产品的上级
      addProductParent: null, // 加购产品选择预约日期
      fixValidStartTime: "", // 产品有效期
      fixValidEndTime: "", // 产品有效期
      date: moment(),
      defaultData: "", // T+n天后可选
      // 加购
      visible: false,
      tempRow: {}, // 临时加购行数据
      tempMainItem: {}, // 临时的组合产品行
      addBuyList: [], // 加购产品列表
      showDiscountSku: false, // 惠民列表弹框
      discountSku: {}, // 惠民列表选中值
      discountSkus: [], //特惠产品列表
      changePlayerItem: {}, // 修改游客信息item纪录
      isReadCard: false, // 是否是读取身份证
      currentCardId: "", // 当前刷的身份证号
      idCardFocusValue: "", // 记录当前聚焦的身份证是否有值
      currentTicketTraveller: {}, // 当前产品信息
      isFocus: false // 是否开启购物车信息自动读取身份证
    };
  },

  created() {},

  methods: {
    moment,
    /*
     * name: 禁用日期
     * desc: 日期可选范围：景区设置-下单规则（startDate， endDate）和产品有效期(fixValidStartTime, fixValidEndTime)的并集
     * */
    disabledDate(now) {
      let current = now.valueOf();
      return (
        current < this.startDate ||
        current < this.fixValidStartTime ||
        current <
          moment()
            .startOf("day")
            .valueOf() ||
        (this.endDate
          ? current > this.endDate || current > this.fixValidEndTime
          : current > this.fixValidEndTime)
      );
    },
    /**
     * @name: 预约日期时段展示
     * @params: item 主产品行
     * @params: obj 子产品行
     * @params: addProduct 加购产品行
     * */
    showVisibleTime(item, obj, addProduct) {
      if (item.playDate) {
        this.visibleItemDate = moment(item.playDate);
      }
      // 默认是主产品修改日期和预约时段
      this.visibleItem = item;

      // 如果是组合 一子产品一票
      if (obj) {
        if (obj.playDate) this.visibleItemDate = moment(obj.playDate);
        this.visibleItem = obj;
        this.subObjParent = item;
        // this.visibleItem.timeIntervalId = obj.timeIntervalId;
        this.visibleObj = obj;
      } else {
        this.visibleObj = null;
        this.subObjParent = null;
      }

      // 如果是加购选择预约时段
      if (addProduct) {
        if (addProduct.playDate)
          this.visibleItemDate = moment(addProduct.playDate);
        this.visibleItem = addProduct;
        this.addProductParent = item;
      }
      // 主产品选择预约时段
      else {
        this.addProductParent = null;
      }
      // 设置产品有效期，禁用不可选日期
      this.fixValidStartTime = this.visibleItem.fixValidStartTime;
      // 后台存的是date类型，返回的是日期零点，要转换成23:59:59
      this.fixValidEndTime = moment(this.visibleItem.fixValidEndTime)
        .endOf("day")
        .valueOf();
      this.getTimes();
      this.visibleTime = true;
    },
    // 获取分时预约时段列表
    getTimes() {
      if (
        this.visibleItem.timeShareAppointmentRuleId ||
        this.subObjParent.timeShareAppointmentRuleId
      ) {
        // 如果接口有返回预约日期则使用接口日期,否则为景区设置-下单规则第一个日期
        let date = this.visibleItemDate
          ? this.visibleItemDate.valueOf()
          : this.startDate;
        appointmentTimeRuleStock({
          // 有子产品时，分时预约规则跟着主产品走 subObjParent.timeShareAppointmentRuleId
          ruleId:
            this.visibleItem.timeShareAppointmentRuleId ||
            this.subObjParent.timeShareAppointmentRuleId,
          date
        })
          .then(res => {
            // 过滤出可用时段
            this.visibleTimes = res.filter(
              temp =>
                temp.stock > 0 &&
                (date < temp.endTime ? temp.endTime : date) >
                  new Date().getTime()
            );
            if (this.visibleTimes.length === 0) {
              this.$message.warning("暂无可预约的时段或可预约时段没有库存");
            }
            this.visibleTime = true;
            // this.visibleItem.timeOpen = !this.visibleItem.timeOpen;
          })
          .catch(() => {});
      }
    },
    // 预约日期选择改变
    dateChange(visibleItem) {
      this.visibleTimes = [];
      visibleItem.timeIntervalId = "";
      this.getTimes();
    },
    // 主产品日期改变
    handleOk() {
      if (!this.visibleItemDate.valueOf() || !this.visibleItem.timeIntervalId) {
        this.$message.warning("请选择日期和时段");
        return;
      }
      // 更改日期参数
      const date = {
        cartId: this.cartId,
        playDate: this.visibleItemDate.valueOf(),
        productId: this.visibleItem.productId,
        index: this.visibleItem.index
      };
      if (this.visibleObj) {
        date.subProductId = this.visibleObj.productId;
        date.subIndex = this.visibleObj.index;
        date.productId = this.subObjParent.productId;
        date.index = this.subObjParent.index;
      }
      // 更改时段参数
      const time = { ...date };
      delete time.playDate;
      time.timeIntervalId = this.visibleItem.timeIntervalId;
      // 修改加购预约时段
      if (this.addProductParent) {
        this.updateAddBuy(
          {
            productId: this.addProductParent.productId,
            index: this.addProductParent.index,
            addBuyProductId: this.visibleItem.productId,
            timeShareAppointmentRuleId: this.visibleItem
              .timeShareAppointmentRuleId,
            timeIntervalId: this.visibleItem.timeIntervalId,
            playDate: this.visibleItemDate.valueOf()
          },
          this.visibleObj
        );
      }
      // 修改主产品预约时段
      else {
        this.$emit("update-date", date, time);
      }
      this.visibleTime = false;
    },
    // 删除购物车数量
    delItem(item, val) {
      const obj = {
        productId: item.productId,
        index: item.index,
        detailBuyNum: val
      };
      this.currentCardId = ""; // 凡是有删除，清空当前身份证号
      this.$emit("update-cart", obj);
    },
    // 修改数量
    changeNum(e, item) {
      let val = e.target.value;
      if (val) {
        const obj = {
          productId: item.productId,
          index: item.index,
          playDate: this.params.playDate,
          timeShareAppointmentRuleId: this.params.appointmentRules,
          timeIntervalId: this.params.timeId,
          subScenicId: this.params.subScenicId,
          detailBuyNum: val
        };
        this.$emit("update-cart", obj);
      }
    },
    // 修改游客信息
    /**
     * @params item Object  主产品行
     * @params key String  校验的key
     * @params val Object  动态表单行
     * @params temp Object  子产品行
     * */
    changePlayer(item, key, val = {}, temp) {
      if (this.isReadCard) return;
      this.$refs.formData.validateField(key, valid => {
        if (!valid) {
          this.discountSku = {};
          this.changePlayerItem = item;
          // 如果是身份证情况下，且已选择时段，则进行惠民弹框
          if (
            val.fieldType === 6 &&
            val.fieldValue &&
            val.fieldValue.trim() &&
            item.appointmentTimePO &&
            item.appointmentTimePO.appointmentBeginTime
          ) {
            let obj = {
              playDate: this.params.playDate, // 游玩日期
              idCard: val.fieldValue, // 身份证号
              timeIntervalId: item.timeIntervalId, // 时段id
              timeShareAppointmentRuleId: this.params.appointmentRules, // 分时预约id
              //   // 多规格 item.specCombination === 1, 否则单规格
              productId: item.productId, // 产品id
              spuId: item.spuId, // 产品spuld
              subScenicId: this.params.subScenicId //子景区id 特惠产品要用到
            };
            selectDiscountSku(obj).then(res => {
              if (res.selectProduct && res.selectProduct.length > 1) {
                // 惠民列表产品大于1，提供选择弹框
                //选择特惠产品
                this.discountSkus = res.selectProduct;
                this.discountSku = res.selectProduct[0];
                this.showDiscountSku = true;
              } else if (res.selectProduct && res.selectProduct.length === 1) {
                // 惠民列表为1不弹框选中
                this.discountSku = res.selectProduct[0];
                this.getTicketTraveller(item, temp);
              } else {
                // 是身份证情况下，没惠民政策
                this.getTicketTraveller(item, temp);
              }
            });
          } else {
            // 非填写身份证情况下
            if (val.fieldType !== 6) {
              this.getTicketTraveller(item, temp);
            } else if (
              (val.fieldType === 6 && val.fieldValue) ||
              this.idCardFocusValue
            ) {
              // 如果当前为身份证，并且有值的情况下失去焦点调用
              this.getTicketTraveller(item, temp);
            }
          }
        }
      });
    },
    idCardFocus(val) {
      this.isFocus = true;
      this.idCardFocusValue = val;
    },
    // 人脸录入
    /**
     * item: 主产品行
     * res: 图片地址
     * obj: 子产品行
     * */
    faceChange(item, res, obj) {
      if (obj) obj.personFaceUrl = res;
      else item.personFaceUrl = res;
      this.getTicketTraveller(item, obj);
    },
    // 更新游客信息
    getTicketTraveller(item, temp) {
      this.currentTicketTraveller = item || temp;
      // 把凭证信息复制到更多游客信息
      let obj = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index,
        traveler: this.getTraveler(temp || item) // temp 为组合子产品行，item为主产品行
      };
      // 惠民政策
      if (this.discountSku.selectProductSkuId) {
        obj.discountProductId = this.discountSku.selectProductSkuId;
      }
      // 如果是组合产品 一子产品一游客时
      if (temp) {
        obj.subProductId = temp.productId;
        obj.subIndex = temp.index;
      }
      this.$emit("update-player", obj, this.callback);
    },
    callback() {
      this.currentTicketTraveller.IdTypeList.forEach(item => {
        // 更新购物车报错时清空身份证value
        if (item.fieldType === 6) item.fieldValue = "";
      });
    },
    //确认选择特惠产品
    sureDiscountSku() {
      this.getTicketTraveller(this.changePlayerItem);
      this.showDiscountSku = false;
    },
    // 获取游客信息
    getTraveler(item) {
      const obj = {
        visitorFieldList: item.visitorFieldList.map(temp => {
          return {
            id: temp.id,
            fieldType: temp.fieldType,
            fieldValue: temp.fieldValue
          };
        })
      };
      // 如果存在证件类型列表
      if (item.IdTypeList.length) {
        const arr = [];
        item.IdTypeList.forEach(temp => {
          // 如果凭证名称或证件类型选择了指定证件，则录入证件后到购物车，否则不录入
          if (temp.fieldType === item.IdType) {
            // 如果是身份证类型，又有性别时自动给选着性别
            let sexIndex = item.visitorFieldList.findIndex(
              te => te.fieldType === 8
            );
            if (item.IdType === 6 && temp.fieldValue && sexIndex !== -1) {
              // 1输入身份证可以自动写入，2.防止先选了性别，再填身份证，性别和证件的性别不符(身份证倒数第二位单数为男)
              let sexType = ["1", "3", "5", "7", "9"].includes(
                temp.fieldValue.slice(-2, -1)
              );
              obj.visitorFieldList[sexIndex].fieldValue = sexType ? "男" : "女";
            }
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: temp.fieldValue
            });
          } else {
            arr.push({
              id: temp.id,
              fieldType: temp.fieldType,
              fieldValue: ""
            });
          }
        });
        obj.visitorFieldList = obj.visitorFieldList.concat(arr);
      }
      // 游客类型
      if (item.visitorTypeId) obj.visitorTypeId = item.visitorTypeId;
      // 添加卡腕带号
      if (item.requiredVoucherNo) {
        // 为0不开启卡腕带录入
        obj.voucherNo = item.voucherNo;
      }
      // 添加人脸
      if (item.requiredPersonId) {
        // 为1不开启人脸录入
        obj.personId = item.personId;
        obj.personFaceUrl = item.personFaceUrl;
      }
      return obj;
    },
    // 快速通行
    fastChange(item, obj) {
      const data = {
        cartId: this.cartId,
        productId: item.productId,
        index: item.index
      };
      // 一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
        data.whetherFastTicket = obj.whetherFastTicket ? 1 : 0;
      }
      // 一种产品一游客、、票务
      else {
        data.whetherFastTicket = item.whetherFastTicket ? 1 : 0;
      }
      this.$emit("update-fast", data);
    },
    // 选择加购 mainItem: 如果是组合产品一子产品一游客则存在mainItem
    selectAdd(row, mainItem) {
      getTicketAddBuy({
        productId:
          mainItem.combUseRule === 2 ? mainItem.productId : row.productId,
        dailyCalendar: this.params.playDate
      })
        .then(res => {
          this.$refs.productAddBuy.loadData(res, row.subProductList);
          this.tempRow = row;
          if (mainItem.combUseRule === 2) {
            this.tempMainItem = mainItem;
          }
        })
        .catch(() => {});
    },
    // 选中加购
    checkAddBuy(item, temp, obj) {
      const val = temp.productFlagPO.whetherCheck ? 0 : 1;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          whetherCheck: val
        },
        obj
      );
    },
    // 加购加
    plusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: temp.productFlagPO.buyNum + 1
        },
        obj
      );
    },
    // 加购减
    minusAddBuy(item, temp, obj) {
      if (!temp.productFlagPO.whetheChangeNum) return;
      if (temp.productFlagPO.buyNum > 0)
        this.updateAddBuy(
          {
            productId: item.productId,
            index: item.index,
            addBuyProductId: temp.productId,
            addBuyNum: temp.productFlagPO.buyNum - 1
          },
          obj
        );
    },
    // 删除加购
    delAddBuy(item, temp, obj) {
      this.updateAddBuy(
        {
          productId: item.productId,
          index: item.index,
          addBuyProductId: temp.productId,
          addBuyNum: 0
        },
        obj
      );
    },
    // 更新加购产品
    addInputBlur(e, item, temp, obj) {
      const val = e.target.value;
      if (integerReg.test(val)) {
        this.updateAddBuy(
          {
            productId: item.productId,
            index: item.index,
            addBuyProductId: temp.productId,
            addBuyNum: val
          },
          obj
        );
      }
    },
    // 修改弹窗加购产品数量
    updateAddBuyBatch(data) {
      let obj = {};
      // combUseRule === 2 组合产品：一子产品一游客需要subIndex和subProductId
      if (this.tempMainItem.combUseRule === 2) {
        obj = {
          productId: this.tempMainItem.productId,
          index: this.tempMainItem.index,
          subProductId: this.tempRow.productId,
          subIndex: this.tempRow.index,
          ...data
        };
      } else {
        obj = {
          productId: this.tempRow.productId,
          index: this.tempRow.index,
          ...data
        };
      }
      obj.cartId = this.cartId;
      commonAddBuyBatch(obj)
        .then(res => {
          this.$emit("change-cart", res);
        })
        .catch(() => {});
    },

    // 更新加购
    updateAddBuy(data, obj) {
      // 如果是组合产品：一子产品一游客
      if (obj) {
        data.subProductId = obj.productId;
        data.subIndex = obj.index;
      }
      this.$emit("update-add-buy", data);
    },
    // 刷身份证自动匹配产品到购物车
    changeCard(info) {
      this.isFocus = false;
      let obj = {
        formatType: this.formatType,
        playDate: this.params.playDate, // 游玩日期
        subScenicId: this.params.subScenicId, // 子景区id
        timeIntervalId: this.params.timeId,
        cartId: this.cartId || "",
        travelers: [
          {
            visitorFieldList: [
              {
                id: "1380460304389713921",
                fieldType: 6,
                fieldValue: info.idCard
              }
            ]
          }
        ]
      };
      // 票务和游乐用同一个接口
      let api = ticketCommonlyVisitiorBatch;
      let type = this.formatType;
      if (type == 13) {
        // 组合
        api = combinationVisitiorBatch;
      }
      api(obj).then(res => {
        this.$emit("change-cart", res);
      });
    },
    // 购物车改动后更新
    updateCart({ detailProductList, id }) {
      this.cartId = id;
      // 处理购物车返回的数据
      const dealData = item => {
        // 分时预约按子景区走则使用头部筛选条件的，否则通过接口获取时段列表
        // const arr = this.appointment !== 1 ? this.times : [];
        // 过滤出可用时段(库存大于0 且 时段大于当前选中日期)
        // const times = arr.filter(temp => {
        //   return (
        //     temp.remainingStock > 0 &&
        //     (this.params.playDate < temp.endTime
        //       ? temp.endTime
        //       : this.params.playDate > new Date().getTime())
        //   );
        // });
        // 处理出游人信息字段，把身份证、护照、港澳通行证、台胞证统一合并为证件类型，只能填写一个
        const visitorFieldList1 = [];
        const visitorFieldList2 = [];
        let IdType = "";
        // 防止后台不返回traveler字段报错
        item.traveler = item.traveler || {
          visitorFieldList: [],
          memberTouristList: []
        };
        item.traveler.visitorFieldList.forEach(temp => {
          if (
            temp.fieldType === 6 ||
            temp.fieldType === 17 ||
            temp.fieldType === 18 ||
            temp.fieldType === 19 ||
            temp.fieldType === 21
          ) {
            visitorFieldList1.push(temp);
            if (temp.fieldValue) {
              IdType = temp.fieldType;
            }
          } else {
            visitorFieldList2.push(temp);
          }
        });

        return {
          index: item.index, // 序号
          productId: item.productId,
          spuId: item.spuId,
          productName: item.productName, // 产品名称
          whetherSpecialDiscount: item.productFlagPO.whetherSpecialDiscount, // 是否特惠产品
          oldProductName: item.productFlagPO.oldProductName, // 特惠产品前旧产品名称
          ownerMerchantName: item.ownerMerchantName, // 商户名称
          sellingAmount: item.sellingAmount / 100, // 价格
          buyNum: item.productFlagPO.buyNum, // 数量
          buyNumDiasabled: false, // 修改数量禁用
          IdType:
            IdType ||
            (visitorFieldList1[0] ? visitorFieldList1[0].fieldType : ""), // 证件类型
          IdTypeList: visitorFieldList1, // 证件类型列表
          visitorFieldList: visitorFieldList2, // 游客信息
          memberTouristList: item.traveler.memberTouristList, // 游客类型列表
          visitorTypeId: item.traveler.visitorTypeId, // 选中的游客类型
          wristbandPrice: item.traveler.wristbandPrice, // 游客信息
          fast: item.traveler.fast, // 是否开启快票功能
          whetherFastTicket: !!item.traveler.whetherFastTicket, // 是否打开了快票
          timeIntervalId: item.appointmentTimePO
            ? item.appointmentTimePO.timeIntervalId
            : "", // 选中的时段id
          appointmentBeginTime: item.appointmentTimePO?.appointmentBeginTime, // 预约开始时段
          appointmentEndTime: item.appointmentTimePO?.appointmentEndTime, // 预约结束时段
          playerType: "", // 游客类型
          numDisabled: false, // 修改数量禁用
          // 加购产品列表
          subProductList: item.subProductList.map(temp => {
            // 设置时段选择
            this.$set(
              temp,
              "timeIntervalId",
              temp.appointmentTimePO
                ? temp.appointmentTimePO.timeIntervalId
                : ""
            );
            // this.$set(temp, "times", times);
            // this.$set(temp, "timeOpen", false);
            return temp;
          }),
          whetherRecommend: item.whetherRecommend, // 是否有推荐加购
          personFaceUrl: item.traveler.personFaceUrl, // 人脸头像
          personId: item.traveler.personId, // 人脸头像id
          timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 产品是否开启分时预约
          // timeOpen: false,
          playDate: item.playDate,
          appointmentTimePO: item.appointmentTimePO,
          visibleTime: false,
          voucherNo: item.traveler.voucherNo || "",
          requiredPersonId: item.traveler.requiredPersonId,
          requiredPersonTrue: item.traveler.requiredPersonTrue,
          requiredVoucherNo: item.traveler.requiredVoucherNo,
          fixValidStartTime: item.fixValidStartTime, // 产品有效期
          fixValidEndTime: item.fixValidEndTime // 产品有效期
        };
      };
      this.formData.cartList = detailProductList.map(item => {
        // 如果使用规则是一子产品一游客，则游客信息放在
        if (item.productFlagPO.combUseRule === 2) {
          return {
            productId: item.productId,
            index: item.index, // 序号
            productName: item.productName, // 组合产品名称
            sellingAmount: item.sellingAmount, // 组合产品价格
            combUseRule: 2, // 一子产品一游客
            whetherRecommend: item.whetherRecommend, // 是否有加购
            timeShareAppointmentRuleId: item.timeShareAppointmentRuleId, // 时段规则id
            // timeOpen: false,
            visibleTime: false,
            // 子产品列表
            subProductList: item.subProductList.map(temp => {
              return dealData(temp);
            })
          };
        } else {
          return dealData(item);
        }
      });
      // 更新弹窗加购产品数量
      // if (this.$refs.productAddBuy && this.tempRow) {
      //   const row = this.formData.cartList.find(
      //     item => item.index === this.tempRow.index
      //   );
      //   if (row) this.$refs.productAddBuy.updateData(row.subProductList);
      // }
      /**
       * 批量轮询身份证自动填入对应信息
       */
      let list = this.formData.cartList;
      // 如果清空了购物车，初始化数据
      if (!list.length) {
        this.isReadCard = false; // 记录当前是否是读取身份证调用
        this.currentCardId = ""; // 用于记录当前读取身份号
        this.isFocus = false; // 清空完购物车关闭自动获取焦点
      }
      // 如果开启了自动聚焦身份证
      if (this.isFocus) {
        for (let i = 0; i < list.length; i++) {
          let tem = list[i];
          // 组合（一子产品一游客）
          if (tem.combUseRule === 2) {
            for (let j = 0; j < tem.subProductList.length; j++) {
              let subProductItem = tem.subProductList[j];
              // 查找列表是否有身份证录入
              let subIndex = subProductItem.IdTypeList.findIndex(
                te => te.fieldType === 6
              );
              // 如果找到身份证且没值时，自动聚焦
              if (
                subIndex !== -1 &&
                !subProductItem.IdTypeList[subIndex].fieldValue &&
                subProductItem.IdType == 6
              ) {
                this.$nextTick(() => {
                  this.$refs[`idCardInput${i}${j}`][0].focus();
                });
                return;
              }
            }
          } else {
            // 查找列表是否有身份证录入
            let k = tem.IdTypeList.findIndex(te => te.fieldType === 6);
            // 如果找到身份证且没值时，自动聚焦
            if (k !== -1 && !tem.IdTypeList[k].fieldValue && tem.IdType == 6) {
              this.$nextTick(() => {
                this.$refs[`idCardInput${i}`][0].focus();
              });
              return;
            }
          }
        }
      }
    },
    // 人脸、证件识别涉及的方法区域 （如果是组合产品：一子产品一游客 val为obj）
    readCard(res, val, key, temp, valItem) {
      this.currentCardId = res.id;
      val.visitorFieldList.forEach(item => {
        //填充姓名
        if (item.fieldType == 4) {
          item.fieldValue = res.name;
        } else if (item.fieldType == 7) {
          //生日,分组匹配出年月日，再转换成moment格式
          // let reg = /(\d+)-(\d+)-(\d+)/;
          // let target = res.birth.match(reg);
          // let birthday = moment()
          //   .year(target[1])
          //   .month(Number(target[2]) - 1)
          //   .date(target[3])
          //   .format("YYYY-MM-DD");
          item.fieldValue = res.birth;
        } else if (item.fieldType == 8) {
          //性别(2男，1女)
          if (res.sex === "男") {
            item.fieldValue = "男";
          } else {
            item.fieldValue = "女";
          }
        } else if (item.fieldType == 9) {
          this.isReadCard = true;
          //证件照
          fileUploadBase64({ data: res.photo, ext: "png" })
            .then(value => {
              this.$set(item, "fieldValue", value.src);
              if (val.requiredPersonId) {
                // 如果有人脸
                this.getPicture(res.photo, val, key, temp, valItem);
              } else {
                if (!valItem) {
                  this.isReadCard = false;
                  this.changePlayer(val, key, temp);
                } else {
                  // 一子产品一游客
                  this.isReadCard = false;
                  this.changePlayer(valItem, key, temp, val);
                }
              }
            })
            .catch(() => {
              this.isReadCard = false;
              this.$message.error("图片上传接口vv错误");
            });
        } else if (item.fieldType == 12) {
          // 地址
          item.fieldValue = res.address;
        }
      });
      // if (this.formItemData.requiredPersonId) this.getPicture(res.photo);
    },
    getPicture(data64, val, key, temp, valItem) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      //this.$message.success({ content: this.imgbase64, duration: 10 });
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then(res => {
          // this.$message.success(JSON.stringify(res));
          this.saveFace(res.src, val, key, temp, valItem);
        })
        .catch(() => {
          this.isReadCard = false;
        });
    },
    // 保存人脸
    saveFace(url, val, key, temp, valItem) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      face(postData)
        .then(res => {
          val.personFaceUrl = url;
          val.personId = res.personId;
          if (!valItem) {
            this.isReadCard = false;
            this.changePlayer(val, key, temp);
          } else {
            // 一子产品一游客
            this.isReadCard = false;
            this.changePlayer(valItem, key, temp, val);
          }
        })
        .catch(() => {
          this.isReadCard = false;
        });
    },
    // 校验购物车表单
    validate() {
      let ok = true;
      // 如果购物车需要填游客信息则需要校验游客信息合法性
      if (this.$refs.formData) {
        this.$refs.formData.validate(valid => {
          if (!valid) {
            this.$message.warning("请正确填写购物信息");
            ok = false;
          } else {
            ok = this.checkPass();
          }
        });
      }
      return ok;
    },
    checkPass() {
      let phoneKeyObj = {};
      let idCardKeyObj = {};
      this.formData.cartList.forEach(item => {
        // 纪录手机号，身份证
        let phone =
          item.visitorFieldList.find(te => te.fieldType === 5) &&
          item.visitorFieldList.find(te => te.fieldType === 5).fieldValue;
        let card =
          item.IdTypeList.find(te => te.fieldType === 6) &&
          item.IdTypeList.find(te => te.fieldType === 6).fieldValue;
        if (phone) {
          // 手机号作为key,储存身份证号列表
          if (item.IdTypeList && item.IdTypeList.length) {
            item.IdTypeList.forEach(tem => {
              // 查看游客信息区域身份证并纪录
              if (tem.fieldType === 6 && tem.fieldValue) {
                if (!phoneKeyObj[phone]) {
                  phoneKeyObj[phone] = [tem.fieldValue];
                } else {
                  phoneKeyObj[phone].push(tem.fieldValue);
                }
              }
            });
          }
        }
        if (card) {
          // 检查身份证时
          item.visitorFieldList.forEach(tem => {
            // 身份证作为key，存储手机号列表
            if (tem.fieldType === 5 && tem.fieldValue) {
              if (!idCardKeyObj[card]) {
                idCardKeyObj[card] = [tem.fieldValue];
              } else {
                idCardKeyObj[card].push(tem.fieldValue);
              }
            }
          });
        }
      });
      let isTrue = false; // 用来判断手机号相同下，身份证是否一致
      let isTrue2 = false; // 用来判断身份证相同下手机号是否为一致
      for (let key in phoneKeyObj) {
        // 循环手机号为key的对象，查看身份证是否一致
        let setArr = [...new Set(phoneKeyObj[key])];
        if (phoneKeyObj[key].length > 1) {
          // 去重之后有多个，说明身份证都不一致，需要提示
          isTrue = setArr.length !== 1;
          break;
        }
      }
      for (let key in idCardKeyObj) {
        // 循环身份证为key的对象，查看手机号是否一致
        let setArr = [...new Set(idCardKeyObj[key])];
        if (idCardKeyObj[key].length > 1) {
          // 去重之后有多个，说明身份证都不一致，需要提示
          isTrue2 = setArr.length !== 1;
          break;
        }
      }
      if (isTrue || isTrue2) {
        this.$message.error(
          isTrue
            ? "同一号码下,身份证号需保持一致!"
            : "同一身份证号,手机号需保持一致!"
        );
        return false;
      } else return true;
    }
  }
};
</script>

<style scoped lang="less">
@import "ticket-order-common";
/*重置antd表单样式*/
.ant-form {
  color: #333;
}
.ant-form-inline .ant-form-item {
  margin-right: 0;
  width: 100%;
  /deep/.ant-form-item-control-wrapper {
    width: 100%;
  }
}

/*加减号*/
.minus {
  color: #a4a4a4;
  font-size: 24px;
  cursor: pointer;
}
.number {
  margin: 0 16px;
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  color: #25a2f2;
}
/*减号*/
.plus {
  font-size: 24px;
  color: #25a2f2;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.input-number {
  margin: 0 4px;
  border: none;
  width: 30px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 18px;
  outline-color: #dcdfe6;
  color: #25a2f2;
  background: #f1f1f1;
}

.not-allow {
  .minus,
  .plus,
  .input-number {
    cursor: not-allowed;
  }
}

.hui {
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  background: #f32a2a;
  color: #fff;
}
.label {
  margin-top: 10px;
}

.clear-margin-top {
  margin-top: 0;
}
.idCard {
  /deep/.ant-select {
    height: 31px;
  }
}
</style>
