<!--
登记出游人信息页面
填写出游人信息 base form表单
Time : 2022/1/6
Name : 陈福明
fieldType : 
1 自定义文本框 2 自定义日期选择 3 自定义下拉框 4 姓名 5 手机号 6 身份证号码 7 生日 8 性别 9 证件照 10 司机姓名 11 司机车牌号 12 地址 13 邮箱 14 微信号 15 证件类型 16 国家 17 台胞证 18 港澳通行证 19 护照 20 游客类型
-->

<template>
  <div class="base-form ml-2x mt-3x">
    <!-- 卡/腕带 -->
    <a-form-model
      ref="voucherForm"
      :label-col="labelCol"
      :model="formItemData"
      :wrapper-col="wrapperCol"
      label-align="left"
      v-if="formItemData.requiredVoucherNo"
    >
      <a-form-model-item
        label="卡/腕带编号"
        prop="voucherNo"
        :rules="[
          {
            required: true,
            message: '请输入卡/腕带编号',
            trigger: 'blur'
          }
        ]"
      >
        <a-input
          v-model="formItemData.voucherNo"
          placeholder="请输入卡/腕带编号"
        />
      </a-form-model-item>
    </a-form-model>
    <!-- 人脸录入 -->
    <a-form-model
      ref="personIdForm"
      :model="formItemData"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      v-if="formItemData.requiredPersonId"
    >
      <a-form-model-item
        label="人脸信息"
        prop="personId"
        :required="false"
        :rules="[
          {
            required: false,
            message: '请录入人脸'
          }
        ]"
      >
        <face-input
          v-model="formItemData.personId"
          :key="Math.random()"
          @imgUrlChange="
            res => {
              formItemData.personFaceUrl = res;
            }
          "
          :echoimgurl="formItemData.personFaceUrl"
        ></face-input>
      </a-form-model-item>
    </a-form-model>

    <!-- 其他的动态表单 -->
    <a-form-model
      ref="ticketsInfoForm"
      :model="item"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      v-for="(item, index) in formItemData.visitorFieldList"
      :key="index"
    >
      <!-- 手机号 -->
      <a-form-model-item
        ref="phone"
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 5"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入手机号',
            trigger: ['change', 'blur']
          },
          {
            type: 'number',
            message: '手机号需为数字'
          },
          {
            validator: checkPhone
          }
        ]"
      >
        <a-input v-model.number="item.fieldValue" />
      </a-form-model-item>
      <!-- 身份证号码 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 6"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入身份证号',
            trigger: ['change', 'blur']
          },
          {
            message: '身份证格式错误'
          },
          {
            validator: checkIdCard
          }
        ]"
      >
        <IdCardInput
          v-model="item.fieldValue"
          @readCard="readCard"
        ></IdCardInput>
      </a-form-model-item>

      <!-- 社保卡 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 21"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入社保卡号',
            trigger: ['change', 'blur']
          },
          {
            message: '社保卡号格式错误'
          },
          {
            validator: checkNormal
          }
        ]"
      >
        <IdCardInput
          medical-card
          v-model="item.fieldValue"
          placeholder="请刷社保卡/输入社保卡号"
          @readCard="readXbCard"
        ></IdCardInput>
      </a-form-model-item>

      <!-- 姓名 -->
      <a-form-model-item
        ref="name"
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 4"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入姓名',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>

      <!-- 地址 -->
      <a-form-model-item
        ref="address"
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 12"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入地址',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>

      <!-- 生日 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 7"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择生日时间',
            triggger: 'change'
          }
        ]"
      >
        <a-date-picker
          v-model="item.fieldValue"
          type="date"
          :disabled-date="disabledDate"
          @change="
            (...params) => {
              item.fieldValue = params[1];
            }
          "
          style="width: 100%"
          format="YYYY-MM-DD"
        />
      </a-form-model-item>
      <!-- 性别 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 8"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择性别',
            triggger: 'change'
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option value="1">女</a-select-option>
          <a-select-option value="2">男</a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- 证件照 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 9"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请拍摄证件照',
            triggger: 'change'
          }
        ]"
      >
        <picture-input class="m-x" v-model="item.fieldValue"></picture-input>
      </a-form-model-item>
      <!-- 10 司机姓名 -->
      <a-form-model-item
        ref="name"
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 10"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入司机姓名',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 11 司机车牌号 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 11"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入司机车牌号',
            trigger: ['change', 'blur']
          },
          {
            message: '错误的车牌号'
          },
          {
            validator: checkLicensePlate
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 12 地址 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 12"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入地址',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 13 邮箱 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 13"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入邮箱',
            trigger: ['change', 'blur']
          },
          {
            message: '邮箱格式错误'
          },
          {
            validator: checkEmail
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 14 : 微信号 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 14"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入微信号',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 15 证件类型 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 15"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择证件类型',
            triggger: 'change'
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option
            v-for="(temp, index) in item.fieldOptions"
            :key="index"
            :value="temp"
            >{{ temp }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <!-- 16 国家 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 16"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择你的国家',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option
            v-for="(temp, index) in item.fieldOptions"
            :key="index"
            :value="temp"
            >{{ temp }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <!-- 17 台胞证 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 17"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入台胞证',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 18 港澳通行证 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 18"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入港澳通行证',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 19 护照 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 19"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请输入护照',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>
      <!-- 20 : 游客类型 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="item.whetherSystemField && item.fieldType == 20"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择游客类型',
            trigger: ['change', 'blur']
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>

      <!-- 自定义文本框 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="!item.whetherSystemField && item.fieldType == 1"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '必填项不能为空',
            triggger: 'blur'
          }
        ]"
      >
        <a-input v-model="item.fieldValue" />
      </a-form-model-item>

      <!-- 自定义日期选择 -->
      <a-form-model-item
        :label="item.fieldName"
        v-if="!item.whetherSystemField && item.fieldType == 2"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '请选择日期',
            triggger: 'change'
          }
        ]"
      >
        <a-date-picker
          type="date"
          v-model="item.fieldValue"
          @change="
            (...params) => {
              item.fieldValue = params[1];
            }
          "
          style="width: 100%"
          format="YYYY-MM-DD"
        />
      </a-form-model-item>

      <!-- 自定义下拉框-->
      <a-form-model-item
        :label="item.fieldName"
        v-if="!item.whetherSystemField && item.fieldType == 3"
        prop="fieldValue"
        :rules="[
          {
            required: item.required,
            message: '必填项不能为空',
            triggger: 'blur'
          }
        ]"
      >
        <a-select v-model="item.fieldValue">
          <a-select-option
            v-for="(son, index) in item.fieldOptions"
            :value="son"
            :key="index"
            >{{ son }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import IdCardInput from "@/components/hardware/IdCardInput";
import FaceInput from "./hardware/FaceInput.vue";
import {
  checkPhone,
  checkIdCard,
  checkLicensePlate,
  checkEmail,
  checkNormal
} from "../utils/global";
import PictureInput from "./hardware/PictureInput.vue";
import moment from "moment";
import { faceAnalyzeUpload, face } from "@/api/iot";
import { fileUploadBase64 } from "../api/upload";
export default {
  components: {
    IdCardInput,
    FaceInput,
    PictureInput
  },
  props: {
    formItemData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      checkNormal,
      checkPhone,
      checkIdCard,
      checkLicensePlate, // 校验车牌号
      checkEmail, // 邮箱校验
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      rules: {},
      voucherNo: ""
    };
  },
  watch: {
    formItemData: {
      handler: function(newVal) {
        if (newVal.visitorFieldList) {
          //处理身份证 6和证件照9 二选一必填 打补丁写死
          let isTwo =
            newVal.visitorFieldList.some(item => item.fieldType == 6) &&
            newVal.visitorFieldList.some(item => item.fieldType == 9);
          if (isTwo) {
            newVal.isTwo = true;
            newVal.visitorFieldList.forEach(item => {
              if ([6, 9].includes(item.fieldType)) {
                item.required = false;
              }
            });
          }
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    moment,
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 购票人信息表单检验
    validateForm() {
      let flagArr = [];
      //验证其他动态表单
      if (this.$refs["ticketsInfoForm"]) {
        for (let i = 0; i < this.$refs["ticketsInfoForm"].length; i++) {
          this.$refs["ticketsInfoForm"][i].validate(valid => {
            valid ? flagArr.push(true) : flagArr.push(false);
          });
        }
      }
      //验证卡腕带
      if (this.formItemData.requiredVoucherNo) {
        this.$refs["voucherForm"].validate(valid => {
          valid ? flagArr.push(true) : flagArr.push(false);
        });
      }
      //验证人脸录入
      if (this.formItemData.requiredPersonId) {
        this.$refs["personIdForm"].validate(valid => {
          valid ? flagArr.push(true) : flagArr.push(false);
        });
      }

      return flagArr.every(item => item);
    },
    // 读取社保卡
    readXbCard(res) {
      // 给身份证赋值
      const temp = this.formItemData.visitorFieldList.find(
        val => val.fieldType === 6
      );
      if (temp) temp.fieldValue = res.id;
    },
    // 读取身份证
    readCard(res) {
      // console.log(res, "省份证读卡的信息---*------");
      this.formItemData.visitorFieldList.forEach(item => {
        //填充姓名
        if (item.fieldType == 4) {
          item.fieldValue = res.name;
        } else if (item.fieldType == 7) {
          //生日,分组匹配出年月日，再转换成moment格式
          // let reg = /(\d+)-(\d+)-(\d+)/;
          // let target = res.birth.match(reg);
          // let birthday = moment()
          //   .year(target[1])
          //   .month(Number(target[2]) - 1)
          //   .date(target[3])
          //   .format("YYYY-MM-DD");
          item.fieldValue = res.birth;
        } else if (item.fieldType == 8) {
          //性别(2男，1女)
          if (res.sex === "男") {
            item.fieldValue = "2";
          } else {
            item.fieldValue = "1";
          }
        } else if (item.fieldType == 9) {
          //证件照
          fileUploadBase64({ data: res.photo, ext: "png" })
            .then(res => {
              item.fieldValue = res.src;
              this.formItemData.personFaceUrl = res.src;
            })
            .catch(err => {
              console.log(err);
              this.$message.error("图片上传接口错误");
            });
        } else if (item.fieldType == 12) {
          // 地址
          item.fieldValue = res.address;
        }
      });

      if (this.formItemData.requiredPersonId) this.getPicture(res.photo);
    },

    getPicture(data64) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      //this.$message.success({ content: this.imgbase64, duration: 10 });
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then(res => {
          // this.$message.success(JSON.stringify(res));
          this.saveFace(res.src);
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveFace(url) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      face(postData)
        .then(res => {
          this.imgUrl = url;
          // this.$message.success("人脸录入成功");
          // this.$emit("input", res.personId);
          this.formItemData.personId = res.personId;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped></style>
