<!-- 门票充值 弹窗 -->

<template>
  <a-modal
    class="ticket-recharge"
    :visible="vis"
    title="门票充值"
    width="780px"
    :body-style="{
      'max-height': '460px'
    }"
    @cancel="cancel"
    :mask-closable="false"
  >
    <a-tabs type="card">
      <!-- 门票资产套餐 tab -->
      <a-tab-pane key="1" tab="门票资产套餐">
        <ul class="flex-wrap ul">
          <template v-if="tikectData.length">
            <li
              class="li mr-2x mb-2x"
              v-for="(item, index) in tikectData"
              :key="index"
            >
              <p class="mb-x">{{ item.name }}</p>
              <div class="mb-2x text-1" v-if="item.assetsInfoList">
                含：{{
                  item.assetsInfoList.length
                    ? item.assetsInfoList.toString()
                    : ""
                }}
              </div>
              <!-- 计算器 -->
              <div class="flex-between align-center">
                <span>{{ item.sellingPrice | money }}</span>
                <a-input
                  class="quantity"
                  size="small"
                  v-model="item.count"
                  @blur="inputBlur('1', item.count)"
                >
                  <a-icon
                    @click="minusClick('1', index)"
                    slot="addonBefore"
                    type="minus"
                  />
                  <a-icon
                    @click="plusClick('1', index)"
                    slot="addonAfter"
                    type="plus"
                  />
                </a-input>
              </div>
            </li>
          </template>
          <li v-else class="flex-center res">
            <a-empty />
          </li>
        </ul>
      </a-tab-pane>
      <!-- 项目套餐 tab -->
      <a-tab-pane key="2" tab="项目套餐">
        <ul class="flex-wrap ul">
          <template v-if="tikectData2.length">
            <li
              class="li mr-2x mb-2x li2"
              v-for="(item, index) in tikectData2"
              :key="index"
            >
              <p class="mb-x text-1">{{ item.name }}</p>
              <p class="mb-x">
                可玩次数：
                <span v-if="item.spuSpecInfo.whetherUseLimitType">
                  {{ item.spuSpecInfo ? item.spuSpecInfo.useLimit : "" }}
                  次
                </span>
                <span v-else>
                  不限
                </span>
              </p>
              <div class="mb-2x">
                可玩项目：
                <span v-if="item.spuSpecInfo.relationProjectType == 1">
                  全部
                </span>
                <span v-if="item.spuSpecInfo.relationProjectType == 2">
                  {{ relateList(item.projectInfoList).substr(0, 6) }}
                </span>
                <a-popover
                  trigger="hover"
                  class="ml-x"
                  v-if="item.spuSpecInfo.relationProjectType == 2"
                >
                  <template slot="content" v-if="item.projectInfoList">
                    <p
                      v-for="(item2, index) in item.projectInfoList"
                      :key="index"
                    >
                      {{ item2.name }}
                      <span class="ml-2x">可玩次数：</span>
                      <span
                        v-if="
                          item2.customRelation && item2.customRelation.lessLimit
                        "
                      >
                        最少：{{ item2.customRelation.lessLimit }}次
                      </span>
                      <span v-else>
                        不限
                      </span>
                      <span
                        v-if="
                          item2.customRelation && item2.customRelation.mostLimit
                        "
                      >
                        最多：{{ item2.customRelation.mostLimit }}次
                      </span>
                      <span v-else>
                        不限
                      </span>
                    </p>
                  </template>
                  <a-icon class="icon pointer" type="question" />
                </a-popover>
              </div>
              <!-- 计算器 -->
              <div class="flex-between align-center">
                <span>{{ item.sellingPrice | money }}</span>
                <a-input
                  class="quantity"
                  size="small"
                  v-model="item.count"
                  @blur="inputBlur('2', item.count)"
                >
                  <a-icon
                    @click="minusClick('2', index)"
                    slot="addonBefore"
                    type="minus"
                  />
                  <a-icon
                    @click="plusClick('2', index)"
                    slot="addonAfter"
                    type="plus"
                  />
                </a-input>
              </div>
            </li>
          </template>
          <li v-else class="flex-center res">
            <a-empty />
          </li>
        </ul>
      </a-tab-pane>
    </a-tabs>
    <!-- 应收金额 -->
    <div class="flex-between" slot="footer">
      <div>
        应收金额：<span class="red">{{ totalMoney | money }}</span>
      </div>
      <div>
        <a-button key="cancel" @click="cancel">取消</a-button>
        <a-button
          type="primary"
          key="refund"
          @click="confirm"
          :loading="loading"
          >支付</a-button
        >
      </div>
    </div>
  </a-modal>
</template>
<script>
import { productFrontRechargeticket } from "../../../../api/search";
import { ticketRecharge, manyProduct } from "../../../../api/cart";
export default {
  name: "TicketRecharge",
  components: {},
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loading: false,
      tikectData: [], // 门票资产套餐数据
      tikectData2: [], // 项目套餐数据
      calcAmountTotal1: null, // 资产门票套餐总额
      calcAmountTotal2: null, // 资产门票套餐总额
      totalMoney: "", // 所有应收总额
      carId: "" // 购物车订单id
    };
  },
  watch: {
    vis(newVal) {
      if (newVal) {
        this.getCartId();
      }
    }
  },
  created() {
    Promise.all([this.getData(1), this.getData(2)]).then(res => {
      // console.log(res, "门票充值数据");
      this.tikectData = res[0];
      this.tikectData2 = res[1];
    });
  },
  methods: {
    getData(type) {
      return new Promise(resolve => {
        productFrontRechargeticket({
          packageType: type || 1, // 充值套餐类型 1: 门票资产套餐 2: 项目套餐
          page: 1,
          size: 9999
        })
          .then(res => {
            resolve(res.records);
          })
          .catch(() => {});
      });
    },
    // 生成购物车id
    getCartId() {
      ticketRecharge({
        ticketNo: this.detail.auxiliaryId
      })
        .then(res => {
          this.carId = res.id;
        })
        .catch(() => {});
    },
    // 可玩项目字符截取
    relateList(params) {
      const arr = [];
      if (params) {
        params.forEach(item => {
          arr.push(item.name);
        });
      }
      return arr.toString();
    },
    // 计算器 增加 按钮
    plusClick(type, index) {
      this.add({
        plusParam: type == 1 ? this.tikectData[index] : this.tikectData2[index], // 用于计算器加减数
        tikectData: type == 1 ? this.tikectData : this.tikectData2, // 用于计算应收总额
        type // 用于计算应收总额
      });
    },
    // 计算器 减少 按钮
    minusClick(type, index) {
      this.minus({
        plusParam: type == 1 ? this.tikectData[index] : this.tikectData2[index], // 用于计算器加减数
        tikectData: type == 1 ? this.tikectData : this.tikectData2,
        type
      });
    },
    // input blur
    inputBlur(type, value) {
      if (!/(^[0-9]\d*$)/.test(value)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      // type == 1 门票资产套餐TAB
      this.calcAmount({
        tikectData: type == 1 ? this.tikectData : this.tikectData2,
        type
      });
    },

    // 计算应收金额
    /**
     * 遍历每张票的价格乘以计算器的数值，得到每张票的总额，
     * 然后再遍历汇总所有门票的金额
     */
    calcAmount(param) {
      let mon = "";
      mon = param.tikectData
        .map((item, index) => {
          // 先计算每张票的额度
          return item.sellingPrice * param.tikectData[index].count;
        })
        .reduce((prev, cur) => {
          // 再计算所有票的总额
          return prev + cur;
        });
      if (param.type == 1) {
        this.calcAmountTotal1 = mon;
      } else {
        this.calcAmountTotal2 = mon;
      }
      this.totalMoney = this.calcAmountTotal1 + this.calcAmountTotal2;
    },

    // 计算器加减方法抽离(arr:每个计算器加减的数据)
    add(params) {
      if (!/(^[0-9]\d*$)/.test(params.plusParam.count)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      params.plusParam.count++;
      this.calcAmount(params);
    },
    // 计算器减少方法抽离
    minus(params) {
      if (!/(^[0-9]\d*$)/.test(params.plusParam.count)) {
        this.$message.warning("输入的数字必须为1以上的正整数");
        return;
      }
      if (params.plusParam.count <= 0) {
        this.$message.warning("已经是最小值了");
        return;
      }
      params.plusParam.count--;
      this.calcAmount(params);
    },

    // 关闭弹窗、及取消按钮
    cancel() {
      this.$emit("update:vis", false);
    },

    // 确定按钮
    confirm() {
      const arr = [...this.tikectData, ...this.tikectData2];
      let flag = false;
      const arr2 = arr.filter(item => {
        item.totalMoney = this.totalMoney;
        if (!item.count.toString()) {
          flag = true;
        }
        return item.count >= 1;
      });
      if (flag) {
        this.$message.warning("你还有套餐价格未输入");
        return;
      }
      if (arr2.length <= 0) {
        this.$message.warning("你还未选择套餐和套餐数量");
        return;
      }
      const params = {
        id: this.carId, // 生成的购物id
        buyChannels: 6, // 购买渠道
        businessCategory: 4, // 订单业务类型
        productList: []
      };
      arr2.forEach(item => {
        params.productList.push({
          productId: item.id,
          spuId: item.spuId,
          buyNum: item.count
        });
      });
      this.loading = true;
      manyProduct(params)
        .then(() => {
          this.$emit("update:vis", false);
          this.$store.dispatch("order/setCartList", { id: this.carId });
          this.$router.push({ path: "/home/sell/OrderInfo" });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";

.ul {
  display: flex;
  align-content: flex-start;
  .li {
    border-radius: @x;
    width: 31%;
    height: 106px;
    background-color: @bgGray;
    padding: 10px;
    box-sizing: border-box;
    .icon {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      color: #fff;
      padding: 1px;
    }
    .quantity {
      width: 120px;
      text-align: center;
    }
  }
  .li2 {
    height: 140px;
  }
}
/deep/.ant-modal-body {
  overflow-y: auto;
}
</style>
