<template>
  <div class="wrapper flex-col flex-between">
    <panel-head title="取票"></panel-head>
    <div class="main mt-2x relative">
      <KeyboardPro @ok="ok" v-model="numbers" type="3">
        <template v-slot:input="{ confirm }">
          <a-input
            size="large"
            allow-clear
            v-model="numbers"
            placeholder="订单号/取票号/手机号/身份证号/票号"
            @pressEnter="confirm()"
          >
            <a-icon slot="prefix" type="search" class="primary" />
            <span
              slot="addonAfter"
              class="iconfont iconcredentials_icon primary font-xxl"
            ></span>
          </a-input>
        </template>
      </KeyboardPro>

      <!--重新打印-->
      <footer class="text-c mt-3x">
        <span class="gray"
          >取票过程遇到 取票异常、出票中断、打印卡纸、打印缺纸？</span
        >
        <router-link to="/home/ticket/RePrint">
          <a-button type="link" size="large">重新打印门票</a-button>
        </router-link>
      </footer>
    </div>
  </div>
</template>

<script>
import panelHead from "../../../components/common/PanelHead.vue";
import KeyboardPro from "../../../components/common/KeyboardPro.vue";

import { orderTaketicket } from "../../../api/search";

export default {
  name: "GetTicket",
  data() {
    return {
      numbers: "",
      isClick: false // 是否在执行
    };
  },
  components: {
    panelHead: panelHead,
    KeyboardPro: KeyboardPro
  },
  methods: {
    ok() {
      // 身份证读卡功能，会连续执行几次
      if (this.isClick) {
        return;
      }
      this.isClick = true;
      if (!this.numbers) {
        this.isClick = false;
        this.$message.warning("请先输入号码");
        return;
      }
      orderTaketicket({
        keyword: this.numbers
      })
        .then(res => {
          // res: doorTicket未取普通门票数量，groupTicket未取团体票数量，takeTicket已取门票数量
          if (!res.doorTicket && !res.groupTicket && !res.takeTicket) {
            this.$message.warning("查无可取门票");
            this.isClick = false;
            return;
          }
          if (!res.doorTicket && !res.groupTicket && res.takeTicket) {
            this.$message.warning("所有门票已取，重取请执行门票补办操作");
            this.isClick = false;
            return;
          }
          res.keyword = this.numbers;
          sessionStorage.setItem("getTicketInfo", JSON.stringify(res));
          this.isClick = false;
          this.$router.push("/home/ticket/GetTicketDetail");
        })
        .catch(() => {
          this.isClick = false;
        });
    }
  },
  watch: {
    numbers(newVal, oldVal) {
      //身份证格式
      const reg = /^[0-9]*X?$/;
      if (!reg.test(newVal) && newVal !== "") {
        this.numbers = oldVal;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-top: 60px;
}
</style>
