<!-- 门票组合产品操作详情页 -->
<template>
  <div>
    <a-row class="ph-2x">
      <a-col :span="detail.ifExistTicketSub ? 24 : 12">
        <a-row>
          <a-col :span="detail.ifExistTicketSub ? 12 : 24">
            <div class="flex-start mt-2x">
              <div :span="5" class="gray">票号</div>
              <div :span="19">
                <span>{{ detail.auxiliaryId }}</span>
                <a-popover
                  class="ml-2x"
                  title="票号"
                  width="250"
                  trigger="click"
                >
                  <span class="blue pointer">详情</span>
                  <section
                    slot="content"
                    class="gray"
                    v-for="(item, index) in ticketSubDetailList || []"
                    :key="index"
                    :class="{ 'mt-2x': index > 0 }"
                  >
                    <p>主票号：{{ item.mainTicketNo }}</p>
                    <p v-if="item.subTicketNo" class="mt-x">
                      子票号：{{ item.subTicketNo }}
                    </p>
                    <p v-if="item.voucherNo" class="mt-x">
                      卡/腕带编号：{{ item.voucherNo }}
                    </p>
                  </section>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">实收金额</div>
              <div>
                <span class="red">{{ detail.receivedAmount | money }}</span>
                <a-popover class="ml-2x" title="价格详情" trigger="click">
                  <span class="blue pointer">详情</span>
                  <div class="gray" slot="content">
                    <p>产品单价：{{ detail.totalAmount | money }}</p>
                    <p class="mt-2x">
                      优惠余额：{{ detail.discountAmount | money }}
                    </p>
                    <p class="mt-2x">
                      优惠类型：{{ discountInfo.discountTypeName }}
                    </p>
                    <p class="mt-2x">
                      活动名称：{{ discountInfo.activityName }}
                    </p>
                    <p class="mt-2x">
                      实收金额：{{ detail.receivedAmount | money }}
                    </p>
                  </div>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">买家</div>
              <div>
                <span>{{ takerInfo }}</span>
                <a-popover
                  class="ml-2x"
                  title="买家信息"
                  width="250"
                  trigger="click"
                >
                  <span class="blue pointer">详情</span>
                  <section
                    slot="content"
                    class="gray mb-1x"
                    v-for="(item, index) in receiverInfo"
                    :key="index"
                  >
                    {{ item.fieldName }}:
                    <!-- <span v-if="item.fieldType === 8">{{
                    item.fieldValue === "1"
                      ? "女"
                      : item.fieldValue === "2"
                      ? "男"
                      : "未知"
                  }}</span> -->
                    <img
                      v-if="item.fieldType === 9"
                      :src="item.fieldValue"
                      alt=""
                      width="80"
                      height="80"
                      class="pointer"
                      @click="enlargeImg(item.fieldValue)"
                    />
                    <span v-else>{{ item.fieldValue }}</span>
                  </section>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">游客</div>
              <div>
                <span>{{ playerInfo }}</span>
                <a-popover class="ml-2x" title="游客信息">
                  <span class="blue pointer">详情</span>
                  <section
                    slot="content"
                    class="gray pv-2x"
                    :class="{ 'bd-t': index > 0 }"
                    v-for="(item, index) in visitorInfo || []"
                    :key="index"
                  >
                    <div
                      v-if="item.bindFaceUrl"
                      class="flex-start align-center mb-1x"
                    >
                      <p class="mr-x">人脸照:</p>
                      <img
                        width="80"
                        height="80"
                        class="pointer"
                        :src="item.bindFaceUrl"
                        alt="人脸图片"
                        @click="enlargeImg(item.bindFaceUrl)"
                      />
                    </div>
                    <p
                      v-for="(temp, i) in item.userFieldInfoList || []"
                      :key="'userFieldInfoList' + i"
                      :class="{ 'mt-1x': i > 0 }"
                    >
                      {{ temp.fieldName }}:
                      <span v-if="temp.fieldType === 8">{{
                        temp.fieldValue === "1"
                          ? "女"
                          : temp.fieldValue === "2"
                          ? "男"
                          : "未知"
                      }}</span>
                      <img
                        v-else-if="temp.fieldType === 9"
                        :src="temp.fieldValue"
                        alt=""
                        width="80"
                        height="80"
                        class="pointer"
                        @click="enlargeImg(temp.fieldValue)"
                      />
                      <span v-else>{{ temp.fieldValue }}</span>
                    </p>
                  </section>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">游玩日期</div>
              <div>{{ detail.useDate | date }}</div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">预约时段</div>
              <div>
                {{ detail.appointmentPeriodStartTime | date("hh:mm") }} ~
                {{ detail.appointmentPeriodEndTime | date("hh:mm") }}
              </div>
            </div>
          </a-col>
          <a-col :span="detail.ifExistTicketSub ? 12 : 24">
            <div class="flex-start mt-2x">
              <div class="gray">门票状态</div>
              <div class="primary">{{ detail.statusText }}</div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray w120">组合产品核销规则</div>
              <div class="primary">
                {{
                  detail.ticketWriteOffConfigInfo.whetherCombinationCheck
                    ? "开启"
                    : "不开启"
                }}
                <span
                  v-if="detail.ticketWriteOffConfigInfo.whetherCombinationCheck"
                >
                  {{
                    detail.ticketWriteOffConfigInfo.combinationCheckType == 1
                      ? "一次核销一票"
                      : "一次核销全部"
                  }}
                </span>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">会员信息</div>
              <div>
                <span>{{
                  ticketMemberInfo.idCardNumber || ticketMemberInfo.mobile
                }}</span>
                <a-popover
                  class="ml-2x"
                  title="会员信息"
                  width="250"
                  trigger="click"
                >
                  <span class="blue pointer">详情</span>
                  <div class="gray" slot="content">
                    <p>姓名: {{ ticketMemberInfo.name }}</p>
                    <p class=" mt-1x">手机号: {{ ticketMemberInfo.mobile }}</p>
                    <p class=" mt-1x">
                      身份证: {{ ticketMemberInfo.idCardNumber }}
                    </p>
                  </div>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">订单信息</div>
              <div>
                <span>{{ detail.orderId }}</span>
                <a-popover
                  class="ml-2x"
                  title="取票人信息"
                  width="250"
                  trigger="click"
                >
                  <span class="blue pointer">详情</span>
                  <div class="gray" slot="content">
                    <p>订单编号: {{ orderBasisInfo.orderId }}</p>
                    <p class=" mt-1x">
                      下单时间:
                      {{
                        orderBasisInfo.createTime | date("yyyy-mm-dd hh:mm:ss")
                      }}
                    </p>
                    <p class=" mt-1x">
                      销售商户:
                      {{ orderBasisInfo.orderSaleMerchantName }}
                    </p>
                    <p class=" mt-1x">
                      销售渠道: {{ orderBasisInfo.buyChannelsText }}
                    </p>
                    <p class=" mt-1x">收款备注: {{ orderBasisInfo.remarks }}</p>
                    <p class=" mt-1x">
                      销售操作人: {{ orderBasisInfo.salespersonName }}
                    </p>
                    <p class=" mt-1x">
                      支付方式: {{ orderBasisInfo.payWayText }}
                    </p>
                    <p class=" mt-1x">
                      支付通道: {{ orderBasisInfo.payChannels }}
                    </p>
                    <p class=" mt-1x">
                      外部订单号: {{ orderBasisInfo.outsideCode }}
                    </p>
                  </div>
                </a-popover>
              </div>
            </div>
            <div class="flex-start mt-2x">
              <div class="gray">使用有效期</div>
              <div>
                {{ detail.beginValidTime | date }} ~
                {{ detail.endValidTime | date }}
              </div>
            </div>
            <div class="flex-start mt-2x" v-if="detail.orderRefundInfo">
              <div class="gray">退款信息</div>
              <div>
                <span>{{ detail.orderRefundInfo.refundId }}</span>
                <a-popover
                  class="ml-2x"
                  title="取票人信息"
                  width="250"
                  trigger="click"
                >
                  <span class="blue pointer">详情</span>
                  <div class="gray" slot="content">
                    <p>退款单号: {{ orderRefundInfo.refundId }}</p>
                    <p class=" mt-1x">
                      退款时间:
                      {{
                        orderRefundInfo.refundCompleteTime
                          | date("yyyy-mm-dd hh:mm:ss")
                      }}
                    </p>
                    <p class=" mt-1x">
                      退款渠道: {{ orderRefundInfo.refundPasswayName }}
                    </p>
                    <p class=" mt-1x">
                      退款备注: {{ orderRefundInfo.remarks }}
                    </p>
                    <p class=" mt-1x">
                      退款操作人:
                      {{ orderRefundInfo.refundAuditPersonName }}
                    </p>
                    <p class=" mt-1x">
                      退款通道: {{ orderRefundInfo.refundPasswayName }}
                    </p>
                    <p class=" mt-1x">
                      外部退款号: {{ orderRefundInfo.refundutsideCode }}
                    </p>
                  </div>
                </a-popover>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12" v-if="!detail.ifExistTicketSub">
        <div class=" flex-start mt-2x">
          <div class="gray">可使用日期</div>
          <div>
            {{
              ticketWriteOffConfigInfo.checkTravelDateType === 0
                ? "门票有效期内都可核销"
                : "仅预约游玩日期可核销"
            }}
          </div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">可核销次数</div>
          <div v-if="ticketWriteOffConfigInfo.enterTimesType">
            {{ ticketWriteOffConfigInfo.enterTimes }}
          </div>
          <div v-else>不限制</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">每日可核销</div>
          <div v-if="ticketWriteOffConfigInfo.dailyEnterTimesType">
            {{ ticketWriteOffConfigInfo.dailyEnterTimes }}
          </div>
          <div v-else>不限制</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">可核销月份</div>
          <div>{{ ticketWriteOffConfigInfo.validMonths }}</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">可核销星期</div>
          <div>{{ ticketWriteOffConfigInfo.validWeeks }}</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">可核销时段</div>
          <div v-if="ticketWriteOffConfigInfo.useTimeType">
            <template v-if="ticketWriteOffConfigInfo.useTimeRange">
              {{ ticketWriteOffConfigInfo.useTimeRange[0] | date("hh:mm") }} ~
              {{ ticketWriteOffConfigInfo.useTimeRange[1] | date("hh:mm") }}
            </template>
          </div>
          <div v-else>不限制</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">已核销次数</div>
          <div>{{ detail.alreadyWriteOffTimes }}</div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">首次核销时间</div>
          <div>
            {{ detail.firstWriteOffTime | date("yyyy-mm-dd hh:mm:ss") }}
          </div>
        </div>
        <div class="flex-start mt-2x">
          <div class="gray">核销后有效期</div>
          <div>
            {{ detail.afterWriteOffValidTime | date("yyyy-mm-dd hh:mm:ss") }}
          </div>
        </div>
      </a-col>

      <!-- 放大图片预览 弹窗 -->
      <a-modal
        @cancel="enlargeImgVis = false"
        :width="500"
        :visible.sync="enlargeImgVis"
        title=""
        :footer="null"
      >
        <div class="flex-center preview-img">
          <img :src="enlargeImgUrl" />
        </div>
      </a-modal>
    </a-row>
    <!--门票信息-->
    <template>
      <h2 class="title-mid">门票信息</h2>
      <a-table
        :columns="ticketColumns"
        :data-source="detail.infoList"
        :scroll="{ x: true }"
        rowKey="id"
        :pagination="false"
      >
        <span slot-scope="row" slot="useDate">{{ row.useDate | date }}</span>
        <span slot="beginValidTime">-</span>
        <span slot-scope="row" slot="appointmentPeriodStartTime"
          >{{ row.appointmentPeriodStartTime | date }} -
          {{ row.appointmentPeriodEndTime | date }}</span
        >
        <span class="primary" slot-scope="row" slot="statusText">{{
          row.statusText
        }}</span>
        <span slot-scope="row" slot="enterTimes">{{
          row.enterTimesType == 0 ? "不限" : row.enterTimes
        }}</span>
        <span slot-scope="row" slot="dailyEnterTimesType">{{
          row.dailyEnterTimesType == 0 ? "不限" : row.dailyEnterTimes
        }}</span>
        <span slot-scope="row" slot="validMonthsType">{{
          row.validMonthsType == 0 ? "不限" : row.validMonths
        }}</span>
        <span slot-scope="row" slot="validWeekType">{{
          row.validWeekType == 0 ? "不限" : row.validWeeks
        }}</span>
        <span slot-scope="row" slot="useTimeType">{{
          row.useTimeType == 0 ? "不限" : row.useTimeRange | date
        }}</span>
        <span slot-scope="row" slot="firstWriteOffTime">{{
          row.firstWriteOffTime | date
        }}</span>
        <span slot-scope="row" slot="afterWriteOffValidTime">{{
          row.afterWriteOffValidTime | date
        }}</span>
        <template slot="action" slot-scope="row" v-if="row.id !== '合计'">
          <a-button
            v-if="row.orderBusinessTypeText != '自定义收款'"
            type="link"
            @click="refund(row, 2)"
            :disabled="row.status < 3 || row.status > 5"
            >退款
          </a-button>
          <a-button type="link" @click="linkMore(row)">更多</a-button>
        </template>
      </a-table>
    </template>
    <!--退款、退单-->
    <order-refund
      :visible.sync="visible"
      :order-id="orderId"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import OrderRefund from "../../order/components/OrderRefund";
import { findOrderDetailInfo } from "@/api/order";
export default {
  name: "ticketDetail",
  props: {
    // 基本信息
    detail: {
      type: Object,
      default() {
        return {};
      }
    },
    // 票号详情
    ticketSubDetailList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 门票出游人列表
    visitorInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 优惠信息
    discountInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 门票出游人列表
    customerList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 取票人列表信息
    receiverInfo: {
      type: Array,
      default() {
        return [];
      }
    },
    // 出游人
    playerInfo: {
      type: String,
      default() {
        return "";
      }
    },
    // 取票人
    takerInfo: {
      type: String,
      default() {
        return "";
      }
    },
    // 门票副票及腕带列表
    ticketVoucherList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 门票核销配置信息
    checkConfVO: {
      type: Object,
      default() {
        return {};
      }
    },
    // 会员详情信息
    ticketMemberInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 订单详情
    orderBasisInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 退款信息
    orderRefundInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    // 核销配置信息
    ticketWriteOffConfigInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: { OrderRefund },
  data() {
    return {
      refundDetail: {}, //门票信息
      enlargeImgVis: false,
      enlargeImgUrl: "",
      orderId: this.$route.query.orderId,
      visible: false, // 退款退单
      // 退款信息
      refundList: [], // 退款记录
      refundIdType: "", // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: "", // 退款类型 1退单， 2退款， 3退押金
      productName: "", // 退款产品名称
      productCategoryId: "", // 产品业务类型id
      // 门票table
      ticketColumns: [
        {
          title: "票号",
          dataIndex: "auxiliaryId",
          key: "auxiliaryId",
          width: 130
        },
        {
          title: "取票号",
          dataIndex: "getTicketNo",
          key: "getTicketNo",
          width: 130
        },
        {
          title: "游客姓名",
          dataIndex: "visitorName",
          key: "visitorName",
          width: 130
        },
        {
          title: "游客身份证",
          dataIndex: "visitorIdCard",
          key: "visitorIdCard",
          width: 130
        },
        {
          title: "使用日期",
          scopedSlots: { customRender: "useDate" },
          key: "useDate",
          width: 130
        },
        {
          title: "使用时段",
          scopedSlots: { customRender: "appointmentPeriodStartTime" },
          key: "appointmentPeriodStartTime",
          width: 130
        },
        {
          title: "产品名称",
          dataIndex: "productName",
          key: "productName",
          width: 130
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryName",
          key: "productCategoryName",
          width: 120
        },
        {
          title: "产品分类",
          dataIndex: "productTypeName",
          key: "productTypeName",
          width: 130
        },
        {
          title: "门票状态",
          dataIndex: "statusText",
          key: "statusText",
          width: 110
        },
        {
          title: "销售分销渠道",
          dataIndex: "buyChannelName",
          key: "buyChannelName",
          width: 120
        },
        {
          title: "产品所属商户",
          dataIndex: "productOwnerMerchantName",
          key: "productOwnerMerchantName",
          width: 140
        },
        {
          title: "订单编号",
          dataIndex: "orderId",
          key: "orderId",
          width: 180
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "",
          width: 180,
          fixed: "right"
        }
      ]
    };
  },
  created() {
    this.loadDetail();
  },
  methods: {
    // 加载订单详情
    loadDetail() {
      findOrderDetailInfo({
        id: this.orderId
      })
        .then(res => {
          this.refundDetail = res;
        })
        .catch(() => {});
    },
    enlargeImg(imgUrl) {
      this.enlargeImgUrl = imgUrl;
      this.enlargeImgVis = true;
    },
    // 门票信息- 更多按钮跳转到门票操作页面
    linkMore(row) {
      let path = "/home/ticket/TicketOperateDetail";
      let query = {
        id: row.id
      };

      this.$router.push({
        path,
        query
      });
    },
    // 退款
    refund(row, type) {
      // 退单
      this.refundType = 1; //  1
      this.refundIdType = "";
      this.productName = "";
      this.productCategoryId = "";
      this.refundIdList = [];
      // 退子产品
      if (row) {
        this.refundType = 2;
        this.productName = row.productName;
        this.productCategoryId = row.productCategoryId;
        this.refundIdType = type; //产品即子订单传1， 门票用2
        this.refundIdList = [row.id];
      }
      this.visible = true;
    },
    // 退款成功
    refunded() {
      this.loadDetail();
    }
  }
};
</script>

<style lang="less" scoped>
.w120 {
  width: 120px !important;
}
.flex-start > .gray {
  width: 110px;
}
.preview-img {
  img {
    width: 400px;
    height: 400px;
  }
}
</style>
