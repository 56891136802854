<!--
  @name: 门票表格组件，
  props: tableData-表格数据
-->
<template>
  <a-table
    :scroll="{ x: 1800 }"
    :columns="columns"
    :data-source="tableData"
    :pagination="false"
    :rowKey="(records, index) => index"
  >
    <span slot="useDate" slot-scope="row">{{ row.useDate | date }}</span>
    <span slot="appointmentPeriodStartTime" slot-scope="row"
      >{{ row.appointmentPeriodStartTime }} ~
      {{ row.appointmentPeriodEndTime }}</span
    >
    <span slot="beginValidTime" slot-scope="row"
      >{{ row.beginValidTime | date }} ~ {{ row.endValidTime | date }}</span
    >
    <span slot="userInfo1" slot-scope="row">{{
      row.takeTicketPersonPhone ? row.takeTicketPersonPhone : "-"
    }}</span>
    <span slot="userInfo2" slot-scope="row">{{
      row.takeTicketPersonIdCard ? row.takeTicketPersonIdCard : "-"
    }}</span>
    <template slot="action" slot-scope="row">
      <a-button v-permission="permission" type="link" @click="goDetail(row.id)"
        >门票操作</a-button
      >
    </template>
  </a-table>
</template>

<script>
const columns = [
  {
    title: "票号",
    dataIndex: "auxiliaryId",
    key: "auxiliaryId",
    width: 130
  },
  {
    title: "门票名称",
    dataIndex: "productName",
    key: "productName",
    width: 200,
    ellipsis: true
  },
  {
    title: "门票状态",
    key: "statusText",
    dataIndex: "statusText",
    width: 90
  },
  {
    title: "游玩日期",
    key: "useDate",
    scopedSlots: { customRender: "useDate" },
    width: 120
  },
  {
    title: "预约时段",
    key: "appointmentPeriodStartTime",
    scopedSlots: { customRender: "appointmentPeriodStartTime" },
    width: 150
  },
  {
    title: "使用有效期",
    key: "beginValidTime",
    scopedSlots: { customRender: "beginValidTime" },
    width: 200
  },
  {
    title: "取票号",
    dataIndex: "mainAuxiliaryId",
    key: "mainAuxiliaryId",
    width: 130
  },
  {
    title: "订单编号",
    dataIndex: "mainOrderId",
    key: "mainOrderId",
    width: 180
  },
  {
    title: "销售商户",
    dataIndex: "orderSaleMerchantName",
    key: "orderSaleMerchantName",
    width: 180
  },
  {
    title: "销售渠道",
    dataIndex: "buyChannelsText",
    key: "buyChannelsText",
    width: 120
  },
  {
    title: "取票手机号",
    scopedSlots: { customRender: "userInfo1" },
    key: "userInfo1",
    width: 120
  },
  {
    title: "取票身份证",
    scopedSlots: { customRender: "userInfo2" },
    key: "userInfo2",
    width: 180
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];
export default {
  name: "TicketListTable",
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 门票操作权限
    permission: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      columns
    };
  },
  methods: {
    goDetail(id) {
      this.$emit("jump", true);
      this.$router.push({
        path: "/home/ticket/TicketOperateDetail",
        query: { id }
      });
    }
  }
};
</script>

<style scoped></style>
