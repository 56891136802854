<template>
  <div class="wrapper flex-between flex-col">
    <!-- 头部 -->
    <panel-head title="会员充值">
      <p class="font-l mr-4x">会员充值</p>
      <div class="flex-grow flex-start align-center pl-4x">
        <!--        <b class="mr-4x">{{ memberInfoOne.name }}</b>-->
        <!--        <div class="yellow mr-4x">-->
        <!--          <span class="iconfont icondengji"></span-->
        <!--          >{{ memberInfoOne.currentLevelName }}-->
        <!--        </div>-->
        <!--        <span class="mr-4x">手机号：{{ memberInfoOne.mobile }}</span>-->
        <!--        <span class="mr-4x"-->
        <!--          >余额：{{ memberInfoOne.buyBalance | money }}元</span-->
        <!--        >-->
      </div>
    </panel-head>
    <!-- 主体 -->
    <div class="main mt-2x pb-2x">
      <a-tabs
        default-active-key="1"
        type="card"
        tabPosition="top"
        @change="tabChange"
      >
        <a-tab-pane key="1" tab="兑换币套餐">
          <div class="ph-2x">
            <a-row :gutter="[16, 16]">
              <a-col
                :xxl="8"
                :xl="8"
                :lg="12"
                v-for="item in coinMoneyList"
                :key="item.id"
              >
                <div
                  @click="selectProduct(item)"
                  class="shadow coin_money_card"
                  :class="currOrderId === item.id ? 'coin_bg' : ''"
                >
                  <div class="flex-between">
                    <span class="font-l text-1 bold">{{ item.name }}</span>
                    <span class="font-xl flex-shrink ml-2x price">{{
                      item.sellingPrice | money
                    }}</span>
                  </div>
                  <div class="flex-1 flex-between flex-col">
                    <div class="gray mt-1x ">
                      <a-row class="flex-start">
                        <a-col class="flex-shrink" :span="9">
                          充值总余额:{{ item.memberBalance | money("") }}
                        </a-col>
                        <a-col class="flex-shrink" :span="7">
                          赠送积分:{{ item.giftPoints }}
                        </a-col>
                        <a-col :span="8">
                          赠送成长值:{{ item.giftGrowth }}
                        </a-col>
                      </a-row>
                      <p class="mt-x text-1">
                        <span
                          class="pointer"
                          @click="showProductSettingMode(item)"
                          v-if="item.validRulesList.length"
                        >
                          有效期：
                          {{
                            item.validRulesList[item.defaultValidityRule - 1]
                              .ruleName
                          }}
                        </span>
                        <span v-else>有效期： -</span>
                        <a-icon
                          v-if="item.validRulesList.length"
                          type="down"
                          class="ml-x"
                        />
                      </p>
                      <p class="mt-x text-2">
                        赠送优惠券： {{ item.giftCoupons || "无" }}
                      </p>
                      <div class="mt-x table_bd">
                        <a-row class="p-x primary-light1 text">
                          <a-col :span="8" class="ph-x">
                            兑换币名称
                          </a-col>
                          <a-col :span="4" class="ph-x">
                            金额
                          </a-col>
                          <a-col :span="12" class="ph-x">
                            可用产品
                          </a-col>
                        </a-row>
                        <template v-for="(temp, idx) in 2">
                          <a-row class="p-x trRow" :key="idx">
                            <a-col :span="8" class="text-1 ph-x">
                              {{
                                item.exchangeCoinList[idx] &&
                                  item.exchangeCoinList[idx].name
                              }}
                            </a-col>
                            <a-col :span="4" class="ph-x">
                              {{
                                item.exchangeCoinList[idx] &&
                                  item.exchangeCoinList[idx].assetNum
                                    | money("")
                              }}
                            </a-col>
                            <a-col :span="12" class="text-1 ph-x">
                              <a-tooltip
                                placement="topLeft"
                                v-if="
                                  item.exchangeCoinList[idx] &&
                                    item.exchangeCoinList[idx]
                                      .applicableProducts
                                "
                              >
                                <template slot="title">
                                  <span>{{
                                    item.exchangeCoinList[idx]
                                      .applicableProducts
                                  }}</span>
                                </template>
                                <span>{{
                                  item.exchangeCoinList[idx].applicableProducts
                                }}</span>
                              </a-tooltip>
                              <span
                                v-else-if="
                                  item.exchangeCoinList[idx] &&
                                    !item.exchangeCoinList[idx]
                                      .applicableProducts
                                "
                                >全部</span
                              >
                            </a-col>
                          </a-row>
                        </template>
                        <a-row class="p-x trRow">
                          <a-col
                            v-if="
                              item.exchangeCoinList &&
                                item.exchangeCoinList.length > 2
                            "
                            :span="24"
                          >
                            <a-popover trigger="'click'" placement="top">
                              <template #title>资产明细</template>
                              <template #content>
                                <div class="table_bd">
                                  <a-row class="p-x primary-light1">
                                    <a-col :span="8" class="ph-x">
                                      兑换币名称
                                    </a-col>
                                    <a-col :span="4" class="ph-x">
                                      金额
                                    </a-col>
                                    <a-col :span="12" class="ph-x">
                                      可用产品
                                    </a-col>
                                  </a-row>
                                  <a-row
                                    v-for="(temp, idx) in item.exchangeCoinList"
                                    :key="idx"
                                    class="p-x trRow"
                                  >
                                    <a-col :span="8" class="ph-x">
                                      {{ temp.name }}
                                    </a-col>
                                    <a-col :span="4" class="ph-x">
                                      {{ temp.assetNum | money("") }}
                                    </a-col>
                                    <a-col :span="12" class="ph-x">
                                      {{ temp.applicableProducts || "全部" }}
                                    </a-col>
                                  </a-row>
                                </div>
                              </template>
                              <p class="pointer flex-center">更多V</p>
                            </a-popover>
                          </a-col>
                          <a-col v-else></a-col>
                        </a-row>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="兑 换 券">
          <div class="ph-2x">
            <a-row :gutter="[16, 16]">
              <a-col
                :xxl="8"
                :xl="8"
                :lg="12"
                v-for="item in coinList"
                :key="item.id"
              >
                <div
                  @click="selectProduct(item)"
                  class="shadow coin_card"
                  :class="currOrderId === item.id ? 'coin_bg' : ''"
                >
                  <div class="flex-between">
                    <span class="font-l text-1 bold">{{ item.name }}</span>
                    <span class="font-xl flex-shrink ml-2x price">{{
                      item.sellingPrice | money
                    }}</span>
                  </div>
                  <div class="flex-1 flex-between flex-col">
                    <div class="gray mt-1x ">
                      <p>
                        <span
                          class="pointer"
                          @click="showProductSettingMode(item)"
                          v-if="item.validRulesList.length"
                        >
                          有效期：
                          {{
                            item.validRulesList[item.defaultValidityRule - 1]
                              .ruleName
                          }}
                        </span>
                        <span v-else>有效期： -</span>
                        <a-icon
                          v-if="item.validRulesList.length"
                          type="down"
                          class="ml-x"
                        />
                      </p>
                      <div class="flex-between">
                        <p class="mt-1x text-2">
                          兑换规则：{{ item.convertRules }}
                        </p>
                        <a-popover trigger="'click'" placement="top">
                          <template #title>兑换规则</template>
                          <template #content>
                            <p class="mt-x text-2">
                              {{ item.convertRules }}
                            </p>
                          </template>
                          <span class="pl-2x mt-1x pointer primary flex-shrink"
                            >更多></span
                          >
                        </a-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- 底部 -->
    <div class="flex-end cum-footer">
      <a-form-model
        ref="form"
        :model="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <!-- 审批状态：1免审核，2审核中，3拒绝，4通过 -->
        <a-form-model-item
          prop="rechargeValue"
          label="充值对象"
          :rules="[
            {
              required: true,
              message: '请输入充值对象',
              trigger: 'change'
            },
            {
              validator:
                memberInfo.whetherTemporaryMember === 0 ? checkPhone : null
            }
          ]"
        >
          <a-input
            placeholder="请输入充值对象"
            allow-clear
            v-model="form.rechargeValue"
            :disabled="memberInfo.whetherTemporaryMember === 1"
          />
        </a-form-model-item>
      </a-form-model>
      <a-button
        class="btn-lg flex-between align-center ml-3x"
        size="large"
        shape="round"
        type="primary"
        @click="createOrder"
      >
        <span>结账</span>
        <span>{{ productParams.sellingPrice || 0 | money }}</span>
      </a-button>
    </div>
    <!--支付方式-->
    <pay-way
      ref="payWay"
      :is-modal="true"
      :showMemberBalance="false"
      :showPayAfter="false"
      :cart-id="cartId"
      @success="paySuccess"
    ></pay-way>
    <!-- 产品有效期设置列表 -->
    <a-modal
      v-model="isShowProductSettingMode"
      title="产品有效期设置"
      :width="600"
    >
      <section
        style="max-height:300px;"
        v-if="showProductValiditySetting.length"
      >
        <div
          v-for="(item, idx) in showProductValiditySetting"
          :key="idx"
          class="content flex-between align-center pv-2x"
          :class="{ 'bd-t': idx > 0 }"
        >
          <div class="product-setting-name">
            {{ item.ruleName }}
          </div>
          <div class="flex-between align-center">
            <template v-if="item.isDefault">
              <span class="primary">已设默认</span>
            </template>
            <template v-else>
              <span class="pointer" @click="productSetDefault(idx + 1)"
                >设为默认</span
              >
            </template>
          </div>
        </div>
      </section>

      <a-empty v-else :image="simpleImage" description="未设置产品有效期" />
    </a-modal>
  </div>
</template>

<script>
// 明细
import PanelHead from "@/components/common/PanelHead";
import { productFrontMembervoucher, productFrontpackage } from "@/api/search"; // MS-搜索中心接口
import { orderOneMemberInfo } from "@/api/member"; // MS-会员中心接口
import { memberRecharge } from "@/api/cart"; // MS-购物车中心接口
import PayWay from "@/components/sell/commonents/PayWay";
import { mapGetters } from "vuex";
import { Empty } from "ant-design-vue";
import { checkPhone } from "../../../utils/global";

export default {
  name: "MemberRecharge",
  components: { PanelHead, PayWay },
  data() {
    return {
      checkPhone,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      coinMoneyList: [], // 兑换币列表
      coinList: [], // 兑换券列表
      tabActive: "1",
      cartId: 0,
      isIconDown: true, // 向上，向下箭头转换
      currOrderId: 0, // 当前充值套餐id
      productParams: {}, // 产品参数对象
      isShowProductSettingMode: false, // 产品有效期设置
      showProductValiditySetting: [], // 单个产品的产品有效期设置
      setProductId: "", // 设置产品有效期产品id
      form: {
        rechargeValue: "" // 充值对象
      }
      // // 表单验证规则
      // rules: {
      //   rechargeValue: [
      //     { required: true, message: "请输入充值对象", trigger: "change" }
      //   ]
      // }
    };
  },
  computed: {
    ...mapGetters("common", ["memberInfo"])
  },
  created() {
    this.form.rechargeValue =
      this.memberInfo.mobile ||
      (this.memberInfo.ticketNo && this.memberInfo.ticketNo.toString()) ||
      "";
    this.productFrontpackageLoad();
  },
  // 防止父组件没设置validate属性而报错，因为payway组件有引入  inject: ["validate"],
  provide() {
    return {
      validate: {}
    };
  },
  methods: {
    // 加载兑换币套餐
    productFrontpackageLoad() {
      productFrontpackage().then(res => {
        this.coinMoneyList = res;
        // 查找产品的有效期
        this.findProductValidity();
      });
    },
    // 加载兑换券
    productFrontMembervoucherLoad() {
      productFrontMembervoucher({
        page: 1,
        size: 99
      }).then(res => {
        this.coinList = res.records;
        // 查找产品的有效期
        this.findProductValidity();
      });
    },
    // tab切换
    tabChange(key) {
      this.currOrderId = 0; // 变更充值套餐id
      this.productParams = {}; // 变更产品参数对象
      this.tabActive = key;
      if (key === "1") {
        this.productFrontpackageLoad();
      } else this.productFrontMembervoucherLoad(); // 查询资产套餐列表
    },
    // 结账支付完成
    paySuccess() {
      this.currOrderId = 0; // 变更充值套餐id
      this.productParams = {}; // 变更产品参数对象
      if (this.tabActive === "1") {
        this.productFrontpackageLoad();
      } else this.productFrontMembervoucherLoad(); // 查询资产套餐列表
    },

    // 选择充值的套餐
    selectProduct(item) {
      this.currOrderId = item.id; // 变更充值套餐id
      this.productParams = item; // 变更产品参数对象
    },
    memberRechargeFn(memberObj) {
      // // 新会员充值
      let item = this.productParams;
      let obj = {
        rechargeTo: this.form.rechargeValue,
        giftMemberPhones: [
          {
            productId: item.id,
            validType: 1,
            validStartTime:
              (item.validRulesList[item.defaultValidityRule - 1] &&
                item.validRulesList[item.defaultValidityRule - 1].beginDate) ||
              "", // 有效期开始时间
            validEndTime:
              (item.validRulesList[item.defaultValidityRule - 1] &&
                item.validRulesList[item.defaultValidityRule - 1].endDate) ||
              "" // 有效期结束时间
          }
        ]
      };
      if (this.memberInfo.id) {
        // 存在登录会员id
        obj.purchaserMemberId = this.memberInfo.id;
        obj.whetherTemporaryMember = this.memberInfo.whetherTemporaryMember;
      } else {
        // 未登录，通过充值对象查找出来的会员id
        obj.purchaserMemberId = memberObj.id;
        obj.whetherTemporaryMember = memberObj.whetherTemporaryMember;
      }
      memberRecharge(obj)
        .then(res => {
          this.cartId = res.id;
          this.$nextTick(() => {
            this.$refs.payWay.pay();
          });
        })
        .catch(() => {});
    },
    checkPhoneFn(val) {
      if (val) {
        // 根据会员号查询会员详情
        orderOneMemberInfo({
          evidence: val
        }).then(res => {
          this.memberRechargeFn(res);
        });
      }
    },
    // 会员充值
    createOrder() {
      if (!this.productParams.id) {
        this.$message.error(
          this.tabActive === "1" ? "请先选择兑换币套餐" : "请先选择兑换券"
        );
        return;
      } else if (!this.form.rechargeValue) {
        this.$message.error("充值对象必填!");
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.checkPhoneFn(this.form.rechargeValue);
        }
      });
    },
    // 显示产品有效期设置弹窗
    showProductSettingMode(item) {
      this.setProductId = item.id;
      // 获取产品设置的产品有效期
      // 存缓存
      let arr = localStorage.getItem("coinValidityRule")
        ? JSON.parse(localStorage.getItem("coinValidityRule"))
        : [];
      let productRuleObj = arr.find(val => {
        return val.setProductId === item.id;
      });
      this.showProductValiditySetting = item.validRulesList;
      if (this.showProductValiditySetting.length) {
        // 初始化默认产品有效期
        this.showProductValiditySetting.forEach(item => {
          item.isDefault = false;
        });
        // 有设置过默认产品有效期就拿默认的
        if (productRuleObj) {
          // 当窗口自己设置的默认有效期，后台删了有效期的一些配置就拿默认的
          this.showProductValiditySetting[
            productRuleObj.idx - 1
          ].isDefault = true;
        } else {
          // 没有就拿系统设置的
          this.showProductValiditySetting[
            item.validDefault - 1
          ].isDefault = true;
        }
      }
      this.isShowProductSettingMode = true;
    },
    // 产品有效期设置
    productSetDefault(idx) {
      let setProductId = this.setProductId;
      let value = { setProductId: setProductId, idx: idx };
      // 查询该产品是否设置有效期
      let arr = localStorage.getItem("coinValidityRule")
        ? JSON.parse(localStorage.getItem("coinValidityRule"))
        : [];
      let ProductValidityRule = arr;
      let isSet = true;
      if (ProductValidityRule.length) {
        ProductValidityRule.forEach(item => {
          // 设置过的产品就编辑产品有效期
          if (item.setProductId === setProductId) {
            item.idx = idx;
            isSet = false;
          }
        });
      }
      // 没设置过的产品就添加
      if (isSet) {
        ProductValidityRule.push(value);
      }
      // 存缓存
      localStorage.setItem(
        "coinValidityRule",
        JSON.stringify(ProductValidityRule)
      );
      this.findProductValidity();
      this.isShowProductSettingMode = false;
    },
    // 查找产品的产品有效期和取票操作
    findProductValidity() {
      if (this.tabActive === "1") {
        // 兑换币套餐
        this.coinMoneyList.forEach(item => {
          // 重置默认产品有效期
          item.defaultValidityRule = item.validDefault;
          // 查询该产品是否设置有效期
          let arr = localStorage.getItem("coinValidityRule")
            ? JSON.parse(localStorage.getItem("coinValidityRule"))
            : [];
          if (arr) {
            let productRuleObj = arr.find(val => {
              return val.setProductId === item.id;
            });
            if (productRuleObj) {
              // 窗口设置为最后一条规则，后台删了最后一条规则，就重新默认为后台设置的默认
              if (productRuleObj.idx <= item.validRulesList.length) {
                item.defaultValidityRule = productRuleObj.idx;
              }
            }
          }
        });
      } else {
        // 兑换券
        this.coinList.forEach(item => {
          // 重置默认产品有效期
          item.defaultValidityRule = item.validDefault;
          // 查询该产品是否设置有效期
          let arr = localStorage.getItem("coinValidityRule")
            ? JSON.parse(localStorage.getItem("coinValidityRule"))
            : [];
          if (arr) {
            let productRuleObj = arr.find(val => {
              return val.setProductId === item.id;
            });
            if (productRuleObj) {
              // 窗口设置为最后一条规则，后台删了最后一条规则，就重新默认为后台设置的默认
              if (productRuleObj.idx <= item.validRulesList.length) {
                item.defaultValidityRule = productRuleObj.idx;
              }
            }
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.content {
  padding: 16px;
  overflow: hidden;
}
.coin_money_card {
  .coin_public;
  height: 280px;
}
.coin_card {
  .coin_public;
  height: 140px;
}
.coin_public {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  padding: 16px;
}
.coin_bg {
  box-shadow: 0 0 9px 0 rgba(65, 130, 252, 0.5);
  border: 1px solid @primary;
  /*box-shadow: 0 0 9px 0 rgba(65, 130, 252, 0.1) !important;*/
}
.input {
  width: 200px;
}
.table_bd {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid @border;
  .trRow {
    min-height: 30px;
  }
  > .trRow:not(:last-child) {
    border-bottom: 1px solid @border;
  }
}
.primary-light1 {
  background: @primary-light2;
}
.cum-footer {
  background: #fff;
  padding: 24px 24px 0 24px;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}
</style>
