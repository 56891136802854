<!--订单产品详情-->
<template>
  <div class="wrapper flex-between flex-col">
    <page-header title="订单产品详情"></page-header>
    <section class="main mt-2x">
      <div class="flex-between p-2x">
        <div class="flex-start align-center" v-if="searchTitle">
          <p class="flex-shrink mr-2x">{{ searchTitle }}</p>
          <a-input-search
            v-model="searchKey"
            :placeholder="`请输入${searchTitle}`"
            class="input"
            allow-clear
            @change="search"
          >
          </a-input-search>
        </div>
        <div class="text-r flex-grow">
          <!--订单为支付 且 状态且业务类型不是会员特权和补办则展示按钮-->
          <a-button
            type="primary"
            v-if="
              orderStatus === '3' &&
                orderBusinessType !== 5 &&
                orderBusinessType !== 7
            "
            @click="refundAll"
            >退款</a-button
          >
        </div>
      </div>
      <!--表格-->
      <a-table
        :columns="columns"
        :data-source="tableData"
        rowKey="id"
        :pagination="false"
      >
        <span
          :class="{ primary: row.orderStatusText !== '合计' }"
          slot-scope="row"
          slot="orderStatusText"
          >{{ row.orderStatusText }}</span
        >
        <template slot-scope="row" slot="productName">
          <span v-if="row.productName">{{ row.productName }}</span>
        </template>
        <span slot-scope="row" slot="totalAmount">{{
          row.totalAmount | money
        }}</span>
        <span slot-scope="row" slot="receivedAmount">{{
          row.receivedAmount | money
        }}</span>
        <span slot-scope="row" slot="discountAmount">{{
          row.discountAmount | money
        }}</span>
        <span slot-scope="row" slot="totalRefundAmount">{{
          row.totalRefundAmount | money
        }}</span>
        <span slot-scope="row" slot="leftAmount">{{
          row.leftAmount | money
        }}</span>
        <template
          slot-scope="row"
          slot="action"
          v-if="row.orderStatusText !== '合计'"
        >
          <a-button
            type="link"
            :disabled="row.orderStatus < 3 || row.orderStatus > 5"
            @click="refund([row.id], row.productName)"
            >退款</a-button
          >
        </template>
      </a-table>
    </section>

    <!--退款弹窗-->
    <order-refund
      :order-id="orderId"
      :visible.sync="visible"
      :product-name="productName"
      :product-category-id="productCategoryId"
      :refund-type="refundType"
      :refund-id-list="refundIdList"
      :refund-id-type="refundIdType"
      @on-ok="refunded"
    ></order-refund>
  </div>
</template>

<script>
import PageHeader from "../../../components/common/PanelHead";
import OrderRefund from "./components/OrderRefund";
import { findSuborderDetailInfo } from "../../../api/order";
export default {
  name: "ProductDetail",
  components: { PageHeader, OrderRefund },
  data() {
    return {
      visible: false, // 退款弹窗
      id: this.$route.query.id,
      productCategoryId: this.$route.query.productCategoryId, // 产品类型
      orderStatus: this.$route.query.orderStatus, // 订单状态
      orderId: this.$route.query.orderId, // 订单编号
      orderBusinessType: this.$route.query.orderBusinessType, // 订单业务类型
      searchKey: "", // 票号
      searchTitle: "",
      tableData: [],
      originData: [],
      columns: [
        {
          title: "产品名称",
          scopedSlots: { customRender: "productName" },
          width: 180
        },
        {
          title: "产品编号",
          dataIndex: "productId",
          key: "productId",
          width: 180
        },
        {
          title: "产品类型",
          dataIndex: "productCategoryText",
          key: "productCategoryText",
          width: 120
        },
        {
          title: "产品状态",
          scopedSlots: { customRender: "orderStatusText" },
          key: "orderStatusText",
          width: 100
        },
        {
          title: "应付金额",
          scopedSlots: { customRender: "totalAmount" },
          key: "totalAmount",
          width: 100
        },
        {
          title: "优惠金额",
          scopedSlots: { customRender: "discountAmount" },
          key: "discountAmount",
          width: 100
        },
        {
          title: "实付金额",
          scopedSlots: { customRender: "receivedAmount" },
          key: "receivedAmount",
          width: 100
        },
        {
          title: "已退款金额",
          scopedSlots: { customRender: "totalRefundAmount" },
          key: "totalRefundAmount",
          width: 100
        },
        {
          title: "未退款金额",
          scopedSlots: { customRender: "leftAmount" },
          key: "leftAmount",
          width: 100
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          key: "action",
          width: 100
        }
      ],

      // 退款信息
      refundList: [], // 退款记录
      refundIdType: "", // id类型 1子订单id 2产品详情id
      refundIdList: [], // 子订单id列表或订单详情id列表
      refundType: "", // 退款类型 1退单， 2退款， 3退押金
      productName: "" // 退款产品名称
    };
  },
  created() {
    // 更多分类请看接口文档的产品分类
    // http://www.docway.net/project/1dAc1O6WB4z/1dVp80ii0sC
    const arr = [
      "1365211690130337793",
      "1365211690470076417",
      "1365211693271871490",
      "1365211694463053826",
      "1365211694970564609",
      "1365211695276748801",
      "1365211696811864066",
      "1361211689463443192", // 新增剧院显示票号title
      "1459375207863967746" // 组合产品
    ];
    let title = "";
    // 门票类型
    if (arr.includes(this.productCategoryId)) {
      title = "票号";
      this.columns.unshift({
        title,
        dataIndex: "auxiliaryId",
        key: "auxiliaryId",
        width: 120
      });
    }
    // 会员类型
    else if (
      this.productCategoryId === "1365211695767482369" ||
      this.productCategoryId === "1365211695767482369"
    ) {
      title = "会员编号";
      this.columns.unshift({
        title,
        dataIndex: "purchaserMemberId",
        key: "purchaserMemberId",
        width: 120
      });
    }
    // 项目类型
    else if (
      this.productCategoryId === "1365211691942277121" ||
      this.productCategoryId === "1368872739652091905"
    ) {
      title = "套餐编号";
      this.columns.unshift({
        title,
        dataIndex: "auxiliaryId",
        key: "auxiliaryId",
        width: 120
      });
    }

    // 剧院产品添加座位号
    if (this.productCategoryId === "1361211689463443192") {
      this.columns.splice(2, 0, {
        title: "座位",
        dataIndex: "specs",
        key: "specs",
        width: 130
      });
    }
    this.searchTitle = title;
    this.loadData();
  },
  methods: {
    loadData() {
      findSuborderDetailInfo({ id: this.id })
        .then(res => {
          this.originData = res;

          this.total(res);
        })
        .catch(function() {});
    },
    // 合计
    total(res) {
      let obj = {
        totalAmount: 0,
        discountAmount: 0,
        receivedAmount: 0,
        totalRefundAmount: 0,
        leftAmount: 0,
        orderStatusText: "合计",
        id: "hj"
      };
      // 合计并生成新的table数组
      const arr = res.map(item => {
        obj.totalAmount += item.totalAmount;
        obj.discountAmount += item.discountAmount;
        obj.receivedAmount += item.receivedAmount;
        obj.totalRefundAmount += item.totalRefundAmount;
        obj.leftAmount += item.leftAmount;
        return item;
      });
      if (arr.length) arr.push(obj);
      this.tableData = arr;
    },
    // 搜索
    search(e) {
      let key =
        this.searchTitle === "会员编号" ? "purchaserMemberId" : "auxiliaryId";
      this.total(
        this.originData.filter(item => {
          let temp = item[key] + "";
          return temp.includes(e.target.value);
        })
      );
    },
    // 开始退款弹窗
    refund(arr, productName) {
      this.refundType = 2;
      this.productName = productName;
      this.refundIdType = 2;
      this.refundIdList = arr;
      this.visible = true;
    },
    // 退全部
    refundAll() {
      this.refund(
        this.originData.map(item => item.id),
        this.originData[0].productName
      );
    },
    // 退款成功
    refunded() {
      this.orderStatus = 9; // 改为部分退款
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
