import { render, staticRenderFns } from "./TicketChanges.vue?vue&type=template&id=0d09a148&scoped=true&"
import script from "./TicketChanges.vue?vue&type=script&lang=js&"
export * from "./TicketChanges.vue?vue&type=script&lang=js&"
import style0 from "./TicketChanges.vue?vue&type=style&index=0&id=0d09a148&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d09a148",
  null
  
)

export default component.exports