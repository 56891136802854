<!--
  @name: 会员折扣
  @author: fengyanlong
  @date:2021-04-14
-->
<template>
  <div class="order-hyzk">
    <!-- 当没有会员时，查询会员键盘 -->
    <div v-if="!memberId">
      <find-member></find-member>
    </div>
    <!--查找到会员-->
    <div v-if="memberId" class="flex-start">
      <!--会员信息卡片-->
      <top-member-info
        :is-checked="isChecked"
        @handleClick="handleClick"
        @changeMember="reset"
      ></top-member-info>
    </div>
  </div>
</template>

<script>
import FindMember from "../../../../components/member/FindMember.vue";
import { mapGetters, mapActions } from "vuex";
import TopMemberInfo from "./TopMemberInfo.vue";
import { wholeOrderMemberDiscountInfo } from "../../../../api/member";

export default {
  name: "OrderHyzk",
  props: {
    orderData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  components: {
    FindMember,
    TopMemberInfo
  },
  computed: {
    ...mapGetters("common", ["memberId"]),
    ...mapGetters("common", ["memberInfo"])
  },
  data() {
    return {
      levelInfo: {},
      isChecked: false,
      stopClick: false // 防止点击点击过快
    };
  },
  methods: {
    ...mapActions("order", ["removeOrderDiscountTextList"]),
    handleClick() {
      this.isChecked = !this.isChecked;
      if (this.isChecked) {
        if (this.stopClick) {
          return;
        }
        this.stopClick = true;
        //过滤下单产品的列表
        const list = this.orderData.productItemList.map(item => {
          return {
            productId: item.productId, // 产品id
            salePrice: item.sellingPrice, // 销售价格(1个产品的销售价格)
            count: item.buyNum, // 产品数量
            merchantId: item.merchantId // 商户id
          };
        });
        wholeOrderMemberDiscountInfo({
          memberId: this.memberId,
          productItems: list,
          preferentialList: [
            {
              preferentialType: "E", //A整单折扣 B会员折扣 C优惠券 D营销活动 E会员整单折扣
              customAmount: this.memberInfo.buyBalance // 会员余额
            }
          ]
        })
          .then(res => {
            if (res.amount || res.amount === 0) {
              // 抛出给父元素，点击确定在执行，存储优惠到vuex
              this.$emit("triggerCalc", {
                name: "会员折扣",
                amount: res.amount,
                merchantDiscountStr: "E"
              });
            }
          })
          .finally(() => {
            this.stopClick = false;
          });
      } else {
        this.reset();
      }
    },
    reset() {
      this.isChecked = false;
      this.removeOrderDiscountTextList("orderDiscount");
      this.$emit("triggerDel");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../../assets/less/custom.less";

/*会员等级卡片*/
.level-card {
  overflow: hidden;
  position: relative;
  width: 250px;
  height: 136px;
  margin-right: 24px;
  border-radius: 14px;
  background-size: cover;
  color: #ffffff;
  .checked {
    width: 34px;
    height: 34px;
    background-color: #ffffff;
    position: absolute;
    right: 18px;
    bottom: 15px;
    border-radius: 50%;
    text-align: center;
    line-height: 34px;
    font-size: 22px;
    color: @primary;
  }
}
</style>
