<template>
  <section class="flex-start flex-grow">
    <!--中间路由-->
    <transition name="slide-fade">
      <!--四级路由-->
      <router-view class="flex-grow"></router-view>
    </transition>
    <!--购物车-->
    <shopping-cart></shopping-cart>
  </section>
</template>
<script>
import ShoppingCart from "@/components/ShoppingCart";
import { shoppingCart } from "@/api/cart"; // MS-产品中心接口 3.0
import { mapActions } from "vuex";

export default {
  name: "SellTicket",
  components: {
    ShoppingCart
  },
  data() {
    return {};
  },
  created() {
    if (this.$store.state.order.cartList.id) {
      this.getCartList(); // 获取单个购物车商品列表
    }
  },
  methods: {
    ...mapActions("order", ["setCartList"]),

    // 获取单个购物车商品列表
    getCartList() {
      shoppingCart({ id: this.$store.state.order.cartList.id })
        .then(res => {
          this.cartList = res;
          this.setCartList(res);
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
/*.sell-ticket {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  height: 100%;*/
/*  flex: auto;*/
/*  min-height: 0;*/
/*}*/

/* 可以设置不同的进入和离开动画 */
.slide-fade-enter-active {
  transition: all 0.3s;
}
.slide-fade-enter {
  opacity: 0;
  -webkit-opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
  -webkit-opacity: 0;
}
</style>
