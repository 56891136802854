<template>
  <a-modal
    :visible="vis"
    title="填写游客信息"
    :footer="null"
    @cancel="cancel"
    :mask-closable="false"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      labelAlign="left"
    >
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone">
        <a-input v-model="form.phone" allow-clear />
      </a-form-model-item>

      <a-form-model-item label="证件类型">
        <a-select
          :default-value="6"
          v-model="form.cardType"
          placeholder="请选择证件类型"
        >
          <a-select-option value="身份证">身份证 </a-select-option>
          <a-select-option value="护照">护照 </a-select-option>
          <a-select-option value="港澳通行证">港澳通行证 </a-select-option>
          <a-select-option value="台胞证">台胞证 </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="证件号码" prop="idCard">
        <IdCardInput
          v-model="form.idCard"
          @readCard="readCard"
          ref="myidCardInput"
          placeholder="请输入真实证件号凭证入园"
        ></IdCardInput>
      </a-form-model-item>
      <a-form-model-item label="游客类型">
        <a-select
          :default-value="form.visitorTypeId"
          v-model="form.visitorTypeId"
          placeholder="请选择游客类型"
        >
          <a-select-option
            v-for="item in touristTypes"
            :key="item.id"
            :value="item.id"
          >
            {{ item.touristCategoryName }}
          </a-select-option>
        </a-select>
        <div class="red" v-if="touristTypes.length > 0">
          <!-- 折扣类型 (1.折扣 2.无 3.免费) -->
          优惠：{{
            touristType.discountCategory == 1
              ? touristType.fold / 10 + "折"
              : touristType.discountCategory == 2
              ? "无"
              : "免费"
          }}
        </div>
      </a-form-model-item>

      <div class="flex-center">
        <a-button type="primary mr-4x" size="large" @click="sure">
          确定
        </a-button>
        <a-button size="large" @click="cancel">取消</a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>
<script>
import { checkPhone, checkText, dateFormat } from "../../../../utils/global";
import IdCardInput from "@/components/hardware/IdCardInput";
import { newSelectTouristWindowInfoByProductId } from "@/api/member";
import { merchantSettingInfo } from "@/api/merchant";

export default {
  name: "TouristDialog",
  components: { IdCardInput },
  props: {
    vis: {
      type: Boolean,
      default: false
    },
    productId: {
      type: String,
      default: ""
    },
    playDate: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        cardType: "身份证", // 证件类型
        idCard: "", // 证件号码
        visitorTypeId: "" //游客类型
      },
      rules: {
        name: [
          { required: false, message: "请输入姓名", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        idCard: [
          { required: false, message: "请输入证件号码", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ],
        phone: [
          { required: false, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ]
      },
      touristType: {},
      touristTypes: []
    };
  },
  watch: {
    vis(newVal) {
      this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", newVal);
      if (newVal && this.$refs.myidCardInput) {
        this.$refs.myidCardInput.listen({ target: { value: "" } });
      } else {
        window.readIdCard = () => {}; //关闭弹窗时-身份证监听置空
      }
    }
  },
  methods: {
    //获取景区配置 动态获取游客信息填写字段
    querymerchantSettingInfo() {
      merchantSettingInfo().then(res => {
        console.log(res);
      });
    },
    queryTourist(productId) {
      // this.querymerchantSettingInfo();
      // this.$refs.myidCardInput.toFouce();
      // 清空数据
      this.touristTypes = [];
      this.form.visitorTypeId = "";
      this.touristType = {};
      newSelectTouristWindowInfoByProductId({ productIds: productId }).then(
        res => {
          if (res[0].memberTouristManagementVOList) {
            this.touristTypes = res[0].memberTouristManagementVOList || [];
            let date = dateFormat(this.playDate);
            // 2022-09-01之后暂定不能购买中小学游客类型 53551
            if (date >= "2022-09-01") {
              this.touristTypes = this.touristTypes.filter(
                item => item.id !== 53551
              );
            }
            if (this.touristTypes.length) {
              this.form.visitorTypeId = this.touristTypes[0].id;
              this.touristType = this.touristTypes[0];
            }
          }
        }
      );
    },
    sure() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          this.$message.warning("填写的信息校验不通过，请检查");
          return;
        }
        this.$emit("change", this.form);
        this.$refs.ruleForm.resetFields();
        this.cancel();
      });
    },
    // 关闭弹窗
    cancel() {
      this.$emit("update:vis", false);
    },
    // 身份证读卡
    readCard(res) {
      // console.log("读到的身份证信息9+++++", res);
      this.form.name = res.name;
    }
  }
};
</script>
<style lang="less" scoped></style>
